import React from "react";
import Footer from "../../Footer";
import TopNavbar from "../../TopNavbar";
import LeftNavbar from "../../LeftNavbar";
import { Redirect } from "react-router-dom";
import NewsBlogNoticeDetails from "./NewsBlogNoticeDetails";

const NewsBlogNotice = () => {
  document.title = "Admin | Show News Blog & Notice";
  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <NewsBlogNoticeDetails />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default NewsBlogNotice;
