import React, { useEffect, useState } from "react";
import "./Nav.css";
// import Category from "./Category";
// import HomeDropDown from "./HomeDropDown";
// import FeatureDropDown from "./FeatureDropDown";
import { Link, NavLink, useHistory } from "react-router-dom";
import { getCategories } from "../../../Common/Features/Action/CategoryAction/CategoryAction";
import { homePageSearch } from "../Features/Action/WholesalerAction";
import { Drawer } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSelector } from "react-redux";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { useDispatch } from "react-redux";
import logo from "../../../assets/Images/kreesalogo.png";
import { BiMenu } from "react-icons/bi";
import { toast } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";
import QuoteModal from "../../User/Quotation/QuoteModal";
import NestedListGroup from "../../User/NavBar/Drawer/Drawer";

function Nav() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Categories, loader } = useSelector((s) => s.Categories);
  const [modal, setModal] = useState(false);

  // drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  const [navText, setNavText] = useState("Quotation Order");

  useEffect(() => {
    var index = 0;
    const interval = setInterval(() => {
      switch (index) {
        case 0:
          setNavText("Offer Your Price");
          break;
        case 1:
          setNavText("Bargain Online");
          break;
        default:
          setNavText("Quotation Order");
          break;
      }
      index++;
      if (index > 2) {
        index = 0;
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const dropdownSearch = (id, type, name) => {
    let data = {
      catType: type,
      categoryId: id,
      userType: "wholesale",
    };
    AxiosConfig.post(`/kreesa/search-product`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((res) => {
      // dispatch(homePageSearch(res.data));
      history.push({
        pathname: "/seller/search-page",
        search: `?category=${name}`,
        state: res.data,
      });
    });
  };
  $(function () {
    // whenever we hover over a menu item that has a submenu
    $("li.nav-cat_list").on("mouseover", function () {
      var $menuItem = $(this),
        $submenuWrapper = $("> .wrappereds", $menuItem);

      // grab the menu item's position relative to its positioned parent
      var menuItemPos = $menuItem.position();

      // place the submenu in the correct position relevant to the menu item

      $submenuWrapper.css({
        top: "0px",
        left: menuItemPos.left + Math.round($menuItem.outerWidth() * 1.75),
      });
    });
  });
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        id="home-nav"
      >
        <div className="container-lg">
          <button
            className="navbar-toggler "
            style={{ marginTop: "-5px" }}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <span className="navbar-toggler-icon"> */}
            <BiMenu color="white" size={20} />
            {/* </span> */}
          </button>

          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            style={{ zIndex: "99", background: "#319ffb", padding: "5px" }}
          >
            <ul
              className="navbar-nav w-100"
              style={{ zIndex: "20000", justifyContent: "space-between" }}
            >
              <li className="nav-item active">
                <div
                  className="nav-link nav-categories __titlee _saler cursor-pointer p-2"
                  onClick={() => setOpenDrawer(true)}
                >
                  All Categories
                </div>
                {/* drawer */}
                <Drawer
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                  size="xs"
                  placement="left"
                >
                  <Drawer.Header>
                    <Drawer.Title className="font-weight-bold h3">
                      All Categories
                    </Drawer.Title>
                  </Drawer.Header>
                  <Drawer.Body className="m-0 p-0">
                    <NestedListGroup onClose={() => setOpenDrawer(false)} />
                  </Drawer.Body>
                </Drawer>
              </li>
              <div className="d-inline d-md-block d-lg-flex p-2 p-md-0">
                {history.location.pathname !== "/custom-order" && (
                  <>
                    <li className="nav-item ">
                      <NavLink className="nav-link __titlee _saler" to="/home">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item _saler">
                      <NavLink
                        to="/quotation"
                        className="nav-link __titlee _saler"
                        // onClick={() => setModal(true)}
                      >
                        {navText}
                      </NavLink>
                    </li>
                    <li className="nav-item _saler">
                      <NavLink
                        className="nav-link __titlee _saler"
                        to={"#"}
                        onClick={() =>
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You agree to provide access to kreesa.com to order on your behalf",
                            icon: "warning",
                            showCancelButton: true,
                            showDenyButton: true,
                            confirmButtonColor: "#28a745",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Order By Image",
                            denyButtonText: "Order By Audio",
                            denyButtonColor: "blue",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              history.push(
                                `/${
                                  localStorage.getItem("auth") ===
                                  "ROLE_WHOLESALER"
                                    ? "wholesaler"
                                    : "retailer"
                                }/custom-order/register/image`
                              );
                            } else if (result.isDenied) {
                              history.push(
                                `/${
                                  localStorage.getItem("auth") ===
                                  "ROLE_WHOLESALER"
                                    ? "wholesaler"
                                    : "retailer"
                                }/custom-order/register/audio`
                              );
                            }
                          })
                        }
                      >
                        Custom Order
                      </NavLink>
                    </li>
                    <li className="nav-item _saler">
                      <NavLink className="nav-link __titlee _saler" to="/about">
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item _saler">
                      <NavLink
                        className="nav-link __titlee _saler"
                        to="/contact-us"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li className="nav-item _saler">
                      <NavLink className="nav-link __titlee _saler" to="/news">
                        News
                      </NavLink>
                    </li>
                  </>
                )}
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <QuoteModal modal={modal} setModal={setModal} />
    </div>
  );
}

export default Nav;
