import { createSlice } from "@reduxjs/toolkit";
import { getSingleWholesalerProducts } from "../../Action/WholesalerAction";

const singleRetailerWholesaler = createSlice({
  name: "retailerAndWholesalerProducts",
  initialState: {
    singleWholesalerProducts: [],
    loader: false,
    errors: {},
  },
  reducers: {},
  extraReducers: {
    /* to single Retailer Wholesaler Products in User Page*/
    [getSingleWholesalerProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleWholesalerProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.singleWholesalerProducts = action.payload;
    },
    [getSingleWholesalerProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export default
export default singleRetailerWholesaler.reducer;
