import React, { useRef, useState } from "react";
import "./viewall.css";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Excerpt } from "../../../utils";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addToWishlist,
  checkWishList,
  removeFromWishlist,
} from "../Features/action";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import UserLogin from "../UserLogin/Login";
import { useEffect } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Star from "../Star";

const ViewAllCard = (props) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const location = useLocation();

  const [productLists] = useAutoAnimate();

  const { wishlist } = useSelector((state) => state.userProducts);
  // const [index, setIndex] = useState(0);
  // const [show, setShow] = useState(false);

  // const carousel = useCallback(() => {
  //   setTimeout(() => {
  //     if (index < image.length - 1) {
  //       setIndex(index + 1);
  //     } else {
  //       setIndex(0);
  //     }
  //   }, 1000);
  // }, [index, image.length]);

  // useEffect(() => {
  //   dispatch(
  //     checkWishList({
  //       userId: +localStorage.getItem("userId"),
  //       productId: +wishlist?.data?.id,
  //     })
  //   );
  // }, []);

  const filterHandler = (e) => {
    const value = e.target.value;

    const productsArray = data;

    if (value === "priceHighToLow") {
      const filterPrice = productsArray.slice().sort((a, b) => {
        return b.specification.productPrice - a.specification.productPrice;
      });
      setData(filterPrice);
    } else if (value === "priceLowToHigh") {
      const filterPrice = productsArray.slice().sort((a, b) => {
        return a.specification.productPrice - b.specification.productPrice;
      });
      setData(filterPrice);
    }
    // RATING
    else if (value === "RatingHighToLow") {
      const filterPrice = productsArray.slice().sort((a, b) => {
        return b.review - a.review;
      });
      setData(filterPrice);
    } else if (value === "RatingLowToHigh") {
      const filterPrice = productsArray.slice().sort((a, b) => {
        return a.review - b.review;
      });
      setData(filterPrice);
    }
    //DISCOUNT
    else if (value === "DiscountHighToLow") {
      const filterPrice = productsArray.slice().sort((a, b) => {
        return (
          b.specification.productDiscount - a.specification.productDiscount
        );
      });
      setData(filterPrice);
    } else if (value === "DiscountLowToHigh") {
      const filterPrice = productsArray.slice().sort((a, b) => {
        return (
          a.specification.productDiscount - b.specification.productDiscount
        );
      });
      setData(filterPrice);
    }
  };

  useEffect(() => {
    setData(props?.data);
  }, []);

  return (
    <>
      <div
        // className="d-flex justify-content-end"
        style={{
          marginBottom: 8,
        }}
      >
        <select
          name="sortOne"
          id=""
          className="form-select form-select-lg"
          style={{
            width: 250,
          }}
          onChange={filterHandler}
        >
          <option value="">Sort By</option>

          <optgroup label="Price">
            <option className="font-weight-normal" value="priceHighToLow">
              Price (High To Low)
            </option>
            <option className="font-weight-normal" value="priceLowToHigh">
              Price (Low To High)
            </option>
          </optgroup>

          <optgroup label="Rating">
            <option className="font-weight-normal" value="RatingHighToLow">
              Rating (High To Low)
            </option>
            <option className="font-weight-normal" value="RatingLowToHigh">
              Rating (Low To High)
            </option>
          </optgroup>
          <optgroup label="Discount">
            <option className="font-weight-normal" value="DiscountHighToLow">
              Discount (High To Low)
            </option>
            <option className="font-weight-normal" value="DiscountLowToHigh">
              Discount (Low To High)
            </option>
          </optgroup>
        </select>
      </div>
      <div
        className=""
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        ref={productLists}
      >
        {data?.map((product) => (
          <div className="p-1">
            <div className="__card__ border">
              <div
                className="card__heart"
                onClick={async () => {
                  if (!localStorage.getItem("token")) {
                    setModalVisible(true);
                  } else if (
                    ["ROLE_RETAILER", "ROLE_WHOLESALER"].includes(
                      localStorage.getItem("auth")
                    ) ||
                    ([
                      "ROLE_CEO",
                      "ROLE_MANAGER",
                      "ROLE_RECEPTIONIST",
                      "ROLE_MARKETING_MANAGER",
                    ].includes(localStorage.getItem("auth")) &&
                      location?.state === undefined)
                  ) {
                    toast.info("Login with user account.");
                  } else {
                    if (wishlist?.status === "404") {
                      await dispatch(
                        addToWishlist({
                          userId: +localStorage.getItem("userId"),
                          productId: product.id,
                        })
                      );
                      toast.success("Added to Wishlist");
                    } else {
                      await dispatch(
                        removeFromWishlist({
                          userId: +localStorage.getItem("userId"),
                          id: +wishlist.data.id,
                          productId: product.id,
                        })
                      );
                      toast.error("Removed from Wishlist");
                    }
                  }
                }}
              >
                {wishlist?.status === "200" ? (
                  <i
                    className="fas fa-heart"
                    style={{
                      color: "red",
                    }}
                  ></i>
                ) : (
                  <i className="fas fa-heart"></i>
                )}
              </div>

              <div className="card__image">
                {props?.customdata?.userId ? (
                  <Link
                    to={{
                      pathname: `/single-product/${product?.id}`,
                      key: product?.id,
                      id: product?.id,
                      state: { prevPath: history.location.pathname },
                      slectImage:
                        AxiosConfig.defaults.baseURL +
                        "/kreesa/image/product/" +
                        product?.image?.id +
                        "/" +
                        product?.image?.image,
                    }}
                  >
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                      alt={product?.productName}
                      className="productDet_scale product__image__size_"
                    />
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: `/single-product/${product?.id}`,
                      key: product?.id,
                      id: product?.id,
                      slectImage:
                        AxiosConfig.defaults.baseURL +
                        "/kreesa/image/product/" +
                        product?.image?.id +
                        "/" +
                        product?.image?.image,
                    }}
                  >
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                      alt={product?.productName}
                      className="productDet_scale product__image__size_"
                    />
                  </Link>
                )}
              </div>
              <div className="productDet">
                <div className="card__details">
                  <p className="title"></p>
                  <Link
                    to={{
                      pathname: `/single-product/${product?.id}`,
                      state: {
                        prevPath: history.location.pathname,
                      },
                    }}
                    style={{
                      color: "#c762d7",
                    }}
                  >
                    <p className="title text-capitalize">
                      {Excerpt(product?.productName, 18)}
                    </p>
                  </Link>

                  {/* <span className="span1">Rs{offerPrice}</span>
          <span className="span2">Rs{actualPrice}</span> */}
                  <span className="span3">
                    - {product.specification?.productDiscount} % off
                  </span>
                </div>
                <div className="card__size">
                  <p>
                    {product?.specification?.productDiscount === 0 ? (
                      <span className="span2">
                        {" "}
                        Rs {Math.round(product?.specification?.productPrice)}
                      </span>
                    ) : (
                      <>
                        <strike
                          className="span1"
                          // style={{
                          //   color: "red",
                          // }}
                        >
                          Rs {Math.round(product?.specification?.productPrice)}
                        </strike>
                        <span
                          className="span2"
                          style={{
                            // color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Rs{" "}
                          {Math.round(
                            product?.specification?.productPrice -
                              (product?.specification?.productPrice *
                                product?.specification?.productDiscount) /
                                100
                          )}
                        </span>
                      </>
                    )}

                    {product?.specification?.productDiscount === 0 ? (
                      ""
                    ) : (
                      <span
                        className="span3"
                        style={{
                          color: "#f57f17",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Save{" "}
                        {Math.round(
                          Math.abs(
                            product?.specification?.productPrice -
                              product?.specification?.productPrice -
                              (product?.specification?.productPrice *
                                product?.specification?.productDiscount) /
                                100
                          )
                        )}
                      </span>
                    )}
                    <Star value={product.review} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Modal
          style={{ zIndex: 999999 }}
          show={modalVisible}
          centered
          size="lg"
          onHide={() => setModalVisible(false)}
        >
          <Modal.Body>
            <UserLogin modal="true" modalVisible={setModalVisible} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalVisible(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ViewAllCard;
