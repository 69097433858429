import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to approve Order in Admin Page*/
export const approveOrder = createAsyncThunk("approveOrder", async (data) => {
  // approveOrder/pending
  // approveOrder/fulfilled
  // approveOrder/rejected
  const response = await AxiosConfig.get(
    `/kreesa/admin/order/approve-order/${data.id}`,
    config
  )
    .then((res) => {
      toast.success("Order has been approved");
      data.history.go();
    })
    .catch((err) => toast.error("An Error Occurred"));
  return response.data;
});

/* to assign Order in Admin Page*/
export const assignOrder = createAsyncThunk("assignOrder", async (orderId) => {
  // assignOrder/pending
  // assignOrder/fulfilled
  // assignOrder/rejected
  const response = await AxiosConfig.get(`${orderId}`, config);
  return response.data;
});

/* to reject Order in Admin Page*/
export const rejectOrder = createAsyncThunk("rejectOrder", async (data) => {
  // rejectOrder/pending
  // rejectOrder/fulfilled
  // rejectOrder/rejected
  const response = await AxiosConfig.get(
    `/kreesa/admin/order/decline-order/${data.id}`,
    config
  )
    .then((res) => {
      toast.success("Order has been Rejected");
      data.history.go(0);
    })
    .catch((err) => toast.error("An Error Occurred"));
  return response.data;
});

/* ---------- wholesaler approve order---------- */
export const WholesaleApproveOrder = createAsyncThunk(
  "WholesaleApproveOrder",
  async (data, { dispatch }) => {
    // rejectOrder/pending
    // rejectOrder/fulfilled
    // rejectOrder/rejected
    const response = await AxiosConfig.get(
      `/kreesa/wholesaler/order/${data.id}/order-status/Approved`,
      config
    ).then((res) => {
      toast.success(res.data);
      data.history.go(0);
    });
    // .catch((err) => toast.error("An Error Occurred"));

    return response.data;
  }
);
/* ---------- wholesaler reject order ---------- */

export const WholesaleRejectOrder = createAsyncThunk(
  "WholesaleRejectOrder",
  async (data, { dispatch }) => {
    // rejectOrder/pending
    // rejectOrder/fulfilled
    // rejectOrder/rejected
    const response = await AxiosConfig.get(
      `/kreesa/wholesaler/order/${data.id}/order-status/Rejected`,
      config
    ).then((res) => {
      toast.success(res.data);
      data.history.go();
    });

    return response.data;
  }
);
/* ----------------------------- SellersConfirmDelivery ---------------------------- */

export const SellersConfirmDelivery = createAsyncThunk(
  "SellersConfirmDelivery",
  async (data, { dispatch }) => {
    // SellersConfirmDelivery/pending
    // SellersConfirmDelivery/fulfilled
    // SellersConfirmDelivery/rejected
    const response = await AxiosConfig.get(
      `/kreesa/wholesale/delivery/confirm-delivery/${data.id}`,
      config
    ).then((res) => {
      toast.success(res.data);
      data.history.go(0);
    });
    return response.data;
  }
);
// to get all orders
export const getAllWholesaleOrder = createAsyncThunk(
  "getAllWholesaleOrder",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/order/view-all/Wholesale`,
      config
    );
    return response.data;
  }
);
export const getAllRetailOrder = createAsyncThunk(
  "getAllRetailOrder",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/order/view-all/Retail`,
      config
    );
    return response.data;
  }
);
export const getAllDeliveryBoy = createAsyncThunk(
  "getAllDeliveryBoy",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/get-all-delivery-boy`,
      config
    );
    return response.data;
  }
);
export const AssignToDeliveryBoy = createAsyncThunk(
  "AssignToDeliveryBoy",
  async (datas) => {
    const response = await AxiosConfig.post(
      `/kreesa/admin/assign/delivery`,
      datas
    );
    if (response && response.status === 200) {
      toast.success(response.data);
      setTimeout(() => {
        datas?.history?.go();
      }, 500);
    }
    return response.data;
  }
);

export const AssignQuoteToDeliveryBoy = createAsyncThunk(
  "AssignQuoteToDeliveryBoy",
  async (datas) => {
    const response = await AxiosConfig.post(
      `/kreesa/admin/delivery/assign-quote-delivery`,
      datas
    );
    if (response && response.status === 200) {
      toast.success(response.data);
      setTimeout(() => {
        datas?.history?.go();
      }, 500);
    }
    return response.data;
  }
);

export const changePaymentStatus = createAsyncThunk(
  "changePaymentStatus",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/order/make-amount-paid/${data.orderId}`
    );
    if (response && response.status === 200) {
      data.type === "retail"
        ? dispatch(getAllRetailOrder())
        : dispatch(getAllWholesaleOrder());
      toast.success(response.data);
    } else {
      toast.err("An Error Occurred");
    }
    return response.data;
  }
);

/* ---------------------------- return order here --------------------------- */
export const getAllReturnOrder = createAsyncThunk(
  "getAllAdminReturnOrder",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/return/get-all-return-request`,
      config
    );
    return response.data;
  }
);

/* ----------------------------- AdminConfirmDelivery ---------------------------- */

// export const AdminConfirmDelivery = createAsyncThunk("AdminConfirmDelivery", async (orderId) => {
//   // AdminConfirmDelivery/pending
//   // AdminConfirmDelivery/fulfilled
//   // AdminConfirmDelivery/rejected
//     const { data } = await AxiosConfig.get(`/kreesa/admin/delivery/confirm-delivery/${orderId}`, config);
//     return data;
// });

export const AdminConfirmDelivery = createAsyncThunk(
  "AdminConfirmDelivery",
  async (orderData, { rejectWithValue }) => {
    try {
      const { data } = await AxiosConfig.get(
        `/kreesa/admin/delivery/confirm-delivery/${orderData.id}/${orderData.status}`,
        config
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
