import React, { useEffect } from "react";
import "./Product.css";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Rating from "react-rating";
import { useDispatch, useSelector } from "react-redux";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import {
  getWholesalerProducts,
  WholesaleFeatureProduct,
} from "../Features/Action/WholesalerAction";
import ProductCard from "./productCard";
import HtmlParser from "react-html-parser";
import { Excerpt } from "../../../utils";
import { useHistory } from "react-router-dom";
import {
  featureProductForUser,
  getAllRetailerFlashSale,
  getAllRetailerKreesaProducts,
  getRetailerBrandProducts,
  getRetailerFlashSale,
  getRetailerOrganicProducts,
  getRetailerProducts,
  getRetailerTrendingProducts,
} from "../../User/Features/action";
import SellerCard from "./SellerCard";
import SellerProductGrid from "./SellerProductGrid";

const ProductList = () => {
  const { wholesalerProducts, wholesaleFeature, loader } = useSelector(
    (s) => s.WholesalerReducer
  );

  const history = useHistory();
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    dispatch(getWholesalerProducts());
    dispatch(WholesaleFeatureProduct());
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  /* -------------------------------------------------------------------------- */
  /*                                     new                                    */
  /* -------------------------------------------------------------------------- */
  const {
    userpageProducts,
    userFeature,
    trendingProducts,
    organicProducts,
    kreesaProducts,
    allKreesaProducts,
    brandProducts,
    allFlashProducts,
  } = useSelector((state) => state.userProducts);

  // featured products
  useEffect(() => {
    dispatch(getRetailerProducts("wholesale"));
    dispatch(featureProductForUser("wholesale"));
    dispatch(getRetailerTrendingProducts("wholesale"));
    dispatch(getRetailerOrganicProducts("wholesale"));
    // dispatch(getRetailerKreesaProducts("wholesale"));
    dispatch(getRetailerBrandProducts("wholesale"));
    // to get all kreesa products

    dispatch(getAllRetailerFlashSale("wholesale"));
    dispatch(getRetailerFlashSale("wholesale"));
  }, []);

  return !loader ? (
    <div className="container-fluid">
      {/*  ----------------------------- Feature Product -----------------------------  */}

      {/* {wholesaleFeature?.length > 0 && (
        <div className="bestsellers-mains">
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontWeight: "bold",
            }}
          >
            Feature Product
          </h3>
          <Carousel
            responsive={responsive}
            containerclassName="carousel-container carousel-cont"
            itemclassName="carousel-item-padding-100-px"
          >
            {wholesaleFeature?.map((product, i) => (
              <div key={i} className="ml-4">
                <Link
                  to={
                    history?.location?.state?.prevPath === "/custom-order" ||
                    history.location.pathname.includes("/custom-order")
                      ? `/custom-order/product/${product.id}`
                      : `/single-product-page/${product.id}`
                  }
                >
                  <ProductCard
                    title={product?.productName}
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                    price={product?.specification?.productPrice}
                    discount={product?.specification?.productDiscount}
                  />
                </Link>
              </div>
            ))}
          </Carousel>
        </div>
      )} */}
      {/*  ----------------------------- Recent Product -----------------------------  */}
      {/* {wholesalerProducts?.length > 0 && (
        <>
          <h2 className="text-center text-uppercase pt-6 font-weight-bold">
            Recent Products
          </h2>
          <div className="container-fluid mt-4">
            <div className="row">
              {wholesalerProducts?.map((product) => (
                <div className="col-sm-6 col-md-3">
                  <article className="product__cards__wrapper">
                    <Link
                      to={
                        history?.location?.state?.prevPath ===
                          "/custom-order" ||
                        history.location.pathname.includes("/custom-order")
                          ? `/custom-order/product/${product.id}`
                          : `/single-product-page/${product.id}`
                      }
                      className="product__images__holder cursor-pointer"
                    >
                      <p className="product__images__holder__link"></p>
                      <div
                        className="image-liquid product__images__holder--original"
                        style={{
                          backgroundImage: `url(${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName})`,
                        }}
                      ></div>
                    </Link>

                    <div className="products__description">
                      <h1 className="products__description__title">
                        <p className="text-capitalize">
                          {product?.productName}
                        </p>
                      </h1>

                      <div className="row">
                        <div className="col-xs-12 col-sm-8 products__description__category product__secondary__text">
                          <p>
                            {HtmlParser(Excerpt(product?.productHighlight, 60))}
                          </p>
                        </div>
                        <div className="col-xs-12 col-sm-4 products__description__price">
                          {product?.specification?.productDiscount === 0 ? (
                            <p className="text-success mt-1">
                              <b>
                                Rs.{" "}
                                {Math.round(
                                  product?.specification?.productPrice
                                )}
                              </b>
                            </p>
                          ) : (
                            <p className="text-center font-weight-bold">
                              <strike
                                style={{
                                  fontSize: 16,
                                }}
                              >
                                Rs.{" "}
                                {Math.round(
                                  product?.specification?.productPrice
                                )}
                              </strike>
                              <br />
                              <b className="text-success">
                                {" "}
                                Rs.{" "}
                                {Math.round(
                                  product?.specification?.productPrice -
                                    (product?.specification?.productPrice *
                                      product?.specification?.productDiscount) /
                                      100
                                )}
                              </b>
                              <br />
                              <strong className="text-danger product__price__off font-weight-bold">
                                {" "}
                                - {product?.specification?.productDiscount} %
                                OFF
                              </strong>
                            </p>
                          )}
                        </div>
                      </div>

                      <hr className="product__horizontal_line" /> */}
      {/* <div
                        style={{ margin: 0, padding: "0 10px" }}
                        onClick={() =>
                          dispatch(getSingleWholesalerProducts(product?.id))
                        }
                      >
                        <QuickViewButton />
                      </div> */}
      {/* </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </>
      )} */}

      {/*  -------------------------------------------------------------------------- */
      /*                                     new                                    */
      /* --------------------------------------------------------------------------  */}

      {/* Flash products */}
      {allFlashProducts?.length > 0 && (
        <SellerProductGrid title="Flash Sale" data={allFlashProducts} />
      )}

      {wholesalerProducts?.length > 0 && (
        <SellerProductGrid title="Recent Products" data={wholesalerProducts} />
      )}
      {/* Feature products */}
      {wholesaleFeature?.length > 0 && (
        <SellerCard title="Feature Products" data={wholesaleFeature} />
      )}
      {/* Brand products */}
      {brandProducts?.length > 0 && (
        <SellerCard title="Brand products" data={brandProducts} />
      )}
      {/* Trending products */}
      {trendingProducts?.length > 0 && (
        <SellerCard title="Trending Products" data={trendingProducts} />
      )}
      {/* Organic priducts */}
      {organicProducts?.length > 0 && (
        <SellerCard title="Organic Products" data={organicProducts} />
      )}
      {/* kreesa products */}
      {allKreesaProducts?.length > 0 && (
        <SellerProductGrid title="Kreesa Products" data={allKreesaProducts} />
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default ProductList;
