import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVacancy } from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";
import "./style.css";
import moment from "moment";
import HtmlParser from "react-html-parser";

const ReviewVacancyDetail = () => {
  document.title = "Admin | Vacancy";
  const dispatch = useDispatch();

  const { id } = useParams();
  const { vacancy, loader } = useSelector((s) => s.AdminReducer);
  // to get single vacancy
  useEffect(() => {
    dispatch(getVacancy(id));
  }, [id]);

  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <section
                id="sectionJobList"
                className="section section-job-list gradient-light--lean-left"
              >
                <div className="row row-grid justify-content-center">
                  <div className="col-md-8 col-lg-7 col-xl-6 text-center">
                    <h2 className="section__title mb-4 text-capitalize">
                      Jobs Openings at {vacancy?.jobLocation}
                    </h2>

                    <p>
                      We’re always searching for{" "}
                      <span className="font-weight-bold h4">
                        {vacancy?.openVacancies}
                      </span>{" "}
                      peoples to join our team. Take a look at our current
                      openings.
                    </p>
                  </div>

                  <div className="job-list__wrapper mb-6">
                    <h4 className="mb-4 text-capitalize">{vacancy?.jobType}</h4>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-md-4 text-uppercase">
                    {vacancy?.title}
                  </div>
                  <div className="col-md-4">
                    <i className="fas fa-clock mr-1"></i>{" "}
                    {moment(vacancy?.deadline).format("lll")}
                  </div>
                  <div className="col-md-4">
                    <i className="fas fa-map-marker-alt mr-1"></i>{" "}
                    {vacancy?.jobLocation}
                  </div>
                </div>
                <div className="row mt-6">
                  <div className="col-md-6">
                    <p className="h3 text-center">Description </p>
                    {HtmlParser(vacancy?.jobDescription)}
                  </div>
                  <div className="col-md-6">
                    <p className="h3 text-center">Requirements </p>
                    {HtmlParser(vacancy?.requirement)}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ReviewVacancyDetail;
