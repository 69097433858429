import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFeature,
  retailWholesaleFeatureProduct,
} from "../../Features/Action/FeatureProductAction";
import Spinner from "../../Spinner";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { Link } from "react-router-dom";
import moment from "moment";
import { Pagination } from "@mantine/core";

const FeatureWholesale = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  const { featureProduct, loader } = useSelector((s) => s.FeatureReducer);
  const dispatch = useDispatch();

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = featureProduct?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(featureProduct?.length / perPage);

  useEffect(() => {
    dispatch(retailWholesaleFeatureProduct("Wholesale"));
  }, []);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              {/* <Link
                to="/admin/primary-category/register"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add Category <i className="fas fa-bezier-curve ml-2"></i>
              </Link> */}
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Wholesaler's Feature Product…"
                    aria-label="Search"
                    // ref={inputRef}
                    // onChange={filterCategory}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Feature Products </h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Products Name</th>
                    {/* <th>Status</th> */}
                    <th>Category</th>
                    <th>Sell By</th>
                    <th>Publish Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${p.image.id}/${p.image?.imageName}`}
                          width="48"
                          height="48"
                          loading="lazy"
                          className="rounded-circle mr-2"
                          alt={p.productName}
                        />
                        {p.productName}
                      </td>

                      {/* <td>
                        <span className="badge bg-info">Pending</span>
                      </td> */}
                      <td>
                        {p.primaryCategoryName}/{p.secondaryCategoryName}/
                        {p.ternaryCategoryName}
                      </td>
                      <td>{p.companyName}</td>
                      <td>
                        {p.isPublish ? (
                          <span className="badge bg-success">Published</span>
                        ) : (
                          <span className="badge bg-danger">Unpublished</span>
                        )}
                      </td>
                      <td>{moment(p.createdAt).format("ll")}</td>
                      <td>{moment(p.updatedAt).format("ll")}</td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            {localStorage.getItem("auth") === "ROLE_CEO" && (
                              <>
                                <Link
                                  to={`/admin/product/edit/${p.id}`}
                                  className=" text-info"
                                >
                                  <i className="fas fa-user-edit"></i> Edit
                                </Link>
                                <div className="dropdown-divider" />
                                <Link
                                  to={`/admin/product/view/retail/${p.id}`}
                                  className=" text-success"
                                >
                                  <i className="fas fa-eye "></i> Review
                                </Link>
                                <div className="dropdown-divider" />
                              </>
                            )}
                            <div className="dropdown-divider" />
                            <p
                              onClick={async () => {
                                await dispatch(removeFeature(p.id));
                                dispatch(
                                  retailWholesaleFeatureProduct("Wholesale")
                                );
                              }}
                              className=" text-danger cursor-pointer"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>{" "}
                              Remove from Feature
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Feature Product Found !</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default FeatureWholesale;
