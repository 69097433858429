import { convertToRaw, EditorState } from "draft-js";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import * as Yup from "yup";
import useButtonLoader from "../../../../ButtonLoaderHook";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner";

const validationSchema = Yup.object().shape({
  provience_name: Yup.string().required("Provience Name is required"),
  country_id: Yup.number().required("Country Name is required"),
  status: Yup.string().required("Status is required"),
});

const ProvinceForm = () => {
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add Province",
    "Loading..."
  );
  const history = useHistory();

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  const ContryHandler = async (values, submitProps) => {
    setButtonLoader(true);

    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const des = draftToHtml(convertToRaw(description.getCurrentContent()));
      const res = await AxiosConfig.post(
        "/kreesa/admin/address/save-province",
        {
          ...values,
          description: des,
        },
        config
      );
      if (res && res.status === 200) {
        toast.success("Province added successfully");
        setButtonLoader(false);
        history.push("/admin/province");
      }
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };
  // to get all countries
  const { countries, loader } = useSelector((s) => s.AddressReducer);
  return !loader ? (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title font-weight-bold">Add Province Form</h5>
          <h6 className="card-subtitle text-muted">
            {" "}
            You Can Easily Add New Province.
          </h6>
        </div>
        <div className="card-body">
          <Formik
            initialValues={{
              country_id: "",
              provience_name: "",
              status: true,
            }}
            onSubmit={ContryHandler}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="col">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Country Name</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <select
                      id="inputState"
                      required
                      name="country_id"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Select Country First...</option>
                      {countries?.map((p, i) => (
                        <option key={i} value={p.id}>
                          {p.country_name}
                        </option>
                      ))}
                    </select>
                    {errors.country_id && touched.country_id ? (
                      <div style={{ color: "red" }}> {errors.country_id} </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Province Name</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="provience_name"
                      id="inputCategory"
                      onChange={handleChange}
                      value={values.provience_name}
                      placeholder="Enter Province Name"
                    />
                    {errors.provience_name && touched.provience_name ? (
                      <div style={{ color: "red" }}>
                        {" "}
                        {errors.provience_name}{" "}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3 mt-2 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Status</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-check-input ml-2"
                      type="radio"
                      name="status"
                      value={true}
                      onChange={handleChange}
                      checked={values.status === true ? true : null}
                      id="exampleRadios1"
                    />
                    <label
                      className="form-check-label pl-2"
                      htmlFor="exampleRadios1"
                    >
                      {" "}
                      Active
                    </label>
                    <input
                      className="form-check-input ml-2"
                      type="radio"
                      name="status"
                      value={false}
                      onChange={handleChange}
                      id="exampleRadios2"
                    />
                    <label
                      className="form-check-label pl-2"
                      htmlFor="exampleRadios2"
                    >
                      Inactive
                    </label>
                    {errors.status && touched.status ? (
                      <div style={{ color: "red" }}> {errors.status} </div>
                    ) : null}
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 col-md-8">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">
                        Country Description
                      </span>
                      <span className="text-info"> (optional)</span>
                    </label>
                    <Editor
                      editorState={description}
                      onEditorStateChange={onEditorStateChange}
                      editorStyle={{
                        backgroundColor: "white",
                        minHeight: "150px",
                        padding: "10px 20px",
                      }}
                      editorClassName="mt-2 p-10 mx-auto"
                    />
                  </div>
                </div>
                <div className="mb-3 mt-4 col-md-4">
                  <button
                    id="catadd"
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-success btn-lg btn-block"
                    ref={buttonLoader}
                  >
                    Add Province
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ProvinceForm;
