import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../../Pagination";
import {
  deleteTernaryCategory,
  getTernaryCategory,
} from "../../Features/Action/CategoryAction/CategoryAction";
import moment from "moment";
import Spinner from "../../Spinner";
import { filterTernaryCategory } from "../../Features/Reducer/CategoriesReducer/TernaryCategoryReducer";
import HtmlParser from "react-html-parser";
import { Excerpt } from "../../../../utils";

const TernaryCategoryDetail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const inputRef = useRef("");

  const dispatch = useDispatch();

  const filterCategory = () => {
    dispatch(filterTernaryCategory(inputRef.current.value));
  };
  // to get ternary Category
  const { ternaryCategories, loader } = useSelector((s) => s.ternaryCategory);

  useEffect(() => {
    dispatch(getTernaryCategory());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = ternaryCategories.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(ternaryCategories.length / perPage);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link
                to="/admin/ternary-category/register"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add Ternary Category{" "}
                <i className="fas fa-bezier-curve ml-2"></i>
              </Link>
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Ternary Category…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterCategory}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Ternary Categories</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Category Name</th>
                    <th>Description</th>
                    <th className="d-xl-table-cell">Start Date</th>
                    <th className="d-xl-table-cell">Created By</th>
                    <th>Status</th>
                    <th className="d-md-table-cell">Last Modified by</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>{p.ternaryCategoryName}</td>
                      <td>{HtmlParser(Excerpt(p.description, 40))}</td>
                      <td className="d-xl-table-cell">
                        {moment(p.creationDate).format("ll")}
                      </td>
                      <td className="d-xl-table-cell">{p.createdBy}</td>
                      {/* status */}
                      {p.ternaryCategoryStatus === 0 ? (
                        <td>
                          <span className="badge bg-danger">Inactive</span>
                        </td>
                      ) : (
                        <td>
                          <span className="badge bg-success">Active</span>
                        </td>
                      )}
                      {/* status */}
                      <td className="d-md-table-cell">{p.lastModifiedBy}</td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              className="dropdown-item text-info"
                              to={`/admin/ternary-category/edit/${p.id}`}
                            >
                              <i className="fas fa-user-edit"></i> Edit
                            </Link>
                            <div className="dropdown-divider"></div>
                            <button
                              className="dropdown-item text-danger"
                              onClick={() =>
                                dispatch(deleteTernaryCategory(p.id))
                              }
                            >
                              <i className="fas fa-trash-alt"></i> Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={ternaryCategories}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Ternary Category Found !</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default TernaryCategoryDetail;
