import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mantine/core";
import {
  addImplementedTree,
  fetchUnimplementedTree,
} from "../Features/Action/TreeAction";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import DatePicker from "react-datepicker";
import useButtonLoader from "../../ButtonLoaderHook";
import { toast } from "react-toastify";
import { validationSchema } from "./validationSchema";
import Spinner from "../Spinner";

const Unimplemented = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState("asc");
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "SUBMIT",
    "Loading..."
  );
  const [perPage] = useState(7);
  const { loader, unimplemented } = useSelector((s) => s.TreeReducer);
  const dispatch = useDispatch();

  const treeHandler = async (values) => {
    setButtonLoader(true);
    try {
      const res = await dispatch(
        addImplementedTree({
          ...values,
          unimplement_tree_id: modalData.id,
        })
      );
      if (res) {
        toast.success(res.payload);
      }
      setModal(false);
      setButtonLoader(false);
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = unimplemented?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(unimplemented?.length / perPage);

  const toggleSortDirection = () => {
    const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortDirection);
  };

  const sortEntriesByDate = () => {
    const sortedEntries = currentEntries.sort((a, b) => {
      const dateA = moment(a.purchase_date, "ddd MMM DD HH:mm:ss [CEST] YYYY");
      const dateB = moment(b.purchase_date, "ddd MMM DD HH:mm:ss [CEST] YYYY");
      if (sortDirection === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    return sortedEntries;
  };

  const sortedEntries = sortEntriesByDate();

  useEffect(() => {
    dispatch(fetchUnimplementedTree());
  }, [dispatch]);

  return !loader ? (
    <div className="container">
      {/* <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Unimplemented Tree..."
                    aria-label="Search"
                    // ref={inputRef}
                    // onChange={filterReviews}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/*  */}
      <div className="row">
        {sortedEntries?.length > 0 ? (
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Unimplemented Trees</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th
                      className="d-xl-table-cell"
                      onClick={toggleSortDirection}
                      style={{ cursor: "pointer" }}
                    >
                      Purchased Date{" "}
                      {sortDirection === "asc" ? (
                        <i className="fa fa-chevron-up" />
                      ) : (
                        <i className="fa fa-chevron-down" />
                      )}
                    </th>
                    <th className="d-xl-table-cell">User Name</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>

                      <td>
                        {moment(
                          p.purchase_date,
                          "ddd MMM DD HH:mm:ss [CEST] YYYY"
                        ).format("ll")}
                      </td>
                      <td>
                        <a href={`mailto:${p.user?.email}`}>{p.user?.email}</a>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            {/* <button className="dropdown-item text-success">
                              <i className="fa fa-eye"></i> Quick View
                            </button>
                            <div className="dropdown-divider" /> */}
                            <div
                              className="dropdown-item text-info cursor-pointer"
                              onClick={() => {
                                setModal(true);
                                setModalData(p);
                              }}
                            >
                              <i className="fa fa-check"></i> Planted Tree
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal
                show={modal}
                onHide={() => setModal(false)}
                size="lg"
                centered
              >
                <Modal.Header>
                  <Modal.Title className="font-weight-light text-center w-100 d-flex justify-content-between">
                    <div>Add Planted Tree</div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setModal(false)}
                    >
                      X
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <PlantTreeForm {...{ modalData }} setModal={modal} /> */}
                  <Formik
                    initialValues={{
                      address_of_plant: "",
                      date_of_plantation: new Date(),
                      latitude_longitude: "",
                      type_of_plant: "",
                    }}
                    onSubmit={treeHandler}
                    validationSchema={validationSchema}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      isValid,
                      handleBlur,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit} autoComplete={false}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={values.address_of_plant}
                            onChange={handleChange}
                            name="address_of_plant"
                            placeholder="Address Of Plant*"
                          />
                        </div>
                        {errors.address_of_plant && touched.address_of_plant ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {errors.address_of_plant}{" "}
                          </div>
                        ) : null}
                        <div className="form-group mt-3">
                          <DatePicker
                            className="form-control"
                            name="deadline"
                            format="YYYY-MM-DD HH:mm:ss"
                            minDate={values.date_of_plantation}
                            selected={values.date_of_plantation}
                            onChange={(date) =>
                              setFieldValue("date_of_plantation", date)
                            }
                          />
                        </div>
                        {errors.date_of_plantation &&
                        touched.date_of_plantation ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {errors.date_of_plantation}{" "}
                          </div>
                        ) : null}
                        <div className="form-group mt-3">
                          <input
                            type="tel"
                            className="form-control"
                            value={values.type_of_plant}
                            onChange={handleChange}
                            name="type_of_plant"
                            placeholder="Type of plant*"
                          />
                        </div>
                        {errors.type_of_plant && touched.type_of_plant ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {errors.type_of_plant}{" "}
                          </div>
                        ) : null}
                        <div className="form-group mt-3">
                          <input
                            className="form-control"
                            value={values.latitude_longitude}
                            onChange={handleChange}
                            name="latitude_longitude"
                            placeholder="latitude longitude*"
                          />
                        </div>
                        {errors.latitude_longitude &&
                        touched.latitude_longitude ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {errors.latitude_longitude}{" "}
                          </div>
                        ) : null}
                        <div>
                          <button
                            type="submit"
                            ref={buttonLoader}
                            onClick={handleSubmit}
                            className="btn btn-lg mt-3 px-4 btn-info"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3>No Unimplemented Tree Found !</h3>
          </div>
        )}
        {/* pagination  */}
        {currentEntries?.length > 0 && (
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Unimplemented;
