import { useEffect, useState } from "react";
import "./style.css";

const Pagination = ({ pages, setCurrentPage, currentEntries, allEntries }) => {
  const numOfPages = [];

  for (let i = 1; i <= pages; i++) {
    numOfPages.push(i);
  }

  const [currentButton, setCurrentButton] = useState(1);

  useEffect(() => {
    setCurrentPage(currentButton);
  }, [currentButton, setCurrentPage]);

  return (
    <>
      {allEntries.length >= 1 && (
        <div className="card-body">
          <div className="clearfix">
            <div className="hint-text">
              Showing <b>{currentEntries.length}</b> Out of{" "}
              <b>{allEntries.length}</b> Entries...
            </div>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination pagination-md">
              <li
                className={`${
                  currentButton === 1
                    ? "page-item disabled-buttons"
                    : "page-item"
                }`}
              >
                <div
                  className="page-link cursor-pointer"
                  onClick={() =>
                    setCurrentButton((prev) => (prev === 1 ? prev : prev - 1))
                  }
                >
                  &laquo;
                </div>
              </li>
              {numOfPages.map((page, index) => {
                return (
                  <li
                    key={index}
                    className={`${
                      currentButton === page ? "page-item active" : "page-item"
                    }`}
                  >
                    <div
                      className="page-link cursor-pointer"
                      onClick={() => setCurrentButton(page)}
                    >
                      {page}
                    </div>
                  </li>
                );
              })}
              <li
                className={`${
                  currentButton === numOfPages.length
                    ? "page-item disabled-buttons"
                    : "page-item"
                }`}
              >
                <div
                  className="page-link cursor-pointer"
                  onClick={() =>
                    setCurrentButton((next) =>
                      next === numOfPages.length ? next : next + 1
                    )
                  }
                >
                  &raquo;
                </div>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default Pagination;
