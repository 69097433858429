import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../../Pagination";
import { getGroupProduct } from "../../Features/Action/GroupProductAction";
import { filterGroupProduct } from "../../Features/Reducer/GroupProductReducer";
import Spinner from "../../Spinner";

const ShowGroupProductDetail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const inputRef = useRef("");

  const dispatch = useDispatch();

  const filterGroup = () => {
    dispatch(filterGroupProduct(inputRef.current.value));
  };
  // to get secondary Category
  const { GroupProduct, loader } = useSelector((s) => s.GroupProduct);

  useEffect(() => {
    dispatch(getGroupProduct());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = GroupProduct.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(GroupProduct.length / perPage);
  return !loader ? (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex w-100">
            <div className="card flex-fill flex-fill">
              <div className="card-header">
                <Link
                  to="/admin/group-product/register"
                  className="btn btn-lg btn-outline-info display-5 mt-2"
                  style={{ float: "right" }}
                >
                  Add New Group Product
                </Link>
                <form className="d-none mt-2 d-sm-inline-block">
                  <div className="input-group input-group-navbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Group Product…"
                      aria-label="Search"
                      ref={inputRef}
                      onChange={filterGroup}
                    />
                    <button className="btn" type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {currentEntries?.length > 0 ? (
          <div className="row">
            <div className="col-12 col-lg-8 d-flex w-100">
              <div className="card flex-fill overflow-auto">
                <div className="card-header">
                  <h5 className="card-title mb-0">All Group Products</h5>
                </div>
                <table className="table table-hover my-0 ">
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Group Product Name</th>
                      <th>Group Product SKU</th>
                      <th>Status</th>
                      <th className="d-md-table-cell">Created By</th>
                      <th className="d-md-table-cell">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries?.map((p, i) => (
                      <tr key={i}>
                        <td>{perPage * (currentPage - 1) + i + 1}</td>
                        <td>{p.groupName}</td>
                        <td className="d-xl-table-cell">{p.groupSKU}</td>
                        <td>
                          <span className="badge bg-success">Active</span>
                        </td>
                        <td className="d-md-table-cell">kreesa</td>
                        <td>
                          <div className="btn-group">
                            <button
                              type="button"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#3b7ddd",
                              }}
                              className="btn-md btn btn-success dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More
                            </button>
                            <div className="dropdown-menu text-center">
                              <Link
                                className="dropdown-item text-success"
                                to={`/admin/group-product/view/${p.id}`}
                              >
                                <i className="fas fa-user-edit"></i> Edit
                              </Link>
                              {/* <div className="dropdown-divider"></div>
                              <div className="dropdown-item text-danger">
                                <i className="fas fa-trash-alt text-danger"></i>{" "}
                                Delete
                              </div> */}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* pagination  */}
            <Pagination
              pages={totalPagesNum}
              setCurrentPage={setCurrentPage}
              currentEntries={currentEntries}
              allEntries={GroupProduct}
            />
          </div>
        ) : (
          <div className="text-center">
            <h3>No Group Product Found !</h3>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ShowGroupProductDetail;
