import React from "react";
import { Switch } from "react-router-dom";
import Home from "../../Components/RetailerWholeSaler/Home";
import Cart from "../../Components/RetailerWholeSaler/Cart";
import SinglProductPage from "../../Components/RetailerWholeSaler/SingleProductPage";
import ContactUs from "../../Components/RetailerWholeSaler/ContactUs";
import OrderNow from "../../Components/RetailerWholeSaler/OrderNow";
import SearchPage from "../../Components/RetailerWholeSaler/searchPage";
import SalerRouting from "../PrivateRoute/salerRouting";
import AboutUs from "../../Components/RetailerWholeSaler/AboutUs";
import TermsandConditions from "../../Components/RetailerWholeSaler/TermsandConditions";
import Checkout from "../../Components/User/Checkout";
import SellerViewAll from "../../Components/RetailerWholeSaler/ProductList/SellerViewAll";

function RetailerWholeSaler() {
  return (
    <Switch>
      {/* Saler */}
      <SalerRouting exact path="/home" component={Home} />

      {/* Cart */}
      <SalerRouting exact path="/seller/cart" component={Cart} />
      <SalerRouting exact path="/seller/search-page" component={SearchPage} />
      <SalerRouting exact path="/search-page" component={SearchPage} />
      {/* single product page */}
      <SalerRouting
        exact
        path="/single-product-page/:id"
        component={SinglProductPage}
      />

      <SalerRouting exact path="/checkout-seller" component={Checkout} />
      <SalerRouting exact path="/order-now" component={OrderNow} />
      <SalerRouting exact path="/about-us" component={AboutUs} />
      <SalerRouting exact path="/contact-us" component={ContactUs} />
      <SalerRouting
        exact
        path="/terms-conditions"
        component={TermsandConditions}
      />
      <SalerRouting
        exact
        path="/seller-view-all/:title"
        component={SellerViewAll}
      />
    </Switch>
  );
}

export default RetailerWholeSaler;
