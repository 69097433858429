import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to get all Products Of Retailer in Admin Page*/
export const getProductsOfRetailer = createAsyncThunk(
  "getProductsOfRetailer",
  async () => {
    const response = await AxiosConfig.get(
      "/kreesa/admin/product/view-all/Retail",
      config
    );
    return response.data;
  }
);

/* to get all Products Of Wholesale in Admin Page*/
export const getProductsOfWholesaler = createAsyncThunk(
  "getProductsOfWholesaler",
  async () => {
    const response = await AxiosConfig.get(
      "/kreesa/admin/product/view-all/Wholesale",
      config
    );
    return response.data;
  }
);

/* to get single Products Of Wholesale in Admin Page*/
export const getWholesalerReviewById = createAsyncThunk(
  "getWholesalerReviewById",
  async (id, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/product/view/${id}`,
      config
    );
    dispatch(getProductsOfWholesaler());
    dispatch(getProductsOfRetailer());
    return response.data;
  }
);

// *********************************************************************************
// *********************************************************************************
// *********************************************************************************
// *********************************************************************************

/* to get Products of wholesaler*/
export const getWholesalerProducts = createAsyncThunk(
  "getWholesalerProducts",
  async (userId) => {
    const response = await AxiosConfig.get(
      `/kreesa/wholesaler/product/view/${userId}`,
      config
    );
    return response.data;
  }
);

/* to get Products of Retailer*/
export const getRetailerProducts = createAsyncThunk(
  "getRetailerProducts",
  async (userId) => {
    const response = await AxiosConfig.get(
      `/kreesa/retailer/product/view/${userId}`,
      config
    );
    return response.data;
  }
);

/* to get all reviews of buyer*/
export const getallBuyerReview = createAsyncThunk(
  "getallBuyerReview",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/review/get-all-review-of-buyer`,
      config
    );
    return response.data;
  }
);

/* to get all reviews of seller*/
export const getallUserReview = createAsyncThunk(
  "getallUserReview",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/review/get-all-review-of-user`,
      config
    );
    return response.data;
  }
);

/* to get all reviews of seller*/
export const ReviewApprove = createAsyncThunk(
  "ReviewApprove",
  async (reviewId, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/review/approve-review/${reviewId}`,
      config
    );
    dispatch(getallBuyerReview(0));
    return response.data;
  }
);
