import $ from "jquery";
import "jquery-validation";
const GroupProductValidation = () => {
  // Wait for the DOM to be ready
  $(function () {
    $("#groupadd-forms").validate({
      // Specify validation rules
      rules: {
        GroupName: {
          required: true,
        },

        role: {
          required: true,
        },
        role2: {
          required: true,
        },
        role3: {
          required: true,
        },
      },

      messages: {
        GroupName: {
          required: "Group Name is required",
        },
        role: {
          required: "Primary category required",
        },
        role2: {
          required: "Secondary category required",
        },
        role3: {
          required: "Trenary category required",
        },
      },

      highlight: function (element) {
        $(element).removeClass("is-valid").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid").addClass("is-valid");
      },
    });
    $("#gadd").on("click", function () {
      $("#groupadd-forms").valid();
    });
  });
  $("#groupadd-forms ").on("keyup blur click", function () {
    // fires on every keyup & blur
    if ($("#groupadd-forms ").valid()) {
      // checks form for validity
      $("button#gadd").prop("disabled", false); // enables button
    } else {
      $("button#gadd").prop("disabled", "disabled"); // disables button
    }
  });
};

export default GroupProductValidation;
