import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import Spinner from "../../../Spinner";
import moment from "moment";
// import { toast } from "react-toastify";

const ReviewRetailorDetailsForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [state, setState] = useState();
  const [isLoaded, setIsLoaded] = useState(true);

  document.title = `Admin | ${state?.companyName}`;

  // get all users from API
  const getSingleRetailor = async (id) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetSingleRetailorDetails = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${id}`,
        config
      );
      setState(GetSingleRetailorDetails.data);
      setIsLoaded(false);
    } catch (err) {
      // toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };
  useEffect(() => {
    getSingleRetailor(id);
  }, []);
  return !isLoaded ? (
    <div>
      <div className="container ">
        <div className="col-md-2" style={{ float: "right" }}>
          {localStorage.getItem("auth") !== "ROLE_RECEPTIONIST" &&
            localStorage.getItem("auth") !== "ROLE_MARKETING_MANAGER" && (
              <Link to={`/admin/edit-retailers/${state.userId}`}>
                <input
                  type="submit"
                  className="profile-edit-btn"
                  name="btnAddMore"
                  value="Edit User"
                />
              </Link>
            )}
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="profile-img">
              <img
                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${state.userId}/${state?.companyLogo}`}
                alt={state?.companyName}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="tab-content profile-tab" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <label>Company Name</label>
                  </div>
                  <div className="col-md-6">
                    <p>{state?.companyName}</p>
                  </div>
                </div>
                {/* <div className="row" style={{height:'40px'}}>
                  <div className="col-md-6">
                    <label>Company Address</label>
                  </div>
                  <div className="col-md-6">
                    <p>{state?.companyAddress}</p>
                  </div>
                </div> */}
                <div className="row" style={{ height: "40px" }}>
                  <div className="col-md-6">
                    <label>Country</label>
                  </div>
                  <div className="col-md-6" style={{ marginTop: "0px" }}>
                    <p>{state?.countryName}</p>
                  </div>
                </div>
                <div className="row" style={{ height: "40px" }}>
                  <div className="col-md-6">
                    <label>Provience</label>
                  </div>
                  <div className="col-md-6" style={{ marginTop: "0px" }}>
                    <p>{state?.provienceName}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>District</label>
                  </div>
                  <div className="col-md-6" style={{ marginTop: "0px" }}>
                    <p>{state?.districtName}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6 ">
                    <label>Street Address</label>
                  </div>
                  <div className="col-md-6">
                    <p>{state?.streetAddress}</p>
                  </div>
                </div>{" "}
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label>Landmark</label>
                  </div>
                  <div className="col-md-3">
                    <p>{state?.landmark}</p>
                  </div>
                </div>{" "}
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label>Pin Code</label>
                  </div>
                  <div className="col-md-6">
                    <p>{state?.pinCode}</p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label>Company Email</label>
                  </div>
                  <div className="col-md-6">
                    <p>{state?.companyEmail}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Company Type</label>
                  </div>
                  <div className="col-md-6">
                    <p>{state?.companyType}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Company Contact</label>
                  </div>
                  <div className="col-md-6">
                    <p> {state?.companyContactNo}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Register Status</label>
                  </div>
                  <div className="col-md-6">
                    {state?.isRegistered === true ? (
                      <>
                        <p>Register</p>
                        <div className="row">
                          <div className="col-md-12">
                            <p>PAN NO: {state?.panNo}</p>
                          </div>
                        </div>
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${state.userId}/${state?.registrationDocument}`}
                          alt={state?.companyName}
                          width={120}
                          height={120}
                          className="rounded"
                        />
                      </>
                    ) : (
                      <p>Not Register</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Insuranced Status</label>
                  </div>
                  <div className="col-md-6">
                    {state?.isInsured === true ? (
                      <p>Insuranced</p>
                    ) : (
                      <p>Not Insuranced</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Status</label>
                  </div>
                  <div className="col-md-6">
                    {state?.flag === true ? (
                      state?.locked === true ? (
                        <p>Active</p>
                      ) : (
                        <p>Inactive</p>
                      )
                    ) : (
                      <p>Blocked</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Created Date</label>
                  </div>
                  <div className="col-md-6">
                    <p>{moment(state.createdAt).format("ll")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ReviewRetailorDetailsForm;
