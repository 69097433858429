import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

/* to get all Retailer And Wholesaler Products in userpage */
export const getWholesalerProducts = createAsyncThunk(
  "getWholesalerProducts",
  async () => {
    // getWholesalerProducts/pending
    // getWholesalerProducts/fulfilled
    // getWholesalerProducts/rejected
    const response = await AxiosConfig.get(
      "/kreesa/customer/product/wholesale/view-all"
    );
    return response.data;
  }
);

/* to get Single  Retailer And Wholesaler Products in userpage */
export const getSingleWholesalerProducts = createAsyncThunk(
  "getSingleWholesalerProducts",
  async (id) => {
    // getSingleWholesalerProducts/pending
    // getSingleWholesalerProducts/fulfilled
    // getSingleWholesalerProducts/rejected
    const response = await AxiosConfig.get(`/kreesa/customer/product/${id}`);
    return response.data;
  }
);

export const postSingleWholesalerCart = createAsyncThunk(
  "postSingleWholesalerCart",
  async (data, { dispatch }) => {
    // getSingleWholesalerProducts/fulfilled
    // getSingleWholesalerProducts/rejected
    const response = await AxiosConfig.post(`/kreesa/customer/cart/add`, data)
      .then((res) => {
        dispatch(viewAllCart(data.userId));
        toast.info(data?.productName + "Added To Cart");
      })
      .catch((err) => toast.error("An Error Occurred"));
    // ).then(() => dispatch(viewAllCart));
    // return response.data;
  }
);

/* ------------------------------ view all cart ----------------------------- */
export const viewAllCart = createAsyncThunk("getViewAllCart", async (id) => {
  const response = await AxiosConfig.get(
    `/kreesa/customer/cart/view-all?userId=${id}`
  );
  return response.data;
});

/* ------------------------------- remove cart ------------------------------ */

export const removeCartItem = createAsyncThunk(
  "removeCartItem",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/cart/items/remove?cId=${data.cartId}`
    ).then(
      (res) =>
        data?.productName &&
        toast.info(data?.productName + " removed successfully")
    );
    dispatch(viewAllCart(data.userId));
    return response.data;
  }
);

/* ------------------------------- update cart ------------------------------ */
export const updateCartItem = createAsyncThunk(
  "updateCartItem",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/customer/cart/update`,
      data
    );
    dispatch(viewAllCart(data.userId));
    return response.data;
  }
);
/* --------------------------------- search --------------------------------- */
export const searchItem = createAsyncThunk(
  "searchItem",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/wholesaler/search-product`,
      data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

export const homePageSearch = createAsyncThunk(
  "searchItemWholesale",
  async (data, { dispatch }) => {
    return data;
  }
);

/* --------------------------------- Get user quotation --------------------------------- */
export const getUserQuotation = createAsyncThunk(
  "salerQuotation",
  async (userId, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/quotation/get-quotation-for-user/${userId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

export const getAssignedQuotation = createAsyncThunk(
  "getAssignedQuotationSaler",
  async (userId, { dispatch }) => {
    const auth = localStorage.getItem("auth");
    const response = await AxiosConfig.get(
      `/kreesa/${
        auth === "ROLE_RETAILER" ? "retail" : "wholesale"
      }/quotation/assigned/${userId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);
// actual price
export const postActualPrice = createAsyncThunk(
  "postActualPrice",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/wholesale/quotation/approve`,

      data.data,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (response && response.status === 200) {
      dispatch(getUserQuotation(data.userId));
      data.toast.success(response.data.message);
      return response.data;
    }
  }
);

/* --------------------------------- Approve saler quotation --------------------------------- */
export const approveSalerQuotation = createAsyncThunk(
  "approveSalerQuotation",
  async (userId, { dispatch }) => {
    const response = await AxiosConfig.get(`/kreesa/approve/${userId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch(getUserQuotation(userId));
    return response.data;
  }
);

/* --------------------------------- Confirm delivery from supplier --------------------------------- */
export const confirmDeliveryFromSupplier = createAsyncThunk(
  "approveSalerQuotation",
  async (quoteId, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/delivery/confirm-delivery-from-supplier/${quoteId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        toast.success(res.data);
        dispatch(getAssignedQuotation(localStorage.getItem("userId")));
      })
      .catch((err) => toast.err("An Error Occurred"));

    return response.data;
  }
);
/* --------------------------------- Confirm delivery from supplier --------------------------------- */
export const WholesaleFeatureProduct = createAsyncThunk(
  "WholesaleFeatureProduct",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/customer/features/product/wholesale/view-all`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);

/* --------------------------------- Reject Saler quotation --------------------------------- */
export const rejectSalerQuotation = createAsyncThunk(
  "rejectSalerQuotation",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/${
        localStorage.getItem("auth") === "ROLE_RETAILER"
          ? "retail"
          : "wholesale"
      }/quotation/decline/${data.id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => toast.success("Quotation removed successfully"))
      .catch((err) => toast.error("An error occurred"));
    dispatch(getUserQuotation(data.userId));
    return response.data;
  }
);

//

export const wholesaleReadNotification = createAsyncThunk(
  "wholesaleReadNotification",
  async (id) => {
    const user = localStorage.getItem("auth");

    const response = await AxiosConfig.get(
      // user === "ROLE_WHOLESALER"
      `/kreesa/wholesaler/mark-notification-as-read/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  }
);
