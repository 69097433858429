import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { getCategories } from "../../../../Common/Features/Action/CategoryAction/CategoryAction";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import Spinner from "../../Spinner";
import TopNavbar from "../../TopNavbar";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../../ButtonLoaderHook";
import { getSingleGroupProduct } from "../../Features/Action/GroupProductAction";
import { Formik, Form } from "formik";

const EditGroupProduct = () => {
  document.title = "Admin | Edit Group Product";

  const { id } = useParams();
  const history = useHistory();
  const { EditGroupProduct } = useSelector((s) => s.GroupProduct);

  // to get all Categories(Primary, Secondary, Ternary)
  const { Categories, loader } = useSelector((s) => s.Categories);
  const dispatch = useDispatch();

  // to get single GroupProduct
  useEffect(() => {
    dispatch(getSingleGroupProduct(id));
    dispatch(getCategories());
  }, [id]);

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Group Product",
    "Loading..."
  );

  const updateGroupProductHandler = async (values) => {
    setButtonLoader(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.post(
        `/kreesa/admin/group/update/${id}`,
        { ...values },
        config
      );
      if (res) {
        toast.success("Group Product Update succesfully !");
        setButtonLoader(false);
        history.push("/admin/group-product/view");
      }
    } catch (err) {
      console.log(err + "error found");
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      // toast.error(err.response.data.message);
      setButtonLoader(false);
    }
  };

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          {!loader ? (
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title font-weight-bold">
                    Edit Group Product Form
                  </h5>
                  <h6 className="card-subtitle text-muted">
                    You Can Easily Edit Group Product.
                  </h6>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      groupName: EditGroupProduct?.groupName,
                      groupSKU: EditGroupProduct?.groupSKU,
                      primaryCategoryId: EditGroupProduct?.primaryCategoryId,
                      secondaryCategoryId:
                        EditGroupProduct?.secondaryCategoryId,
                      ternaryCategoryId: EditGroupProduct?.ternaryCategoryId,
                      id: EditGroupProduct?.id,
                    }}
                    onSubmit={(values) => updateGroupProductHandler(values)}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="col">
                          <div className="mb-3 col-md-4">
                            <label className="form-label" htmlFor="inputCity">
                              <span className="font-weight-bold">
                                Group Product Name
                                <span className="text-danger"> *</span>
                              </span>
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              name="groupName"
                              id="inputCity"
                              value={values.groupName}
                              onChange={handleChange}
                              placeholder="Enter Group Product Name"
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <label className="form-label" htmlFor="inputCity">
                              <span className="font-weight-bold">
                                Group Product SKU{" "}
                                <span className="text-info"> (Optional)</span>
                              </span>
                            </label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              name="groupSKU"
                              id="inputCity"
                              value={values.groupSKU}
                              onChange={handleChange}
                              placeholder="Enter Group Product Name"
                            />
                          </div>
                        </div>
                        <div className="mb-3 col-md-4">
                          <label
                            className="form-label font-weight-bold"
                            htmlFor="inputState"
                          >
                            Primary Category{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            id="inputState"
                            required
                            name="primaryCategoryId"
                            onChange={handleChange}
                            className="form-control"
                            value={values.primaryCategoryId}
                          >
                            {/* to get all Primary Categories */}
                            {Categories.primary?.map((p, i) => (
                              <option
                                key={i}
                                value={p.id}
                                selected={
                                  EditGroupProduct?.primaryCategoryId === p.id
                                }
                              >
                                {p.categoryName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3 col-md-4">
                          <label
                            className="form-label font-weight-bold"
                            htmlFor="inputState"
                          >
                            Secondary Category{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            id="inputState"
                            required
                            onChange={handleChange}
                            name="secondaryCategoryId"
                            className="form-control"
                            value={values.secondaryCategoryId}
                          >
                            {/* to get all Secondary Categories */}
                            {Categories.secondary?.map((p, i) => (
                              <option
                                key={i}
                                value={p.id}
                                selected={
                                  EditGroupProduct?.secondaryCategoryId === p.id
                                }
                              >
                                {p.secondaryCategoryName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3 col-md-4">
                          <label
                            className="form-label font-weight-bold"
                            htmlFor="inputState"
                          >
                            Ternary Category{" "}
                          </label>
                          <select
                            id="inputState"
                            required
                            onChange={handleChange}
                            name="ternaryCategoryId"
                            className="form-control"
                            value={values.ternaryCategoryId}
                          >
                            {/* to get all Ternary Categories */}
                            {Categories.ternary?.map((p, i) => (
                              <option
                                key={i}
                                value={p.id}
                                selected={
                                  EditGroupProduct?.ternaryCategoryId === p.id
                                }
                              >
                                {p.ternaryCategoryName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* <div className="row">
                      <div className="mb-3 col-md-2">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Min Price
                            <span className="text-danger"> *</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          required
                          name="GroupName"
                          id="inputCity"
                          onChange={handleChange}
                          placeholder="Min Price"
                        />
                      </div>
                       <div className="mb-3 col-md-2">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Max Price
                            <span className="text-danger"> *</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          name="GroupName"
                          id="inputCity"
                          onChange={handleChange}
                          placeholder="Max Price"
                        />
                      </div> 
                    </div>  */}
                        <div className="mb-3 mt-4 col-md-4">
                          <button
                            type="submit"
                            className="btn btn-success btn-lg btn-block"
                            ref={buttonLoader}
                            onClick={handleSubmit}
                          >
                            Update Group Product
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default EditGroupProduct;
