import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import primaryCategory from "../Components/Admin/Features/Reducer/CategoriesReducer/PrimaryCategoryReducer";
import CustomOrder from "../Components/Admin/Features/Reducer/CustomOrderReducer";
import secondaryCategory from "../Components/Admin/Features/Reducer/CategoriesReducer/SecondaryCategoryReducer";
import ternaryCategory from "../Components/Admin/Features/Reducer/CategoriesReducer/TernaryCategoryReducer";
import Categories from "../Common/Features/Reducer/CategoriesReducer/Categories";
import GroupProduct from "../Components/Admin/Features/Reducer/GroupProductReducer";
import ProductRetailerReducer from "../Components/Admin/Features/Reducer/ProductReducer/RetailerReducer";
import ProductWholesalerReducer from "../Components/Admin/Features/Reducer/ProductReducer/WholesalerReducer";
import AdminNotificationReducer from "../Components/Admin/Features/Reducer/AdminNotificationReducer";
import getWholesalerProductsReducer from "../Components/Admin/Features/Reducer/ProductReducer/WholesalerReducer/getWholesalerProductsReducer";
import getRetailerProductsReducer from "../Components/Admin/Features/Reducer/ProductReducer/RetailerReducer/getRetailerProductsReducer";
import GetTotalNumberOfNotification from "../Components/Admin/Features/Reducer/AdminNotificationReducer/GetTotalNumberOfNotification";
import FeatureReducer from "../Components/Admin/Features/Reducer/FeatureProductReducer";
import userProducts from "../Components/User/Features/slices/userProductSlice";
import userProfile from "../Components/User/Features/slices/userProfile";
import basketSlice from "../Components/User/Features/slices/basketSlice";
import UserDetailsReducer from "../Common/Features/Reducer/AllUserDetailsReducer";
import SingleCustomOrder from "../Common/Features/Reducer/CustomOrderReducer";
import basketReducer from "../Components/RetailerWholeSaler/slices/basketSlice";
// user Page of Wholesaler & Retailer
import WholesalerReducer from "../Components/RetailerWholeSaler/Features/Reducer/WholesalerReducer";
import SingleWholesalerReducer from "../Components/RetailerWholeSaler/Features/Reducer/SingleWholesalerReducer";
import CompanyType from "../Components/Admin/Features/Reducer/CompanyType/CompanyType";
import EnquiryReducer from "../Components/Admin/Features/Reducer/EnquiryReducer";
import AdminDeliveryReducer from "../Components/Admin/Features/Reducer/OrderStatusReducer";
import QuotationsReducer from "../Components/Admin/Features/Reducer/QuotationsReducer";
import AddressReducer from "../Components/Admin/Features/Reducer/AddressReducer";
import LegalReducer from "../Components/Admin/Features/Reducer/LegalReducer";
import DiscountReducer from "../Components/Admin/Features/Reducer/DiscountReducer";
import AdminReducer from "../Components/Admin/Features/Reducer/AdminReducer";
import RewardReducer from "../Components/Admin/Features/Reducer/RewardReducer";
import TreeReducer from "../Components/Admin/Features/Reducer/TreeReducer";
import DeliveryReducer from "../Components/DeliveryBoy/Features/Reducer/DeliveryReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const reducers = combineReducers({
  primaryCategory,
  secondaryCategory,
  ternaryCategory,
  // fetching categories in retailor & wholesaler page
  Categories,
  GroupProduct,
  userProducts,
  basketSlice,
  CustomOrder,
  basketReducer,
  WholesalerReducer,
  getWholesalerProductsReducer,
  getRetailerProductsReducer,
  SingleWholesalerReducer,
  ProductRetailerReducer,
  ProductWholesalerReducer,
  AdminNotificationReducer,
  GetTotalNumberOfNotification,
  UserDetailsReducer,
  CompanyType,
  SingleCustomOrder,
  userProfile,
  EnquiryReducer,
  AdminDeliveryReducer,
  QuotationsReducer,
  AddressReducer,
  LegalReducer,
  AdminReducer,
  DeliveryReducer,
  FeatureReducer,
  DiscountReducer,
  RewardReducer,
  TreeReducer,
});

const persistConfig = {
  timeout: 0,
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
