import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";

const MyReturnOrder = () => {
  const [state, setstate] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  // get my order from API
  const getMyReturnOrder = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const getMyReturnOrderDetails = await AxiosConfig.get(
        `/kreesa/${
          localStorage.getItem("auth") === "ROLE_WHOLESALER"
            ? "wholesaler"
            : "retailer"
        }/view-all-return-order/${localStorage.getItem("userId")}`,
        config
      );
      setstate(getMyReturnOrderDetails.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    getMyReturnOrder();
  }, []);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = state?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(state?.length / perPage);
  return !isLoaded ? (
    <>
      {state.length > 0 ? (
        <div className="container">
          <div className="row">
            {/* <div className="col-12 d-flex w-100">
                <div className="card flex-fill flex-fill">
                    <div className="card-header">
                        <Link to="/retailer/product/register" className="btn btn-lg btn-outline-info display-5 mt-2" style={{ float: 'right' }}>
                            Add Product  <i className="fab fa-product-hunt"></i></Link>
                    </div>
                </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 col-lg-8 d-flex w-100">
              <div className="card flex-fill overflow-auto">
                <div className="card-header">
                  <h5 className="card-title mb-0">My Latest Return Orders</h5>
                </div>
                <table className="table table-hover my-0 ">
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                      <th>Reason</th>
                      {/* <th className="d-xl-table-cell">Number of Order</th> */}
                      {/* <th className="d-md-table-cell">Address</th> */}
                      {/* <th className="d-md-table-cell">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries?.map((p, i) => (
                      <tr>
                        <td>{perPage * (currentPage - 1) + i + 1}</td>
                        <td>{p.productName}</td>
                        <td>{p.quantity}</td>
                        <td className="d-xl-table-cell">{p.productPrice}</td>
                        <td className="d-xl-table-cell">{p.totalAmount}</td>
                        <td className="d-xl-table-cell">{p?.reason}</td>
                        {/* <td className="d-md-table-cell">{p.shippingAddress}</td> */}
                        {/* <td className="d-md-table-cell">
                          <Link
                            to={`/retailer/my-orders/${p.id}`}
                            className="btn btn-info"
                          >
                            <i className="fas fa-eye"></i> View More
                          </Link>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              pages={totalPagesNum}
              setCurrentPage={setCurrentPage}
              currentEntries={currentEntries}
              allEntries={state}
            />
          </div>
        </div>
      ) : (
        <div className="card flex-fill overflow-auto">
          <div className="card-header">
            <h5 className="card-title mb-0">My Latest Return Orders</h5>
          </div>
          <div className="text-center pt-2 pb-4">NO DATA FOUND</div>
        </div>
      )}
    </>
  ) : (
    <Spinner />
  );
};

export default MyReturnOrder;
