import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BlockedRetailorsTab from "./BlockedRetailorsTab";
import AllRetailorsTab from "./AllRetailorsTab";
import { Link } from "react-router-dom";
import { filterRetailer } from "../../Features/Reducer/AdminReducer";
import {
  exportSellerInExcel,
  getAllRetailer,
} from "../../Features/Action/AdminBlockUnblockAction";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import ExportInExcel from "../../ExportInExcel";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";

const RetailorsDetails = () => {
  document.title = "Admin | Retailors";

  const inputRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllRetailer());
  }, []);

  const filter = () => {
    dispatch(filterRetailer(inputRef.current.value));
  };

  const exportInExcel = async () => {
    const res = await dispatch(
      exportSellerInExcel({ userType: ["ROLE_RETAILER"] })
    );
    const fulfilled = res.meta.requestStatus === "fulfilled";

    const fileName = res.payload;

    if (fulfilled) {
      const url =
        AxiosConfig.defaults.baseURL + `/kreesa/document/report/${fileName}`;
      const newTab = window.open(url, "_blank");
      setTimeout(() => {
        newTab.close();
      }, 5000);
    }
  };

  return (
    <div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex w-100">
              <div className="card flex-fill flex-fill">
                <div className="card-header">
                  <ExportInExcel onClick={exportInExcel} />
                  {localStorage.getItem("auth") !== "ROLE_RECEPTIONIST" && (
                    <Link
                      to="/admin/add-new-rwuser"
                      className="btn btn-lg btn-outline-info display-5 mt-2"
                      style={{ float: "right" }}
                    >
                      Add New Seller <i className="fas fa-user-plus ml-2"></i>
                    </Link>
                  )}
                  <form className="d-none mt-2 d-sm-inline-block">
                    <div className="input-group input-group-navbar">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Retailers…"
                        aria-label="Search"
                        ref={inputRef}
                        onChange={filter}
                      />
                      <button className="btn" type="button">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Tabs>
              <TabList>
                <Tab>All Retailors</Tab>

                <Tab>Blocked Retailors</Tab>
              </TabList>

              <TabPanel>
                {/* All Users */}

                <AllRetailorsTab />
              </TabPanel>

              <TabPanel>
                {/* Blocked Users */}

                <BlockedRetailorsTab />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailorsDetails;
