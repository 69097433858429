import { Redirect, Route, useHistory } from "react-router";

const SalerRouting = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  const auth = localStorage.getItem("auth");

  const history = useHistory();
  //roles
  const salerRole = ["ROLE_RETAILER", "ROLE_WHOLESALER"];

  const adminRole = [
    "ROLE_SUPER_ADMIN",
    "ROLE_CEO",
    "ROLE_MANAGER",
    "ROLE_MARKETING_MANAGER",
    "ROLE_RECEPTIONIST",
  ];
  const consumerRole = ["ROLE_USER"];

  // if (adminRole.includes(auth)) {
  //   history.push("/admin");
  // }
  // if (consumerRole.includes(auth)) {
  //   history.push("/");
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && adminRole.includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/admin",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && consumerRole.includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && ["ROLE_DELIVERY_BOY"].includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/delivery",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default SalerRouting;
