import { createSlice } from '@reduxjs/toolkit'
import { getCompanyType } from '../../Action/CompanyTypeAction/CompanyTypeAction';

const CompanyTypeReducer = createSlice({
    name: 'companyType',
    initialState: {
       companyType: [],
       companyTypeContainer: [],
        loader: false,
        errors: {},
    },
    reducers: {
        filterCompanyType: (state, action) => {
            state.companyType = state.companyTypeContainer.filter((u) =>
                u.typeName.toLowerCase().includes(action.payload) ||
                u.typeName.toUpperCase().includes(action.payload)
            );
        }
    },
    extraReducers: {
        /* to get all Primary Categories */
        [getCompanyType.pending]: (state, action) => {
            state.loader = true;
        },
        [getCompanyType.fulfilled]: (state, action) => {
            state.loader = false;
            state.companyType = action.payload;
            state.companyTypeContainer = action.payload;
        },
        [getCompanyType.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload;

        },
    }
});

// export
export const { filterCompanyType } = CompanyTypeReducer.actions;
// export default
export default CompanyTypeReducer.reducer;