import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../Features/action';
import { Image } from 'react-bootstrap';
import AxiosConfig from '../../../Common/Api/AxiosConfig';
import logo from '../../../assets/Images/kreesalogo.png';
import {
  getAllCountries,
  getAllDistrict,
  getAllProvince,
} from '../../Admin/Features/Action/AddressAction';
import ModalImage from 'react-modal-image';
import Spinner from '../../Admin/Spinner';

function AccountDetail() {
  const dispatch = useDispatch();
  const { countries, districts, proviences, loader } = useSelector(
    (s) => s.AddressReducer
  );

  useEffect(() => {
    dispatch(getUserProfile(localStorage.getItem('userId')));
  }, []);

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllDistrict());
    dispatch(getAllProvince());
  }, []);

  const { profile } = useSelector((state) => state.userProfile);

  return !loader ? (
    <div>
      {/* <div className="row">
        <div className="col-12 col-md-4 d-flex align-items-center mb-4">
          {profile.profile === null ? (
            <Image
              src={logo}
              roundedCircle
              height={120}
              width={120}
              style={{ marginLeft: "65px" }}
              alt=""
            />
          ) : (
            <Image
              src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${profile?.userId}/${profile?.profile}`}
              roundedCircle
              height={120}
              width={120}
              style={{ marginLeft: "65px" }}
            />
          )}
        </div>
        <div className="col-12 col-md-8">
          <h4>Profile Information</h4>
          <div className="desc-item mt-2">
            <div>First Name</div>
            <div>: {profile?.firstname}</div>
          </div>
          <div className="desc-item">
            <div>Last Name</div>
            <div>: {profile?.lastname}</div>
          </div>
          <div className="desc-item">
            <div>Contact</div>
            <div>: {profile?.contact}</div>
          </div>
          
          <div className="desc-item">
            <div>Email</div>
            <div>: {profile?.emailId}</div>
          </div>
        </div>
      </div>

      <div className="col-12 offset-md-2 d-flex mt-3">
        <button
          className=" btn-success btn-lg border-0 ml-6 mt-4"
          type="button"
          data-toggle="modal"
          data-target="#exampleModal"
          style={{ width: "max-content", padding: "10px 50px" }}
        >
          Edit Profile
        </button>
      </div>
     */}

      {/* <div className="container">
        <div className="row">
          <div className="">
            <div className="profile-card-4 text-center">
              <img
                style={{ height: "300px" }}
                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${profile?.userId}/${profile?.profile}`}
                className="img img-responsive"
              />
              <div className="profile-content text-center">
                <div className="profile-description text-center">
                  <h5 className="font-weight-bold">
                    First Name: {profile?.firstname}
                  </h5>
                  <h5 className="font-weight-bold">
                    Last Name: {profile?.lastname}
                  </h5>
                </div>
                <div className="profile-description ">
                  <h5 className="font-weight-bold">
                    Phone No: {profile?.contact}
                  </h5>
                  <h5 className="font-weight-bold">
                    Address:{"  "}
                    {
                      districts.find((i) => i.id === profile.countryId)
                        ?.district_name
                    }
                    ,{" "}
                    {
                      proviences.find((i) => i.id === profile.countryId)
                        ?.provience_name
                    }
                    ,{" "}
                    {
                      countries.find((i) => i.id === profile.countryId)
                        ?.country_name
                    }
                  </h5>
                </div>

                <div className="profile-description ">
                  <h5 className="font-weight-bold">
                    Street Address: {profile?.streetAddress}
                  </h5>
                  <h5 className="font-weight-bold">
                    Landmark: {profile?.landmark || "-"}
                  </h5>{" "}
                  <h5 className="font-weight-bold">
                    Pin Code: {profile?.pinCode || "-"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* new */}
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          {/* <div className="col-lg-6"> */}
          <div className="about-text">
            <ModalImage
              alt={`${profile?.firstname} ${profile?.lastname}`}
              small={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${profile?.userId}/${profile?.profile}`}
              large={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${profile?.userId}/${profile?.profile}`}
              loading="lazy"
              className="img-fluid user-profile__image mt-4"
              showRotate={true}
              hideDownload={true}
            />
            {/* <h3 className="dark-color">About Me</h3> */}
            <hr />
            <h4 className="dark-color">
              {profile?.firstname} {profile?.lastname}
            </h4>
            <h4 style={{ color: 'green' }}>
              <i class="fas fa-award"></i> {profile.rewardPoints}
            </h4>

            {/* <h6 className="theme-color lead font-weight-bold">
              {profile?.firstname} {profile?.lastname}
            </h6> */}
            <div className="row about-list">
              <div className="col-md-6">
                <div className="media mt-2">
                  <label>Residence</label>
                  <p className="font-weight-bold">
                    {
                      districts.find((i) => i.id === profile.countryId)
                        ?.district_name
                    }
                    ,{' '}
                    {
                      countries.find((i) => i.id === profile.countryId)
                        ?.country_name
                    }
                  </p>
                </div>
                <div className="media mt-2">
                  <label>Address</label>
                  <p className="font-weight-bold">
                    {
                      districts.find((i) => i.id === profile.countryId)
                        ?.district_name
                    }
                    ,{' '}
                    {
                      proviences.find((i) => i.id === profile.countryId)
                        ?.provience_name
                    }
                    ,
                    {
                      countries.find((i) => i.id === profile.countryId)
                        ?.country_name
                    }
                  </p>
                </div>
                <div className="media mt-2">
                  <label>Street Address</label>
                  <p className="font-weight-bold">
                    {`${profile?.streetAddress??""} , ${profile?.landmark??""} , ${profile?.pinCode??""}`}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="media mt-2">
                  <label>E-mail</label>
                  <p className="font-weight-bold">{profile?.emailId}</p>
                </div>
                <div className="media mt-2">
                  <label>Phone</label>
                  <p className="font-weight-bold">{profile?.contact}</p>
                </div>

                <div className="media mt-2">
                  <label>Status</label>
                  <p>
                    {profile.active === true ? (
                      <span className="badge bg-success px-3">Active</span>
                    ) : (
                      <span className="badge bg-danger">Inactive</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-6 mt-4"> */}
        {/* <img
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${profile?.userId}/${profile?.profile}`}
                  title={`${profile?.firstname} ${profile?.lastname}`}
                  alt={profile?.profile}
                  style={{ height: 300, width: "100%", objectFit: "contain" }}
                /> */}
        {/* <ModalImage
              alt={`${profile?.firstname} ${profile?.lastname}`}
              small={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${profile?.userId}/${profile?.profile}`}
              large={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${profile?.userId}/${profile?.profile}`}
              loading="lazy"
              className="img-fluid user-profile__image"
              showRotate={true}
              hideDownload={true}
            /> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default AccountDetail;
