import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

/* --------------------------- substring function --------------------------- */
export const Excerpt = (str, count) => {
  if (str?.length > count) {
    str = str.substring(0, count) + " ...";
  }
  return str;
};

/* ----------------------- to get all params from url ----------------------- */
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

/* ------------------------------ scroll to top ----------------------------- */
export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const body = document.querySelector("#kreesa");

    body.scrollIntoView(
      {
        behavior: "auto",
      },
      500
    );
  }, [pathname]);
  return children;
};

/* ------------------------------ device width ------------------------------ */
export const useDeviceDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};

/* ------------------------ scroll left right handler ----------------------- */

export const useScrollHandler = () => {
  // scroll to left side or right side of the page by clicking on left or right side of keyboard
  window.addEventListener("keydown", (e) => {
    if (e.keyCode === 37) {
      document.querySelector(".overflow-auto").scrollLeft -= 100;
    } else if (e.keyCode === 39) {
      document.querySelector(".overflow-auto").scrollLeft += 100;
    }
  });
};
