import React from "react";
import { Link} from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../../assets/Images/kreesalogo.png";
import AxiosConfig from "../../../Common/Api/AxiosConfig";


const UserMessage = () => {
               
  const handelActivation = async () => {
    let res;
    try {
      res = await AxiosConfig.get(`/kreesa/user-login-registration/resend-verification-link/${localStorage.getItem("activation")}`);
    } catch (err) {
      console.log(err + "Something Went Wrong ! ");
      toast.error("Something Went Wrong !");
    }
    if (res && res.status === 200) {
      toast.success("Verification Link Sent Successfully !");
    }
  };
  const rmactivate=()=>{
    localStorage.removeItem("activation");

  }
  return (
    <div>
      <div className="container col-sm-12 col-md-12 col-lg-12 mt-4">
        <div className="signup-form register-form">
          <form className="rform">
            <div className="d-flex" style={{ justifyContent: "space-evenly" }}>
              <Link to="/">
                <img src={logo} className="rrounded" alt="..." width="150px" />
              </Link>
            </div>
            <div className="form-group register-group">
              <div className="row mt-4" style={{flexDirection:'column'}}>
                <p className="text-center">
                  Your activation link has sent to your email account. Check
                  Your email{" "}
                </p>
                <p className="text-center">
                  If you haven't got activation link? Click here{" "}
                  <Link onClick={handelActivation} to="/activation-link" className="text-info">
                    Re-Send Link
                  </Link>{" "}
                </p>
                <p className="text-center">
                  <Link to="/login" onClick={rmactivate} className="text-info">
                    Login Now?
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
