import React from "react";
import Footer from "../Footer";
import TopNavbar from "../TopNavbar";
import LeftNavbar from "../LeftNavbar/";
import { Redirect, useParams } from "react-router-dom";
import MyQuotation from "../../RetailerWholeSaler/Quotation";
import MyQuotationDetails from "./MyQuotationDetails";

const MyQuotationPage = () => {
  document.title = "Retailor | My Quotation";
  const { id } = useParams();
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token !== "ROLE_RETAILER") {
    return <Redirect exact to="/login" />;
  }

  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            {id ? <MyQuotationDetails {...{ id }} /> : <MyQuotation />}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MyQuotationPage;
