import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import useWindowDimensions from "../../../Common/WindowDimensions/WindowDimensions";
import UserFooter from "../UserFooter";
import "./MyAccount.css";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import OrderCancell from "../MyOrder/OrderCancell";
import OrderReturn from "../MyOrder/OrderReturn";
import Orderdetails from "../MyOrder/Orderetails";
import AccountDetail from "./AccountDetail";
import { useDispatch } from "react-redux";
import { getUserOrder } from "../Features/action";
import UserQuotation from "../UserQuoataion";
import { CgProfile } from "react-icons/cg";
import { GoPackage } from "react-icons/go";
import { MdPreview } from "react-icons/md";
import { IoMdFlower, IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineFileSearch, AiOutlineHeart } from "react-icons/ai";
import MyQuotation from "../../RetailerWholeSaler/Quotation";
import UserQuotationTable from "../UserQuoataion/quotationTable";
import WishList from "./WishList";
import MyOrderDetails from "../../Retailer/MyOrder/MyOrderDetails";
import ShowReview from "../MyReview/showReview";
import { useHistory } from "react-router-dom";
import NotificationDetails from "../../Admin/Notifications/NotificationDetails";
import MyTreePlantationUser from "../Tree/MyTreePlantationUser";

const MyAccount = () => {
  document.title = "Kreesa | Manage Account";
  const dispatch = useDispatch();
  const history = useHistory();

  const [userProfile, setUserProfile] = useState([]);
  const [displayJSX, setDisplayJSX] = useState();

  const pageData = [
    {
      id: 1,
      icon: <CgProfile size="18px" />,
      title: "My Profile",
      url: "?my-profile",
      jsx: <AccountDetail />,
      subTitle: [
        {
          id: 1,
          url: "&edit",
          title: "Edit Profile",
          jsx: <EditProfile />,
        },
        {
          id: 2,
          title: "Change Password",
          url: "&change-password",
          jsx: <ChangePassword />,
        },
      ],
    },
    {
      id: 2,
      icon: <GoPackage size="18px" />,
      title: "My Orders",
      url: "?my-orders",
      jsx: <Orderdetails />,
      subTitle: [
        { id: 1, title: "Success", url: "&success", jsx: <Orderdetails /> },
        { id: 3, title: "Rejected", url: "&cancel", jsx: <OrderCancell /> },
        { id: 3, title: "Returned", url: "&return", jsx: <OrderReturn /> },
      ],
    },
    {
      id: 3,
      icon: <AiOutlineFileSearch size="18px" />,
      title: "My Quotation",
      url: "?my-quotation",
      jsx: <UserQuotationTable />,
      subTitle: [],
    },
    {
      id: 3,
      icon: <MdPreview size="18px" />,
      title: "My Review",
      url: "?my-review",
      jsx: <ShowReview />,
      subTitle: [],
    },
    {
      id: 4,
      icon: <IoMdNotificationsOutline size="18px" />,
      title: "All Notification",
      url: "?all-notification",
      jsx: <NotificationDetails user={localStorage.getItem("auth")} />,
      subTitle: [],
    },
    {
      id: 5,
      icon: <IoMdFlower size="18px" />,
      title: "My Planted Tree",
      url: "?my-planted-tree",
      jsx: <MyTreePlantationUser />,
      subTitle: [],
    },
  ];

  useEffect(() => {
    dispatch(getUserOrder(localStorage.getItem("userId")));

    switch (history.location.search) {
      case "?my-profile":
        setDisplayJSX(pageData[0].jsx);
        break;

      case "?my-profile&edit":
        setDisplayJSX(pageData[0].subTitle[0].jsx);
        break;

      case "?my-profile&change-password":
        setDisplayJSX(pageData[0].subTitle[1].jsx);
        break;

      case "?my-orders":
      case "?my-orders&success":
        setDisplayJSX(pageData[1].subTitle[0].jsx);
        break;

      case "?my-orders&cancel":
        setDisplayJSX(pageData[1].subTitle[1].jsx);
        break;

      case "?my-orders&return":
        setDisplayJSX(pageData[1].subTitle[2].jsx);
        break;

      case "?my-quotation":
        setDisplayJSX(pageData[2].jsx);
        break;

      case "?my-review":
        setDisplayJSX(pageData[3].jsx);
        break;

      case "?all-notification":
        setDisplayJSX(pageData[4].jsx);
        break;
      
        case "?my-planted-tree":
        setDisplayJSX(pageData[5].jsx);
        break;

      default:
        history.push("/manage-account?my-profile");
        break;
    }
  }, [history.location.search]);

  const infoToggler = (id) => {
    if (userProfile.length > 0) {
      userProfile.some((i) =>
        i.sidebarId === id
          ? setUserProfile(userProfile.filter((j) => j.sidebarId !== id))
          : setUserProfile([...userProfile, { sidebarId: id }])
      );
    } else {
      setUserProfile([{ sidebarId: id }]);
    }
  };

  const jsxChanger = (jsx) => {
    setDisplayJSX(jsx);
  };

  const { width } = useWindowDimensions;
  return (
    <div>
      <Search />

      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-3 col-lg-2 d-flex justify-content-center">
            <div className="user-sidebar">
              <div>
                {pageData.map((i) => (
                  <div className="user-sidebar-content" key={i.id}>
                    <div
                      className="user-sidebar-title"
                      style={
                        history.location.search.includes(i.url)
                          ? {
                              backgroundColor: "whitesmoke",
                              boxShadow: "2px 4px 5px #bababa",
                              borderRadius: "10px",
                            }
                          : {}
                      }
                      onClick={() => {
                        infoToggler(i.id);
                        setDisplayJSX(i.jsx);
                        history.push(
                          `/manage-account${
                            i.title === "My Orders" ? i.url + "&success" : i.url
                          }`
                        );
                      }}
                    >
                      {i.icon}
                      {i.title}
                    </div>
                    {i.subTitle?.map((j) => (
                      <div
                        className="user-subtitle-container"
                        style={
                          history.location.search.includes(j.url)
                            ? {
                                fontWeight: "bold",
                                textDecoration: "underline",
                                fontSize: "18px",
                                whiteSpace: "nowrap",
                              }
                            : {
                                fontSize: "16px",
                              }
                        }
                      >
                        {userProfile.length > 0 &&
                          userProfile.some((k) => k.sidebarId === i.id) && (
                            <div
                              className="user-sidebar-subtitle"
                              onClick={() => {
                                setDisplayJSX(j.jsx);
                                history.push(`/manage-account${i.url}${j.url}`);
                              }}
                            >
                              {j.title}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-10">{displayJSX}</div>
        </div>
      </div>

      <UserFooter />
    </div>
  );
};

export default MyAccount;
