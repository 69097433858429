import React, { useState, useEffect, useMemo } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./style.css";
import { MdFreeCancellation } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { useDeviceDimensions } from "../../../utils";

const LeftNavbar = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const [position, setPosition] = useState(
    useMemo(() => {
      const position = localStorage.getItem("position");
      if (position) {
        return position;
      }
      return "";
    }, [])
  );

  const { width: deviceWidth } = useDeviceDimensions();

  const history = useHistory();

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // scroll to top when changing page
  useEffect(() => {
    let element = document.getElementById("sidebarContent");
    element.scrollTo(0, localStorage.getItem("position"));
  }, [position]);

  localStorage.setItem("position", position);

  const sidebarData = [
    {
      title: "Personal Settings",
      subtitle: [
        {
          url: "/retailer/profile-settings",
          name: "Settings and Privacy",
          icon: "fas fa-cogs",
        },
        {
          url: "/retailer/profile",
          name: "Profile",
          icon: "far fa-user-circle",
        },
        {
          url: "/retailer/my-review",
          name: "My Review",
          icon: "fas fa-comment",
        },
        {
          url: "/retailer/message",
          name: "Messages",
          icon: "fas fa-sms",
        },
        {
          url: "/retailer/wishlist",
          name: "Wishlist",
          icon: <AiOutlineHeart size="18px" />,
          faIcon: false,
        },
      ],
    },
    {
      title: "Add / Edit Product",
      subtitle: [
        {
          url: "/retailer/product/view",
          name: "Product",
          icon: "fab fa-product-hunt",
        },
        {
          url: "/retailer/stock",
          name: "My Stocks",
          icon: "fas fa-business-time",
        },
      ],
    },
    {
      title: "Incoming Order",
      subtitle: [
        {
          name: "Orders",
          icon: "fas fa-cogs",
          dropdown: true,
          dropdownData: [
            {
              url: "/retailer/orders/view",
              type: "Normal Order",
            },
            { url: "/retailer/quotation-order", type: "Quotation Order" },
          ],
        },
      ],
    },
    {
      title: "Tree Plantation",
      subtitle: [
        {
          url: "/retailer/tree/my-planted-tree",
          name: "My Planted Trees",
          icon: "fas fa-seedling",
        },
      ],
    },
    {
      title: "Outgoing Order",
      subtitle: [
        {
          url: "/retailer/my-orders",
          name: "My Orders",
          icon: "fas fa-signal",
        },
        {
          url: "/retailer/quotation",
          name: "My Quotation",
          icon: "fas fa-bookmark",
        },
        {
          url: "/retailer/custom-order",
          name: "Custom Orders",
          icon: "fas fa-bookmark",
        },
        {
          // url: "/retailer/custom-order/register",
          name: "Add Custom Order",
          icon: "fas fa-bookmark",
          dropdown: true,
          dropdownData: [
            {
              url: "/retailer/custom-order/register/image",
              type: "Custom order By Image",
            },
            {
              url: "/retailer/custom-order/register/audio",
              type: "Custom order By Voice",
            },
          ],
        },
      ],
    },
    {
      title: "Return Order",
      subtitle: [
        {
          url: "/retailer/my-return-orders",
          name: "My Return Order",
          faIcon: false,
          icon: <MdFreeCancellation size="18px" />,
        },
      ],
    },
  ];

  return (
    <nav
      id="sidebar"
      className={`collapse ${matches && "show"}`}
      style={{ width: deviceWidth > 768 ? "300px" : "500px" }}
    >
      <div
        className="sidebar-content js-simplebar"
        id="sidebarContent"
        onScroll={(e) => setPosition(Math.round(e.target.scrollTop))}
      >
        <Link className="sidebar-brand" to="/retailer">
          <span className="align-middle text-sm">Kreesa Saler's Dashboard</span>
        </Link>

        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/home"
            >
              <i className="fas fa-shopping-cart"></i>
              <span className="align-middle">Continue to Shopping</span>
            </NavLink>
          </li>

          {sidebarData.map((i) => (
            <>
              <li className="sidebar-header">{i.title}</li>

              {i.subtitle.map((j) => (
                <>
                  {j.dropdown ? (
                    <li className="sidebar-item">
                      <a
                        data-target={`#${j.name.replace(/\s/g, "-")}`}
                        data-toggle="collapse"
                        className="sidebar-link collapsed"
                      >
                        <i className={j.icon}></i>
                        <span className="align-middle">{j.name}</span>
                      </a>
                      <ul
                        id={j.name.replace(/\s/g, "-")}
                        className={`sidebar-dropdown list-unstyled ${
                          j.dropdownData.some(({ url }) =>
                            history.location.pathname.includes(url)
                          )
                            ? ""
                            : "collapse"
                        }`}
                        data-parent="#sidebar"
                      >
                        {j.dropdownData.map((k, idx) => (
                          <li className="sidebar-item" key={idx}>
                            <NavLink
                              className="sidebar-link"
                              to={k.url}
                              exact
                              activeClassName="active_nav__admin_1"
                            >
                              {k.type}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li className="sidebar-item">
                      <NavLink
                        className="sidebar-link"
                        exact
                        activeClassName="active_nav0"
                        to={j.url}
                      >
                        {j?.faIcon === false ? (
                          <>{j.icon}</>
                        ) : (
                          <i className={j.icon}></i>
                        )}
                        <span className="align-middle">{j.name}</span>
                      </NavLink>
                    </li>
                  )}
                </>
              ))}
            </>
          ))}

          {/* <li className="sidebar-header">Personal Settings</li>

          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/retailer"
            >
              <i className="fas fa-tachometer-alt"></i>
              <span className="align-middle">Dashboard</span>
            </NavLink>
          </li>

          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/retailer/profile-settings"
            >
              <i className="fas fa-cogs"></i>
              <span className="align-middle">Settings & Privacy</span>
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/retailer/profile"
            >
              <i className="far fa-user-circle"></i>
              <span className="align-middle">Profile</span>
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/retailer/my-orders"
            >
              <i className="fas fa-signal"></i>
              <span className="align-middle">My Orders</span>
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/retailer/my-review"
            >
              <i className="fas fa-comments"></i>
              <span className="align-middle">My Reviews</span>
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/retailer/my-return-orders"
            >
              <MdFreeCancellation size="18px" />
              <span className="align-middle">My Return Orders</span>
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              activeClassName="active_nav01"
              to="/retailer/wishlist"
            >
              <AiOutlineHeart size="18px" />
              <span className="align-middle">WishList</span>
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              to="/retailer/stock"
              activeClassName="active_nav01"
            >
              <i className="fas fa-business-time"></i>
              <span className="align-middle">My Stocks</span>
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink
              className="sidebar-link"
              exact
              to="/retailer/quotation"
              activeClassName="active_nav01"
            >
              <i className="fas fa-bookmark"></i>
              <span className="align-middle">My Quotation</span>
            </NavLink>
          </li>
          <li className="sidebar-header">Tools & Orders</li>
          <li className="sidebar-item">
            <a
              data-target="#category"
              data-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i className="fas fa-bezier-curve"></i>
              <span className="align-middle">Orders</span>
            </a>
            <ul
              id="category"
              className={`sidebar-dropdown list-unstyled ${
                history?.location?.state?.prevPath ===
                  "/retailer/orders/view" ||
                history.location.pathname.includes("/retailer/orders/view") ||
                history?.location?.state?.prevPath ===
                  "/retailer/quotation-order" ||
                history.location.pathname.includes("/retailer/quotation-order")
                  ? ""
                  : "collapse"
              }`}
              data-parent="#sidebar"
            >
              <li className="sidebar-item">
                <NavLink
                  className="sidebar-link"
                  to="/retailer/orders/view"
                  exact
                  activeClassName="active_nav1"
                >
                  Show all Orders
                </NavLink>
              </li>
              <li className="sidebar-item">
                <NavLink
                  className="sidebar-link"
                  to="/retailer/quotation-order"
                  exact
                  activeClassName="active_nav1"
                >
                  Quotation Order
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              data-target="#CustomOrders"
              data-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i className="fas fa-boxes"></i>
              <span className="align-middle">Custom Orders</span>
            </a>
            <ul
              id="CustomOrders"
              className={`sidebar-dropdown list-unstyled ${
                history?.location?.state?.prevPath ===
                  "/retailer/custom-order" ||
                history.location.pathname.includes("/retailer/custom-order")
                  ? ""
                  : "collapse"
              }`}
              data-parent="#sidebar"
            >
              <li className="sidebar-item">
                <NavLink
                  className="sidebar-link"
                  to="/retailer/custom-order"
                  exact
                  activeClassName="active_nav1"
                >
                  Show Custom Orders
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              data-target="#Messages"
              data-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i className="fas fa-sms"></i>
              <span className="align-middle">Messages</span>
            </a>
            <ul
              id="Messages"
              className={`sidebar-dropdown list-unstyled ${
                history?.location?.state?.prevPath === "/retailer/message" ||
                history.location.pathname.includes("/retailer/message")
                  ? ""
                  : "collapse"
              }`}
              data-parent="#sidebar"
            >
              <li className="sidebar-item">
                <NavLink
                  className="sidebar-link"
                  to="/retailer/message"
                  exact
                  activeClassName="active_nav1"
                >
                  Show Messages
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="sidebar-header">Product</li>
          <li className="sidebar-item">
            <a
              data-target="#product"
              data-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i className="fab fa-product-hunt"></i>
              <span className="align-middle">Product</span>
            </a>
            <ul
              id="product"
              className={`sidebar-dropdown list-unstyled ${
                history?.location?.state?.prevPath ===
                  "/retailer/product/view" ||
                history.location.pathname.includes("/retailer/product/view")
                  ? ""
                  : "collapse"
              }`}
              data-parent="#sidebar"
            >
              <li className="sidebar-item">
                <NavLink
                  className="sidebar-link"
                  exact
                  activeClassName="active_nav1"
                  to="/retailer/product/view"
                >
                  Show Product
                </NavLink>
              </li>
            </ul>
          </li> */}
          {/* blank */}
          <li className="sidebar-header"></li>
          <li className="sidebar-item"></li>
        </ul>
      </div>
    </nav>
  );
};

export default LeftNavbar;
