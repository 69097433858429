import React from "react";
import { Redirect, useParams } from "react-router-dom";
import MyQuotationDetails from "../../Retailer/MyQuotation/MyQuotationDetails";
import MyQuotation from "../../RetailerWholeSaler/Quotation";
import Footer from "../Footer";
import LeftNavbar from "../LeftNavbar";
import TopNavbar from "../TopNavbar";

const MyQuotationPageWholesaler = () => {
  document.title = "WholeSaler | My Quotation";
  const { id } = useParams();
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token !== "ROLE_WHOLESALER") {
    return <Redirect exact to="/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            {id ? <MyQuotationDetails {...{ id }} /> : <MyQuotation />}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MyQuotationPageWholesaler;
