import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllApprovedQuoteByBuyer } from "../../Admin/Features/Action/QuotationAction";
import Spinner from "../../Admin/Spinner";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import ModalImage from "react-modal-image";
import { useHistory } from "react-router-dom";
import { Modal as Modals } from "react-responsive-modal";

const MyQuotationDetails = ({ id }) => {
  document.title = `Quotation Details`;

  const { approvedQuoteByBuyer, loader } = useSelector(
    (s) => s.QuotationsReducer
  );

  const [user, setUser] = useState();

  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });

  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };

  // get consumer details
  const getUsers = async (id) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const getu = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${id}`,
        config
      );

      history.location.state !== undefined &&
        AxiosConfig.get(`/kreesa/admin/get-user-by-user-id/${id}`, config);
      setUser(getu.data);
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useLayoutEffect(() => {
    dispatch(getAllApprovedQuoteByBuyer(id));
  }, [id]);

  return !loader ? (
    <div className="container">
      <div className="card-title mb-0 font-weight-bold">
        <h5>My Quotation Details</h5>
      </div>
      <div className="row mt-2">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="col-md-2">Expected Price</th>
                <th className="col-md-2">Product Name</th>
                <th className="col-md-2">Quantity</th>
                <th className="col-md-2">Image</th>
                <th className="col-md-2">status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="col-md-2 font-weight-bold">
                  Rs {approvedQuoteByBuyer?.expected_price}
                </td>
                <td className="col-md-2">
                  {approvedQuoteByBuyer?.product_name}
                </td>
                <td className="col-md-2">{approvedQuoteByBuyer?.quantity}</td>
                <td className="col-md-2">
                  <ModalImage
                    small={
                      approvedQuoteByBuyer?.image
                        ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${approvedQuoteByBuyer?.id}/${approvedQuoteByBuyer?.image}`
                        : "/images/kreesalogo.png"
                    }
                    large={
                      approvedQuoteByBuyer?.image
                        ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${approvedQuoteByBuyer?.id}/${approvedQuoteByBuyer?.image}`
                        : "/images/kreesalogo.png"
                    }
                    loading="lazy"
                    className="img-fluid quote__modal__img"
                    alt={approvedQuoteByBuyer?.product_name}
                    hideDownload={true}
                  />
                </td>
                <td className="col-md-2">
                  <p>
                    Admin Approval{" "}
                    {approvedQuoteByBuyer?.admin_approval === true ? (
                      <span className="badge bg-success">Approved</span>
                    ) : (
                      <span className="badge bg-danger">Pending</span>
                    )}
                  </p>

                  <p>
                    Saler Approval{" "}
                    {approvedQuoteByBuyer?.buyer_approval === true ? (
                      <span className="badge bg-success">Approved</span>
                    ) : (
                      <span className="badge bg-danger">Pending</span>
                    )}
                  </p>

                  <p>
                    User Approval{" "}
                    {approvedQuoteByBuyer?.user_approval === true ? (
                      <span className="badge bg-success">Approved</span>
                    ) : (
                      <>
                        <span className="badge bg-danger">Pending</span>
                      </>
                    )}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {approvedQuoteByBuyer?.sellers?.length > 0 && (
          <>
            <h4>Approved List of Seller</h4>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="col-md-2">seller(s) Detail</th>

                    <th className="col-md-2">Actual Price (Per Unit)</th>
                    <th className="col-md-2">Delivery Charge</th>
                    <th className="col-md-2">Delivery Days</th>
                    <th className="col-md-2">Image</th>
                    <th className="col-md-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {approvedQuoteByBuyer?.sellers?.map((item, index) => (
                    <tr key={index}>
                      {/* {localStorage.getItem("auth") === "ROLE_USER" ? ( */}
                        <td className="col-md-2 font-weight-bold">
                          Kreesa seller
                        </td>
                      {/* ) : (
                        <td className="col-md-2 cursor-pointer">
                          <span
                            className="font-weight-bold"
                            onClick={() => {
                              handleOpenModal(item.user.id);
                              getUsers(item.user.id);
                            }}
                          >
                            Kreesa Seller {index + 1}
                          </span>
                          {modal.show &&
                            modal.requestedModalId === item.user.id && (
                              <Modals
                                open={modal}
                                onClose={handleCloseModal}
                                center
                                classNames={{
                                  overlayAnimationIn:
                                    "customEnterOverlayAnimation",
                                  overlayAnimationOut:
                                    "customLeaveOverlayAnimation",
                                  modalAnimationIn: "customEnterModalAnimation",
                                  modalAnimationOut:
                                    "customLeaveModalAnimation",
                                  modal: "customModal",
                                }}
                                animationDuration={1000}
                              >
                                <div className="pro">
                                  {/* <div className="col-md-4">
                                  <div className="profile-img">
                                    <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${id}/${user.companyLogo}`} alt={user?.companyName} />
                                  </div>
                                </div>  

                                  <h3 className="text-uppercase">
                                    {user?.companyName}{" "}
                                  </h3>
                                </div>

                                <div className="col-md-12 m-auto">
                                  <div className="tab-content profile-tab custom-height">
                                    {user?.role === "ROLE_USER" ? (
                                      <div
                                        className="tab-pane fade show active"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                      >
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>User Name</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p>
                                              {user?.firstname} {user?.lastname}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Address</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p>{`${
                                              user?.streetAddress === null
                                                ? ""
                                                : user?.streetAddress + ","
                                            } ${user?.districtName}, ${
                                              user?.provienceName
                                            }, ${user?.countryName}`}</p>
                                          </div>
                                        </div>
                                        <div className="row mt-2">
                                          <div className="col-md-6">
                                            <label>Email</label>
                                          </div>
                                          <div className="col-md-6 ">
                                            <p>{user?.emailId}</p>
                                          </div>
                                        </div>
                                        <div className="row ">
                                          <div className="col-md-6">
                                            <label>Contact</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p>{user?.contact}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="tab-pane fade show active"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                      >
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Company Name</label>
                                          </div>
                                          <div className="col-md-6 ">
                                            <p className="text-capitalize">
                                              {user?.companyName}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Company Address</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p className="text-capitalize">
                                              <p>{`${
                                                user?.streetAddress === null
                                                  ? ""
                                                  : user?.streetAddress + ","
                                              } ${user?.districtName}, ${
                                                user?.provienceName
                                              }, ${user?.countryName}`}</p>
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Company Email</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p>{user?.companyEmail}</p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Company Type</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p className="text-capitalize">
                                              {user?.companyType}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Company Contact</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p> {user?.companyContactNo}</p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>PAN No</label>
                                          </div>
                                          <div className="col-md-6">
                                            <p> {user?.panNo}</p>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Register Status</label>
                                          </div>
                                          <div className="col-md-6">
                                            {user?.isRegistered === true ? (
                                              <p>Register</p>
                                            ) : (
                                              <p>Not Register</p>
                                            )}
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Insuranced Status</label>
                                          </div>
                                          <div className="col-md-6">
                                            {user?.isInsured === true ? (
                                              <p>Insuranced</p>
                                            ) : (
                                              <p>Not Insuranced</p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Modals>
                            )}
                        </td>
                      )} */}
                      <td className="col-md-2 font-weight-bold">
                        Rs {item?.actual_price}
                      </td>
                      <td className="col-md-2 font-weight-bold">
                        Rs {item?.deliveryCharge}
                      </td>

                      <td className="col-md-2 font-weight-bold">
                        {item?.deliveryDays}
                      </td>
                      <td className="col-md-2">
                        <ModalImage
                          small={
                            item?.image
                              ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${id}/${item?.id}/${item?.image}`
                              : "/images/kreesalogo.png"
                          }
                          large={
                            item?.image
                              ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${id}/${item?.id}/${item?.image}`
                              : "/images/kreesalogo.png"
                          }
                          loading="lazy"
                          className="img-fluid quote__modal__img"
                          alt={item?.image}
                          hideDownload={true}
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            history.push({
                              pathname: "/checkout-user",
                              state: [
                                {
                                  quoteId: item.id,
                                  shippingCost: item.deliveryCharge,
                                  orderQuantity: approvedQuoteByBuyer.quantity,
                                  name: approvedQuoteByBuyer.product_name,
                                  price: item.actual_price,
                                  discount: 0,
                                  discountPrice:
                                    item.actual_price -
                                    (item.actual_price * 0) / 100,
                                  discountedTotalPrice:
                                    (item.actual_price -
                                      (item.actual_price * 0) / 100) *
                                    approvedQuoteByBuyer.quantity,
                                  type: "quote",
                                },
                              ],
                            });
                          }}
                          className="dropdown-item text-info btn btn-primary"
                        >
                          <i className="fas fa-check"></i> Approve
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {approvedQuoteByBuyer.buyer_approval === false && (
          <div className="alert alert-danger p-3">
            <strong>
              <i className="fas fa-exclamation-triangle"></i>
              &nbsp;&nbsp;&nbsp;
              <span>
                This quote is not approved yet. Please wait for admin and
                sellers approval.
              </span>
            </strong>
          </div>
        )}
        {approvedQuoteByBuyer.admin_approval === true &&
          approvedQuoteByBuyer.buyer_approval === true &&
          approvedQuoteByBuyer.user_approval === true && (
            <div className="alert alert-success p-3">
              <strong>
                <i className="fa fa-check"></i>
                &nbsp;&nbsp;&nbsp;
                <span>This quotation order has been placed</span>
              </strong>
            </div>
          )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default MyQuotationDetails;
