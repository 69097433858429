import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useButtonLoader from "../../../ButtonLoaderHook";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useHistory } from "react-router-dom";

const AddForm = () => {
  const history = useHistory();
  const [description, setDescription] = useState(EditorState.createEmpty());
  const initialValues = {
    type: "",
  };
  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Company Type is required"),
  });
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add Company Type",
    "Loading..."
  );

  const addHandler = async (values) => {
    setButtonLoader(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const des = draftToHtml(convertToRaw(description.getCurrentContent()));
      const res = await AxiosConfig.post(
        "/kreesa/admin/company-type/add-company-type",
        { ...values, description: des },
        config
      );
      if (res) {
        toast.success("Company Type Add Successfully");
        setButtonLoader(false);
        history.push("/company-type");
      }
    } catch (err) {
      // toast.error("Something Went Wrong !");
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title font-weight-bold">Add Company Type Form</h5>
        <h6 className="card-subtitle text-muted">
          {" "}
          You Can Easily Add New Company Type.
        </h6>
      </div>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => addHandler(values)}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="col">
                <div className="mb-3 col-md-4">
                  <label className="form-label" htmlFor="inputCity">
                    <span className="font-weight-bold">Company Type</span>{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required="required"
                    name="type"
                    onChange={handleChange}
                    id="inputCategory"
                    placeholder="Enter Company Type"
                    value={values.type}
                  />
                  {errors.type && touched.type ? (
                    <div style={{ color: "red" }}>{errors.type}</div>
                  ) : null}
                </div>
              </div>
              <div className="col">
                <div className="mb-3 col-md-8">
                  <label className="form-label" htmlFor="inputCity">
                    <span className="font-weight-bold">
                      Company Type Description
                    </span>
                    <span className="text-info"> (optional)</span>
                  </label>
                  <Editor
                    editorState={description}
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{
                      backgroundColor: "white",
                      minHeight: "150px",
                      padding: "10px 20px",
                    }}
                    editorClassName="mt-2 p-10 mx-auto"
                  />
                </div>
              </div>
              <div className="mb-3 mt-4 col-md-4">
                <button
                  id="catadd"
                  type="submit"
                  className="btn btn-success btn-lg btn-block"
                  ref={buttonLoader}
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  Add Company Type
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddForm;
