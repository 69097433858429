import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleNewsBlogNotice } from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import HtmlParser from "react-html-parser";
import ModalImage from "react-modal-image";

const NewsBlogNoticeDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { blog, loader } = useSelector((s) => s.AdminReducer);

  useEffect(() => {
    dispatch(getSingleNewsBlogNotice(id));
  }, [id]);

  return !loader ? (
    <div className="card">
      <div className="p-2">
        <h3 className="news__blog_notice_blog">{blog?.title}</h3>
        <h3 className="news__blog_notice_title">{blog?.blogType}</h3>
        <div className="row">
          <div className="col-12 col-lg-6 pb-3 pb-lg-0">
            {/* <img
              src={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
              alt={blog?.title}
              className="mt-2"
              loading="lazy"
              width={"100%"}
              style={{
                borderRadius: 5,
                objectFit: "cover",
              }}
            /> */}
            <ModalImage
              alt={blog?.title}
              small={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
              large={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
              className="img-fluid"
              style={{
                objectFit: "cover",
              }}
              loading="lazy"
              showRotate={true}
              hideDownload={true}
            />
          </div>

          <div className="col-12 col-lg-6 ">
            <h3 className="text-left">Short Description</h3>
            <p className="text-capitalize">{HtmlParser(blog?.shortDesc)}</p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <h3 className="text-left">Long Description</h3>
            <p className="text-capitalize">{HtmlParser(blog?.longDesc)}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default NewsBlogNoticeDetails;
