import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to get all Primary Categories */
export const getPrimaryCategory = createAsyncThunk("primaryCategory", async () => {
  // primaryCategory/pending
  // primaryCategory/fulfilled
  // primaryCategory/rejected
  const response = await AxiosConfig.get("/kreesa/admin/category/primary/view", config);
  return response.data;
});

/* to get all Primary Categories */
export const deletePrimaryCategory = createAsyncThunk("deletePrimaryCategory", async (id, { dispatch }) => {
  // deletePrimaryCategory/pending
  // deletePrimaryCategory/fulfilled
  // deletePrimaryCategory/rejected
  const response = await AxiosConfig.get(`/kreesa/admin/category/primary/delete/${id}`, config);
  dispatch(getPrimaryCategory());
  return response.data;
});

/* to get all Secondary Categories */
export const getSecondaryCategory = createAsyncThunk("secondaryCategory", async () => {
  // secondaryCategory/pending
  // secondaryCategory/fulfilled
  // secondaryCategory/rejected
  const response = await AxiosConfig.get("/kreesa/admin/category/secondary/view", config);
  return response.data;
});
/* to deleteSecondaryCategory */
export const deleteSecondaryCategory = createAsyncThunk("deleteSecondaryCategory", async (id, { dispatch }) => {
  // deleteSecondaryCategory/pending
  // deleteSecondaryCategory/fulfilled
  // deleteSecondaryCategory/rejected
  const response = await AxiosConfig.get(`/kreesa/admin/category/secondary/delete/${id}`, config);
    dispatch(getSecondaryCategory());
  return response.data;
});

/* to get all Ternary Categories */
export const getTernaryCategory = createAsyncThunk("ternaryCategory", async () => {
  // ternaryCategory/pending
  // ternaryCategory/fulfilled
  // ternaryCategory/rejected
  const response = await AxiosConfig.get("/kreesa/admin/category/ternary/view", config);
  return response.data;
});
/* to get all Ternary Categories */
export const deleteTernaryCategory = createAsyncThunk("deleteTernaryCategory", async (id, { dispatch }) => {
  // deleteTernaryCategory/pending
  // deleteTernaryCategory/fulfilled
  // deleteTernaryCategory/rejected
  const response = await AxiosConfig.get(`/kreesa/admin/category/ternary/delete/${id}`, config);
  dispatch(getTernaryCategory());
  return response.data;
});

// to edit primary category
export const editPrimaryCategory = createAsyncThunk("editPrimaryCategory", async (id) => {
  // editPrimaryCategory/pending
  // editPrimaryCategory/fulfilled
  // editPrimaryCategory/rejected
  const response = await AxiosConfig.get(`/kreesa/admin/category/primary/view/${id}`, config);
  return response.data;
});

export const editSecondaryCategory = createAsyncThunk("editSecondaryCategory", async (id) => {
  // editSecondaryCategory/pending
  // editSecondaryCategory/fulfilled
  // editSecondaryCategory/rejected
  const response = await AxiosConfig.get(`/kreesa/admin/category/secondary/view/${id}`, config);
  return response.data;
});

export const editTernaryCategory = createAsyncThunk("editTernaryCategory", async (id) => {
  // editSecondaryCategory/pending
  // editSecondaryCategory/fulfilled
  // editSecondaryCategory/rejected
  const response = await AxiosConfig.get(`/kreesa/admin/category/ternary/view/${id}`, config);
  return response.data;
});
