import React from "react";
import Footer from "../Footer";
import TopNavbar from "../TopNavbar";
import LeftNavbar from "../LeftNavbar";
import { Redirect, useParams } from "react-router-dom";
import ProductExportInExcel from "../ExportInExcel/ProductExportInExcel";

const Report = () => {
  document.title = "Admin | Report";
  const { type } = useParams();

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            {type === "product" && (
              <ProductExportInExcel
                currentUser={["ROLE_RETAILER", "ROLE_WHOLESALER"]}
              />
            )}
            {type === "order" && (
              <ProductExportInExcel
                currentUser={["ROLE_USER", "ROLE_RETAILER", "ROLE_WHOLESALER"]}
              />
            )}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Report;
