import React from "react";
import { useState } from "react";
import RatingStar from "../../../Widgets/RatingStar/RatingStar";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";

const Review = ({ modal, setModal, data }) => {
  const [star, setStar] = useState(0);
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState("");

  const handleSubmit = () => {
    if (star !== 0 && review.length > 0) {
      setLoading(true);
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      AxiosConfig.post(
        "/kreesa/place-review",
        {
          orderDetailId: data,
          review,
          star,
          userId: +localStorage.getItem("userId"),
        },
        config
      )
        .then((res) => {
          setStar(0);
          setReview("");
          toast.success(res.data);
          setModal(false);
        })
        .catch((err) => {
          toast.error("An error occurred");
          setStar(0);
          setReview("");
        });
      setLoading(false);
    } else {
      toast.error("Please fill up the form");
    }
  };

  return (
    <div>
      <Modal
        show={modal}
        centered
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Body>
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="card-body text-center">
                {" "}
                <img
                  src=" https://i.imgur.com/d2dKtI7.png"
                  height="100"
                  width="100"
                  alt="kreesa-review"
                />
                <div class="comment-box text-center">
                  <h4>Give Review</h4>
                  <div class="rating">
                    <div className="rating-main col-sm-12 col-md-12 col-lg-12 text-center mt-3">
                      <Rating
                        onClick={(rate) => setStar(rate)}
                        ratingValue={star}
                        size={30}
                      />
                    </div>
                  </div>
                  <div class="comment-area">
                    {" "}
                    <textarea
                      class="form-control"
                      placeholder="what is your view?"
                      rows="4"
                      onChange={(e) => setReview(e.target.value)}
                    ></textarea>
                  </div>
                  <div class="text-center mt-4">
                    <button
                      disabled={loading}
                      class={`btn btn-success send pr-4 pl-5`}
                      onClick={() => handleSubmit()}
                    >
                      {loading ? "Loading..." : "Submit"}{" "}
                      <i class="fa fa-long-arrow-right ml-1"></i>
                    </button>
                    <button
                      disabled={loading}
                      class="btn btn-danger send ml-3 pr-4 pl-5"
                      onClick={() => {
                        setModal(false);
                      }}
                    >
                      {loading ? "Loading..." : "Cancel"}{" "}
                      <i class="fa fa-long-arrow-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Review;
