import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCountries,
  getAllProvince,
  getSingleCountries,
  getSingleProvince,
  getAllDistrict,
  getSingleDistrict,
} from "../../Action/AddressAction";

const AddressReducer = createSlice({
  name: "Country",
  initialState: {
    countries: [],
    country: [],
    districts: [],
    proviences: [],
    provience: [],
    proviencesContainer: [],
    countriesContainer: [],
    districts: [],
    district: [],
    districtsContainer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterCountry: (state, action) => {
      state.countries = state.countriesContainer.filter(
        (u) =>
          u.country_name.toLowerCase().includes(action.payload) ||
          u.country_name.toUpperCase().includes(action.payload)
      );
    },
    filterProvince: (state, action) => {
      state.proviences = state.proviencesContainer.filter(
        (u) =>
          u.provience_name.toLowerCase().includes(action.payload) ||
          u.provience_name.toUpperCase().includes(action.payload)
      );
    },
    filterDistrict: (state, action) => {
      state.districts = state.districtsContainer.filter(
        (u) =>
          u.district_name.toLowerCase().includes(action.payload) ||
          u.district_name.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* -------------------------- to get all Countries -------------------------- */
    [getAllCountries.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllCountries.fulfilled]: (state, action) => {
      state.loader = false;
      state.countries = action.payload;
      state.countriesContainer = action.payload;
    },
    [getAllCountries.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* ------------------------- to get single Countries ------------------------ */
    [getSingleCountries.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleCountries.fulfilled]: (state, action) => {
      state.loader = false;
      state.country = action.payload;
    },
    [getSingleCountries.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* -------------------------- to get all proviences ------------------------- */
    [getAllProvince.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllProvince.fulfilled]: (state, action) => {
      state.loader = false;
      state.proviences = action.payload;
      state.proviencesContainer = action.payload;
    },
    [getAllProvince.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* ------------------------ to get single proviences ------------------------ */
    [getSingleProvince.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleProvince.fulfilled]: (state, action) => {
      state.loader = false;
      state.provience = action.payload;
    },
    [getSingleProvince.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* --------------------------- to get all district -------------------------- */
    [getAllDistrict.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllDistrict.fulfilled]: (state, action) => {
      state.loader = false;
      state.districts = action.payload;
      state.districtsContainer = action.payload;
    },
    [getAllDistrict.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* ------------------------- to get single district ------------------------- */
    [getSingleDistrict.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleDistrict.fulfilled]: (state, action) => {
      state.loader = false;
      state.district = action.payload;
    },
    [getSingleDistrict.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});
// export
export const { filterCountry, filterProvince, filterDistrict } =
  AddressReducer.actions;

// export default
export default AddressReducer.reducer;
