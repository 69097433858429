import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

//view
export const viewAllDiscountVoucher = createAsyncThunk(
  "admin/viewAllDiscountVoucher",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/voucher/admin/view`,
      config
    ).catch((err) => toast.error("An Error Occurred"));
    return response.data;
  }
);

//view
export const deleteDiscountVoucher = createAsyncThunk(
  "admin/deleteDiscountVoucher",
  async (id, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/voucher/admin/delete/${id}`,
      config
    )
      .then((res) => {
        toast.success("Voucher Removed");
        dispatch(viewAllDiscountVoucher());
      })
      .catch((err) => toast.error("An Error Occurred"));
    return response.data;
  }
);
