import React from "react";
import { useSelector } from "react-redux";
import MyReturnOrder from "../../Retailer/ReturnOrders/MyReturnOrder";

function OrderReturn() {
  const { userOrder } = useSelector((state) => state.userProducts);

  return (
    <div>
      {/* <div
        className="form-row col-7 mt-4 collapse card-header"
        id="collapseTwo"
        aria-labelledby="headingTwo"
        data-parent="#accordion"
      >
        order return
      </div> */}
      <MyReturnOrder />
    </div>
  );
}

export default OrderReturn;
