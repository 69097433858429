import { createSlice } from "@reduxjs/toolkit";
import { getAllEnquiries } from "../../Action/CompanyTypeAction/CompanyTypeAction";

const EnquiriesReducer = createSlice({
  name: "Enquiries",
  initialState: {
    allEnquiries: [],
    allEnquiriesContainer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterEnquiries: (state, action) => {
      state.allEnquiries = state.allEnquiriesContainer.filter((u) =>
        u.email.toLowerCase().includes(action.payload) ||
        u.email.toUpperCase().includes(action.payload)
        // u.productName.toLowerCase().includes(action.payload) ||
        // u.productName.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Products */
    [getAllEnquiries.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllEnquiries.fulfilled]: (state, action) => {
      state.loader = false;
      state.allEnquiries = action.payload;
      state.allEnquiriesContainer = action.payload;
    },
    [getAllEnquiries.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterEnquiries } = EnquiriesReducer.actions;
// export default
export default EnquiriesReducer.reducer;
