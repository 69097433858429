import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {  useParams } from "react-router-dom";
import { exportProductInExcel } from "../Features/Action/AdminBlockUnblockAction";
import AxiosConfig from "../../../Common/Api/AxiosConfig";

const validationSchema = Yup.object().shape({
  start_date: Yup.date()
    .required("Start date is required")
    .max(new Date(), "Start date must be less than or equal to current date"),
  end_date: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start_date"), "End date must be equal or after start date")
    .max(new Date(), "End date must be less than or equal to current date"),
});

const ProductExportInExcel = (props) => {
  const currentUser = props.currentUser;
  const [state, setstate] = useState([]);
  const dispatch = useDispatch();
  const { type } = useParams();

  const exportProduct = async (inputValues) => {
    if (inputValues.report_type === "INDIVIDUAL" && inputValues.user_id == 0) {
      return toast.error("User is required");
    }
    const result = await dispatch(exportProductInExcel({ ...inputValues, type}));
    const fulfilled = result.meta.requestStatus === "fulfilled";

    const fileName = result.payload;

    if (fulfilled) {
      const url =
        AxiosConfig.defaults.baseURL + `/kreesa/document/report/${fileName}`;
      const newTab = window.open(url, "_blank");
      setTimeout(() => {
        newTab.close();
      }, 5000);
    }
  };

  //   fetchUsers
  const fetchUsers = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const users = await AxiosConfig.post(
        "kreesa/admin/report/users",
        { userType: currentUser },
        config
      );
      setstate(users.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, [currentUser]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Formik
                initialValues={{
                  start_date: "",
                  end_date: "",
                  report_type: "ALL",
                  user_id: 0,
                }}
                onSubmit={exportProduct}
                validationSchema={validationSchema}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  isValid,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="start_date">Start Date:</label>
                        <input
                          type="date"
                          id="start_date"
                          name="start_date"
                          value={values.start_date}
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.start_date && touched.start_date ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {errors.start_date}{" "}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="end_date">End Date:</label>
                        <input
                          type="date"
                          id="end_date"
                          name="end_date"
                          value={values.end_date}
                          onChange={handleChange}
                          className="form-control"
                        />
                        {errors.end_date && touched.end_date ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {errors.end_date}{" "}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="report_type">Report Type:</label>
                        <select
                          id="report_type"
                          name="report_type"
                          value={values.report_type}
                          onChange={handleChange}
                          className="form-control"
                        >
                          <option value="ALL">All</option>
                          <option value="INDIVIDUAL">Individual</option>
                        </select>
                      </div>
                      <div
                        className="col-md-6"
                        style={{
                          cursor:
                            values.report_type === "ALL"
                              ? "not-allowed"
                              : "default",
                        }}
                      >
                        <label htmlFor="Users">User</label>
                        <Select
                          isDisabled={values.report_type === "ALL"}
                          options={state?.map((user) => ({
                            value: user.id,
                            label: user.company_name === null
                              ? user.first_name + " " + user.last_name + " - User"
                              : user.company_name + " - Seller",
                          }))}
                          name="user_id"
                          onChange={(event) =>
                            setFieldValue("user_id", event.value)
                          }
                          id="users"
                        />
                        {errors.user_id && touched.user_id ? (
                          <div style={{ color: "red" }}> {errors.user_id} </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        size="lg"
                        variant="primary"
                        onClick={handleSubmit}
                      >
                      <span className="fas fa-file-excel-o"></span>  Export {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductExportInExcel;

ProductExportInExcel.propTypes = {
  currentUser: PropTypes.string.isRequired,
};
