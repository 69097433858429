import React, { useEffect, useState } from "react";
import "./Banner.css";
import AxiosConfig from "../../Common/Api/AxiosConfig";

function Banner() {
  const [state, setState] = useState([]);

  const getcustomerBanner = async () => {
    try {
      const customer = "customer";
      const seller = "seller";
      const getBanner = await AxiosConfig.get(
        localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
          localStorage.getItem("auth") === "ROLE_RETAILER"
          ? `/kreesa/admin/banner/get-alll-banner-by-type/${seller}`
          : `/kreesa/admin/banner/get-alll-banner-by-type/${customer}`
      );
      setState(
        getBanner.data?.filter((item) => item.bannerPosition === "BOTTOM")
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getcustomerBanner();
  }, []);
  return (
    <div
      className="container-fluid px-lg-6"
      style={{
        // backgroundColor: "#e2e6eb",
        backgroundColor:
          localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
          localStorage.getItem("auth") === "ROLE_RETAILER"
            ? ""
            : "#e2e6eb",
      }}
    >
      {/* <div className="banner1">
          {/* <video autoPlay="autoplay" className="bv" muted>
            <source src={banner4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
      <div className="row">
        {state?.map((item, index) => (
          <div className="col-md-4 mt-sm-4 mt-md-5" key={index}>
            {item.redirectionLink === null ? (
              <img
                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${item?.id}/${item?.image}`}
                className="banner-subimage img-fluid"
                alt={item?.image}
              />
            ) : (
              <a
                href={item.redirectionLink}
                target={`${
                  item.redirectionLink?.slice(0, 22) ===
                  "https://www.kreesa.com"
                    ? "_self"
                    : "_blank"
                }`}
              >
                <img
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${item?.id}/${item?.image}`}
                  className="banner-subimage img-fluid"
                  alt={item?.image}
                />
              </a>
            )}
          </div>
        ))}
      </div>

      {/* <div className="banner1"> */}
      {/* <div className="sub-banner1"> */}
      {/* <img alt="imaagesgs" src={banner6} className="banner-subimage" /> */}
      {/* </div> */}
      {/* <div className="sub-banner2"> */}
      {/* <img alt="imaagesgs" src={banner6} className="banner-subimage" /> */}
      {/* </div> */}
      {/* </div>  */}
    </div>
  );
}

export default Banner;
