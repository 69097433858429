import $ from "jquery";
import AxiosConfig from "../../../Common/Api/AxiosConfig";

// already email check
export function checkemail() {
  var email = document.getElementById("email").value;
  // console.log(email);
  var l = AxiosConfig.defaults.baseURL + `/kreesa/check/verify-email/${email}`;
  // console.log(l);
  if (email) {
    $.ajax({
      type: "get",
      url: l,

      success: function (response) {
        if (response.status === "false") {
          document.getElementById("email-errors").innerHTML =
            "Email Already Exists";
          document.getElementById("email-errors").style.visibility = "visible";
          document.getElementById("email").style.borderColor = "#dc3545";
          document.getElementById("sends").disabled = true;
          document.getElementById("pas").disabled = true;
        } else if (response.status === "true") {
          document.getElementById("email-errors").style.visibility = "hidden";
          document.getElementById("email").style.borderColor = "";
          document.getElementById("sends").disabled = false;
          document.getElementById("pas").disabled = false;
        }
      },
    });
  } else {
    $("#email-errors").html("");
    return false;
  }
}

export function checkPhone() {
  var chkphone = document.getElementById("phone").value;
  var existPhone =
    AxiosConfig.defaults.baseURL + `/kreesa/check/verify-phone/${chkphone}`;
  if (chkphone) {
    $.ajax({
      type: "get",
      url: existPhone,

      success: function (response) {
        if (response.status === "false") {
          document.getElementById("phone-verify-error").innerHTML =
            "Phone Already Exists";
          document.getElementById("phone-verify-error").style.visibility =
            "visible";
          document.getElementById("phone").style.borderColor = "#dc3545";
          document.getElementById("sends").disabled = true;
          document.getElementById("email").disabled = true;
          document.getElementById("pas").disabled = true;
          // document.getElementById("sends").style.visibility = "hidden";
        } else if (response.status === "true") {
          document.getElementById("phone-verify-error").style.visibility =
            "hidden";
          document.getElementById("phone").style.borderColor = "";
          document.getElementById("sends").disabled = false;
          document.getElementById("email").disabled = false;
          document.getElementById("pas").disabled = false;

          // document.getElementById("sends").style.visibility = "visible";
        }
      },
    });
  } else {
    $("#phone-verify-error").html("");
    return false;
  }
}
