import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../../Components/Wholesaler";
import WholesalerShowProduct from "../../Components/Wholesaler/Product/ShowProduct";
import WholesalerAddProduct from "../../Components/Wholesaler/Product/AddProduct";
import Profile from "../../Components/Wholesaler/Profile";
import Settings from "../../Components/Wholesaler/Settings";
import ShowOrders from "../../Components/Wholesaler/ShowOrders";
import MyOrder from "../../Components/Wholesaler/MyOrder";
import ShowCustomOrder from "../../Components/Wholesaler/CustomOrder/ShowCustomOrder";
import AddCustomOrder from "../../Components/Wholesaler/CustomOrder/AddCustomOrder";
import SingleMyOrder from "../../Components/Wholesaler/MyOrder/SingleOrderDetail";
import SingleOrder from "../../Components/Wholesaler/ShowOrders/SingleOrderDetail";
import EditCustomOrder from "../../Components/Wholesaler/CustomOrder/EditCustomOrder";
import SalerRouting from "../PrivateRoute/salerRouting";
import WholeSalerReviewById from "../../Components/Wholesaler/Product/ShowProduct/WholeSalerReviewById";
import Stocks from "../../Components/Wholesaler/Stocks";
import RevieWholesalerSingleStock from "../../Components/Wholesaler/Stocks/ReviewWholesalerSingleStock";
import Message from "../../Components/Wholesaler/Message";
import MyQuotationPageWholesaler from "../../Components/Wholesaler/MyQuotation";
import AssignQuotation from "../../Components/Wholesaler/AssignQuotation";
import WholesalerReturnOrder from "../../Components/Wholesaler/ReturnOrders";
import Wishlist from "../../Components/Wholesaler/WishList";
import ShowReviewWholesale from "../../Components/Wholesaler/MyReview";
import Checkout from "../../Components/User/Checkout";
import PayNow from "../../Components/User/PayNow";
import Notifications from "../../Components/Wholesaler/Notification";
import MyPlatnedTreeWholesale from "../../Components/Wholesaler/Tree";

const WholeSalersRoute = () => {
  return (
    <Switch>
      {/* WholeSaler Route start*/}
      <SalerRouting exact path="/wholesaler" component={Home} />
      {/* Quotation order */}
      <SalerRouting
        exact
        path="/wholesaler/quotation-order"
        component={AssignQuotation}
      />

      {/* Product */}
      <SalerRouting
        exact
        path="/wholesaler/product/view"
        component={WholesalerShowProduct}
      />
      <SalerRouting
        exact
        path="/wholesaler/product/register"
        component={WholesalerAddProduct}
      />
      <SalerRouting
        exact
        path="/wholesaler/product/single-view/:id"
        component={WholeSalerReviewById}
      />
      <SalerRouting
        exact
        path="/wholesaler/all-notification"
        component={Notifications}
      />

      {/* WholeSaler Profile Settings */}
      <SalerRouting
        exact
        path="/wholesaler/profile-settings"
        component={Settings}
      />
      <SalerRouting exact path="/wholesaler/profile" component={Profile} />

      {/* /WholeSaler/orders/view */}
      <SalerRouting
        exact
        path="/wholesaler/orders/view"
        component={ShowOrders}
      />

      <SalerRouting
        exact
        path="/wholesaler/quotation"
        component={MyQuotationPageWholesaler}
      />
      <SalerRouting
        exact
        path="/wholesaler/quotation/:id"
        component={MyQuotationPageWholesaler}
      />

      {/* Retailor Custom Order */}
      <SalerRouting
        exact
        path="/wholesaler/custom-order"
        component={ShowCustomOrder}
      />
      <SalerRouting
        exact
        path="/wholesaler/edit-custom-order/:customId"
        component={EditCustomOrder}
      />
      <SalerRouting
        exact
        path="/wholesaler/custom-order/register/:type"
        component={AddCustomOrder}
      />

      <SalerRouting
        exact
        path="/wholesaler/my-return-orders"
        component={WholesalerReturnOrder}
      />

      <SalerRouting exact path="/wholesaler/my-orders" component={MyOrder} />
      <SalerRouting
        exact
        path="/wholesaler/my-orders/:id"
        component={SingleMyOrder}
      />
      <SalerRouting
        exact
        path="/wholesaler/my-review"
        component={ShowReviewWholesale}
      />
      <SalerRouting
        exact
        path="/wholesaler/orders/:id"
        component={SingleOrder}
      />
      <SalerRouting exact path="/wholesaler/pay-now" component={PayNow} />

      {/* Stock  */}
      <SalerRouting exact path="/wholesaler/stock" component={Stocks} />
      <SalerRouting
        exact
        path="/wholesaler/singlepage-stock/:stockId"
        component={RevieWholesalerSingleStock}
      />
      <SalerRouting exact path="/wholesaler/message" component={Message} />
      <SalerRouting exact path="/wholesaler/wishlist" component={Wishlist} />

      {/* Tree Plantation */}

      <SalerRouting exact path="/wholesaler/tree/my-planted-tree" component={MyPlatnedTreeWholesale} />

      {/* WholeSaler Route End*/}
    </Switch>
  );
};

export default WholeSalersRoute;
