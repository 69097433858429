import React, { useEffect, useState } from "react";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import "./style.css";

const Dashboard = () => {
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSummary = async () => {
    setLoading(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.get(
        `/kreesa/wholesaler/get-dashboard-summary/${localStorage.getItem(
          "userId"
        )}`,
        config
      );
      setSummary(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSummary();
  }, []);

  return !loading ? (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto d-none d-sm-block">
          <h2>
            {" "}
            <strong>Analytics</strong> Dashboard
          </h2>
        </div>

        <div className="col-auto ml-auto text-right mt-n1">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
              <li className="breadcrumb-item">
                <a href="/wholesaler">Kreesa</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/wholesaler">Dashboards</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Analytics
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="row">
        <div className="w-100">
          <div className="row" id="product-details">
            <h5 className="mb-4 h3">
              <strong>Product Details</strong>
            </h5>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[0]?.totalProduct}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Total Products</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 h1 mb-3 text-center">
                    {" "}
                    {summary[0]?.totalApproveProduct}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Approved Products</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 h1 mb-3 text-center">
                    {" "}
                    {summary[0]?.totalBlockedProduct}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Block Products</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[0]?.totalPendingProduct}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Pending Products</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[0]?.totalRejectProduct}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Rejected Products</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-xl-6 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Recent Movement</h5>
            </div>
            <div className="card-body py-3">
              <div className="chart chart-sm">
                <canvas id="chartjs-dashboard-line"></canvas>
              </div>
            </div>
          </div>
        </div> */}
        </div>

        {/* <div className="col-xl-6 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Recent Movement</h5>
            </div>
            <div className="card-body py-3">
              <div className="chart chart-sm">
                <canvas id="chartjs-dashboard-line"></canvas>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="w-100">
          <div className="row" id="order-details">
            <h5 className="h3 mb-4">
              <strong> Orders Details </strong>
            </h5>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[1]?.totalOrder}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Total Orders</p>

                  {/* <div className="mb-1">
                      <span className="text-success">
                        <i className="mdi mdi-arrow-bottom-right"></i> 5.25%
                      </span>
                      <span className="text-muted">Since last week</span>
                    </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[1]?.ApprovedOrder}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Approved Orders</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[1]?.pendingOrder}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Pending Orders</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[1]?.RejectedOrder}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Rejected Orders</p>
                  {/* <div className="mb-1">
                      <span className="text-success">
                        <i className="mdi mdi-arrow-bottom-right"></i> 5.25%
                      </span>
                      <span className="text-muted">Since last week</span>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-6 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Recent Movement</h5>
            </div>
            <div className="card-body py-3">
              <div className="chart chart-sm">
                <canvas id="chartjs-dashboard-line"></canvas>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="row">
        <div className="w-100">
          <div className="row" id="other-details">
            <h5 className="h3 mb-4">
              {" "}
              <strong> Other Details </strong>
            </h5>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[2]?.totalOrder}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Total Orders</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[2]?.totalProductSold === null
                      ? 0
                      : summary[2]?.totalProductSold}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Product Sold</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[2]?.totalRevenueCollected === null
                      ? 0
                      : summary[2]?.totalRevenueCollected}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Revenue Collected</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[2]?.OrderDeliver}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Order Deliver</p>
                  {/* <div className="mb-1">
                      <span className="text-success">
                        <i className="mdi mdi-arrow-bottom-right"></i> 5.25%
                      </span>
                      <span className="text-muted">Since last week</span>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {summary[2]?.pendingOrder}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Pending Order</p>
                  {/* <div className="mb-1">
                      <span className="text-success">
                        <i className="mdi mdi-arrow-bottom-right"></i> 5.25%
                      </span>
                      <span className="text-muted">Since last week</span>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-6 col-xxl-7">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Recent Movement</h5>
            </div>
            <div className="card-body py-3">
              <div className="chart chart-sm">
                <canvas id="chartjs-dashboard-line"></canvas>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-xxl-3 d-flex order-2 order-xxl-3">
          {/* <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Browser Usage</h5>
            </div>
            <div className="card-body d-flex">
              <div className="align-self-center w-100">
                <div className="py-3">
                  <div className="chart chart-xs">
                    <canvas id="chartjs-dashboard-pie"></canvas>
                  </div>
                </div>

                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>Chrome</td>
                      <td className="text-right">4306</td>
                    </tr>
                    <tr>
                      <td>Firefox</td>
                      <td className="text-right">3801</td>
                    </tr>
                    <tr>
                      <td>IE</td>
                      <td className="text-right">1689</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="col-12 col-md-12 col-xxl-6 d-flex order-3 order-xxl-2">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Real-Time</h5>
            </div>
            <div className="card-body px-4">
              <div id="world_map" style={{ height: "350px" }}></div>
            </div>
          </div>
        </div> */}
        <div className="col-12 col-md-6 col-xxl-3 d-flex order-1 order-xxl-1">
          {/* <div className="card flex-fill">
            <div className="card-header">
              <h5 className="card-title mb-0">Calendar</h5>
            </div>
            <div className="card-body d-flex">
              <div className="align-self-center w-100">
                <div className="chart">
                  <div id="datetimepicker-dashboard"></div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* 
      <div className="row">
        <div className="col-12 col-lg-8 col-xxl-9 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <h5 className="card-title mb-0">Latest Projects</h5>
            </div>
            <table className="table table-hover my-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="d-none d-xl-table-cell">Start Date</th>
                  <th className="d-none d-xl-table-cell">End Date</th>
                  <th>Status</th>
                  <th className="d-none d-md-table-cell">Assignee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Project Apollo</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Vanessa Tucker</td>
                </tr>
                <tr>
                  <td>Project Fireball</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-danger">Cancelled</span>
                  </td>
                  <td className="d-none d-md-table-cell">William Harris</td>
                </tr>
                <tr>
                  <td>Project Hades</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Sharon Lessman</td>
                </tr>
                <tr>
                  <td>Project Nitro</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-warning">In progress</span>
                  </td>
                  <td className="d-none d-md-table-cell">Vanessa Tucker</td>
                </tr>
                <tr>
                  <td>Project Phoenix</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">William Harris</td>
                </tr>
                <tr>
                  <td>Project X</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Sharon Lessman</td>
                </tr>
                <tr>
                  <td>Project Romeo</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-success">Done</span>
                  </td>
                  <td className="d-none d-md-table-cell">Christina Mason</td>
                </tr>
                <tr>
                  <td>Project Wombat</td>
                  <td className="d-none d-xl-table-cell">01/01/2020</td>
                  <td className="d-none d-xl-table-cell">31/06/2020</td>
                  <td>
                    <span className="badge bg-warning">In progress</span>
                  </td>
                  <td className="d-none d-md-table-cell">William Harris</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3 d-flex">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <h5 className="card-title mb-0">Monthly Sales</h5>
            </div>
            <div className="card-body d-flex w-100">
              <div className="align-self-center chart chart-lg">
                <canvas id="chartjs-dashboard-bar"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  ) : (
    <Spinner />
  );
};

export default Dashboard;
