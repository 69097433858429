import React from "react";
import "./Contact.css";
import Header from "../Header";
import Nav from "../Nav";
import Footer from "../Footer";
import useWindowDimensions from "../../../Common/WindowDimensions/WindowDimensions";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../ButtonLoaderHook";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  company: Yup.string().required("Company is required"),
  contact_details: Yup.number().required("Contact is required"),
  message: Yup.string().required("Message is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
});

const Index = () => {
  document.title = "Kreesa | Contact";
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Send Message",
    "Loading..."
  );

  const { width } = useWindowDimensions;
  /* ------------------------------- contact-us ------------------------------- */
  const contactHandler = async (values, submitProps) => {
    setButtonLoader(true);
    try {
      const res = await AxiosConfig.post("/kreesa/submit-contactUs-details", {
        ...values,
      });
      if (res && res.status === 200) {
        toast.success("Message sent successfully");
        setButtonLoader(false);
        submitProps.resetForm();
      }
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };
  return (
    <div>
      <Header />
      <Nav />
      <div>
        <h3 className="p-4">
          {" "}
          Please use the form below to send us your comments and feedback. We
          appreciate you taking the time to provide us with your views so that
          we can best meet the need of users.
        </h3>
      </div>
      <div className="container-fluid contacts-container mb-4">
        <div className="contact-form">
          <h3>Contact Form</h3>
          <Formik
            initialValues={{
              name: "",
              email: "",
              contact_details: "",
              message: "",
              company: "",
            }}
            onSubmit={contactHandler}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group d-flex contact-item">
                  <div>
                    <label for="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      className="form-control"
                      id="name"
                    />
                    {errors.name && touched.name ? (
                      <div style={{ color: "red" }}> {errors.name} </div>
                    ) : null}
                  </div>
                  <div>
                    <label for="company">Company</label>
                    <input
                      type="text"
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                      className="form-control"
                      id="company"
                    />
                    {errors.company && touched.company ? (
                      <div style={{ color: "red" }}> {errors.company} </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group d-flex contact-item">
                  <div>
                    <label for="email">Email address</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      className="form-control"
                      id="email"
                    />
                    {errors.email && touched.email ? (
                      <div style={{ color: "red" }}> {errors.email} </div>
                    ) : null}
                  </div>
                  <div>
                    <label for="company">Contact</label>
                    <input
                      name="contact_details"
                      value={values.contact_details}
                      onChange={handleChange}
                      type="tel"
                      className="form-control"
                      id="contact"
                    />
                    {errors.contact_details && touched.contact_details ? (
                      <div style={{ color: "red" }}>
                        {" "}
                        {errors.contact_details}{" "}
                      </div>
                    ) : null}
                  </div>{" "}
                </div>
                <div className="form-group contact-item">
                  <label for="company">Message</label>
                  <textarea
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    rows={3}
                    id="company"
                  />
                  {errors.message && touched.message ? (
                    <div style={{ color: "red" }}> {errors.message} </div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  ref={buttonLoader}
                  className="btn"
                >
                  Send Message
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="location-details">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14127.688100048745!2d85.2791815!3d27.7196937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1a0a0e1dfdbd435f!2sKreesa%20Sales%20Private%20Limited!5e0!3m2!1sen!2snp!4v1641112127766!5m2!1sen!2snp"
            className="map-location"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
