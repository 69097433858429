import { createAsyncThunk } from '@reduxjs/toolkit';
import AxiosConfig from '../../../../Common/Api/AxiosConfig'



/* to get all Primary Categories */
export const getCategories = createAsyncThunk('category', async () => {
    // category/pending
    // category/fulfilled
    // category/rejected
    const response = await AxiosConfig.get("/kreesa/category");
    return response.data;
})


