import React, { useState } from "react";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import Footer from "../../Footer";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useButtonLoader from "../../../ButtonLoaderHook";
import AdminCategoryValidation from "../../../Validation/AdminCategoryValidation";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

const CategoryForm = () => {
  const history = useHistory();

  document.title = "Admin | Add Category";
  const [categoryName, setcategoryName] = useState("");
  const [categoryStatus, setCategoryStatus] = useState("1");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add Category",
    "Loading..."
  );

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  const addCategoryHandler = async (e) => {
    setButtonLoader(true);
    e.preventDefault();
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const des = draftToHtml(convertToRaw(description.getCurrentContent()));
      const res = await AxiosConfig.post(
        "/kreesa/admin/category/primary",
        { categoryName, description: des, categoryStatus },
        config
      );

      if (res) {
        toast.success(`${categoryName} category added succesfully !`);
        history.push("/admin/primary-category/view");
        setButtonLoader(false);
      }
    } catch (err) {
      console.log(err + "error found");
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      // setMessage('Filed to add New category !')
      // toast.error(err.response.data.message);
      setButtonLoader(false);
    }
  };
  AdminCategoryValidation();

  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title font-weight-bold">
                    Add Category Form
                  </h5>
                  <h6 className="card-subtitle text-muted">
                    {" "}
                    You Can Easily Add New Category.
                  </h6>
                </div>
                <div className="card-body">
                  <form id="acategoryadd-forms" onSubmit={addCategoryHandler}>
                    <div className="col">
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Category Name
                          </span>{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required="required"
                          name="categoryName"
                          onChange={(e) => setcategoryName(e.target.value)}
                          id="inputCategory"
                          placeholder="Enter Category Name"
                        />
                      </div>
                      <div className="mb-3 mt-2 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">Status</span>{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          className="form-check-input ml-2"
                          type="radio"
                          name="categoryStatus"
                          id="exampleRadios1"
                          checked={categoryStatus === "1"}
                          value="1"
                          onChange={(e) => {
                            setCategoryStatus(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label pl-2"
                          htmlFor="exampleRadios1"
                        >
                          {" "}
                          Active
                        </label>
                        <input
                          className="form-check-input ml-2"
                          type="radio"
                          name="categoryStatus"
                          id="exampleRadios2"
                          checked={categoryStatus === "0"}
                          value="0"
                          onChange={(e) => {
                            setCategoryStatus(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label pl-2"
                          htmlFor="exampleRadios2"
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3 col-md-8">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Category Description
                          </span>
                          <span className="text-info"> (optional)</span>
                        </label>
                        <Editor
                          editorState={description}
                          onEditorStateChange={onEditorStateChange}
                          editorStyle={{
                            backgroundColor: "white",
                            minHeight: "150px",
                            padding: "10px 20px",
                          }}
                          editorClassName="mt-2 p-10 mx-auto"
                        />
                      </div>
                    </div>
                    <div className="mb-3 mt-4 col-md-4">
                      <button
                        id="catadd"
                        type="submit"
                        className="btn btn-success btn-lg btn-block"
                        ref={buttonLoader}
                      >
                        Add Category
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CategoryForm;
