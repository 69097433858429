import { createAsyncThunk } from '@reduxjs/toolkit';
import AxiosConfig from '../../../../../Common/Api/AxiosConfig'

// to get token
const config = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
};


/* to get all Group Product */
export const getGroupProduct = createAsyncThunk('GroupProduct', async () => {
    // GroupProduct/pending
    // GroupProduct/fulfilled
    // GroupProduct/rejected
    const response = await AxiosConfig.get("/kreesa/admin/group/view", config);
    return response.data;
})

/* to get single Group Product */
export const getSingleGroupProduct = createAsyncThunk('SingleGroupProduct', async (id) => {
    // GroupProduct/pending
    // GroupProduct/fulfilled
    // GroupProduct/rejected
    const response = await AxiosConfig.get(`/kreesa/admin/group/view/${id}`, config);
    return response.data;
})