import { createSlice } from "@reduxjs/toolkit";
import { getWholesalerProducts } from "../../../Action/ProductAction";

const Wholesalers = createSlice({
  name: "Wholesalers",
  initialState: {
    Wholesalers: [],
    WholesalersContainer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterWholesalers: (state, action) => {
      state.Wholesalers = state.WholesalersContainer.filter(
        (u) =>
          // u.productDescription.toLowerCase().includes(action.payload) ||
          // u.productDescription.toUpperCase().includes(action.payload) ||
          u.productName.toLowerCase().includes(action.payload) || u.productName.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Products */
    [getWholesalerProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getWholesalerProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.Wholesalers = action.payload;
      state.WholesalersContainer = action.payload;
    },
    [getWholesalerProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterWholesalers } = Wholesalers.actions;
// export default
export default Wholesalers.reducer;
