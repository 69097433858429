import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import "./style.css";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import UserRegisterValidation from "../../Validation/UserRegisterValidation";
import logo from "../../../assets/Images/kreesalogo.png";

const AdminLogin = () => {
  document.title = "Kreesa | OTP Verification";

  const history = useHistory();
  const [password, setPassword] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader("Reset Password", "Loading...");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    let res;
    try {
      // get username
      const username = localStorage.getItem("_forget_username")
      // api
      res = await AxiosConfig.post("/kreesa/user-login-registration/change-password",{password,username});
    } catch (error) {
      console.log(error);
    }
    if (res && res.status === 200) {
        setButtonLoader(false);
        localStorage.removeItem("_forget_username");
        toast.success("password changed successfully");
        history.push("/admin/login");
    }
  };

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token) {
    return <Redirect exact to="/admin" />;
  }
  UserRegisterValidation();
  return (
    <div className="container infinity-container">
      <div className="row">
        <div className="col-md-1 infinity-left-space"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 text-center infinity-form">
          {/* <!-- Company Logo --> */}
          <div className="text-center mb-3 mt-5">
            <img src={logo} alt="company logo" width="150px" />
          </div>
          <div className="text-center mb-4 rounded">
            <h4>Reset Password</h4>
          </div>

          <form id="user-register-forms" className="px-3" onSubmit={handleSubmit}>
            <div className="form-input">
              <span>
                <i className="fas fa-key"></i>
              </span>
              <input type="password" name="password" placeholder="Enter New Password" onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <div className="form-input">
              <span>
                <i className="fas fa-key"></i>
              </span>
              <input type="password" name="confirm_password" placeholder="Enter Confirm Password" required />
            </div>

            {/* <!-- Verify OTP  --> */}
            <div className="mb-3">
              <button type="submit" id="send" ref={buttonLoader} className="btn btn-block">
              Reset Password
              </button>
            </div>
          </form>
        </div>

        {/* <!-- FORM END --> */}
        <div className="col-md-1 infinity-right-space"></div>
      </div>
    </div>
  );
};

export default AdminLogin;
