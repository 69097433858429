import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import "./style.css";
import { Modal } from "react-responsive-modal";
import Spinner from "../../../../Admin/Spinner";
import { getWholesalerReviewById } from "../../../../Admin/Features/Action/ProductAction";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import HtmlParser from "react-html-parser";

const ProductReviewDetails = () => {
  const { ReviewById, loader } = useSelector((s) => s.ProductWholesalerReducer);
  document.title = `Retailer | ${ReviewById?.productName}`;
  const dispatch = useDispatch();
  const { id } = useParams();

  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };

  useEffect(() => {
    dispatch(getWholesalerReviewById(id));
  }, []);

  return !loader ? (
    <div className="text-capitalize">
      <div className="card flex-fill">
        <div
          className="card-header text-uppercase font-weight-bold "
          style={{ backgroundColor: "rgb(30 182 202)", color: "white" }}
        >
          Product Details{" "}
          <Link
            className="btn btn-danger btn-sm px-4"
            to={{
              pathname: `/saller/edit-product/${id}`,
              state: "retail",
            }}
            style={{
              float: "right",
              color: "whitesmoke",
              fontWeight: 600,
              fontFamily: "sans-serif",
              borderRadius: 4,
              letterSpacing: 1,
            }}
          >
            Edit
          </Link>
        </div>
        <div className="row m-4 mt-4">
          <div className="col-md-3">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Name
            </label>
            <p className="text-dark h5">{ReviewById.productName}</p>
          </div>
          <div className="col-md-3">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Category
            </label>
            <p className="text-dark h5">
              {ReviewById.primaryCategoryName}/
              {ReviewById.secondaryCategoryName}/
              {ReviewById.ternaryCategoryName}
            </p>
          </div>
        </div>
        <div className="row m-4">
          <div className="col-md-12">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Description
            </label>
            <p className="text-dark h5">
              {HtmlParser(ReviewById.productDescription)}
            </p>
          </div>
        </div>
        {/*  */}

        {ReviewById?.specification?.map((item, index) => (
          <div key={index}>
            <div
              className="card-header text-uppercase font-weight-bold "
              style={{ backgroundColor: "rgb(30 182 202)", color: "white" }}
            >
              Product Specification {index + 1}
            </div>

            <div className="row m-4">
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Size
                </label>
                <p className="text-dark">{item.productSize}</p>
              </div>
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Weight <span className="text-info">(kg)</span>
                </label>
                <p className="text-dark">{item.product_weight}</p>
              </div>
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Height
                </label>
                <p className="text-dark">{item.productHeight}</p>
              </div>
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Length
                </label>
                <p className="text-dark">{item.productLength}</p>
              </div>
              <hr />
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Thickness
                </label>
                <p className="text-dark">{item.productThickness}</p>
              </div>
              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product Quantity
                </label>
                <p className="text-dark">{item.productQuantity}</p>
              </div>
              <div className="col-md-3">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Rate
                </label>
                <p className="text-dark">{item.productRate}</p>
              </div>
              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product price
                </label>
                <p className="text-dark">{item.productPrice}</p>
              </div>
              <hr />
              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product Discount
                </label>
                <p className="text-danger">{item.productDiscount}%</p>
              </div>

              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product color
                </label>
                <br />
                {item.color?.map((c, ind) => (
                  <span className="text-dark" id="m-color">
                    {c.colorName} &nbsp;
                  </span>
                ))}
              </div>
            </div>
            <div>
              <div
                className="card-header text-uppercase font-weight-bold "
                style={{
                  backgroundColor: "rgb(30 182 202)",
                  color: "white",
                }}
              >
                Product Images
              </div>

              <div className="d-flex m-4">
                {item.image?.map((image, im) => (
                  <div key={im}>
                    <div
                      onClick={() => handleOpenModal(image.id)}
                      className="d-flex cursor-pointer"
                    >
                      <img
                        className="img-fluid img-thumbnail ml-2"
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${image.id}/${image.imageName}`}
                        height="132px"
                        width="145px"
                        alt={image.imageName}
                      />
                    </div>
                    {/*Modal  */}
                    {modal.show && modal.requestedModalId == image.id && (
                      <Modal
                        open={modal}
                        onClose={handleCloseModal}
                        center
                        classNames={{
                          overlayAnimationIn: "customEnterOverlayAnimation",
                          overlayAnimationOut: "customLeaveOverlayAnimation",
                          modalAnimationIn: "customEnterModalAnimation",
                          modalAnimationOut: "customLeaveModalAnimation",
                        }}
                        animationDuration={1000}
                      >
                        <div className="pro">
                          <img
                            className="img-fluid img-thumbnail"
                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${image.id}/${image.imageName}`}
                            height={450}
                            width={550}
                            alt={image.imageName}
                          />
                        </div>
                      </Modal>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {/* Modal */}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ProductReviewDetails;
