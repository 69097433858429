import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import moment from "moment";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  confirmDeliveryFromSupplier,
  getAssignedQuotation,
  postActualPrice,
} from "../../RetailerWholeSaler/Features/Action/WholesalerAction";
import ViewQuoteModal from "../../User/Quotation/ViewQuoteModal";
import Swal from "sweetalert2";

// validation
const validationSchema = Yup.object().shape({
  actualPrice: Yup.number("Must be number")
    .typeError("Actual price must be a number")
    .required("Required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  deliveryDay: Yup.number("Must be number")
    .typeError("delivery day must be a number")
    .required("Required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  deliveryCharge: Yup.number("Must be number")
    .typeError("delivery charge must be a number")
    .required("Required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
});
const AssignQuotation = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [modalId, setModalId] = useState("");
  const [assignModalId, setAssignModalId] = useState("");
  const [modal, setModal] = useState(false);
  // const [assignModal, setAssignModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userData, setUserData] = useState({});

  const [perPage] = useState(7);
  const dispatch = useDispatch();

  const { assignedQuotation, loader } = useSelector(
    (state) => state.WholesalerReducer
  );

  useEffect(() => {
    dispatch(getAssignedQuotation(localStorage.getItem("userId")));
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = assignedQuotation.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(assignedQuotation.length / perPage);

  const actualPriceHandler = async (values) => {
    setLoading(true);
    let data = new FormData();
    data.append("final_price", values.actualPrice);
    data.append("quotationId", modalId);
    data.append("photo", values.photo);
    data.append("userId", localStorage.getItem("userId"));
    data.append("deliveryCharge", values.deliveryCharge);
    data.append("deliveryDay", values.deliveryDay);

    await dispatch(
      postActualPrice({
        data,
        userId: localStorage.getItem("userId"),
        toast,
      })
    );
    setModalId("");
    dispatch(getAssignedQuotation(localStorage.getItem("userId")));
    setLoading(false);
  };
  return !loader ? (
    <div>
      <div className="">
        {currentEntries?.length > 0 ? (
          <div className="">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h3 className="card-title mb-0 font-weight-bold">
                  Assigned Quotations
                </h3>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Image</th>
                    <th>Address</th>
                    <th>Actual price</th>
                    <th>Admin</th>
                    <th>User</th>
                    <th>Default Address</th>
                    {/* <th>Seller</th> */}
                    <th>Delivery</th>

                    {/* <th className="d-xl-table-cell">Quantity</th> */}

                    {/* <th className="d-md-table-cell">Address</th> */}
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{p.product_name}</td>
                      <td>{moment(p.created_date).format("DD MMM YY")}</td>
                      {/* <td>{moment(p.orderDate).format("ll")}</td> */}
                      <td>{p.expected_price}</td>
                      <td>{p.quantity}</td>
                      <td>
                        {" "}
                        <img
                          onClick={() =>
                            Swal.fire({
                              imageUrl: `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${p.id}/${p.image}`,
                              imageHeight: 200,
                              imageAlt: "A tall image",
                            })
                          }
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${p.id}/${p.image}`}
                          className="cursor-pointer"
                          alt={p.image}
                          height={50}
                          width={70}
                        />
                      </td>
                      <td>
                        {(p?.shipping_address !== null
                          ? p?.shipping_address
                          : "- ") +
                          ", " +
                          (p?.shipping_state !== null
                            ? p?.shipping_state
                            : "- ") +
                          ", " +
                          (p?.shipping_country !== null
                            ? p?.shipping_country
                            : "- ")}
                      </td>
                      <td className="font-weight-bold">
                        {p.actual_price_seller === 0
                          ? "______"
                          : `Rs. ${
                              p.actual_price_seller === null
                                ? p.actual_price
                                : p.actual_price_seller
                            }`}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            p.admin_final_approval === "REJECTED"
                              ? "bg-danger"
                              : p.admin_final_approval === "APPROVED"
                              ? "bg-success"
                              : "bg-info"
                          }`}
                        >
                          {p.admin_final_approval}
                        </span>
                      </td>
                      <td>
                        {p.user_approval === true ? (
                          <span className="badge bg-success">Approved</span>
                        ) : (
                          <span className="badge bg-info">Pending</span>
                        )}
                      </td>
                      <td>
                        <span
                          className="badge bg-secondary cursor-pointer"
                          onClick={() => {
                            AxiosConfig.get(
                              `/kreesa/admin/get-user-by-user-id/${p.user_id}`
                            )
                              .then(({ data }) => {
                                Swal.fire({
                                  titleText: "Default Address",
                                  confirmButtonColor: "#0d6efd",
                                  html: `<div style="text-align:start"><h5>Address : ${data.streetAddress}, ${data.districtName}, ${data.provienceName}, ${data.countryName}</h5></div>`,
                                });
                              })
                              .catch((err) =>
                                toast.error("An Error Occurred ")
                              );
                          }}
                        >
                          Address
                        </span>
                      </td>
                      {/* <td>
                        {p.buyer_approval === true ? (
                          <span className="badge bg-success">Approved</span>
                        ) : (
                          <span className="badge bg-info">Pending</span>
                        )}
                      </td> */}
                      <td>
                        {p.buyer_approval === true &&
                          p.user_approval === true &&
                          p.admin_final_approval === "APPROVED" && (
                            <>
                              {p.supplier_delivery_approval === false ? (
                                <span className="badge bg-danger">
                                  Not Confirmed
                                </span>
                              ) : (
                                <span className="badge bg-success">
                                  Confirmed
                                </span>
                              )}
                            </>
                          )}
                      </td>

                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-left text-center">
                            <button
                              onClick={async () => {
                                setModalData(p);
                                const response = await AxiosConfig.get(
                                  `/kreesa/admin/get-user-by-user-id/${p.user_id}`,
                                  {
                                    headers: {
                                      Authorization:
                                        "Bearer " +
                                        localStorage.getItem("token"),
                                    },
                                  }
                                ).then((res) => setUserData(res.data));
                                setModal(true);
                                //   handleApproveQuotation(p.id);
                              }}
                              className="dropdown-item text-info"
                            >
                              <i className="fas fa-eye"></i> View
                            </button>
                            {/* {p.user_approval === false && (
                              <>
                                <div className="dropdown-divider"></div>
                                <button
                                  onClick={() => setAssignModalId(p.id)}
                                  className="dropdown-item text-success"
                                >
                                  <i class="fas fa-check"></i>Assign Delivery
                                </button>
                              </>
                            )} */}

                            {p.buyer_approval === true &&
                              p.user_approval === true &&
                              p.admin_final_approval === "APPROVED" &&
                              p.supplier_delivery_approval === false && (
                                <>
                                  <div className="dropdown-divider"></div>

                                  <button
                                    onClick={() =>
                                      dispatch(
                                        confirmDeliveryFromSupplier(p.id)
                                      )
                                    }
                                    className="dropdown-item text-success"
                                  >
                                    <i class="fas fa-check"></i> Confirm
                                    Delivery
                                  </button>
                                </>
                              )}
                            {p.final_approval === "PENDING" && (
                              <>
                                <div className="dropdown-divider"></div>
                                <button
                                  onClick={() => setModalId(p.id)}
                                  className="dropdown-item text-success"
                                >
                                  <i class="fas fa-check"></i> Confirm
                                </button>
                              </>
                            )}
                          </div>
                        </div>

                        <Modal
                          show={assignModalId === p.id}
                          onHide={() => setModalId("")}
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title className="text-capitalize">
                              Assign Delivery Boy for {p.product_name}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body></Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => setAssignModalId("")}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        <Modal
                          show={modalId === p.id}
                          onHide={() => setModalId("")}
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title className="text-capitalize">
                              <h6>Actual Price For : {p.product_name}</h6>
                              <h6>Expected Price : Rs, {p.expected_price}</h6>
                              <h6>
                                Input Delivery charge after confirming the
                                address
                              </h6>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <h5
                              className="text-capitalize"
                              style={{
                                fontFamily: "Times New Roman",
                                fontWeight: "bold",
                              }}
                            >
                              Kindly Input Actual price you can sell, delivery
                              charges applicable, delivery days required to
                              deliver and the product image you are supplying
                            </h5>
                            <Formik
                              enableReinitialize={true}
                              initialValues={{
                                actualPrice: "",
                                photo: "",
                                deliveryCharge: "",
                                deliveryDay: "",
                              }}
                              validationSchema={validationSchema}
                              onSubmit={actualPriceHandler}
                            >
                              {({
                                values,
                                handleSubmit,
                                handleBlur,
                                handleChange,
                                setFieldValue,
                                dirty,
                                isValid,
                                errors,
                                touched,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                  <div>
                                    <div className="mb-3 mt-2">
                                      <label
                                        className="form-label font-weight-bold"
                                        htmlFor="Image"
                                      >
                                        Delivery Charge
                                      </label>
                                      <input
                                        name="deliveryCharge"
                                        type="text"
                                        placeholder="Enter Delivery Charge"
                                        className="form-control"
                                        id="inpuStockNew"
                                        onChange={handleChange}
                                        value={values.deliveryCharge}
                                      />
                                      {errors.deliveryCharge &&
                                      touched.deliveryCharge ? (
                                        <div style={{ color: "red" }}>
                                          {errors.deliveryCharge}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="mb-3 mt-2">
                                      <label
                                        className="form-label font-weight-bold"
                                        htmlFor="Image"
                                      >
                                        Delivery Day
                                      </label>
                                      <input
                                        name="deliveryDay"
                                        type="text"
                                        placeholder="Enter Delivery Day"
                                        className="form-control"
                                        id="inpuStockNew"
                                        onChange={handleChange}
                                        value={values.deliveryDay}
                                      />
                                      {errors.deliveryDay &&
                                      touched.deliveryDay ? (
                                        <div style={{ color: "red" }}>
                                          {errors.deliveryDay}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="mb-3 mt-2">
                                      <label
                                        className="form-label font-weight-bold"
                                        htmlFor="Image"
                                      >
                                        Price{" "}
                                        <span className="text-info">
                                          (per unit)
                                        </span>
                                      </label>
                                      <input
                                        name="actualPrice"
                                        type="text"
                                        placeholder="Enter Actual Price"
                                        className="form-control"
                                        id="inpuStockNew"
                                        onChange={handleChange}
                                        value={values.actualPrice}
                                      />
                                      {errors.actualPrice &&
                                      touched.actualPrice ? (
                                        <div style={{ color: "red" }}>
                                          {errors.actualPrice}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="mt-2">
                                      <label
                                        className="form-label font-weight-bold"
                                        htmlFor="Image"
                                      >
                                        Image
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        name="photo"
                                        accept="image/png,image/jpg,image/jpeg"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "photo",
                                            e.target.files[0]
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      <small>
                                        Accepted Formats: png, jpg & jpeg
                                      </small>

                                      {errors.photo && touched.photo ? (
                                        <div style={{ color: "red" }}>
                                          {errors.photo}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <button
                                    style={{ float: "right" }}
                                    type="submit"
                                    className="btn btn-primary mt-4"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                  >
                                    {loading ? "Loading . . ." : "Send Request"}
                                  </button>
                                </Form>
                              )}
                            </Formik>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => setModalId("")}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ViewQuoteModal
                modal={modal}
                userData={userData}
                data={modalData}
                setModal={setModal}
              />
            </div>
          </div>
        ) : (
          <h4 className="text-center">Assigned Quotation Not Found</h4>
        )}
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={assignedQuotation}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default AssignQuotation;
