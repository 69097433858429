import React, { useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import OtpVerification from "../../Validation/OtpValidation";
import logo from "../../../assets/Images/kreesalogo.png";

const UserOtpVerification = () => {
  document.title = "Kreesa | OTP Verification";
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Verify Your OTP",
    "Loading..."
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    let res;
    try {
      // get username
      const username = localStorage.getItem("_forget_username");
      // api
      res = await AxiosConfig.post(
        `/kreesa/user-login-registration/verify-otp/`,
        { otp, username }
      );
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
      setButtonLoader(false);
    }
    if (res && res.status === 200) {
      setButtonLoader(false);
      toast.success("Otp has been verified");
      history.push("/user/reset-password");
    }
  };

  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token === "ROLE_RETAILER") {
    return <Redirect exact to="/retailer" />;
  }
  if (token === "ROLE_WHOLESALER") {
    return <Redirect exact to="/wholesaler" />;
  }
  if (token === "ROLE_USER") {
    return <Redirect exact to="/" />;
  }
  OtpVerification();
  return (
    <div className="container infinity-container">
      <div className="row">
        <div className="col-md-1 infinity-left-space"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 text-center infinity-form">
          {/* <!-- Company Logo --> */}
          <div className="text-center mb-3 mt-5">
           <Link to="/login"> <img src={logo} alt="company logo" width="150px" /></Link>
          </div>
          <div className="text-center mb-4 rounded">
            <h4>OTP Verification</h4>
          </div>

          <form id="user-otp-form" className="px-3" onSubmit={handleSubmit}>
            <div className="form-input">
              <span>
                <i className="fas fa-key"></i>
              </span>
              <input
                type="text"
                name="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>

            {/* <!-- Verify OTP  --> */}
            <div className="mb-3">
              <button
                type="submit"
                id="otpbtn"
                ref={buttonLoader}
                className="btn btn-block"
              >
                Verify Your OTP
              </button>
            </div>
          </form>
        </div>

        {/* <!-- FORM END --> */}
        <div className="col-md-1 infinity-right-space"></div>
      </div>
    </div>
  );
};

export default UserOtpVerification;
