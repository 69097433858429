import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";

const ShowOrderDetail = () => {
  const [state, setstate] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  // get all notification
  const getAllOrders = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const allOrders = await AxiosConfig.get(
        `/kreesa/retailer/order/view/supplier/${localStorage.getItem(
          "userId"
        )}`,
        config
      );
      setstate(allOrders.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
    }
  };
  //
  useEffect(() => {
    getAllOrders();
  }, []);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = state?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(state?.length / perPage);
  return !isLoaded ? (
    <div className="container">
      <div className="row">
        {/* <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link to="/retailer/product/register" className="btn btn-lg btn-outline-info display-5 mt-2" style={{ float: "right" }}>
                Add Product <i className="fab fa-product-hunt"></i>
              </Link>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">Latest Orders</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Orders Number</th>
                  <th>Orders Date</th>
                  <th className="d-xl-table-cell">Number of Order</th>
                  <th className="d-md-table-cell">Address</th>
                  <th className="d-md-table-cell">Status</th>
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map((p, i) => (
                  <tr>
                    <td>{perPage * (currentPage - 1) + i + 1}</td>
                    <td>{p.orderNo}</td>
                    <td>{moment(p.orderDate).format("ll")}</td>
                    <td className="d-xl-table-cell">
                      {p.orderDetails?.length}
                    </td>
                    <td className="d-md-table-cell">{p.shippingAddress}</td>
                    <td>
                      {p?.orderDetails?.map((i) =>
                        i.sellerApproval === "Approved" ? (
                          <span className="badge bg-success">
                            {i.sellerApproval}
                          </span>
                        ) : i.sellerApproval === "Pending" ? (
                          <span className="badge bg-info">
                            {i.sellerApproval}
                          </span>
                        ) : (
                          <span className="badge bg-danger">
                            {i.sellerApproval}
                          </span>
                        )
                      )}
                    </td>
                    <td className="d-md-table-cell">
                      <Link
                        to={`/retailer/orders/${p.id}`}
                        className="btn btn-info"
                      >
                        <i className="fas fa-eye"></i> View More
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={state}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ShowOrderDetail;
