import React, { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import useButtonLoader from "../../../ButtonLoaderHook";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { useHistory, useParams } from "react-router-dom";
import {
  viewAllLegalDocs,
  viewLegalDoc,
} from "../../Features/Action/LegalAction";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Spinner";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  legalType: Yup.string().required("Type is required"),
});

const ManageLegal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { singleDoc, legalData, loader } = useSelector((s) => s.LegalReducer);

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    `${id ? "Update" : "Create"} Legal Document`,
    "Loading..."
  );

  const [description, setDescription] = useState(EditorState.createEmpty());

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };

  useEffect(() => {
    if (id && singleDoc?.description?.length > 0) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(singleDoc?.description)
          )
        )
      );
    }
  }, [singleDoc, id]);

  const legal = [
    "Privacy Policy",
    "Term And Condition",
    "Term of Use",
    "Return Policy",
  ];

  const pathname = history.location.pathname;

  useEffect(() => {
    if (pathname.includes("view")) {
      dispatch(viewLegalDoc(id));
    } else {
      dispatch(viewAllLegalDocs());
    }
  }, [pathname, id]);

  const LegalHandler = async (values) => {
    setButtonLoader(true);

    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.post(
        "/kreesa/legal/admin/save",
        {
          ...values,
          legalType: values.legalType?.toUpperCase()?.replace(/ /g, "_"),
          // description: draftToHtml(
          //   convertToRaw(values.description.getCurrentContent())
          // ),
        },
        config
      ).then((res) => {
        toast.success("Legal Docs Added Successfully");
        setButtonLoader(false);
        history.push("/admin/legal-docs");
      });
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };

  const convertCase = (value) =>
    value
      ?.split("_")
      .map((i) => i.charAt(0).toUpperCase() + i.slice(1).toLowerCase())
      .toString()
      .replace(/,/g, " ");

  return !loader ? (
    <div>
      <Formik
        initialValues={{
          legalType: id ? convertCase(singleDoc.legalType) : "",
          title: id ? singleDoc.title : "",
          description: id ? singleDoc.description : "",
        }}
        onSubmit={LegalHandler}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="inputEmail4">
                  Type
                </label>

                <select
                  name="legalType"
                  onChange={handleChange}
                  values={values.legalType}
                  className="form-control"
                  disabled={id && true}
                >
                  <option hidden value="">
                    {id ? values.legalType : "Select Docs Type"}
                  </option>
                  {legal.map((i) => (
                    <option value={convertCase(i)}>{i}</option>
                  ))}
                  {/* {legalData.some(
                    (i) =>
                      !legal.includes(convertCase(i.legalType)) && (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      )
                  )} */}
                </select>

                {errors.legalType && touched.legalType ? (
                  <div style={{ color: "red" }}> {errors.legalType} </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-2">
              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="inputEmail4">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  id="inputCategory"
                  placeholder="Enter Title"
                />
                {errors.title && touched.title ? (
                  <div style={{ color: "red" }}> {errors.title} </div>
                ) : null}
              </div>
            </div>

            <div>
              <label className="form-label" htmlFor="inputEmail4">
                Description
              </label>
              <Editor
                editorState={description}
                onEditorStateChange={(e) => {
                  onEditorStateChange(e);
                  setFieldValue(
                    "description",
                    draftToHtml(convertToRaw(description.getCurrentContent()))
                  );
                }}
                editorStyle={{
                  backgroundColor: "white",
                  height: "350px",
                  padding: "10px 20px",
                }}
                editorClassName="mt-2 p-10 mx-auto"
              />
              {errors.description && touched.description ? (
                <div style={{ color: "red" }}> {errors.description} </div>
              ) : null}
            </div>
            <div className="mb-3 mt-4 col-md-3" style={{ float: "right" }}>
              <button
                id="catadd"
                type="submit"
                onClick={handleSubmit}
                className="btn btn-success btn-lg btn-block"
                ref={buttonLoader}
              >
                {id ? "Update" : "Create"} Legal Document
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <Spinner />
  );
};

export default ManageLegal;
