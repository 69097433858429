import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useDispatch, useSelector } from "react-redux";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSingleNewsBlogNotice } from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";

// validation schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  blogType: Yup.string().required("Blog type is required"),
  shortDesc: Yup.string()
    .required("Short Description is required")
    .min(13, "Short Description must be 5 characters"),
  file: Yup.mixed()
    .required("Image is required")
    .nullable()
    .test(
      "fileType",
      "Only accept Jpg/Jpeg/Png/webp/gif file type",
      (file) =>
        file &&
        [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/webp",
          "image/gif",
        ].includes(file.type)
    ),
});

const AddEditNewsBlogNoticeForm = () => {
  const { id } = useParams();

  const [shortDesc, setShortDesc] = useState(EditorState.createEmpty());
  const [longDesc, setLongDesc] = useState(EditorState.createEmpty());

  const dispatch = useDispatch();
  const history = useHistory();

  const { blog, loader } = useSelector((s) => s.AdminReducer);

  //create
  const formHandler = async (values) => {
    const data = new FormData();
    data.append("title", values.title);
    data.append("blogType", values.blogType);
    data.append("shortDesc", values.shortDesc);
    data.append("longDesc", values.longDesc);
    // file upload
    if (values.file) {
      data.append("file", values.file);
    }
    if (id) {
      data.append("id", id);
    }
    const res = await AxiosConfig.post(
      id ? `/kreesa/admin/blog/update/${id}` : `/kreesa/admin/blog/create`,
      data
    );

    // lower case the blog type
    const str = values.blogType.toLowerCase();
    if (res && res.status === 200) {
      toast.success(
        `${str.charAt(0).toUpperCase() + str.slice(1)} has been ${
          id ? "updated" : "created"
        } !!`
      );
      history.goBack();
    }
  };

  // useEffect
  useEffect(() => {
    if (blog?.shortDesc?.length > 0) {
      setShortDesc(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(blog?.shortDesc))
        )
      );
    }
    if (blog?.longDesc?.length > 0) {
      setLongDesc(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(blog?.longDesc))
        )
      );
    }
  }, [blog, id]);

  useEffect(() => {
    dispatch(getSingleNewsBlogNotice(id));
  }, [id]);

  useEffect(() => {
    setLongDesc(EditorState.createEmpty());
    setShortDesc(EditorState.createEmpty());
  }, []);
  return !loader ? (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title font-weight-bold">
          {!id ? "Add" : "Update"} News/Blog/Notice Form
        </h5>
        <h6 className="card-subtitle text-muted">
          {" "}
          You Can Easily {!id ? "Add" : "Update"} News/Blog/Notice.
        </h6>
      </div>
      <div className="card-body">
        <Formik
          initialValues={{
            title: id ? blog?.title : "",
            file: null,
            blogType: id ? blog?.blogType : "",
            shortDesc: id ? blog?.shortDesc : "",
            longDesc: id ? blog?.longDesc : "",
          }}
          validationSchema={!id && validationSchema}
          onSubmit={formHandler}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="col">
                <div className="mb-3 col-md-6">
                  <label className="form-label" htmlFor="inputCity">
                    <span className="font-weight-bold">Title</span>{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter Title"
                    value={values.title}
                  />
                  {errors.title && touched.title ? (
                    <div style={{ color: "red" }}>{errors.title}</div>
                  ) : null}
                </div>
              </div>
              <div className="col">
                <div className="mb-3 col-md-6">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="inputState"
                  >
                    Blog Type <span className="text-danger"> *</span>
                  </label>
                  <select
                    id="inputState"
                    name="blogType"
                    onChange={handleChange}
                    value={values.blogType}
                    className="form-control"
                  >
                    <option value="">Select Any Blog Type...</option>
                    <option value="NEWS">News</option>
                    <option value="BLOG">Blog</option>
                    <option value="NOTICE">Notice</option>
                  </select>
                  {errors.blogType && touched.blogType ? (
                    <div style={{ color: "red" }}>{errors.blogType}</div>
                  ) : null}
                </div>
              </div>
              <div className="col">
                <div className="mb-3 col-md-6">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="inputState"
                  >
                    Image <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/png,image/jpg,image/jpeg"
                    name="file"
                    onChange={(e) => setFieldValue("file", e.target.files[0])}
                    onBlur={handleBlur}
                  />
                  <span className="text-muted">
                    Accepted Formats: png, jpg & jpeg
                  </span>
                  <br />
                  {id && (
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
                      alt={blog?.title}
                      width="100"
                      className="mt-2"
                      loading="lazy"
                    />
                  )}
                  {errors.file && touched.file ? (
                    <div style={{ color: "red" }}>{errors.file}</div>
                  ) : null}
                </div>
              </div>
              <div className="col">
                <div className="mb-3 col-md-8">
                  <label className="form-label" htmlFor="inputCity">
                    <span className="font-weight-bold">Short Description</span>
                    <span className="text-danger"> *</span>
                  </label>
                  <div className="text-editor1">
                    <Editor
                      editorState={shortDesc}
                      placeholder="Enter Short Description ..."
                      onEditorStateChange={(val) => {
                        setShortDesc(val);
                        setFieldValue(
                          "shortDesc",
                          draftToHtml(convertToRaw(val.getCurrentContent()))
                        );
                      }}
                      editorStyle={{
                        backgroundColor: "white",
                        height: "250px",
                        padding: "10px 20px",
                        // overflow: "scroll",
                      }}
                      editorClassName="mt-2 p-10 mx-auto"
                    />
                  </div>
                  {errors.shortDesc && touched.shortDesc ? (
                    <div style={{ color: "red" }}>{errors.shortDesc}</div>
                  ) : null}
                </div>
              </div>
              <div className="col">
                <div className="mb-3 col-md-8">
                  <label className="form-label" htmlFor="inputCity">
                    <span className="font-weight-bold">Long Description</span>
                    <span className="text-info"> (optional)</span>
                  </label>
                  <div className="text-editor1">
                    <Editor
                      editorState={longDesc}
                      placeholder="Enter Long Description ..."
                      onEditorStateChange={(val) => {
                        setLongDesc(val);
                        setFieldValue(
                          "longDesc",
                          draftToHtml(convertToRaw(val.getCurrentContent()))
                        );
                      }}
                      editorStyle={{
                        backgroundColor: "white",
                        height: "350px",
                        padding: "10px 20px",
                        // overflow: "scroll",
                      }}
                      editorClassName="mt-2 p-10 mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3 mt-4 col-md-4">
                <button
                  id="catadd"
                  type="submit"
                  className="btn btn-success btn-lg btn-block"
                  onClick={handleSubmit}
                  // disabled={!isValid}
                >
                  {!id ? "Add" : "Update"} News/Blog/Notice
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default AddEditNewsBlogNoticeForm;
