import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";
import Modal from "../../Retailer/Stock/EditModal";

const StockDetails = (props) => {
  const [state, setstate] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [modalId, setModalId] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  // to get token
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  // get my all stock from API
  const getStock = async () => {
    try {
      const getStockDetails = await AxiosConfig.get(
        `/kreesa/stock-management/view-all-stock/${localStorage.getItem(
          "userId"
        )}`,
        config
      );
      setstate(getStockDetails.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStock();
  }, [localStorage.getItem("userId")]);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = state?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(state?.length / perPage);

  return !isLoaded ? (
    <div className="container">
      {currentEntries.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">My Stocks</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Product Name</th>
                    <th>Product Price</th>
                    <th className="d-xl-table-cell">Total Stock</th>
                    {/* <th className="d-md-table-cell">Address</th> */}
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>{p.productName}</td>
                      {/* <td>{moment(p.orderDate).format("ll")}</td> */}
                      <td className="d-xl-table-cell">{p.productPrice}</td>
                      <td className="d-md-table-cell">{p.totalStock}</td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <button
                              onClick={() => setModalId(`modal${i}`)}
                              className="dropdown-item text-primary"
                            >
                              <i class="fas fa-user-edit"></i> Edit
                            </button>
                            <div className="dropdown-divider" />
                            <Link
                              to={`/wholesaler/singlepage-stock/${p.stockId}`}
                              className="dropdown-item text-info"
                            >
                              <i className="fas fa-eye"></i> Review
                            </Link>
                          </div>
                        </div>

                        <Modal
                          show={modalId === `modal${i}`}
                          onHide={() => setModalId("")}
                          {...props}
                          name={p.productName}
                          id={p.stockId}
                          quantity={p.totalStock}
                          getStocks={getStock}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={state}
          />
        </div>
      ) : (
        <div className="row">
          <h3 className="text-center">No Stock Found</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default StockDetails;
