import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPushNotfication } from "../Features/Action/AdminNotificationAction";
import Spinner from "../Spinner";
import { Pagination } from "@mantine/core";
import { filterPushNotification } from "../Features/Reducer/AdminNotificationReducer";
import { Link } from "react-router-dom";

const NotificationDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const inputRef = useRef("");
  const { pushNotification, loader } = useSelector(
    (s) => s.AdminNotificationReducer
  );

  const dispatch = useDispatch();

  // Search
  const filterNotification = () => {
    dispatch(filterPushNotification(inputRef.current.value));
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = pushNotification?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(pushNotification?.length / perPage);

  useEffect(() => {
    dispatch(getAllPushNotfication());
  }, []);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link
                to="/admin/notification"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Send Notification <i className="fas fa-bell"></i>
              </Link>
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Notifications..."
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterNotification}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">All Notifications</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Message</th>
                    <th>Send To</th>
                    <th>Type/User</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((p, i) => (
                    <tr>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>{p.message}</td>
                      <td>{p.sendTo}</td>
                      <td>
                        {p.type}
                        {p.type === "single" && (
                          <a href={`mailto:${p.user.email}`}>
                            {" "}
                            ({p.user.email})
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Notification Found !!!</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default NotificationDetails;
