import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  address_of_plant: Yup.string().required("Address of plant is required"),
  date_of_plantation: Yup.date().required("Date of plantation is required"),
  latitude_longitude: Yup.string().required("latitude longitude is required"),
  type_of_plant: Yup.string().required("Type of plant is required"),
});

export const validationPlantLimitSchema = Yup.object().shape({
  price: Yup.number("Must be number")
    .typeError("Delivery Charge must be a number")
    .required("Required")
    .positive("Must be a positive number"),
});
