import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import Spinner from "../../../Spinner";
import { toast } from "react-toastify";

const ReviewCustomerDetailsForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [state, setState] = useState();
  const [isLoaded, setIsLoaded] = useState(true);

  // get all users from API
  const getSingleCustomer = async (id) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetSingleCustomerDetails = await AxiosConfig.get(
        `/kreesa/admin/view-single-admin/${id}`,
        config
      );
      setState(GetSingleCustomerDetails.data);
      setIsLoaded(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };
  useEffect(() => {
    getSingleCustomer(id);
  }, []);
  return !isLoaded ? (
    <div>
      <div className="container emp-profile">
        <form method="post">
          <div className="row">
            <div className="col-md-4">
              <div className="profile-img">
                <img
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/admin/${state.userId}/${state?.image}`}
                  alt={state?.firstname}
                />
                {/* <div className="file btn btn-lg btn-primary">
                                      Change Photo
                                      <input type="file" name="file" />
                                  </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head">
                <h3 className="text-uppercase">
                  {state?.firstname} {state?.lastname}
                </h3>
                <h6>
                  {state.role === "ROLE_CEO"
                    ? "CEO"
                    : state.role === "ROLE_MANAGER"
                    ? "Manager"
                    : state.role === "ROLE_MARKETING_MANAGER"
                    ? "Marketing Manager"
                    : state.role === "ROLE_RECEPTIONIST"
                    ? "Receptionist"
                    : state.role === "ROLE_RETAILER"
                    ? "Retailer"
                    : state.role === "ROLE_WHOLESALER"
                    ? "WholeSaler"
                    : state.role === "ROLE_USER"
                    ? "User"
                    : ""}
                </h6>

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    {/* <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Timeline</a> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              {localStorage.getItem("auth") !== "ROLE_RECEPTIONIST" && (
                <Link to={`/admin/edit-customer/${state.userId}`}>
                  <input
                    type="submit"
                    className="profile-edit-btn"
                    name="btnAddMore"
                    value="Edit User"
                  />
                </Link>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="tab-content profile-tab" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {/* <div className="row">
                    <div className="col-md-6">
                      <label>User Id</label>
                    </div>
                    <div className="col-md-6">
                      <p></p>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-6">
                      <label>First Name</label>
                    </div>
                    <div className="col-md-6">
                      <p>{state?.firstname}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Last Name</label>
                    </div>
                    <div className="col-md-6">
                      <p>{state?.lastname}</p>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-6">
                      <label>Address</label>
                    </div>
                    <div className="col-md-6">
                      <p>{state?.address}</p>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-md-6">
                      <label>Email</label>
                    </div>
                    <div className="col-md-6">
                      <p>{state?.email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Phone</label>
                    </div>
                    <div className="col-md-6">
                      <p> {state?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Status</label>
                    </div>
                    <div className="col-md-6">
                      {state?.flag === true ? (
                        state?.locked === true ? (
                          <p>Active</p>
                        ) : (
                          <p>Inactive</p>
                        )
                      ) : (
                        <p>Blocked</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Experience</label>
                    </div>
                    <div className="col-md-6">
                      <p>Expert</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Hourly Rate</label>
                    </div>
                    <div className="col-md-6">
                      <p>10$/hr</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Total Projects</label>
                    </div>
                    <div className="col-md-6">
                      <p>230</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>English Level</label>
                    </div>
                    <div className="col-md-6">
                      <p>Expert</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Availability</label>
                    </div>
                    <div className="col-md-6">
                      <p>6 months</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Your Bio</label>
                      <br />
                      <p>Your detail description</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ReviewCustomerDetailsForm;
