import React from 'react'
import CompanyTypeDetails from './CompanyTypeDetails'
import Footer from '../Footer'
import TopNavbar from '../TopNavbar'
import LeftNavbar from '../LeftNavbar'
import { Redirect } from 'react-router-dom'

const CompanyType = () => {
    document.title = "Admin | Show Company Type"
    // to check authentication user or not
    let token = localStorage.getItem('token', 'username','auth');
    if (token === null) {
        return <Redirect exact to="/admin/login" />
    }
    return (
        <>
            <div className="wrapper">
                <LeftNavbar />
                <div className="main">
                    <TopNavbar />
                    <main className="content">
                        <CompanyTypeDetails />
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default CompanyType
