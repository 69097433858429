import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Table, Modal } from "react-bootstrap";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
// import UserOrderValidation from "../../Validation/OrderValidation";
import "./checkout.css";
import { useDispatch } from "react-redux";
import { removeCartRetailItem } from "../Features/action";
import { removeCartItem } from "../../RetailerWholeSaler/Features/Action/WholesalerAction";
import uuid from "react-uuid";
import logo from "../../../assets/Images/kreesalogo.png";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useRef } from "react";
import Cookies from "js-cookie";
import Spinner from "../../Admin/Spinner";
import useButtonLoader from "../../ButtonLoaderHook";
import EditProfile from "../MyAccount/EditProfile";

const Checkout = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [token, setToken] = useState([]);
  const [ipsToken, setIpsToken] = useState([]);
  const [connectTxn, setConnectTxn] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(true);
  const [country, setCountry] = useState([]);
  const [district, setDistrict] = useState([]);
  const [province, setProvince] = useState([]);
  const [profile, setProfile] = useState([]);
  const [codeResult, setCodeResult] = useState({});
  const [rewardPoints, setRewardPoints] = useState(false);
  const [rewardStatus, setRewardStatus] = useState({});

  document.title = `Kreesa | Order-Now`;

  const connectIpsRef = useRef();
  const userFormRef = useRef();

  useEffect(() => {
    AxiosConfig.get(
      `/kreesa/admin/get-user-by-user-id/${
        history.location.pathname.includes("/custom-order")
          ? JSON.parse(localStorage.getItem("customOrder")).userId
          : localStorage.getItem("userId")
      }`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        setProfile(res.data);
        if(!res.data?.countryName){
          console.log(res.data?.countryName, "Country");
          setShowEditProfileModal(true);
        }
        setOrders({ ...orders, shippingCost: res.data.deliveryCharge });
      });
  }, []);

  useEffect(() => {
    AxiosConfig.get("/kreesa/check/get-country").then((res) =>
      setCountry(res.data)
    );
    AxiosConfig.get("/kreesa/check/get/rewards/status").then((res) =>
      setRewardStatus(res.data)
    );
  }, []);

  // catch a props through Link

  const userRole = localStorage.getItem("auth");

  (props?.location?.state === undefined || !history?.location?.state === []) &&
    history.push("/home");
  const state = props.location.state;
  // state

  const [showModal, setShowModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [validateForm, setValidateForm] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState({
    signature: false,
    radioSelect: "",
  });

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Submit",
    "Loading..."
  );

  /* --------------------- get total items and bill amount -------------------- */
  let billAmount = 0;
  state?.forEach((i) => {
    billAmount = billAmount + i?.discountedTotalPrice;
  });

  let customOrderData = JSON.parse(localStorage.getItem("customOrder"));

  const [orders, setOrders] = useState({
    consumerId: ["ROLE_USER", "ROLE_WHOLESALER", "ROLE_RETAILER"].includes(
      userRole
    )
      ? localStorage.getItem("userId")
      : customOrderData?.userId,
    contactNo: "",
    orderDetails: [],
    orderType:
      localStorage.getItem("auth") === "ROLE_USER" ? "retail" : "wholesale",
    shippingAddress: "",
    shippingCost: "",
    shippingCountry: "Nepal",
    shippingState: "",
  });

  const {
    access_key,
    amount,
    bill_to_address_city,
    bill_to_address_country,
    bill_to_address_line1,
    bill_to_address_postal_code,
    bill_to_address_state,
    bill_to_email,
    bill_to_forename,
    bill_address1,
    bill_city,
    customer_lastname,
    bill_to_phone,
    bill_to_surname,
    currency,
    locale,
    payment_method,
    profile_id,
    reference_number,
    signed_date_time,
    transaction_type,
    transaction_uuid,
    signed_field_names,
    unsigned_field_names,
    signature,
  } = token;

  // connect IPS
  const {
    appId,
    appName,
    merchantId,
    particulars,
    referenceId,
    remarks,
    txnCrncy,
    txnId,
    txnamt,
    token: connectIpsToken,
    txnDate,
  } = ipsToken;

  /* ------------------------------- final price ------------------------------ */

  const ORDER_DICOUNT = Math.round(billAmount * codeResult.discount * 0.01);

  function finalProductPrice(value) {
    if (value <= codeResult.maxDiscount) {
      return billAmount - value;
    }
    return billAmount - codeResult.maxDiscount;
  }

  const vhfinal =
    state[0]?.type === "quote"
      ? finalProductPrice(ORDER_DICOUNT) + state[0]?.shippingCost
      : finalProductPrice(ORDER_DICOUNT) + orders?.shippingCost;

  const finalPrice =
    state[0]?.type === "quote"
      ? Math.round(billAmount + state[0]?.shippingCost)
      : Math.round(billAmount + orders?.shippingCost);

  // grand total
  const GRAND_TOTAL = Math.round(
    rewardPoints && profile.rewardPoints >= 100
      ? codeResult.discountType === "AMOUNT"
        ? finalPrice - codeResult.discount - profile.rewardPoints / 10
        : codeResult.maxDiscount - profile.rewardPoints / 10
        ? vhfinal - profile.rewardPoints / 10
        : finalPrice - profile.rewardPoints / 10
      : codeResult.discountType === "AMOUNT"
      ? finalPrice - codeResult.discount
      : codeResult.maxDiscount
      ? vhfinal
      : finalPrice
  );

  const GRAND_TOTAL_IF_NEGATIVE =
    GRAND_TOTAL < rewardPoints && profile.rewardPoints >= 100 ? 0 : GRAND_TOTAL;

  const REWARD_DISCOUNT =
    profile.rewardPoints / 10 > finalPrice
      ? finalPrice
      : profile.rewardPoints / 10;

  // add order
  const AddOrderhandle = async (e) => {
    setBtnDisable(true);
    setShowModal(false);
    try {
      let SelctedPaymentMethod;
      switch (paymentLoading.radioSelect) {
        case "connectIps":
          SelctedPaymentMethod = "CONNECT_IPS";
          break;
        case "esewa":
          SelctedPaymentMethod = "ESEWA";
          break;
        case "card":
          SelctedPaymentMethod = "CARD_PAYMENT";
          break;
        case "khalti":
          SelctedPaymentMethod = "KHALTI";
          break;

        default:
          SelctedPaymentMethod = "CASH_ON_DELIVERY";
          break;
      }
      const res = await AxiosConfig.post(
        `/kreesa/${state[0]?.type === "quote" ? "approve" : "order"}`,
        {
          ...orders,
          orderDetails: props.location.state,
          ...(state[0]?.type === "quote" && {
            quoteApprovalId: state[0]?.quoteId,
          }),
          useRewardPoints: rewardPoints,
          defaultAddress: defaultAddress,
          totalCost: GRAND_TOTAL_IF_NEGATIVE,
          voucherDiscount:
            Math.round(
              codeResult.discountType === "AMOUNT"
                ? codeResult.discount
                : codeResult.maxDiscount && finalPrice - vhfinal
            ) ?? 0,
          voucherId: codeResult.id ?? null,
          payment: {
            paymentMethod: SelctedPaymentMethod,
            transactionId:
              SelctedPaymentMethod === "CONNECT_IPS"
                ? transaction_uuid?.slice(0, 18)
                : transaction_uuid,
            totalPayment: GRAND_TOTAL_IF_NEGATIVE,
            paymentStatus: "unpaid",
          },
        }
      ).then((res) => {
        setConnectTxn(res?.data?.id);
        localStorage.getItem("auth") === "ROLE_USER"
          ? props.location.state.map(
              (i) =>
                i?.cardId &&
                dispatch(
                  removeCartRetailItem({
                    cartId: i?.cartId,
                    userId: history.location.pathname.includes("/custom-order")
                      ? customOrderData.userId
                      : localStorage.getItem("userId"),
                  })
                )
            )
          : props.location.state.map(
              (i) =>
                i?.cardId &&
                dispatch(
                  removeCartItem({
                    cartId: i?.cartId,
                    userId: history.location.pathname.includes("/custom-order")
                      ? customOrderData.userId
                      : localStorage.getItem("userId"),
                  })
                )
            );

        if (paymentLoading.radioSelect === "cash") {
          // history.replace();
          toast.success(`Order Placed !`);
          localStorage.removeItem("customOrder");

          setTimeout(() => {
            history.replace({ pathname: "/home", state: [] });
          }, 1500);
        }

        setBtnDisable(false);
      });
    } catch (err) {
      console.log(err);
      toast.error(`An Error Occurred !`);
      setBtnDisable(false);
      // setShowModal(false);
    }
  };
  // get time for visa & debit card
  let timesCount = new Date().toISOString();
  let times = timesCount.split(".")[0]?.concat("Z");

  /* ----------------------------------- debit & credit handler start ---------------------------------- */
  const paymentHandler = async (values) => {
    try {
      const res = await AxiosConfig.post("/kreesa/payment/signature", {
        ...values,
      });
      if (res.data) {
        setToken(res.data);
        setPaymentLoading({ ...paymentLoading, signature: true });
        // AddOrderhandle();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const QUOTE_ORDER_DICOUNT = Math.round(
  //   billAmount +
  //     state[0]?.shippingCost -
  //     (billAmount + state[0]?.shippingCost * codeResult.discount) / 100
  // );

  /* ----------------------------------- debit & credit handler end ---------------------------------- */
  let transitionUuid = uuid();

  /* ----------------------------------- connect IPS handler ---------------------------------- */
  const connectIpsHandler = async (values) => {
    try {
      const res = await AxiosConfig.post(
        "/kreesa/payment/connect-ips/signature",
        {
          ...values,
        }
      );
      if (res.data) {
        setIpsToken(res.data);
        setPaymentLoading({ ...paymentLoading, signature: true });
        // AddOrderhandle();
      }
    } catch (err) {
      console.log(err);
    }
  };
  // UserOrderValidation

  // UserOrderValidation();

  /* ------------------------------- Cupon code ------------------------------- */
  const coupenHandler = async (values) => {
    try {
      const res = await AxiosConfig.post(
        `${
          localStorage.getItem("auth") === "ROLE_USER"
            ? "/kreesa/voucher/apply/customer"
            : "/kreesa/voucher/apply/seller"
        }`,
        { code: values.code, userId: localStorage.getItem("userId") }
      );

      if (res.data && res.status === 200) {
        setCodeResult(res.data);
      }
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.message);
    }
  };

  /* ------------------------------ khalti congig ----------------------------- */

  const checkoutKhaltiHandler = async () => {
    // header configuration
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    const res = await AxiosConfig.post(
      "/kreesa/payment/khalti",
      {
        return_url: window.location.origin + "/payment-success/khalti",
        website_url: window.location.origin,
        amount: Math.round(codeResult.maxDiscount ? vhfinal : finalPrice) * 100,
        purchase_order_id: transaction_uuid,
        purchase_order_name: state[0]?.name,
      },
      config
    );
    if (res && res.status === 200) {
      AddOrderhandle();
      window.location.href = res.data.payment_url;
    }
  };

  /* ------------------------------- Cupon code ------------------------------- */

  Cookies.set("__connectIpsxnamt", txnamt);

  return profile ? (
    <div className="text-capitalize" style={{ height: "100vh" }}>
      {btnDisable ? (
        <Spinner />
      ) : (
        <main className="container">
          <div>
            <div className="py-2 text-center">
              <Link
                to={
                  localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
                  localStorage.getItem("auth") === "ROLE_RETAILER"
                    ? "/home"
                    : "/"
                }
              >
                <img
                  className="d-block mx-auto mb-2 img-fluid"
                  style={{ borderRadius: "50%" }}
                  src={logo}
                  alt="kreesa"
                  width="72"
                  height="57"
                />
              </Link>
              <h3>Order form</h3>

              <p className="lead">You can easily order from here...</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-8 mx-auto">
              <div className="row">
                <h4 className="mb-3">Please Choose a Billing address</h4>
                <div
                  className="d-flex flex-row mt-2 mb-4"
                  style={{ gap: "60px" }}
                >
                  <div className="form-check">
                    <input
                      onChange={(e) => {
                        AxiosConfig.get(
                          `/kreesa/check/get-district/${profile?.provinceId}`
                        ).then((res) => setDistrict(res.data));
                        setDefaultAddress(true);
                      }}
                      className="form-check-input"
                      type="radio"
                      name="addressChoose"
                      id="defaultAddress"
                      checked={defaultAddress === true}
                    />
                    <label className="form-check-label" for="defaultAddress">
                      Default Address
                    </label>
                  </div>
                  {!history.location.pathname.includes("custom-order") && (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        onChange={(e) => {
                          setDefaultAddress(false);
                        }}
                        type="radio"
                        name="addressChoose"
                        id="newAddress"
                        checked={defaultAddress === false}
                      />
                      <label className="form-check-label" for="newAddress">
                        Add New Address
                      </label>
                    </div>
                  )}
                </div>
                <Formik
                  initialValues={{
                    fullName: "",
                    contact: "",
                    country: "",
                    province: "",
                    district: "",
                    streetAddress: "",
                    landmark: "",
                    pincode: "",
                    shippingCost: "",
                  }}
                  validationSchema={Yup.object().shape({
                    fullName:
                      !defaultAddress &&
                      Yup.string().required("Full Name is Required "),
                    streetAddress:
                      !defaultAddress &&
                      Yup.string().required("Street is Required "),
                    contact:
                      !defaultAddress &&
                      Yup.number()
                        .required("Contact is Required")
                        .typeError("you must specify a number"),
                    landmark: Yup.string(),
                    pincode: Yup.string(),
                    streetAddress:
                      !defaultAddress &&
                      Yup.string().required("Street is Required "),
                    country:
                      !defaultAddress &&
                      Yup.string().required("Select Country "),
                    province:
                      !defaultAddress &&
                      Yup.string().required("Select Province "),
                    district:
                      !defaultAddress &&
                      Yup.string().required("Select District "),
                  })}
                  onSubmit={(values) => {
                    let finalValue = {
                      ...orders,
                      contactNo: values.contact,
                      fullName: values.fullName,
                      shippingCost: values.shippingCost,
                      totalCost: orders.totalCost + values.shippingCost,
                      shippingState: values.province,
                      shippingAddress:
                        (values.pincode?.length > 0 && values.pincode + ",") +
                        (values.landmark?.length > 0 && values.landmark + ",") +
                        values.streetAddress,
                    };
                    setOrders(finalValue);
                  }}
                >
                  {({
                    handleChange,
                    setFieldValue,
                    errors,
                    touched,
                    handleSubmit,
                    values,
                    resetForm,
                    isValid,
                  }) => (
                    <Form onChange={() => isValid && setValidateForm(true)}>
                      <div className="row">
                        <div className="col-sm-6">
                          <label
                            htmlFor="fullName"
                            className="form-label font-weight-bold"
                          >
                            {userRole === "ROLE_USER"
                              ? "Full Name"
                              : "Company name"}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            disabled={defaultAddress}
                            className="form-control form-control-lg"
                            name="fullName"
                            value={
                              defaultAddress
                                ? localStorage.getItem("auth") === "ROLE_USER"
                                  ? profile?.firstname + " " + profile?.lastname
                                  : profile.companyName
                                : values.fullName
                            }
                            onChange={handleChange}
                            placeholder={`Enter Your ${
                              userRole === "ROLE_USER" ? "Full" : "Company"
                            } Name`}
                          />
                          {!defaultAddress &&
                          errors.fullName &&
                          touched.fullName ? (
                            <div style={{ color: "red" }}>
                              {errors.fullName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-sm-6">
                          <label
                            htmlFor="firstName"
                            className="form-label font-weight-bold "
                          >
                            Contact Number
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            disabled={defaultAddress}
                            value={
                              defaultAddress
                                ? localStorage.getItem("auth") === "ROLE_USER"
                                  ? profile?.contact
                                  : profile?.companyContactNo
                                : values.contact
                            }
                            type="tel"
                            name="contact"
                            className="form-control form-control-lg"
                            onChange={handleChange}
                            placeholder="Enter Your Contact Number"
                          />
                          {!defaultAddress &&
                          errors.contact &&
                          touched.contact ? (
                            <div style={{ color: "red" }}>{errors.contact}</div>
                          ) : null}
                        </div>
                      </div>

                      {defaultAddress ? (
                        <div className="row mt-4">
                          <div className="col-sm-4">
                            <label
                              htmlFor="firstName"
                              className="form-label font-weight-bold "
                            >
                              Country
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              disabled={true}
                              value={profile?.countryName}
                              className="form-control form-control-lg"
                            />
                          </div>
                          <div className="col-sm-4">
                            <label
                              htmlFor="firstName"
                              className="form-label font-weight-bold "
                            >
                              Province
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              disabled={true}
                              value={profile?.provienceName}
                              className="form-control form-control-lg"
                            />
                          </div>
                          <div className="col-sm-4">
                            <label
                              htmlFor="firstName"
                              className="form-label font-weight-bold "
                            >
                              District
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              disabled={true}
                              value={profile?.districtName}
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="row mt-4">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="radio"
                                className="font-weight-bold"
                              >
                                Country <span className="text-danger"> *</span>
                              </label>
                              <div
                                className="select-list"
                                style={{
                                  border: "1px solid #ced4da",
                                  color: "black",
                                }}
                              >
                                <select
                                  name="country"
                                  onChange={(e) => {
                                    if (e.target.value === "Select Country") {
                                      setFieldValue("country", "");
                                      setProvince([]);
                                      return;
                                    }

                                    AxiosConfig.get(
                                      `/kreesa/check/get-province/${e.target.value}`
                                    ).then((res) => {
                                      setProvince(res.data);
                                      setDistrict([]);
                                      setFieldValue(
                                        "country",
                                        country?.find(
                                          (i) => i.id == e.target.value
                                        )?.country_name
                                      );
                                    });
                                  }}
                                >
                                  <option>Select Country</option>
                                  {country?.map((i) => (
                                    <option key={i.id} value={i.id}>
                                      {i.country_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {errors.country && touched.country ? (
                              <div style={{ color: "red" }}>
                                {errors.country}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="radio"
                                className="font-weight-bold"
                              >
                                {" "}
                                Province <span className="text-danger"> *</span>
                              </label>
                              <div
                                className="select-list"
                                style={{ border: "1px solid #ced4da" }}
                              >
                                <select
                                  name="provinceId"
                                  onChange={(e) => {
                                    if (e.target.value === "Select Province") {
                                      setDistrict([]);
                                      setFieldValue("province", "");
                                      return;
                                    }
                                    AxiosConfig.get(
                                      `/kreesa/check/get-district/${e.target.value}`
                                    ).then((res) => {
                                      setDistrict(res.data);
                                      setFieldValue(
                                        "province",
                                        province?.find(
                                          (i) => i.id == e.target.value
                                        )?.provience_name
                                      );
                                    });
                                  }}
                                >
                                  <option> Select Province </option>
                                  {province?.map((i) => (
                                    <option key={i.id} value={i.id}>
                                      {i.provience_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {errors.province && touched.province ? (
                              <div style={{ color: "red" }}>
                                {errors.province}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="radio"
                                className="font-weight-bold"
                              >
                                {" "}
                                District <span className="text-danger"> *</span>
                              </label>
                              <div
                                className="select-list"
                                style={{ border: "1px solid #ced4da" }}
                              >
                                <select
                                  name="district"
                                  onChange={(e) => {
                                    if (e.target.value === "Select District") {
                                      setFieldValue("district", "");

                                      return;
                                    }
                                    setFieldValue(
                                      "district",
                                      district?.find(
                                        (i) => i.id == e.target.value
                                      )?.district_name
                                    );

                                    setFieldValue(
                                      "shippingCost",
                                      district?.find(
                                        (i) => i.id == e.target.value
                                      )?.deliveryCharge
                                    );

                                    setOrders({
                                      ...orders,
                                      shippingCost: district?.find(
                                        (i) => i.id == e.target.value
                                      )?.deliveryCharge,
                                    });
                                  }}
                                >
                                  <option> Select District </option>
                                  {district?.map((i) => (
                                    <option key={i.id} value={i.id}>
                                      {i.district_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            {errors.district && touched.district ? (
                              <div style={{ color: "red" }}>
                                {errors.district}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div className="row mt-4">
                        <div className="col-sm-4">
                          <label
                            htmlFor="address"
                            className="form-label font-weight-bold"
                          >
                            Street Address
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            disabled={defaultAddress}
                            value={
                              defaultAddress
                                ? profile?.streetAddress
                                : values.streetAddress
                            }
                            className="form-control form-control-lg"
                            name="streetAddress"
                            onChange={handleChange}
                            placeholder={!defaultAddress ? "Enter Street" : "-"}
                          />
                          {!defaultAddress &&
                          errors.streetAddress &&
                          touched.streetAddress ? (
                            <div style={{ color: "red" }}>
                              {errors.streetAddress}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-sm-4">
                          <label
                            htmlFor="landmark"
                            className="form-label font-weight-bold"
                          >
                            Landmark
                            {/* <span className="text-danger"> *</span> */}
                          </label>
                          <input
                            name="landmark"
                            disabled={defaultAddress}
                            value={
                              defaultAddress
                                ? localStorage.getItem("auth") === "ROLE_USER"
                                  ? profile?.landmark
                                  : profile?.landMark
                                : values.landmark
                            }
                            className="form-control form-control-lg"
                            onChange={handleChange}
                            placeholder={
                              !defaultAddress ? "Enter Your Landmark" : "-"
                            }
                          />
                        </div>

                        <div className="col-sm-4">
                          <label
                            htmlFor="pin"
                            className="form-label font-weight-bold"
                          >
                            Pin Code
                            {/* <span className="text-danger"> *</span> */}
                          </label>
                          <input
                            name="pincode"
                            disabled={defaultAddress}
                            value={
                              defaultAddress ? profile?.pinCode : values.pincode
                            }
                            className="form-control form-control-lg"
                            onChange={handleChange}
                            placeholder={
                              !defaultAddress ? "Enter Pin Code" : "-"
                            }
                          />
                        </div>

                        <button
                          ref={userFormRef}
                          type="submit"
                          style={{ display: "none" }}
                        >
                          submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>

                <Table striped responsive="sm" style={{ marginTop: "20px" }}>
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Discount</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.map((i, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.name}</td>
                        <td>{i.orderQuantity}</td>
                        <td>Rs. {Math.round(i.price)}</td>
                        <td>{i.discount}%</td>
                        <td>
                          Rs.
                          {Math.round(i.discountedTotalPrice)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="card mb-3 col-md-6">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        code: "",
                      }}
                      onSubmit={coupenHandler}
                    >
                      {({
                        handleChange,
                        setFieldValue,
                        errors,
                        touched,
                        handleSubmit,
                        values,
                        resetForm,
                      }) => (
                        <Form>
                          <div className="form-group">
                            <label className="font-weight-bold mb-2">
                              Have coupon?{" "}
                              <span className="text-info">(optional)</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control coupon"
                                placeholder="Coupon code"
                                name="code"
                                onChange={handleChange}
                              />
                              <button
                                className="btn btn-primary px-4"
                                onClick={handleSubmit}
                              >
                                Apply
                              </button>
                            </div>
                            {errors.code && touched.code ? (
                              <div style={{ color: "red" }}>
                                {" "}
                                {errors.code}{" "}
                              </div>
                            ) : null}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                {profile.rewardPoints >= 100 && rewardStatus.rewards_status && (
                  <div className="card mb-3 col-md-12">
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-md-1">
                          <input
                            type="checkbox"
                            name="rewardPoints"
                            id="reward_point"
                            style={{
                              height: 18,
                              width: 18,
                            }}
                            onChange={() => setRewardPoints(!rewardPoints)}
                          />
                        </div>
                        <div className="col">
                          <label
                            htmlFor="reward_point"
                            style={{ fontSize: 18, fontWeight: "500" }}
                          >
                            Reward Points Discount
                          </label>
                          <div>Rs {profile.rewardPoints / 10}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-12 order-prod-desc">
                  <h4>BILL</h4>
                  <div className="desc-item">
                    <div>Total Items</div>
                    <div>: {state?.length}</div>
                  </div>
                  <div className="desc-item">
                    <div>Sub Total</div>
                    <div>
                      : Rs.{" "}
                      {state
                        ?.map((i) => Math.round(i.discountedTotalPrice))
                        .reduce((a, b) => a + b)}
                    </div>
                  </div>
                  <div className="desc-item">
                    <div>Shipping Price</div>
                    <div>
                      : Rs.{" "}
                      {state[0]?.type === "quote"
                        ? state[0]?.shippingCost
                        : orders?.shippingCost}
                    </div>
                  </div>

                  {codeResult.id && (
                    <div className="desc-item">
                      <div>Coupon Discount</div>
                      <div>
                        : Rs.
                        {/* {Math.round(
                codeResult.discountType === "AMOUNT"
                  ? finalPrice - codeResult.discount
                  : !codeResult.maxDiscount === 0 &&
                      finalPrice - vhfinal
              )} */}
                        {Math.round(
                          codeResult.discountType === "AMOUNT"
                            ? codeResult.discount
                            : codeResult.maxDiscount && finalPrice - vhfinal
                        )}
                      </div>
                    </div>
                  )}
                  {rewardPoints && profile.rewardPoints >= 100 && (
                    <div className="desc-item">
                      <div>Reward Discount</div>
                      <div>: Rs. {REWARD_DISCOUNT}</div>
                    </div>
                  )}
                  <hr
                    style={{
                      margin: "5px 0px",
                      color: "teal",
                      width: "230px",
                    }}
                  />
                  <div className="desc-item font-weight-bold">
                    <div>Total</div>
                    <div>
                      : Rs. {/* codeResult */}
                      {/* state[0]?.type === "quote" ? Math.round(billAmount +
            state[0]?.shippingCost) : Math.round(billAmount +
            orders?.shippingCost); */}
                      {/* {Math.round(codeResult.maxDiscount ? vhfinal : finalPrice)} */}
                      {GRAND_TOTAL_IF_NEGATIVE}
                    </div>
                  </div>
                </div>
              </div>
              {/*  --------------------------- visa or debit card start ---------------------------  */}
              <Formik
                enableReinitialize={true}
                initialValues={{
                  access_key: process.env.REACT_APP_CARD_ACCESS_KEY,
                  profile_id: process.env.REACT_APP_CARD_PROFILE_ID,
                  transaction_uuid: transitionUuid,
                  signed_field_names:
                    "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,bill_address1,bill_city,customer_lastname",
                  unsigned_field_names:
                    "card_type,card_number,card_expiry_date",
                  signed_date_time: times,
                  locale: "en",
                  transaction_type: "sale",
                  reference_number:
                    profile?.userId === null ? "" : profile?.userId,
                  amount: Math.round(
                    codeResult.maxDiscount ? vhfinal : finalPrice
                  ),
                  currency: "NPR",
                  payment_method: "card",
                  bill_to_forename: "Kreesa",
                  bill_to_surname: "Sales",
                  bill_address1: "NA",
                  bill_city: "NA",
                  customer_lastname: "NA",
                  bill_to_email: localStorage.getItem("username"),
                  bill_to_phone: "0987654321",
                  bill_to_address_line1: "NA",
                  bill_to_address_city: "NA",
                  bill_to_address_state: "Bagmati Province",
                  bill_to_address_country: "NP",
                  bill_to_address_postal_code: "Kathmandu",
                }}
                onSubmit={paymentHandler}
              >
                {({ handleChange, handleSubmit, values, touched, errors }) => (
                  <Form onSubmit={handleSubmit} method="POST" id="frm-nicasia">
                    <div className="form-group">
                      <input
                        type="hidden"
                        name="access_key"
                        value={values.access_key}
                      />
                      <input
                        type="hidden"
                        name="profile_id"
                        value={values.profile_id}
                      />
                      <input
                        type="hidden"
                        name="transaction_uuid"
                        value={values.transaction_uuid}
                      />
                      <input
                        type="hidden"
                        name="signed_field_names"
                        value={values.signed_field_names}
                      />
                      <input
                        type="hidden"
                        name="unsigned_field_names"
                        value={values.unsigned_field_names}
                      />
                      <input
                        type="hidden"
                        name="signed_date_time"
                        value={values.signed_date_time}
                      />
                      <input
                        type="hidden"
                        name="locale"
                        value={values.locale}
                      />
                      <input
                        type="hidden"
                        name="amount"
                        value={values.amount}
                      />
                      <input
                        type="hidden"
                        name="bill_to_forename"
                        value={values.bill_to_forename}
                      />
                      <input
                        type="hidden"
                        name="bill_to_surname"
                        value={values.bill_to_surname}
                      />
                      <input
                        type="hidden"
                        name="bill_to_email"
                        value={values.bill_to_email}
                      />
                      <input
                        type="hidden"
                        name="bill_to_phone"
                        value={values.bill_to_phone}
                      />
                      <input
                        type="hidden"
                        name="bill_to_address_line1"
                        value={values.bill_to_address_line1}
                      />
                      <input
                        type="hidden"
                        name="bill_to_address_city"
                        value={values.bill_to_address_city}
                      />
                      <input
                        type="hidden"
                        name="bill_to_address_state"
                        value={values.bill_to_address_state}
                      />
                      <input
                        type="hidden"
                        name="bill_to_address_country"
                        value={values.bill_to_address_country}
                      />
                      <input
                        type="hidden"
                        name="bill_to_address_postal_code"
                        value={values.bill_to_address_postal_code}
                      />
                      <input
                        type="hidden"
                        name="transaction_type"
                        value={values.transaction_type}
                      />
                      <input
                        type="hidden"
                        name="reference_number"
                        value={values.reference_number}
                      />
                      <input
                        type="hidden"
                        name="currency"
                        value={values.currency}
                      />
                      <input
                        type="hidden"
                        name="payment_method"
                        value={values.payment_method}
                      />
                      <input
                        type="hidden"
                        name="card_type"
                        className="form-control"
                        value={values.card_type}
                      />
                      <input
                        type="hidden"
                        name="card_number"
                        className="form-control"
                        value={values.card_number}
                      />
                      <input
                        type="hidden"
                        name="card_expiry_date"
                        className="form-control"
                        value={values.card_expiry_date}
                      />
                    </div>
                    <div className="mt-2">
                      Note :{" "}
                      <Link
                        to={
                          localStorage.getItem("auth") === "ROLE_WHOLESALER"
                            ? "/wholesaler/profile-settings"
                            : localStorage.getItem("auth") === "ROLE_USER"
                            ? "/manage-account?my-profile&edit"
                            : "/retailer/profile-settings"
                        }
                        className="font-weight-bold"
                      >
                        If you want to change your default address go to your
                        account ,edit your Profile and Update your Address from
                        there.
                      </Link>
                    </div>
                    <button
                      id="orderbtn"
                      className="w-100 mt-4 btn btn-primary btn-lg"
                      type="submit"
                      onClick={() => {
                        connectIpsRef.current.click();
                        if (!defaultAddress) {
                          //   if (
                          //     orders.contactNo === "" &&
                          //     orders.shippingAddress === "" &&
                          //     orders.shippingState === ""
                          //   ) {
                          //     toast.error("Please fill up the form");
                          //   } else {
                          userFormRef.current.click();
                          validateForm && setShowModal(true);

                          //   }
                        } else {
                          console.log(profile.countryName, "Profile")
                          if(!profile.countryName){
                            setShowEditProfileModal(true);
                          }else{
                            setOrders({
                              ...orders,
                              contactNo:
                                localStorage.getItem("auth") === "ROLE_USER"
                                  ? profile?.contact
                                  : profile?.companyContactNo,
                              fullName:
                                localStorage.getItem("auth") === "ROLE_USER"
                                  ? profile?.firstname + " " + profile?.lastname
                                  : profile?.companyName,
                              shippingCost:
                                state[0]?.type === "quote"
                                  ? state[0]?.shippingCost
                                  : profile?.deliveryCharge,
                              shippingState: profile?.provienceName,
                              totalCost:
                                state[0]?.type === "quote"
                                  ? Math.round(billAmount) +
                                    state[0]?.shippingCost
                                  : billAmount + profile?.deliveryCharge,
                              shippingAddress:
                                (profile?.pinCode?.length > 0 &&
                                  profile?.pinCode + ",") +
                                (profile?.landmark?.length > 0 &&
                                  profile?.landmark + ",") +
                                profile?.streetAddress,
                            });
                            setShowModal(true); 
                          }
                        }
                      }}
                    >
                      Continue To Checkout
                    </button>
                  </Form>
                )}
              </Formik>
              {/*  --------------------------- visa or debit card end ---------------------------  */}

              {/*  --------------------------- connectIps start ---------------------------  */}
              <Formik
                enableReinitialize={true}
                initialValues={{
                  appId: process.env.REACT_APP_CONNET_APP_ID,
                  appName: "Kreesa",
                  merchantId: process.env.REACT_APP_CONNET_MERCHANT_ID,
                  particulars: "PART-001",
                  referenceId: "REF-" + transaction_uuid?.slice(0, 10),
                  remarks: "RMKS-001",
                  txnCrncy: "NPR",
                  txnId: transaction_uuid?.slice(0, 18),
                  txnamt:
                    Math.round(codeResult.maxDiscount ? vhfinal : finalPrice) *
                    100,
                }}
                onSubmit={connectIpsHandler}
              >
                {({ handleSubmit, values, submitForm }) => (
                  <Form onLoad={submitForm} method="POST">
                    <input
                      onLoad={handleSubmit}
                      type="hidden"
                      name="merchantId"
                      value={values.merchantId}
                    />
                    <input type="hidden" name="appId" value={values.appId} />
                    <input
                      type="hidden"
                      name="appName"
                      value={values.appName}
                    />
                    <input type="hidden" name="txnId" value={values.txnId} />
                    <input
                      type="hidden"
                      name="txnCrncy"
                      value={values.txnCrncy}
                    />
                    <input type="hidden" name="txnamt" value={values.txnamt} />

                    <input
                      type="hidden"
                      name="referenceId"
                      value={values.referenceId}
                    />

                    <input
                      type="hidden"
                      name="remarks"
                      value={values.remarks}
                    />

                    <input
                      type="hidden"
                      name="particulars"
                      value={values.particulars}
                    />
                    <button style={{ display: "none" }} ref={connectIpsRef}>
                      here
                    </button>
                  </Form>
                )}
              </Formik>
              {/*  --------------------------- connectIps end ---------------------------  */}
            </div>
          </div>
        </main>
      )}

      <footer className="mt-6 text-muted text-center text-small">
        <p className="mb-1">&copy; 2020–{new Date().getFullYear()} kreesa</p>
        <ul className="list-inline pb-4">
          <li className="list-inline-item">
            <a href="#">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Support</a>
          </li>
        </ul>
        <Modal
          show={showModal}
          size="lg"
          centered
          onHide={() => setShowModal(false)}
        >
          {" "}
          <Modal.Header
            closeButton={false}
            style={{ backgroundColor: "#E5E5E5" }}
          >
            <Modal.Title>Please Choose a Payment Method</Modal.Title>
            <Modal.Title
              style={{ cursor: "pointer" }}
              onClick={() => setShowModal(false)}
            >
              X
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#E5E5E5", minHeight: 370 }}>
            <div className="row d-flex justify-content-center">
              <div
                onClick={() => {
                  setPaymentLoading({ ...paymentLoading, radioSelect: "cash" });
                }}
                className="col-10 payment-option"
                style={{
                  cursor: "pointer",
                  border:
                    paymentLoading.radioSelect === "cash"
                      ? "4px solid teal"
                      : "2px solid grey",
                }}
              >
                <label
                  htmlFor="cash-in-hand"
                  style={{ cursor: "pointer" }}
                  className="d-flex justify-content-between"
                >
                  <div>
                    <div className="d-flex ">
                      <img src="/images/cashondelivery.webp" height="65px" />
                      <div>
                        <h4 className="ml-4 mt-2 font-weight-bold">
                          Cash in hand
                        </h4>
                        <h6 className="ml-4 mt-1">
                          User must pay while recieving orders.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <input
                    style={{ display: "none" }}
                    checked={paymentLoading.radioSelect === "cash"}
                    type="radio"
                    name="checkout-type"
                    id="cash-in-hand"
                  />
                </label>
              </div>
              <hr style={{ color: "black", width: "100%" }} className="m-3" />
              <h4 className="ml-3 mt-1 font-weight-bold">
                Other Available Payment Methods
              </h4>
              <div className="container">
                <div className="row mx-auto">
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setPaymentLoading({
                        ...paymentLoading,
                        radioSelect: "card",
                      });
                    }}
                    className="col-sm cursor-pointer"
                  >
                    <img
                      style={{
                        cursor: "pointer",
                        border:
                          paymentLoading.radioSelect === "card" &&
                          "2px solid teal",
                      }}
                      src="/images/card.webp"
                      height="65px"
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      setPaymentLoading({
                        ...paymentLoading,
                        radioSelect: "esewa",
                      });
                    }}
                    className="col-sm col-md-4 payment__modal cursor-pointer"
                  >
                    <img
                      style={{
                        cursor: "pointer",
                        border:
                          paymentLoading.radioSelect === "esewa" &&
                          "2px solid teal",
                      }}
                      src="/images/esewa.webp"
                      height="65px"
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      setPaymentLoading({
                        ...paymentLoading,
                        radioSelect: "khalti",
                      });
                    }}
                    className="col-sm col-md-4 payment__modal cursor-pointer"
                  >
                    <img
                      style={{
                        cursor: "pointer",
                        border:
                          paymentLoading.radioSelect === "khalti" &&
                          "2px solid teal",
                      }}
                      src="/images/Khalti-collaborations.png"
                      height="65px"
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      setPaymentLoading({
                        ...paymentLoading,
                        radioSelect: "connectIps",
                      });
                    }}
                    className="col-sm mt-lg-4 col-md-4 payment__modal cursor-pointer"
                  >
                    <img
                      onClick={() => connectIpsRef.current.click()}
                      style={{
                        cursor: "pointer",
                        border:
                          paymentLoading.radioSelect === "connectIps" &&
                          "2px solid teal",
                      }}
                      src="/images/connectips.webp"
                      height="65px"
                    />
                  </div>
                </div>
              </div>
            </div>

            {paymentLoading.radioSelect === "cash" && (
              <div className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (paymentLoading.radioSelect === "cash") {
                      AddOrderhandle();
                    }
                  }}
                  disabled={btnDisable}
                  className="btn btn-lg btn-primary"
                  type="submit"
                >
                  {btnDisable ? "Please wait . . ." : "Submit"}
                </button>
              </div>
            )}
            {paymentLoading.radioSelect === "connectIps" && (
              <form action={process.env.REACT_APP_CONNET_BASEURL} method="post">
                <input
                  type="hidden"
                  name="MERCHANTID"
                  id="MERCHANTID"
                  value={merchantId}
                />
                <input type="hidden" name="APPID" id="APPID" value={appId} />
                <input
                  type="hidden"
                  name="APPNAME"
                  id="APPNAME"
                  value={appName}
                />
                <input type="hidden" name="TXNID" id="TXNID" value={txnId} />
                <input
                  type="hidden"
                  name="TXNDATE"
                  id="TXNDATE"
                  value={txnDate}
                />
                <input
                  type="hidden"
                  name="TXNCRNCY"
                  id="TXNCRNCY"
                  value={txnCrncy}
                />
                <input type="hidden" name="TXNAMT" id="TXNAMT" value={txnamt} />

                <input
                  type="hidden"
                  name="REFERENCEID"
                  id="REFERENCEID"
                  value={referenceId}
                />

                <input
                  type="hidden"
                  name="REMARKS"
                  id="REMARKS"
                  value={remarks}
                />

                <input
                  type="hidden"
                  name="PARTICULARS"
                  id="PARTICULARS"
                  value={particulars}
                />

                <input
                  type="hidden"
                  name="TOKEN"
                  id="TOKEN"
                  value={connectIpsToken}
                />
                <div className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end">
                  <input
                    className="btn btn-lg btn-primary"
                    value="Submit"
                    type="submit"
                    onClick={() => AddOrderhandle()}
                  />
                </div>
              </form>
            )}

            {paymentLoading.radioSelect === "khalti" && (
              <div className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end">
                <input
                  className="btn btn-lg btn-primary"
                  value="Submit"
                  type="submit"
                  onClick={checkoutKhaltiHandler}
                />
              </div>
            )}
            {paymentLoading.radioSelect === "esewa" && (
              <form
                encType="multipart/form-data"
                action={process.env.REACT_APP_ESEWA_BASEURL}
                method="POST"
              >
                <input
                  value={codeResult.maxDiscount ? vhfinal : finalPrice}
                  name="tAmt"
                  type="hidden"
                />
                <input
                  value={codeResult.maxDiscount ? vhfinal : finalPrice}
                  name="amt"
                  type="hidden"
                />
                <input value="0" name="txAmt" type="hidden" />
                <input value="0" name="psc" type="hidden" />
                <input value="0" name="pdc" type="hidden" />
                <input
                  value={process.env.REACT_APP_ESEWA_SCD}
                  name="scd"
                  type="hidden"
                />
                <input value={transaction_uuid} name="pid" type="hidden" />
                <input
                  value={`${window.location.origin}/payment-success/esewa?q=su`}
                  type="hidden"
                  name="su"
                />
                <input
                  value={`${window.location.origin}`}
                  type="hidden"
                  name="fu"
                />
                <div className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end">
                  <input
                    className="btn btn-lg btn-primary"
                    value="Submit"
                    type="submit"
                    onClick={() => AddOrderhandle()}
                  />
                </div>
              </form>
            )}
            {paymentLoading.radioSelect === "card" && (
              <form
                className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end"
                id="frm-nicasia"
                action={process.env.REACT_APP_CARD_BASEURL}
                method="post"
              >
                <div className="form-group">
                  <input type="hidden" name="access_key" value={access_key} />
                  <input type="hidden" name="profile_id" value={profile_id} />
                  <input
                    type="hidden"
                    name="transaction_uuid"
                    value={transaction_uuid}
                  />
                  <input
                    type="hidden"
                    name="signed_field_names"
                    value={signed_field_names}
                  />
                  <input
                    type="hidden"
                    name="unsigned_field_names"
                    value={unsigned_field_names}
                  />
                  <input
                    type="hidden"
                    name="signed_date_time"
                    value={signed_date_time}
                  />
                  <input type="hidden" name="locale" value={locale} />
                  <input type="hidden" name="amount" value={amount} />
                  <input
                    type="hidden"
                    name="bill_to_forename"
                    value={bill_to_forename}
                  />
                  <input
                    type="hidden"
                    name="bill_to_surname"
                    value={bill_to_surname}
                  />
                  <input
                    type="hidden"
                    name="bill_address1"
                    value={bill_address1}
                  />
                  <input type="hidden" name="bill_city" value={bill_city} />
                  <input
                    type="hidden"
                    name="customer_lastname"
                    value={customer_lastname}
                  />
                  <input
                    type="hidden"
                    name="bill_to_email"
                    value={bill_to_email}
                  />
                  <input
                    type="hidden"
                    name="bill_to_phone"
                    value={bill_to_phone}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_line1"
                    value={bill_to_address_line1}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_city"
                    value={bill_to_address_city}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_state"
                    value={bill_to_address_state}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_country"
                    value={bill_to_address_country}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_postal_code"
                    value={bill_to_address_postal_code}
                  />
                  <input
                    type="hidden"
                    name="transaction_type"
                    value={transaction_type}
                  />
                  <input
                    type="hidden"
                    name="reference_number"
                    value={reference_number}
                  />
                  <input type="hidden" name="currency" value={currency} />
                  <input
                    type="hidden"
                    name="payment_method"
                    value={payment_method}
                  />
                  <input type="hidden" name="signature" value={signature} />
                  <input
                    type="hidden"
                    name="card_type"
                    className="form-control"
                    value="001"
                  />
                  <input
                    type="hidden"
                    name="card_number"
                    className="form-control"
                    value=""
                  />
                  <input
                    type="hidden"
                    name="card_expiry_date"
                    className="form-control"
                    value=""
                  />
                </div>

                <input
                  className="btn btn-lg btn-primary"
                  value="Submit"
                  type="submit"
                  onClick={() => AddOrderhandle()}
                />
                {/* <button
                  disabled={btnDisable}
                  className="btn btn-lg btn-primary"
                  type="submit"
                  value="Submit"
                  ref={buttonLoader}
                  onClick={() => AddOrderhandle()}
                >
                  {paymentLoading.signature === false
                    ? "Please wait"
                    : "Submit"}
                </button> */}
              </form>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={showEditProfileModal}
          centered
          onHide={() => setShowEditProfileModal(false)}
        >
          {" "}
          <Modal.Header
            closeButton={false}
            style={{ backgroundColor: "#E5E5E5" }}
          >
            <Modal.Title
              style={{ cursor: "pointer"}}
              onClick={() => setShowEditProfileModal(false)}
            >
              X
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#E5E5E5", minHeight: 370, paddingTop: 0 }}>
              <EditProfile
              isModal={true}
              onSuccess={()=>{
                setShowEditProfileModal(false);
                AxiosConfig.get(
                  `/kreesa/admin/get-user-by-user-id/${
                    history.location.pathname.includes("/custom-order")
                      ? JSON.parse(localStorage.getItem("customOrder")).userId
                      : localStorage.getItem("userId")
                  }`,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                  .then((res) => {
                    setProfile(res.data);
                    setOrders({ ...orders, shippingCost: res.data.deliveryCharge });
                  })
                  .catch((err) => toast.error("An Error Occurred "));
              }}
              >
              </EditProfile>
          </Modal.Body>
        </Modal>
      </footer>
    </div>
  ) : (
    <Spinner />
  );
};

export default Checkout;
