import { createSlice } from "@reduxjs/toolkit";
import {
  confirmCustomOrderPlacement,
  getCustomOrder,
} from "../../Action/CustomOrderAction";

const CustomOrder = createSlice({
  name: "customOrder",
  initialState: {
    customOrder: [],
    customOrderContainer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterCustomOrder: (state, action) => {
      state.customOrder = state.customOrderContainer.filter(
        (u) =>
          u.orderDescription.toLowerCase().includes(action.payload) ||
          u.orderDescription.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Custom Order */
    [getCustomOrder.pending]: (state, action) => {
      state.loader = true;
    },
    [getCustomOrder.fulfilled]: (state, action) => {
      state.loader = false;
      state.customOrder = action.payload;
      state.customOrderContainer = action.payload;
    },
    [getCustomOrder.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    /* to get all Custom Order */
    [confirmCustomOrderPlacement.pending]: (state, action) => {
      state.loader = true;
    },
    [confirmCustomOrderPlacement.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [confirmCustomOrderPlacement.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterCustomOrder } = CustomOrder.actions;
// export default
export default CustomOrder.reducer;
