import { Pagination } from "@mantine/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { getProductsOfWholesaler } from "../../../Features/Action/ProductAction";
import { approveAndRejectProductStatus } from "../../../Features/Action/ProductAction/UpdateStatusAction";
import Spinner from "../../../Spinner";

const RejectedProductTab = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = props.data?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(props.data?.length / perPage);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getProductsOfWholesaler());
  }, []);

  const ApproveStatus = async (id) => {
    await dispatch(approveAndRejectProductStatus("Approved" + "/" + id));
    // toast.success("Product has been Approved");
    // history.push("/admin/product/view/wholesale");
    dispatch(getProductsOfWholesaler());
  };
  //
  let j = 1;
  return !props.loader ? (
    <div className="row">
      <div className="col-12 col-lg-8 d-flex w-100">
        <div className="card flex-fill" style={{ minHeight: "420px" }}>
          <table className="table table-hover my-0 ">
            <thead>
              <tr>
                <th>Sn.</th>
                <th>Products Name</th>
                <th>Status</th>
                <th>Category</th>
                <th>Sell By</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th className="d-md-table-cell">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries?.map((p, i) => (
                <tr key={i}>
                  <td>{j++}</td>
                  <td>
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${p.image.id}/${p.image?.imageName}`}
                      width="48"
                      height="48"
                      loading="lazy"
                      className="rounded-circle mr-2"
                      alt={p.productName}
                    />
                    {p.productName}
                  </td>
                  <td>
                    <span className="badge bg-danger">Rejected</span>
                  </td>
                  <td>
                    {p.primaryCategoryName}/{p.secondaryCategoryName}/
                    {p.ternaryCategoryName}
                  </td>
                  <td>{p.companyName}</td>
                  <td>{moment(p.createdAt).format("ll")}</td>
                  <td>{moment(p.updatedAt).format("ll")}</td>
                  <td>
                    <div className="btn-group">
                      <button
                        type="button"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "#3b7ddd",
                        }}
                        className="btn-md btn btn-success dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                      </button>
                      <div className="dropdown-menu text-left">
                        {/* <Link className="dropdown-item text-info">
                                <i className="fas fa-user-edit"></i> Edit
                              </Link> */}
                        {localStorage.getItem("auth") === "ROLE_CEO" && (
                          <>
                            <Link
                              to={`/admin/product/view/wholesale/${p.id}`}
                              className="dropdown-item text-success"
                            >
                              <i className="fas fa-eye "></i> Review
                            </Link>
                            <div className="dropdown-divider" />
                          </>
                        )}
                        <div
                          onClick={() => ApproveStatus(p.id)}
                          className="dropdown-item text-primary cursor-pointer"
                        >
                          <i className="fa fa-check"></i> Approved
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {props.data?.length > 0 && (
        <Pagination
          onChange={setCurrentPage}
          page={currentEntries}
          total={totalPagesNum}
        />
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default RejectedProductTab;
