import React from "react";
import { Route, Switch } from "react-router-dom";
import Homepage from "../../Homepage/Homepage";
import Contact from "../../Components/User/Contact";
import Signup from "../../Components/User/UserSignup";
import UserSignup from "../../Components/User/UserSignup/UserSignup";
import Login from "../../Components/User/UserLogin";
import SingleProduct from "../../Components/User/SingleProduct";
import MyAccount from "../../Components/User/MyAccount";
import Basket from "../../Components/User/Basket";
import UserMessage from "../../Components/User/UserSignup/UserMessage";
import UserForgetPassword from "../../Components/User/UserForgetPassword";
import UserOtpVerification from "../../Components/User/UserOtpVerification";
import UserResetPassword from "../../Components/User/UserResetPassword";
import LoginRouting from "../PrivateRoute/loginRouting";
import ConsumerRouting from "../PrivateRoute/consumerRouting";
import Checkout from "../../Components/User/Checkout";
import SearchPageUser from "../../Components/User/searchPage";
import TermsandConditions from "../../Components/User/TermsandConditions";
import AboutUs from "../../Components/User/AboutUs";
import RegisterPage from "../../Components/User/UserSignup/RegisterPage";
import OrderNow from "../../Components/RetailerWholeSaler/OrderNow";
import EsewaSuccess from "../../Components/User/Payment/EsewaSuccess";
import ConnectIpsSuccess from "../../Components/User/Payment/ConnectIpsSuccess";
import JobPortal from "../../Components/User/JobPortal";
import JobDetailsPage from "../../Components/User/JobPortal/job-details";
import ReturnPolicy from "../../Components/User/UserFooter/returnPolicy";
import ShippingGuide from "../../Components/User/UserFooter/shippingGuide";
import PaymentMethod from "../../Components/User/UserFooter/paymentMethod";
import SalerPage from "../../Components/User/UserFooter/salerPage";
import KreesaComplaint from "../../Components/User/UserFooter/kreesaComplaint";
import KreesaTeaches from "../../Components/User/UserFooter/kreesaTeaches";
import WishList from "../../Components/User/MyAccount/WishList";
import onlineSellingGuide from "../../Components/User/UserFooter/onlineSellingGuide";
import NewsBlogNotice from "../../Components/User/NewsBlogNotice";
import SingleOrderDetailUser from "../../Components/User/MyOrder/SingleOrderDetail";
import TermsOfUse from "../../Components/User/UserFooter/TermsOfUse";
import PrivacyPolicy from "../../Components/User/UserFooter/privacyPolicy";
import SinglePage from "../../Components/User/NewsBlogNotice/SinglePage";
import QuotationPage from "../../Components/User/Quotation/QuotationPage";
import MyQuotation from "../../Components/User/Quotation/MyQuotation";
import ViewAll from "../../Components/User/BestSellers/ViewAll";
import PayNow from "../../Components/User/PayNow";
import KhaltiSuccess from "../../Components/User/Payment/KhaltiSuccess";
import TreeNotification from "../../Components/RetailerWholeSaler/TreeNotification";

const UserRoute = () => {
  return (
    <Switch>
      {/* payment success */}
      <Route exact path="/payment-success/esewa" component={EsewaSuccess} />
      <Route exact path="/payment-success/khalti" component={KhaltiSuccess} />
      <Route
        exact
        path="/payment/connect-ips/verification/success"
        component={ConnectIpsSuccess}
      />
      {/* Homepage for user panel */}
      <Route exact path="/" component={Homepage} />

      {/* about us */}
      <Route exact path="/about" component={AboutUs} />

      <Route exact path="/contact" component={Contact} />

      {/* Credentials */}
      <LoginRouting exact path="/signup" component={Signup} />

      {/* <LoginRouting exact path="/userlogin" component={UserLogin} /> */}

      <LoginRouting exact path="/login" component={Login} />

      <LoginRouting exact path="/usersignup" component={UserSignup} />

      <Route exact path="/order-now-user" component={OrderNow} />

      {/* Forgot Password */}
      <Route exact path="/register-form" component={RegisterPage} />
      <Route
        exact
        path="/user/forgot-password"
        component={UserForgetPassword}
      />
      {/* OTP */}
      <Route
        exact
        path="/user/OTP-verification"
        component={UserOtpVerification}
      />
      {/* Reset password */}
      <Route exact path="/user/reset-password" component={UserResetPassword} />
      {/* job portal */}
      <Route exact path="/jobs" component={JobPortal} />
      <Route exact path="/job-detail/:id" component={JobDetailsPage} />

      <Route exact path="/user/search-page" component={SearchPageUser} />

      {/* Product */}
      <Route exact path="/single-product/:id" component={SingleProduct} />

      <Route exact path="/checkout-user" component={Checkout} />

      <ConsumerRouting exact path="/user/pay-now" component={PayNow} />
      {/* Manage Account */}
      <ConsumerRouting exact path="/manage-account" component={MyAccount} />

      <ConsumerRouting
        exact
        path="/order-details-user/:id"
        component={SingleOrderDetailUser}
      />

      {/* cart */}
      <Route exact path="/user-cart" component={Basket} />

      {/* activation-link */}
      <Route exact path="/activation-link" component={UserMessage} />

      {/* terms and conditions */}
      <Route exact path="/termsandconditions" component={TermsandConditions} />

      {/* privacy policy */}
      <Route exact path="/return-policy" component={ReturnPolicy} />

      {/*  */}
      <Route exact path="/seller-page" component={SalerPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />

      <Route exact path="/selling-guide" component={onlineSellingGuide} />

      {/*  */}
      <Route exact path="/complaint" component={KreesaComplaint} />

      <Route exact path="/kreesa-teaches" component={KreesaTeaches} />

      {/* shipping guide */}
      <Route exact path="/shipping-guide" component={ShippingGuide} />
      <Route exact path="/terms-of-use" component={TermsOfUse} />

      {/* shipping guide */}
      <Route exact path="/payment-methods" component={PaymentMethod} />
      <Route exact path="/wishlist" component={WishList} />

      <Route exact path="/news" component={NewsBlogNotice} />
      <Route exact path="/blog/view/:id" component={SinglePage} />
      <Route exact path="/quotation" component={QuotationPage} />
      <Route exact path="/user/quotations/:id" component={MyQuotation} />
      <Route exact path="/view-all/:title" component={ViewAll} />

      <Route exact path="/tree-notification" component={TreeNotification} />

      {/* Tree Plantation */}

      {/* <Route exact path="/tree/my-planted-tree" component={MyPlatnedTree} /> */}
    </Switch>
  );
};

export default UserRoute;
