import { createSlice } from "@reduxjs/toolkit";
import {
  getAdminNotification,
  getAllPushNotfication,
} from "../../Action/AdminNotificationAction";

const AdminNotifications = createSlice({
  name: "AdminNotifications",
  initialState: {
    Notification: [],
    loader: false,
    errors: {},
    pushNotification: [],
    pushNotificationContainer: [],
  },
  reducers: {
    filterGroupProduct: (state, action) => {},
    filterPushNotification: (state, action) => {
      state.pushNotification = state.pushNotificationContainer.filter(
        (u) =>
          u.message.toLowerCase().includes(action.payload) ||
          u.message.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Products */
    [getAdminNotification.pending]: (state, action) => {
      state.loader = true;
    },
    [getAdminNotification.fulfilled]: (state, action) => {
      state.loader = false;
      state.Notification = action.payload;
    },
    [getAdminNotification.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // push notification
    [getAllPushNotfication.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllPushNotfication.fulfilled]: (state, action) => {
      state.loader = false;
      state.pushNotification = action.payload;
      state.pushNotificationContainer = action.payload;
    },
    [getAllPushNotfication.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterPushNotification } = AdminNotifications.actions;
// export default
export default AdminNotifications.reducer;
