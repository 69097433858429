import "../BestSellers/BestSellers.css";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getRetailerProducts,
  featureProductForUser,
  getRetailerTrendingProducts,
  getRetailerOrganicProducts,
  getRetailerKreesaProducts,
  getRetailerBrandProducts,
  getAllRetailerKreesaProducts,
  getAllRetailerFlashSale,
  getRetailerFlashSale,
} from "../Features/action";
import "./grid.css";
import Card from "./CardSlider";
import ProductGrid from "./ProductGrid";

const ProductList = () => {
  const dispatch = useDispatch();
  const {
    userpageProducts,
    userFeature,
    trendingProducts,
    organicProducts,
    kreesaProducts,
    flashProducts,
    allKreesaProducts,
    brandProducts,
  } = useSelector((state) => state.userProducts);

  // featured products
  useEffect(() => {
    dispatch(getRetailerProducts("retail"));
    dispatch(featureProductForUser("retail"));
    dispatch(getRetailerTrendingProducts("retail"));
    dispatch(getRetailerOrganicProducts("retail"));
    // dispatch(getRetailerKreesaProducts("retail"));
    dispatch(getRetailerBrandProducts("retail"));
    // to get all kreesa products
    dispatch(getAllRetailerKreesaProducts("retail"));
    dispatch(getAllRetailerKreesaProducts("retail"));
    dispatch(getRetailerFlashSale("retail"));
  }, []);

  return (
    <div>
      {/* Flash products */}
      {flashProducts?.length > 0 && (
        <Card title="Flash Sale" data={flashProducts} />
      )}
      {/* Recent products */}
      {userpageProducts?.length > 0 && (
        <ProductGrid title="Recent Products" data={userpageProducts} />
      )}
      {/* Feature products */}
      {userFeature?.length > 0 && (
        <Card title="Feature Products" data={userFeature} />
      )}
      {/* Brand products */}
      {brandProducts?.length > 0 && (
        <Card title="Brand products" data={brandProducts} />
      )}
      {/* Trending products */}
      {trendingProducts?.length > 0 && (
        <Card title="Trending Products" data={trendingProducts} />
      )}
      {/* Organic priducts */}
      {organicProducts?.length > 0 && (
        <Card title="Organic Products" data={organicProducts} />
      )}
      {/* kreesa products */}
      {allKreesaProducts?.length > 0 && (
        <ProductGrid title="Kreesa Products" data={allKreesaProducts} />
      )}
    </div>
  );
};

export default ProductList;
