import { Form, Formik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  AssignQuotationsByAdmin,
  getAllWholesalers,
  getSingleQuotations,
  getAllRetailers,
} from "../../Features/Action/QuotationAction";
import Spinner from "../../Spinner";
import * as Yup from "yup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import useButtonLoader from "../../../ButtonLoaderHook";
import { getCompanyType } from "../../Features/Action/CompanyTypeAction/CompanyTypeAction";
import { toast } from "react-toastify";

const animatedComponents = makeAnimated();

const QuotationAssignDetail = () => {
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Assign To",
    "Loading..."
  );
  const [individual, setIndividual] = useState("");
  const [companyTypes, setCompanyTypes] = useState("");

  const { id } = useParams();
  const history = useHistory();
  const { singlequotation, wholesalers, retailers, loader } = useSelector(
    (s) => s.QuotationsReducer
  );
  const { companyType } = useSelector((s) => s.CompanyType);

  // to get all company types
  const getAllCompanyType = companyType?.map((company) => {
    return { label: company.typeName, value: company.id };
  });

  // to get all wholesalers
  const getAllWholers = wholesalers?.map((wholesale) => {
    return { label: wholesale.fullName, value: wholesale.userId };
  });
  // to get all retailers
  const getAllRetail = retailers?.map((retailer) => {
    return { label: retailer.fullName, value: retailer.userId };
  });

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getSingleQuotations(id));
    dispatch(getCompanyType());
    if (singlequotation?.quotation_by === "ROLE_USER") {
      dispatch(getAllRetailers());
    } else {
      dispatch(getAllWholesalers());
    }
  }, [id, dispatch, singlequotation?.quotation_by]);

  // Assign order handler
  const handleAssignorder = async (values) => {
    if (values.type === "All") {
      dispatch(
        AssignQuotationsByAdmin({ type: values.type, id, toast, history })
      );
    } else if (values.type === "Individual") {
      dispatch(
        AssignQuotationsByAdmin({
          type: values.type,
          supplier_id: individual,
          id,
          toast,
          history,
        })
      );
    } else if (values.type === "company_type") {
      dispatch(
        AssignQuotationsByAdmin({
          companyTypeId: companyTypes,
          type: values.type,
          id,
          toast,
          history,
        })
      );
    }
  };

  return !loader ? (
    <div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title font-weight-bold">
              Assign Quotation Of{" "}
              {singlequotation?.quotation_by === "ROLE_WHOLESALER"
                ? "Wholesaler"
                : singlequotation?.quotation_by === "ROLE_RETAILER"
                ? "Retailer"
                : "User"}
            </h4>
            <h6 className="card-subtitle text-muted">
              {" "}
              Be sure you are in Quotation Approved of{" "}
              {singlequotation?.user?.email}.
            </h6>
            <h3 className="pt-2 text-capitalize">
              {singlequotation?.product_name}
            </h3>
          </div>
          <div className="card-body">
            <Formik
              initialValues={{
                type: "",
                supplier_id: "",
                companyTypeId: "",
              }}
              onSubmit={handleAssignorder}
              validationSchema={Yup.object().shape({
                type: Yup.string().required("Assign Field is required"),
                companyTypeId: Yup.string().when("type", {
                  is: "company_type",
                  then: Yup.string().required("Company is required"),
                }),
                supplier_id: Yup.string().when("type", {
                  is: "Individual",
                  then: Yup.string().required("Supplier Name is required"),
                }),
              })}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="col">
                    <div className="mb-3 mt-2 col-md-6">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">Assign To</span>{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        value="All"
                        onChange={handleChange}
                        className="form-check-input ml-2 trigger"
                        type="radio"
                        name="type"
                        data-rel="sim-micro-desktop"
                      />
                      <label
                        className="form-check-label pl-2"
                        htmlFor="exampleRadios1"
                      >
                        All
                      </label>
                      <input
                        value="Individual"
                        onChange={handleChange}
                        className="form-check-input ml-2 trigger"
                        type="radio"
                        name="type"
                        data-rel="sim-micro-desktop"
                      />

                      <label
                        className="form-check-label pl-2"
                        htmlFor="exampleRadios2"
                      >
                        Individual
                      </label>
                      <input
                        value="company_type"
                        onChange={handleChange}
                        className="form-check-input ml-2 trigger"
                        type="radio"
                        name="type"
                        data-rel="sim-micro-desktop"
                      />

                      <label
                        className="form-check-label pl-2"
                        htmlFor="exampleRadios2"
                      >
                        Company Type
                      </label>
                    </div>
                    {errors.type && touched.type ? (
                      <div style={{ color: "red" }}> {errors.type} </div>
                    ) : null}
                  </div>
                  {values.type === "Individual" && (
                    <div className="form-group row mt-4">
                      <div className="col-md-4">
                        <Select
                          className="Select"
                          name="supplier_id"
                          placeholder="Choose Individual"
                          onChange={(event) => {
                            setIndividual(event.value);
                            setFieldValue("supplier_id", event.value);
                          }}
                          options={
                            singlequotation.quotation_by === "ROLE_USER"
                              ? getAllRetail
                              : getAllWholers
                          }
                          components={animatedComponents}
                          autoFocus
                          // isMulti
                        />
                      </div>
                      {errors.supplier_id &&
                      touched.supplier_id &&
                      !values.supplier_id ? (
                        <div style={{ color: "red" }}>{errors.supplier_id}</div>
                      ) : null}
                    </div>
                  )}
                  {values.type === "company_type" && (
                    <div className="form-group row mt-4">
                      <div className="col-md-4">
                        <Select
                          className="Select"
                          name="companyTypeId"
                          placeholder="Choose Company Type"
                          onChange={(event) => {
                            setCompanyTypes(event.value);
                            setFieldValue("companyTypeId", event.value);
                          }}
                          options={getAllCompanyType}
                          components={animatedComponents}
                          autoFocus
                          // isMulti
                        />
                      </div>
                      {errors.companyTypeId &&
                      touched.companyTypeId &&
                      !values.companyTypeId ? (
                        <div style={{ color: "red" }}>
                          {errors.companyTypeId}
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="mb-3 mt-4 col-md-4">
                    <button
                      id=""
                      type="submit"
                      className="btn btn-success btn-lg btn-block"
                      onClick={handleSubmit}
                      ref={buttonLoader}
                    >
                      Assign
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default QuotationAssignDetail;
