import React from "react";
import { Redirect } from "react-router-dom";
import NotificationDetails from "../../Admin/Notifications/NotificationDetails";
import Footer from "../Footer";
import LeftNavbar from "../LeftNavbar";
import TopNavbar from "../TopNavbar";

const Notifications = () => {
  document.title = "WholeSaler | Notifications";
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token !== "ROLE_WHOLESALER") {
    return <Redirect exact to="/login" />;
  }
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <NotificationDetails user={token} />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Notifications;
