import React from "react";
import Footer from "../Footer";
import TopNavbar from "../TopNavbar";
import LeftNavbar from "../LeftNavbar/";
import Unimplemented from "./Unimplemented";
import Implemented from "./Implemented";
import TreeLimit from "./TreeLimit";
import { Redirect, useLocation } from "react-router-dom";

const Tree = () => {
  document.title = "Admin | Tree";
  const location = useLocation();

  const pathComponents = {
    "/admin/tree-unimplemented": <Unimplemented />,
    "/admin/tree-implemented": <Implemented />,
    "/admin/tree-limit/view": <TreeLimit />,
  };

  const componentToRender = pathComponents[location.pathname] || null;

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">{componentToRender}</main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Tree;
