import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to get all AdminNotificationAction */
export const getAdminNotification = createAsyncThunk(
  "getAdminNotification",
  async (limit) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/notification/get-all-notification?limit=${limit}`,
      config
    );
    return response.data;
  }
);

/* to get Total Notification */
export const getTotalNotification = createAsyncThunk(
  "getTotalNotification",
  async () => {
    const response = await AxiosConfig.get(
      "/kreesa/admin/notification/get-total-number-of-notification",
      config
    );
    return response.data;
  }
);

/* to Mark all as Read for Admin */
export const MarkAsReadForAdmin = createAsyncThunk(
  "MarkAsReadForAdmin",
  async (id, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/notification/mark-notification-read/${id}`,
      config
    );
    if (response && response.status === 200) {
      dispatch(getAdminNotification());
      dispatch(getTotalNotification());
    }
    return response.data;
  }
);

/* to get all AdminNotificationAction */
export const getAllPushNotfication = createAsyncThunk(
  "getAllPushNotfication",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/notification/send-push-notification`,
      config
    );
    return response.data;
  }
);
