import "../Offer/Offer.css";
import icon1 from "../../../assets/Images/icon-1.png";
import icon2 from "../../../assets/Images/icon-2.png";
import icon3 from "../../../assets/Images/icon-3.png";
import useWindowDimensions from "../../../Common/WindowDimensions/WindowDimensions";
function Offer() {
  const { width } = useWindowDimensions();
  return (
    <>
      {/* <div className="offer">
          <div className="offer2">
            <div className="sub-img-offer1">
              <img src={icon1} className="offer-icon" alt="img" />
            </div>
            <br />
            <div className="sub-offer1">
              <h3 className="offer-text">FAST & FREE SHIPPING ON ALL ORDERS</h3>
            </div>
          </div>
          <div className="offer1">
            <br />
            <div className="sub-img-offer1">
              <img src={icon2} className="offer-icon" alt="img" />
            </div>
            <div className="sub-offer1">
              <h3 className="offer-text"> 30 DAYS MONEY BACK GURANTEE</h3>
            </div>
          </div>

          <div className="offer1">
            <br />
            <div className="sub-img-offer1">
              <img src={icon3} className="offer-icon" alt="img" />
            </div>

            <div className="sub-offer1">
              <h3 className="offer-text">SAFE SHOPPING GURANTEE</h3>
            </div>
          </div>
        </div>
     */}

      {/* <div className="container-fluid offer">
        <div className="row pl-4 py-4 offerss px-lg-6">
          <div className="col offer1">
            <img src={icon1} className="offer-icon " alt="img" />

            <div className="offer-text">CHEAP SHIPPING ON ALL ORDERS</div>
          </div>
          <div className="col offer2">
            <img src={icon2} className="offer-icon" alt="img" />
            <div className="offer-text"> 7 DAYS RETURN</div>
          </div>
          <div className="col offer3">
            <img src={icon3} className="offer-icon" alt="img" />
            <div className="offer-text ">SAFE SHOPPING GURANTEE</div>
          </div>
        </div>
      </div> */}

      <div
        className="container-fluid p-5"
        style={{
          // backgroundColor: "#e2e6eb",
          backgroundImage:
            "linear-gradient(-25deg, #cfd8dc 47%, lightblue 67%)",
        }}
      >
        <div
          className="row px-lg-6"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OfferItems image={icon1} text="Affordable shipping on all orders" />
          <OfferItems image={icon2} text="UPTO 7 DAYS RETURN" />
          <OfferItems image={icon3} text="SAFE SHOPPING GUARANTEED" />
        </div>
      </div>
    </>
  );
}

export default Offer;

// offer items
const OfferItems = (props) => {
  return (
    <>
      <div
        className="col-md-4"
        style={{
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        <img src={props.image} alt={props.text} />
        <span
          style={{ fontSize: 20 }}
          className="three_step_offer_ font-weight-bold"
        >
          {props.text}
        </span>
      </div>
      <hr className="d-md-none" />
    </>
  );
};
