import React from 'react'
import Footer from '../../Footer'
import TopNavbar from '../../TopNavbar'
import LeftNavbar from '../../LeftNavbar'
import QuotationsWholesale from './QuotationsWholesale'
import { Redirect } from 'react-router-dom'

const Quotations = () => {

    document.title = "Admin | Wholesale Quotations"
    // to check authentication user or not
    let token = localStorage.getItem('token', 'username', 'auth');
    if (token === null) {
        return <Redirect exact to="/admin/login" />
    }
    return (
        <>
            <div className="wrapper">
                <LeftNavbar />
                <div className="main">
                    <TopNavbar />
                    <main className="content">
                        <QuotationsWholesale />
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Quotations
