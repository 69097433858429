import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import EditProduct from "./EditProduct";

import WLeftNavbar from "../../../Wholesaler/LeftNavbar";
import WTopNavbar from "../../../Wholesaler/TopNavbar";

const Product = () => {
  document.title = `${
    localStorage.getItem("auth") === "ROLE_WHOLESALER"
      ? "Wholsaler"
      : "Retailer"
  } | Edit Product`;
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  // if (token !== "ROLE_RETAILER") {
  //   return <Redirect exact to="/login" />;
  // }
  return (
    <>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ? (
        <div className="wrapper">
          <WLeftNavbar />
          <div className="main">
            <WTopNavbar />
            <main className="content">
              <EditProduct />
            </main>
            <Footer />
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <LeftNavbar />
          <div className="main">
            <TopNavbar />
            <main className="content">
              <EditProduct />
            </main>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
