import React from "react";
import Footer from "../../Footer";
import TopNavbar from "../../TopNavbar";
import LeftNavbar from "../../LeftNavbar/";
import { Redirect } from "react-router-dom";
import SingleOrderDetail from "./SingleOrderDetail";

const SingleOrder = () => {
  document.title = `${
    localStorage.getItem("auth") === "ROLE_WHOLESALER"
      ? "Wholesaler"
      : "Retailer"
  } | Order Details`;
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token !== "ROLE_RETAILER") {
    return <Redirect exact to="/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <SingleOrderDetail />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SingleOrder;
