import React, { useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import AllUsersTab from "./AllUsersTab";
import BlockedUsersTab from "./BlockedUsersTab";
import { useDispatch } from "react-redux";
import { filterAdmistrator } from "../../Features/Reducer/AdminReducer";
import {
  exportAdminInExcel,
  exportInExcelDownload,
  getAllAdmistrator,
} from "../../Features/Action/AdminBlockUnblockAction";
import ExportInExcel from "../../ExportInExcel";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";

const AdministratorDetails = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    dispatch(getAllAdmistrator());
  }, []);

  const filter = () => {
    dispatch(filterAdmistrator(inputRef.current.value));
  };

  const exportInExcel = async () => {
    const res = await dispatch(exportAdminInExcel());
    const fulfilled = res.meta.requestStatus === "fulfilled";

    const fileName = res.payload;

    if (fulfilled) {
      const url = AxiosConfig.defaults.baseURL + `/kreesa/document/report/${fileName}`;
      const newTab = window.open(url, "_blank");
      setTimeout(() => {
        newTab.close();
      }, 5000);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex w-100">
            <div className="card flex-fill flex-fill">
              <div className="card-header">
                <ExportInExcel onClick={exportInExcel} />
                <Link
                  to="/admin/add-new-user"
                  className="btn btn-lg btn-outline-info display-5 mt-2"
                  style={{ float: "right" }}
                >
                  Add New User <i className="fas fa-user-plus ml-2"></i>
                </Link>
                <form className="d-none mt-2 d-sm-inline-block">
                  <div className="input-group input-group-navbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Administrator…"
                      aria-label="Search"
                      ref={inputRef}
                      onChange={filter}
                    />
                    <button className="btn" type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <Tabs>
            <TabList>
              <Tab>All Users</Tab>
              <Tab>Blocked Users</Tab>
            </TabList>

            <TabPanel>
              {/* All Users */}
              <AllUsersTab />
            </TabPanel>

            <TabPanel>
              {/* Blocked Users */}
              <BlockedUsersTab />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default AdministratorDetails;
