import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { unBlockUser } from "../../Features/Action/AdminBlockUnblockAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const BlockedWholesalersTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setstate] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  // get all Blocked Wholesalers from API
  const getBlockedWholesalers = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetallUserDetails = await AxiosConfig.get(
        "/kreesa/admin/get-all-wholesaler",
        config
      );
      setstate(GetallUserDetails.data);
      setIsLoaded(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };
  useEffect(() => {
    getBlockedWholesalers();
  }, []);
  //unblock user
  const handleUnBlockUser = (id) => {
    dispatch(unBlockUser(id));
    toast.info("Retailer has been unblocked");
    setTimeout(() => {
      getBlockedWholesalers();
    }, 500);
  };
  return !isLoaded ? (
    <>
      <div
        className="col-12 col-lg-8 d-flex w-100 "
        style={{ minHeight: "420px" }}
      >
        <div className="card flex-fill overflow-auto">
          <div className="card-header">
            <h5 className="card-title mb-0">Blocked Wholesalers</h5>
          </div>
          <table className="table table-hover my-0 ">
            <thead>
              <tr>
                <th>Sn.</th>
                <th>Company Name</th>
                <th className="d-xl-table-cell">Company Email</th>
                {/* <th className="d-xl-table-cell">Company Address</th> */}
                <th className="d-xl-table-cell">Company Phone</th>
                <th className="d-xl-table-cell">Company Type</th>
                <th className="d-xl-table-cell">Status</th>
                <th className="d-md-table-cell" colSpan="2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {state?.map(
                (p, i) =>
                  p.flag == false && (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{p.companyName}</td>
                      <td className="d-xl-table-cell">
                        <a href={`mailto:${p.companyEmail}`}>
                          {p.companyEmail}
                        </a>
                      </td>
                      {/* <td className="d-xl-table-cell">{p.companyAddress}</td> */}
                      <td className="d-xl-table-cell">{p.companyContactNo}</td>
                      <td className="d-xl-table-cell">{p.companyType}</td>
                      <td>
                        <span className="badge bg-danger">Blocked</span>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success "
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>

                          <div className="dropdown-menu text-left">
                            {/* <Link className="dropdown-item text-info">
                              <i className="fas fa-user-edit"></i> Edit
                            </Link> */}
                            {localStorage.getItem("auth") !==
                              "ROLE_RECEPTIONIST" &&
                            localStorage.getItem("auth") !==
                              "ROLE_MARKETING_MANAGER" ? (
                              <>
                                <Link
                                  to={`/admin/edit-wholesaler/${p.userId}`}
                                  className="dropdown-item text-info cursor-pointer"
                                >
                                  <i className="fas fa-user-edit"></i> Edit
                                </Link>

                                <div className="dropdown-divider" />
                                <Link
                                  // to={`/admin/details/${p.userId}`}
                                  className="dropdown-item text-info"
                                >
                                  <i className="fas fa-key"></i> Change Password
                                </Link>
                                <div className="dropdown-divider" />
                                <Link
                                  to={`/admin/view-wholesalers/${p.userId}`}
                                  className="dropdown-item text-success cursor-pointer"
                                >
                                  <i className="fas fa-eye"></i> Review
                                </Link>
                                <div className="dropdown-divider" />
                                <p
                                  onClick={() => handleUnBlockUser(p.userId)}
                                  className="dropdown-item text-danger cursor-pointer"
                                >
                                  <i className="fas fa-user-lock"></i> Unblock
                                </p>
                              </>
                            ) : (
                              <Link
                                to={`/admin/view-wholesalers/${p.userId}`}
                                className="dropdown-item text-success cursor-pointer"
                              >
                                <i className="fas fa-eye"></i> Review
                              </Link>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : (
    <Spinner />
  );
};

export default BlockedWholesalersTab;
