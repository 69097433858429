import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import Swal from "sweetalert2";
import {
  makeFeatureProduct,
  makeProductBrand,
  makeProductKreesa,
  makeProductOrganic,
  makeProductFlash,
  makeProductTrending,
  removeBrandProduct,
  removeFeature,
  removeKreesaProduct,
  removeOrganicProduct,
  removeTrendingProduct,
  removeFlashProduct,
} from "../../../Features/Action/FeatureProductAction";
import { getProductsOfRetailer } from "../../../Features/Action/ProductAction";
import {
  approveAndRejectProductStatus,
  publishProductStatus,
} from "../../../Features/Action/ProductAction/UpdateStatusAction";
import Spinner from "../../../Spinner";
import { Pagination } from "@mantine/core";
import FlashModal from "../FlashModal";

const ApprovedProducts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [flashModal, setFlashModal] = useState([]);
  const [perPage] = useState(7);

  useEffect(() => {
    dispatch(getProductsOfRetailer());
  }, []);

  const BlockProduct = async (id) => {
    await dispatch(approveAndRejectProductStatus("Blocked" + "/" + id));
    // toast.info("Product has been Blocked");
    // history.push("/admin/product/view/retail");
    dispatch(getProductsOfRetailer());
  };
  const RejectProduct = async (id) => {
    await dispatch(approveAndRejectProductStatus("Rejected" + "/" + id));
    // history.push("/admin/product/view/retail");
    dispatch(getProductsOfRetailer());
  };

  const inspection = (obj, type) => {
    const data = {
      featureProduct: obj.featureProduct,
      organicBazar: obj.organicBazar,
      brandZone: obj.brandZone,
      kreesaProduct: obj.kreesaProduct,
      trendingNow: obj.trendingNow,
      flashSale: obj.flashSale,
    };

    const filteration = Object.keys(data).filter((i) => data[i] === true);

    if (filteration.length === 0) {
      return false;
    } else if (filteration.toString() === type) {
      return true;
    } else {
      return true;
    }
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = props.data?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(props.data?.length / perPage);

  return !props.loader ? (
    <div className="row">
      <div className="col-12 col-lg-8 d-flex w-100">
        <div
          className="card flex-fill overflow-auto"
          style={{ minHeight: "420px" }}
        >
          <table className="table table-hover my-0 ">
            <thead>
              <tr>
                <th>Sn.</th>
                <th>Products Name</th>
                {/* <th>Status</th> */}
                <th>Category</th>
                <th>Sell By</th>
                <th>Product Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Flash Product</th>
                <th>Publish Status</th>
                <th className="d-md-table-cell">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries?.map((p, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${p.image.id}/${p.image?.imageName}`}
                      width="48"
                      height="48"
                      loading="lazy"
                      className="rounded-circle mr-2"
                      alt={p.productName}
                    />
                    {p.productName}
                  </td>
                  {/* <td>
                        <span className="badge bg-info">Approved</span>
                      </td> */}
                  <td>
                    {p.primaryCategoryName}/{p.secondaryCategoryName}/
                    {p.ternaryCategoryName}
                  </td>
                  <td>{p.companyName}</td>
                  <td>
                    <span
                      className={`badge bg-${
                        p.trendingNow
                          ? "info"
                          : p.organicBazar
                          ? "success"
                          : p.kreesaProduct
                          ? "warning"
                          : p.brandZone
                          ? "danger"
                          : p.featureProduct
                          ? "primary"
                          : "secondary"
                      }`}
                    >
                      {p.trendingNow
                        ? "Trending"
                        : p.organicBazar
                        ? "Organic"
                        : p.kreesaProduct
                        ? "Kreesa"
                        : p.brandZone
                        ? "Brand"
                        : p.featureProduct
                        ? "Feature"
                        : "Recent"}
                    </span>
                  </td>

                  <td>{moment(p.createdAt).format("ll")}</td>
                  <td>{moment(p.updatedAt).format("ll")}</td>
                  <td>
                    {p?.flashSale ? (
                      <span className="badge bg-success">Flash</span>
                    ) : (
                      <span className="badge bg-secondary">Normal</span>
                    )}
                  </td>
                  <td>
                    {p.isPublish ? (
                      <span className="badge bg-success">Published</span>
                    ) : (
                      <span className="badge bg-danger">Unpublished</span>
                    )}
                  </td>
                  <td>
                    <div className="btn-group">
                      <button
                        type="button"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "#3b7ddd",
                        }}
                        className="btn-md btn btn-success dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        More
                      </button>
                      <div className="dropdown-menu text-center">
                        {localStorage.getItem("auth") === "ROLE_CEO" && (
                          <>
                            {inspection(p, "flashSale") ? null : (
                              <>
                                {p.flashSale === false ? (
                                  <div
                                    onClick={() =>
                                      setFlashModal([
                                        ...flashModal,
                                        "flash" + p.id,
                                      ])
                                    }
                                    className="text-info cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-bolt"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Make Flash
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      removeFlashProduct({
                                        id: p.flashSalesId,
                                        type: "retail",
                                      })
                                    }
                                    className="text-danger cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-bolt"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Remove From Flash
                                  </div>
                                )}
                                <div className="dropdown-divider" />
                              </>
                            )}

                            {inspection(p, "featureProduct") ? null : (
                              <>
                                {p.featureProduct === false ? (
                                  <div
                                    onClick={async () => {
                                      await dispatch(makeFeatureProduct(p.id));
                                      dispatch(getProductsOfRetailer());
                                    }}
                                    className="text-info cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Make Feature
                                  </div>
                                ) : (
                                  <div
                                    onClick={async () => {
                                      await dispatch(removeFeature(p.id));
                                      dispatch(getProductsOfRetailer());
                                    }}
                                    className="text-danger cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Remove From Feature
                                  </div>
                                )}
                                <div className="dropdown-divider" />
                              </>
                            )}
                            {inspection(p, "organicBazar") ? null : (
                              <>
                                {p.organicBazar === false ? (
                                  <div
                                    onClick={() => {
                                      dispatch(
                                        makeProductOrganic({
                                          id: p.id,
                                          type: "retail",
                                        })
                                      );
                                    }}
                                    className="text-info cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-leaf"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Make Organic
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      removeOrganicProduct({
                                        id: p.id,
                                        type: "retail",
                                      })
                                    }
                                    className="text-danger cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-leaf"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Remove From Organic
                                  </div>
                                )}
                                <div className="dropdown-divider" />
                              </>
                            )}
                            {inspection(p, "brandZone") ? null : (
                              <>
                                {p.brandZone === false ? (
                                  <div
                                    onClick={() => {
                                      dispatch(
                                        makeProductBrand({
                                          id: p.id,
                                          type: "retail",
                                        })
                                      );
                                    }}
                                    className="text-info cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-copyright"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Make Brand
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      removeBrandProduct({
                                        id: p.id,
                                        type: "retail",
                                      })
                                    }
                                    className="text-danger cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-copyright"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Remove From Brand
                                  </div>
                                )}
                                <div className="dropdown-divider" />
                              </>
                            )}
                            {inspection(p, "kreesaProduct") ? null : (
                              <>
                                {p.kreesaProduct === false ? (
                                  <div
                                    onClick={() => {
                                      dispatch(
                                        makeProductKreesa({
                                          id: p.id,
                                          type: "retail",
                                        })
                                      );
                                    }}
                                    className="text-info cursor-pointer"
                                  >
                                    <i
                                      className="fab fa-product-hunt"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Make Kreesa
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      removeKreesaProduct({
                                        id: p.id,
                                        type: "retail",
                                      })
                                    }
                                    className="text-danger cursor-pointer"
                                  >
                                    <i
                                      className="fab fa-product-hunt"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Remove From Kreesa
                                  </div>
                                )}
                                <div className="dropdown-divider" />
                              </>
                            )}
                            {inspection(p, "trendingNow") ? null : (
                              <>
                                {p.trendingNow === false ? (
                                  <div
                                    onClick={() => {
                                      dispatch(
                                        makeProductTrending({
                                          id: p.id,
                                          type: "retail",
                                        })
                                      );
                                    }}
                                    className="text-info cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-arrow-up"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Make Trending
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      removeTrendingProduct({
                                        id: p.id,
                                        type: "retail",
                                      })
                                    }
                                    className="text-danger cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-arrow-up"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Remove From Trending
                                  </div>
                                )}
                                <div className="dropdown-divider" />
                              </>
                            )}
                            <Link
                              to={{
                                pathname: `/admin/product/edit/${p.id}`,
                                state: "retail",
                              }}
                              className=" text-info"
                            >
                              <i className="fas fa-user-edit"></i> Edit
                            </Link>
                            <div className="dropdown-divider" />
                            <Link
                              to={`/admin/product/view/retail/${p.id}`}
                              className=" text-success"
                            >
                              <i className="fas fa-eye "></i> Review
                            </Link>
                            <div className="dropdown-divider" />
                            <div
                              onClick={() => BlockProduct(p.id)}
                              className=" text-danger cursor-pointer"
                            >
                              <i className="fa fa-ban" aria-hidden="true"></i>{" "}
                              Block
                            </div>
                          </>
                        )}
                        <div className="dropdown-divider" />

                        {!p.isPublish && (
                          <>
                            <div
                              onClick={() => {
                                if (p.groupId === null) {
                                  return toast.error(
                                    "please add group to product"
                                  );
                                } else {
                                  dispatch(
                                    publishProductStatus({
                                      id: p.id,
                                      type: "retail",
                                    })
                                  );
                                }
                              }}
                              className="text-primary cursor-pointer"
                            >
                              <i className="fa fa-check"></i> Publish
                            </div>
                            <div className="dropdown-divider" />
                          </>
                        )}
                        <div className="dropdown-divider" />
                        <p
                          onClick={() => {
                            RejectProduct(p.id);
                          }}
                          className=" text-danger cursor-pointer"
                        >
                          <i className="fa fa-ban" aria-hidden="true"></i>{" "}
                          Reject
                        </p>
                      </div>
                    </div>
                  </td>
                  <FlashModal
                    state={flashModal}
                    setState={setFlashModal}
                    data={p}
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {props.data?.length > 0 && (
        <Pagination
          onChange={setCurrentPage}
          page={currentEntries}
          total={totalPagesNum}
        />
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default ApprovedProducts;
