import { Redirect, Route } from "react-router";
import { useEffect } from "react";

const AdminRouting = ({ component: Component, access, ...rest }) => {
  const token = localStorage.getItem("token");
  const auth = localStorage.getItem("auth");

  //roles
  const adminRole = [
    "ROLE_SUPER_ADMIN",
    "ROLE_CEO",
    "ROLE_MANAGER",
    "ROLE_MARKETING_MANAGER",
    "ROLE_RECEPTIONIST",
    "ROLE_HR",
  ];

  const salerRole = ["ROLE_RETAILER", "ROLE_WHOLESALER"];

  const consumerRole = ["ROLE_USER"];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token === null) {
          return (
            <Redirect
              push
              to={{
                pathname: "/admin/login",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && salerRole.includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/home",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && consumerRole.includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && ["ROLE_DELIVERY_BOY"].includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/delivery",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && access.includes(auth)) {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default AdminRouting;
