import { createSlice } from '@reduxjs/toolkit'
import { getTernaryCategory,editTernaryCategory } from '../../Action/CategoryAction/CategoryAction';

const TernaryCategoryReducer = createSlice({
    name: 'ternaryCategories',
    initialState: {
        ternaryCategories: [],
        ternaryContainer: [],
        loader: false,
        errors: {},
        editTernaryCategories:[]
    },
    reducers: {
        filterTernaryCategory: (state, action) => {
            state.ternaryCategories = state.ternaryContainer.filter((u) =>
                u.ternaryCategoryName.toLowerCase().includes(action.payload)||
                u.ternaryCategoryName.toUpperCase().includes(action.payload)
            );
        }
    },
    extraReducers: {
        /* to get all Secondary view Categories */
        [getTernaryCategory.pending]: (state, action) => {
            state.loader = true;
        },
        [getTernaryCategory.fulfilled]: (state, action) => {
            state.loader = false;
            state.ternaryCategories = action.payload;
            state.ternaryContainer = action.payload;

        },
        [getTernaryCategory.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload
        },
         /* edit Primary Categories */
         [editTernaryCategory.pending]: (state, action) => {
            state.loader = true;
        },
        [editTernaryCategory.fulfilled]: (state, action) => {
            state.loader = false;
            state.editTernaryCategories = action.payload;
            state.primaryContainer = action.payload;
        },
        [editTernaryCategory.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload
        },
    }
});

// export
export const { filterTernaryCategory } = TernaryCategoryReducer.actions;
// export default
export default TernaryCategoryReducer.reducer;