import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { filterCustomOrder } from "../../Features/Reducer/CustomOrderReducer";
import AllCustomOrdersTab from "./AllCustomOrdersTab";
import RetailorCustomOrders from "./RetailorCustomOrders";
import WholesalerCustomOrders from "./WholesalerCustomOrders";

const ShowCustomOrderDetails = ({ type }) => {
  const inputRef = useRef("");

  const dispatch = useDispatch();
  const { customOrder, loader } = useSelector((s) => s.CustomOrder);

  const orders = customOrder.filter((p) => p.fileType === type.toUpperCase());

  const filtercustomOrder = () => {
    dispatch(filterCustomOrder(inputRef.current.value));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              {orders?.length >= 1 && (
                <h3 className="mt-2" style={{ float: "right" }}>
                  Total ({orders?.length}) Custom Orders
                </h3>
              )}
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Custom Order"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filtercustomOrder}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Tabs>
          <TabList>
            <Tab>All Custom Orders</Tab>
            <Tab>Retailor's Custom Orders</Tab>
            <Tab>Wholesaler's Custom Orders</Tab>
          </TabList>
          {/* All Custom Orders */}
          <TabPanel>
            <AllCustomOrdersTab type={type} />
          </TabPanel>

          {/* Retailor's Custom Orders */}
          <TabPanel>
            <RetailorCustomOrders
              data={customOrder.filter(
                (p) =>
                  p.orderType === "ROLE_RETAILER" &&
                  p.fileType === type.toUpperCase()
              )}
              loader={loader}
              type={type}
            />
          </TabPanel>

          {/* Wholesaler's Custom Orders */}
          <TabPanel>
            <WholesalerCustomOrders
              data={customOrder.filter(
                (p) =>
                  p.orderType === "ROLE_WHOLESALER" &&
                  p.fileType === type.toUpperCase()
              )}
              loader={loader}
              type={type}
            />
          </TabPanel>
        </Tabs>
      </div>

      {/* pagination  */}
    </div>
  );
};

export default ShowCustomOrderDetails;
