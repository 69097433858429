import UserFooter from ".";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import Header from "../../RetailerWholeSaler/Header";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import Nav from "../../RetailerWholeSaler/Nav";
import { Link } from "react-router-dom";
import shippingImage from "../../../assets/Images/shippingGuide.png";

const ShippingGuide = () => {
  document.title = "Shipping Guide";
  const data = [
    {
      id: 1,
      title: "Shipping Guide of",
      subTitle:
        "After completion of order shipping liability lies with kreesa.com or respective supplier in case of Drop-shipping orders.",
      content: [
        "Very 1st step is kreesa.com will approve the products to dispatch after going through the details of the product. Also, inspection is done on some materials as the case may be. In case of goods requiring inspection the goods will only be dispatched after due inspection from kreesa Inspector and after acquiring quality assured approval from him.",
        "Once goods to be dispatched are inspected or approved from kreesa’s management the goods are given to our logistics partner or given to transport agency.",
        "On delivery of such goods Qr code on receivers side will be scanned by the delivery boy and delivered when any amount due paid by the receiver.",
        "Also, kreesa gives you the option to confirm goods in front of delivery agent. If unsatisfied can be returned with the same delivery agent.",
        "Also customer can collect their goods from specific assigned kreesa’s warehouse for which no delivery charge will be applied.",
        "Delivery agent should also comply with all the guidelines of kreesa.com for delivering goods to the users.",
        "Any damage of goods in the phase of transportation is the liability of Delivery agent.",
        "Time of delivery may differ in some cases because of inevitable reasons like fire, flood, earthquake, landslide, huge traffic jams etc.",
        "In case of Imports shipping should be done as per the guidelines of Nepal Government.",
        "Delivery charge is applicable to all the orders.Delivery charge is calculated on the basis of size, dimensions, weight and other factors impacting the space.",
        "For insurance, Insurance charges are charged separately and is applicable only for orders from foreign countries.",
      ],
    },
  ];
  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      <div className="container mt-6 mb-2">
        {data.map((i) => (
          <div className="mt-6" key={i.id}>
            <h2 className="mb-3 font-weight-bold">
              {i.title} <Link to="/"> kreesa.com</Link>
            </h2>
            <ol>
              {i.content.map((j, index) => (
                <li
                  style={{
                    textAlign: "justify",
                    fontSize: "18px",
                    marginBottom: "15px",
                  }}
                  key={index}
                >
                  {j}
                </li>
              ))}
            </ol>
          </div>
        ))}
        <div className="row mt-4">
          <img src={shippingImage} alt={shippingImage} />
        </div>
        <div className="mt-4">
          <h3>Locations we ship to:</h3>
          <p
            style={{
              fontSize: "18px",
            }}
            className="mt-3"
          >
            Presently we are delivering good to Kathmandu Valley Suppliers,
            Wholesalers, Retailers and Users.
          </p>
          <p
            style={{
              fontSize: "18px",
            }}
          >
            Our mission is to reach 77 districts
          </p>
        </div>{" "}
        <div className="mt-6">
          <h3>Contact for Franchise or Branch:</h3>
          <p
            style={{
              fontSize: "18px",
            }}
          >
            <a href={`mailto:kreesasales@gmail.com`}>kreesasales@gmail.com</a>
            <br />
            kreesa.com
            <br />
            <a href="tel:+9779813007298"> +977 9813007298</a>
            <br />
          </p>
        </div>
      </div>

      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default ShippingGuide;
