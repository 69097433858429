import React, { useState, useEffect } from "react";
import "./UserSignup.css";
import { Link, useHistory, Redirect } from "react-router-dom";
import logo from "../../../assets/Images/kreesalogo.png";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useButtonLoader from "../../ButtonLoaderHook";
import { checkemail, checkPhone } from "./CheckExistValue";
import { useSelector, useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
  company_name: Yup.string().required("Company Name is required"),
  // company_address: Yup.string().required("Company Address is required"),
  companyTypeId: Yup.string().required("Select Company Type"),
  countryId: Yup.string().required("Select Country "),
  provinceId: Yup.string().required("Select Province "),
  districtId: Yup.string().required("Select District "),
  street_address: Yup.string().required("Street Required "),
  landmark: Yup.string().required("Landmark Required"),
  pin_code: Yup.number()
    .required("Pin code required")
    .typeError("Must be Number"),
  company_contact_no: Yup.number()
    .required("Contact Number is required")
    .typeError("Must be Number"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  role: Yup.string().required("Role is required"),
  hasInsured: Yup.string().required("HasInsuranced is required"),
  paymentType: Yup.string().required("Commission Type is required"),
  hasRegistered: Yup.boolean(),
  pan_no: Yup.string().when("hasRegistered", (hasRegistered) => {
    if (hasRegistered) {
      return Yup.string().required("PAN Number is required");
    }
  }),
  image: Yup.mixed().when("hasRegistered", (hasRegistered) => {
    if (hasRegistered) {
      return Yup.mixed().required("image is required");
      // .test(
      //   "fileType",
      //   "Only accept Jpg/Jpeg/Png file type",
      //   (file) =>
      //     file && ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
      // )
    }
  }),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required ")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  accept: Yup.bool().oneOf([true], "Terms and Condition must be accepted"),
});

const UserSignup = () => {
  document.title = "User Signup";
  const [state, setstate] = useState([]);
  const [visible, setVisible] = useState(true);
  const [logofile, setLogoFile] = useState();

  const { countries } = useSelector((s) => s.AddressReducer);
  const { proviences } = useSelector((s) => s.AddressReducer);
  const { districts } = useSelector((s) => s.AddressReducer);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let history = useHistory();
  const dispatch = useDispatch();

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Register Now",
    "Loading..."
  );

  const getAllCompanyType = async () => {
    try {
      const res = await AxiosConfig.get("/kreesa/company-type/all");
      setstate(res.data);
    } catch (error) {
      console.log(error + "Something Went Wrong ! ");
    }
  };
  useEffect(() => {
    getAllCompanyType();
  }, []);

  const RegisterUserHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();

      if (values.image) {
        data.append("registration_document", values.image);
      }
      if (logofile) {
        data.append("company_logo", logofile);
      }
      data.append("company_name", values.company_name);
      data.append("company_type", values.companyTypeId);
      data.append("company_email", values.email);
      data.append("email", values.email);
      data.append("company_address", values.company_address);
      data.append("hasInsured", values.hasInsured);
      data.append("hasRegistered", values.hasRegistered);
      data.append("company_contact_no", values.company_contact_no);
      data.append("role", values.role);
      data.append("password", values.password);
      data.append("pan_no", values.pan_no);
      data.append("companyTypeId", values.companyTypeId);
      data.append("countryId", values.countryId);
      data.append("provinceId", values.provinceId);
      data.append("districtId", values.districtId);
      data.append("street_address", values.street_address);
      data.append("landmark", values.landmark);
      data.append("pin_code", values.pin_code);
      data.append("paymentType", values.paymentType);

      const res = await AxiosConfig.post(
        "/kreesa/user-login-registration/register",
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (res && res.status === 200) {
        toast.success("New user added succesfully !");
        history.push("/activation-link");
        localStorage.setItem("activation", values.email);
        setButtonLoader(false);
      }
    } catch (err) {
      console.log(err + "Something Went Wrong ! ");
      toast.error("Something Went Wrong !");
      setButtonLoader(false);
    }
  };

  const [country, setCountry] = useState([]);
  const [district, setDistrict] = useState([]);
  const [province, setProvince] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  useEffect(() => {
    AxiosConfig.get("/kreesa/check/get-country").then((res) =>
      setCountry(res.data)
    );
  }, []);

  // to check authentication user or not
  let token = localStorage.getItem("token");
  if (token) {
    return <Redirect exact to="/" />;
  }

  return (
    <div className="mb-5">
      <section className="rw-signup">
        {/* <!-- <img src="images/signup-bg.jpg" alt=""> --> */}
        <div className="container" id="rw-container">
          <div className="signup-content">
            <Formik
              initialValues={{
                company_name: "",
                // company_type: "",
                email: "",
                company_contact_no: "",
                // company_address: "",
                countryId: "",
                provinceId: "",
                districtId: "",
                street_address: "",
                landmark: "",
                pin_code: "",
                password: "",
                confirmPassword: "",
                role: "",
                pan_no: "",
                isLoading: false,
                hasInsured: "",
                hasRegistered: false,
                accept: false,
                companyTypeId: state.id,
                countryId: countries.id,
                provinceId: proviences.id,
                districtId: districts.id,
                image: "",
                paymentType: "",
              }}
              onSubmit={(values) => {
                RegisterUserHandler(values);
              }}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
                handleBlur,
                setFieldValue,
              }) => (
                <Form
                  id="rw-signup-form"
                  className="rw-signup-form"
                  onSubmit={handleSubmit}
                >
                  <h2 className="text-center"> Business Registration </h2>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-evenly" }}
                  >
                    <Link to="/">
                      <img
                        src={logo}
                        className="rrounded"
                        alt="..."
                        width="150px"
                      />
                    </Link>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="company_name"> Company name </label>
                      <input
                        type="text"
                        className="form-input "
                        value={values.company_name}
                        onChange={handleChange}
                        name="company_name"
                        placeholder="Company Name"
                      />
                      {errors.company_name && touched.company_name ? (
                        <div style={{ color: "red" }}>
                          {errors.company_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email"> Company Email </label>
                      <input
                        type="email"
                        className="form-input "
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onKeyUp={checkemail}
                      />
                      {errors.email && touched.email ? (
                        <div style={{ color: "red" }}> {errors.email} </div>
                      ) : null}
                      <span
                        id="email-errors"
                        className="error"
                        htmlFor="email"
                      ></span>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="company_address"> Company Address </label>
                    <input
                      type="text"
                      className="form-input "
                      name="company_address"
                      value={values.company_address}
                      onChange={handleChange}
                      placeholder="Company Address"
                    />
                    {errors.company_address && touched.company_address ? (
                      <div style={{ color: "red" }}>
                        {errors.company_address}
                      </div>
                    ) : null}
                  </div> */}
                  <div className="form-row" id="two-columns">
                    <div className="form-group">
                      <label htmlFor="radio"> Country </label>
                      <div className="select-list">
                        <select
                          id="country"
                          name="countryId"
                          //   onChange={(e) => {
                          //     const scat = e.target.value;
                          //     setcompanyTypeId(scat)
                          // }}
                          onChange={(e) => {
                            AxiosConfig.get(
                              `/kreesa/check/get-province/${e.target.value}`
                            ).then((res) => {
                              setProvince(res.data);
                              setDistrict([]);
                            });
                            setFieldValue("countryId", e.target.value);
                          }}
                          // values={values.select_country}
                        >
                          <option> Select Country </option>
                          {country?.map((i) => (
                            <option key={i.id} value={i.id}>
                              {i.country_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.countryId && touched.countryId ? (
                        <div style={{ color: "red" }}> {errors.countryId} </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="radio"> Province </label>
                      <div className="select-list">
                        <select
                          id="country"
                          name="provinceId"
                          onChange={(e) => {
                            AxiosConfig.get(
                              `/kreesa/check/get-district/${e.target.value}`
                            ).then((res) => setDistrict(res.data));
                            setFieldValue("provinceId", e.target.value);
                          }}
                          // values={values.province}
                        >
                          <option> Select Province </option>
                          {province?.map((i) => (
                            <option key={i.id} value={i.id}>
                              {i.provience_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.provinceId && touched.provinceId ? (
                        <div style={{ color: "red" }}>
                          {" "}
                          {errors.provinceId}{" "}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="radio"> District </label>
                      <div className="select-list">
                        <select
                          id="country"
                          name="districtId"
                          onChange={(e) => {
                            AxiosConfig.get(
                              `/kreesa/check/get-district/${e.target.value}`
                            ).then((res) => setDistrict(res.data));
                            setFieldValue("districtId", e.target.value);
                          }}
                          // onChange={handleChange}
                          // values={values.select_country}
                        >
                          <option> Select District </option>
                          {district?.map((i) => (
                            <option key={i.id} value={i.id}>
                              {i.district_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.districtId && touched.districtId ? (
                        <div style={{ color: "red" }}>
                          {" "}
                          {errors.districtId}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="street_address"> Street Address </label>
                    <input
                      type="text"
                      className="form-input "
                      name="street_address"
                      value={values.street_address}
                      onChange={handleChange}
                      placeholder="Street"
                      id="street"
                    />
                    {errors.street_address && touched.street_address ? (
                      <div style={{ color: "red" }}>
                        {errors.street_address}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-row" id="two-columns">
                    <div className="form-group">
                      <label htmlFor="landmark"> Landmark </label>
                      <input
                        type="text"
                        className="form-input"
                        name="landmark"
                        value={values.landmark}
                        onChange={handleChange}
                        placeholder="Landmark"
                        id="landmark"
                      />
                      {errors.landmark && touched.landmark ? (
                        <div style={{ color: "red" }}>{errors.landmark}</div>
                      ) : null}
                    </div>{" "}
                    <div className="form-group">
                      <label htmlFor="pin_code"> Pin Code </label>
                      <input
                        type="text"
                        className="form-input "
                        name="pin_code"
                        value={values.pin_code}
                        onChange={handleChange}
                        placeholder="Pin code"
                        id="phone"
                        onKeyUp={checkPhone}
                      />
                      {errors.pin_code && touched.pin_code ? (
                        <div style={{ color: "red" }}>{errors.pin_code}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-row" id="two-columns">
                    <div className="form-group">
                      <label htmlFor="company_contact">
                        {" "}
                        Company Contact Number
                      </label>
                      <input
                        type="text"
                        className="form-input "
                        name="company_contact_no"
                        value={values.company_contact_no}
                        onChange={handleChange}
                        placeholder="Phone"
                        id="phone"
                        onKeyUp={checkPhone}
                      />
                      {errors.company_contact_no &&
                      touched.company_contact_no ? (
                        <div style={{ color: "red" }}>
                          {errors.company_contact_no}
                        </div>
                      ) : null}
                      <span
                        id="phone-verify-error"
                        className="error"
                        htmlFor="phone"
                      ></span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="company"> Company Type </label>
                      <div className="select-list">
                        <select
                          id="company"
                          name="companyTypeId"
                          onChange={handleChange}
                          values={values.companyTypeId}
                        >
                          <option defaultValue="">
                            Select Your Company Type...
                          </option>
                          {state?.map((p, i) => (
                            <option key={i} value={p.id}>
                              {p.typeName}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.companyTypeId && touched.companyTypeId ? (
                        <div style={{ color: "red" }}>
                          {errors.companyTypeId}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row" id="two-columns">
                    <div className="form-group ">
                      <label htmlFor="company_logo"> Company Logo </label>
                      <input
                        type="file"
                        className="form-input "
                        name="company_logo"
                        accept="image/png,image/jpg,image/jpeg"
                        onChange={(e) => setLogoFile(e.target.files[0])}
                        id="company_logo"
                      />
                      <small>Accepted Formats: png, jpg & jpeg</small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="radio"> Register As </label>
                      <div className="select-list">
                        <select
                          id="country"
                          name="role"
                          //   onChange={(e) => {
                          //     const scat = e.target.value;
                          //     setcompanyTypeId(scat)
                          // }}
                          onChange={handleChange}
                          values={values.companyTypeId}
                        >
                          <option> Select Saler Type... </option>
                          {[
                            {
                              id: 1,
                              name: "WholeSaler",
                              value: "ROLE_WHOLESALER",
                            },
                            {
                              id: 2,
                              name: "Retailer",
                              value: "ROLE_RETAILER",
                            },
                          ].map((i) => (
                            <option key={i.id} value={i.value}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.role && touched.role ? (
                        <div style={{ color: "red" }}> {errors.role} </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row" id="two-columns">
                    <div className="form-group">
                      <label htmlFor="password"> Password </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-input "
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        placeholder="Password"
                        id="pas"
                      />
                      <span
                        className="input-group-text cursor-pointer mt-2"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          width: 40,
                        }}
                      >
                        <i
                          className={`${
                            showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                          }`}
                          id="togglePassword"
                        ></i>
                      </span>
                      {errors.password && touched.password ? (
                        <div style={{ color: "red" }}> {errors.password} </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password"> Confirm Password </label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-input "
                        name="confirmPassword"
                        onChange={handleChange}
                        placeholder="Confirm Password"
                      />
                      <span
                        className="input-group-text cursor-pointer mt-2"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        style={{
                          width: 40,
                        }}
                      >
                        <i
                          className={`${
                            showConfirmPassword
                              ? "fa fa-eye-slash"
                              : "fa fa-eye"
                          }`}
                          id="togglePassword"
                        ></i>
                      </span>
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="radio"> commission Type </label>
                      <div className="select-list">
                        <select
                          id="country"
                          name="paymentType"
                          onChange={handleChange}
                          value={values.paymentType}
                        >
                          <option> Select commission Type... </option>
                          {[
                            {
                              id: 1,
                              name: "Fix Amount",
                              value: "FIX_AMOUNT",
                            },
                            {
                              id: 2,
                              name: "Percentage",
                              value: "PERCENTAGE",
                            },
                          ].map((i) => (
                            <option key={i.id} value={i.value}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.paymentType && touched.paymentType ? (
                        <div style={{ color: "red" }}>
                          {" "}
                          {errors.paymentType}{" "}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row" id="radio-colums">
                    <div className="form-group">
                      <label htmlFor="insurance">
                        Does Your Company have Insurance ?
                      </label>
                      <div className="form-flex" id="signup-insurance">
                        <div>
                          <label htmlFor="yes">
                            <input
                              type="radio"
                              name="hasInsured"
                              onChange={handleChange}
                              value="Yes"
                              id="yes"
                              style={{
                                width: 20,
                                height: 20,
                              }}
                            />{" "}
                            <span className="pl-2">Yes</span>
                          </label>
                        </div>
                        <div>
                          <label htmlFor="no">
                            <input
                              type="radio"
                              name="hasInsured"
                              onChange={handleChange}
                              value="No"
                              id="no"
                              style={{
                                width: 20,
                                height: 20,
                              }}
                            />{" "}
                            <span className="pl-2">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.hasInsured && touched.hasInsured ? (
                      <div style={{ color: "red" }}> {errors.hasInsured} </div>
                    ) : null}
                  </div>
                  <div className="form-row">
                    <div className="form-group terms-condition">
                      <input
                        type="checkbox"
                        id="reg-signup"
                        name="hasRegistered"
                        value={values.hasRegistered}
                        onChange={handleChange}
                        // onClick={Handlechange}
                        style={{
                          width: 20,
                          height: 20,
                          marginTop: -2,
                        }}
                        onClick={() => setVisible(!visible)}
                      />
                      <label htmlFor="reg-signup">
                        Is Your Company Registered ?
                      </label>
                    </div>
                  </div>
                  <div id="text" className="reg-details">
                    If Registered, Please Upload Registration Document
                    <div className="form-input">
                      <div className="row">
                        <div className="col" id="reg-document">
                          <input
                            disabled={visible}
                            type="file"
                            className="form-control"
                            name="image"
                            accept="image/png,image/jpg,image/jpeg"
                            onChange={(e) =>
                              setFieldValue("image", e.target.files[0])
                            }
                            onBlur={handleBlur}
                          />
                          <small>Accepted Formats: png, jpg & jpeg</small>
                          {errors.image && touched.image ? (
                            <div style={{ color: "red" }}> {errors.image} </div>
                          ) : null}
                        </div>
                        <div className="col">
                          <input
                            disabled={visible}
                            type="text"
                            className="form-control"
                            name="pan_no"
                            placeholder="Pan Number/VAT No"
                            value={values.pan_no}
                            onChange={handleChange}
                          />
                          {errors.pan_no && touched.pan_no ? (
                            <div style={{ color: "red" }}>{errors.pan_no}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group terms-condition">
                      <input
                        id="terms"
                        type="checkbox"
                        name="accept"
                        onChange={handleChange}
                        style={{
                          width: 20,
                          height: 20,
                          marginTop: -2,
                        }}
                      />
                      <label htmlFor="terms">
                        I accept the
                        <Link to="/termsandconditions" target="_blank">
                          {" "}
                          Terms and Conditions Privacy Policy
                        </Link>
                      </label>
                    </div>
                    <div className="terms-condition-validation">
                      {errors.accept && touched.accept ? (
                        <div style={{ color: "red" }}> {errors.accept} </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group register-group">
                    <button
                      type="submit"
                      // id="sends"
                      className="btn btn-success btn-lg btn-block sends"
                      ref={buttonLoader}
                      onClick={handleSubmit}
                      disabled={!isValid}
                    >
                      Register Now
                    </button>
                  </div>
                  <div className="text-center font-weight-bold">
                    Already have an account ?
                    <Link to="/login"> Login Here </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserSignup;
