import { createSlice } from "@reduxjs/toolkit";
import {
  getAllWholesalers,
  getAllQuotations,
  getSingleQuotations,
  getAllRetailers,
  DeclineQuotations,
  getAllAssignedDelivery,
  getAllAssignedDeliveryQuotations,
  getAllApprovedQuoteByBuyer,
  ChangePaymentStatusQuotation,
  generateQrCode,
} from "../../Action/QuotationAction";

const AdminQuotations = createSlice({
  name: "Quotations",
  initialState: {
    userquot: [],
    qrCode: {},
    userquotContainer: [],
    retailquot: [],
    retailquotContainer: [],
    wholesalequot: [],
    wholesalequotContainer: [],
    assignedDelivery: [],
    assignedDeliveryContainer: [],
    assignedDeliveryAdmin: [],
    assignedDeliveryQuotations: [],
    singlequotation: [],
    wholesalers: [],
    retailers: [],
    approvedQuoteByBuyer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterAssignedQuote: (state, action) => {
      state.assignedDelivery = state.assignedDeliveryContainer.filter(
        (u) =>
          u.orderNo.toLowerCase().includes(action.payload) ||
          u.orderNo.toUpperCase().includes(action.payload)
      );
    },
    filterAssignedQuoteForAdmin: (state, action) => {
      state.assignedDeliveryQuotations = state.assignedDeliveryAdmin.filter(
        (u) =>
          u.product_name.toLowerCase().includes(action.payload) ||
          u.product_name.toUpperCase().includes(action.payload)
      );
    },
    filterUserQuotations: (state, action) => {
      state.userquot = state.userquotContainer.filter(
        (u) =>
          u.product_name.toLowerCase().includes(action.payload) ||
          u.product_name.toUpperCase().includes(action.payload)
      );
    },
    filterRetailQuotations: (state, action) => {
      state.retailquot = state.retailquotContainer.filter(
        (u) =>
          u.product_name.toLowerCase().includes(action.payload) ||
          u.product_name.toUpperCase().includes(action.payload)
      );
    },
    filterWholesaleQuotations: (state, action) => {
      state.wholesalequot = state.wholesalequotContainer.filter(
        (u) =>
          u.product_name.toLowerCase().includes(action.payload) ||
          u.product_name.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all quotations */
    [getAllQuotations.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllQuotations.fulfilled]: (state, action) => {
      state.loader = false;
      let array1 = [];
      let array2 = [];
      let array3 = [];
      const quot = action.payload;
      quot.forEach((element) => {
        if (element.quotation_by === "ROLE_RETAILER") {
          array1.push(element);
        } else if (element.quotation_by === "ROLE_WHOLESALER") {
          array2.push(element);
        } else if (element.quotation_by === "ROLE_USER") {
          array3.push(element);
        }
      });
      state.retailquot = array1;
      state.wholesalequot = array2;
      state.userquot = array3;
      // filter
      state.userquotContainer = array3;
      state.retailquotContainer = array1;
      state.wholesalequotContainer = array2;
    },
    [getAllQuotations.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* to get single quotation */
    [getSingleQuotations.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleQuotations.fulfilled]: (state, action) => {
      state.loader = false;
      state.singlequotation = action.payload;
    },
    [getSingleQuotations.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* to get all wholesalers */
    [getAllWholesalers.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllWholesalers.fulfilled]: (state, action) => {
      state.loader = false;
      state.wholesalers = action.payload;
    },
    [getAllWholesalers.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get all retailers
    [getAllRetailers.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailers.fulfilled]: (state, action) => {
      state.loader = false;
      state.retailers = action.payload;
    },
    [getAllRetailers.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [DeclineQuotations.pending]: (state, action) => {
      state.loader = true;
    },
    [DeclineQuotations.fulfilled]: (state, action) => {
      state.loader = false;
      state.retailers = action.payload;
    },
    [DeclineQuotations.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [ChangePaymentStatusQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [ChangePaymentStatusQuotation.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [ChangePaymentStatusQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [generateQrCode.pending]: (state, action) => {
      state.loader = true;
    },
    [generateQrCode.fulfilled]: (state, action) => {
      state.loader = false;
      state.qrCode = action.payload;
    },
    [generateQrCode.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getAllAssignedDelivery.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllAssignedDelivery.fulfilled]: (state, action) => {
      state.loader = false;
      state.assignedDelivery = action.payload;
      state.assignedDeliveryContainer = action.payload;
    },
    [getAllAssignedDelivery.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getAllAssignedDeliveryQuotations.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllAssignedDeliveryQuotations.fulfilled]: (state, action) => {
      state.loader = false;
      state.assignedDeliveryQuotations = action.payload;
      state.assignedDeliveryAdmin = action.payload;
    },
    [getAllAssignedDeliveryQuotations.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [getAllApprovedQuoteByBuyer.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllApprovedQuoteByBuyer.fulfilled]: (state, action) => {
      state.loader = false;
      state.approvedQuoteByBuyer = action.payload;
    },
    [getAllApprovedQuoteByBuyer.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const {
  filterUserQuotations,
  filterWholesaleQuotations,
  filterRetailQuotations,
  filterAssignedQuote,
  filterAssignedQuoteForAdmin,
} = AdminQuotations.actions;
// export default
export default AdminQuotations.reducer;
