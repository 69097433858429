import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to Block User */
export const blockUser = createAsyncThunk("blockUser", async (id) => {
  // blockUser/pending
  // blockUser/fulfilled
  // blockUser/rejected
  const response = await AxiosConfig.get(
    `/kreesa/admin/block-user/${id}`,
    config
  );
  return response.data;
});

/* to unblock User */
export const unBlockUser = createAsyncThunk("unBlockUser", async (id) => {
  // unBlockUser/pending
  // unBlockUser/fulfilled
  // unBlockUser/rejected
  const response = await AxiosConfig.get(
    `/kreesa/admin/unblock-user/${id}`,
    config
  );
  return response.data;
});
/* to unblock User */
export const approveUser = createAsyncThunk("approveUser", async (id) => {
  // approveUser/pending
  // approveUser/fulfilled
  // approveUser/rejected
  const response = await AxiosConfig.get(
    `/kreesa/admin/activate-user/${id}`,
    config
  );
  return response.data;
});

/* to unlock User */
export const unlockUser = createAsyncThunk("unlockUser", async (id) => {
  // unlockUser/pending
  // unlockUser/fulfilled
  // unlockUser/rejected
  const response = await AxiosConfig.get(
    `/kreesa/admin/unlocked-user/${id}`,
    config
  );
  return response.data;
});

/* to get all vacancy */
export const getallVacancies = createAsyncThunk("getallVacancies", async () => {
  const { data } = await AxiosConfig.get(`/kreesa/admin/vacancy`, config);
  return data;
});
/* to get all vacancy */
export const getVacancy = createAsyncThunk("getVacancy", async (id) => {
  const { data } = await AxiosConfig.get(`/kreesa/admin/vacancy/${id}`, config);
  return data;
});

// to get vacancy application
export const getAllApplications = createAsyncThunk(
  "getAllApplications",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/vacancy/application`,
      config
    );
    return data;
  }
);

// to delete application
export const applicationStatus = createAsyncThunk(
  "applicationStatus",
  async (values, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/admin/vacancy/application/status/update`,
      values
    );
    if (response && response.status === 200) {
      values.toast.success(
        `Vacancy ${
          values?.status[0]?.toUpperCase() +
          values?.status?.substring(1).toLowerCase()
        }`
      );
      dispatch(getAllApplications());
    }
    return response.data;
  }
);

/* to add vacancy */
export const addVacancy = createAsyncThunk(
  "admin/addVacancy",
  async (values, { dispatch }) => {
    const response = await AxiosConfig.post(`/kreesa/admin/vacancy`, values);
    if (response && response.status === 200) {
      values.toast.success("Vacancy Added Successfully");
      dispatch(getallVacancies());
    }
    return response.data;
  }
);

/* to update vacancy */
export const updateVacancy = createAsyncThunk(
  "updateVacancy",
  async (values, { dispatch }) => {
    const response = await AxiosConfig.post(
      `/kreesa/admin/vacancy/update/${values.id}`,
      values
    );

    if (response && response.status === 200) {
      values.toast.success("Vacancy updated Successfully");
      dispatch(getallVacancies());
    }
    return response.data;
  }
);

/* to delete vacancy */
export const deleteVacancy = createAsyncThunk(
  "deleteVacancy",
  async (values, { dispatch }) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/vacancy/delete/${values.id}`
    );

    if (response && response.status === 200) {
      values.toast.error("Vacancy has been deleted !!");
      dispatch(getallVacancies());
    }
    return response.data;
  }
);
// to get all faqs for admin
export const getAllFaqsForAdmin = createAsyncThunk(
  "getAllFaqsForAdmin",
  async () => {
    const { data } = await AxiosConfig.get("/kreesa/admin/faq", config);
    return data;
  }
);
// to get single faq for admin
export const getSingleFaqsForAdmin = createAsyncThunk(
  "getSingleFaqsForAdmin",
  async (id) => {
    const { data } = await AxiosConfig.get(`/kreesa/admin/faq/${id}`, config);
    return data;
  }
);

// to get all faqs for admin
export const deleteFaq = createAsyncThunk(
  "deleteFaq",
  async (id, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/faq/delete/${id}`,
      config
    );
    dispatch(getAllFaqsForAdmin());
    return data;
  }
);
//

// to get all faqs for admin
export const getUserDetail = createAsyncThunk("getUserDetail", async (id) => {
  const { data } = await AxiosConfig.get(
    `/kreesa/admin/get-user-by-user-id/${id}`,
    config
  );
  return data;
});

// to get all blogs for admin
export const getAllBlogForAdmin = createAsyncThunk(
  "admin/getAllBlogForAdmin",
  async () => {
    const { data } = await AxiosConfig.get(`/kreesa/admin/blog/view`, config);
    return data;
  }
);
// to get all blogs for admin
export const getAllComplimentAdmin = createAsyncThunk(
  "admin/getAllComplimentAdmin",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/get-all-compliment`,
      config
    );
    return data;
  }
);

// to get all blogs for admin
export const getSingleNewsBlogNotice = createAsyncThunk(
  "admin/getSingleNewsBlogNotice",
  async (id) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/blog/view/${id}`,
      config
    );
    return data;
  }
);

// to delete blog
export const deleteBlog = createAsyncThunk(
  "admin/deleteBlog",
  async (id, { dispatch }) => {
    const { data } = await AxiosConfig.post(`/kreesa/admin/blog/delete/${id}`);
    dispatch(getAllBlogForAdmin());
    return data;
  }
);

// to get all Admistrator
export const getAllAdmistrator = createAsyncThunk(
  "admin/getAllAdmistrator",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/view-all-admin`,
      config
    );
    return data;
  }
);

// to get all Retailer
export const getAllRetailer = createAsyncThunk(
  "admin/retailerInadmin",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/get-all-retailer`,
      config
    );
    return data;
  }
);

// to get all WholeSeller
export const getAllWholesaler = createAsyncThunk(
  "admin/wholesalerInadmin",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/get-all-wholesaler`,
      config
    );
    return data;
  }
);
// to get all Customer
export const getAllCustomer = createAsyncThunk(
  "admin/getAllCustomerInadmin",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/get-all-customer`,
      config
    );
    return data;
  }
);

// to get admin analytics
export const dashboardAnalytics = createAsyncThunk(
  "admin/dashboardAnalytics",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/get-data-for-dashboard`,
      config
    );
    return data;
  }
);

// to get excel file name
export const exportAdminInExcel = createAsyncThunk(
  "admin/exportAdminInExcel",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/report/generate-admin-report`,
      config
    );
    return data;
  }
);

// to get excel file name for customer
export const exportCustomerInExcel = createAsyncThunk(
  "admin/exportCustomerInExcel",
  async () => {
    const { data } = await AxiosConfig.get(
      `kreesa/admin/report/generate-user-report`,
      config
    );
    return data;
  }
);

// to get excel file name for retailor/wholesaler
export const exportSellerInExcel = createAsyncThunk(
  "admin/exportSellerInExcel",
  async (role) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/report/generate-seller-report`,
      role,
      config
    );
    return data;
  }
);

// to get excel file name for retailor/wholesaler
export const exportProductInExcel = createAsyncThunk(
  "admin/exportProductInExcel",
  async (payload) => {
    const type = payload.type;
    delete payload.type;
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/report/generate-${type}-report`,
      payload,
      config
    );
    return data;
  }
);