import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../../Components/Admin/";
import Profile from "../../Components/Admin/Profile";
import Settings from "../../Components/Admin/Settings";
import AdminLogin from "../../Components/Admin/AdminLogin";
import ForgotPassword from "../../Components/Admin/ForgotPassword";
import OtpVerification from "../../Components/Admin/OtpVerification";
import ResetPassword from "../../Components/Admin/ResetPassword";
import AddAdmin from "../../Components/Admin/AddAdmin";
import PrimaryCategory from "../../Components/Admin/Categories/PrimaryCategory";
import EditCategoryForm from "../../Components/Admin/Categories/PrimaryCategoryForm/EditCategoryForm";
import EditSecondaryCategoryForm from "../../Components/Admin/Categories/SecondaryCategoryFrom/EditCategoryForm";
import EditTernaryCategoryForm from "../../Components/Admin/Categories/TernaryCategoryForm/EditCategoryForm";
import PrimaryCategoryForm from "../../Components/Admin/Categories/PrimaryCategoryForm";
import SecondaryCategory from "../../Components/Admin/Categories/SecondaryCategory";
import SecondaryCategoryFrom from "../../Components/Admin/Categories/SecondaryCategoryFrom";
import TernaryCategory from "../../Components/Admin/Categories/TernaryCategory";
import TernaryCategoryFrom from "../../Components/Admin/Categories/TernaryCategoryForm";
import Administrator from "../../Components/Admin/UserManagement/Administrator";
import EditAdministrator from "../../Components/Admin/UserManagement/Administrator/EditAdministrator";
import Customers from "../../Components/Admin/UserManagement/Customers";
import Retailors from "../../Components/Admin/UserManagement/Retailors";
import Wholesalers from "../../Components/Admin/UserManagement/Wholesalers";
import ShowGroupProduct from "../../Components/Admin/GroupProduct/ShowGroupProduct";
import AddGroupProduct from "../../Components/Admin/GroupProduct/AddGroupProduct";
import RetailerViewProduct from "../../Components/Admin/ViewProduct/Retailer";
import WholesalerReviewById from "../../Components/Admin/ViewProduct/Wholesaler/ReviewById";
import RetailReviewById from "../../Components/Admin/ViewProduct/Retailer/ReviewById";
import WholesalerViewProduct from "../../Components/Admin/ViewProduct/Wholesaler";
import EditProductDetails from "../../Components/Admin/ViewProduct/Wholesaler/EditProductDetails";
import ShowCustomOrder from "../../Components/Admin/CustomOrder/ShowCustomOrder";
import WholesalerShowOrders from "../../Components/Admin/Orders/Wholesaler/ShowOrder";
import RetailerShowOrders from "../../Components/Admin/Orders/Retailer/ShowOrder";
import SingleOrderDetail from "../../Components/Admin/Orders/SingleOrderDetail";
import EditGroupProduct from "../../Components/Admin/GroupProduct/EditGroupProduct";
import PushNotification from "../../Components/Admin/PushNotifications";
import AllPushNotification from "../../Components/Admin/PushNotifications/Notifications";
import ReviewDetails from "../../Components/Admin/UserManagement/Administrator/ReviewDetails";
import ReviewRetailorDetail from "../../Components/Admin/UserManagement/Retailors/ReviewRetailorDetail";
import ReviewWholeSalerDetail from "../../Components/Admin/UserManagement/Wholesalers/ReviewWholeSalerDetail";
import ReviewCustomerDetails from "../../Components/Admin/UserManagement/Customers/ReviewCustomerDetails";
import EditRetailor from "../../Components/Admin/UserManagement/Retailors/EditRetailor";
import EditWholeSaler from "../../Components/Admin/UserManagement/Wholesalers/EditWholeSaler";
import EditCutomers from "../../Components/Admin/UserManagement/Customers/EditCustomers";
import AddRWUser from "../../Components/Admin/UserManagement/AddRWUser";
import AdminRouting from "../PrivateRoute/adminRouting";
import AddType from "../../Components/Admin/CompanyType/AddType";
import EditType from "../../Components/Admin/CompanyType/EditType";
import CompanyType from "../../Components/Admin/CompanyType";
import LoginRouting from "../PrivateRoute/loginRouting";
import AddSellerBanner from "../../Components/Admin/Banner/SellerBanner/AddSellerBanner";
import AddCustomerBanner from "../../Components/Admin/Banner/CustomerBanner/AddCustomerBanner";
import SellerBanner from "../../Components/Admin/Banner/SellerBanner/ShowSellerBanner";
import CustomerBanner from "../../Components/Admin/Banner/CustomerBanner/ShowCustomerBanner";
import EditSallerBanner from "../../Components/Admin/Banner/SellerBanner/EditSallerBanner";
import EditCustomerBanner from "../../Components/Admin/Banner/CustomerBanner/EditCustomerBanner";
import SingleCustomOrder from "../../Components/Admin/CustomOrder/SingleCustomOrder";
import Enquiry from "../../Components/Admin/Enquiry";
import Message from "../../Components/Admin/Message";
import UserQuotations from "../../Components/Admin/Quotations/User";
import WholesalerQuotations from "../../Components/Admin/Quotations/Wholesaler";
import RetailerQuotations from "../../Components/Admin/Quotations/Retailer";
import SingleQuotations from "../../Components/Admin/Quotations/SingleQuotations";
import Country from "../../Components/Admin/Addressing/Country/ShowCountry";
import District from "../../Components/Admin/Addressing/District/ShowDistrict";
import Province from "../../Components/Admin/Addressing/Province/ShowProvince";
import AddCountry from "../../Components/Admin/Addressing/Country/AddCountry";
import EditCountry from "../../Components/Admin/Addressing/Country/EditCountry";
import AddDistrict from "../../Components/Admin/Addressing/District/AddDistrict";
import AddProvince from "../../Components/Admin/Addressing/Province/AddProvince";
import EditProvience from "../../Components/Admin/Addressing/Province/EditProvience";
import EditDistrict from "../../Components/Admin/Addressing/District/EditDistrict";
import AdminEditProduct from "../../Components/Admin/ViewProduct/Editproduct";
import QuotationAssign from "../../Components/Admin/Quotations/QuotationAssign";
import Vacancies from "../../Components/Admin/Vacancies/ShowVacancy";
import AddEditVacancy from "../../Components/Admin/Vacancies/AddVacancy";
import AppliedVacancies from "../../Components/Admin/AppliedVacancies";
import ReviewVacancy from "../../Components/Admin/Vacancies/Review";
import ReturnOrdersAdmin from "../../Components/Admin/Orders/ReturnOrder";
import ViewReturnOrder from "../../Components/Admin/Orders/ReturnOrder/ViewReturnOrder";
import ComplaintPage from "../../Components/Admin/Complaint";
import FAQ from "../../Components/Admin/FAQ/Show";
import AddEditFAQ from "../../Components/Admin/FAQ/Add";
import SingleFAQ from "../../Components/Admin/FAQ/SingleFAQ";
import AssignedOrders from "../../Components/Admin/Orders/Assigned";
import AssignedAllQuotation from "../../Components/Admin/Quotations/Assigned";
import FeatureRetailProduct from "../../Components/Admin/FeatureProduct/Retail";
import FeatureWholesaleProduct from "../../Components/Admin/FeatureProduct/Wholesale";
import Reviews from "../../Components/Admin/Reviews";
import ShowNewsBlogNotice from "../../Components/Admin/NewsBlogNotice/Show";
import AddNewsBlogNotice from "../../Components/Admin/NewsBlogNotice/Add";
import SingleNewsBlogNotice from "../../Components/Admin/NewsBlogNotice/SingleBlog";
import SellerHome from "../../Components/RetailerWholeSaler/Home";
import Cart from "../../Components/RetailerWholeSaler/Cart";
import SinglProductPage from "../../Components/RetailerWholeSaler/SingleProductPage";
import Checkout from "../../Components/User/Checkout";
import OrganicProduct from "../../Components/Admin/ProductType/OrganicProduct";
import TrendingProduct from "../../Components/Admin/ProductType/TrendingProduct";
import BrandProduct from "../../Components/Admin/ProductType/BrandProduct";
import KreesaProduct from "../../Components/Admin/ProductType/KreesaProduct";
import SellerViewAll from "../../Components/RetailerWholeSaler/ProductList/SellerViewAll";
import FlashProduct from "../../Components/Admin/ProductType/FlashProduct";
import LegalDocsAdmin from "../../Components/Admin/LegalDocs";
import ManageLegalDocsAdmin from "../../Components/Admin/LegalDocs/ManageDocs";
import DiscountVoucherAdmin from "../../Components/Admin/DiscountVoucher/manage";
import VoucherListAdmin from "../../Components/Admin/DiscountVoucher/list";
import AllNotification from "../../Components/Admin/Notifications";
import Tree from "../../Components/Admin/Tree";
import Report from "../../Components/Admin/Report";

const AdminRoute = () => {
  return (
    <Switch>
      {/* Admin Login */}
      <AdminRouting
        exact
        path="/admin"
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        component={Home}
      />
      <LoginRouting exact path="/admin/login" component={AdminLogin} />
      {/* Forgot Password */}
      <Route exact path="/admin/forgot-password" component={ForgotPassword} />
      {/* OTP */}
      <Route exact path="/admin/OTP-verification" component={OtpVerification} />
      {/* Reset password */}
      <Route exact path="/admin/reset-password" component={ResetPassword} />
      {/* Admin Profile Settings */}
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/profile-settings"
        component={Settings}
      />
      {/* enquiry */}
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/enquiry"
        component={Enquiry}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/show/news-blog-notice/:id"
        component={SingleNewsBlogNotice}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/edit/news-blog-notice/:id"
        component={AddNewsBlogNotice}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/add/news-blog-notice"
        component={AddNewsBlogNotice}
      />
      {[
        "/admin/tree-unimplemented",
        "/admin/tree-implemented",
        "/admin/tree-limit/view",
      ].map((path) => (
        <AdminRouting
          exact
          access={[
            "ROLE_CEO",
            "ROLE_MANAGER",
            "ROLE_RECEPTIONIST",
            "ROLE_MARKETING_MANAGER",
            "ROLE_HR",
          ]}
          path={path}
          component={Tree}
        />
      ))}
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/news-blog-notice"
        component={ShowNewsBlogNotice}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/profile"
        component={Profile}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/complaint"
        component={ComplaintPage}
      />
      {/* Addressing */}
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/country"
        component={Country}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/add-country"
        component={AddCountry}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/country/edit/:id"
        component={EditCountry}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/district"
        component={District}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/add-district"
        component={AddDistrict}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/district/edit/:id"
        component={EditDistrict}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/province"
        component={Province}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/add-province"
        component={AddProvince}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/provience/edit/:id"
        component={EditProvience}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/legal-docs"
        component={LegalDocsAdmin}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/discount-voucher/add"
        component={DiscountVoucherAdmin}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/discount-voucher"
        component={VoucherListAdmin}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/legal-docs/create"
        component={ManageLegalDocsAdmin}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/legal-docs/view/:id"
        component={ManageLegalDocsAdmin}
      />
      {/* Adminstrator */}
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/details"
        component={Administrator}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/view-details/:id"
        component={ReviewDetails}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/details/:id"
        component={EditAdministrator}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/add-new-user"
        component={AddAdmin}
      />
      {/* Wholesaler */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/wholesalers"
        component={Wholesalers}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/view-wholesalers/:id"
        component={ReviewWholeSalerDetail}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/edit-wholesaler/:id"
        component={EditWholeSaler}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/add-new-rwuser"
        component={AddRWUser}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/all-notification"
        component={AllNotification}
      />
      {/* Customers */}
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
        ]}
        path="/admin/customers"
        component={Customers}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_MARKETING_MANAGER",
          "ROLE_HR",
        ]}
        path="/admin/messages"
        component={Message}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
        ]}
        path="/admin/view-customers/:id"
        component={ReviewCustomerDetails}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/vacancies"
        component={Vacancies}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/add-vacancy"
        component={AddEditVacancy}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/edit-vacancy/:id"
        component={AddEditVacancy}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/vacancy-review/:id"
        component={ReviewVacancy}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/applied-vacancies/:id"
        component={AppliedVacancies}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/applied-vacancies"
        component={AppliedVacancies}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
        ]}
        path="/admin/quotations/assign/:id"
        component={QuotationAssign}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/edit-faq/:id"
        component={AddEditFAQ}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/add-faq"
        component={AddEditFAQ}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/view-faq/:id"
        component={SingleFAQ}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/view/faq"
        component={FAQ}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/edit-customer/:id"
        component={EditCutomers}
      />
      {/* Retailors */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/retailers"
        component={Retailors}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/view-retailers/:id"
        component={ReviewRetailorDetail}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/quotations/user"
        component={UserQuotations}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/quotations/retailer"
        component={RetailerQuotations}
      />{" "}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/quotations/assigned"
        component={AssignedAllQuotation}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/quotations/wholesaler"
        component={WholesalerQuotations}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/quotations/:id"
        component={SingleQuotations}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_MARKETING_MANAGER"]}
        path="/admin/edit-retailers/:id"
        component={EditRetailor}
      />
      {/* Primary Category */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/primary-category/view"
        component={PrimaryCategory}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/primary-category/register"
        component={PrimaryCategoryForm}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/primary-category/edit/:id"
        component={EditCategoryForm}
      />
      {/* Secondary Category */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/secondary-category/view"
        component={SecondaryCategory}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/secondary-category/register"
        component={SecondaryCategoryFrom}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/secondary-category/edit/:id"
        component={EditSecondaryCategoryForm}
      />
      {/* Ternary Category */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/ternary-category/view"
        component={TernaryCategory}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/ternary-category/register"
        component={TernaryCategoryFrom}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/ternary-category/edit/:id"
        component={EditTernaryCategoryForm}
      />
      {/* Group Product */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/group-product/view"
        component={ShowGroupProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/group-product/view/:id"
        component={EditGroupProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/group-product/register"
        component={AddGroupProduct}
      />
      {/* Custom Orders */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/admin/custom-order/:type"
        component={ShowCustomOrder}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/admin/view-customorder/:orderId"
        component={SingleCustomOrder}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/custom-order"
        component={SellerHome}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/custom-order-view-all/:title"
        component={SellerViewAll}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/custom-order/cart"
        component={Cart}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/custom-order/product/:id"
        component={SinglProductPage}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/custom-order/checkout"
        component={Checkout}
      />
      {/* <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/custom-order"
        component={Homepage}
      /> */}
      {/* Show Product */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/product/view/retail"
        component={RetailerViewProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/feature-product/view/retail"
        component={FeatureRetailProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/feature-product/view/wholesale"
        component={FeatureWholesaleProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/organic-product/view/retail"
        component={OrganicProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/organic-product/view/wholesale"
        component={OrganicProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/trending-product/view/retail"
        component={TrendingProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/trending-product/view/wholesale"
        component={TrendingProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/brand-product/view/retail"
        component={BrandProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/brand-product/view/wholesale"
        component={BrandProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/kreesa-product/view/retail"
        component={KreesaProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/kreesa-product/view/wholesale"
        component={KreesaProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/flash-product/view/retail"
        component={FlashProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/flash-product/view/wholesale"
        component={FlashProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/view/reviews"
        component={Reviews}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/view/review/:id"
        component={Reviews}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/product/edit/:id"
        component={AdminEditProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/product/view/wholesale"
        component={WholesalerViewProduct}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/product/edit/wholesale/:id"
        component={EditProductDetails}
      />
      {/* Review by Id wholesaler */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/product/view/wholesale/:id"
        component={WholesalerReviewById}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/product/view/retail/:id"
        component={RetailReviewById}
      />
      {/* getting order for Retail & Wholesale*/}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/admin/order/view/wholesale"
        component={WholesalerShowOrders}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/admin/order/return"
        component={ReturnOrdersAdmin}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO"]}
        path="/admin/order/assigned"
        component={AssignedOrders}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/admin/order/return/view/:id"
        component={ViewReturnOrder}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/notification"
        component={PushNotification}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/notifications"
        component={AllPushNotification}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/admin/order/view/retail"
        component={RetailerShowOrders}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_RECEPTIONIST"]}
        path="/admin/order/view/wholesale/:id"
        component={SingleOrderDetail}
      />
      {/* company type */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/company-type"
        component={CompanyType}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/add-company-type"
        component={AddType}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER"]}
        path="/admin/edit-company-type/:id"
        component={EditType}
      />
      {/* Banner  */}
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/view/seller-banner"
        component={SellerBanner}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/seller-banner"
        component={AddSellerBanner}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/edit-seller-banner/:bId"
        component={EditSallerBanner}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/view/user-banner"
        component={CustomerBanner}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/user-banner"
        component={AddCustomerBanner}
      />
      <AdminRouting
        exact
        access={["ROLE_CEO", "ROLE_MANAGER", "ROLE_HR"]}
        path="/admin/edit-user-banner/:bId"
        component={EditCustomerBanner}
      />
      <AdminRouting
        exact
        access={[
          "ROLE_CEO",
          "ROLE_MANAGER",
          "ROLE_MARKETING_MANAGER",
          "ROLE_RECEPTIONIST",
          "ROLE_HR",
        ]}
        path="/admin/report/:type"
        component={Report}
      />
      {/* {/* Error Page */}
      {/* <Route exact component={PageNotFound} /> */}
    </Switch>
  );
};

export default AdminRoute;
