import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./style.css";

const LeftNavbar = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const history = useHistory();

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <>
      {/* {localStorage.getItem("auth") === "ROLE_DELIVERY_BOY" && ( */}
      <nav id="sidebar" className={`collapse ${matches && "show"}`}>
        <div
          className="sidebar-content js-simplebar"
          style={{ width: "259px" }}
        >
          <Link className="sidebar-brand" to="/delivery">
            <span className="align-middle">Kreesa Dashboard</span>
          </Link>

          <ul className="sidebar-nav">
            <li className="sidebar-header">Personal Settings</li>

            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                exact
                activeClassName="active__nav0"
                to="/delivery"
              >
                <i className="fas fa-tachometer-alt"></i>
                <span className="align-middle">Dashboard</span>
              </NavLink>
            </li>

            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                exact
                activeClassName="active__nav0"
                to="/delivery/profile-settings"
              >
                <i className="fas fa-cogs"></i>
                <span className="align-middle">Settings & Privacy</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link"
                exact
                activeClassName="active__nav0"
                to="/delivery/profile"
              >
                <i className="far fa-user-circle"></i>
                <span className="align-middle">Profile</span>
              </NavLink>
            </li>

            <li className="sidebar-header">Delivery Services</li>

            <li className="sidebar-item">
              <a
                data-target="#Delivery"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <i className="fab fa-first-order"></i>
                <span className="align-middle">Orders</span>
              </a>
              <ul
                id="Delivery"
                className={`sidebar-dropdown list-unstyled ${
                  history?.location?.state?.prevPath ===
                    "/delivery/get-all-delivery-orders" ||
                  history.location.pathname.includes(
                    "/delivery/get-all-delivery-orders"
                  ) ||
                  history?.location?.state?.prevPath ===
                    "/delivery/get-all-quotation-delivery-orders" ||
                  history.location.pathname.includes(
                    "/delivery/get-all-quotation-delivery-orders"
                  )
                    ? ""
                    : "collapse"
                }`}
                data-parent="#sidebar"
              >
                <li className="sidebar-item">
                  <NavLink
                    className="sidebar-link"
                    to="/delivery/get-all-delivery-orders"
                    exact
                    activeClassName="active__nav0"
                  >
                    Show all Deliveries
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    className="sidebar-link"
                    to="/delivery/get-all-quotation-delivery-orders"
                    exact
                    activeClassName="active__nav0"
                  >
                    Show Quotation Deliveries
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* blank div */}
            <li className="sidebar-header"></li>
            <li className="sidebar-item"></li>
          </ul>
        </div>
      </nav>
      {/* )} */}
    </>
  );
};

export default LeftNavbar;
