import { createSlice } from "@reduxjs/toolkit";
import {
  checkWishList,
  clearCart,
  getAllWishlists,
  getUserFaq,
  getRetailerProducts,
  getSingleRetailProducts,
  getUserOrder,
  getVacancies,
  homePageSearch,
  logoutUser,
  postSingleRetailCart,
  removeCartRetailItem,
  searchItemRetail,
  updateCartRetailItem,
  viewAllRetailCart,
  featureProductForUser,
  getBlogs,
  getBlogById,
  getRetailerTrendingProducts,
  getRetailerOrganicProducts,
  getRetailerKreesaProducts,
  getRetailerBrandProducts,
  getAllRetailerBrandProducts,
  getAllRetailerTrendingProducts,
  getAllRetailerFeatureProducts,
  getAllRetailerOrganicProducts,
  getAllRetailerKreesaProducts,
  getAllRetailerFlashSale,
  getRetailerFlashSale,
  getLegalDocsUser,
} from "../action";

const userProducts = createSlice({
  name: "userProducts",
  initialState: {
    userpageProducts: [],
    singleRetailProducts: [],
    retailCart: [],
    loader: false,
    userVacancies: [],
    errors: {},
    legalDoc: [],
    searchItem: [],
    userOrder: [],
    wishlist: [],
    wishlists: [],
    userFeature: [],
    faqs: [],
    news: [],
    notices: [],
    blogs: [],
    blog: [],
    trendingProducts: [],
    organicProducts: [],
    kreesaProducts: [],
    brandProducts: [],
    flashProducts: [],
    // all products
    allBrandProducts: [],
    allTrendingProducts: [],
    allFeatureProducts: [],
    allOrganicProducts: [],
    allKreesaProducts: [],
    allFlashProducts: [],
  },
  reducers: {},
  extraReducers: {
    /* to single Retailer Wholesaler Products in User Page*/
    //
    [getAllRetailerKreesaProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailerKreesaProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.allKreesaProducts = action.payload;
    },
    [getAllRetailerKreesaProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllRetailerOrganicProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailerOrganicProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.allOrganicProducts = action.payload;
    },
    [getAllRetailerOrganicProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllRetailerFeatureProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailerFeatureProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.allFeatureProducts = action.payload;
    },
    [getAllRetailerFeatureProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllRetailerTrendingProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailerTrendingProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.allTrendingProducts = action.payload;
    },
    [getAllRetailerTrendingProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllRetailerBrandProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailerBrandProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.allBrandProducts = action.payload;
    },
    [getAllRetailerBrandProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getRetailerBrandProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getRetailerBrandProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.brandProducts = action.payload;
    },
    [getRetailerBrandProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllRetailerFlashSale.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailerFlashSale.fulfilled]: (state, action) => {
      state.loader = false;
      state.allFlashProducts = action.payload;
    },
    [getAllRetailerFlashSale.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getRetailerFlashSale.pending]: (state, action) => {
      state.loader = true;
    },
    [getRetailerFlashSale.fulfilled]: (state, action) => {
      state.loader = false;
      state.flashProducts = action.payload;
    },
    [getRetailerFlashSale.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getRetailerKreesaProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getRetailerKreesaProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.kreesaProducts = action.payload;
    },
    [getRetailerKreesaProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [getRetailerOrganicProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getRetailerOrganicProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.organicProducts = action.payload;
    },
    [getRetailerOrganicProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [getRetailerTrendingProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getRetailerTrendingProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.trendingProducts = action.payload;
    },
    [getRetailerTrendingProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [getRetailerProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getRetailerProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.userpageProducts = action.payload;
    },
    [getRetailerProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getSingleRetailProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleRetailProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.singleRetailProducts = action.payload;
    },
    [getSingleRetailProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [postSingleRetailCart.pending]: (state, action) => {
      state.loader = true;
    },
    [postSingleRetailCart.fulfilled]: (state, action) => {
      state.loader = false;
      // state.singleRetailProducts = action.payload;
    },
    [postSingleRetailCart.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [viewAllRetailCart.pending]: (state, action) => {
      state.loader = true;
    },
    [viewAllRetailCart.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.retailCart = payload;
    },
    [viewAllRetailCart.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [removeCartRetailItem.pending]: (state, action) => {
      state.loader = true;
    },
    [removeCartRetailItem.fulfilled]: (state, { payload }) => {
      state.loader = false;
      // state.cartDetail = payload;
    },
    [removeCartRetailItem.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [updateCartRetailItem.pending]: (state, action) => {
      state.loader = true;
    },
    [updateCartRetailItem.fulfilled]: (state, { payload }) => {
      state.loader = true;
      // state.cartDetail = payload;
    },
    [updateCartRetailItem.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [searchItemRetail.pending]: (state, action) => {
      state.loader = true;
    },
    [searchItemRetail.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.searchItem = payload;
    },
    [searchItemRetail.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [logoutUser.fulfilled]: (state, action) => {
      state.loader = false;
      state.retailCart = [];
    },

    [clearCart.fulfilled]: (state, action) => {
      state.loader = false;
      state.retailCart = [];
    },

    [homePageSearch.fulfilled]: (state, action) => {
      state.loader = false;
      state.searchItem = action.payload;
    },

    [getUserOrder.pending]: (state, action) => {
      state.loader = true;
    },
    [getUserOrder.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.userOrder = payload;
    },
    [getUserOrder.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [getVacancies.pending]: (state, action) => {
      state.loader = true;
    },
    [getVacancies.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.userVacancies = payload;
    },
    [getVacancies.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to check wishlist
    [checkWishList.pending]: (state, action) => {
      state.loader = true;
    },
    [checkWishList.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.wishlist = payload;
    },
    [checkWishList.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get all wishlist
    [getAllWishlists.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllWishlists.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.wishlists = payload;
    },
    [getAllWishlists.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get all faqs
    [getUserFaq.pending]: (state, action) => {
      state.loader = true;
    },
    [getUserFaq.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.faqs = payload;
    },
    [getUserFaq.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get all featured products for user
    [featureProductForUser.pending]: (state, action) => {
      state.loader = true;
    },
    [featureProductForUser.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.userFeature = payload;
    },
    [featureProductForUser.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // get blogs
    [getBlogs.pending]: (state, action) => {
      state.loader = true;
    },
    [getBlogs.fulfilled]: (state, { payload }) => {
      state.loader = false;
      // state.blogs = payload;
      const data = payload;
      let array1 = [];
      let array2 = [];
      let array3 = [];
      //
      data?.forEach((element) => {
        if (element.blogType === "BLOG") {
          array1.push(element);
        } else if (element.blogType === "NOTICE") {
          array2.push(element);
        } else if (element.blogType === "NEWS") {
          array3.push(element);
        }
      });
      state.blogs = array1;
      state.notices = array2;
      state.news = array3;
    },
    [getBlogs.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // get blogs
    [getBlogById.pending]: (state, action) => {
      state.loader = true;
    },
    [getBlogById.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.blog = payload;
    },
    [getBlogById.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    // get blogs
    [getLegalDocsUser.pending]: (state, action) => {
      state.loader = true;
    },
    [getLegalDocsUser.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.legalDoc = payload;
    },
    [getLegalDocsUser.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export default
export default userProducts.reducer;
