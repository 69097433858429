import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleFaqsForAdmin } from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";
import FaqImgae from "../../../../assets/Images/faq.png";
import HtmlParser from "react-html-parser";

const SingleFAQDetails = () => {
  document.title = "Admin | Single FAQ";
  const { id } = useParams();
  const dispatch = useDispatch();

  const { faq, loader } = useSelector((s) => s.AdminReducer);

  useEffect(() => {
    dispatch(getSingleFaqsForAdmin(id));
  }, [id]);

  return !loader ? (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <p className="text-capitalize font-weight-bold">{faq?.question}</p>
          {/* <p>How to shop?</p> */}
          {/* <p>How to post the requirements?</p> */}
          {/* <a href={faq?.videoUrl} target="_blank">
            <img
              src={`http://img.youtube.com/vi/${faq?.videoUrl?.slice(
                17
              )}/0.jpg`}
              height="200"
              alt={faq?.videoUrl?.slice(17)}
            />
          </a> */}
          {faq.videoUrl === "" ? (
            <img
              src={FaqImgae}
              alt="no-image"
              style={{
                borderRadius: 12,
              }}
              height="250"
              className="mt-3"
            />
          ) : (
            <div className="mt-3 faq__video_iframe">
              {HtmlParser(faq?.videoUrl)}
            </div>
          )}

          <div className="mt-4">{HtmlParser(faq?.answer)}</div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default SingleFAQDetails;
