// import React from "react";
// import "./style.css";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   InstapaperShareButton,
//   EmailShareButton,
// } from "react-share";
// import { FacebookIcon } from "react-share";
// import { Helmet } from "react-helmet";
// const index = (props) => {
//   return (
//     <div>
//       <Helmet>
//         <meta charset="utf-8" />
//         <meta http-equiv="X-UA-Compatible" content="IE=edge" />
//         <meta name="csrf_token" content="" />
//         <meta property="type" content="website" />

//         <meta
//           name="viewport"
//           content="width=device-width, initial-scale=1, shrink-to-fit=no"
//         />
//         <meta name="msapplication-TileColor" content="#ffffff" />
//         <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
//         <meta name="theme-color" content="#ffffff" />
//         <meta name="_token" content="" />
//         <meta name="robots" content="noodp" />

//         <meta name="description" content={props.description} />

//         <meta property="og:locale" content="en_US" />
//         <meta property="og:type" content="website" />

//         <meta property="og:image" content={props.image} />
//         <meta content="image/*" property="og:image:type" />
//       </Helmet>
//       <div className="menus__">
//         <input
//           type="checkbox"
//           href="#"
//           className="menu-open"
//           name="menu-open"
//           id="menu-open"
//         />
//         <label className="menu-open-button" for="menu-open">
//           <i className="fa fa-share-alt share-icon"></i>
//         </label>

//         <a href="" className="menu__items facebook_share_btn">
//           {" "}
//           <FacebookShareButton
// url={props.facebook}
// hashtag={"#kreesa"}
// quote={props.description}
//             className="Demo__some-network__share-button"
//           >
//             <FacebookIcon size={30} round />
//           </FacebookShareButton>
//         </a>
//         <a href="" className="menu__items twitter_share_btn">
//           <TwitterShareButton
//             url={props.twitter}
//             hashtag={"#kreesa"}
//             quote={props.description}
//             className="Demo__some-network__share-button"
//           >
//             <i className="fab fa-twitter"></i>
//           </TwitterShareButton>
//         </a>
//         {/* <a href="" className="menu__items pinterest_share_btn">
//           <InstapaperShareButton url={instagram} hashtag={"#kreesa"} description={description} className="Demo__some-network__share-button">
//             <i className="fab fa-instagram"></i>
//           </InstapaperShareButton>
//         </a> */}
//         <a href="" className="menu__items google_plus_share_btn">
//           <EmailShareButton
//             url={props.email}
//             hashtag={"#kreesa"}
//             quote={props.description}
//             className="Demo__some-network__share-button"
//           >
//             <i className="fas fa-envelope-square"></i>
//           </EmailShareButton>
//         </a>
//         {/* <a href="https://www.youtube.com" target="_blank" className="menu__items youtube_share_btn">
//           {" "}
//           <i className="fa fa-youtube"></i>{" "}
//         </a> */}
//       </div>
//     </div>
//   );
// };

// export default index;

/* -------------------------------------------------------------------------- */
/*                                 new react share                                */
/* -------------------------------------------------------------------------- */

import React from "react";
import { Helmet } from "react-helmet";
import { FaShare } from "react-icons/fa";
import $ from "jquery";
import "./style.css";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  WhatsappIcon,
  EmailIcon,
  InstapaperShareButton,
  ViberShareButton,
  ViberIcon,
} from "react-share";
const SocialShare = (props) => {
  // $(document).ready(function () {
  //   $(".social-share-toggle-btn").hide();
  // $(".social-share-toggle-btn").click(function () {
  //   $(".social-share-buttons").slideDown();
  //   $(".social-share-toggle-btn").hide();
  // });
  // });
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="csrf_token" content="" />
        <meta property="type" content="website" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />
        <meta name="description" content={props.description} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={props.image} />
        <meta property="image" content={props.image} data-react-helmet="true" />
        <meta content="image/*" property="og:image:type" />
      </Helmet>
      {/* <button
        type="button"
        className="btn btn-lg social-share-toggle-btn"
        style={{
          backgroundColor: "#efefef",
          fontWeight: "bold",
        }}
      >
        <FaShare /> SHARE
      </button> */}

      <section
        className="c-network mt-2 social-share-buttons"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FacebookShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <FacebookIcon size={"2rem"} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          media={props.image}
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <TwitterIcon size={"2rem"} round />
        </TwitterShareButton>

        <ViberShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <ViberIcon size={"2rem"} round />
        </ViberShareButton>
        {/*  */}
        <WhatsappShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          separator=":: "
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <WhatsappIcon size={"2rem"} round />
        </WhatsappShareButton>

        <LinkedinShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          windowWidth={750}
          windowHeight={600}
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <LinkedinIcon size={"2rem"} round />
        </LinkedinShareButton>
        <InstapaperShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          windowWidth={750}
          windowHeight={600}
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <i
            className="fab fa-instagram"
            style={{
              fontSize: "2rem",
            }}
          ></i>
        </InstapaperShareButton>

        <PinterestShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          windowWidth={1000}
          windowHeight={730}
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <PinterestIcon size={"2rem"} round />
        </PinterestShareButton>

        <EmailShareButton
          url={props.url}
          hashtag={"#kreesa"}
          quote={props.description}
          body="body"
          style={{
            outline: "none",
            border: "none",
          }}
          className="ml-2"
        >
          <EmailIcon size={"2rem"} round />
        </EmailShareButton>
      </section>
    </div>
  );
};
export default SocialShare;
