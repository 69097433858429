import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllQuotations,
  ApproveQuotations,
  DeclineQuotations,
  getAllAssignedDeliveryQuotations,
} from "../../Features/Action/QuotationAction";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import { filterAssignedQuoteForAdmin } from "../../Features/Reducer/QuotationsReducer";
import { getAllDeliveryBoy } from "../../Features/Action/OrderStatusAction";
import moment from "moment";
import { Pagination } from "@mantine/core";

const AllAssignedQuotation = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();
  const inputRef = useRef("");

  const { assignedDeliveryQuotations, loader } = useSelector(
    (s) => s.QuotationsReducer
  );
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = assignedDeliveryQuotations?.slice(
    indexOfFirst,
    indexOfLast
  );
  const totalPagesNum = Math.ceil(assignedDeliveryQuotations?.length / perPage);
  useEffect(() => {
    dispatch(getAllQuotations());
    dispatch(getAllDeliveryBoy());
    dispatch(getAllAssignedDeliveryQuotations());
  }, []);
  /* ------------------------ handle Approve Quotation ------------------------ */
  const handleApproveQuotation = (id) => {
    dispatch(ApproveQuotations(id));
    toast.success("Quotation Approved Successfully");
  };

  // Search
  const filterQuotation = () => {
    dispatch(filterAssignedQuoteForAdmin(inputRef.current.value));
  };
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Assigned Quotations..."
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterQuotation}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <h4>All Assigned Quotation</h4>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Quotations</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th className="d-xl-table-cell">Expected Price</th>
                    <th className="d-xl-table-cell">Created By</th>
                    <th>Delivery Status</th>
                    <th className="d-md-table-cell">Created Date</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>
                        {" "}
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${p.id}/${p.image}`}
                          width="48"
                          height="48"
                          loading="lazy"
                          className="rounded-circle mr-2"
                          alt={p.product_name}
                        />
                        {p.product_name}
                      </td>
                      <td>{p.quantity}</td>
                      <td className="d-xl-table-cell">{p.expected_price}</td>
                      <td className="d-xl-table-cell">
                        {" "}
                        <a href={`mailto:${p.createdBy}`}>{p.created_by}</a>
                      </td>

                      <td>{p.delivery_status}</td>

                      <td className="d-md-table-cell">
                        {moment(p.created_date).format("lll")}
                      </td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            {/* <Link
                              to={`/admin/quotations/${p.id}`}
                              className="text-success"
                            > */}
                            <div
                              className="cursor-pointer text-success"
                              onClick={() =>
                                history.push({
                                  pathname: `/admin/quotations/${p.id}`,
                                  state: "delivery-detail",
                                })
                              }
                            >
                              <i className="fas fa-eye"></i> Review
                            </div>
                            {/* </Link> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="text-center">
          <h5>No Assigned Quotation Found !</h5>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default AllAssignedQuotation;
