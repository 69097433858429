import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  AdminConfirmDelivery,
  AssignQuoteToDeliveryBoy,
  changePaymentStatus,
  getAllDeliveryBoy,
  getAllRetailOrder,
  getAllReturnOrder,
  getAllWholesaleOrder,
} from "../../Action/OrderStatusAction";

const AdminDeliveryReducer = createSlice({
  name: "AdminDeliveryReducer",
  initialState: {
    allAdminDelivery: [],
    message: [],
    retailOrders: [],
    retailOrdersContainer: [],
    wholesaleOrders: [],
    deliveryBoys: [],
    wholesaleOrdersContainer: [],
    returnOrders: [],
    returnOrdersContainer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterReturnOrder: (state, action) => {
      state.returnOrders = state.returnOrdersContainer.filter(
        (u) =>
          u.productName.toLowerCase().includes(action.payload) ||
          u.productName.toUpperCase().includes(action.payload)
      );
    },
    filterWholesaleDelivery: (state, action) => {
      state.wholesaleOrders = state.wholesaleOrdersContainer.filter(
        (u) =>
          u.orderNo.toLowerCase().includes(action.payload) ||
          u.orderNo.toUpperCase().includes(action.payload)
      );
    },
    filterRetailDelivery: (state, action) => {
      state.retailOrders = state.retailOrdersContainer.filter(
        (u) =>
          u.orderNo.toLowerCase().includes(action.payload) ||
          u.orderNo.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Products */
    [AdminConfirmDelivery.pending]: (state, action) => {
      state.loader = true;
    },
    [AdminConfirmDelivery.fulfilled]: (state, action) => {
      state.loader = false;
      state.allEnquiries = action.payload;
      state.message = toast.success(action.payload);
    },
    [AdminConfirmDelivery.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
      state.message = toast.error(action.payload);
    },
    // get all wholesale orders
    [getAllWholesaleOrder.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllWholesaleOrder.fulfilled]: (state, action) => {
      state.loader = false;
      state.wholesaleOrders = action.payload;
      state.wholesaleOrdersContainer = action.payload;
    },
    [getAllWholesaleOrder.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // get all retail orders
    [getAllRetailOrder.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailOrder.fulfilled]: (state, action) => {
      state.loader = false;
      state.retailOrders = action.payload;
      state.retailOrdersContainer = action.payload;
    },
    [getAllRetailOrder.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // get all delivery boy
    [getAllDeliveryBoy.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllDeliveryBoy.fulfilled]: (state, action) => {
      state.loader = false;
      state.deliveryBoys = action.payload;
    },
    [getAllDeliveryBoy.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getAllReturnOrder.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllReturnOrder.fulfilled]: (state, action) => {
      state.loader = false;
      state.returnOrders = action.payload;
      state.returnOrdersContainer = action.payload;
    },
    [getAllReturnOrder.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [AssignQuoteToDeliveryBoy.pending]: (state, action) => {
      state.loader = true;
    },
    [AssignQuoteToDeliveryBoy.fulfilled]: (state, action) => {
      state.loader = false;
      state.returnOrders = action.payload;
    },
    [AssignQuoteToDeliveryBoy.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [changePaymentStatus.pending]: (state, action) => {
      state.loader = true;
    },
    [changePaymentStatus.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [changePaymentStatus.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const {
  filterWholesaleDelivery,
  filterRetailDelivery,
  filterReturnOrder,
} = AdminDeliveryReducer.actions;
// export default
export default AdminDeliveryReducer.reducer;
