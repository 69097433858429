import React, { useEffect, useState } from "react";
import LeftNavbar from "../../../LeftNavbar";
import TopNavbar from "../../../TopNavbar";
import Footer from "../../../Footer";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useButtonLoader from "../../../../ButtonLoaderHook";
import {
  editTernaryCategory,
  getSecondaryCategory,
} from "../../../Features/Action/CategoryAction/CategoryAction";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../Spinner";
import { Formik, Form } from "formik";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const EditTernaryCategoryForm = () => {
  document.title = "Admin | Edit Ternary Category";
  const { editTernaryCategories, loader } = useSelector(
    (s) => s.ternaryCategory
  );
  const { secondaryCategories } = useSelector((s) => s.secondaryCategory);
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Category",
    "Loading..."
  );
  const [description, setDescription] = useState();

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const initialValues = {
    id: editTernaryCategories?.id,
    secondaryCategoryId: editTernaryCategories.secondaryCategory?.id,
    ternaryCategoryStatus: editTernaryCategories?.ternaryCategoryStatus,
    ternaryCategoryName: editTernaryCategories?.ternaryCategoryName,
    description: editTernaryCategories?.description,
  };
  useEffect(() => {
    dispatch(editTernaryCategory(id));
  }, [id]);

  //for wysiwyg editor
  const onEditorStateChange = (event) => {
    setDescription(event);
  };
  useEffect(() => {
    dispatch(getSecondaryCategory());
    if (editTernaryCategories?.description?.length > 0) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(editTernaryCategories?.description)
          )
        )
      );
    }
  }, [editTernaryCategories]);
  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  const updateTernaryCategoryHandler = async (values) => {
    setButtonLoader(true);

    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const des = draftToHtml(convertToRaw(description.getCurrentContent()));
      const res = await AxiosConfig.post(
        `/kreesa/admin/category/ternary/update/${id}`,
        { ...values, description: des },
        config
      );

      if (res) {
        toast.success(`Category updated succesfully !`);
        setButtonLoader(false);
        history.push("/admin/ternary-category/view");
      }
    } catch (err) {
      console.log(err + "error found");
      // setMessage('Filed to add New category !')
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      // toast.error(err.response.data.message);
      setButtonLoader(false);
    }
  };
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          {!loader ? (
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title font-weight-bold">
                    Edit Category Form
                  </h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => updateTernaryCategoryHandler(values)}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="col">
                          <div className="mb-3 col-md-4">
                            <label className="form-label" htmlFor="inputCity">
                              <span className="font-weight-bold">
                                Secondary Category Name
                              </span>{" "}
                              <span className="text-danger"> *</span>
                            </label>
                            <select
                              className="form-control"
                              required="required"
                              name="secondaryCategoryId"
                              value={
                                values.secondaryCategoryId ||
                                editTernaryCategories.secondaryCategory?.id
                              }
                              id="inputCity"
                              onChange={handleChange}
                            >
                              {secondaryCategories.map((sc, ii) => (
                                <option
                                  key={ii}
                                  value={sc.id}
                                  selected={
                                    editTernaryCategories?.secondaryCategory
                                      ?.secondaryCategoryName ===
                                    sc.secondaryCategoryName
                                  }
                                >
                                  {sc.secondaryCategoryName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-3 mt-2 col-md-4">
                            <label className="form-label" htmlFor="inputCity">
                              <span className="font-weight-bold">
                                Ternary Name
                              </span>
                              <span className="text-danger"> *</span>
                            </label>

                            <input
                              className="form-control"
                              type="text"
                              name="ternaryCategoryName"
                              id="inputCity"
                              onChange={handleChange}
                              value={values?.ternaryCategoryName}
                            />
                          </div>
                          <div className="mb-3 mt-2 col-md-4">
                            <label className="form-label" htmlFor="inputCity">
                              <span className="font-weight-bold">Status</span>
                              <span className="text-danger"> *</span>
                            </label>

                            <input
                              className="form-check-input ml-2"
                              type="radio"
                              name="ternaryCategoryStatus"
                              id="exampleRadios1"
                              value="1"
                              checked={
                                values?.ternaryCategoryStatus === 1
                                  ? "checked"
                                  : null
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label pl-2"
                              htmlFor="exampleRadios1"
                            >
                              {" "}
                              Active
                            </label>
                            <input
                              className="form-check-input ml-2"
                              type="radio"
                              name="ternaryCategoryStatus"
                              id="exampleRadios2"
                              value="0"
                              checked={
                                values?.ternaryCategoryStatus === 0
                                  ? "checked"
                                  : null
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label pl-2"
                              htmlFor="exampleRadios2"
                            >
                              {" "}
                              Inactive{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3 col-md-6">
                            <label className="form-label" htmlFor="inputCity">
                              <span className="font-weight-bold">
                                Category Description
                              </span>
                              <span className="text-info"> (optional)</span>
                            </label>
                            <Editor
                              editorState={description}
                              onEditorStateChange={(event) =>
                                onEditorStateChange(event)
                              }
                              editorStyle={{
                                backgroundColor: "white",
                                minHeight: "150px",
                                padding: "10px 20px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-3 mt-4 col-md-4">
                          <button
                            type="submit"
                            className="btn btn-success btn-lg btn-block"
                            ref={buttonLoader}
                            onClick={handleSubmit}
                          >
                            Update Category
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default EditTernaryCategoryForm;
