import $ from "jquery";
import "jquery-validation";

const UserLoginValidation = () => {
  // Wait for the DOM to be ready
  $(document).ready(function () {
    $("#user-otp-form").validate({
      // Specify validation rules
      rules: {
        otp: {
          required: true,
          number: true,
          maxlength: 6,
        },
      },

      highlight: function (element) {
        $(element).removeClass("is-valid").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid").addClass("is-valid");
      },
    });
  });
  $("#user-otp-form input").on("keyup blur", function () {
    // fires on every keyup & blur
    if ($("#user-otp-form").valid()) {
      // checks form for validity
      $("button#otpbtn").prop("disabled", false); // enables button
    } else {
      $("button#otpbtn").prop("disabled", "disabled"); // disables button
    }
  });
};

export default UserLoginValidation;
