import { useEffect, useState } from "react";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import "./ImageSlide.css";
import { Carousel } from "react-responsive-carousel";

const ImageSlide = () => {
  const [state, setState] = useState([]);

  const getcustomerBanner = async () => {
    try {
      const type = "customer";
      const getBanner = await AxiosConfig.get(
        `/kreesa/admin/banner/get-alll-banner-by-type/${type}`
      );
      setState(getBanner.data?.filter((item) => item.bannerPosition === "TOP"));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getcustomerBanner();
  }, []);

  return (
    <div
      className="m-0 p-0"
      style={{
        backgroundColor: "#e2e6eb",
      }}
    >
      {state?.length > 0 && (
        <Carousel
          autoPlay
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          swipeable={false}
          // by default 3 seconds
          interval={4000}
        >
          {state?.map((item, index) => (
            <div key={index}>
              <div
                className={`${
                  item.redirectionLink === null ? "" : "cursor-pointer"
                }`}
                onClick={() => {
                  const link = item.redirectionLink;
                  if (
                    link &&
                    (link.startsWith("https://kreesa.com") ||
                      link.startsWith("https://www.kreesa.com"))
                  ) {
                    window.open(link, "_self");
                  } else if (link) {
                    window.open(link, "_blank");
                  }
                }}
              >
                <img
                  loading="lazy"
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${item.id}/${item.image}`}
                  alt={item?.image}
                  style={{
                    width: "100%",
                    objectFit: "fill",
                    height: 350,
                  }}
                  className="img-fluid slider__images__"
                />
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
    // <div id="demo" className="carousel slide cs" data-ride="carousel">
    //   <ul className="carousel-indicators ci">
    //     {state.map((item, index) => (
    //       <li
    //         key={index}
    //         data-target="#demo"
    //         data-slide-to={index}
    //         className={index === 0 ? "active" : ""}
    //       ></li>
    //     ))}
    //   </ul>
    //   <div className="carousel-inner cin">
    //     {state?.map((item, index) => (
    //       <div
    //         className={`carousel-item ${index === 0 ? "active" : ""}`}
    //         key={index}
    //       >
    //         {item.redirectionLink === null ? (
    //           <img
    //             className="img-fluid slid-im"
    //             src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${item.id}/${item.image}`}
    //             alt={item.image}
    //             style={{ objectFit: "contain" }}
    //           />
    //         ) : (
    //           <a
    //             href={item.redirectionLink}
    //             target={`${
    //               item.redirectionLink?.slice(0, 22) ===
    //               "https://www.kreesa.com"
    //                 ? "_self"
    //                 : "_blank"
    //             }`}
    //           >
    //             <img
    //               className="img-fluid slid-im"
    //               src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${item.id}/${item.image}`}
    //               alt={item.image}
    //               style={{ objectFit: "contain" }}
    //             />
    //           </a>
    // )}
    //          <div className="carousel-caption d-none d-md-block ">
    //             <h1 className="text-capitalize ">You and Us, moving together.</h1>
    //             <h2 className="mt-4">Enjoy Kreesa's Best Products</h2>
    //           </div>
    //      </div>
    //     ))}
    //   </div>

    //   <a className="carousel-control-prev" href="#demo" data-slide="prev">
    //     <span className="carousel-control-prev-icon"></span>
    //   </a>
    //   <a className="carousel-control-next" href="#demo" data-slide="next">
    //     <span className="carousel-control-next-icon"></span>
    //   </a>
    // </div>
  );
};

export default ImageSlide;
