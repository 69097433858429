import React, { useEffect } from "react";
import { List } from "rsuite";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import "./Drawer.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../../Common/Features/Action/CategoryAction/CategoryAction";
import { Transition } from "react-transition-group";
import { useHistory } from "react-router-dom";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";

const NestedListGroup = ({ onClose }) => {
  const [primary, setPrimary] = React.useState();
  const [secondary, setSecondary] = React.useState();
  const { Categories, loader } = useSelector((s) => s.Categories);
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const dropdownSearch = (id, type, name) => {
    let data = {
      catType: type,
      categoryId: id,
      userType:
        localStorage.getItem("auth") === "ROLE_RETAILER" ||
        localStorage.getItem("auth") === "ROLE_WHOLESALER"
          ? "wholesale"
          : "retail",
    };
    AxiosConfig.post(`/kreesa/search-product`, data, {
      // headers: {
      //   Authorization: "Bearer " + localStorage.getItem("token"),
      // },
    }).then((res) => {
      history.push({
        pathname:
          localStorage.getItem("auth") === "ROLE_RETAILER" ||
          localStorage.getItem("auth") === "ROLE_WHOLESALER"
            ? "/seller/search-page"
            : "/user/search-page",
        search: `?category=${name}`,
        state: res.data,
      });
    });
  };
  return (
    <List bordered style={{ textTransform: "capitalize" }}>
      {Categories.primary?.map((p, i) => (
        <>
          <List.Item
            key={i}
            index={i}
            className="h4 text-black cursor-pointer drawer_primary_container_"
            onClick={() => {
              if (
                Categories.secondary?.filter((secId) => {
                  return secId.primaryCategoryId === p.id;
                }).length > 0
              ) {
                setPrimary(p.id);
              } else {
                dropdownSearch(p.id, "primary", p.categoryName);
                onClose();
              }
            }}
            style={{
              background: primary === p.id && "#e2e6eb",
              fontWeight: primary === p.id && "bold",
            }}
          >
            {p.categoryName}
            {Categories.secondary?.filter((secId) => {
              return secId.primaryCategoryId === p.id;
            }).length > 0 && (
              <ArrowRightLineIcon
                style={{
                  float: "right",
                }}
              />
            )}
          </List.Item>
          {/* secondary category */}
          {primary === p.id && (
            <List bordered>
              {Categories.secondary?.map(
                (sec, j) =>
                  p.id === sec.primaryCategoryId && (
                    <>
                      <Transition
                        in={sec}
                        timeout={300}
                        unmountOnExit
                        mountOnEnter
                      >
                        <List.Item
                          key={j}
                          index={j}
                          className={`${
                            secondary === sec.id &&
                            "drawer_primary_container_items"
                          } h4 text-black cursor-pointer drawer_primary_container`}
                          onClick={() => {
                            if (
                              Categories.ternary.filter((terId) => {
                                return terId.secondaryCategoryId === sec.id;
                              }).length > 0
                            ) {
                              setSecondary(sec.id);
                            } else {
                              dropdownSearch(
                                sec.id,
                                "secondary",
                                sec.secondaryCategoryName
                              );
                              onClose();
                            }
                          }}
                          style={
                            sec === "entering"
                              ? { animation: "moveSideBar .3s forwards" }
                              : sec === "entered"
                              ? { transform: "translateX(-0px)" }
                              : {
                                  animation:
                                    "moveSideBar .3s reverse backwards",
                                }
                          }
                        >
                          {sec.secondaryCategoryName}
                          {Categories.ternary.filter((terId) => {
                            return terId.secondaryCategoryId === sec.id;
                          }).length > 0 && (
                            <ArrowRightLineIcon
                              style={{
                                float: "right",
                              }}
                            />
                          )}
                        </List.Item>
                      </Transition>
                      {/* ternary */}
                      {secondary === sec.id && (
                        <List bordered>
                          {Categories.ternary?.map(
                            (ter, k) =>
                              sec.id === ter.secondaryCategoryId && (
                                <Transition
                                  in={sec}
                                  timeout={300}
                                  unmountOnExit
                                  mountOnEnter
                                  key={k}
                                >
                                  <List.Item
                                    key={j}
                                    index={ter}
                                    className="h4 text-black cursor-pointer drawer_primary_container_"
                                    onClick={() => {
                                      dropdownSearch(
                                        ter.id,
                                        "ternary",
                                        ter.ternaryCategoryName
                                      );
                                      onClose();
                                    }}
                                    style={
                                      ter === "entering"
                                        ? {
                                            animation:
                                              "moveSideBar .3s forwards",
                                          }
                                        : ter === "entered"
                                        ? { transform: "translateX(-0px)" }
                                        : {
                                            animation:
                                              "moveSideBar .3s reverse backwards",
                                          }
                                    }
                                  >
                                    {ter.ternaryCategoryName}
                                    {/* {Categories.ternary.filter((terId) => {
                                        return (
                                          terId.secondaryCategoryId === sec.id
                                        );
                                      }).length > 0 && (
                                        <ArrowRightLineIcon
                                          style={{
                                            float: "right",
                                          }}
                                        />
                                      )} */}
                                  </List.Item>

                                  {/* ternary */}
                                </Transition>
                              )
                          )}
                        </List>
                      )}
                    </>
                  )
              )}
            </List>
          )}
        </>
      ))}
      <div className="pb-4"></div>
    </List>
  );
};

export default NestedListGroup;
