import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import {
  blockUser,
  getAllAdmistrator,
  unlockUser,
} from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useButtonLoader from "../../../ButtonLoaderHook";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Pagination } from "@mantine/core";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required ")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
  accept: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});

const AllUsersTab = () => {
  const [state, setstate] = useState([]);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  // const initialValues = {
  //   newPassword: "",
  //   confirmPassword:'',
  //  email:state.email
  // };

  const [buttonLoader1, setButtonLoader1] = useButtonLoader(
    "Save Changes",
    "Loading..."
  );
  const [show, setShow] = useState({
    password: false,
    confirmPassword: false,
  });

  const [modalId, setModalId] = React.useState("");
  const handleClose = () => setModalId("");

  const dispatch = useDispatch();

  // get all users from API
  const { admistrator, loader } = useSelector((s) => s.AdminReducer);

  useEffect(() => {
    dispatch(getAllAdmistrator());
  }, []);
  //block
  const BlockUser = (id) => {
    dispatch(blockUser(id));
    toast.info("User has been Blocked");
    setTimeout(() => {
      dispatch(getAllAdmistrator());
    }, 500);
  };
  // unlocked user
  const unLockUser = (id) => {
    dispatch(unlockUser(id));
    toast.info("User has been Unlocked");
    setTimeout(() => {
      dispatch(getAllAdmistrator());
    }, 500);
  };

  const UpdatePasswordHandler = async (values) => {
    setButtonLoader1(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let data = new FormData();
      data.append("newPassword", values.newPassword);
      data.append("email", admistrator.email);
      const res = await AxiosConfig.post(
        "/kreesa/admin/update-password",
        values,
        config
      );
      if (res) {
        toast.success("Password Change Successfully");
        setModalId("");
        setButtonLoader1(false);
      }
    } catch (error) {
      console.log(error + "Something Went Wrong ! ");
      toast.error("Something Went Wrong !");
      setButtonLoader1(false);
    }
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = admistrator?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(admistrator?.length / perPage);

  return !loader ? (
    <>
      <div
        className="col-12 col-lg-8 d-flex w-100"
        style={{ minHeight: "400px" }}
      >
        {currentEntries?.length > 0 ? (
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">All Users</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Full Name</th>
                  <th className="d-xl-table-cell">E-mail</th>
                  <th className="d-xl-table-cell">Phone</th>
                  <th className="d-xl-table-cell">Profile Image</th>
                  <th className="d-xl-table-cell">Role</th>
                  <th>Status</th>
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map(
                  (p, i) =>
                    p.userId != localStorage.getItem("userId") && (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {p.firstname} {p.lastname}
                        </td>
                        <td className="d-xl-table-cell">
                          <a href={`mailto:${p.email}`}>{p.email}</a>
                        </td>
                        <td className="d-xl-table-cell">{p.phoneNumber}</td>
                        <td className="d-xl-table-cell">
                          <img
                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/Admin/${p.userId}/${p.image}`}
                            width="48"
                            height="48"
                            className="rounded-circle mr-2"
                            alt="Avatar"
                          />
                        </td>
                        <td className="d-xl-table-cell">
                          {p.role === "ROLE_CEO"
                            ? "CEO"
                            : "" || p.role === "ROLE_MANAGER"
                            ? "MANAGER"
                            : "" || p.role === "ROLE_MARKETING_MANAGER"
                            ? "MARKETING MANAGER"
                            : "" || p.role === "ROLE_RECEPTIONIST"
                            ? "RECEPTIONIST"
                            : "" || p.role === "ROLE_DELIVERY_BOY"
                            ? "DELIVERY BOY"
                            : "HR"}
                        </td>
                        <td>
                          {p.flag === true ? (
                            p.locked === true ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Inactive</span>
                            )
                          ) : (
                            <span className="badge bg-danger">Blocked</span>
                          )}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              type="button"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#3b7ddd",
                              }}
                              className="btn-md btn btn-success dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More
                            </button>
                            <div className="dropdown-menu text-left">
                              <Link
                                to={`/admin/details/${p.userId}`}
                                className="dropdown-item text-info"
                              >
                                <i className="fas fa-user-edit"></i> Edit
                              </Link>
                              <div className="dropdown-divider" />
                              <button
                                onClick={() => setModalId(`modal${i}`)}
                                className="dropdown-item text-primary"
                              >
                                <i className="fas fa-key"></i> Change Password
                              </button>
                              <div className="dropdown-divider" />
                              <Link
                                to={`/admin/view-details/${p.userId}`}
                                className="dropdown-item text-success"
                              >
                                <i className="fas fa-eye"></i> Review
                              </Link>

                              {p.flag === true ? (
                                <>
                                  <div className="dropdown-divider" />
                                  <p
                                    onClick={() => BlockUser(p.userId)}
                                    className="dropdown-item text-danger cursor-pointer"
                                  >
                                    <i className="fas fa-user-lock"></i> Block
                                  </p>
                                </>
                              ) : null}
                              {p.locked === false ? (
                                <>
                                  <div className="dropdown-divider" />
                                  <p
                                    onClick={() => unLockUser(p.userId)}
                                    className="dropdown-item text-warning cursor-pointer"
                                  >
                                    <i className="fad fa-user-clock"></i> UnLock
                                  </p>
                                </>
                              ) : null}
                            </div>
                          </div>
                          <Modal
                            show={modalId === `modal${i}`}
                            // onHide={handleClose}
                            onHide={() => setModalId("")}
                          >
                            <Modal.Header>
                              <Modal.Title>
                                Change {p.firstname} {p.lastname} Password{" "}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Formik
                                initialValues={{
                                  newPassword: "",
                                  confirmPassword: "",
                                  email: p.email,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) =>
                                  UpdatePasswordHandler(values)
                                }
                              >
                                {({
                                  values,
                                  handleSubmit,
                                  handleChange,
                                  errors,
                                  touched,
                                  isValid,
                                }) => (
                                  <Form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="inputPasswordNew"
                                      >
                                        New password
                                      </label>
                                      <input
                                        name="newPassword"
                                        type={
                                          show.password ? "text" : "password"
                                        }
                                        placeholder="Enter New password"
                                        className="form-control"
                                        id="inputPasswordNew"
                                        onChange={handleChange}
                                        value={values.newPassword}
                                      />
                                      <span
                                        className="input-group-text cursor-pointer mt-2"
                                        onClick={() =>
                                          setShow({
                                            ...show,
                                            password: !show.password,
                                          })
                                        }
                                        style={{
                                          width: 40,
                                        }}
                                      >
                                        <i
                                          className={`${
                                            show.password
                                              ? "fa fa-eye-slash"
                                              : "fa fa-eye"
                                          }`}
                                          id="togglePassword"
                                        ></i>
                                      </span>
                                      {errors.newPassword &&
                                      touched.newPassword ? (
                                        <div style={{ color: "red" }}>
                                          {errors.newPassword}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="inputPasswordNew2"
                                      >
                                        Verify password
                                      </label>
                                      <input
                                        name="confirmPassword"
                                        type={
                                          show.confirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        onChange={handleChange}
                                        placeholder="Enter New password Again"
                                        className="form-control"
                                        id="inputPasswordNew2"
                                      />
                                      <span
                                        className="input-group-text cursor-pointer mt-2"
                                        onClick={() =>
                                          setShow({
                                            ...show,
                                            confirmPassword:
                                              !show.confirmPassword,
                                          })
                                        }
                                        style={{
                                          width: 40,
                                        }}
                                      >
                                        <i
                                          className={`${
                                            show.confirmPassword
                                              ? "fa fa-eye-slash"
                                              : "fa fa-eye"
                                          }`}
                                          id="togglePassword"
                                        ></i>
                                      </span>
                                      {errors.confirmPassword &&
                                      touched.confirmPassword ? (
                                        <div style={{ color: "red" }}>
                                          {errors.confirmPassword}
                                        </div>
                                      ) : null}
                                    </div>
                                    <button
                                      id="sends"
                                      type="submit"
                                      className="btn btn-primary"
                                      ref={buttonLoader1}
                                      onClick={handleSubmit}
                                    >
                                      Save changes
                                    </button>
                                  </Form>
                                )}
                              </Formik>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="card-body">
            <h4 className="card-title text-center font-weight-bold">
              No Admistrator Found
            </h4>
          </div>
        )}
      </div>
      {currentEntries?.length > 0 && (
        <Pagination
          onChange={setCurrentPage}
          page={currentEntries}
          total={totalPagesNum}
        />
      )}
    </>
  ) : (
    <Spinner />
  );
};

export default AllUsersTab;
