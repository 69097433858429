import UserFooter from ".";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";

const PaymentMethod = () => {
  document.title = "Kreesa | Payment Method";
  const data = [
    {
      id: 1,
      title: "Advance Payment",
      image: "/advance-payment.jpg",
      content:
        "This type of payment is required for customized orders or Imports from foreign countries.",
      example: [
        {
          id: 1,
          desc: "When you wish to order some furnitures using Image, then you have to initiate payment as advance not less than 50%.",
        },
        {
          id: 2,
          desc: "When your requirement is accepted by foreign supplier and the order is confirmed. You have to pay 100% in advance. Delivery charge, insurance and freight are applicable at the time of Delivery.",
        },
      ],
    },
    {
      id: 2,
      title: "Payment using VISA / MasterCard",
      image: "/visa-master-card.png",
      content:
        "While Checking out from kreesa.com you can pay using your VISA/MASTER debit or credit cards.",
    },
    {
      id: 3,
      title: "Cash / Transfer on delivery",
      image: "/cash-on-delivery.png",
      content:
        "This is the payment method which is most popular and favorable among the customers. In this method you have to pay the amount while receiving the products.You can pay using cash or pay by scanning the QR CODE of Kreesa Sales Private Limited.",
    },
    {
      id: 4,
      title: "eSewa",
      image: "/esewa.jpg",
      content:
        "While Checking out from kreesa.com you can pay using your e-Sewa account.",
    },
    {
      id: 5,
      title: "connectIps",
      image: "/connectIPS.png",
      content:
        "While Checking out from kreesa.com you can pay using your ConnectIPS account.",
    },
    {
      id: 6,
      title: "Khalti",
      image: "/Khalti-collaborations.png",
      content:
        "While Checking out from kreesa.com you can pay using your khalti account.",
    },
  ];
  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}

      <div className="container mt-6 mb-2">
        <h2 style={{ color: "#3b7ddd" }}>
          Available Payment Methods on Kreesa.com
        </h2>
        {data.map((i) => (
          <div className="mt-5" key={i.id}>
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-6">
                <h3 className="mb-3">{i.title}</h3>
                <p style={{ textAlign: "justify", fontSize: "18px" }}>
                  {i.content}
                </p>
                {i.example && (
                  <ol>
                    {i.example.map((j) => (
                      <li
                        style={{
                          textAlign: "justify",
                          fontSize: "18px",
                          marginBottom: "15px",
                        }}
                      >
                        {j.desc}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
              <div className="col-12 col-md-6 text-center">
                <img
                  src={`/images${i.image}`}
                  className="rounded"
                  style={{
                    height: i.title === "Khalti" && 130,
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default PaymentMethod;
