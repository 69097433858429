import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { getCategories } from "../../../../Common/Features/Action/CategoryAction/CategoryAction";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import Spinner from "../../Spinner";
import TopNavbar from "../../TopNavbar";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../../ButtonLoaderHook";
import GroupProductValidation from "../../../Validation/GroupProductValidation";

const AddGroupProduct = () => {
  const [groupName, setGroupName] = useState("");
  const [primaryCategoryId, setPrimaryCategoryId] = useState("");
  const [groupSKU, setProductSKU] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [ternaryCategoryId, setTernaryCategoryId] = useState("");
  const [selectSecondary, SetSelectSecondary] = useState([]);
  const [selectTernary, SetSelectTernary] = useState([]);

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add Group Product",
    "Loading..."
  );
  const history = useHistory();
  document.title = "Admin | Add Group Product";

  const addGroupProductHandler = async (e) => {
    setButtonLoader(true);
    try {
      e.preventDefault();
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.post(
        "/kreesa/admin/group/add",
        {
          groupName,
          primaryCategoryId,
          secondaryCategoryId,
          ternaryCategoryId,
          groupSKU,
        },
        config
      );
      if (res) {
        toast.success("New Group Product added succesfully !");
        setButtonLoader(false);
        history.push("/admin/group-product/view");
      }
    } catch (err) {
      console.log(err + "error found");
      // setMessage('Filed to add New category !')
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };

  // to get all Categories(Primary, Secondary, Ternary)
  const { Categories, loader } = useSelector((s) => s.Categories);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  GroupProductValidation();
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          {!loader ? (
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title font-weight-bold">
                    Add Group Product Form
                  </h5>
                  <h6 className="card-subtitle text-muted">
                    You Can Easily Add New Group Product.
                  </h6>
                </div>
                <div className="card-body">
                  <form id="groupadd-forms">
                    <div className="col">
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Group Product Name
                            <span className="text-danger"> *</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          name="GroupName"
                          id="inputCity"
                          onChange={(e) => setGroupName(e.target.value)}
                          placeholder="Enter Group Product Name"
                        />
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Group Product SKU
                            <span className="text-info"> (Optional)</span>
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="GroupSKU"
                          onChange={(e) => setProductSKU(e.target.value)}
                          placeholder="Enter Group Product SKU"
                        />
                      </div>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label
                        className="form-label font-weight-bold"
                        htmlFor="inputState"
                      >
                        Primary Category <span className="text-danger">*</span>
                      </label>
                      <select
                        id="inputState"
                        required
                        name="role"
                        onChange={(e) => {
                          setPrimaryCategoryId(e.target.value);
                          SetSelectSecondary(e.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="">
                          Select Primary Category First...
                        </option>

                        {/* to get all Primary Categories */}
                        {Categories.primary?.map((p, i) => (
                          <option key={i} value={p.id}>
                            {p.categoryName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label
                        className="form-label font-weight-bold"
                        htmlFor="inputState"
                      >
                        Secondary Category{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        id="inputState1"
                        required
                        onChange={(e) => {
                          setSecondaryCategoryId(e.target.value);
                          SetSelectTernary(e.target.value);
                        }}
                        name="role2"
                        className="form-control"
                      >
                        <option value="">
                          Select Secondary Category First...
                        </option>

                        {/* to get all Secondary Categories */}
                        {Categories.secondary?.map(
                          (p, i) =>
                            selectSecondary == p.primaryCategoryId && (
                              <option key={i} value={p.id}>
                                {p.secondaryCategoryName}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label
                        className="form-label font-weight-bold"
                        htmlFor="inputState"
                      >
                        Ternary Category
                      </label>
                      <select
                        id="inputState2"
                        onChange={(e) => {
                          setTernaryCategoryId(e.target.value);
                        }}
                        // name="role3"
                        className="form-control"
                      >
                        <option value="">
                          Select Ternary Category First...
                        </option>
                        {/* to get all Ternary Categories */}
                        {Categories.ternary?.map(
                          (p, i) =>
                            selectTernary == p.secondaryCategoryId && (
                              <option key={i} value={p.id}>
                                {p.ternaryCategoryName}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                    {/*  */}
                    <div className="mb-3 mt-4 col-md-4">
                      <button
                        id="gadd"
                        type="submit"
                        className="btn btn-success btn-lg btn-block"
                        ref={buttonLoader}
                        onClick={addGroupProductHandler}
                      >
                        Add Group Product
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </main>

        <Footer />
      </div>
    </div>
  );
};

export default AddGroupProduct;
