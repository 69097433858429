import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Pagination } from "@mantine/core";
import { getAllAssignedDelivery } from "../../Features/Action/QuotationAction";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import { filterAssignedQuote } from "../../Features/Reducer/QuotationsReducer";
import moment from "moment";
import { getAllDeliveryBoy } from "../../Features/Action/OrderStatusAction";
import Swal from "sweetalert2";

const AllAssignedOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const history = useHistory();

  const dispatch = useDispatch();
  const inputRef = useRef("");

  const { assignedDelivery, loader } = useSelector((s) => s.QuotationsReducer);

  useEffect(() => {
    // getOrders();
    dispatch(getAllAssignedDelivery());
    dispatch(getAllDeliveryBoy());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = assignedDelivery?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(assignedDelivery?.length / perPage);

  const filter = () => {
    dispatch(filterAssignedQuote(inputRef.current.value));
  };

  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Orders…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">All Assigned Delivery</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th className="d-xl-table-cell">Order Number</th>
                    <th>Contact </th>
                    <th className="d-xl-table-cell">Order Date</th>
                    <th className="d-md-table-cell">Shipping Address</th>
                    <th className="d-md-table-cell">Total Payment</th>
                    <th className="d-md-table-cell">Status</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td className="d-xl-table-cell">{p.orderNo}</td>
                      <td>{p.contactNo}</td>
                      <td className="d-xl-table-cell">
                        {moment(p.orderDate).format("ll")}
                      </td>

                      <td className="d-md-table-cell">{p.shippingAddress}</td>

                      <td className="d-md-table-cell">Rs. {p?.totalCost}</td>
                      <td className="d-md-table-cell">{p?.deliveryFlag}</td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            {/* <Link
                              to={`/admin/order/view/wholesale/${p.id}`}
                            > */}
                            <div
                              style={{ cursor: "pointer" }}
                              className="dropdown-item text-info"
                              onClick={() =>
                                history.push({
                                  pathname: `/admin/order/view/wholesale/${p.id}`,
                                  state: "delivery-detail",
                                })
                              }
                            >
                              <i className="fas fa-eye"></i> View More
                            </div>
                            <>
                              {p.deliveryStatus !== null && (
                                <>
                                  <div className="dropdown-divider"></div>
                                  <div
                                    className="dropdown-item cursor-pointer text-primary"
                                    onClick={() => {
                                      AxiosConfig.get(
                                        `/kreesa/admin/get-user-by-user-id/${p.deliveryBoyId}`
                                      )
                                        .then(({ data }) => {
                                          Swal.fire({
                                            titleText: "Delivery Boy Details",
                                            confirmButtonColor: "#0d6efd",
                                            html: `<div style="text-align:start"><h5>Name :  ${data.firstname} ${data.lastname}</h5></br><h5>Address : ${data.districtName}, ${data.provienceName}, ${data.countryName}</h5></br><h5>Email :  ${data.emailId}</h5></br><h5>Contact :  ${data.contact}</h5></div>`,
                                          });
                                        })
                                        .catch((err) =>
                                          toast.error("An Error Occurred ")
                                        );
                                    }}
                                  >
                                    <i className="fas fa-user"></i> Delivery
                                    Details
                                  </div>
                                </>
                              )}
                            </>
                            {/* </Link> */}
                            {/* {p.delivery && (
                              <>
                                <div className="dropdown-divider"></div>
                                <div
                                  className="dropdown-item cursor-pointer text-success"
                                  onClick={() =>
                                    toast.info("Already Delivered")
                                  }
                                >
                                  <i className="fa fa-check"></i> Delivered
                                </div>
                              </>
                            )} */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
          {/* <Modal
            modal={modal}
            setModal={setModal}
            data={deliveryBoys}
            orderId={orderId}
          /> */}
        </div>
      ) : (
        <div className="p-2 text-center">
          <h3>No Data Found</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default AllAssignedOrders;
