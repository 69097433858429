import UserFooter from ".";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getLegalDocsUser } from "../Features/action";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import Spinner from "../../Admin/Spinner";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  document.title = "Kreesa | Return Policy";
  const { legalDoc, loader } = useSelector((state) => state.userProducts);

  useEffect(() => {
    dispatch(getLegalDocsUser("privacy-policy"));
  }, []);

  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      {loader ? (
        <Spinner />
      ) : (
        <div className="container mt-6 mb-4 user-select-none">
          <h2 className="mb-3">{legalDoc.title}</h2>
          {ReactHtmlParser(legalDoc.description)}
        </div>
      )}
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default PrivacyPolicy;
