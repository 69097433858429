import { createSlice } from "@reduxjs/toolkit";
import {
  getWholesalerProducts,
  viewAllCart,
  postSingleWholesalerCart,
  removeCartItem,
  updateCartItem,
  searchItem,
  homePageSearch,
  getUserQuotation,
  approveSalerQuotation,
  postActualPrice,
  getAssignedQuotation,
  rejectSalerQuotation,
  confirmDeliveryFromSupplier,
  WholesaleFeatureProduct,
} from "../../Action/WholesalerAction";

const WholesalerProducts = createSlice({
  name: "WholesalerProducts",
  initialState: {
    wholesalerProducts: [],
    loader: false,
    errors: {},
    cartDetail: [],
    searchItem: [],
    myQuotation: [],
    userQuotation: [],
    assignedQuotation: [],
    wholesaleFeature: [],
  },
  reducers: {},
  extraReducers: {
    /* to get all Wholesaler Products in User Page*/
    [getWholesalerProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getWholesalerProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.wholesalerProducts = action.payload;
    },
    [getWholesalerProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [postSingleWholesalerCart.pending]: (state, action) => {
      state.loader = true;
    },
    [postSingleWholesalerCart.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [postSingleWholesalerCart.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [viewAllCart.pending]: (state, action) => {
      state.loader = true;
    },
    [viewAllCart.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.cartDetail = payload;
    },
    [viewAllCart.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [removeCartItem.pending]: (state, action) => {
      state.loader = true;
    },
    [removeCartItem.fulfilled]: (state, { payload }) => {
      state.loader = false;
      // state.cartDetail = payload;
    },
    [removeCartItem.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [updateCartItem.pending]: (state, action) => {
      state.loader = true;
    },
    [updateCartItem.fulfilled]: (state, { payload }) => {
      state.loader = true;
      // state.cartDetail = payload;
    },
    [updateCartItem.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [searchItem.pending]: (state, action) => {
      state.loader = true;
    },
    [searchItem.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.searchItem = payload;
    },
    [searchItem.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [homePageSearch.fulfilled]: (state, action) => {
      state.loader = false;
      state.searchItem = action.payload;
    },

    [getUserQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [getUserQuotation.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.myQuotation = payload;
    },
    [getUserQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [approveSalerQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [approveSalerQuotation.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.userQuotation = payload;
    },
    [approveSalerQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [rejectSalerQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [rejectSalerQuotation.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.userQuotation = payload;
    },
    [rejectSalerQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getAssignedQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [getAssignedQuotation.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.assignedQuotation = payload;
    },
    [getAssignedQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [postActualPrice.pending]: (state, action) => {
      state.loader = true;
    },
    [postActualPrice.fulfilled]: (state, { payload }) => {
      state.loader = false;
    },
    [postActualPrice.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [confirmDeliveryFromSupplier.pending]: (state, action) => {
      state.loader = true;
    },
    [confirmDeliveryFromSupplier.fulfilled]: (state, { payload }) => {
      state.loader = false;
    },
    [confirmDeliveryFromSupplier.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // get all feature product for wholesale
    [WholesaleFeatureProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [WholesaleFeatureProduct.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.wholesaleFeature = payload;
    },
    [WholesaleFeatureProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export default
export default WholesalerProducts.reducer;
