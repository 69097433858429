import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Flag from "../../../assets/Images/flag.png";
import { FooterTopSection } from "../../User/UserFooter";

const UserFooter = () => {
  return (
    <div>
      {/* <footer className="bg-light text-center text-lg-start footer col-sm-12 col-md-12 col-lg-12">
        <div className="main-container col-sm-12 col-md-12 col-lg-12">
          <form action="">
            <div className="row" id="row">
              <div className="col-6 mb-4 mb-md-0 socialbtn">
                <div className="social">
                  <a
                    className="bt"
                    href="https://www.facebook.com/adminkreesha"
                    target="_blank"
                    role="button"
                  >
                    <i className="fab fa-facebook-f fabs"></i>
                  </a>
                  <a
                    className="bt"
                    href="https://twitter.com/kreesa_sales"
                    target="_blank"
                    role="button"
                  >
                    <i className="fab fa-twitter fabs"></i>
                  </a>
                  <a
                    className="bt"
                    href="https://www.instagram.com/kreesa.sales/"
                    target="_blank"
                    role="button"
                  >
                    <i className="fab fa-instagram fabs"></i>
                  </a>
                   <a className="bt" href="https://vm.tiktok.com/ZSJ7TfWA4" target role="button"><i className="fab fa-tiktok"></i></a> 
                </div>
              </div>
              <div className="col-md-6 col5 col-8 mb-4 mb-md-0 d-flex">
                <div className="col-2" >
                  <p className="pt-2 para">
                    <strong>Sign up for our newsletter</strong>
                  </p>
                </div>

                <div className=" col-4  d-flex ">
                  <input
                    type="email"
                    id="form5Example2"
                    className="form-control form1 mt-2"
                    placeholder="Enter your email"
                  />
                   <button type="submit" className="btn mb-4 fbtn ml-2 mt-2">
                    Subscribe
                  </button>
                </div>
               
              </div>
            </div>
          </form>
        </div>

        <div className="row" id="bottom">
          <div className="row-id">
            <div className="col" id="firstbottom">
              <h6 className="text-uppercase font-weight-bold h6">Contact Us</h6>

              <p id="p">
                {" "}
                <i className="fas fass fa-home"></i> Nagarjun -3 Diamondmarg Kathmandu, Nepal
              </p>
              <p id="p">
                {" "}
                <i className="fas fass fa-envelope "></i> kreesasales@gmail.com
              </p>
              <p id="p">
                {" "}
                <i className="fas fass fa-phone"></i> +977 1-5249582, 9851325737, 9813007298
              </p>
            </div>
          </div>
          <div className="col" id="btnf">
            <h5 className="text-uppercase mb-0">ABOUT MARKET</h5>

            <ul className="list-unstyled" id="list">
              <li>
                <a href="#!" className="">
                  {" "}
                  About Us
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Market Reviews
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Site Map
                </a>
              </li>
            </ul>
          </div>
          <div className="col" id="btnf">
            <h5 className="text-uppercase mb-0">CUSTOMER SERVICE</h5>

            <ul className="list-unstyled" id="list">
              <li>
                <a href="#!" className="">
                  Shipping Policy
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Compensation First
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  My Account
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Return Policy
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col" id="btnf">
            <h5 className="text-uppercase mb-0">PAYMENT & SHIPPING</h5>

            <ul className="list-unstyled" id="list">
              <li>
                <a href="#!" className="">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Payment Methods
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Shipping Guide
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Locations We Ship To
                </a>
              </li>
              <li>
                <a href="#!" className="">
                  Estimated Delivery Time
                </a>
              </li>
            </ul>
          </div>
        </div>

         <div className="text-center p-3">
  SM Market © 2021 Demo Store. All Rights Reserved. Designed by
    <a className="text-danger" href="" ></a>
  </div> 
      </footer>*/}

      <footer
        className="text-center text-lg-start text-white user__footer"
        id="home__footer"
      >
        <FooterTopSection />
        <section
          className="d-flex justify-content-between p-4 f-section"
          style={{ backgroundColor: "#319FFB" }}
        >
          <div
            className="me-5 get-touch"
            style={{
              verticalAlign: "-webkit-baseline-middle",
              fontSize: "x-large",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            <span className="text-capitalize p-4">
              Get connected with us on social networks{" "}
            </span>
          </div>
          <div className="">
            <a href="https://www.facebook.com/adminkreesa" target="_blank">
              <i
                className="fab fa-facebook footer-social"
                style={{ fontSize: "20px" }}
              ></i>
            </a>
            <a href="https://twitter.com/kreesa_sales" target="_blank">
              <i
                style={{ fontSize: "20px" }}
                className="fab fa-twitter footer-social"
              ></i>
            </a>
            <a href="https://www.instagram.com/kreesa_com" target="_blank">
              <i
                style={{ fontSize: "20px" }}
                className="fab fa-instagram  footer-social"
              ></i>
            </a>
            <a href="viber://chat?number=+9779813007298" target="_blank">
              <i
                style={{ fontSize: "20px" }}
                className="fab fa-viber  footer-social"
              ></i>
            </a>
            <a
              // href="https://web.whatsapp.com/send?phone=+9779813007298"
              href="https://wa.me/+9779813007298"
              target="_blank"
            >
              <i
                style={{ fontSize: "20px" }}
                className="fab fa-whatsapp  footer-social"
              ></i>
            </a>
            {/* <a href="https://vm.tiktok.com/ZSJ7TfWA4" target="_blank">
                <i style={{fontSize:"20px",color:"red"}} className="fab fa-tiktok fabs"></i>
              </a> */}
          </div>
        </section>

        <section>
          <div className="container-fluid text-center text-md-start mt-5">
            <div className="row">
              <div className="col-md-3 mt-4 col-lg-4 col-xl-3 mx-auto mb-4 footer-heading">
                <h6 className="text-uppercase  ">PAYMENT & SHIPPING</h6>
                <hr className="mb-4 mt-0 mx-auto" />
                <p className="fp">
                  <Link to="/terms-of-use" className="anchor-hover">
                    Terms of Use
                  </Link>
                </p>
                <p>
                  <a href="/payment-methods" className="anchor-hover">
                    Payment Methods
                  </a>
                </p>
                <p>
                  <a href="/shipping-guide" className="anchor-hover">
                    Shipping Guide
                  </a>
                </p>
                {/* <p>
                  <a href="#!" className="anchor-hover">
                    Locations We Ship To
                  </a>
                </p> */}
                <p>
                  <a href="/seller-page" className="anchor-hover">
                    Seller Page
                  </a>
                </p>
                <p>
                  <a href="/selling-guide" className="anchor-hover">
                    Online Selling Guide
                  </a>
                </p>
              </div>

              <div className="col-md-2 mt-4 col-lg-2 col-xl-2 mx-auto mb-4 footer-heading">
                <h6 className="text-uppercase  ">CUSTOMER SERVICE</h6>
                <hr className="mb-4 mt-0 mx-auto" />
                {/* <p>
                  <a href="#!" className="anchor-hover">
                    Shipping Policy
                  </a>
                </p> */}
                {/* <p>
                  <a href="#!" className="anchor-hover">
                    Compensation First
                  </a>
                </p> */}
                <p>
                  <a
                    href={
                      localStorage.getItem("auth") === "ROLE_WHOLESALER"
                        ? "/wholesaler"
                        : "/retailer"
                    }
                    className="anchor-hover"
                  >
                    My Account
                  </a>
                </p>
                <p>
                  <a href="/return-policy" className="anchor-hover">
                    Return Policy
                  </a>
                </p>
                <p>
                  <Link to="/contact-us" className="anchor-hover">
                    Contact Us
                  </Link>
                </p>
                <p>
                  <Link to="/kreesa-teaches" className="anchor-hover">
                    Kreesa Teaches (FAQ)
                  </Link>
                </p>
              </div>
              <div className="col-md-3 mt-4 col-lg-2 col-xl-2 mx-auto mb-4 footer-heading">
                <h6 className="text-uppercase  ">ABOUT MARKET</h6>
                <hr className="mb-4 mt-0 mx-auto" />
                <p>
                  <Link to="/about" className="anchor-hover">
                    About Us
                  </Link>
                </p>
                <p>
                  <a href="/complaint" className="anchor-hover">
                    Kreesa Complaint
                  </a>
                </p>
                <p>
                  <Link
                    to="/terms-conditions"
                    target="_blank"
                    className="anchor-hover"
                  >
                    Terms and Conditions
                  </Link>
                </p>
                <p>
                  <a href="/privacy-policy" className="anchor-hover">
                    Privacy Policy
                  </a>
                </p>
                <p>
                  <a href="#!" className="anchor-hover">
                    Site Map
                  </a>
                </p>
              </div>
              <div className="col-md-4 mt-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 footer-heading">
                <h6 className="text-uppercase">Contact</h6>
                <hr className="mb-4 mt-0 mx-auto" />
                <p>
                  <i className="fas fa-home mr-3"></i>Diamondmarg 3, Panchdhara,
                  Kathmandu 44620
                </p>
                <p>
                  <i className="fas fa-envelope mr-3"></i>{" "}
                  <a href="mailto:kreesasales@gmail.com">
                    {" "}
                    kreesasales@gmail.com{" "}
                  </a>
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i>
                  <a href="tel:+977 1-5249582"> +977 1-5249582,</a>
                  <a href="tel:9851325737"> 9851325737,</a>
                  <a href="tel:9813007298"> 9813007298</a>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © {new Date().getFullYear()}{" "}
          <img
            src={Flag}
            alt="flag"
            style={{
              height: 20,
              width: 20,
            }}
          />{" "}
          Made in Nepal | Copyright:
          <a className="text-white" href="">
            &nbsp; Kreesa
          </a>
        </div>
      </footer>
    </div>
  );
};

export default UserFooter;
