import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../../Components/Retailer";
import RetailerShowProduct from "../../Components/Retailer/Product/ShowProduct";
import RetailerAddProduct from "../../Components/Retailer/Product/AddProduct";
import RetailerEditProduct from "../../Components/Retailer/Product/EditProduct";
import Profile from "../../Components/Retailer/Profile";
import Settings from "../../Components/Retailer/Settings";
import ShowOrders from "../../Components/Retailer/ShowOrders";
import MyOrder from "../../Components/Retailer/MyOrder";
import SingleOrderDetail from "../../Components/Retailer/MyOrder/SingleOrderDetail";
import SingleRetailOrder from "../../Components/Retailer/ShowOrders/SingleOrderDetail";
import ShowCustomOrder from "../../Components/Retailer/CustomOrder/ShowCustomOrder";
import AddCustomOrder from "../../Components/Retailer/CustomOrder/AddCustomOrder";
import SalerRouting from "../PrivateRoute/salerRouting";
import EditCustomOrder from "../../Components/Retailer/CustomOrder/EditCustomOrder";
import RetailerReviewById from "../../Components/Retailer/Product/ShowProduct/RetailerReviewById";
import Stock from "../../Components/Retailer/Stock";
import ReviewSingleStock from "../../Components/Retailer/Stock/ReviewSingleStock";
import Message from "../../Components/Retailer/Message";
import MyQuotationPage from "../../Components/Retailer/MyQuotation";
import AssignedQuotes from "../../Components/Retailer/AssignedQuotation";
import YourReturnOrder from "../../Components/Retailer/ReturnOrders";
import Whishlist from "../../Components/Retailer/WishList";
import MyReviews from "../../Components/Retailer/Review";
import Checkout from "../../Components/User/Checkout";
import PayNow from "../../Components/User/PayNow";
import Notifications from "../../Components/Retailer/Notifications";
import MyPlatnedTree from "../../Components/Retailer/Tree";

const RetailorsRoute = () => {
  return (
    <Switch>
      {/* Retailer Route start*/}
      <SalerRouting exact path="/retailer" component={Home} />
      <SalerRouting
        exact
        path="/retailer/quotation-order"
        component={AssignedQuotes}
      />

      {/* Product */}
      <SalerRouting
        exact
        path="/retailer/product/view"
        component={RetailerShowProduct}
      />
      <SalerRouting
        exact
        path="/retailer/product/register"
        component={RetailerAddProduct}
      />
      <SalerRouting
        exact
        path="/retailer/all-notification"
        component={Notifications}
      />
      <SalerRouting
        exact
        path="/saller/edit-product/:id"
        component={RetailerEditProduct}
      />
      <SalerRouting
        exact
        path="/retailer/product/single-view/:id"
        component={RetailerReviewById}
      />

      {/* Retailer Profile Settings */}
      <SalerRouting
        exact
        path="/retailer/profile-settings"
        component={Settings}
      />

      <SalerRouting
        exact
        path="/retailer/quotation"
        component={MyQuotationPage}
      />
      <SalerRouting
        exact
        path="/retailer/quotation/:id"
        component={MyQuotationPage}
      />

      <SalerRouting exact path="/retailer/profile" component={Profile} />

      {/* /retailer/orders/view */}
      <SalerRouting exact path="/retailer/orders/view" component={ShowOrders} />

      {/* Retailor Custom Order */}
      <SalerRouting
        exact
        path="/retailer/custom-order"
        component={ShowCustomOrder}
      />
      <SalerRouting
        exact
        path="/retailer/edit-custom-order/:customId"
        component={EditCustomOrder}
      />
      <SalerRouting
        exact
        path="/retailer/custom-order/register/:type"
        component={AddCustomOrder}
      />

      <SalerRouting exact path="/retailer/my-orders" component={MyOrder} />
      <SalerRouting
        exact
        path="/retailer/my-return-orders"
        component={YourReturnOrder}
      />

      <SalerRouting exact path="/retailer/pay-now" component={PayNow} />
      <SalerRouting
        exact
        path="/retailer/my-orders/:id"
        component={SingleOrderDetail}
      />

      <SalerRouting exact path="/retailer/my-review" component={MyReviews} />
      <SalerRouting
        exact
        path="/retailer/orders/:id"
        component={SingleRetailOrder}
      />
      {/* Stock  */}
      <SalerRouting exact path="/retailer/stock" component={Stock} />
      <SalerRouting
        exact
        path="/retailer/singlepage-stock/:stockId"
        component={ReviewSingleStock}
      />
      <SalerRouting exact path="/retailer/message" component={Message} />
      <SalerRouting exact path="/retailer/wishlist" component={Whishlist} />

      {/* Tree Plantation */}

      <SalerRouting exact path="/retailer/tree/my-planted-tree" component={MyPlatnedTree} />
  
      {/* Retailer Route End*/}
    </Switch>
  );
};

export default RetailorsRoute;
