import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import moment from "moment";
import Spinner from "../../Admin/Spinner";
import FileSaver from "file-saver";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import Review from "../ReviewModal";
import Pagination from "../../Pagination";
import Search from "../Search/Search";
import Navbar from "../NavBar/Navbar";
import Swal from "sweetalert2";

const SingleOrderDetailUser = () => {
  const { id } = useParams();
  const [state, setstate] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  const [returnModal, setReturnModal] = useState(false);
  const [returnLoading, setReturnLoading] = useState(false);
  const [returnReason, setReturnReason] = useState(null);
  const [ratingModal, setRatingModal] = useState(false);
  const [orderId, setOrderId] = useState();

  // get single order from API

  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };

  const getSingleOrder = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetMyOrderDetails = await AxiosConfig.get(
        `/kreesa/retailer/order/view/${id}`,
        config
      );
      setstate(GetMyOrderDetails.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleOrder();
  }, []);

  return !isLoaded ? (
    <div>
      <Search />

      <Navbar />
      <div className="container-fluid"></div>
      <div className="text-capitalize">
        <div className="card mx-lg-4 p-2">
          <h5 className="font-weight-bold">Order Details</h5>
        </div>
        <div className="card mx-lg-4 mt-4">
          <div className="p-1">
            <p className="ml-2">
              Order Id:{" "}
              <span className="text-muted">
                {state.orderAndShippingDetails?.orderNo}
              </span>
            </p>
            <p className="card-text ml-2">
              Order Date:{" "}
              <span className="text-muted">
                {moment(state.orderAndShippingDetails?.orderDate).format("lll")}
              </span>
            </p>
            <p className="card-text ml-2">
              Order Type:{" "}
              <span className="text-muted font-weight-bold">
                {state.orderAndShippingDetails?.orderType}
              </span>
            </p>
          </div>
        </div>
        {state?.orderDetails?.map((p, index) => (
          <div className="card mx-lg-4 mt-4" key={index}>
            <div className="">
              <h6 className="h6 ml-4 mt-1 pt-1">Sold by kreesa</h6>
              <hr className="text-success mx-4" />
            </div>
            {/*  ------------------------------- main image -------------------------------  */}
            <div className="row g-0">
              {p.image?.map((img, i) =>
                i === 0 ? (
                  <div className="col-md-3 ml-4">
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                      className="img-fluid m-2 rounded-start"
                      style={{
                        width: "125px",
                        height: "125px",
                        borderRadius: "50%",
                      }}
                      alt={img.image}
                    />
                  </div>
                ) : (
                  ""
                )
              )}
              <div className="col-md-3">
                <div className="card-body">
                  <p className="h5 font-weight-bold">
                    {p.productDetails.productName}
                  </p>
                  {/* <p className="card-text">this isi</p>  */}
                  <p className="card-text">
                    <small className="badge bg-info">
                      {p.order?.selectedColor}
                    </small>
                  </p>
                  {p.order.discount === null ? (
                    <p className="card-text">
                      <small className="text-warning h4">
                        Rs. {p.order?.productPrice}
                      </small>
                    </p>
                  ) : (
                    <p className="card-text">
                      <s className="text-muted">Rs. {p.order?.productPrice}</s>
                      <small className="ml-2 text-warning font-weight-bold h4">
                        Rs{" "}
                        {p.order?.productPrice -
                          (p.order?.productPrice * p.order?.discount) / 100}
                      </small>
                      <small className="ml-2 h5">
                        {" "}
                        - {p.order?.discount} % OFF
                      </small>
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-1 m-4">
                <p className="card-text">
                  <small className="badge bg-danger">
                    {p.order?.statusFromBuyer}
                  </small>
                </p>
                <p className="card-text font-weight-bold">
                  Height: {p.productDetails?.hight}
                </p>
                <p className="card-text font-weight-bold">
                  Weight: {p.productDetails?.weight}
                </p>
                <p className="card-text font-weight-bold">
                  Size: {p.productDetails?.size}
                </p>
              </div>
              <div className="col-md-1 font-weight-bold m-4">
                Qty:{p.order.orderQuantity}
              </div>
              {p.order.statusFromBuyer !== "Rejected" && (
                <div className="col-md-2 m-2">
                  <div className="btn-group">
                    <button
                      type="button"
                      style={{
                        textTransform: "capitalize",
                        backgroundColor: "#3b7ddd",
                      }}
                      className="btn-md btn btn-success dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Actions
                    </button>

                    <div className="dropdown-menu">
                      <>
                        <div
                          className="dropdown-item text-info cursor-pointer ml-1"
                          onClick={() => {
                            setRatingModal(true);
                            setOrderId(p.order?.specificationId);
                          }}
                        >
                          <i className="fas fa-comments mr-2"></i>Give Rating
                        </div>
                        <div className="dropdown-divider" />
                      </>
                      <div
                        className="text-danger cursor-pointer align-items-center justify-content-center ml-4"
                        onClick={() => {
                          Swal.fire({
                            title: "Enter Return Reason",
                            input: "text",
                            inputAttributes: {
                              autocapitalize: "off",
                            },
                            showCancelButton: true,
                            confirmButtonText: "Confirm",
                            showLoaderOnConfirm: true,
                            preConfirm: (reason) => {
                              AxiosConfig.post(
                                `/kreesa/retailer/return-order`,
                                {
                                  orderDetailId: p?.order?.id,
                                  reason,
                                  userId: localStorage.getItem("userId"),
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                      "token"
                                    )}`,
                                  },
                                }
                              )
                                .then((res) => {
                                  toast.info(res.data);
                                })
                                .catch((err) =>
                                  toast.error("An Error Occurred")
                                );
                            },
                            allowOutsideClick: () => !Swal.isLoading(),
                          }).then((result) => {});
                        }}
                      >
                        <i className="fas fa-close mr-2"></i>Return Order
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <Modal
                open={returnModal}
                onClose={() => setReturnModal(false)}
                center
                classNames={{
                  overlayAnimationIn: "customEnterOverlayAnimation",
                  overlayAnimationOut: "customLeaveOverlayAnimation",
                  modalAnimationIn: "customEnterModalAnimation",
                  modalAnimationOut: "customLeaveModalAnimation",
                }}
                animationDuration={500}
              >
                <div className="container">
                  <div className="pt-4 pb-4">
                    <div className="form-group">
                      <label for="return-order">
                        Please provide reason for your return order.
                      </label>
                      <textarea
                        rows={3}
                        className="form-control form-control-lg mt-2"
                        id="return-order"
                        placeholder="Enter reason here"
                        onChange={(e) => setReturnReason(e.target.value)}
                      />
                    </div>
                    <button
                      disabled={returnLoading}
                      className="btn btn-primary mt-3"
                      style={{ float: "right" }}
                      onClick={() => handleReturnOrder(p)}
                    >
                      {returnLoading ? "Loading . . . " : "Submit"}
                    </button>
                  </div>
                </div>
              </Modal> */}

              <div className="row m-4">
                {p.image?.map((img, i) => (
                  <div className="col-md-2" key={i}>
                    <div
                      onClick={() => handleOpenModal(img.imageId)}
                      className="d-flex cursor-pointer"
                    >
                      <img
                        className="img-fluid img-thumbnail m-1"
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                        height={100}
                        width={120}
                        alt={img.image}
                      />
                    </div>
                    {/*Modal  */}
                    {modal.show && modal.requestedModalId == img.imageId && (
                      <Modal
                        open={modal}
                        onClose={handleCloseModal}
                        center
                        classNames={{
                          overlayAnimationIn: "customEnterOverlayAnimation",
                          overlayAnimationOut: "customLeaveOverlayAnimation",
                          modalAnimationIn: "customEnterModalAnimation",
                          modalAnimationOut: "customLeaveModalAnimation",
                        }}
                        animationDuration={1000}
                      >
                        <div className="pro">
                          <img
                            className="img-fluid img-thumbnail"
                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                            height={650}
                            width={700}
                            alt={img.image}
                          />
                        </div>
                      </Modal>
                    )}
                  </div>
                ))}
              </div>
              {/*  ------------------------------- model end ------------------------------  */}
            </div>
          </div>
        ))}
        <Review modal={ratingModal} setModal={setRatingModal} data={orderId} />

        <div className="card mx-lg-4 mt-4">
          <div className="row">
            <div className="col-6 ml-5 mt-4 col-md-3">
              {/*<h6 className="h6 ml-4 mt-1">Billing Address</h6>
            <hr className="text-info" />
            <p className="ml-2">Name</p>
            <i className="ml-2">{state.shippingAddress}</i>
            <p className="ml-2">{state.contactNo}</p>*/}
            </div>
            <div className="col-6 ml-5 mt-4 col-md-3">
              <h6 className="h6 ml-4 mt-1">Shipping Address</h6>
              <hr className="text-info" />
              <p className="ml-2">Name</p>
              <i className="ml-2">
                {state.orderAndShippingDetails?.shippingState} -{" "}
                {state.orderAndShippingDetails?.shippingAddress} -{" "}
                {state.orderAndShippingDetails?.shippingCountry}
              </i>
              <p className="ml-2">{state.orderAndShippingDetails?.contactNo}</p>
            </div>

            <div className="col-6 ml-5 mt-4 col-md-3">
              <ul className="list-group mb-3">
                {state?.orderDetails?.map((p, i) => (
                  <li
                    className="list-group-item d-flex justify-content-between lh-sm"
                    key={i}
                  >
                    <div>
                      <h6 className="my-0">Product name</h6>
                      <small className="text-muted">
                        {p.productDetails.productName}
                      </small>
                    </div>
                    <small className="text-muted">
                      Rs {p.order.productPrice}
                    </small>
                  </li>
                ))}
                <li className="list-group-item d-flex justify-content-between">
                  <span>Shipping Cost</span>
                  <strong>
                    Rs {state.orderAndShippingDetails?.shippingCost}
                  </strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total (Npr)</span>
                  <strong>{state?.orderAndShippingDetails?.totalCost}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default SingleOrderDetailUser;
