import React, { useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../../ButtonLoaderHook";
import CustomeOrderValidation from "../../../Validation/WholsalerValidation/CustomeOrderValidation";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import DatePicker from "react-datepicker";
import moment from "moment";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";

const AddCustomOrder = () => {
  const history = useHistory();
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [estimatedDate, SetEstimatedDate] = useState(new Date());
  const [file, setFile] = useState(null);
  const [state, setState] = useState({ recordState: null });
  const [record, setRecord] = useState();
  // get audio blob
  const [audioBlob, setAudioBlob] = useState();
  // const [customType, setCustomType] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add Custom Order",
    "Loading..."
  );

  document.title = "Wholesaler | Register Custom Order";

  const { type } = useParams();

  const customType = type.toUpperCase();

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  const AddCustomOrderHandler = async (e) => {
    setButtonLoader(true);
    try {
      e.preventDefault();
      let data = new FormData();
      data.append("fileType", customType);
      if (customType === "IMAGE") {
        data.append("file", file);
      }
      if (customType === "AUDIO") {
        data.append("file", record);
      }
      data.append(
        "description",
        draftToHtml(convertToRaw(description.getCurrentContent()))
      );
      data.append("estimatedDate", moment(estimatedDate).format("YYYY-MM-DD"));
      data.append("userId", localStorage.getItem("userId"));
      data.append("orderType", localStorage.getItem("auth"));
      const res = await AxiosConfig.post(
        "/kreesa/wholesaler/register-custom-order",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res) {
        setButtonLoader(false);
        toast.success("Custom " + res.data);
        history.push("/wholesaler/custom-order");
      }
    } catch (err) {
      console.log(err + "error found");
      toast.error("Something Went Wrong");
      setButtonLoader(false);
    }
  };

  const start = (e) => {
    e.preventDefault();
    setState({
      recordState: RecordState.START,
    });
  };

  const stop = (e) => {
    e.preventDefault();
    setState({
      recordState: RecordState.STOP,
    });
  };

  const onStop = (audioData) => {
    setRecord(audioData.blob);
    setAudioBlob(audioData.url);
  };

  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token !== "ROLE_WHOLESALER") {
    return <Redirect exact to="/login" />;
  }
  CustomeOrderValidation();
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title font-weight-bold">
                  Add Custom Order
                </h5>
                <h6 className="card-subtitle text-muted">
                  {" "}
                  You Can Easily Add Custom Order.
                </h6>
              </div>
              <div className="card-body">
                <form id="customer-add">
                  <div className="col">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">
                          Estimated Date{" "}
                        </span>
                        <span className="text-danger"> *</span>
                      </label>
                      <DatePicker
                        className="form-control"
                        value={estimatedDate}
                        selected={estimatedDate}
                        onChange={(date) => SetEstimatedDate(date)}
                      />
                    </div>
                  </div>
                  {/* <div className="col">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">
                          Custom Order Type
                        </span>
                        <span className="text-danger"> *</span>
                      </label>
                      <select
                        name="customType"
                        onChange={(e) => setCustomType(e.target.value)}
                        className="form-select"
                      >
                        <option value="">Select any</option>
                        <option value="IMAGE">Image</option>
                        <option value="AUDIO">Voice</option>
                      </select>
                    </div>
                  </div> */}
                  {customType === "IMAGE" && (
                    <div className="col">
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Custom Order Image
                          </span>
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          required="required"
                          onChange={(e) => setFile(e.target.files[0])}
                          name="file"
                          accept="image/png,image/jpg,image/jpeg"
                          id="inputFile"
                          placeholder="Enter Category Name"
                        />{" "}
                        <small>Accepted Formats: png, jpg & jpeg</small>
                      </div>
                    </div>
                  )}
                  {customType === "AUDIO" && (
                    <div className="col">
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Custom Order Voice
                          </span>
                          <span className="text-danger"> *</span>
                        </label>

                        <AudioReactRecorder
                          state={state.recordState}
                          onStop={onStop}
                          canvasHeight={100}
                        />

                        <div className="btn-group mt-2" role="group">
                          <button
                            type="button"
                            onClick={start}
                            className="btn btn-primary btn-lg"
                          >
                            <i class="fas fa-microphone"></i>
                          </button>
                          <button
                            type="button"
                            onClick={stop}
                            className="btn btn-danger btn-lg"
                          >
                            <i className="fas fa-pause"></i>
                          </button>
                        </div>
                        {audioBlob && (
                          <div className="mt-2">
                            <audio
                              controls
                              src={audioBlob}
                              style={{ height: 35 }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col">
                    <div className="mb-3 col-md-8">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">
                          Custom Order Description
                        </span>
                        <span className="text-info"> (optional)</span>
                      </label>
                      <Editor
                        editorState={description}
                        onEditorStateChange={onEditorStateChange}
                        editorStyle={{
                          backgroundColor: "white",
                          height: "150px",
                          padding: "10px 20px",
                        }}
                        editorClassName="mt-2 p-10 mx-auto"
                      />
                    </div>
                  </div>
                  <div className="mb-3 mt-4 col-md-4">
                    <button
                      type="submit"
                      className="btn btn-success btn-lg btn-block"
                      id="acsubmit"
                      ref={buttonLoader}
                      onClick={AddCustomOrderHandler}
                    >
                      Add Custom Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AddCustomOrder;
