import React, { useState } from "react";
import "./UserLogin.css";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../assets/Images/kreesalogo.png";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import UserLoginValidation from "../../Validation/UserLoginValidation";

const UserLogin = ({ modal = false, modalVisible }) => {
  document.title = "Kreesa | User Login";

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Login",
    "Loading..."
  );

  let history = useHistory();

  const handleSubmit = async (e) => {
    setButtonLoader(true);
    e.preventDefault();
    let res;

    try {
      //api
      res = await AxiosConfig.post("/kreesa/user-login-registration/login", {
        username,
        password,
      });
    } catch (error) {
      toast.error(error.response.data);
      toast.error(error.response.data.message);
      if (error.response && error.response.status === 428) {
        history.push("/activation-link");
        localStorage.setItem("activation", username);
      }
      setButtonLoader(false);
    }

    if (res && res.status === 203) {
      setButtonLoader(false);
      toast.info(res.data);
      return;
    }

    // to check role as well as redirect to userpage or not
    if (res && res.status === 200) {
      setButtonLoader(false);
      if (res.data.role === "ROLE_USER") {
        // set localstorage
        localStorage.setItem("token", res.headers.jwttoken);
        localStorage.setItem("username", username);
        localStorage.setItem("auth", res.data.role);
        localStorage.setItem("userId", res.data.id);
        // redirect to / url
        !modal ? history.push("/") : modalVisible(false);
        // history.push("/");
        history.go(0);
      } else {
        toast.error("Login with user Account !");
      }
    }
  };

  UserLoginValidation();
  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-12  d-flex justify-content-center align-items-center bg-white mt-3">
            <div className=" p-md-5 mx-md-4">
              <div className="text-center">
                <Link to="/">
                  <img className="logo-image mr-3" src={logo} alt="logo" />
                </Link>
              </div>

              <form
                className="uform ml-4"
                onSubmit={handleSubmit}
                id="user-login-form"
              >
                <h4 className="text-center text-capitalize font-weight-bold py-4 ml-2">
                  Please login to your kreesa account
                </h4>

                <div className="input_box ml-4">
                  <input
                    name="email"
                    class="form-control input-w"
                    type="text"
                    placeholder="Email or Phone"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <div className="icon">
                    <i className="fas fa-user mt-2 "></i>
                  </div>
                </div>
                <div className="input_box mt-4 ml-4">
                  <input
                    name="password"
                    class="form-control input-w"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <div className="icon">
                    <i class="fas fa-key"></i>
                  </div>
                </div>

                <div className="mb-3 px-3">
                  <button
                    name="submit"
                    ref={buttonLoader}
                    type="submit"
                    className={`btn btn-blue text-center cbtn mt-4 ${
                      modal ? "ml-4" : "ml-5"
                    }`}
                    id="lbtn"
                  >
                    Login
                  </button>{" "}
                </div>
                <div className="text-center mb-4">
                  <Link to="/user/forgot-password" className="forget-link">
                    Forgot password?
                  </Link>
                </div>
              </form>
              <div className=" mb-4 px-3 ml-4">
                <h6 className="font-weight-bold ml-4">
                  Don't have an account?{" "}
                  <Link to="/usersignup" className="text-danger ">
                    Register from Here
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
