import { Redirect, Route } from "react-router";

const LoginRouting = ({ component: Component, ...rest }) => {
  const auth = localStorage.getItem("auth");

  //roles
  const adminRole = [
    "ROLE_SUPER_ADMIN",
    "ROLE_CEO",
    "ROLE_MANAGER",
    "ROLE_MARKETING_MANAGER",
    "ROLE_RECEPTIONIST",
  ];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth === "ROLE_RETAILER" || auth === "ROLE_WHOLESALER") {
          return (
            <Redirect
              exact
              to={{
                pathname: "/home",
                state: { from: props.location },
              }}
            />
          );
        } else if (adminRole.includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/admin",
                state: { from: props.location },
              }}
            />
          );
        } else if (auth === "ROLE_USER") {
          return (
            <Redirect
              exact
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        } else if (auth === "ROLE_DELIVERY_BOY") {
          return (
            <Redirect
              exact
              to={{
                pathname: "/delivery",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default LoginRouting;
