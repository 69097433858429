import { Form, Formik, validateYupSchema } from "formik";
import React, { useEffect } from "react";
import Navbar from "../NavBar/Navbar";
import { getVacancies } from "../Features/action";
import Search from "../Search/Search";
import * as Yup from "yup";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import Spinner from "../../Admin/Spinner";
import UserFooter from "../UserFooter";
import useButtonLoader from "../../ButtonLoaderHook";

const validationSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required"),
  lname: Yup.string().required("Last Name is required"),
  contact: Yup.number().required("Phone Number is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  resume: Yup.mixed().required("File is required"),
  image: Yup.mixed().required("Image is required"),
  identityDocument: Yup.mixed().required("Identity Document is required"),
  identityType: Yup.string().required("Identity Type is required"),
});

const JobDetailsPage = () => {
  document.title = "Kreesa | Job Detail";
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Submit",
    "Loading..."
  );

  const { userVacancies, loader } = useSelector((state) => state.userProducts);

  useEffect(() => {
    dispatch(getVacancies());
  }, [id]);

  const submitHandler = async (values) => {
    try {
      setButtonLoader(true);
      let data = new FormData();
      data.append("name", values.fname + " " + values.lname);
      data.append("email", values.email);
      data.append("contact", values.contact);
      data.append("document", values.resume);
      data.append("image", values.image);
      data.append("vacancyId", id);
      data.append("identityDocument", values.identityDocument);
      data.append("identityType", values.identityType);

      await AxiosConfig.post("/kreesa/public/vacancy", data).then((res) => {
        toast.success(res.data.message);
        history.push("/jobs");
        setButtonLoader(false);
      });
    } catch (err) {
      toast.error("An error occurred");
      setButtonLoader(false);
    }
  };

  return (
    <div>
      <Search />
      <Navbar />
      {!loader ? (
        <div className="container mt-4">
          <div className="row">
            <div className="col-12 col-md-6 form__jobs">
              <Formik
                initialValues={{
                  fname: "",
                  lname: "",
                  email: "",
                  contact: "",
                  resume: "",
                  image: "",
                  identityType: "",
                  identityDocument: "",
                }}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  setFieldValue,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <div className="job__form__card">
                      <h3>Personal Details</h3>
                      <div className="row mt-3">
                        <div className="col-sm-6 mt-2">
                          <label className="form-label" htmlFor="fname">
                            <span className="font-weight-bold">First Name</span>{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="fname"
                            onChange={handleChange}
                            value={values.fname}
                            placeholder="First Name"
                          />
                          {errors.fname && touched.fname ? (
                            <div style={{ color: "red" }}> {errors.fname} </div>
                          ) : null}
                        </div>
                        <div className="col-sm-6 mt-2">
                          <label className="form-label" htmlFor="lname">
                            <span className="font-weight-bold">Last Name</span>{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="lname"
                            onChange={handleChange}
                            value={values.lname}
                            placeholder="Last Name"
                          />
                          {errors.lname && touched.lname ? (
                            <div style={{ color: "red" }}> {errors.lname} </div>
                          ) : null}
                        </div>
                        <div className="col-12 mt-3">
                          <label className="form-label" htmlFor="email">
                            <span className="font-weight-bold">
                              Email Address
                            </span>{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            placeholder="Enter your email"
                          />
                          {errors.email && touched.email ? (
                            <div style={{ color: "red" }}> {errors.email} </div>
                          ) : null}
                        </div>
                        <div className="col-12 mt-3">
                          <label className="form-label" htmlFor="email">
                            <span className="font-weight-bold">
                              Image (pp Size)
                            </span>{" "}
                            <span className="text-danger"> * (jpg/png)</span>
                          </label>
                          <input
                            type="file"
                            className="form-control form-control-lg"
                            name="image"
                            accept="image/*"
                            onChange={(e) =>
                              setFieldValue("image", e.currentTarget.files[0])
                            }
                            placeholder="Enter your email"
                          />
                          {errors.image && touched.image ? (
                            <div style={{ color: "red" }}> {errors.image} </div>
                          ) : null}
                        </div>
                        <div className="col-12 mt-3">
                          <label className="form-label" htmlFor="contact">
                            <span className="font-weight-bold">
                              Contact Number
                            </span>{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="contact"
                            onChange={handleChange}
                            value={values.contact}
                            placeholder="Enter your contact number"
                          />
                          {errors.contact && touched.contact ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              {errors.contact}{" "}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 mt-3">
                          <label className="form-label" htmlFor="contact">
                            <span className="font-weight-bold">
                              Identity Type
                            </span>{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            className="form-select"
                            name="identityType"
                            onChange={handleChange}
                          >
                            <option value="">Select any Identity Type</option>
                            <option value="Citizenship">Citizenship</option>
                            <option value="Passport">Passport</option>
                          </select>
                          {errors.identityType && touched.identityType ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              {errors.identityType}{" "}
                            </div>
                          ) : null}
                        </div>
                        {/* identityDocument */}
                        <div className="col-12 mt-3">
                          <label className="form-label" htmlFor="email">
                            <span className="font-weight-bold">
                              Identity Document (Citizenship/Passport)
                            </span>{" "}
                            <span className="text-danger"> * (jpg/png)</span>
                          </label>
                          <input
                            type="file"
                            className="form-control form-control-lg"
                            name="identityDocument"
                            accept="image/*"
                            onChange={(e) =>
                              setFieldValue(
                                "identityDocument",
                                e.currentTarget.files[0]
                              )
                            }
                          />
                          {errors.identityDocument &&
                          touched.identityDocument ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              {errors.identityDocument}{" "}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="job__form__card">
                      <h3>Resume</h3>
                      <div className="row">
                        <div className="col-12 mt-2">
                          <input
                            type="file"
                            accept=".doc,.docx,.pdf"
                            className="form-control form-control-lg"
                            name="resume"
                            id="resume"
                            onChange={(e) =>
                              setFieldValue("resume", e.currentTarget.files[0])
                            }
                            placeholder="Enter your resume"
                          />
                          {errors.resume && touched.resume ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              {errors.resume}{" "}
                            </div>
                          ) : null}
                          <p className="mt-2 font-weight-bold">
                            Accepted file types: .doc, .docx, and .pdf.
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="job__buttons"
                      ref={buttonLoader}
                      onClick={handleSubmit}
                    >
                      Apply
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-12 col-md-6">
              <div className="job__detail__container">
                {userVacancies
                  ?.filter((i) => i.id == id)
                  ?.map((j) => (
                    <div key={j}>
                      <h6 style={{ color: "grey", fontWeight: "bold" }}>
                        Deadline : {moment(j.deadline).format("D MMM, YYYY")}{" "}
                      </h6>

                      <h3 className="job__title__detail">
                        {j.title} ({j.openVacancies})
                      </h3>
                      <h5>{j.jobType}</h5>

                      <div className="job__box__col">
                        <div className="job__desc__detail">
                          <h5>Location</h5>
                          {j.jobLocation}
                        </div>

                        <div className="job__desc__detail">
                          <h5>Overview</h5>
                          {j.jobOverview}
                        </div>

                        <div className="job__desc__detail">
                          <h5>Requirements</h5>
                          {ReactHtmlParser(j.requirement)}
                        </div>
                        <div className="job__desc__detail">
                          <h5>Job Description</h5>
                          {ReactHtmlParser(j.jobDescription)}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      )}
      <UserFooter />
    </div>
  );
};

export default JobDetailsPage;
