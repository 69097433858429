import $ from "jquery";
import "jquery-validation";

function UserwrRegistrationValidation() {
  $(function () {
    $.validator.addMethod(
      "strongePassword",
      function (value) {
        return (
          // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])$/.test(value)
          /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) &&
          /[a-z]/.test(value) &&
          /\d/.test(value) &&
          /[A-Z]/.test(value)
        );
      },
      "The password must contain at least 1 number, at least 1 lower case letter, and at least 1 upper case letter"
    );
    $.validator.addMethod(
      "lettersonly",
      function (value, element) {
        return this.optional(element) || value == value.match(/^[a-zA-Z ]*$/);
      },
      "Letters only please"
    );
    $.validator.addMethod(
      "extension",
      function (value, element, param) {
        param =
          typeof param == "string" ? param.replace(/,/g, "|") : "png|jpeg|jpg";
        return (
          this.optional(element) ||
          value.match(new RegExp(".(" + param + ")$", "i"))
        );
      },
      $.validator.format("Only accept png|jpeg|jpg")
    );

    $("#user-wrregister-forms").validate({
      // Specify validation rules

      rules: {
        company_name: {
          required: true,
          lettersonly: true,
        },
        company_address: {
          required: true,
        },
        phone_no: {
          required: true,
          number: true,
          maxlength: 10,
        },
        email: {
          required: true,
          email: true,
        },

        company_type: {
          required: true,
        },

        password: {
          required: true,
          strongePassword: true,
          minlength:8,
        },
        confirm_password: {
          required: true,
          equalTo: '[name="password"]',
        },
        role: {
          required: true,
        },
        accept: {
          required: true,
        },
        hasInsured: {
          required: true,
        },
        image: {
          required: true,
          extension: true,
        },
        pan_no: {
          required: true,
        },
      },
      messages: {
        company_name: {
          required: "Company name is required",
        },
        company_address: {
          required: "Company address is required",
        },
        phone_no: {
          required: "Phone number is required",
        },
        email: {
          required: "Email is required",
        },
        company_type: {
          required: "Please select an item!",
        },

        password: {
          required: "Password is required",
        },
        confirm_password: {
          required: "Confirm password is required",
          equalTo: "Password doesn't match",
        },
        role: {
          required: "Role is required",
        },
        accept: {
          required: "Accept the terms and conditions",
        },
        hasInsured: {
          required: "Select one ",
        },
      },

      highlight: function (element) {
        $(element).removeClass("is-valid").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid").addClass("is-valid");
      },
    });
    $("#sends").on("click", function () {
      $("#user-wrregister-forms").valid();
    });
    $("#user-wrregister-forms input").on("keyup blur click", function () {
      // fires on every keyup & blur
      if ($("#user-wrregister-forms").valid()) {
        // checks form for validity
        $("button[type='submit']").attr("disabled", false); // enables button
      } else {
        $("button[type='submit']").attr("disabled",true); // disables button
      }
    });
  });
 
}

export default UserwrRegistrationValidation;
