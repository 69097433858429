import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import UserwrRegistrationValidation from "../../Validation/UserwrRegistrationValidation";
import { Formik, Form } from "formik";
import Spinner from "../../Admin/Spinner";
import { getAllCountries } from "../../Admin/Features/Action/AddressAction";
import { getAllProvince } from "../../Admin/Features/Action/AddressAction";
import { getAllDistrict } from "../../Admin/Features/Action/AddressAction";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../Admin/Features/Action/AdminBlockUnblockAction";

const ProfileSetting = () => {
  const [logoFile, setLogoFile] = useState();
  const [newPassword, setPassword] = useState();
  const { countries, proviences, districts } = useSelector(
    (s) => s.AddressReducer
  );

  const dispatch = useDispatch();
  let id = localStorage.getItem("userId");
  const { user, loader } = useSelector((s) => s.AdminReducer);

  const initialValues = {
    company_name: user?.companyName,
    // company_address: user?.companyAddress,
    company_contact_no: user?.companyContactNo,
    company_email: user?.companyEmail,
    companyType: user?.companyType,
    companyTypeId: user?.companyTypeId,
    countryId: user?.countryId,
    provinceId: user?.provinceId,
    districtId: user?.districtId,
    hasInsured: user?.isInsured,
    pan_no: user?.panNo,
    landmark: user?.landMark,
    pin_code: user?.pinCode,
    hasRegistered: user?.isRegistered,
    street_address: user?.streetAddress,
  };

  useEffect(() => {
    dispatch(getUserDetail(id));
  }, [id]);

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllDistrict());
    dispatch(getAllProvince());
  }, []);

  // to get all user Details by their relavent email
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Save Changes",
    "Loading..."
  );
  const [buttonLoader1, setButtonLoader1] = useButtonLoader(
    "Save Changes",
    "Loading..."
  );

  const updateProfileHandler = async (values) => {
    setButtonLoader(true);

    try {
      let data = new FormData();
      if (logoFile) {
        data.append("company_logo", logoFile);
      }

      data.append("email", values.company_email);
      data.append("company_name", values.company_name);
      data.append("companyTypeId", values.companyTypeId);
      if (values.countryId) {
        data.append("countryId", values.countryId);
      }
      if (values.provinceId) {
        data.append("provinceId", values.provinceId);
      }
      if (values.districtId) {
        data.append("districtId", values.districtId);
      }
      data.append("company_email", values.company_email);
      data.append("hasRegistered", values.hasRegistered);
      data.append("hasInsured", values.hasInsured);
      data.append("landmark", values.landmark);
      data.append("pin_code", values.pin_code);
      data.append("street_address", values.street_address);
      // data.append("company_address", values.company_address);
      data.append("company_contact_no", values.company_contact_no);
      data.append("role", localStorage.getItem("auth"));
      data.append("pan_no", values.pan_no);
      data.append("id", user.userId);

      const res = await AxiosConfig.post(
        "/kreesa/retailer/update-profile",
        data,
        {
          headers: {
            "Content-Type": "aplication,multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res) {
        toast.success("Profile Update succesfully !");
        setButtonLoader(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(err + "error found");
      // setMessage('Filed to add New category !')
      setButtonLoader(false);
    }
  };

  //update password
  const updatePasswordHandler = async (e) => {
    setButtonLoader1(true);
    e.preventDefault();
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let email = localStorage.getItem("username");
      const res = await AxiosConfig.post(
        `/kreesa/retailer/update-password`,
        { newPassword, email },
        config
      );

      if (res) {
        toast.success(`Password Changes succesfully !`);
        setButtonLoader1(false);
      }
    } catch (err) {
      console.log(err + "error found");
      // setMessage('Filed to add New category !')
      setButtonLoader1(false);
    }
  };
  UserwrRegistrationValidation();
  return !loader ? (
    <div className="container-fluid p-0">
      <h1 className="h3 mb-3">Settings</h1>

      <div className="row">
        <div className="col-md-3 col-xl-2">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 font-weight-bold">
                Profile Settings
              </h1>
            </div>
            <div className="list-group list-group-flush" role="tablist">
              <a
                className="list-group-item list-group-item-action active"
                data-toggle="list"
                href="#account"
                role="tab"
              >
                Account
              </a>
              <a
                className="list-group-item list-group-item-action"
                data-toggle="list"
                href="#password"
                role="tab"
              >
                Password
              </a>
              <a
                className="list-group-item list-group-item-action"
                data-toggle="list"
                href="#"
                role="tab"
              >
                Email notifications
              </a>
              <a
                className="list-group-item list-group-item-action"
                data-toggle="list"
                href="#"
                role="tab"
              >
                Your data
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-9 col-xl-10">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="account"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0 font-weight-bold">
                    Update Profile Info
                  </h5>
                </div>

                <div className="card-body">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => updateProfileHandler(values)}
                  >
                    {({ handleChange, handleSubmit, values }) => (
                      <Form
                        encType="multipart/form-data"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-md-8">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                Company Name
                              </label>
                              <input
                                style={{ cursor: "not-allowed" }}
                                value={values.company_name}
                                name="company_name"
                                onChange={handleChange}
                                disabled
                                type="text"
                                className="form-control"
                                id="inputCompanyName"
                                placeholder="Enter Company Name"
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                {" "}
                                Company Email
                              </label>
                              <input
                                style={{ cursor: "not-allowed" }}
                                onChange={handleChange}
                                value={values.company_email}
                                name="company_email"
                                disabled
                                type="text"
                                className="form-control"
                                id="inputEmail"
                                placeholder="Enter Company Email"
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                {" "}
                                Company Type
                              </label>
                              <input
                                value={values.companyType}
                                onChange={handleChange}
                                disabled
                                style={{ cursor: "not-allowed" }}
                                type="text"
                                className="form-control"
                                id="inputType"
                                placeholder="Enter Company Type"
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                {" "}
                                PAN
                              </label>
                              <input
                                style={{ cursor: "not-allowed" }}
                                name="pan_no"
                                onChange={handleChange}
                                value={values.pan_no || ""}
                                disabled
                                type="text"
                                className="form-control"
                                id="inputPan"
                                placeholder="Enter PAN"
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                Street Address
                              </label>
                              <input
                                value={values.street_address}
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                id="inputStreetAddress"
                                placeholder="Enter Street Address"
                                name="street_address"
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputEmail4"
                              >
                                Country
                              </label>
                              <select
                                name="countryId"
                                onChange={handleChange}
                                // onBlur={(e) => SetSelectProvince(e.target.value)}
                                value={values.countryId}
                                className="form-control"
                              >
                                <option value="">Select Your Country...</option>
                                {countries?.map((c, i) => (
                                  <option
                                    key={i}
                                    value={c.id}
                                    selected={values.countryId === c.id}
                                  >
                                    {c.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="mb-3 ">
                              <label
                                className="form-label"
                                htmlFor="inputEmail4"
                              >
                                Province
                              </label>
                              <select
                                name="provinceId"
                                onChange={handleChange}
                                // onBlur={(e) => SetSelectDistrict(e.target.value)}
                                value={values.provinceId}
                                className="form-control"
                              >
                                <option value="">
                                  Select Your Provience...
                                </option>
                                {proviences?.map(
                                  (pr, i) => (
                                    // selectProvince == pr.country.id && (
                                    <option
                                      key={i}
                                      value={pr.id}
                                      selected={values?.provinceId === pr.id}
                                    >
                                      {pr?.provience_name}
                                    </option>
                                  )
                                  // )
                                )}
                              </select>
                            </div>
                            <div className="mb-3 ">
                              <label
                                className="form-label"
                                htmlFor="inputEmail4"
                              >
                                District
                              </label>
                              <select
                                name="districtId"
                                onChange={handleChange}
                                value={values.districtId}
                                className="form-control"
                              >
                                <option value="">
                                  Select Your District...
                                </option>
                                {districts?.map(
                                  (d, i) => (
                                    // selectDistrict == d?.stateOrProvience?.id && (
                                    <option
                                      key={i}
                                      value={d.id}
                                      selected={values?.districtId === d.id}
                                    >
                                      {d.district_name}
                                    </option>
                                  )
                                  // )
                                )}
                              </select>
                            </div>

                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                {" "}
                                Company Contact
                              </label>
                              <input
                                value={values.company_contact_no}
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                id="inputComapnyContact"
                                placeholder="Enter Company Contact"
                                name="company_contact_no"
                              />
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Landmark
                                </label>
                                <input
                                  // defaultValue={userDetails?.firstname}
                                  // onChange={(e) => setFname(e.target.value)}
                                  name="landmark"
                                  onChange={handleChange}
                                  value={values.landmark || ""}
                                  type="text"
                                  className="form-control"
                                  id="inputFirstName"
                                  placeholder="landmark"
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputLastName"
                                >
                                  Pin Code
                                </label>
                                <input
                                  // defaultValue={userDetails?.lastname}
                                  // onChange={(e) => setLname(e.target.value)}
                                  name="pin_code"
                                  onChange={handleChange}
                                  value={values.pin_code || ""}
                                  type="text"
                                  className="form-control"
                                  id="inputLastName"
                                  placeholder="Pin code"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="text-center">
                              {user.image === null ? (
                                <img
                                  src="/images/new.jpg"
                                  style={{ height: "200px", width: "200px" }}
                                  className="avatar img-fluid rounded mr-1"
                                  alt=""
                                />
                              ) : (
                                <img
                                  alt={user.companyName}
                                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${user?.userId}/${user?.companyLogo}`}
                                  className="rounded-circle img-responsive mt-2"
                                  style={{ height: "200px", width: "200px" }}
                                />
                              )}
                              <div className="mt-2">
                                <input
                                  type="file"
                                  name="compony_logo"
                                  accept="image/png,image/jpg,image/jpeg"
                                  onChange={(e) => {
                                    setLogoFile(e.target.files[0]);
                                  }}
                                />
                                <small>Accepted Formats: png, jpg & jpeg</small>
                              </div>
                              <small>
                                For best results, use an image at least 128px by
                                128px in .jpg format
                              </small>
                            </div>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={buttonLoader}
                        >
                          Save changes
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="password" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Password</h5>

                  <form
                    id="user-wrregister-forms"
                    onSubmit={updatePasswordHandler}
                  >
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputPasswordNew">
                        New password
                      </label>
                      <input
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Enter New password"
                        className="form-control"
                        id="inputPasswordNew"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputPasswordNew2">
                        Verify password
                      </label>
                      <input
                        name="confirm_password"
                        type="password"
                        placeholder="Enter New password Again"
                        className="form-control"
                        id="inputPasswordNew2"
                      />
                    </div>
                    <button
                      id="sends"
                      type="submit"
                      className="btn btn-primary"
                      ref={buttonLoader1}
                    >
                      Save changes
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ProfileSetting;
