import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../../assets/Images/kreesalogo.png";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { checkemail, checkPhone } from "./CheckExistValue";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useButtonLoader from "../../ButtonLoaderHook";
import "../UserSignup/UserSignup.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries } from "../../Admin/Features/Action/AddressAction";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  // street_address: Yup.string().required("Street Address is required"),
  phone_no: Yup.number().required("Phone Number is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  // landmark: Yup.string().required("Landmark Required"),
  // pin_code: Yup.number().required("Pin code required"),

  // image: Yup.mixed().when("hasRegistered", (hasRegistered) => {
  //   if (hasRegistered) {
  //     return Yup.mixed().required("image is required");
  //     // .test(
  //     //   "fileType",
  //     //   "Only accept Jpg/Jpeg/Png file type",
  //     //   (file) =>
  //     //     file && ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
  //     // )
  //   }
  // }),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required ")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  accept: Yup.bool().oneOf([true], "Terms and Condition must be accepted"),
  // countryId: Yup.string().required("Select Country "),
  // provinceId: Yup.string().required("Select Province "),
  // districtId: Yup.string().required("Select District "),
});

const UserSignup = () => {
  document.title = "Kreesa | User SignUp";
  const [image, setImage] = useState();
  const [country, setCountry] = useState([]);
  const [district, setDistrict] = useState([]);
  const [province, setProvince] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let history = useHistory();
  const dispatch = useDispatch();
  const { countries, proviences, districts } = useSelector(
    (s) => s.AddressReducer
  );

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Register Now",
    "Loading..."
  );

  // useEffect(() => {
  //   AxiosConfig.get("/kreesa/check/get-country").then((res) =>
  //     setCountry(res.data)
  //   );
  // }, []);
  // useEffect(() => {
  //   dispatch(getAllCountries());
  // }, []);

  // api
  const addUserHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      if (image) {
        data.append("image", image);
      }
      data.append("email", values.email);
      data.append("phone_no", values.phone_no);
      data.append("password", values.password);
      data.append("role", "ROLE_USER");
      // data.append("countryId", values.countryId);
      // data.append("provinceId", values.provinceId);
      // data.append("districtId", values.districtId);
      // data.append("street_address", values.street_address);
      // data.append("landmark", values.landmark);
      // data.append("pin_code", values.pin_code);

      const res = await AxiosConfig.post(
        "/kreesa/user-login-registration/register",
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (res && res.status === 200) {
        toast.success("User added succesfully !");
        setButtonLoader(false);
        history.push("/activation-link");
        localStorage.setItem("activation", values.email);
      }
    } catch (err) {
      console.log(err + "error found");
      toast.error(`Something Went Wrong !`);
      setButtonLoader(false);
    }
  };
  // to check authentication user or not
  let token = localStorage.getItem("token");
  if (token) {
    return <Redirect exact to="/" />;
  }

  return (
    <div className="mb-5">
      <section className="rw-signup">
        {/* <!-- <img src="images/signup-bg.jpg" alt=""> --> */}
        <div className="container" id="rw-container">
          <div className="signup-content">
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                phone_no: "",
                password: "",
                confirmPassword: "",
                street_address: "",
                isLoading: false,
                accept: false,
                // countryId: countries.id,
                // provinceId: proviences.id,
                // districtId: districts.id,
                // landmark: "",
                // pin_code: "",
              }}
              onSubmit={(values) => {
                addUserHandler(values);
              }}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
                setFieldValue,
              }) => (
                <Form className="rw-signup-form" onSubmit={handleSubmit}>
                  <h2 className="text-center"> User Registration </h2>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-evenly" }}
                  >
                    <Link to="/">
                      <img
                        src={logo}
                        className="rrounded"
                        alt="logo"
                        width="150px"
                      />
                    </Link>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="first_name"> First name </label>
                        <input
                          type="text"
                          className="form-input"
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange}
                          placeholder="First Name"
                        />
                        {errors.first_name && touched.first_name ? (
                          <div style={{ color: "red" }}>
                            {errors.first_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="last_name"> Last name </label>
                        <input
                          type="text"
                          className="form-input"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          placeholder="Last Name"
                        />
                        {errors.last_name && touched.last_name ? (
                          <div style={{ color: "red" }}>{errors.last_name}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="first_name"> Phone Number </label>
                        <input
                          type="text"
                          className="form-input"
                          name="phone_no"
                          value={values.phone_no}
                          onKeyUp={checkPhone}
                          onChange={handleChange}
                          placeholder="Phone"
                          id="phone"
                        />
                        {errors.phone_no && touched.phone_no ? (
                          <div style={{ color: "red" }}>{errors.phone_no}</div>
                        ) : null}
                        <span
                          id="phone-verify-error"
                          className="error"
                          htmlFor="phone"
                        ></span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="last_name"> Email </label>
                        <input
                          type="email"
                          className="form-input"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onKeyUp={checkemail}
                          placeholder="Email"
                          id="email"
                        />
                        {errors.email && touched.email ? (
                          <div style={{ color: "red" }}>{errors.email}</div>
                        ) : null}
                        <span
                          id="email-errors"
                          className="error"
                          htmlFor="email"
                        ></span>
                      </div>
                    </div>
                    {/* <div
                      className="row mt-2"
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="radio"> Country </label>
                          <div className="select-list">
                            <select
                              id="country"
                              name="countryId"
                              onChange={(e) => {
                                AxiosConfig.get(
                                  `/kreesa/check/get-province/${e.target.value}`
                                ).then((res) => {
                                  setProvince(res.data);
                                  setDistrict([]);
                                });
                                setFieldValue("countryId", e.target.value);
                              }}
                            >
                              <option> Select Country </option>
                              {country?.map((i) => (
                                <option key={i.id} value={i.id}>
                                  {i.country_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.countryId && touched.countryId ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              {errors.countryId}{" "}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="radio"> Province </label>
                          <div className="select-list">
                            <select
                              id="country"
                              name="provinceId"
                              onChange={(e) => {
                                AxiosConfig.get(
                                  `/kreesa/check/get-district/${e.target.value}`
                                ).then((res) => setDistrict(res.data));
                                setFieldValue("provinceId", e.target.value);
                              }}
                            >
                              <option> Select Province </option>
                              {province?.map((i) => (
                                <option key={i.id} value={i.id}>
                                  {i.provience_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.provinceId && touched.provinceId ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              {errors.provinceId}{" "}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="radio"> District </label>
                          <div className="select-list">
                            <select
                              id="country"
                              name="districtId"
                              onChange={(e) => {
                                AxiosConfig.get(
                                  `/kreesa/check/get-district/${e.target.value}`
                                ).then((res) => setDistrict(res.data));
                                setFieldValue("districtId", e.target.value);
                              }}
                            >
                              <option> Select District </option>
                              {district?.map((i) => (
                                <option key={i.id} value={i.id}>
                                  {i.district_name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.districtId && touched.districtId ? (
                            <div style={{ color: "red" }}>
                              {" "}
                              {errors.districtId}{" "}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group mt-2">
                      <label for="first_name"> Street Address </label>
                      <input
                        type="text"
                        placeholder="Enter Your Street Address"
                        name="street_address"
                        onChange={handleChange}
                        value={values.street_address}
                      />
                      {errors.street_address && touched.street_address ? (
                        <div style={{ color: "red" }}>
                          {" "}
                          {errors.street_address}{" "}
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  {/* <div className="form-row" id="two-columns">
                    <div className="form-group">
                      <label htmlFor="landmark"> Landmark </label>
                      <input
                        type="text"
                        className="form-input"
                        name="landmark"
                        value={values.landmark}
                        onChange={handleChange}
                        placeholder="Landmark"
                        id="landmark"
                      />
                      {errors.landmark && touched.landmark ? (
                        <div style={{ color: "red" }}>{errors.landmark}</div>
                      ) : null}
                    </div>{" "}
                    <div className="form-group">
                      <label htmlFor="pin_code"> Pin Code </label>
                      <input
                        type="text"
                        className="form-input "
                        name="pin_code"
                        value={values.pin_code}
                        onChange={handleChange}
                        placeholder="Pin code"
                        id="phone"
                        onKeyUp={checkPhone}
                      />
                      {errors.pin_code && touched.pin_code ? (
                        <div style={{ color: "red" }}>{errors.pin_code}</div>
                      ) : null}
                    </div>
                  </div> */}
                  
                  <div className="row">
                    <div className="form-group mt-2">
                      <label for="first_name">
                        Your Image
                        <span className="text-info"> (optional)</span>
                      </label>
                      <input
                        type="file"
                        name="image"
                        accept="image/png,image/jpg,image/jpeg"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                      />
                      <small>Accepted Formats: png, jpg & jpeg</small>
                    </div>
                  </div> 
                  <div className="row">
                    <div className="form-group mt-2">
                      <label for="password"> Password </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-input"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        placeholder="Password"
                        id="pas"
                      />
                      <span
                        className="input-group-text cursor-pointer mt-2"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          width: 40,
                        }}
                      >
                        <i
                          className={`${
                            showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                          }`}
                          id="togglePassword"
                        ></i>
                      </span>
                      {errors.password && touched.password ? (
                        <div style={{ color: "red" }}>{errors.password}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mt-2">
                      <label for="re_password"> Confirm Password </label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-input"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                      />
                      <span
                        className="input-group-text cursor-pointer mt-2"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        style={{
                          width: 40,
                        }}
                      >
                        <i
                          className={`${
                            showConfirmPassword
                              ? "fa fa-eye-slash"
                              : "fa fa-eye"
                          }`}
                          id="togglePassword"
                        ></i>
                      </span>
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group mt-3 w-100 d-flex">
                    <input
                      type="checkbox"
                      name="accept"
                      onChange={handleChange}
                      // className="w-0 mt-1"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <label htmlFor="terms" className="pl-2">
                      {" "}
                      I accept the
                      <Link to="/termsandconditions" target="_blank">
                        {" "}
                        Terms and Conditions Privacy Policy
                      </Link>
                    </label>
                  </div>
                  {errors.accept && touched.accept ? (
                    <div style={{ color: "red" }}>{errors.accept}</div>
                  ) : null}
                  <div className="form-groups"> </div>
                  <div className="form-group register-group">
                    <button
                      id="sends"
                      type="submit"
                      className="btn btn-success btn-lg btn-block sends"
                      onClick={handleSubmit}
                      disabled={!isValid}
                      ref={buttonLoader}
                    >
                      Register Now
                    </button>
                  </div>
                  <div className="text-center font-weight-bold">
                    Already have an account ?
                    <Link to="/login"> Login Here </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserSignup;
