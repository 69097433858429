import $ from "jquery";
import "jquery-validation";

const AdminCategoryValidation = () => {
  // Wait for the DOM to be ready
  $(function () {
    $("#acategoryadd-forms").validate({
      // Specify validation rules
      rules: {
        categoryName: {
          required: true,
        },

        // role: {
        //   required: true,
        // },
        SecondaryCategory: {
          required: true,
        },
        TernaryCategoryName: {
          required: true,
        },

        message:{
          required: true,
        },
        // sendto: {
        //   required: true,
        // },
        // all:{
        //   required: true,
        // },
        email:{
          required: true,
          email: true,
        }
      },

      messages: {
        categoryName: {
          required: "Category is required",
        },
        message:{
          required: "Message is required",
        },
        
        email:{
          required: "Email is required",
          email: "Invalid email",
        }
      },

      highlight: function (element) {
        $(element).removeClass("is-valid").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid").addClass("is-valid");
      },
    });
    $("#catadd").on("click", function () {
      $("#acategoryadd-forms").valid();
    });
  });
  $("#acategoryadd-forms input").on("keyup blur", function () {
    // fires on every keyup & blur
    if ($("#acategoryadd-forms").valid()) {
      // checks form for validity
      $("button#catadd").prop("disabled", false); // enables button
    } else {
      $("button#catadd").prop("disabled", "disabled"); // disables button
    }
  });
};

export default AdminCategoryValidation;
