import React, { useEffect, useState } from "react";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import Header from "../../RetailerWholeSaler/Header";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import Nav from "../../RetailerWholeSaler/Nav";
import UserFooter from "../UserFooter";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../Features/action";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { useHistory } from "react-router-dom";
import Spinner from "../../Admin/Spinner";
import { Excerpt } from "../../../utils";
import Pagination from "../../Pagination";

const NewsBlogNotice = () => {
  document.title = "Kreesa | News / Blog & Notice";
  // state
  const [activeTab, setActiveTab] = useState("BLOGS");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(6);

  const history = useHistory();

  const onchange = (e) => {
    setActiveTab(e.target.value);
  };
  const { news, notices, blogs, loader } = useSelector(
    (state) => state.userProducts
  );
  const dispatch = useDispatch();

  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  //
  const currentEntries =
    activeTab === "NEWS"
      ? news?.slice(indexOfFirst, indexOfLast)
      : activeTab === "NOTICE"
      ? notices?.slice(indexOfFirst, indexOfLast)
      : blogs?.slice(indexOfFirst, indexOfLast);

  const totalPagesNum =
    activeTab === "NEWS"
      ? Math.ceil(news?.length / perPage)
      : activeTab === "NOTICE"
      ? Math.ceil(notices?.length / perPage)
      : Math.ceil(blogs?.length / perPage);

  useEffect(() => {
    dispatch(getBlogs());
  }, []);

  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      {!loader ? (
        <div className="container mt-4 mb-2">
          <p
            style={{
              fontSize: 18,
            }}
          >
            <a
              href={
                localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
                localStorage.getItem("auth") === "ROLE_RETAILER"
                  ? "/home"
                  : "/"
              }
              className="text-muted"
            >
              {" "}
              Home /{" "}
            </a>
            News, Blogs and Notices
          </p>
          <h3 className="news__blog_notice_clr">NEWS, BLOGS AND NOTICES</h3>
          <p
            style={{
              color: "#ee5a5e",
              lineHeight: 1.3,
              fontSize: 20,
            }}
            className="mt-4"
          >
            Click on the tabs below to select news, blogs or notices.{" "}
          </p>
          {/* tabs */}
          <div className="row mt-5">
            <div className="col-md-2">
              <label className="radio">
                {" "}
                <input
                  type="radio"
                  name="action"
                  value="BLOGS"
                  onChange={onchange}
                />{" "}
                <span
                  style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 25,
                    background:
                      activeTab === "BLOGS" ? "rgb(26, 173, 241)" : "",
                    color: activeTab === "BLOGS" ? "black" : "",
                  }}
                >
                  BLOGS
                </span>
              </label>
            </div>
            <div className="col-md-2">
              <label className="radio">
                {" "}
                <input
                  type="radio"
                  name="action"
                  value="NEWS"
                  onChange={onchange}
                />{" "}
                <span
                  style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 25,
                    background: activeTab === "NEWS" ? "rgb(26, 173, 241)" : "",
                    color: activeTab === "NEWS" ? "black" : "",
                  }}
                >
                  NEWS
                </span>
              </label>
            </div>

            <div className="col-md-2">
              <label className="radio">
                {" "}
                <input
                  type="radio"
                  name="action"
                  value="NOTICE"
                  onChange={onchange}
                />{" "}
                <span
                  style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 25,
                    background:
                      activeTab === "NOTICE" ? "rgb(26, 173, 241)" : "",
                    color: activeTab === "NOTICE" ? "black" : "",
                  }}
                >
                  NOTICE
                </span>
              </label>
            </div>
            <hr
              style={{
                color: "#ee5a5e",
              }}
            />
          </div>
          <div className="my-2">
            {activeTab === "NEWS" && (
              <div className="row">
                {/* <h5 className="text-center font-weight-bold">
                  Displaying 1 - 18 of 911
                </h5> */}
                {/* card for news*/}
                {news.length === 0 && (
                  <h4>No News found. Please check back later.</h4>
                )}
                {currentEntries?.map((item) => (
                  <Blogs activeTab="NEWS" history={history} blogs={item} />
                ))}
                {activeTab === "NEWS" && (
                  <Pagination
                    pages={totalPagesNum}
                    setCurrentPage={setCurrentPage}
                    currentEntries={currentEntries}
                    allEntries={news}
                  />
                )}
              </div>
            )}
            {activeTab === "BLOGS" && (
              <div className="row">
                {/* <h5 className="text-center font-weight-bold">
                Displaying 2 - 18 of 111
              </h5> */}

                {blogs.length === 0 && (
                  <h4>No Blogs found. Please check back later.</h4>
                )}

                {/* card for blogs*/}
                {currentEntries?.map((blog) => (
                  <Blogs activeTab="BLOGS" history={history} blogs={blog} />
                ))}
                {activeTab === "BLOGS" && (
                  <Pagination
                    pages={totalPagesNum}
                    setCurrentPage={setCurrentPage}
                    currentEntries={currentEntries}
                    allEntries={blogs}
                  />
                )}
              </div>
            )}
            {activeTab === "NOTICE" && (
              <div className="row">
                {/* <h5 className="text-center font-weight-bold">
                Displaying 3 - 18 of 56
              </h5> */}

                {notices.length === 0 && (
                  <h4>No Notices found. Please check back later.</h4>
                )}

                {/* card for notice*/}
                {currentEntries?.map((notice) => (
                  <Blogs activeTab="NOTICE" history={history} blogs={notice} />
                ))}
                {activeTab === "NOTICE" && (
                  <Pagination
                    pages={totalPagesNum}
                    setCurrentPage={setCurrentPage}
                    currentEntries={currentEntries}
                    allEntries={notices}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default NewsBlogNotice;

//
const Blogs = ({ blogs, history, activeTab }) => {
  return (
    <div
      className="col-md-6 col-lg-4 mt-3"
      style={{
        position: "relative",
      }}
      onClick={() => history.push(`/blog/view/${blogs?.id}`)}
    >
      <div class="node__tag">{activeTab}</div>

      <div className="card__text__news news__hover_effect">
        <div
          className="title-img"
          style={{
            height: 210,
          }}
        >
          <img
            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blogs?.id}/${blogs?.image}`}
            alt={blogs?.title}
            style={{
              height: "100%",
              objectFit: "cover",
              width: "100%",
            }}
            loading="lazy"
          />
        </div>
        <div className="title-container">
          <h5 className="title-text news__text__ p-5">
            {Excerpt(blogs?.title, 60)}
          </h5>
          <p
            style={{
              height: 1.5,
              background: "blue",
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

/* -------------------------------------------------------------------------- */
/*                                  commment                                  */
/* -------------------------------------------------------------------------- */
// <div
//   className="col-md-6 col-lg-4 mt-3"
//   style={{
//     position: "relative",
//   }}
//   onClick={() => history.push(`/blog/view/${item.id}`)}
// >
//   <div class="node__tag">{activeTab}</div>

//   <div className="card__text__news news__hover_effect">
//     <div
//       className="title-img"
//       style={{
//         height: 210,
//       }}
//     >
//       <img
//         src={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${item.id}/${item.image}`}
//         alt={item?.title}
//         style={{
//           height: "100%",
//           objectFit: "cover",
//           width: "100%",
//         }}
//         loading="lazy"
//       />
//     </div>
//     <div className="title-container">
//       <h5 className="title-text news__text__ p-5">
//         {Excerpt(item?.title, 60)}
//       </h5>
//       <p
//         style={{
//           height: 1.5,
//           background: "blue",
//         }}
//       ></p>
//     </div>
//   </div>
// </div>
