import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import {
  blockUser,
  getAllCustomer,
  unlockUser,
} from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useButtonLoader from "../../../ButtonLoaderHook";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Pagination } from "@mantine/core";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required ")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.newPassword === value;
    }),
  accept: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});
const AllCustomerTabs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [buttonLoader1, setButtonLoader1] = useButtonLoader(
    "Save Changes",
    "Loading..."
  );

  const [modalId, setModalId] = React.useState("");
  const handleClose = () => setModalId("");

  // get all Blocked Wholesalers from API
  const { customers, loader } = useSelector((s) => s.AdminReducer);

  useEffect(() => {
    dispatch(getAllCustomer());
  }, []);

  //block
  const BlockUser = (id) => {
    dispatch(blockUser(id));
    toast.info("User has been Blocked");
    setTimeout(() => {
      dispatch(getAllCustomer());
    }, 500);
  };

  // unlocked user
  const unLockUser = (id) => {
    dispatch(unlockUser(id));
    toast.info("User has been Unlocked");
    setTimeout(() => {
      dispatch(getAllCustomer());
    }, 500);
  };
  const UpdatePasswordHandler = async (values) => {
    setButtonLoader1(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let data = new FormData();
      data.append("newPassword", values.newPassword);
      data.append("email", customers.email);
      const res = await AxiosConfig.post(
        "/kreesa/admin/update-password",
        values,
        config
      );
      if (res) {
        toast.success("Password Change Successfully");
        setButtonLoader1(false);
        setModalId("");
      }
    } catch (error) {
      console.log(error + "Something Went Wrong ! ");
      toast.error("Something Went Wrong !");
      setButtonLoader1(false);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = customers?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(customers?.length / perPage);
  return !loader ? (
    <>
      <div className="container">
        {/* <div className="row">
            <div className="col-12 d-flex w-100">
              <div className="card flex-fill flex-fill">
                <div className="card-header">
                  <Link
                    to="/admin/add-new-user"
                    className="btn btn-lg btn-outline-info display-5 mt-2"
                    style={{ float: "right" }}
                  >
                    Add New User <i className="fas fa-user-plus ml-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        <div className="row">
          <div
            className="col-12 col-lg-8 d-flex w-100"
            style={{ minHeight: "420px" }}
          >
            {currentEntries?.length > 0 ? (
              <div className="card flex-fill overflow-auto">
                <div className="card-header">
                  <h5 className="card-title mb-0">All Customers</h5>
                </div>
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Full Name</th>
                      <th className="d-xl-table-cell">E-mail</th>
                      <th className="d-xl-table-cell">Phone</th>
                      <th>Status</th>
                      <th className="d-md-table-cell">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries?.map((p, i) => (
                      <tr key={i}>
                        <td>{perPage * (currentPage - 1) + i + 1}</td>
                        <td>
                          {p.firstname} {p.lastname}
                        </td>
                        <td className="d-xl-table-cell">
                          <a href={`mailto:${p.email}`}>{p.email}</a>
                        </td>
                        <td className="d-xl-table-cell">{p.phoneNumber}</td>
                        <td>
                          {p.flag === true ? (
                            p.locked === true ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Inactive</span>
                            )
                          ) : (
                            <span className="badge bg-danger">Blocked</span>
                          )}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              type="button"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#3b7ddd",
                              }}
                              className="btn-md btn btn-success dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More
                            </button>
                            <div className="dropdown-menu text-left">
                              {localStorage.getItem("auth") !==
                              "ROLE_RECEPTIONIST" ? (
                                <>
                                  <Link
                                    to={`/admin/edit-customer/${p.userId}`}
                                    className="dropdown-item text-info cursor-pointer"
                                  >
                                    <i className="fas fa-user-edit"></i> Edit
                                  </Link>
                                  <div className="dropdown-divider" />
                                  <button
                                    onClick={() => setModalId(`modal${i}`)}
                                    className="dropdown-item text-primary"
                                  >
                                    <i className="fas fa-key"></i> Change
                                    Password
                                  </button>
                                  <div className="dropdown-divider" />
                                  <Link
                                    to={`/admin/view-customers/${p.userId}`}
                                    className="dropdown-item text-success cursor-pointer"
                                  >
                                    <i className="fas fa-eye"></i> Review
                                  </Link>
                                  {p.flag === true ? (
                                    <>
                                      <div className="dropdown-divider" />
                                      <p
                                        onClick={() => BlockUser(p.userId)}
                                        className="dropdown-item text-danger cursor-pointer"
                                      >
                                        <i className="fas fa-user-lock"></i>{" "}
                                        Block
                                      </p>
                                    </>
                                  ) : null}
                                  {p.locked === false ? (
                                    <>
                                      <div className="dropdown-divider" />
                                      <p
                                        onClick={() => unLockUser(p.userId)}
                                        className="dropdown-item text-warning cursor-pointer"
                                      >
                                        <i className="fad fa-user-clock"></i>{" "}
                                        UnLock
                                      </p>
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <Link
                                  to={`/admin/view-customers/${p.userId}`}
                                  className="dropdown-item text-success cursor-pointer"
                                >
                                  <i className="fas fa-eye"></i> Review
                                </Link>
                              )}
                            </div>
                          </div>
                          <Modal
                            show={modalId === `modal${i}`}
                            // onHide={handleClose}
                            onHide={() => setModalId("")}
                          >
                            <Modal.Header
                            // closeButton
                            >
                              <Modal.Title>
                                Change {p.firstname} {p.lastname} Password{" "}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Formik
                                initialValues={{
                                  newPassword: "",
                                  confirmPassword: "",
                                  email: p.email,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) =>
                                  UpdatePasswordHandler(values)
                                }
                              >
                                {({
                                  values,
                                  handleSubmit,
                                  handleChange,
                                  errors,
                                  touched,
                                  isValid,
                                }) => (
                                  <Form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="inputPasswordNew"
                                      >
                                        New password
                                      </label>
                                      <input
                                        name="newPassword"
                                        type="password"
                                        placeholder="Enter New password"
                                        className="form-control"
                                        id="inputPasswordNew"
                                        onChange={handleChange}
                                        value={values.newPassword}
                                      />
                                      {errors.newPassword &&
                                      touched.newPassword ? (
                                        <div style={{ color: "red" }}>
                                          {errors.newPassword}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        htmlFor="inputPasswordNew2"
                                      >
                                        Verify password
                                      </label>
                                      <input
                                        name="confirmPassword"
                                        type="password"
                                        onChange={handleChange}
                                        placeholder="Enter New password Again"
                                        className="form-control"
                                        id="inputPasswordNew2"
                                      />
                                      {errors.confirmPassword &&
                                      touched.confirmPassword ? (
                                        <div style={{ color: "red" }}>
                                          {errors.confirmPassword}
                                        </div>
                                      ) : null}
                                    </div>
                                    <button
                                      id="sends"
                                      type="submit"
                                      className="btn btn-primary"
                                      ref={buttonLoader1}
                                      onClick={handleSubmit}
                                    >
                                      Save changes
                                    </button>
                                  </Form>
                                )}
                              </Formik>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="danger" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="row">
                <h3 className="text-center">No Customers Found</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 && (
        <Pagination
          onChange={setCurrentPage}
          page={currentEntries}
          total={totalPagesNum}
        />
      )}
    </>
  ) : (
    <Spinner />
  );
};

export default AllCustomerTabs;
