import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserDetails } from "../../../Common/Features/Action/AllUserDetailsAction";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import Spinner from "../Spinner";
import UserwrRegistrationValidation from "../../Validation/UserwrRegistrationValidation";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { getUserDetail } from "../Features/Action/AdminBlockUnblockAction";
import {
  getAllCountries,
  getAllDistrict,
  getAllProvince,
} from "../Features/Action/AddressAction";
import { fetchRewardStatus } from "../Features/Action/RewardAction";
import Forms from "react-bootstrap/Form";

const ProfileSetting = () => {
  // to get all user Details by their relavent email
  const { user, loader } = useSelector((s) => s.AdminReducer);

  const { countries, proviences, districts } = useSelector(
    (s) => s.AddressReducer
  );
  const { rewardStatus } = useSelector((s) => s.RewardReducer);

  const [newPassword, setPassword] = useState();
  const [images, setImage] = useState();
  const [rewardPoint, setRewardPoint] = useState(false);

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Save Changes",
    "Loading..."
  );
  const [buttonLoader1, setButtonLoader1] = useButtonLoader(
    "Save Changes",
    "Loading..."
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllUserDetails(localStorage.getItem("userId")));
    dispatch(getUserDetail(localStorage.getItem("userId")));
    dispatch(getAllCountries());
    dispatch(getAllDistrict());
    dispatch(getAllProvince());
    dispatch(fetchRewardStatus());
  }, []);

  useEffect(() => {
    setRewardPoint(rewardStatus.rewards_status);
  }, [rewardStatus, localStorage.getItem("userId")]);

  const updateProfileHandler = async (values) => {
    setButtonLoader(true);

    try {
      //   e.preventDefault();

      let data = new FormData();
      if (images != null) {
        data.append("image", images);
      }
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("email", values.email);
      data.append("address", values.address);
      data.append("phone_no", values.phone_no);
      if (values.countryId) {
        data.append("countryId", values.countryId);
      }
      if (values.provinceId) {
        data.append("provinceId", values.provinceId);
      }
      if (values.districtId) {
        data.append("districtId", values.districtId);
      }
      data.append("street_address", values.street_address);
      data.append("landmark", values.landmark);
      data.append("pin_code", values.pin_code);
      data.append("role", user.role);
      data.append("id", user.userId);
      const res = await AxiosConfig.post("/kreesa/admin/update-user", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res) {
        toast.success("Update user succesfully !");
        setButtonLoader(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(err + "error found");
      setButtonLoader(false);
      // setMessage('Filed to add New category !')
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };

  // admin update password
  const updatePasswordHandler = async (e) => {
    setButtonLoader1(true);
    e.preventDefault();
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let email = localStorage.getItem("username");
      const res = await AxiosConfig.post(
        `/kreesa/admin/update-password`,
        { newPassword, email },
        config
      );

      if (res) {
        toast.success(`Password Changes succesfully !`);
        setButtonLoader1(false);
      }
    } catch (err) {
      console.log(err + "error found");
      // setMessage('Filed to add New category !')
      // toast.error(err.response.data.message);
      setButtonLoader1(false);
    }
  };

  const rewardPointHandler = async () => {
    setRewardPoint(!rewardPoint);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.post(
        !rewardPoint
          ? `/kreesa/admin/rewards/enable/rewards`
          : `/kreesa/admin/rewards/disable/rewards`,
        config
      );

      if (res) {
        toast.success(res.data);
      }
    } catch (err) {
      console.log(err + "error found");
    }
  };

  UserwrRegistrationValidation();
  return !loader ? (
    <div className="container-fluid p-0">
      <h1 className="h3 mb-3">Settings</h1>

      <div className="row">
        <div className="col-md-3 col-xl-2">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 font-weight-bold">
                Profile Settings
              </h1>
            </div>
            <div className="list-group list-group-flush" role="tablist">
              <a
                className="list-group-item list-group-item-action active"
                data-toggle="list"
                href="#account"
                role="tab"
              >
                Account
              </a>
              <a
                className="list-group-item list-group-item-action"
                data-toggle="list"
                href="#password"
                role="tab"
              >
                Password
              </a>
              <a
                className="list-group-item list-group-item-action"
                data-toggle="list"
                href="#rewardSetting"
                role="tab"
              >
                Reward
              </a>
              <a
                className="list-group-item list-group-item-action"
                data-toggle="list"
                href="#"
                role="tab"
              >
                Your data
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-9 col-xl-10">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="account"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0 font-weight-bold">
                    Update Profile Info
                  </h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      first_name: user?.firstname,
                      last_name: user?.lastname,
                      email: user?.emailId,
                      address: user?.address,
                      phone_no: user?.contact,
                      role: user?.role,
                      countryId: user?.countryId,
                      provinceId: user?.provinceId,
                      districtId: user?.districtId,
                      street_address: user?.streetAddress,
                      landmark: user?.landmark,
                      pin_code: user?.pinCode,
                    }}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => updateProfileHandler(values)}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                    }) => (
                      <Form
                        encType="multipart/form-data"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-md-8">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputUsername"
                              >
                                E-mail
                              </label>
                              <input
                                // defaultValue={userDetails?.email}
                                value={values.email || ""}
                                style={{ cursor: "not-allowed" }}
                                disabled
                                type="text"
                                className="form-control"
                                id="inputUsername"
                                placeholder="Username"
                              />
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  First name
                                </label>
                                <input
                                  // defaultValue={userDetails?.firstname}
                                  // onChange={(e) => setFname(e.target.value)}
                                  name="first_name"
                                  onChange={handleChange}
                                  value={values.first_name || ""}
                                  type="text"
                                  className="form-control"
                                  id="inputFirstName"
                                  placeholder="First name"
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputLastName"
                                >
                                  Last name
                                </label>
                                <input
                                  // defaultValue={userDetails?.lastname}
                                  // onChange={(e) => setLname(e.target.value)}
                                  name="last_name"
                                  onChange={handleChange}
                                  value={values.last_name || ""}
                                  type="text"
                                  className="form-control"
                                  id="inputLastName"
                                  placeholder="Last name"
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                Street Address
                              </label>
                              <input
                                value={values.street_address}
                                type="text"
                                className="form-control"
                                id="inputStreetAddress"
                                placeholder="Enter Street Address"
                                name="street_address"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputEmail4"
                              >
                                Country
                              </label>
                              <select
                                name="countryId"
                                onChange={handleChange}
                                // onBlur={(e) => SetSelectProvince(e.target.value)}
                                value={values.countryId}
                                className="form-control"
                              >
                                <option value="">Select Your Country...</option>
                                {countries?.map((c, i) => (
                                  <option
                                    key={i}
                                    value={c.id}
                                    selected={values.countryId === c.id}
                                  >
                                    {c.country_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="mb-3 ">
                              <label
                                className="form-label"
                                htmlFor="inputEmail4"
                              >
                                Province
                              </label>
                              <select
                                name="provinceId"
                                onChange={handleChange}
                                // onBlur={(e) => SetSelectDistrict(e.target.value)}
                                value={values.provinceId}
                                className="form-control"
                              >
                                <option value="">
                                  Select Your Provience...
                                </option>
                                {proviences?.map(
                                  (pr, i) => (
                                    // selectProvince == pr.country.id && (
                                    <option
                                      key={i}
                                      value={pr.id}
                                      selected={values?.provinceId === pr.id}
                                    >
                                      {pr?.provience_name}
                                    </option>
                                  )
                                  // )
                                )}
                              </select>
                            </div>
                            <div className="mb-3 ">
                              <label
                                className="form-label"
                                htmlFor="inputEmail4"
                              >
                                District
                              </label>
                              <select
                                name="districtId"
                                onChange={handleChange}
                                value={values.districtId}
                                className="form-control"
                              >
                                <option value="">
                                  Select Your District...
                                </option>
                                {districts?.map(
                                  (d, i) => (
                                    // selectDistrict == d?.stateOrProvience?.id && (
                                    <option
                                      key={i}
                                      value={d.id}
                                      selected={values?.districtId === d.id}
                                    >
                                      {d.district_name}
                                    </option>
                                  )
                                  // )
                                )}
                              </select>
                            </div>
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="inputAddress"
                              >
                                Phone
                              </label>
                              <input
                                // defaultValue={userDetails?.phoneNo}
                                // onChange={(e) => setPhone(e.target.value)}
                                name="phone_no"
                                onChange={handleChange}
                                value={values.phone_no || ""}
                                type="text"
                                className="form-control"
                                id="inputPhone"
                                placeholder="Enter Phone"
                              />
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Landmark
                                </label>
                                <input
                                  // defaultValue={userDetails?.firstname}
                                  // onChange={(e) => setFname(e.target.value)}
                                  name="landmark"
                                  onChange={handleChange}
                                  value={values.landmark || ""}
                                  type="text"
                                  className="form-control"
                                  id="inputFirstName"
                                  placeholder="landmark"
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputLastName"
                                >
                                  Pin Code
                                </label>
                                <input
                                  // defaultValue={userDetails?.lastname}
                                  // onChange={(e) => setLname(e.target.value)}
                                  name="pin_code"
                                  onChange={handleChange}
                                  value={values.pin_code || ""}
                                  type="text"
                                  className="form-control"
                                  id="inputLastName"
                                  placeholder="Pin code"
                                />
                              </div>
                            </div>
                            {/* <div className="mb-3">
                                            <label className="form-label" htmlFor="inputAddress2">Permanent Address</label>
                                            <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />
                                        </div> 
                                         <div className="row">
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="inputCity">City</label>
                                                <input type="text" className="form-control" id="inputCity" />
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label" htmlFor="inputState">State</label>
                                                <select id="inputState" className="form-control">
                                                    <option selected>Choose...</option>
                                                    <option>...</option>
                                                </select>
                                            </div>
                                            <div className="mb-3 col-md-2">
                                                <label className="form-label" htmlFor="inputZip">Zip</label>
                                                <input type="text" className="form-control" id="inputZip" />
                                            </div>
                                        </div> */}
                          </div>
                          <div className="col-md-4">
                            <div className="text-center">
                              <img
                                alt={user?.firstname}
                                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/admin/${user?.userId}/${user?.profile}`}
                                className="rounded-circle img-responsive mt-2"
                                width="128"
                                height="128"
                              />
                              <div className="mt-2">
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    setImage(e.target.files[0]);
                                  }}
                                  name="image"
                                />
                              </div>
                              {/* <div className="mt-2">
                            <span className="">
                              <i className="fas fa-upload"></i> Upload
                            </span>
                          </div> */}
                              <small>
                                For best results, use an image at least 128px by
                                128px in .jpg format
                              </small>
                            </div>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary"
                          ref={buttonLoader}
                          // onClick={updateProfileHandler}
                          onClick={handleSubmit}
                        >
                          Save Changes
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="password" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Password</h5>

                  <form
                    id="user-wrregister-forms"
                    onSubmit={updatePasswordHandler}
                  >
                    {/* <div className="mb-3">
                      <label className="form-label" htmlFor="inputPasswordCurrent">
                        Current password
                      </label>
                      <input type="password" placeholder="Enter Current password" className="form-control" id="inputPasswordCurrent" />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputPasswordNew">
                        New password
                      </label>
                      <input
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Enter New password"
                        className="form-control"
                        id="inputPasswordNew"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputPasswordNew2">
                        Verify password
                      </label>
                      <input
                        name="confirm_password"
                        type="password"
                        placeholder="Enter New password Again"
                        className="form-control"
                        id="inputPasswordNew2"
                      />
                    </div>
                    <button
                      id="sends"
                      type="submit"
                      className="btn btn-primary"
                      ref={buttonLoader1}
                    >
                      Save changes
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {/* Reward Setting*/}
            <div className="tab-pane fade" id="rewardSetting" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">Reward Setting</p>
                  <hr />
                  <div className="mb-3 mb-3">
                    <div
                      className="form-check form-switch"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        style={{ height: "1.5rem", width: "2.5rem" }}
                        onChange={rewardPointHandler}
                        checked={rewardPoint}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                        style={{ fontWeight: 500 }}
                      >
                        Do You want To Off/On Reward Point?
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ProfileSetting;
