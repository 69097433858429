import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../Admin/Spinner";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { filterCustomOrder } from "../../../Admin/Features/Reducer/CustomOrderReducer";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { getCustomOrderByUserId } from "../../../../Common/Features/Action/CustomOrderAction";
import Pagination from "../../../Pagination";
import { cancelCustomOrder } from "../../../Admin/Features/Action/CustomOrderAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ShowCustomOrderDetails = () => {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const history = useHistory();

  // to get custom Order
  const { customOrderByUserId, loader } = useSelector(
    (s) => s.SingleCustomOrder
  );
  // console.log(customOrderByUserId)
  useEffect(() => {
    dispatch(getCustomOrderByUserId());
  }, []);

  const filterCustomOrderRef = () => {
    dispatch(filterCustomOrder(inputRef.current.value));
  };
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = customOrderByUserId?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(customOrderByUserId?.length / perPage);

  // reject custom order
  const handleCustomOrder = async (id) => {
    await dispatch(cancelCustomOrder(id));
    toast.success("Custom Order Cancelled !!");
  };
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              {/* <div
                onClick={() =>
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You agree to provide access to kreesa.com to order on your behalf",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#28a745",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, I do!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      history.push(`/retailer/custom-order/register`);
                    }
                  })
                }
                className="btn btn-lg btn-outline-info display-5 mt-2 cursor-pointer"
                style={{ float: "right" }}
              >
                Add Custom Order <i className="fas fa-bezier-curve ml-2"></i>
              </div> */}
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Custom Order"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterCustomOrderRef}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">Latest Custom Orders</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Custom Order Picture/Voice</th>
                  <th className="d-xl-table-cell">Last Date</th>
                  <th className="d-xl-table-cell">Custom Orders Description</th>
                  <th>Status</th>
                  {/* <th className="d-md-table-cell">Placed By</th> */}
                  <th className="d-md-table-cell">Ordered By</th>
                  <th>Order Status</th>
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map((p, i) => (
                  <tr key={i}>
                    <td>{perPage * (currentPage - 1) + i + 1}</td>
                    <td>
                      {p.fileType === "IMAGE" || p.fileType === null ? (
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/custom/${p.orderId}/${p.orderImage}`}
                          width="48"
                          height="48"
                          className="rounded-circle mr-2"
                          alt="Avatar"
                        />
                      ) : (
                        <audio
                          controls
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/public/audio/custom-order/${p.orderId}/${p.voiceMessage}`}
                          style={{ height: 35 }}
                        />
                      )}
                    </td>
                    <td className="d-xl-table-cell">
                      {" "}
                      {moment(p.estimatedDate).format("ll")}{" "}
                    </td>
                    <td className="d-xl-table-cell">
                      {ReactHtmlParser(p.orderDescription)}
                    </td>
                    {p.approval === false ? (
                      <td>
                        <span className="badge bg-info">Pending</span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge bg-success">Approval</span>
                      </td>
                    )}
                    <td className="d-md-table-cell">Retailer</td>
                    {p?.orderPlacementFlag === false ? (
                      <td>
                        <span className="badge bg-info">Pending</span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge bg-success">Approved</span>
                      </td>
                    )}
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#3b7ddd",
                          }}
                          className="btn-md btn btn-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <div className="dropdown-menu text-center">
                          {p.approval === false ? (
                            <>
                              <Link
                                className="dropdown-item text-info"
                                to={`/retailer/edit-custom-order/${p.orderId}`}
                              >
                                {" "}
                                <i className="fas fa-user-edit"></i> Edit{" "}
                              </Link>
                              {/* <div className="dropdown-divider" />

                                <div
                                  onClick={() => handleCustomOrder(p.orderId)}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <i className="fas fa-trash-alt text-danger"></i>{" "}
                                  Cancel
                                </div> */}
                            </>
                          ) : (
                            <>
                              {p.approval === false ? (
                                <div
                                  onClick={() => handleCustomOrder(p.orderId)}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <i className="fas fa-trash-alt text-danger"></i>{" "}
                                  Cancel
                                </div>
                              ) : (
                                <p className="text-info">No Action available</p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={customOrderByUserId}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ShowCustomOrderDetails;
