import { Pagination } from "@mantine/core";
import React, { useEffect, useState } from "react";
import ReactLinkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  getTotalNotification,
  MarkAsReadForAdmin,
} from "../Features/Action/AdminNotificationAction";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import { wholesaleReadNotification } from "../../RetailerWholeSaler/Features/Action/WholesalerAction";
import { userReadNotification } from "../../User/Features/action";

const NotificationDetails = ({ user }) => {
  const history = useHistory();
  // to get TotalNotifications
  const [allNotification, setAllNotification] = useState([]);
  const [loading, setLoading] = useState(true);
  //   const { AllNotification } = useSelector((s) => s.AdminNotificationReducer);

  // to get total notification
  const { TotalNotification } = useSelector(
    (s) => s.GetTotalNumberOfNotification
  );

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    setLoading(true);
    AxiosConfig.get(
      user === "ROLE_USER"
        ? `/kreesa/notification/${localStorage.getItem("userId")}`
        : user === "ROLE_RETAILER"
        ? `/kreesa/retailer/get-all-notification/${localStorage.getItem(
            "userId"
          )}`
        : user === "ROLE_WHOLESALER"
        ? `/kreesa/wholesaler/get-all-notification/${localStorage.getItem(
            "userId"
          )}`
        : "/kreesa/admin/notification/get-all-notification",
      config
    )
      .then((res) => setAllNotification(res.data))
      .finally(() => setLoading(false));
  }, [user]);

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const PER_PAGE = user === "ROLE_USER" ? 10 : 20;
  const [perPage] = useState(PER_PAGE);

  useEffect(() => {
    dispatch(getTotalNotification());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = allNotification.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(allNotification.length / perPage);

  return !loading ? (
    <div className="container">
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">All Notifications</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Message</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((p, i) => (
                    <tr
                      key={i}
                      onClick={async () => {
                        const user = localStorage.getItem("auth");

                        if (
                          user === "ROLE_WHOLESALER" ||
                          user === "ROLE_RETAILER"
                        ) {
                          await dispatch(wholesaleReadNotification(p.id));
                        } else if (user === "ROLE_USER") {
                          await dispatch(userReadNotification(p.id));
                        } else {
                          await dispatch(MarkAsReadForAdmin(p.id));
                        }

                        if (p?.route) {
                          history.push(p?.route);
                          history.go();
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>
                        {!p.readFlag ||
                          (p.read_flag && (
                            <i className="fa fa-eye text-info"></i>
                          ))}
                        &nbsp;&nbsp;
                        <ReactLinkify
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key
                          ) => (
                            <a target="blank" href={decoratedHref} key={key}>
                              {decoratedText}
                            </a>
                          )}
                        >
                          {p.notificationMessage || p.notification_message}
                        </ReactLinkify>
                      </td>
                      <td>
                        {moment(p.creationDate || p.created_date)
                          .local()
                          .format("lll")}
                      </td>
                      <td>
                        {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
                        localStorage.getItem("auth") === "ROLE_RETAILER" ||
                        localStorage.getItem("auth") === "ROLE_USER" ? (
                          <>
                            {!p.read_flag && (
                              <i className="fa fa-eye text-success font-weight-bold"></i>
                            )}
                            <span className="text-muted">
                              {" "}
                              {!p.read_flag ? "(Unseen)" : "(seen)"}
                            </span>
                          </>
                        ) : (
                          <>
                            {!p.readFlag && (
                              <i className="fa fa-eye text-success font-weight-bold"></i>
                            )}
                            <span className="text-muted">
                              {" "}
                              {!p.readFlag ? "(Unseen)" : "(seen)"}
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Notification Found !!!</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default NotificationDetails;
