import { createSlice } from "@reduxjs/toolkit";
import { fetchRewardStatus } from "../../Action/RewardAction";

const RewardReducer = createSlice({
  name: "RewardReducer",
  initialState: {
    rewardStatus: {},
    loader: false,
    errors: {},
  },
  reducers: {},
  extraReducers: {
    /* to get all Group Product */
    [fetchRewardStatus.pending]: (state, action) => {
      state.loader = true;
    },
    [fetchRewardStatus.fulfilled]: (state, action) => {
      state.loader = false;
      state.rewardStatus = action.payload;
    },
    [fetchRewardStatus.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
// export const { filterLegal } = RewardReducer.actions;
// export default
export default RewardReducer.reducer;
