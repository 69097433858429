import React from "react";
import PropTypes from "prop-types";

const ExportInExcel = (props) => {
  const { onClick } = props;

  return (
    <div
      className="btn btn-lg btn-outline-success display-5 mt-2 ml-4"
      style={{ float: "right" }}
      onClick={onClick}
    >
      Export <i className="fas fa-file-excel-o"></i>
    </div>
  );
};

export default ExportInExcel;

ExportInExcel.propTypes = {
  onClick: PropTypes.func.isRequired,
};
