import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  getUserInvoice,
  getUserQuotation,
  rejectUserQuotation,
} from "../Features/action/index";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";
import ViewQuoteModal from "../Quotation/ViewQuoteModal";
import FileSaver from "file-saver";
import MyQuotationDetails from "../../Retailer/MyQuotation/MyQuotationDetails";

const UserQuotationTable = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [perPage] = useState(7);
  const dispatch = useDispatch();

  const { quotation: userQuotation, loader } = useSelector(
    (state) => state.userProfile
  );

  useEffect(() => {
    dispatch(getUserQuotation(localStorage.getItem("userId")));
  }, []);

  const saveFile = (orderId, invoice) => {
    FileSaver.saveAs(
      `${AxiosConfig.defaults.baseURL}/kreesa/image/quote-invoice/${orderId}`,
      invoice
    );
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = userQuotation.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(userQuotation.length / perPage);
  return !loader ? (
    <div>
      <div className="">
        {currentEntries?.length > 0 ? (
          <div className="row">
            <div className="col-12 col-lg-8 d-flex w-100">
              <div className="card flex-fill overflow-auto">
                <div className="card-header">
                  <h5 className="card-title mb-0">Latest Quotation</h5>
                </div>
                <table className="table table-hover my-0 ">
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th className="d-xl-table-cell">Expected Price</th>
                      {/* <th className="d-xl-table-cell">Actual Price</th> */}
                      <th>Image</th>
                      <th>Admin</th>
                      <th>Saler</th>
                      <th>Delivery status</th>
                      <th>Payment Status</th>
                      <th>Payment Method</th>
                      <th>Invoice</th>
                      <th>Status</th>
                      <th className="d-md-table-cell">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries?.map((p, i) => (
                      <tr key={i}>
                        <td>{perPage * (currentPage - 1) + i + 1}</td>
                        <td>
                          {" "}
                          {/* <img
                            src={
                              p.image
                                ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${p.id}/${p.image}`
                                : "/images/kreesalogo.png"
                            }
                            width="48"
                            height="48"
                            loading="lazy"
                            className="rounded-circle mr-2"
                            alt={p.product_name}
                          /> */}
                          {p.product_name}
                        </td>
                        <td>{p.quantity}</td>
                        <td className="d-xl-table-cell">{p.expected_price}</td>
                        {/* <td className="d-xl-table-cell font-weight-bold">
                          {p.actual_price}
                        </td> */}

                        <td>
                          {" "}
                          <img
                            src={
                              p.image
                                ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${p.id}/${p.image}`
                                : "/images/kreesalogo.png"
                            }
                            alt={p.image}
                            height={50}
                            width={70}
                          />
                        </td>

                        {p.admin_approval === true ? (
                          <td>
                            <span className="badge bg-success">Approved</span>
                          </td>
                        ) : (
                          <td>
                            <span className="badge bg-danger">Pending</span>
                          </td>
                        )}

                        {p.buyer_approval === true ? (
                          <td>
                            <span className="badge bg-success">Approved</span>
                          </td>
                        ) : (
                          <td>
                            <span className="badge bg-danger">Pending</span>
                          </td>
                        )}

                        <td>{p?.delivery_status}</td>

                        <td>{p?.paymentStatus}</td>
                        <td>{p?.paymentMethod}</td>
                        <td>
                          {p.user_approval === true ? (
                            <div
                              className="cv"
                              style={{
                                color: "blue",
                                cursor: "pointer",
                              }}
                              onClick={() => saveFile(p.id, p.invoice)}
                            >
                              {p?.invoice} <i className="fa fa-download"></i>
                            </div>
                          ) : (
                            <p>Invoice not found</p>
                          )}
                        </td>
                        {/* quotationStatus */}
                        <td>
                          {p.user_approval === true ? (
                            <td>
                              <span className="badge bg-success">Approved</span>
                            </td>
                          ) : (
                            <td>
                              <span className="badge bg-danger">Pending</span>
                            </td>
                          )}

                          {/* {p.quote_status === "ASSIGN" ? (
                            <span className="badge bg-success">Assign</span>
                          ) : p.quote_status === "ACCEPTED" ? (
                            <span className="badge bg-success">Accepted</span>
                          ) : p.quote_status === "REJECTED" ? (
                            <span className="badge bg-danger">Rejected</span>
                          ) : p.quote_status === "NOT_ASSIGNED" ? (
                            <span className="badge bg-danger">
                              Not Assigned
                            </span>
                          ) : (
                            <span className="badge bg-info">Queued</span>
                          )} */}
                        </td>

                        <td className="d-md-table-cell">
                          <div className="btn-group">
                            <button
                              type="button"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#3b7ddd",
                              }}
                              className="btn-md btn btn-success dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More
                            </button>
                            <div className="dropdown-menu text-center">
                              <Link
                                to={`/user/quotations/${p.id}`}
                                className=" text-success"
                              >
                                <i className="fas fa-eye "></i> Review
                              </Link>
                              {/* <button
                                onClick={() => {
                                  setModal(true);
                                  setModalData(p);
                                  //   handleApproveQuotation(p.id);
                                }}
                                className="dropdown-item text-info"
                              >
                                <i className="fas fa-eye"></i> View
                              </button> */}
                              {p.final_approval !== "REJECTED" && (
                                <>
                                  {/* {p.user_approval === false && (
                                    <>
                                      {p.admin_approval === true &&
                                        p.buyer_approval === true && (
                                          <>
                                            <div className="dropdown-divider"></div>

                                            <button
                                              onClick={() => {
                                                history.push({
                                                  pathname: "/checkout-user",
                                                  state: [
                                                    {
                                                      quoteId: p.id,
                                                      orderQuantity: p.quantity,
                                                      name: p.product_name,
                                                      price: p.actual_price,
                                                      discount: 0,
                                                      discountPrice:
                                                        p.actual_price -
                                                        (p.actual_price * 0) /
                                                          100,
                                                      discountedTotalPrice:
                                                        (p.actual_price -
                                                          (p.actual_price * 0) /
                                                            100) *
                                                        p.quantity,
                                                      type: "quote",
                                                    },
                                                  ],
                                                });
                                              }}
                                              className="dropdown-item text-info"
                                            >
                                              <i className="fas fa-check"></i>{" "}
                                              Approve
                                            </button>
                                          </>
                                        )}{" "}
                                    </>
                                  )} */}
                                  {p.user_approval === false &&
                                    p.admin_approval === false && (
                                      <>
                                        <div className="dropdown-divider"></div>

                                        <button
                                          onClick={() => {
                                            dispatch(
                                              rejectUserQuotation({
                                                id: p.id,
                                                userId: p.user_id,
                                              })
                                            );
                                          }}
                                          className="dropdown-item text-danger"
                                        >
                                          <i className="fas fa-times"></i>{" "}
                                          Reject
                                        </button>
                                      </>
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* pagination  */}
            <Pagination
              pages={totalPagesNum}
              setCurrentPage={setCurrentPage}
              currentEntries={currentEntries}
              allEntries={userQuotation}
            />
          </div>
        ) : (
          <h4 className="text-center">No Quotation Found</h4>
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default UserQuotationTable;
