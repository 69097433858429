import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to getAllQuotations */
export const getAllQuotations = createAsyncThunk(
  "getAllQuotations",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/quotation/view-all-quotation`,
      config
    );
    return data;
  }
);

/* to getAllAssignedDelivert */
export const getAllAssignedDelivery = createAsyncThunk(
  "getAllAssignedDelivery",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/delivery/get-all-assign-delivery`,
      config
    );
    return data;
  }
);

/* to getAllAssignedQuotations */
export const getAllAssignedDeliveryQuotations = createAsyncThunk(
  "getAllAssignedDeliveryQuotations",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/delivery/get-all-assign-delivery-quotation`,
      config
    );
    return data;
  }
);

/* to get SingleQuotations */
export const getSingleQuotations = createAsyncThunk(
  "getSingleQuotations",
  async (id) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/quotation/view-single-quotation/${id}`,
      config
    );
    return data;
  }
);

/* to Approve Quotations */
export const ApproveQuotations = createAsyncThunk(
  "ApproveQuotations",
  async (id, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/quotation/approve-quotation/${id}`,
      config
    );
    dispatch(getAllQuotations());
    return data;
  }
);

// generate qr code
export const generateQrCode = createAsyncThunk(
  "generateQrCode",
  async (qrData, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/qr/${qrData.type}/${qrData.id}`,
      { responseType: "blob" }
    );
    window.open(URL.createObjectURL(data));

    return data;
  }
);

/* to Pay status Quotations */
export const ChangePaymentStatusQuotation = createAsyncThunk(
  "ChangePaymentStatusQuotation",
  async (id, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/quotation/change-payment-status-for-quote/${id}`,
      config
    );
    dispatch(getAllQuotations());
    return data;
  }
);

/* to Decline Quotations */
export const DeclineQuotations = createAsyncThunk(
  "DeclineQuotationss",
  async (quoteData, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/quotation/decline-quotation`,
      quoteData,
      config
    )
      .then((res) => toast.success("Quotation Declined"))
      .catch((err) => toast.error("An Error Occurred"));
    dispatch(getAllQuotations());
    return data;
  }
);

// export const AssignQuotationsByAdmin = createAsyncThunk(
//   "AssignQuotationsByAdmin",
//   async ({ values, id, history, toast }, { rejectWithValue }) => {
//     try {
//         const response = await api.signIn(formValue);
//         toast.success("Login Successfully");
//         navigate("/");

//         return response.data;
//     } catch (err) {
//         return rejectWithValue(err.response.data);
//     }
//   }
// );

/* to Assign Quotations */
export const AssignQuotationsByAdmin = createAsyncThunk(
  "admin/AssignQuotationsByAdmin",
  async (values, { dispatch }) => {
    const res = await AxiosConfig.post(
      `/kreesa/quotation/assign-quotation`,
      values
    );
    dispatch(getSingleQuotations(values.id));
    if (res && res.status === 200) {
      values.toast.success("Quotation Assigned Successfully");
      values.history.goBack();
      return res.data;
    } else {
      return values.toast.error("Something went wrong");
    }
  }
);

export const getAllWholesalers = createAsyncThunk(
  "admin/getAllWholesalers",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/get-all-wholesaler`,
      config
    );
    return data;
  }
);

export const getAllRetailers = createAsyncThunk(
  "admin/getAllRetailers",
  async () => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/get-all-retailer`,
      config
    );
    return data;
  }
);

export const getAllApprovedQuoteByBuyer = createAsyncThunk(
  "admin/getAllApprovedQuoteByBuyer",
  async (id) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/quotation/get-all-approved-quotation-by-buyer/${id}`,
      config
    );
    return data;
  }
);
