import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

//view
export const viewAllLegalDocs = createAsyncThunk(
  "viewAllLegalDocs",
  async () => {
    const response = await AxiosConfig.get(
      `/kreesa/legal/admin/view`,
      config
    ).catch((err) => toast.error("An Error Occurred"));
    return response.data;
  }
);

//single view
export const viewLegalDoc = createAsyncThunk("viewLegalDoc", async (id) => {
  const response = await AxiosConfig.get(
    `/kreesa/legal/admin/view/${id}`,
    config
  ).catch((err) => toast.error("An Error Occurred"));
  return response.data;
});
