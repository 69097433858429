import React from "react";
import TopNavbar from "../../../TopNavbar";
import LeftNavbar from "../../../LeftNavbar";
import Footer from "../../../Footer";
import { Redirect } from "react-router-dom";
import CountryForm from "./CountryForm";

const AddCountry = () => {
  document.title = "Admin | Add Country";
  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <CountryForm />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AddCountry;
