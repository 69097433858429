import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SellerTab from "./SellerTab";
import BuyerTab from "./BuyerTab";

const FAQDetails = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link
                to="/admin/add-faq"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add FAQ <i className="fa fa-question-circle"></i>
              </Link>
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search FAQs…"
                    aria-label="Search"
                    // ref={inputRef}
                    // onChange={filterCategory}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="row">
        <Tabs>
          <TabList>
            <Tab>Seller</Tab>
            <Tab>Buyer</Tab>
          </TabList>

          <TabPanel>
            {/* Seller Users */}

            <SellerTab />
          </TabPanel>

          <TabPanel>
            {/* Buyer Users */}

            <BuyerTab />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default FAQDetails;
