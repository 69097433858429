import { createSlice } from "@reduxjs/toolkit";
import { getRetailerProducts } from '../../../Action/ProductAction'

const RetailerProduct = createSlice({
  name: "RetailerProduct",
  initialState: {
    Retailers: [],
    RetailersContainer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterOfRetailer: (state, action) => {
      state.Retailers = state.RetailersContainer.filter((u) =>
        u.productName.toLowerCase().includes(action.payload) ||
        u.productName.toUpperCase().includes(action.payload)
        // u.productName.toLowerCase().includes(action.payload) ||
        // u.productName.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Products */
    [getRetailerProducts.pending]: (state, action) => {
      state.loader = true;
    },
    [getRetailerProducts.fulfilled]: (state, action) => {
      state.loader = false;
      state.Retailers = action.payload;
      state.RetailersContainer = action.payload;
    },
    [getRetailerProducts.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterOfRetailer } = RetailerProduct.actions;
// export default
export default RetailerProduct.reducer;
