import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import {
  getProductsOfRetailer,
  getProductsOfWholesaler,
} from "../ProductAction";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to make feature product  */
export const makeFeatureProduct = createAsyncThunk(
  "makeFeatureProduct",
  async (productId, { rejectWithValue }) => {
    try {
      const { data } = await AxiosConfig.get(
        `/kreesa/admin/feature/make-product-feature/${productId}`,
        config
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

/* to remove feature product */
export const removeFeature = createAsyncThunk(
  "removeFeature",
  async (productId, { rejectWithValue }) => {
    try {
      const { data } = await AxiosConfig.get(
        `/kreesa/admin/feature/remove-product-feature/${productId}`,
        config
      );
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const retailWholesaleFeatureProduct = createAsyncThunk(
  "retailWholesaleFeatureProduct",
  async (type) => {
    const response = await AxiosConfig.get(
      `/kreesa/admin/feature/get-all-features-product/${type}`,
      config
    );
    return response.data;
  }
);

//organic product
export const getAllOrganicProduct = createAsyncThunk(
  "getAllOrganicProduct",
  async (type) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/get-all-organic-product/${type}`,
      config
    );
    return response.data;
  }
);

export const makeProductOrganic = createAsyncThunk(
  "makeProductOrganic",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/make-product-organic/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        toast.info("Added to Organic Product");
      })
      .catch((err) => {
        toast.error(
          !err.response ? "An error occurred" : err.response.data.message
        );
      });

    return response.data;
  }
);

export const removeOrganicProduct = createAsyncThunk(
  "removeOrganicProduct",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/remove-product-organic/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        dispatch(getAllOrganicProduct(data.type));
        toast.success("Product successfully removed");
      })
      .catch((err) => toast.error("An error occurred"));
    return response.data;
  }
);

//trending product
export const getAllTrendingProduct = createAsyncThunk(
  "getAllTrendingProduct",
  async (type) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/get-all-trend-product/${type}`,
      config
    );
    return response.data;
  }
);

export const makeProductTrending = createAsyncThunk(
  "makeProductTrending",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/make-product-trending/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        toast.info("Added to Trending Product");
      })
      .catch((err) =>
        toast.error(
          !err.response ? "An error occurred" : err.response.data.message
        )
      );

    return response.data;
  }
);

export const removeTrendingProduct = createAsyncThunk(
  "removeTrendingProduct",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/remove-product-trending/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        dispatch(getAllTrendingProduct(data.type));
        toast.success("Product successfully removed");
      })
      .catch((err) => toast.error("An error occurred"));

    return response.data;
  }
);

//kreesa product
export const getAllKreesaProduct = createAsyncThunk(
  "getAllKreesaProduct",
  async (type) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/get-all-kreesa-product/${type}`,
      config
    );
    return response.data;
  }
);

export const makeProductKreesa = createAsyncThunk(
  "makeProductKreesa",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/make-product-kreesa/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        toast.info("Added to Kreesa Product");
      })
      .catch((err) =>
        toast.error(
          !err.response ? "An error occurred" : err.response.data.message
        )
      );
    return response.data;
  }
);

export const removeKreesaProduct = createAsyncThunk(
  "removeKreesaProduct",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/remove-product-kreesa/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        dispatch(getAllKreesaProduct(data.type));
        toast.success("Product successfully removed");
      })
      .catch((err) => toast.error("An error occurred"));
    return response.data;
  }
);

//kreesa product
export const getAllBrandProduct = createAsyncThunk(
  "getAllBrandProduct",
  async (type) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/get-all-brand-product/${type}`,
      config
    );
    return response.data;
  }
);

export const makeProductBrand = createAsyncThunk(
  "makeProductBrand",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/make-product-brand/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
      })
      .catch((err) =>
        toast.error(
          !err.response ? "An error occurred" : err.response.data.message
        )
      );
    return response.data;
  }
);

export const removeBrandProduct = createAsyncThunk(
  "removeBrandProduct",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `kreesa/admin/feature/remove-product-brand/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        dispatch(getAllBrandProduct(data.type));
        toast.success("Product successfully removed");
      })
      .catch((err) => toast.error("An error occurred"));
    return response.data;
  }
);

//flash product
export const getAllFlashProduct = createAsyncThunk(
  "getAllFlashProduct",
  async (type) => {
    const response = await AxiosConfig.get(
      `admin/product/flashsale/get-all-flash-product/${type}`,
      config
    );
    return response.data;
  }
);

export const makeProductFlash = createAsyncThunk(
  "makeProductFlash",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `admin/product/flashsale/add-flash-sales`,
      data,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        toast.success(res.data);
      })
      .catch((err) =>
        toast.error(
          !err.response ? "An error occurred" : err.response.data.message
        )
      );
    return response.data;
  }
);

export const removeFlashProduct = createAsyncThunk(
  "removeFlashProduct",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.get(
      `admin/product/flashsale/remove-from-flash-sales/${data.id}`,
      config
    )
      .then((res) => {
        data.type === "wholesale"
          ? dispatch(getProductsOfWholesaler())
          : dispatch(getProductsOfRetailer());
        dispatch(getAllFlashProduct(data.type));
        toast.success(res.data);
      })
      .catch((err) => toast.error("An error occurred"));
    return response.data;
  }
);

export const updateFlashProduct = createAsyncThunk(
  "updateFlashProduct",
  async (data, { dispatch }) => {
    const response = await AxiosConfig.post(
      `admin/product/flashsale/update-flash-sales`,
      data,
      config
    )
      .then((res) => {
        toast.success("Flash Product updated successfully");
        dispatch(getAllFlashProduct(data.type));
      })
      .catch((err) => toast.error("An error occurred"));
    return response.data;
  }
);
