import { createSlice } from "@reduxjs/toolkit";
import {
  getAllApplications,
  getAllFaqsForAdmin,
  getallVacancies,
  getSingleFaqsForAdmin,
  getUserDetail,
  getAllBlogForAdmin,
  getVacancy,
  getSingleNewsBlogNotice,
  getAllComplimentAdmin,
  getAllAdmistrator,
  getAllRetailer,
  getAllWholesaler,
  getAllCustomer,
  dashboardAnalytics,
} from "../../Action/AdminBlockUnblockAction";

const AdminReducer = createSlice({
  name: "AdminReducer",
  initialState: {
    vacancies: [],
    vacanciesContainer: [],
    vacancy: [],
    applications: [],
    applicationsContainer: [],
    loader: false,
    faq: [],
    sellerFaqs: [],
    buyerFaqs: [],
    faqsContainer: [],
    errors: {},
    user: [],
    blogs: [],
    blog: [],
    blogsContainer: [],
    compliments: [],
    complimentsContainer: [],
    admistrator: [],
    admistratorContainer: [],
    retailers: [],
    retailersContainer: [],
    wholesalers: [],
    wholesalersContainer: [],
    customers: [],
    customersContainer: [],
    analytics: [],
  },
  reducers: {
    filterCustomer: (state, action) => {
      state.customers = state.customersContainer.filter(
        (u) =>
          u.firstname.toLowerCase().includes(action.payload) ||
          u.firstname.toUpperCase().includes(action.payload) ||
          u.lastname.toLowerCase().includes(action.payload) ||
          u.lastname.toUpperCase().includes(action.payload)
      );
    },
    filterWholesaler: (state, action) => {
      state.wholesalers = state.wholesalersContainer.filter(
        (u) =>
          u.companyName.toLowerCase().includes(action.payload) ||
          u.companyName.toUpperCase().includes(action.payload)
      );
    },
    filterRetailer: (state, action) => {
      state.retailers = state.retailersContainer.filter(
        (u) =>
          u.companyName.toLowerCase().includes(action.payload) ||
          u.companyName.toUpperCase().includes(action.payload)
      );
    },
    filterAdmistrator: (state, action) => {
      state.admistrator = state.admistratorContainer.filter(
        (u) =>
          u.firstname.toLowerCase().includes(action.payload) ||
          u.firstname.toUpperCase().includes(action.payload) ||
          u.lastname.toLowerCase().includes(action.payload) ||
          u.lastname.toUpperCase().includes(action.payload)
      );
    },
    filterCompliments: (state, action) => {
      state.compliments = state.complimentsContainer.filter(
        (u) =>
          u.complement_type.toLowerCase().includes(action.payload) ||
          u.complement_type.toUpperCase().includes(action.payload)
      );
    },
    filterVacancies: (state, action) => {
      state.vacancies = state.vacanciesContainer.filter(
        (u) =>
          u.title.toLowerCase().includes(action.payload) ||
          u.title.toUpperCase().includes(action.payload)
      );
    },
    filterAppliedVaccencies: (state, action) => {
      state.applications = state.applicationsContainer.filter(
        (u) =>
          u.name.toLowerCase().includes(action.payload) ||
          u.name.toUpperCase().includes(action.payload)
      );
    },
    filterBlogs: (state, action) => {
      state.blogs = state.blogsContainer.filter(
        (u) =>
          u.title.toLowerCase().includes(action.payload) ||
          u.title.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    [getAllCustomer.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllCustomer.fulfilled]: (state, action) => {
      state.loader = false;
      state.customers = action.payload;
      state.customersContainer = action.payload;
    },
    [getAllCustomer.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //get dashboard analytics
    [dashboardAnalytics.pending]: (state, action) => {
      state.loader = true;
    },
    [dashboardAnalytics.fulfilled]: (state, action) => {
      state.loader = false;
      state.analytics = action.payload;
      state.wholesalersContainer = action.payload;
    },
    [dashboardAnalytics.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllWholesaler.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllWholesaler.fulfilled]: (state, action) => {
      state.loader = false;
      state.wholesalers = action.payload;
      state.wholesalersContainer = action.payload;
    },
    [getAllWholesaler.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllRetailer.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllRetailer.fulfilled]: (state, action) => {
      state.loader = false;
      state.retailers = action.payload;
      state.retailersContainer = action.payload;
    },
    [getAllRetailer.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    //
    [getAllAdmistrator.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllAdmistrator.fulfilled]: (state, action) => {
      state.loader = false;
      state.admistrator = action.payload;
      state.admistratorContainer = action.payload;
    },
    [getAllAdmistrator.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // get all complaints
    [getAllComplimentAdmin.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllComplimentAdmin.fulfilled]: (state, action) => {
      state.loader = false;
      state.compliments = action.payload;
      state.complimentsContainer = action.payload;
    },
    [getAllComplimentAdmin.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* to get all Products */
    [getallVacancies.pending]: (state, action) => {
      state.loader = true;
    },
    [getallVacancies.fulfilled]: (state, action) => {
      state.loader = false;
      state.vacancies = action.payload;
      state.vacanciesContainer = action.payload;
    },
    [getallVacancies.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [getVacancy.pending]: (state, action) => {
      state.loader = true;
    },
    [getVacancy.fulfilled]: (state, action) => {
      state.loader = false;
      state.vacancy = action.payload;
    },
    [getVacancy.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getAllApplications.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllApplications.fulfilled]: (state, action) => {
      state.loader = false;
      state.applications = action.payload;
      state.applicationsContainer = action.payload;
    },
    [getAllApplications.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get all faqs
    [getAllFaqsForAdmin.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllFaqsForAdmin.fulfilled]: (state, action) => {
      state.loader = false;
      state.faqsContainer = action.payload;
      //
      let FAQ = action.payload;
      //
      let array1 = [];
      let array2 = [];
      FAQ.forEach((element) => {
        if (element.faqType === "SELLER") {
          array1.push(element);
        } else if (element.faqType === "BUYER") {
          array2.push(element);
        }
      });
      state.sellerFaqs = array1;
      state.buyerFaqs = array2;
    },
    [getAllFaqsForAdmin.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get single faqs
    [getSingleFaqsForAdmin.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleFaqsForAdmin.fulfilled]: (state, action) => {
      state.loader = false;
      state.faq = action.payload;
    },
    [getSingleFaqsForAdmin.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get user details
    [getUserDetail.pending]: (state, action) => {
      state.loader = true;
    },
    [getUserDetail.fulfilled]: (state, action) => {
      state.loader = false;
      state.user = action.payload;
    },
    [getUserDetail.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get all blogs for admin
    [getAllBlogForAdmin.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllBlogForAdmin.fulfilled]: (state, action) => {
      state.loader = false;
      state.blogs = action.payload;
      state.blogsContainer = action.payload;
    },
    [getAllBlogForAdmin.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get single blogs for admin
    [getSingleNewsBlogNotice.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleNewsBlogNotice.fulfilled]: (state, action) => {
      state.loader = false;
      state.blog = action.payload;
    },
    [getSingleNewsBlogNotice.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const {
  filterVacancies,
  filterAppliedVaccencies,
  filterBlogs,
  filterCompliments,
  filterAdmistrator,
  filterRetailer,
  filterWholesaler,
  filterCustomer,
} = AdminReducer.actions;
// export default
export default AdminReducer.reducer;
