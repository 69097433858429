import React, { useEffect, useState } from "react";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import Footer from "../../Footer";
import { Redirect, useHistory } from "react-router-dom";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { useSelector, useDispatch } from "react-redux";
import { getPrimaryCategory } from "../../Features/Action/CategoryAction/CategoryAction";
import { toast } from "react-toastify";
import useButtonLoader from "../../../ButtonLoaderHook";
import AdminCategoryValidation from "../../../Validation/AdminCategoryValidation";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

const SecondaryCategoryFrom = () => {
  const history = useHistory();
  document.title = "Admin | Add Secondary Category";

  // to get primary Category
  const { primaryCategories } = useSelector((s) => s.primaryCategory);
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add Secondary Category",
    "Loading..."
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrimaryCategory());
  }, []);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [secondaryCategoryName, setSecondaryCategory] = useState("");
  const [secondaryCategoryStatus, setSecondaryStatus] = useState("1");
  const [primaryCategoryId, setPrimaryCategoryId] = useState();

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  // seconary category
  const addCategoryHandler = async (e) => {
    setButtonLoader(true);
    try {
      e.preventDefault();
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const des = draftToHtml(convertToRaw(description.getCurrentContent()));
      const res = await AxiosConfig.post(
        "/kreesa/admin/category/secondary",
        {
          primaryCategoryId,
          description: des,
          secondaryCategoryName,
          secondaryCategoryStatus,
        },
        config
      );
      if (res) {
        toast.success("New Secondary Category added succesfully !");
        setButtonLoader(false);
        history.push("/admin/secondary-category/view");
      }
    } catch (err) {
      console.log(err + "error found");
      // setMessage('Filed to add New category !')
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      // toast.error(err.response.data.message);
      setButtonLoader(false);
    }
  };
  AdminCategoryValidation();
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title font-weight-bold">
                    Secondary Category Form Category Form
                  </h5>
                  <h6 className="card-subtitle text-muted">
                    {" "}
                    You Can Easily Add Secondary Category.
                  </h6>
                </div>
                <div className="card-body">
                  <form id="acategoryadd-forms">
                    <div className="mb-3 col-md-4">
                      <label
                        className="form-label font-weight-bold"
                        htmlFor="inputState"
                      >
                        Primary Category <span className="text-danger"> *</span>
                      </label>
                      <select
                        id="inputState"
                        required
                        name="role"
                        onChange={(e) => {
                          const scat = e.target.value;
                          setPrimaryCategoryId(scat);
                        }}
                        className="form-control"
                      >
                        <option value="">
                          Select Primary Category First...
                        </option>

                        {/* to get all Primary Categories */}
                        {primaryCategories?.map((p, i) => (
                          <option key={i} value={p.id}>
                            {p.categoryName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col">
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Secondary Category{" "}
                          </span>{" "}
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          name="SecondaryCategory"
                          id="inputCity"
                          onChange={(e) => setSecondaryCategory(e.target.value)}
                          placeholder="Enter Secondary Category Name"
                        />
                      </div>
                      <div className="mb-3 mt-2 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">Status </span>
                        </label>
                        <input
                          className="form-check-input ml-2"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          checked={secondaryCategoryStatus === "1"}
                          value="1"
                          onChange={(e) => {
                            setSecondaryStatus(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label pl-2"
                          htmlFor="exampleRadios1"
                        >
                          {" "}
                          Active
                        </label>
                        <input
                          className="form-check-input ml-2"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          checked={secondaryCategoryStatus === "0"}
                          value="0"
                          onChange={(e) => {
                            setSecondaryStatus(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label pl-2"
                          htmlFor="exampleRadios2"
                        >
                          {" "}
                          Inactive
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3 col-md-8">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Category Description
                          </span>
                          <span className="text-info"> (optional)</span>
                        </label>
                        <Editor
                          editorState={description}
                          onEditorStateChange={onEditorStateChange}
                          editorStyle={{
                            backgroundColor: "white",
                            minHeight: "150px",
                            padding: "10px 20px",
                          }}
                          editorClassName="mt-2 p-10 mx-auto"
                        />
                      </div>
                    </div>
                    <div className="mb-3 mt-4 col-md-4">
                      <button
                        id="catadd"
                        type="submit"
                        className="btn btn-success btn-lg btn-block"
                        ref={buttonLoader}
                        onClick={addCategoryHandler}
                      >
                        Add Secondary Category
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SecondaryCategoryFrom;
