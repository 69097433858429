import $ from "jquery";
import "jquery-validation";

const UserRegisterValidation = () => {
    $(document).on("ready", function() {
        $.validator.addMethod(
            "strongePassword",
            function(value) {
                return (
                    /^[A-Za-z0-9\d=!\-@._*]*$/.test(value) &&
                    /[a-z]/.test(value) &&
                    /\d/.test(value) &&
                    /[A-Z]/.test(value)
                );
            },
            "The password must contain at least 1 number, at least 1 lower case letter, and at least 1 upper case letter"
        );
        $.validator.addMethod(
            "lettersonly",
            function(value, element) {
                return this.optional(element) || value == value.match(/^[a-zA-Z ]*$/);
            },
            "Letters only please"
        );

        $("#rw-signup-forms").validate({
            // Specify validation rules
            rules: {
                first_name: {
                    required: true,
                    lettersonly: true,
                },
                last_name: {
                    required: true,
                    lettersonly: true,
                },
                address: {
                    required: true,
                },
                phone_no: {
                    required: true,
                    number: true,
                    maxlength: 10,
                    minlength: 6,
                },
                email: {
                    required: true,
                    email: true,
                },
                role: {
                    required: true,
                },
                password: {
                    required: true,
                    strongePassword: true,
                    minlength: 8,
                },
                confirm_password: {
                    required: true,
                    equalTo: '[name="password"]',
                },
                accept: {
                    required: true,
                },

            },

            messages: {
                first_name: {
                    required: "First name is required",
                },
                last_name: {
                    required: "Last name is required",
                    lettersonly: "Letters only",
                },
                address: {
                    required: "Address is required",
                },
                phone_no: {
                    required: "Phone number is required",
                },
                email: {
                    required: "Email is required",
                },
                role: {
                    required: "Role is required",
                },
                password: {
                    required: "Password is required",
                },
                confirm_password: {
                    required: "Confirm password is required",
                    equalTo: "Password doesn't match",
                },
                accept: {
                    required: "Accept the terms and conditions",
                },

            },

            highlight: function(element) {
                $(element).removeClass("is-valid").addClass("is-invalid");
            },
            unhighlight: function(element) {
                $(element).removeClass("is-invalid").addClass("is-valid");
            },
        });
        $("#sends").on("click", function() {
            $("#rw-signup-forms").valid();
        });
        $("#rw-signup-forms input").on("keyup blur click", function() {
            // fires on every keyup & blur
            if ($("#rw-signup-forms").valid()) {
                // checks form for validity
                $("button#sends").prop("disabled", false); // enables button
            } else {
                $("button#sends").prop("disabled", "disabled"); // disables button
            }
        });
    });
};

export default UserRegisterValidation;