import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import {
  AdminConfirmDelivery,
  getAllReturnOrder,
} from "../../Features/Action/OrderStatusAction";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mantine/core";
import { filterReturnOrder } from "../../Features/Reducer/OrderStatusReducer";
import Modal from "../Modal";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import Swal from "sweetalert2";

const ReturnOrderDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const history = useHistory();
  const [state, setstate] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  const [modal, setModal] = useState(false);
  const [orderId, setOrderId] = useState();

  const dispatch = useDispatch();
  const inputRef = useRef("");

  const { returnOrders, loader, deliveryBoys } = useSelector(
    (s) => s.AdminDeliveryReducer
  );

  // handleConfirmDelivery
  const handleConfirmDelivery = (id) => {
    dispatch(AdminConfirmDelivery(id));
  };
  useEffect(() => {
    // getOrders();
    dispatch(getAllReturnOrder());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = returnOrders?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(returnOrders?.length / perPage);

  const filter = () => {
    dispatch(filterReturnOrder(inputRef.current.value));
  };

  // approve Order
  const handleReturnConfirm = async (id) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetMyOrderDetails = await AxiosConfig.get(
        `/kreesa/admin/return/approve-return-request/${id}`,
        config
      );
      toast.success("Return Request Approved");
      history.push("/admin/order/return");

      dispatch(getAllReturnOrder());

      setstate(GetMyOrderDetails.data);
      setIsLoaded(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };

  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Orders…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
              <h4 className="d-none d-lg-flex" style={{ float: "right" }}>
                All Return Orders
              </h4>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Return Orders</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th className="d-xl-table-cell">Product Name</th>
                    <th>Product Color </th>
                    <th className="d-xl-table-cell">Price </th>
                    <th>Quantity</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td className="d-xl-table-cell">{p.productName}</td>
                      <td>{p.productColor}</td>
                      <td className="d-xl-table-cell"> Rs. {p.productPrice}</td>
                      <td>{p.quantity}</td>
                      <td>Rs. {Math.round(p.totalAmmount)}</td>
                      <td>
                        <span
                          className={`badge text-capitalize bg-${
                            p.approval === "PENDING"
                              ? "info"
                              : p.approval === "REJECTED"
                              ? "danger"
                              : "success"
                          }`}
                        >
                          {p.approval}
                        </span>
                      </td>
                      <td>{p?.reason}</td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              to={`/admin/order/return/view/${p.retrunId}`}
                              className="dropdown-item text-info"
                            >
                              <i className="fas fa-eye"></i> View More
                            </Link>

                            {p.approval === "PENDING" && (
                              <>
                                <div className="dropdown-divider"></div>
                                <div
                                  onClick={() => {
                                    handleReturnConfirm(p?.retrunId);
                                  }}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <i className="fas fa-check text-success"></i>{" "}
                                  Approve
                                </div>
                                <div className="dropdown-divider"></div>
                                <div
                                  className="text-danger cursor-pointer d-flex align-items-center justify-content-center"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Enter Return Reason",
                                      input: "text",
                                      inputAttributes: {
                                        autocapitalize: "off",
                                      },
                                      showCancelButton: true,
                                      confirmButtonText: "Confirm",
                                      showLoaderOnConfirm: true,
                                      preConfirm: (reason) => {
                                        AxiosConfig.post(
                                          `/kreesa/admin/return/decline-return-order`,
                                          {
                                            id: p?.retrunId,
                                            reason,
                                          },
                                          {
                                            headers: {
                                              Authorization: `Bearer ${localStorage.getItem(
                                                "token"
                                              )}`,
                                            },
                                          }
                                        )
                                          .then((res) => {
                                            toast.info(res.data);
                                          })
                                          .catch((err) =>
                                            toast.error("An Error Occurred")
                                          );
                                      },
                                      allowOutsideClick: () =>
                                        !Swal.isLoading(),
                                    }).then((result) => {});
                                  }}
                                >
                                  <i className="fas fa-close mr-2"></i>Decline
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Modal
            modal={modal}
            setModal={setModal}
            data={deliveryBoys}
            orderId={orderId}
          />
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="p-2 text-center">
          <h4>No Data Found</h4>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default ReturnOrderDetails;
