import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { GET_USER, UPDATE_USER } from "../Types/types";

const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

/* to get all User Details */

export const getAllUserDetails = createAsyncThunk(
  "getAllUserDetails",
  async (userId) => {
    // getAllUserDetails/pending
    // getAllUserDetails/fulfilled
    // getAllUserDetails/rejected
    const response = await AxiosConfig.get(
      `/kreesa/user/view/${userId}`,
      config
    );
    return response.data;
  }
);

export const updateUser = createAsyncThunk("updateUser", async () => {
  const res = await AxiosConfig.post("/kreesa/wholesaler/update-profile");
  return res.data;
});

// export const updateUser = createAsyncThunk('updateUser', async () => {
//     return (dispatch) => {
//     try {

//       const { data } = AxiosConfig.post("/kreesa/wholesaler/update-profile");

//       dispatch({ type: UPDATE_USER, payload: data });

//     } catch (error) {
//       console.log(error);
//     }
// }
//   });
