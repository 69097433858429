import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import "./static/css/app.css";
import AdminRoute from "./Route/Admin";
import UserRoute from "./Route/User";
import RetailerRoute from "./Route/Retailer";
import WholeSalerRoute from "./Route/WholeSaler";
import RetailerWholeSaler from "./Route/RetailerWholeSaler";
import DeliveryRoute from "./Route/DeliveryBoy";
import { ScrollToTop } from "./utils";

function App() {
  return (
    <ScrollToTop>
      <UserRoute />
      <AdminRoute />
      <RetailerRoute />
      <WholeSalerRoute />
      <RetailerWholeSaler />
      <DeliveryRoute />
    </ScrollToTop>
  );
}

export default App;
