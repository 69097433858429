import React from 'react'
import TopNavbar from '../../TopNavbar'
import LeftNavbar from '../../LeftNavbar/'
import SecondaryCategoryDetail from './SecondaryCategoryDetail'
import Footer from '../../Footer'
import { Redirect } from 'react-router-dom'


const SecondaryCategory = () => {
  document.title = "Admin | Show Category"
  // to check authentication user or not
  let token = localStorage.getItem('token', 'username','auth');
  if (token === null) {
    return <Redirect exact to="/admin/login" />
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <SecondaryCategoryDetail />
          </main>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default SecondaryCategory
