import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to reward points
export const fetchRewardStatus = createAsyncThunk(
  "updateRewardPoints",
  async (_, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/rewards/get/rewards/status`
    );

    return data;
  }
);
