import { useRef, useState, useEffect } from "react";

const useButtonLoader = (defaultText = "", loadingText = "Loading...") => {
  const [isLoading, setLoading] = useState(false);
  const element = useRef(null);

  useEffect(() => {
    if (isLoading) {
      element.current.disabled = true;
      element.current.innerHTML = '<i class="fas fa-spinner fa-spin"></i> ' + loadingText;
    } else if(element.current) {
        element.current.disabled = false;
        element.current.innerHTML = defaultText;
    }
  }, [element, isLoading]);
  return [element, setLoading];
};

export default useButtonLoader;
