import React, { useEffect, useState } from "react";
import "./style.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../../ButtonLoaderHook";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyType } from "../../../Admin/Features/Action/CompanyTypeAction/CompanyTypeAction";
import {
  checkemail,
  checkPhone,
} from "../../../User/UserSignup/CheckExistValue";
import { getAllCountries } from "../../../Admin/Features/Action/AddressAction";
import { getAllProvince } from "../../../Admin/Features/Action/AddressAction";
import { getAllDistrict } from "../../../Admin/Features/Action/AddressAction";

const validationSchema = Yup.object().shape({
  company_name: Yup.string().required("Company Name is required"),
  // company_address: Yup.string().required("Company Address is required"),
  companyTypeId: Yup.string().required("Select Company Type"),
  countryId: Yup.string().required("Select Country"),
  paymentType: Yup.string().required("Commission Type is required"),
  provinceId: Yup.string().required("Select Province"),
  districtId: Yup.string().required("Select District"),
  paymentRate: Yup.number()
    .required("Commission Rate is required")
    .typeError("Must be a positive Number"),
  company_contact_no: Yup.number().required("Contact Number is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  role: Yup.string().required("Role is required"),
  hasInsured: Yup.string().required("HasInsuranced is required"),
  hasRegistered: Yup.boolean(),
  pan_no: Yup.string().when("hasRegistered", (hasRegistered) => {
    if (hasRegistered) {
      return Yup.string().required("PAN Number is required");
    }
  }),
  image: Yup.mixed().when("hasRegistered", (hasRegistered) => {
    if (hasRegistered) {
      return Yup.mixed().required("image is required");
      // .test(
      //   "fileType",
      //   "Only accept Jpg/Jpeg/Png file type",
      //   (file) =>
      //     file && ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
      // )
    }
  }),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is ")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  accept: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});

const AddRWForm = () => {
  const { companyType, loader } = useSelector((s) => s.CompanyType);
  const [visible, setVisible] = useState(false);
  // const [file, setFile] = useState();
  const [logofile, setLogoFile] = useState();
  const { countries } = useSelector((s) => s.AddressReducer);
  const { proviences } = useSelector((s) => s.AddressReducer);
  const { districts } = useSelector((s) => s.AddressReducer);

  const [selectProvince, SetSelectProvince] = useState([]);
  const [selectDistrict, SetSelectDistrict] = useState([]);
  const dispatch = useDispatch();

  let history = useHistory();
  const [image, setImage] = useState();
  const [rimage, setrImage] = useState();

  const initialValues = {
    company_name: "",
    // company_type: "",
    email: "",
    company_contact_no: "",
    company_address: "",
    password: "",
    confirmPassword: "",
    role: "",
    pan_no: "",
    isLoading: false,
    hasInsured: "",
    hasRegistered: false,
    accept: false,
    companyTypeId: companyType.id,
    image: null,
    countryId: countries.id,
    provinceId: proviences.id,
    districtId: districts.id,
    street_address: "",
    landmark: "",
    pin_code: "",
    paymentRate: "",
    paymentType: "",
  };

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add User",
    "Loading..."
  );
  const [show, setShow] = useState({
    password: false,
    confirmPassword: false,
  });

  useEffect(() => {
    dispatch(getCompanyType());
    dispatch(getAllCountries());
    dispatch(getAllProvince());
    dispatch(getAllDistrict());
  }, []);

  const UpdateUserHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      if (values.image) {
        data.append("registration_document", values.image);
      }
      if (logofile) {
        data.append("company_logo", logofile);
      }
      data.append("company_name", values.company_name);
      data.append("company_type", values.companyTypeId);
      data.append("email", values.email);
      data.append("company_email", values.email);
      data.append("company_address", values.company_address);
      data.append("hasInsured", values.hasInsured);
      data.append("hasRegistered", values.hasRegistered);
      data.append("company_contact_no", values.company_contact_no);
      data.append("role", values.role);
      data.append("password", values.password);
      data.append("pan_no", values.pan_no);
      data.append("companyTypeId", values.companyTypeId);
      data.append("countryId", values.countryId);
      data.append("provinceId", values.provinceId);
      data.append("districtId", values.districtId);
      data.append("street_address", values.street_address);
      data.append("landmark", values.landmark);
      data.append("pin_code", values.pin_code);
      data.append("paymentType", values.paymentType);
      data.append("rate", values.paymentRate);
      const res = await AxiosConfig.post(
        "/kreesa/user-login-registration/register",
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      );
      if (res) {
        toast.success("Activation link has been send to your email.!");
        setButtonLoader(false);
        history.push("/admin");
      }
    } catch (err) {
      console.log(err + "Something Went Wrong ! ");
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto">
            <div className="card px-5 pt-4 d-flex justify-content-center">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => {
                  UpdateUserHandler(values);
                }}
                validationSchema={validationSchema}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  isValid,
                  setFieldValue,
                  handleBlur,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* {state ? state : ""} */}
                    <div className="form-heading">
                      <p className="text-left">Add WholeSaler/Retailer User</p>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2">
                        <label className="form-label" htmlFor="inputEmail4">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.company_name}
                          onChange={handleChange}
                          name="company_name"
                          id="inputEmail5"
                          placeholder="Enter Company Name"
                        />
                        {errors.company_name && touched.company_name ? (
                          <div style={{ color: "red" }}>
                            {errors.company_name}
                          </div>
                        ) : null}
                      </div>

                      {/* <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Company Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.company_address}
                          onChange={handleChange}
                          name="company_address"
                          id="inputPassword5"
                          placeholder="Enter Company Address"
                        />
                        {errors.company_address && touched.company_address ? (
                          <div style={{ color: "red" }}>
                            {errors.company_address}
                          </div>
                        ) : null}
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Country
                        </label>
                        <select
                          name="countryId"
                          onChange={handleChange}
                          onBlur={(e) => {
                            SetSelectProvince(e.target.value);
                          }}
                          values={values.countryId}
                          className="form-control"
                        >
                          <option value="">Select Your Country...</option>
                          {countries?.map((p, i) => (
                            <option key={i} value={p.id}>
                              {p.country_name}
                            </option>
                          ))}
                        </select>
                        {errors.countryId && touched.countryId ? (
                          <div style={{ color: "red" }}>{errors.countryId}</div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Province
                        </label>
                        <select
                          name="provinceId"
                          onChange={handleChange}
                          onBlur={(e) => {
                            SetSelectDistrict(e.target.value);
                          }}
                          values={values.provinceId}
                          className="form-control"
                        >
                          <option value="">Select Your Provience...</option>
                          {proviences?.map(
                            (pr, i) =>
                              selectProvince == pr.country.id && (
                                <option key={i} value={pr.id}>
                                  {pr?.provience_name}
                                </option>
                              )
                          )}
                        </select>
                        {errors.provinceId && touched.provinceId ? (
                          <div style={{ color: "red" }}>
                            {errors.provinceId}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          District
                        </label>
                        <select
                          name="districtId"
                          onChange={handleChange}
                          values={values.districtId}
                          className="form-control"
                        >
                          <option value="">Select Your District...</option>
                          {districts?.map(
                            (d, i) =>
                              selectDistrict == d?.stateOrProvience?.id && (
                                <option key={i} value={d.id}>
                                  {d.district_name}
                                </option>
                              )
                          )}
                        </select>
                        {errors.districtId && touched.districtId ? (
                          <div style={{ color: "red" }}>
                            {errors.districtId}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Street Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.street_address}
                          name="street_address"
                          onChange={handleChange}
                          id="street_address"
                          placeholder="Enter Street Address"
                        />
                        {errors.street_address && touched.street_address ? (
                          <div style={{ color: "red" }}>
                            {errors.street_address}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Landmark
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.landmark}
                          name="landmark"
                          onChange={handleChange}
                          id="landmark"
                          placeholder="Enter Street Address"
                        />
                        {errors.landmark && touched.landmark ? (
                          <div style={{ color: "red" }}>{errors.landmark}</div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Pin Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.pin_code}
                          name="pin_code"
                          onChange={handleChange}
                          id="pin_code"
                          placeholder="Enter Street Address"
                        />
                        {errors.pin_code && touched.pin_code ? (
                          <div style={{ color: "red" }}>{errors.pin_code}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Company Contact Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.company_contact_no}
                          name="company_contact_no"
                          onChange={handleChange}
                          id="phone"
                          placeholder="Enter Company Contact Number"
                          onKeyUp={checkPhone}
                        />
                        {errors.company_contact_no &&
                        touched.company_contact_no ? (
                          <div style={{ color: "red" }}>
                            {errors.company_contact_no}
                          </div>
                        ) : null}
                        <span
                          id="phone-verify-error"
                          className="error"
                          htmlFor="phone"
                        ></span>
                      </div>

                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Company Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                          id="email"
                          placeholder="Enter Company Email"
                          onKeyUp={checkemail}
                        />
                        {errors.email && touched.email ? (
                          <div style={{ color: "red" }}>{errors.email}</div>
                        ) : null}
                        <span
                          id="email-errors"
                          className="error"
                          htmlFor="email"
                        ></span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Company Type
                        </label>
                        <select
                          name="companyTypeId"
                          //   onChange={(e) => {
                          //     const scat = e.target.value;
                          //     setcompanyTypeId(scat);
                          //   }}
                          onChange={handleChange}
                          values={values.companyTypeId}
                          className="form-control"
                        >
                          <option value="">Select Your Company Type...</option>
                          {companyType?.map((p, i) => (
                            <option key={i} value={p.id}>
                              {p.typeName}
                            </option>
                          ))}
                        </select>
                        {errors.companyTypeId && touched.companyTypeId ? (
                          <div style={{ color: "red" }}>
                            {errors.companyTypeId}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Password
                        </label>
                        <input
                          type={show.password ? "text" : "password"}
                          className="form-control"
                          value={values.password}
                          name="password"
                          onChange={handleChange}
                          id="inputPassword5"
                          placeholder="Enter Password"
                        />
                        <span
                          className="input-group-text cursor-pointer mt-2"
                          onClick={() =>
                            setShow({ ...show, password: !show.password })
                          }
                          style={{
                            width: 40,
                          }}
                        >
                          <i
                            className={`${
                              show.password ? "fa fa-eye-slash" : "fa fa-eye"
                            }`}
                            id="togglePassword"
                          ></i>
                        </span>
                        {errors.password && touched.password ? (
                          <div style={{ color: "red" }}>{errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Confirm Password
                        </label>
                        <input
                          type={show.confirmPassword ? "text" : "password"}
                          className="form-control"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          name="confirmPassword"
                          id="inputPassword5"
                          placeholder="Enter Password"
                        />
                        <span
                          className="input-group-text cursor-pointer mt-2"
                          onClick={() =>
                            setShow({
                              ...show,
                              confirmPassword: !show.confirmPassword,
                            })
                          }
                          style={{
                            width: 40,
                          }}
                        >
                          <i
                            className={`${
                              show.confirmPassword
                                ? "fa fa-eye-slash"
                                : "fa fa-eye"
                            }`}
                            id="togglePassword"
                          ></i>
                        </span>
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <div style={{ color: "red" }}>
                            {errors.confirmPassword}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3 col-md-6">
                        <div className="row">
                          <label className="form-label" htmlFor="inputEmail4">
                            Role
                          </label>
                          <select
                            name="role"
                            onChange={handleChange}
                            values={values.role}
                            className="form-control"
                          >
                            <option value="">Select Seller Type</option>
                            {[
                              {
                                id: 1,
                                name: "WholeSaler",
                                value: "ROLE_WHOLESALER",
                              },
                              {
                                id: 2,
                                name: "Retailer",
                                value: "ROLE_RETAILER",
                              },
                            ].map((i) => (
                              <option key={i.id} value={i.value}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.role && touched.role ? (
                          <div style={{ color: "red" }}>{errors.role}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Company Logo{" "}
                          <span className="text-info">(Optional)</span>
                        </label>
                        <input
                          type="file"
                          className="form-control "
                          name="company_logo"
                          onChange={(e) => {
                            setLogoFile(e.target.files[0]);
                            setImage(URL.createObjectURL(e.target.files[0]));
                          }}
                          id="pas"
                        />
                        {image && (
                          <div
                            className="mt-2"
                            style={{ flexDirection: "column" }}
                          >
                            <img
                              src={image}
                              alt="dummy"
                              height={80}
                              width={100}
                            />
                          </div>
                        )}
                      </div>

                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Does your company have insurance ?
                        </label>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="radio">
                              {" "}
                              <input
                                type="radio"
                                name="hasInsured"
                                value="Yes"
                                onChange={handleChange}
                              />{" "}
                              <span>Yes</span>
                            </label>
                          </div>
                          <div className="col-md-6 mcol">
                            <label className="radio">
                              {" "}
                              <input
                                type="radio"
                                name="hasInsured"
                                value="No"
                                onChange={handleChange}
                              />{" "}
                              <span>No</span>{" "}
                            </label>
                          </div>
                        </div>
                        {errors.hasInsured && touched.hasInsured ? (
                          <div style={{ color: "red" }}>
                            {errors.hasInsured}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Commission Type
                        </label>
                        <select
                          name="paymentType"
                          onChange={handleChange}
                          value={values.paymentType}
                          className="form-control"
                        >
                          <option> Select Commission Type... </option>
                          {[
                            {
                              id: 1,
                              name: "Fix Amount",
                              value: "FIX_AMOUNT",
                            },
                            {
                              id: 2,
                              name: "Percentage",
                              value: "PERCENTAGE",
                            },
                          ].map((i) => (
                            <option key={i.id} value={i.value}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                        {errors.paymentType && touched.paymentType ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {errors.paymentType}{" "}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="paymentRate">
                          Commission Rate
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.paymentRate}
                          name="paymentRate"
                          onChange={handleChange}
                          id="paymentRate"
                          placeholder="Enter Commission Rate"
                        />
                        {errors.paymentRate && touched.paymentRate ? (
                          <div style={{ color: "red" }}>
                            {errors.paymentRate}
                          </div>
                        ) : null}
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-4">
                        <label
                          className="form-label"
                          htmlFor="inputPassword4"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <input
                            type="checkbox"
                            name="hasRegistered"
                            style={{ height: 20, width: 20 }}
                            onChange={handleChange}
                            onClick={() => setVisible(!visible)}
                          />{" "}
                          Click If Your Company Is Register
                        </label>
                        {visible && (
                          <div className="row">
                            <div>
                              Upload Register Document
                              <div className="form-group register-group">
                                <div className="row">
                                  <div className="col">
                                    <input
                                      type="file"
                                      className="form-control "
                                      name="image"
                                      accept="image/png,image/jpg,image/jpeg"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "image",
                                          e.target.files[0]
                                        );
                                        setrImage(
                                          URL.createObjectURL(e.target.files[0])
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <small>
                                      Accepted Formats: png, jpg & jpeg
                                    </small>

                                    {rimage && (
                                      <div
                                        className="mt-2"
                                        style={{ flexDirection: "column" }}
                                      >
                                        <img
                                          src={rimage}
                                          alt="dummy"
                                          height={80}
                                          width={100}
                                        />
                                      </div>
                                    )}
                                    {errors.image && touched.image ? (
                                      <div style={{ color: "red" }}>
                                        {errors.image}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control "
                                      name="pan_no"
                                      onChange={handleChange}
                                      value={values.pan_no}
                                      placeholder="Pan Number/VAT No"
                                    />
                                    {errors.pan_no && touched.pan_no ? (
                                      <div style={{ color: "red" }}>
                                        {errors.pan_no}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          htmlFor="inputPassword4"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                          }}
                        >
                          <input
                            type="checkbox"
                            name="accept"
                            onChange={handleChange}
                            style={{ height: 20, width: 20 }}
                          />{" "}
                          I accept the
                          <a href="#" style={{ color: "blue" }}>
                            {" "}
                            Terms of Use
                          </a>{" "}
                          &amp;
                          <a href="#" style={{ color: "blue" }}>
                            {" "}
                            Privacy Policy
                          </a>
                        </label>
                        {errors.accept && touched.accept ? (
                          <div style={{ color: "red" }}>{errors.accept}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group pt-3 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            id="sends"
                            ref={buttonLoader}
                            onClick={handleSubmit}
                            disabled={!isValid}
                          >
                            Add User
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRWForm;
