import $ from "jquery";
import "jquery-validation";
const ProductaddValidation = () => {
  $(function () {
    $.validator.addMethod(
      "maxFilesToSelect",
      function (value, element, params) {
        var fileCount = element.files.length;
        if (fileCount > params) {
          return false;
        } else {
          return true;
        }
      },
      "No more than 4 files"
    );
    $.validator.addMethod(
      "lettersonly",
      function (value, element) {
        return this.optional(element) || value == value.match(/^[a-zA-Z ]*$/);
      },
      "Letters only please"
    );

    //validate file extension custom  method.
    $.validator.addMethod(
      "extension",
      function (value, element, param) {
        param =
          typeof param === "string"
            ? param.replace(/,/g, "|")
            : "png|jpe?g|gif";
        return (
          this.optional(element) ||
          value.match(new RegExp(".(" + param + ")$", "i"))
        );
      },
      $.validator.format("Only accept png|jpeg|jpg")
    );

    $("#productadd-forms").validate({
      // Specify validation rules

      rules: {
        productName: {
          required: true,
        },
        role: {
          required: true,
        },
        role2: {
          required: true,
        },
        // role3: {
        //   required: true,
        // },
        description: {
          required: true,
          minlength: 30,
        },

        productWeight: {
          required: true,
          number: true,
        },
        // productLength: {
        //   required: true,
        //   number: true,
        // },
        // productThickness: {
        //   required: true,
        //   number: true,
        // },
        // productHeight: {
        //   required: true,
        //   number: true,
        // },
        minimumQuantity: {
          required: true,
        },
      },

      messages: {
        productName: {
          required: "Product Name is required",
        },
        role: {
          required: "Primary Category is required",
        },
        role2: {
          required: "Secondry Category is required",
        },
        // role3: {
        //   required: "Ternary Category is required",
        // },
        description: {
          required: "Product Description is required",
        },

        productWeight: {
          required: "Product Weight is required",
        },
        // productLength: {
        //   required: "Product Length is required",
        // },
        // productThickness: {
        //   required: "Product Thickness is required",
        // },
        // productHeight: {
        //   required: "Product Height is required",
        // },
        minimumQuantity: {
          required: "Minimum Quantity is required",
        },
      },

      highlight: function (element) {
        $(element).removeClass("is-valid").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid").addClass("is-valid");
      },
    });
    $("#psends").on("click", function () {
      $("#productadd-forms").valid();
    });

    // $("#colorName").rules("add", {
    //   required: true,
    //   messages: {
    //     required: "Color Name is required",
    //   },
    // });
    $("#pimages").rules("add", {
      required: true,
      extension: true,
      maxFilesToSelect: 4,
    });
    $("#inStock").rules("add", {
      required: true,
      messages: {
        required: "Product Size is required",
      },
    });
    $("#productQuantity").rules("add", {
      required: true,
      messages: {
        required: "Product Quantity is required",
      },
    });
    $("#productRate").rules("add", {
      required: true,
      messages: {
        required: "Product Rate is required",
      },
    });
    // $("#colorName").rules("add", {
    //   required: true,
    //   messages: {
    //     required: "Product Discount is required",
    //   },
    // });
  });
  $("#productadd-forms input").on("keyup blur click", function () {
    // fires on every keyup & blur
    if ($("#productadd-forms").valid()) {
      // checks form for validity
      $("#psends").prop("disabled", false); // enables button
    } else {
      $("#psends").prop("disabled", "disabled"); // disables button
    }
  });
};

export default ProductaddValidation;
