import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import moment from "moment";

const ViewQuoteModal = ({ modal, setModal, data, userData }) => {
  return (
    <div>
      {" "}
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="md"
        centered

        // style={{ zIndex: "99999", top: "0.2rem" }}
      >
        <Modal.Header>
          <Modal.Title className="font-weight-light text-center w-100 d-flex justify-content-between">
            <div>Quotation Detail</div>
            <div style={{ cursor: "pointer" }} onClick={() => setModal(false)}>
              X
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <p>Product Name : {data.product_name}</p>
                <p>Expected Price : {data.expected_price}</p>
                <p>
                  Actual Price :{" "}
                  <span className="font-weight-bold">
                    {data.actual_price === 0
                      ? data.actual_price_seller
                      : data.actual_price}
                  </span>{" "}
                </p>
                <p>Quantity : {data.quantity}</p>
                <p>Quantity : {data.quantity}</p>
                <p>
                  Delivery Days :{" "}
                  {data.delivery_days_seller ?? data.delivery_days}
                </p>
                <p>
                  Delivery Charge :{" "}
                  {data.delivery_charge_seller === null
                    ? data?.shipping_cost
                    : data.delivery_charge_seller}
                </p>
                <p>
                  Created Date :{" "}
                  {moment(data.created_date).format("DD MMM, YYYY")}
                </p>
                <p>
                  Admin Approval :{" "}
                  <span
                    className={`badge ${
                      data.admin_final_approval === "REJECTED"
                        ? "bg-danger"
                        : data.admin_final_approval === "APPROVED"
                        ? "bg-success"
                        : "bg-info"
                    }`}
                  >
                    {data.admin_final_approval}
                  </span>
                </p>

                {/* <p>
                  Saler Approval :{" "}
                  {data.buyer_approval === true ? (
                    <span className="badge bg-success">Approved</span>
                  ) : (
                    <span className="badge bg-danger">Pending</span>
                  )}
                </p> */}

                <p>
                  User Approval :{" "}
                  {data.user_approval === true ? (
                    <span className="badge bg-success">Approved</span>
                  ) : (
                    <>
                      <span className="badge bg-danger">Pending</span>
                    </>
                  )}
                </p>

                <p>
                  Quote Status :{" "}
                  {data.quote_status === "REJECTED" ? (
                    <span className="badge bg-danger">{data.quote_status}</span>
                  ) : data.quote_status === "ASSIGNED" ? (
                    <span className="badge bg-success">
                      {data.quote_status}
                    </span>
                  ) : (
                    <span className="badge bg-info">{data.quote_status}</span>
                  )}
                </p>
              </div>

              <div className="col-12 col-md-6 ">
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <img
                    src={
                      data.product_image_seller === null
                        ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${data.id}/${data.image}`
                        : `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${data.id}/${data.quote_approval_id}/${data.product_image_seller}`
                    }
                    width="250"
                    height="250"
                    loading="lazy"
                    alt={data.product_name}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewQuoteModal;
