import Header from "../Header";
import Nav from "../Nav";
import SearchCard from "./searchCard";
import Rating from "react-rating";
import { useSelector } from "react-redux";
import Footer from "../Footer";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const SearchPage = ({ props }) => {
  const location = useLocation();

  document.title = "Search Page";
  // const { loader, searchItem } = useSelector(
  //   (state) => state.WholesalerReducer
  // );

  const [searchItem, setSearchItem] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    setSearchItem(location.state);
    setLoader(false);
  }, [location]);

  return !loader ? (
    <div>
      <div style={{ position: "sticky", top: 0, zIndex: 99999 }}>
        <Header />
        <Nav />
      </div>
      <div className="mt-4 container-xs">
        <div className="row">
          {/* <div className="col-xs-12 offset-1 col-sm-3 col-md-3 col-lg-2 align-center">
            <div className="search-sidebar-item">
              <div className="font-weight-bold mb-2">Related Cateogries</div>
              <div className="search-sidebar-item">Related Cateogries</div>
            </div>
            <hr style={{ backgroundColor: "grey" }} />

            <div className="search-sidebar-item">
              <div className="font-weight-bold mb-2 mt-2">Brand</div>
              <div className="search-sidebar-item mb-2">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                  <div>
                    <input
                      type="checkbox"
                      id="reg-signup"
                      name="hasRegistered"
                      // value={values.hasRegistered}
                      // onChange={handleChange}
                      // onClick={Handlechange}
                      // onClick={() => setVisible(!visible)}
                    />
                    <label htmlFor="reg-signup">{i}</label>
                  </div>
                ))}
              </div>
            </div>
            <hr style={{ backgroundColor: "grey" }} />
          </div> */}
          <div className="col-xs-12">
            <div className="row">
              <div
                className="d-flex align-items-center "
                style={{ padding: "15px 0" }}
              >
                {searchItem?.length > 0 ? (
                  <div
                    className="col-sm-12"
                    style={{
                      marginLeft: "100px",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    {searchItem?.length} items found
                  </div>
                ) : (
                  <h3
                    className="col-sm-12 text-center"
                    // style={{ marginLeft: "100px", color: "blue", fontSize: 22 }}
                    style={{ color: "blue", fontSize: 25, fontWeight: "bold" }}
                  >
                    😔 Sorry, no products found !
                    <br />
                    <q>
                      Products are not available in this <br /> category please
                      try any other category
                    </q>
                  </h3>
                )}
                <div className="d-flex col-sm-12  mx-auto">
                  {/* <div className="btn-group " style={{ marginLeft: "-80px" }}>
                    <button
                      className="btn btn-lg dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sort By{" "}
                    </button>
                    <div
                      className="dropdown-menu col-sm-6 "
                      style={{ marginLeft: "-60px" }}
                    >
                      {[1, 2, 3, 4, 5].map((i) => (
                        <a className="dropdown-item" href="#">
                          item
                        </a>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
              <hr style={{ backgroundColor: "grey" }} />
              {searchItem?.map((i) => (
                <div
                  className="col-xs-12 col-sm-6 col-md-3"
                  style={{ padding: "0" }}
                >
                  <SearchCard
                    id={i?.id}
                    title={i?.productName}
                    subTitle={i?.companyName}
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${i?.image?.id}/${i?.image?.imageName}`}
                    price={i?.specification?.productPrice}
                    discount={i?.specification?.productDiscount}
                    review={i?.review}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <Spinner />
  );
};

export default SearchPage;
