import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Footer from "../Footer";
import LeftNavbar from "../LeftNavbar";
import TopNavbar from "../TopNavbar";
import {
  getAllFlashProduct,
  removeFlashProduct,
} from "../Features/Action/FeatureProductAction";
import useProduct from "../reusable";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const FlashProduct = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ProductType } = useProduct();

  const { flashProduct, loader } = useSelector((s) => s.FeatureReducer);

  useEffect(() => {
    document.title = history.location.pathname.includes("wholesale")
      ? "Admin | Wholesaler Flash Product"
      : "Admin | Retailer Flash Product";

    dispatch(
      getAllFlashProduct(
        history.location.pathname.includes("wholesale") ? "Wholesale" : "Retail"
      )
    );
  }, [history.location.pathname]);

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }

  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <ProductType
            type="Flash"
            arr={flashProduct}
            loader={loader}
            remove={(id) => {
              dispatch(
                removeFlashProduct({
                  id,
                  type: history.location.pathname.includes("wholesale")
                    ? "Wholesale"
                    : "Retail",
                })
              );
            }}
          />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default FlashProduct;
