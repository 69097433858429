import { createSlice } from "@reduxjs/toolkit";
import { getProductsOfRetailer } from '../../../Action/ProductAction'

const Product = createSlice({
  name: "ProductRetailer",
  initialState: {
    products: [],
    productContainer: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterProductOfRetailer: (state, action) => {
      state.products = state.productContainer.filter((u) =>
        u.productName.toLowerCase().includes(action.payload) ||
        u.productName.toUpperCase().includes(action.payload)
        // u.productName.toLowerCase().includes(action.payload) ||
        // u.productName.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Products */
    [getProductsOfRetailer.pending]: (state, action) => {
      state.loader = true;
    },
    [getProductsOfRetailer.fulfilled]: (state, action) => {
      state.loader = false;
      state.products = action.payload;
      state.productContainer = action.payload;
    },
    [getProductsOfRetailer.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterProductOfRetailer } = Product.actions;
// export default
export default Product.reducer;
