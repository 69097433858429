import React, { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  ApproveQuotations,
  DeclineQuotations,
  getSingleQuotations,
} from "../../Features/Action/QuotationAction";
import Spinner from "../../Spinner";
import "./quotations.css";
import Swal from "sweetalert2";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import logo from "../../../../assets/Images/kreesalogo.png";
import ModalImage from "react-modal-image";

const Quotations = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [orderId, setOrderId] = useState();

  const [user, setUser] = useState([]);
  const [seller, setSeller] = useState();
  const [deliveryBoy, setDeliveryBoy] = useState([]);
  const [deliveryModalId, setDeliveryModalId] = useState();
  const { id } = useParams();
  const { singlequotation, loader } = useSelector((s) => s.QuotationsReducer);
  const dispatch = useDispatch();

  const handleApproveQuotation = async (id) => {
    await dispatch(ApproveQuotations(id));
    dispatch(getSingleQuotations(id));
    toast.success("Quotation Approved Successfully");
  };

  const getUser = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const user = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${singlequotation?.user?.id}`,
        config
      );
      setUser(user.data);

      if (singlequotation?.approve_user !== null) {
        const user2 = await AxiosConfig.get(
          `/kreesa/admin/get-user-by-user-id/${singlequotation?.approve_user?.id}`,
          config
        );
        setSeller(user2.data);
      }

      history.location.state !== undefined &&
        AxiosConfig.get(
          `/kreesa/admin/get-user-by-user-id/${singlequotation?.delivery_assign_user?.id}`,
          config
        ).then((res) => setDeliveryBoy(res.data));
    } catch (error) {
      console.log(error);
    }
  };
  useLayoutEffect(() => {
    getUser();
  }, [singlequotation.user]);
  useLayoutEffect(() => {
    dispatch(getSingleQuotations(id));
  }, [id]);

  return !loader ? (
    <div className="card">
      <div
        style={{
          padding: "20px 0 0 20px ",
        }}
      >
        {singlequotation?.user_approval === false && (
          <div className="btn-group">
            <button
              type="button"
              style={{
                textTransform: "capitalize",
                backgroundColor: "#3b7ddd",
              }}
              className="btn-md btn btn-success dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>
            <div className="dropdown-menu text-center">
              {singlequotation?.adminQuotationApprovalStatus === "APPROVED" &&
              singlequotation?.buyer_approval === true &&
              singlequotation?.user_approval === true &&
              singlequotation?.supplier_delivery_approval === true &&
              singlequotation?.quoteDeliveryStatus === "NOT_ASSIGNED" ? (
                <>
                  <button
                    className="dropdown-item cursor-pointer text-info"
                    onClick={() => {
                      setModal(true);
                      setOrderId(singlequotation?.id);
                    }}
                  >
                    <i className="fas fa-user"></i> Assign Delivery
                  </button>
                </>
              ) : (
                <></>
              )}
              {singlequotation?.adminQuotationApprovalStatus === "PENDING" ? (
                <button
                  onClick={() => {
                    handleApproveQuotation(singlequotation?.id);
                  }}
                  className="dropdown-item text-info"
                >
                  <i className="fas fa-check"></i> Approve
                </button>
              ) : (
                singlequotation?.adminQuotationApprovalStatus === "APPROVED" &&
                singlequotation?.quotationStatus === "NOT_ASSIGNED" && (
                  <>
                    {/* <div className="dropdown-divider"></div> */}
                    <Link
                      to={`/admin/quotations/assign/${singlequotation?.id}`}
                      className="dropdown-item text-info"
                    >
                      <i className="fa fa-user"></i> Assign
                    </Link>
                  </>
                )
              )}
              {singlequotation?.adminQuotationApprovalStatus === "PENDING" && (
                <>
                  <div className="dropdown-divider"></div>

                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "Enter decline reason",
                        input: "text",
                        inputAttributes: {
                          autocapitalize: "off",
                        },
                        showCancelButton: true,
                        confirmButtonText: "Confirm",
                        showLoaderOnConfirm: true,
                        preConfirm: (reason) => {
                          dispatch(
                            DeclineQuotations({
                              id: singlequotation?.id,
                              reason,
                            })
                          );
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                      }).then((result) => {});
                    }}
                    className="dropdown-item text-danger"
                  >
                    <i className="fas fa-times"></i> Decline
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {singlequotation?.user_approval === true &&
        singlequotation?.quoteDeliveryStatus === "ASSIGNED" ? (
          <h5 className="text-danger">No Action Remaining</h5>
        ) : singlequotation?.adminQuotationApprovalStatus === "APPROVED" &&
          singlequotation?.buyer_approval === true &&
          singlequotation?.user_approval === true &&
          singlequotation?.supplier_delivery_approval === false ? (
          <h5 className="text-info">Waiting for Supplier Delivery Approval</h5>
        ) : null}
      </div>
      <div className="p-2 pt-4">
        <div className="row">
          {/*  ----------------------------- ...Quotation Details... ----------------------------  */}
          <div className="col-md-6 text-center">
            <h4 className="">Quotation Details</h4>
            <div className="row mt-3">
              <div className="col-sm-3">
                <h6 className="mb-0">Product Name</h6>
              </div>
              <div className="col-sm-9 font-weight-bold">
                {singlequotation.product_name}
              </div>
            </div>
            <hr />
            <div className="row mt-3">
              <div className="col-sm-3">
                <h6 className="mb-0">Actual Price</h6>
              </div>
              <div className="col-sm-9 font-weight-bold">
                {singlequotation.actual_price}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">Expected Price</h6>
              </div>
              <div className="col-sm-9 font-weight-bold">
                Rs {singlequotation.expected_price}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">Total Quantity</h6>
              </div>
              <div className="col-sm-9 font-weight-bold">
                {singlequotation.quantity}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">Delivery Days</h6>
              </div>
              <div className="col-sm-9 font-weight-bold">
                {singlequotation.deliveryDays}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">Delivery Charge</h6>
              </div>
              <div className="col-sm-9 text-center font-weight-bold">
                {singlequotation.shippingCost === 0 ? (
                  <span className="badge bg-success p-2">Free</span>
                ) : (
                  `Rs ${singlequotation.shippingCost}`
                )}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-3">
                <h6 className="mb-0">Admin Status</h6>
              </div>
              <div className="col-sm-9 text-center font-weight-bold">
                <span
                  className={`badge ${
                    singlequotation.adminQuotationApprovalStatus === "REJECTED"
                      ? "bg-danger"
                      : singlequotation.adminQuotationApprovalStatus ===
                        "APPROVED"
                      ? "bg-success"
                      : "bg-info"
                  }`}
                >
                  {singlequotation.adminQuotationApprovalStatus}
                </span>
              </div>
            </div>
            <div className="row">
              <ModalImage
                alt={singlequotation.product_name}
                small={
                  singlequotation.image === null
                    ? logo
                    : `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${singlequotation.id}/${singlequotation.image}`
                }
                large={
                  singlequotation.image === null
                    ? logo
                    : `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${singlequotation.id}/${singlequotation.image}`
                }
                className="img-fluid p-5"
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
                loading="lazy"
                showRotate={true}
                hideDownload={true}
              />
              {/* <img
                src={
                  singlequotation.image === null
                    ? logo
                    : `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${singlequotation.id}/${singlequotation.image}`
                }
                loading="lazy"
                className="text-center p-5"
                alt={singlequotation.product_name}
              /> */}
            </div>
          </div>
          {/*  ----------------------------- ...User Details... ----------------------------  */}
          <div className="col-md-6 text-center">
            <h4 className="">User Details</h4>
            <div className="row">
              <div className="col-md-3 mb-3">
                <div className="d-flex flex-column align-items-center text-center">
                  {user.role === "ROLE_USER" && (
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${user.userId}/${user.profile}`}
                      alt={user?.emailId}
                      className="rounded"
                      width="150"
                    />
                  )}
                  {user.role === "ROLE_WHOLESALER" && (
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${user.userId}/${user.companyLogo}`}
                      alt={user.companyLogo}
                      className="rounded"
                      width="150"
                    />
                  )}
                  {user.role === "ROLE_RETAILER" && (
                    <img
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${user.userId}/${user.companyLogo}`}
                      alt={user.companyLogo}
                      className="rounded"
                      width="150"
                    />
                  )}
                  <div className="mt-3">
                    <h4>{user?.emailId}</h4>
                    <p className="text-white mt-4 bg-primary p-1 rounded">
                      {user.role === "ROLE_WHOLESALER"
                        ? "WHOLESALER"
                        : user.role === "ROLE_RETAILER"
                        ? "RETAILER"
                        : "USER"}
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-sm-3 mt-2">
                      {user.active === false ? (
                        <span className="badge bg-danger">Inactive</span>
                      ) : (
                        <span className="badge bg-success">Active</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {user.role === "ROLE_USER" && (
                <>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user?.firstname} {user?.lastname}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">E-mail</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user?.emailId}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {`${user?.districtName} , ${user?.provienceName} , ${user?.countryName}`}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Contact</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user?.contact}
                  </div>
                </>
              )}
              {user.role === "ROLE_WHOLESALER" && (
                <>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Company Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user?.companyName}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone Number</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user?.companyContactNo}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Company Address</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {`${user?.districtName} , ${user?.provienceName} , ${user?.countryName}`}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Company Type</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    <span className="badge bg-info">{user.companyType}</span>
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Pan Number</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user.panNo === null ? "________" : user.panNo}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Is Insured ?</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user.isInsured === false ? (
                      <span className="badge bg-danger">Not Insured Yet</span>
                    ) : (
                      <span className="badge bg-warning">Insured</span>
                    )}
                  </div>
                </>
              )}
              {user.role === "ROLE_RETAILER" && (
                <>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Company Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user?.companyName}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone Number</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user?.companyContactNo}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Company Address</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {`${user?.districtName} , ${user?.provienceName} , ${user?.countryName}`}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Company Type</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    <span className="badge bg-info">{user.companyType}</span>
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Pan Number</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user.panNo === null ? "________" : user.panNo}
                  </div>
                  <hr />
                  <div className="col-sm-3">
                    <h6 className="mb-0">Is Insured ?</h6>
                  </div>
                  <div className="col-sm-9 text-secondary font-weight-bold">
                    {user.isInsured === false ? (
                      <span className="badge bg-danger">Not Insured Yet</span>
                    ) : (
                      <span className="badge bg-warning">Insured</span>
                    )}
                  </div>
                </>
              )}
            </div>
            <hr />
          </div>

          {seller !== null && seller !== undefined && (
            <div className="col-md-6 text-center">
              <h4 className="">Seller Details</h4>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="d-flex flex-column align-items-center text-center">
                    {user.role === "ROLE_USER" && (
                      <img
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${seller.userId}/${seller.profile}`}
                        alt={seller?.emailId}
                        className="rounded"
                        width="150"
                      />
                    )}
                    {seller.role === "ROLE_WHOLESALER" && (
                      <img
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${seller.userId}/${seller.companyLogo}`}
                        alt={seller.companyLogo}
                        className="rounded"
                        width="150"
                      />
                    )}
                    {seller.role === "ROLE_RETAILER" && (
                      <img
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${seller.userId}/${seller.companyLogo}`}
                        alt={seller.companyLogo}
                        className="rounded"
                        width="150"
                      />
                    )}
                    <div className="mt-3">
                      <h4>{seller?.emailId}</h4>
                      <p className="text-white mt-4 bg-primary p-1 rounded">
                        {seller.role === "ROLE_WHOLESALER"
                          ? "WHOLESALER"
                          : seller.role === "ROLE_RETAILER"
                          ? "RETAILER"
                          : "USER"}
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 mt-2">
                        {seller.active === false ? (
                          <span className="badge bg-danger">Inactive</span>
                        ) : (
                          <span className="badge bg-success">Active</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {seller.role === "ROLE_WHOLESALER" && (
                  <>
                    <div className="col-sm-3">
                      <h6 className="mb-0">Company Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller?.companyName}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone Number</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller?.companyContactNo}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Company Address</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {`${seller?.districtName} , ${seller?.provienceName} , ${seller?.countryName}`}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Company Type</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      <span className="badge bg-info">
                        {seller.companyType}
                      </span>
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Pan Number</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller.panNo === null ? "________" : seller.panNo}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Is Insured ?</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller.isInsured === false ? (
                        <span className="badge bg-danger">Not Insured Yet</span>
                      ) : (
                        <span className="badge bg-warning">Insured</span>
                      )}
                    </div>
                  </>
                )}
                {seller.role === "ROLE_RETAILER" && (
                  <>
                    <div className="col-sm-3">
                      <h6 className="mb-0">Company Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller.companyName}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone Number</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller.companyContactNo}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Company Address</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {`${seller.districtName} , ${seller.provienceName} , ${seller.countryName}`}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Company Type</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      <span className="badge bg-info">
                        {seller.companyType}
                      </span>
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Pan Number</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller.panNo === null ? "________" : seller.panNo}
                    </div>
                    <hr />
                    <div className="col-sm-3">
                      <h6 className="mb-0">Is Insured ?</h6>
                    </div>
                    <div className="col-sm-9 text-secondary font-weight-bold">
                      {seller.isInsured === false ? (
                        <span className="badge bg-danger">Not Insured Yet</span>
                      ) : (
                        <span className="badge bg-warning">Insured</span>
                      )}
                    </div>
                  </>
                )}
              </div>
              <hr />
            </div>
          )}
          {history.location.state !== undefined && (
            <p className="card-text">
              <p
                style={{ fontSize: "13px" }}
                className="p-2 ml-2 badge bg-secondary cursor-pointer"
                onClick={() => {
                  setDeliveryModalId(
                    `modal${singlequotation?.delivery_assign_user?.id}`
                  );
                }}
              >
                Delivery Details
              </p>
            </p>
          )}

          <Modal
            show={
              deliveryModalId ===
              `modal${singlequotation?.delivery_assign_user?.id}`
            }
            onHide={() => setDeliveryModalId("")}
          >
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card flex-fill m-4 overflow-auto">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th className="d-xl-table-cell">Name</th>
                      <th className="d-md-table-cell">Contact</th>
                      <th className="d-md-table-cell">Email</th>
                      <th className="d-md-table-cell">Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <td className="p-2">
                      {deliveryBoy.firstname} {deliveryBoy.lastname}
                    </td>
                    <td className="p-2">{deliveryBoy.contact}</td>
                    <td className="p-2">{deliveryBoy.emailId}</td>
                    <td className="p-2">
                      {deliveryBoy.streetAddress}, {deliveryBoy.districtName},{" "}
                      {deliveryBoy.countryName}
                    </td>
                  </tbody>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setDeliveryModalId("")}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Quotations;
