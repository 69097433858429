import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getallBuyerReview,
  getallUserReview,
  getProductsOfWholesaler,
  getWholesalerReviewById,
  ReviewApprove,
} from "../../../Action/ProductAction";
import { approveAndRejectProductStatus } from "../../../Action/ProductAction/UpdateStatusAction";

const ProductWholesalers = createSlice({
  name: "productWholesaler",
  initialState: {
    Wholesalerproducts: [],
    ReviewById: {},
    WholesalerproductsContainer: [],
    buyerReview: [],
    buyerReviewContainer: [],
    userReview: [],
    userReviewContainer: [],
    loader: false,
    errors: {},
    data: {},
    message: [],
  },
  reducers: {
    filterProductofWholesale: (state, action) => {
      state.Wholesalerproducts = state.WholesalerproductsContainer.filter(
        (u) =>
          // u.productDescription.toLowerCase().includes(action.payload) ||
          // u.productDescription.toUpperCase().includes(action.payload) ||
          u.productName.toLowerCase().includes(action.payload) ||
          u.productName.toUpperCase().includes(action.payload)
      );
    },
    filterUserReview: (state, action) => {
      state.userReview = state.userReviewContainer.filter(
        (u) =>
          u.productName.toLowerCase().includes(action.payload) ||
          u.productName.toUpperCase().includes(action.payload)
      );
    },
    filterBuyerReview: (state, action) => {
      state.buyerReview = state.buyerReviewContainer.filter(
        (u) =>
          u.productName.toLowerCase().includes(action.payload) ||
          u.productName.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Products */
    [getProductsOfWholesaler.pending]: (state, action) => {
      state.loader = true;
    },
    [getProductsOfWholesaler.fulfilled]: (state, action) => {
      state.loader = false;
      state.Wholesalerproducts = action.payload;
      state.WholesalerproductsContainer = action.payload;
    },
    [getProductsOfWholesaler.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* to get single Products */
    [getWholesalerReviewById.pending]: (state, action) => {
      state.loader = true;
    },
    [getWholesalerReviewById.fulfilled]: (state, action) => {
      state.loader = false;
      state.ReviewById = action.payload;
    },
    [getWholesalerReviewById.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [approveAndRejectProductStatus.pending]: (state, action) => {
      state.loader = true;
    },
    [approveAndRejectProductStatus.fulfilled]: (state, action) => {
      state.loader = false;
      state.data = action.payload;
      state.message = toast.success(action.payload);
    },
    [approveAndRejectProductStatus.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
      state.message = toast.error(action.payload);
    },
    // to get all buyer reviews
    [getallBuyerReview.pending]: (state, action) => {
      state.loader = true;
    },
    [getallBuyerReview.fulfilled]: (state, action) => {
      state.loader = false;
      state.buyerReview = action.payload;
      state.buyerReviewContainer = action.payload;
    },
    [getallBuyerReview.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get all user reviews
    [getallUserReview.pending]: (state, action) => {
      state.loader = true;
    },
    [getallUserReview.fulfilled]: (state, action) => {
      state.loader = false;
      state.userReview = action.payload;
      state.userReviewContainer = action.payload;
    },
    [getallUserReview.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // approve review
    [ReviewApprove.pending]: (state, action) => {
      state.loader = true;
    },
    [ReviewApprove.fulfilled]: (state, action) => {
      state.loader = false;
      state.message = toast.success(action.payload);
    },
    [ReviewApprove.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
      state.message = toast.error(action.payload);
    },
  },
});

// export
export const { filterProductofWholesale, filterBuyerReview, filterUserReview } =
  ProductWholesalers.actions;
// export default
export default ProductWholesalers.reducer;
