import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  jobLocation: Yup.string().required("Location is required"),
  title: Yup.string().required("Title is required"),
  jobOverview: Yup.string().required("Job Overview is required"),
  jobType: Yup.string().required("Job type is required"),
  openVacancies: Yup.number("Must be number")
    .typeError("open vacancies must be a number")
    .required("Open vacancies is required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  deadline: Yup.date()
    .nullable()
    .required("deadline Date is required")
    .min(new Date(), "deadline must be later than today"),
  requirement: Yup.string()
    .required("Requirement is required")
    .min(13, "Requirement must be 5 characters"),
});
