import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import useButtonLoader from "../../../ButtonLoaderHook";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSingleCustomOrder } from "../../../../Common/Features/Action/CustomOrderAction";
import Spinner from "../../../Admin/Spinner";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";

const EditCustomOrderForm = () => {
  const { customId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // state
  const { singleCustomOrder, loader } = useSelector((s) => s.SingleCustomOrder);
  const [description, setDescription] = useState(null);
  const [estimatedDate, SetEstimatedDate] = useState();
  const [state, setState] = useState({ recordState: null });
  const [record, setRecord] = useState();
  // get audio blob
  const [audioBlob, setAudioBlob] = useState();
  const [file, setFile] = useState(null);
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Custom Order",
    "Loading..."
  );

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };

  const updateCustomOrderHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      data.append("fileType", values.customType);

      if (file != null) {
        data.append("file", file);
      }
      if (record != null) {
        data.append("file", record);
      }
      data.append(
        "description",
        draftToHtml(convertToRaw(description.getCurrentContent()))
      );
      data.append("estimatedDate", moment(estimatedDate).format("YYYY-MM-DD"));
      data.append("userId", localStorage.getItem("userId"));
      data.append("orderType", localStorage.getItem("auth"));
      data.append("id", customId);
      const res = await AxiosConfig.post(
        "/kreesa/wholesaler/custom-order/update",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res) {
        toast.success("Custom Order updated !");
        setButtonLoader(false);
        history.push("/wholesaler/custom-order");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
      setButtonLoader(false);
    }
  };

  const start = (e) => {
    e.preventDefault();
    setState({
      recordState: RecordState.START,
    });
  };

  const stop = (e) => {
    e.preventDefault();
    setState({
      recordState: RecordState.STOP,
    });
  };

  const onStop = (audioData) => {
    setRecord(audioData.blob);
    setAudioBlob(audioData.url);
  };

  useEffect(() => {
    if (singleCustomOrder?.orderDescription?.length > 0) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(singleCustomOrder?.orderDescription)
          )
        )
      );
    }
    if (singleCustomOrder?.dueDate) {
      SetEstimatedDate(new Date(singleCustomOrder?.dueDate));
    }
  }, [singleCustomOrder]);

  // get single custom order
  useEffect(() => {
    dispatch(getSingleCustomOrder(customId));
  }, [customId]);
  return !loader ? (
    <main className="content">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title font-weight-bold">Update Custom Order</h5>
            <h6 className="card-subtitle text-muted">
              {" "}
              You Can Easily Update Custom Order.
            </h6>
          </div>
          <div className="card-body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                description: singleCustomOrder?.orderDescription,
                estimatedDate: singleCustomOrder?.dueDate,
                orderType: localStorage.getItem("auth"),
                userId: localStorage.getItem("userId"),
                id: singleCustomOrder?.id,
                customType: singleCustomOrder?.fileType,
              }}
              // validationSchema={validationSchema}
              onSubmit={(values) => updateCustomOrderHandler(values)}
            >
              {({ handleChange, handleSubmit, values, errors, touched }) => (
                <Form onSubmit={handleSubmit} id="customer-add">
                  <div className="col">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">Estimated Date</span>
                      </label>
                      <DatePicker
                        className="form-control"
                        name="estimatedDate"
                        selected={estimatedDate || ""}
                        onChange={(date) => SetEstimatedDate(date)}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">
                          Custom Order Type
                        </span>
                        <span className="text-danger"> *</span>
                      </label>
                      <select
                        name="customType"
                        // onChange={(e) => setCustomType(e.target.value)}
                        className="form-select"
                        value={values.customType || ""}
                        onChange={handleChange}
                      >
                        <option value="">Select any</option>
                        <option value="IMAGE">Image</option>
                        <option value="AUDIO">Voice</option>
                      </select>
                    </div>
                  </div>
                  {values.customType === "IMAGE" && (
                    <div className="col">
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Custom Order Image
                          </span>
                          <span className="text-danger"> *</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          required="required"
                          onChange={(e) => setFile(e.target.files[0])}
                          name="file"
                          accept="image/png,image/jpg,image/jpeg"
                          id="inputFile"
                        />{" "}
                        <small>Accepted Formats: png, jpg & jpeg</small>
                      </div>
                    </div>
                  )}
                  {values.customType === "AUDIO" && (
                    <div className="col">
                      <div className="mb-3 col-md-4">
                        <label className="form-label" htmlFor="inputCity">
                          <span className="font-weight-bold">
                            Custom Order Voice
                          </span>
                          <span className="text-danger"> *</span>
                        </label>

                        <AudioReactRecorder
                          state={state.recordState}
                          onStop={onStop}
                          canvasHeight={100}
                        />

                        <div className="btn-group mt-2" role="group">
                          <button
                            type="button"
                            onClick={start}
                            className="btn btn-primary btn-lg"
                          >
                            <i class="fas fa-microphone"></i>
                          </button>
                          <button
                            type="button"
                            onClick={stop}
                            className="btn btn-danger btn-lg"
                          >
                            <i className="fas fa-pause"></i>
                          </button>
                        </div>
                        {audioBlob && (
                          <div className="mt-2">
                            <audio
                              controls
                              src={audioBlob}
                              style={{ height: 35 }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">
                        {singleCustomOrder.fileType === "IMAGE" ||
                        singleCustomOrder.fileType === null
                          ? "Previous Custom Order Image"
                          : "Previous Custom Voice Order"}
                      </span>
                    </label>
                    <div className="">
                      {singleCustomOrder.fileType === "IMAGE" ||
                      singleCustomOrder.fileType === null ? (
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/custom/${singleCustomOrder.orderId}/${singleCustomOrder.orderImage}`}
                          className="img-fluid"
                          height={100}
                          width={120}
                          alt={`${singleCustomOrder.orderImage}`}
                        />
                      ) : (
                        <audio
                          controls
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/public/audio/custom-order/${singleCustomOrder.orderId}/${singleCustomOrder.voiceMessage}`}
                          style={{ height: 35 }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-3 col-md-8">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">
                          Custom Order Description
                        </span>
                        <span className="text-info"> (optional)</span>
                      </label>
                      <Editor
                        editorState={description}
                        onEditorStateChange={onEditorStateChange}
                        editorStyle={{
                          backgroundColor: "white",
                          minHeight: "150px",
                          padding: "10px 20px",
                        }}
                        name="description"
                        editorClassName="mt-2 p-10 mx-auto"
                        value={values.description}
                      />
                    </div>
                  </div>
                  <div className="mb-3 mt-4 col-md-4">
                    <button
                      id="acsubmit"
                      type="submit"
                      className="btn btn-success btn-lg btn-block"
                      onClick={handleSubmit}
                      ref={buttonLoader}
                    >
                      Update Custom Order
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <Spinner />
  );
};

export default EditCustomOrderForm;
