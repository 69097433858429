import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { RiFilter2Fill } from "react-icons/ri";

const SearchFilter = ({ modal, setModal, data, setData }) => {
  const history = useHistory();

  const [filter, setFilter] = useState(
    data !== null ? data : { sortField: "price", sortIn: "asc" }
  );

  return (
    <div>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="lg"
        style={{ zIndex: 99999 }}
      >
        <Modal.Header>
          <Modal.Title className="font-weight-light text-center w-100 d-flex justify-content-between">
            <div>Filter Search</div>
            <div style={{ cursor: "pointer" }} onClick={() => setModal(false)}>
              X
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-4 pb-2">
            <div className="col-sm-6">
              <label className="form-label font-weight-bold">Sort By</label>
              <select
                name="sort"
                onChange={(e) =>
                  setFilter({ ...filter, sortField: e.target.value })
                }
                value={filter.sortField}
                className="form-select form-select-lg"
              >
                <option value="asc">Low to High</option>
                <option value="desc">High to Low</option>
              </select>
            </div>
            <div className="col-sm-6">
              <label className="form-label font-weight-bold">Filter By</label>
              <select
                name="filter"
                onChange={(e) =>
                  setFilter({ ...filter, sortIn: e.target.value })
                }
                className="form-select form-select-lg"
                value={filter.sortIn}
              >
                <option value="price">Price</option>
                <option value="review">Review</option>
                <option value="discount">Discount</option>
              </select>
            </div>

            <div className="mt-4">
              <button
                className="btn btn-lg btn-outline-primary display-5 "
                style={{ float: "right" }}
                onClick={() => {
                  setData(filter);
                  setModal(false);
                }}
              >
                Add Filter <RiFilter2Fill size="22px" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SearchFilter;
