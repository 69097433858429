import React, { useState } from "react";
import UserFooter from "../../User/UserFooter";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Link, useHistory } from "react-router-dom";
import "./quotation.css";
import { Button } from "react-bootstrap";
import quoteImage from "../../../assets/Images/quoteImage.png";
import { validationSchema } from "./validation";

const QuotationPage = () => {
  document.title = "Kreesa | Quotation";

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleQuotation = (values, submitProps) => {
    let data = new FormData();
    setLoading(true);
    data.append("userId", localStorage.getItem("userId"));
    data.append("product_name", values.product_name);
    data.append("quantity", values.quantity);
    data.append("excepted_price", values.expected_price);
    if (values.photo) {
      data.append("photo", values.photo);
    }
    data.append("quotation_by", localStorage.getItem("auth"));

    AxiosConfig.post(`/kreesa/quotation/save-quotation`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((res) => {
      toast.success("Quotation Successfully Submitted");
      setLoading(false);
      window.location.reload();
      window.localStorage.removeItem("values");
      submitProps.resetForm();
    });
  };

  const handleredirect = (values) => {
    window.localStorage.setItem("values", JSON.stringify(values));
    history.push({
      pathname: "/login",
      state: {
        redirect: "/quotation",
      },
    });
    toast.error("kindly login or register before placing quote order");
  };

  let localValues = JSON.parse(window.localStorage.getItem("values"));

  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      <div
        className="container mt-4 quotation__container_ p-4"
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <div className="row">
          <img
            src={quoteImage}
            alt="quotation-image"
            className="quotation__image"
          />
        </div>
        <hr />

        <p className="text-center h4 font-weight-bold">
          यहाँ तपाईंहरूले खोजेको सामानको फोटो नाम,रङ,साईज,ब्रान्ड,आदि राखेर
          कतिमा खोज्नु भएको हो त्यसको मुल्य राखेर पेस गर्नुहोस ।देशी तथा बिदेशी
          आपुर्तिकर्ताले तपाईंले खोजेको सामान दिन सक्ने मुल्य तोकि फोटो राखेर
          पठाउनु हुन्छ।आफुलाई मन पर्ने अथवा सस्तो जुन ठिक लाग्छ त्यो अडर ओके
          गर्नुहोला l
        </p>
        <Formik
          enableReinitialize={true}
          initialValues={{
            product_name: localValues?.product_name
              ? localValues?.product_name
              : "",
            quantity: localValues?.quantity ? localValues?.quantity : "",
            expected_price: localValues?.expected_price
              ? localValues?.expected_price
              : "",
            photo: localValues?.photo ? localValues?.photo : null,
            checked: localValues?.checked ? localValues?.checked : false,
          }}
          onSubmit={(values) =>
            localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
            localStorage.getItem("auth") === "ROLE_RETAILER" ||
            localStorage.getItem("auth") === "ROLE_USER"
              ? handleQuotation(values)
              : handleredirect(values)
          }
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            handleBlur,
          }) => (
            <Form id="quotation-form" onSubmit={handleSubmit}>
              <div className="col">
                <div className="mt-1">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="productName"
                  >
                    Product Name
                  </label>
                  <p className="">
                    Here you can add the name of product, brand, color, size,
                    country of manufacture etc.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    name="product_name"
                    onChange={handleChange}
                    value={values.product_name || ""}
                    id="productName"
                    placeholder="Enter Product Name"
                  />
                  {errors.product_name && touched.product_name ? (
                    <div style={{ color: "red" }}>{errors.product_name}</div>
                  ) : null}
                </div>
                <div className="mt-3">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="quantityy"
                  >
                    Quantity
                  </label>
                  <p className="">
                    Kindly mention number of units you are looking for.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    name="quantity"
                    onChange={handleChange}
                    value={values.quantity || ""}
                    id="quantityy"
                    placeholder="Enter quantity"
                  />
                  {errors.quantity && touched.quantity ? (
                    <div style={{ color: "red" }}>{errors.quantity}</div>
                  ) : null}
                </div>

                <div className="mt-3">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="price"
                  >
                    Expected Price
                  </label>
                  <p className="">
                    Here you can add the range of price you can offer for the
                    required product. (Per Unit)
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    name="expected_price"
                    onChange={handleChange}
                    value={values.expected_price || ""}
                    id="price"
                    placeholder="Enter Expected Price"
                  />
                  {errors.expected_price && touched.expected_price ? (
                    <div style={{ color: "red" }}>{errors.expected_price}</div>
                  ) : null}
                </div>

                <div class="form-check mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="checked"
                    value={values.checked}
                    checked={values.checked ? "checked" : ""}
                    onChange={handleChange}
                    style={{
                      width: 20,
                      height: 20,
                      marginTop: -2,
                    }}
                  />
                  <label
                    className="form-check-label font-weight-bold"
                    for="flexCheckChecked"
                  >
                    Is image available?
                  </label>
                </div>

                {values.checked && (
                  <div className="mt-2">
                    <label
                      className="form-label font-weight-normal"
                      htmlFor="Image"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="photo"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={(e) => {
                        setFieldValue("photo", e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                    />
                    <small>Accepted Formats: png, jpg & jpeg</small>

                    {errors.photo && touched.photo ? (
                      <div style={{ color: "red" }}>{errors.photo}</div>
                    ) : null}
                  </div>
                )}
              </div>
              {localStorage.getItem("token") && (
                <div className="mt-4">
                  <Link
                    to={`${
                      localStorage.getItem("auth") === "ROLE_RETAILER"
                        ? "/retailer/quotation"
                        : localStorage.getItem("auth") === "ROLE_WHOLESALER"
                        ? "/wholesaler/quotation"
                        : "/manage-account?my-quotation"
                    }`}
                    style={{
                      fontSize: 17,
                      fontWeight: "bold",
                    }}
                  >
                    Click here for Responses to your quote order !
                  </Link>
                </div>
              )}
              {/* ))} */}
              <div className="mt-4 d-flex justify-content-end w-100">
                <Button
                  className="px-4 btn"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                  style={{
                    backgroundColor: "#00a8ff",
                  }}
                >
                  {loading ? "Loading . . . " : "Submit"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default QuotationPage;
