import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal as Modals } from "react-responsive-modal";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import {
  approveOrder,
  rejectOrder,
} from "../../Features/Action/OrderStatusAction";
import Spinner from "../../Spinner";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import SupplierDetailsModal from "../SingleOrderDetail/SupplierDetailsModal";
import TopNavbar from "../../TopNavbar";
import LeftNavbar from "../../LeftNavbar";
import ReactHTMLParser from "react-html-parser";

const ViewReturnOrder = (props) => {
  document.title = "Admin | Return Order";

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setstate] = useState();
  const [specId, setSpecId] = useState();
  const [orderDetailsId, setOrderDetailsId] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  const [modalId, setModalId] = useState("");
  const [user, setUser] = useState();

  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };
  // const handleClose = () => setModalId(false);
  // get single order from API
  const getSingleOrder = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetMyOrderDetails = await AxiosConfig.get(
        `/kreesa/admin/return/view-single-return/${id}`,
        config
      );
      setstate(GetMyOrderDetails.data);
      setIsLoaded(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      if (
        err?.response?.data?.httpStatusCode === 403 ||
        err?.response?.data?.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };
  useEffect(() => {
    getSingleOrder(id);
  }, []);

  // get consumer details
  const getUsers = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const getu = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${state.orderAndShippingDetails?.consumerId}`,
        config
      );
      setUser(getu.data);
    } catch (error) {}
  };
  useEffect(() => {
    getUsers();
  }, [state]);

  // approve Order
  const handleReturnConfirm = async (id) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetMyOrderDetails = await AxiosConfig.get(
        `/kreesa/admin/return/approve-return-request/${id}`,
        config
      );
      toast.success("Return Request Approved");
      history.push("/admin/order/return");

      setstate(GetMyOrderDetails.data);
      setIsLoaded(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };

  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            {!isLoaded ? (
              <div className="text-capitalize">
                <div className="card mx-5 p-2 d-flex flex-row justify-content-between align-items-center">
                  <h5 className="font-weight-bold">Return Order Details</h5>
                  <div className="col-md-2 m-2">
                    {/* <div className="btn-group">
                      <button
                        type="button"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "#3b7ddd",
                        }}
                        className="btn-md btn btn-success dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Actions
                      </button>

                      <div className="dropdown-menu">
                        <div
                          onClick={() => {
                            handleReturnConfirm(state?.order?.id);
                          }}
                          className="dropdown-item cursor-pointer"
                        >
                          <i className="fas fa-check text-success"></i> Approve
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="card mx-5 mt-4">
                  <div className="p-1">
                    <p className="ml-2">
                      Order Id:{" "}
                      <span className="text-muted">
                        {state?.orderAndShippingDetails?.orderNo}
                      </span>
                    </p>
                    <p className="card-text ml-2">
                      Order Date:{" "}
                      <span className="text-muted">
                        {moment(
                          state?.orderAndShippingDetails?.orderDate
                        ).format("lll")}
                      </span>
                    </p>
                    <p className="card-text ml-2">
                      Order Type:{" "}
                      <span className="text-muted font-weight-bold">
                        {state?.orderAndShippingDetails?.orderType}
                      </span>
                      <span
                        onClick={() =>
                          handleOpenModal(
                            state?.orderAndShippingDetails?.consumerId
                          )
                        }
                        className="text-success ml-4 cursor-pointer"
                      >
                        Consumer Details
                      </span>
                    </p>
                  </div>
                  <div className="p-1">
                    <p className="ml-2 mt-2">
                      Product Name:{" "}
                      <span className="text-muted">
                        {state?.productDetails?.productName}
                      </span>
                    </p>
                    <p className="card-text ml-2">
                      Description:{" "}
                      <span className="text-muted">
                        {ReactHTMLParser(state?.productDetails?.description)}
                      </span>
                    </p>
                  </div>
                </div>
                {modal.show &&
                  modal.requestedModalId ==
                    state?.orderAndShippingDetails?.consumerId && (
                    <Modals
                      open={modal}
                      onClose={handleCloseModal}
                      center
                      classNames={{
                        overlayAnimationIn: "customEnterOverlayAnimation",
                        overlayAnimationOut: "customLeaveOverlayAnimation",
                        modalAnimationIn: "customEnterModalAnimation",
                        modalAnimationOut: "customLeaveModalAnimation",
                        modal: "customModal",
                      }}
                      animationDuration={1000}
                    >
                      <div className="pro">
                        {/* <div className="col-md-4">
                    <div className="profile-img">
                      <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${id}/${user.companyLogo}`} alt={user?.companyName} />
                    </div>
                  </div> */}

                        <h3 className="text-uppercase">
                          {`${
                            user?.role === "ROLE_USER"
                              ? user?.firstname + " " + user?.lastname
                              : user?.companyName
                          }`}
                        </h3>
                      </div>
                      {user.role === "ROLE_USER" ? (
                        <>
                          <div className="col-sm-3 mt-2">
                            <h6 className="mb-0">Full Name</h6>
                          </div>
                          <div className="col-sm-9 text-secondary font-weight-bold">
                            {user?.firstname} {user?.lastname}
                          </div>
                          <div className="col-sm-3 mt-2">
                            <h6 className="mb-0">E-mail</h6>
                          </div>
                          <div className="col-sm-9 text-secondary font-weight-bold">
                            {user?.emailId}
                          </div>
                          <div className="col-sm-3 mt-2">
                            <h6 className="mb-0">Address</h6>
                          </div>
                          <div className="col-sm-9 text-secondary font-weight-bold">
                            {`${user?.districtName} , ${user?.provienceName} , ${user?.countryName}`}
                          </div>
                          <div className="col-sm-3 mt-2">
                            <h6 className="mb-0">Contact</h6>
                          </div>
                          <div className="col-sm-9 text-secondary font-weight-bold">
                            {user?.contact}
                          </div>
                        </>
                      ) : (
                        <div className="col-md-6 m-auto">
                          <div className="tab-content profile-tab custom-height">
                            <div
                              className="tab-pane fade show active"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Company Name</label>
                                </div>
                                <div className="col-md-6">
                                  <p>{user?.companyName}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Company Address</label>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    {`${user?.districtName} , ${user?.provienceName} , ${user?.countryName}`}
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <label>Company Email</label>
                                </div>
                                <div className="col-md-6">
                                  <p>{user?.companyEmail}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Company Type</label>
                                </div>
                                <div className="col-md-6">
                                  <p>{user?.companyType}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Company Contact</label>
                                </div>
                                <div className="col-md-6">
                                  <p> {user?.companyContactNo}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>PAN No</label>
                                </div>
                                <div className="col-md-6">
                                  <p> {user?.panNo}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Register Status</label>
                                </div>
                                <div className="col-md-6">
                                  {user?.isRegistered === true ? (
                                    <p>Register</p>
                                  ) : (
                                    <p>Not Register</p>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Insuranced Status</label>
                                </div>
                                <div className="col-md-6">
                                  {user?.isInsured === true ? (
                                    <p>Insuranced</p>
                                  ) : (
                                    <p>Not Insuranced</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Modals>
                  )}
                <div className="card mx-5 mt-4">
                  <div className="">
                    <h6 className="h6 ml-4 mt-1 pt-1">Sold by kreesa</h6>
                    <hr className="text-success mx-4" />
                  </div>
                  {/*  ------------------------------- main image -------------------------------  */}

                  <div className="row g-0">
                    {state?.image?.map((img, i) =>
                      img ? (
                        <div className="col-md-3 ml-4" key={i}>
                          <img
                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                            className="img-fluid m-2 rounded-start"
                            style={{
                              width: "125px",
                              height: "125px",
                              borderRadius: "50%",
                            }}
                            alt={img.image}
                          />
                        </div>
                      ) : (
                        <img
                          src={`/assets/images/kreesalogo.png`}
                          className="img-fluid m-2 rounded-start"
                          style={{
                            width: "125px",
                            height: "125px",
                            borderRadius: "50%",
                          }}
                          alt={img.image}
                        />
                      )
                    )}
                    <div className="col-md-3">
                      <div className="card-body">
                        <p className="h5 font-weight-bold">
                          {state?.productDetails?.productName}
                        </p>
                        {/* <p className="card-text">this isi</p>  */}
                        <p className="card-text">
                          <small className="badge bg-info">
                            {state?.order?.selectedColor}
                          </small>
                        </p>
                        {state?.order.discount === 0 ? (
                          <p className="card-text">
                            <small className="text-warning h4">
                              Rs. {state?.order?.productPrice}
                            </small>
                          </p>
                        ) : (
                          <p className="card-text">
                            <s className="text-muted">
                              Rs. {state?.order?.productPrice}
                            </s>
                            <small className="ml-2 text-warning font-weight-bold h4">
                              Rs{" "}
                              {Math.round(
                                state?.order?.productPrice -
                                  (state?.order?.productPrice *
                                    state?.order?.discount) /
                                    100
                              )}
                            </small>
                            <small className="ml-2 h5">
                              {" "}
                              - {state?.order?.discount} % OFF
                            </small>
                          </p>
                        )}
                      </div>
                    </div>

                    {/*  ------------------------------- model start ------------------------------  */}
                    <div className="row m-4">
                      {state?.image?.map((img, i) => (
                        <div className="col-md-2" key={i}>
                          <div
                            onClick={() => handleOpenModal(img.imageId)}
                            className="d-flex cursor-pointer"
                          >
                            <img
                              className="img-fluid img-thumbnail m-1"
                              src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                              height={100}
                              width={120}
                              alt={img.image}
                            />
                          </div>
                          {/*Modal  */}
                          {modal.show && modal.requestedModalId == img.imageId && (
                            <Modals
                              open={modal}
                              onClose={handleCloseModal}
                              center
                              classNames={{
                                overlayAnimationIn:
                                  "customEnterOverlayAnimation",
                                overlayAnimationOut:
                                  "customLeaveOverlayAnimation",
                                modalAnimationIn: "customEnterModalAnimation",
                                modalAnimationOut: "customLeaveModalAnimation",
                              }}
                              animationDuration={1000}
                            >
                              <div className="pro">
                                <img
                                  className="img-fluid img-thumbnail"
                                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                                  height={650}
                                  width={700}
                                  alt={img.image}
                                />
                              </div>
                            </Modals>
                          )}
                        </div>
                      ))}
                    </div>
                    {/*  ------------------------------- model end ------------------------------  */}
                  </div>
                </div>
                {/* large modal end*/}
                <div className="card mx-5 mt-4">
                  <div className="row">
                    <div className="col-6 ml-5 mt-4 col-md-3">
                      {/*<h6 className="h6 ml-4 mt-1">Billing Address</h6>
            <hr className="text-info" />
            <p className="ml-2">Name</p>
            <i className="ml-2">{state.shippingAddress}</i>
            <p className="ml-2">{state.contactNo}</p>*/}
                    </div>
                    <div className="col-6 ml-5 mt-4 col-md-3">
                      <h6 className="h6 ml-4 mt-1">Shipping Address</h6>
                      <hr className="text-info" />
                      <p className="ml-2">Name</p>
                      <i className="ml-2">
                        {state?.orderAndShippingDetails?.shippingState} -{" "}
                        {state?.orderAndShippingDetails?.shippingAddress} -{" "}
                        {state?.orderAndShippingDetails?.shippingCountry}
                      </i>
                      <p className="ml-2">
                        {state?.orderAndShippingDetails?.contactNo}
                      </p>
                    </div>

                    <div className="col-6 ml-5 mt-4 col-md-3">
                      <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                          <div>
                            <h6 className="my-0">
                              {state?.order?.productDetails?.productName}
                            </h6>
                            <span className="text-muted">
                              Qty : {state?.order?.orderQuantity}
                            </span>
                          </div>
                          <small className="text-muted">
                            Rs{" "}
                            {Math.round(
                              state?.order?.productPrice -
                                (state?.order?.productPrice *
                                  state?.order?.discount) /
                                  100
                            ) * state?.order.orderQuantity}
                          </small>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Shipping Cost</span>
                          <strong>
                            Rs {state?.orderAndShippingDetails?.shippingCost}
                          </strong>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Total (Npr)</span>
                          <strong>
                            {Math.round(
                              state?.orderAndShippingDetails?.totalCost
                            )}
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ViewReturnOrder;
