import { createSlice } from "@reduxjs/toolkit";
import { getGroupProduct,getSingleGroupProduct } from "../../Action/GroupProductAction";

const GroupProduct = createSlice({
  name: "GroupProduct",
  initialState: {
    GroupProduct: [],
    GroupProductContainer: [],
    loader: false,
    errors: {},
    EditGroupProduct:[],
  },
  reducers: {
    filterGroupProduct: (state, action) => {
      state.GroupProduct = state.GroupProductContainer.filter((u) => u.groupName.toLowerCase().includes(action.payload) || u.groupName.toUpperCase().includes(action.payload));
    },
  },
  extraReducers: {
    /* to get all Group Product */
    [getGroupProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [getGroupProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.GroupProduct = action.payload;
      state.GroupProductContainer = action.payload;
    },
    [getGroupProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

     /* to get Single Group Product */
     [getSingleGroupProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleGroupProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.EditGroupProduct = action.payload;
    },
    [getSingleGroupProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterGroupProduct } = GroupProduct.actions;
// export default
export default GroupProduct.reducer;
