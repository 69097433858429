import React, { useState } from "react";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import "./myOrder.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { Pagination } from "@mantine/core";
import Spinner from "../../Admin/Spinner";

function OrderCancell() {
  const { userOrder } = useSelector((state) => state.userProducts);

  let orders = userOrder?.filter(
    (i) =>
      i.orderDetails?.filter((j) => j.sellerApproval === "Rejected").length > 0
  );

  const history = useHistory();
  const [ratingModal, setRatingModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = orders?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(orders?.length / perPage);
  return (
    <div className="container">
      {" "}
      <div className="row">
        {/* <div className="col-12 d-flex w-100">
                <div className="card flex-fill flex-fill">
                    <div className="card-header">
                        <Link to="/retailer/product/register" className="btn btn-lg btn-outline-info display-5 mt-2" style={{ float: 'right' }}>
                            Add Product  <i className="fab fa-product-hunt"></i></Link>
                    </div>
                </div>
            </div> */}
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">My Cancelled Orders</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Orders Number</th>
                  <th>Orders Date</th>
                  <th className="d-xl-table-cell">Number of Order</th>
                  <th className="d-md-table-cell">Address</th>
                  <th className="d-md-table-cell">Payment Status</th>
                  <th className="d-md-table-cell">Payment Method</th>
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map((p, i) => (
                  <tr>
                    <td>{perPage * (currentPage - 1) + i + 1}</td>
                    <td>{p.orderNo}</td>
                    <td>{moment(p.orderDate).format("ll")}</td>
                    <td className="d-xl-table-cell">
                      {p.orderDetails?.length}
                    </td>
                    <td className="d-md-table-cell">{p.shippingAddress}</td>
                    <td>
                      <span
                        className={
                          p?.payment?.paymentStatus === "PAID"
                            ? "badge bg-success"
                            : "badge bg-danger"
                        }
                      >
                        {p?.payment?.paymentStatus}
                      </span>
                    </td>
                    <td>{p?.payment?.paymentMethod}</td>

                    <td className="d-md-table-cell">
                      <div className="btn-group">
                        <button
                          type="button"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#3b7ddd",
                          }}
                          className="btn-md btn btn-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <div className="dropdown-menu text-center">
                          <Link
                            to={`/order-details-user/${p.id}`}
                            className="dropdown-item text-primary"
                          >
                            <i className="fas fa-eye mr-2"></i> View More
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={state}
        /> */}
        <Pagination
          onChange={setCurrentPage}
          page={currentEntries}
          total={totalPagesNum}
        />
      </div>
    </div>
  );
}

export default OrderCancell;
