import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { unBlockUser } from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";

const BlockCustomer = () => {
  const [state, setstate] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(true);
  // get all Blocked Wholesalers from API
  const getBlockedCustomers = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetallCustomerDetails = await AxiosConfig.get(
        "/kreesa/admin/get-all-customer",
        config
      );
      setstate(GetallCustomerDetails.data);
      setIsLoaded(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };
  useEffect(() => {
    getBlockedCustomers();
  }, []);

  //unblock user
  const handleUnBlockUser = (id) => {
    dispatch(unBlockUser(id));
    toast.info("Retailer has been unblocked");
    getBlockedCustomers();
  };
  return !isLoaded ? (
    <div>
      <div className="container">
        {/* <div className="row">
              <div className="col-12 d-flex w-100">
                <div className="card flex-fill flex-fill">
                  <div className="card-header">
                    <Link
                      to="/admin/add-new-user"
                      className="btn btn-lg btn-outline-info display-5 mt-2"
                      style={{ float: "right" }}
                    >
                      Add New User <i className="fas fa-user-plus ml-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
        <div className="row">
          <div
            className="col-12 col-lg-8 d-flex w-100"
            style={{ minHeight: "420px" }}
          >
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Blocked Customers</h5>
              </div>
              <table className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Full Name</th>
                    <th className="d-xl-table-cell">E-mail</th>
                    <th className="d-xl-table-cell">Phone</th>
                    <th>Status</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state?.map(
                    (p, i) =>
                      p.flag == false && (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {p.firstname} {p.lastname}
                          </td>
                          <td className="d-xl-table-cell">
                            <a href={`mailto:${p.email}`}>{p.email}</a>
                          </td>
                          <td className="d-xl-table-cell">{p.phoneNumber}</td>
                          <td>
                            {p.flag === true ? (
                              p.locked === true ? (
                                <span className="badge bg-success">Active</span>
                              ) : (
                                <span className="badge bg-danger">
                                  Inactive
                                </span>
                              )
                            ) : (
                              <span className="badge bg-danger">Blocked</span>
                            )}
                          </td>
                          <td>
                            <div className="btn-group">
                              <button
                                type="button"
                                style={{
                                  textTransform: "capitalize",
                                  backgroundColor: "#3b7ddd",
                                }}
                                className="btn-md btn btn-success dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                More
                              </button>
                              <div className="dropdown-menu text-left">
                                {localStorage.getItem("auth") !==
                                "ROLE_RECEPTIONIST" ? (
                                  <>
                                    <Link
                                      to={`/admin/edit-customer/${p.userId}`}
                                      className="dropdown-item text-info cursor-pointer"
                                    >
                                      <i className="fas fa-user-edit"></i> Edit
                                    </Link>
                                    <div className="dropdown-divider" />
                                    <Link
                                      to={`/admin/view-customers/${p.userId}`}
                                      className="dropdown-item text-success cursor-pointer"
                                    >
                                      <i className="fas fa-eye"></i> Review
                                    </Link>
                                    <div className="dropdown-divider" />
                                    <p
                                      onClick={() =>
                                        handleUnBlockUser(p.userId)
                                      }
                                      className="dropdown-item text-danger cursor-pointer"
                                    >
                                      <i className="fas fa-user-lock"></i>{" "}
                                      UnBlock
                                    </p>
                                  </>
                                ) : (
                                  <Link
                                    to={`/admin/view-customers/${p.userId}`}
                                    className="dropdown-item text-success cursor-pointer"
                                  >
                                    <i className="fas fa-eye"></i> Review
                                  </Link>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default BlockCustomer;
