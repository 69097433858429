import $ from "jquery";
import "jquery-validation";


const UserLoginValidation = () => {
  // Wait for the DOM to be ready
$(document).ready(function () {
  
  $("#user-login-form").validate({
    // Specify validation rules
    rules: {
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
      },
    },
    messages: {
      email: {
        required: "Email is required",
      },
      password: {
        required: "Password is required",
      },
    },

    highlight: function (element) {
      $(element).removeClass("is-valid").addClass("is-invalid");
    },
    unhighlight: function (element) {
      $(element).removeClass("is-invalid").addClass("is-valid");
    },
  });
 
});
$('#user-login-form input').on('keyup blur', function () { // fires on every keyup & blur
  if ($('#user-login-form').valid()) {                   // checks form for validity
      $('button#lbtn').prop('disabled', false);        // enables button
  } else {
      $('button#lbtn').prop('disabled', 'disabled');   // disables button
  }
});

}

export default UserLoginValidation
