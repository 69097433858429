import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../ButtonLoaderHook";
import { checkemail, checkPhone } from "../../User/UserSignup/CheckExistValue";
import { useHistory } from "react-router-dom";
import { getAllCountries } from "../Features/Action/AddressAction";
import { getAllProvince } from "../Features/Action/AddressAction";
import { getAllDistrict } from "../Features/Action/AddressAction";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  // address: Yup.string().required(" Address is required"),
  phone_no: Yup.number().required("Phone Number is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  role: Yup.string().required("Role is required"),
  image: Yup.mixed()
    .required("image is required")
    .test(
      "fileType",
      "Only accept Jpg/Jpeg/Png file type",
      (file) =>
        file && ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
    ),

  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const AddAdminForm = () => {
  const [image, setImage] = useState();
  const { countries } = useSelector((s) => s.AddressReducer);
  const { proviences } = useSelector((s) => s.AddressReducer);
  const { districts } = useSelector((s) => s.AddressReducer);

  const [selectProvince, SetSelectProvince] = useState([]);
  const [selectDistrict, SetSelectDistrict] = useState([]);

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const [file, setFile] = useState();
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add New User",
    "Loading..."
  );

  document.title = "Admin | Add User";

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    // address: "",
    phone_no: "",
    role: "",
    password: "",
    countryId: countries.id,
    provinceId: proviences.id,
    districtId: districts.id,
    street_address: "",
    landmark: "",
    pin_code: "",
    image: null,
  };

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllProvince());
    dispatch(getAllDistrict());
  }, []);

  const addUserHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();

      data.append("image", values.image);

      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("email", values.email);
      // data.append("address", values.address);
      data.append("phone_no", values.phone_no);
      data.append("role", values.role);
      data.append("password", values.password);
      data.append("countryId", values.countryId);
      data.append("provinceId", values.provinceId);
      data.append("districtId", values.districtId);
      data.append("street_address", values.street_address);
      data.append("landmark", values.landmark);
      data.append("pin_code", values.pin_code);
      const res = await AxiosConfig.post(
        "/kreesa/admin/register-new-admin",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res) {
        toast.success("New user added succesfully !");
        setButtonLoader(false);
        history.push("/admin/details");
      }
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };

  // UserRegisterValidation();
  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title font-weight-bold">Add Admin Forms</h5>
          <h6 className="card-subtitle text-muted">
            {" "}
            You Can Easily Add New User.
          </h6>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values) => {
              addUserHandler(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              setFieldValue,
              handleBlur,
            }) => (
              <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputEmail4">
                      First Name
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="first_name"
                      onChange={handleChange}
                      value={values.first_name}
                      id="inputEmail5"
                      placeholder="Enter First Name"
                    />
                    {errors.first_name && touched.first_name ? (
                      <div style={{ color: "red" }}>{errors.first_name}</div>
                    ) : null}
                  </div>

                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputPassword4">
                      Last Name
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="last_name"
                      onChange={handleChange}
                      value={values.last_name}
                      id="inputPassword5"
                      placeholder="Enter First Name"
                    />
                    {errors.last_name && touched.last_name ? (
                      <div style={{ color: "red" }}>{errors.last_name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputEmail4">
                      Email{" "}
                      {/* <span className="font-weight-bold">(User name)</span> */}
                    </label>
                    <input
                      type="email"
                      required
                      className="form-control"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      id="email"
                      placeholder="Enter Email"
                      onKeyUp={checkemail}
                    />
                    {errors.email && touched.email ? (
                      <div style={{ color: "red" }}>{errors.email}</div>
                    ) : null}
                    <span
                      id="email-errors"
                      className="error"
                      htmlFor="email"
                    ></span>
                  </div>

                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputPassword4">
                      Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      id="inputPassword4"
                      placeholder="Enter Password"
                    />
                    <span
                      className="input-group-text cursor-pointer mt-2"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        width: 40,
                      }}
                    >
                      <i
                        className={`${
                          showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                        }`}
                        id="togglePassword"
                      ></i>
                    </span>

                    {errors.password && touched.password ? (
                      <div style={{ color: "red" }}>{errors.password}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputEmail4">
                      Country
                    </label>
                    <select
                      name="countryId"
                      onChange={handleChange}
                      onBlur={(e) => {
                        SetSelectProvince(e.target.value);
                      }}
                      values={values.countryId}
                      className="form-control"
                    >
                      <option value="">Select Your Country...</option>
                      {countries?.map((p, i) => (
                        <option key={i} value={p.id}>
                          {p.country_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputEmail4">
                      Province
                    </label>
                    <select
                      name="provinceId"
                      onChange={handleChange}
                      onBlur={(e) => {
                        SetSelectDistrict(e.target.value);
                      }}
                      values={values.provinceId}
                      className="form-control"
                    >
                      <option value="">Select Your Provience...</option>
                      {proviences?.map(
                        (pr, i) =>
                          selectProvince == pr.country.id && (
                            <option key={i} value={pr.id}>
                              {pr?.provience_name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputEmail4">
                      District
                    </label>
                    <select
                      name="districtId"
                      onChange={handleChange}
                      values={values.districtId}
                      className="form-control"
                    >
                      <option value="">Select Your District...</option>
                      {districts?.map(
                        (d, i) =>
                          selectDistrict == d?.stateOrProvience?.id && (
                            <option key={i} value={d.id}>
                              {d.district_name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputPassword4">
                      Street Address
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={values.street_address}
                      name="street_address"
                      onChange={handleChange}
                      id="street_address"
                      placeholder="Enter Street Address"
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputPassword4">
                      Landmark
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={values.landmark}
                      name="landmark"
                      onChange={handleChange}
                      id="landmark"
                      placeholder="Enter Street Address"
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputPassword4">
                      Pin Code
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={values.pin_code}
                      name="pin_code"
                      onChange={handleChange}
                      id="pin_code"
                      placeholder="Enter Street Address"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputZip">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="phone_no"
                      value={values.phone_no}
                      onChange={handleChange}
                      id="phone"
                      placeholder="Enter Phone Number"
                      onKeyUp={checkPhone}
                    />
                    {errors.phone_no && touched.phone_no ? (
                      <div style={{ color: "red" }}>{errors.phone_no}</div>
                    ) : null}
                    <span
                      id="phone-verify-error"
                      className="error"
                      htmlFor="phone"
                    ></span>
                  </div>

                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputState">
                      Role
                    </label>
                    <select
                      id="inputState"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      required
                      className="form-control"
                    >
                      <option value="">Choose Role First...</option>
                      <option value="ROLE_CEO">CEO</option>
                      <option value="ROLE_MANAGER">Manager</option>
                      <option value="ROLE_MARKETING_MANAGER">
                        Marketing Manager
                      </option>
                      <option value="ROLE_RECEPTIONIST">Receptionist</option>
                      <option value="ROLE_DELIVERY_BOY">Delivery Boy</option>
                      <option value="ROLE_HR">HR</option>
                    </select>
                    {errors.role && touched.role ? (
                      <div style={{ color: "red" }}>{errors.role}</div>
                    ) : null}
                  </div>

                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="Image">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      accept="image/png,image/jpg,image/jpeg"
                      required
                      onChange={(e) => {
                        setFieldValue("image", e.target.files[0]);
                        setImage(URL.createObjectURL(e.target.files[0]));
                      }}
                      onBlur={handleBlur}
                    />
                    <small>Accepted Formats: png, jpg & jpeg</small>
                    {image && (
                      <div className="mt-2" style={{ flexDirection: "column" }}>
                        <img src={image} alt="dummy" height={80} width={100} />
                      </div>
                    )}
                    {errors.image && touched.image ? (
                      <div style={{ color: "red" }}>{errors.image}</div>
                    ) : null}
                  </div>
                </div>
                <button
                  type="submit"
                  id="sends"
                  className="btn btn-success btn-lg"
                  ref={buttonLoader}
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  Add New User
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddAdminForm;
