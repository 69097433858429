import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import ReturnOrderDetails from "./ShowReturnOrders";

const ReturnOrdersAdmin = () => {
  document.title = "Admin | Return Orders";
  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <ReturnOrderDetails />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ReturnOrdersAdmin;
