import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import useButtonLoader from '../../../ButtonLoaderHook';
import AxiosConfig from '../../../../Common/Api/AxiosConfig';
import { useHistory, useParams } from 'react-router-dom';
import {
  viewAllLegalDocs,
  viewLegalDoc,
} from '../../Features/Action/LegalAction';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../Spinner';
import ReactDatePicker from 'react-datepicker';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  maxUser: Yup.number().required('Max User is required'),
  userLimit: Yup.number().required('User Limit is required'),
  discountType: Yup.string().required('User Limit is required'),
  targetUser: Yup.string().required('Type is required'),
  // maxDiscount: Yup.number().required("Max Discount is required"),
  maxDiscount: Yup.number().when('discountType', {
    is: 'PERCENTAGE',
    then: Yup.number().required('Max Discount is required'),
  }),
  discount: Yup.number().required('Discount is required'),
  code: Yup.string()
    .length(8, 'Must be 8 characters')
    .required('Code is required'),
});

const Voucher = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { singleDoc, legalData, loader } = useSelector((s) => s.LegalReducer);
  const [expiryDate, setExpiryDate] = useState(new Date());

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    `${id ? 'Update' : 'Create'} Discount Voucher`,
    'Loading...'
  );

  const LegalHandler = async (values) => {
    setButtonLoader(true);

    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await AxiosConfig.post(
        '/kreesa/voucher/admin/save',
        {
          ...values,
        },
        config
      );
      if (res.status === 200) {
        toast.success('Discount Voucher Added Successfully');
        setButtonLoader(false);
        history.push('/admin/discount-voucher');
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };

  const targetUsers = ['SELLER', 'CUSTOMER'];

  return !loader ? (
    <div className="container card m-2">
      <div className="p-3">
        <h4 className="mb-3">Create Discount Voucher</h4>
        <Formik
          initialValues={{
            targetUser: '',
            title: '',
            code: '',
            discount: '',
            maxDiscount: 0,
            expiryDate: expiryDate,
            maxUser: 0,
            userLimit: 0,
            discountType: '',
          }}
          onSubmit={LegalHandler}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row mt-2">
                <div className="mb-3 col-md-4">
                  <label className="form-label" htmlFor="inputEmail4">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={handleChange}
                    id="inputTitle"
                    placeholder="Enter Title"
                  />
                  {errors.title && touched.title ? (
                    <div style={{ color: 'red' }}> {errors.title} </div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-md-2">
                  <label className="form-label" htmlFor="inputEmail4">
                    Target User
                  </label>

                  <select
                    name="targetUser"
                    onChange={handleChange}
                    values={values.targetUser}
                    className="form-select"
                    disabled={id && true}
                  >
                    <option hidden value="">
                      {id ? values.targetUser : 'Select User Type'}
                    </option>
                    {targetUsers.map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>

                  {errors.targetUser && touched.targetUser ? (
                    <div style={{ color: 'red' }}> {errors.targetUser} </div>
                  ) : null}
                </div>
                <div className="mb-3 col-md-2">
                  <label className="form-label" htmlFor="inputEmail4">
                    Code
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    className="form-control"
                    name="code"
                    id="inputCode"
                    placeholder="Enter Code"
                  />
                  {errors.code && touched.code ? (
                    <div style={{ color: 'red' }}> {errors.code} </div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="mb-2 col-md-4">
                  <label className="form-label" htmlFor="inputDiscount4">
                    Discount Type
                  </label>
                  <select
                    name="discountType"
                    onChange={handleChange}
                    values={values.discountType}
                    className="form-select"
                    disabled={id && true}
                  >
                    <option hidden value="">
                      Select discount Type
                    </option>
                    <option value="AMOUNT">Amount (in NPR)</option>
                    <option value="PERCENTAGE">Percentage (in %)</option>
                  </select>

                  {errors.discountType && touched.discountType ? (
                    <div style={{ color: 'red' }}> {errors.discountType} </div>
                  ) : null}
                </div>
              </div>

              <div className="row mt-2">
                <div className="mb-3 col-md-2">
                  <label className="form-label" htmlFor="inputEmail4">
                    Discount
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="discount"
                    onChange={handleChange}
                    id="inputDiscount"
                    placeholder="Enter Discount"
                  />
                  {errors.discount && touched.discount ? (
                    <div style={{ color: 'red' }}> {errors.discount} </div>
                  ) : null}
                </div>
                <div className="mb-3 col-md-2">
                  <label className="form-label" htmlFor="inputEmail4">
                    Max Discount Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="maxDiscount"
                    onChange={handleChange}
                    id="inputmaxDiscount"
                    placeholder="Enter Max Discount"
                    disabled={values.discountType === 'AMOUNT'}
                  />
                  {errors.maxDiscount && touched.maxDiscount ? (
                    <div style={{ color: 'red' }}> {errors.maxDiscount} </div>
                  ) : null}
                </div>
              </div>
              {/*  */}
              <div className="row mt-2">
                <div className="mb-3 col-md-2">
                  <label className="form-label" htmlFor="inputEmail4">
                    Max User
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="maxUser"
                    onChange={handleChange}
                    id="inputmaxUser"
                    placeholder="Enter Max User"
                  />
                  {errors.maxUser && touched.maxUser ? (
                    <div style={{ color: 'red' }}> {errors.maxUser} </div>
                  ) : null}
                </div>
                <div className="mb-3 col-md-2">
                  <label className="form-label" htmlFor="inputEmail4">
                    User Limit
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="userLimit"
                    onChange={handleChange}
                    id="inputuserLimit"
                    placeholder="Enter User Limit"
                  />
                  {errors.userLimit && touched.userLimit ? (
                    <div style={{ color: 'red' }}> {errors.userLimit} </div>
                  ) : null}
                </div>
              </div>
              {/*  */}

              <div className="mb-3 col-md-4">
                <label className="form-label" htmlFor="inputEmail4">
                  Expiry Date
                </label>
                <ReactDatePicker
                  className="form-control"
                  name="expiryDate"
                  format="YYYY-MM-DD HH:mm:ss"
                  minDate={new Date()}
                  selected={expiryDate}
                  onChange={(date) => {
                    setExpiryDate(date);
                    setFieldValue('expiryDate', date);
                  }}
                  value={values.expiryDate}
                />
                {errors.expiryDate && touched.expiryDate ? (
                  <div style={{ color: 'red' }}> {errors.expiryDate} </div>
                ) : null}
              </div>

              <div className="mb-3 mt-4 col-md-4">
                <button
                  id="catadd"
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-success btn-lg btn-block"
                  ref={buttonLoader}
                >
                  {id ? 'Update' : 'Create'} Discount Voucher
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Voucher;
