import { createSlice } from "@reduxjs/toolkit";
import { getTotalNotification } from "../../../Action/AdminNotificationAction";

const TotalNotifications = createSlice({
  name: "TotalNotification",
  initialState: {
    TotalNotification: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterGroupProduct: (state, action) => {

    },
  },
  extraReducers: {
    /* to get all Products */
    [getTotalNotification.pending]: (state, action) => {
      state.loader = true;
    },
    [getTotalNotification.fulfilled]: (state, action) => {
      state.loader = false;
      state.TotalNotification = action.payload;
    },
    [getTotalNotification.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
// export const { filterProduct } = AdminNotification.actions;
// export default
export default TotalNotifications.reducer;
