import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Ceo from "./Ceo";
import Manager from "./Manager";
import Receptionist from "./Receptionist";
import MarketingManager from "./MarketingManager";
import "./style.css";

const LeftNavbar = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const history = useHistory();

  const [position, setPosition] = useState(
    useMemo(() => {
      const position = localStorage.getItem("position");
      if (position) {
        return position;
      }
      return "";
    }, [])
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // scroll to top when changing page
  useEffect(() => {
    let element = document.getElementById("sidebarContent");
    element.scrollTo(0, localStorage.getItem("position"));
  }, [position]);

  localStorage.setItem("position", position);

  return (
    <Ceo />
    // <>
    //   {localStorage.getItem("auth") === "ROLE_CEO" && (
    //     <Ceo {...{ matches, setPosition, history }} />
    //   )}

    //   {/* manager left bar */}
    //   {localStorage.getItem("auth") === "ROLE_MANAGER" && (
    //     <Manager {...{ matches, setPosition, history }} />
    //   )}

    //   {/* receptionist */}
    //   {/* manager left bar */}
    //   {localStorage.getItem("auth") === "ROLE_RECEPTIONIST" && (
    //     <Receptionist {...{ matches, setPosition, history }} />
    //   )}

    //   {/* marketing manager */}
    //   {/* manager left bar */}
    //   {localStorage.getItem("auth") === "ROLE_MARKETING_MANAGER" && (
    //     <MarketingManager {...{ matches, setPosition, history }} />
    //   )}
    // </>
  );
};

export default LeftNavbar;
