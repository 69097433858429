import "./searchPage.css";
import { useHistory } from "react-router-dom";
import Star from "../Star";
import { Excerpt } from "../../../utils";

const SearchCard = ({ title, subTitle, price, src, id, discount, review }) => {
  const history = useHistory();
  return (
    <div
      className="card s-card mx-auto"
      style={{
        width: "15rem",
        borderRadius: "5px",
        cursor: "pointer",
        boxShadow: "0px 0px 3px black",
      }}
      onClick={() => history.push(`/single-product/${id}`)}
    >
      <img className="card-img-top search-img" src={src} alt="Card image cap" />
      <div className="card-body">
        <h5 className="card-title" style={{ fontSize: "16px" }}>
          {Excerpt(title, 20)}
        </h5>
        <div className="card-text" style={{ fontSize: "16px", color: "teal" }}>
          {discount === 0 ? (
            <p className="text-success mt-1 ">
              <b>Rs. {Math.round(price)}</b>
            </p>
          ) : (
            <p className="mt-1font-weight-bold">
              <strike>Rs. {Math.round(price)}</strike>
              <br />
              <b className="text-success">
                {" "}
                Rs. {Math.round(price - (price * discount) / 100)}
              </b>
              <strong className="text-danger font-weight-bold">
                {" "}
                - {discount} % OFF
              </strong>
            </p>
          )}
        </div>

        {/* <Rating
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          className="star-rating mt-2"
          initialRating={4}
          readonly={true}
        /> */}
        <Star value={review} />

        {/* <a href="#" className="btn btn-primary">
          Go somewhere
        </a> */}
      </div>
    </div>
  );
};

export default SearchCard;
