import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllBrandProduct,
  getAllFlashProduct,
  getAllKreesaProduct,
  getAllOrganicProduct,
  getAllTrendingProduct,
  makeFeatureProduct,
  makeProductBrand,
  makeProductFlash,
  makeProductKreesa,
  makeProductOrganic,
  makeProductTrending,
  removeBrandProduct,
  removeFeature,
  removeFlashProduct,
  removeKreesaProduct,
  removeOrganicProduct,
  removeTrendingProduct,
  retailWholesaleFeatureProduct,
  updateFlashProduct,
} from "../../Action/FeatureProductAction";

const FeatureReducer = createSlice({
  name: "FeatureReducer",
  initialState: {
    addFeature: [],
    removeFeature: [],
    loader: false,
    errors: {},
    message: [],
    featureProduct: [],
    organicProduct: [],
    trendingProduct: [],
    kreesaProduct: [],
    brandProduct: [],
    flashProduct: [],
  },
  reducers: {},
  extraReducers: {
    /* to make feature product */
    [makeFeatureProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [makeFeatureProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.addFeature = action.payload;
      state.message = toast.success(action.payload);
    },
    [makeFeatureProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
      state.message = toast.error(action.payload);
    },
    [removeFeature.pending]: (state, action) => {
      state.loader = true;
    },
    [removeFeature.fulfilled]: (state, action) => {
      state.loader = false;
      state.removeFeature = action.payload;
      state.message = toast.success(action.payload);
    },
    [removeFeature.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
      state.message = toast.error(action.payload);
    },
    // /* to get all feature product */
    [retailWholesaleFeatureProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [retailWholesaleFeatureProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.featureProduct = action.payload;
    },
    [retailWholesaleFeatureProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    //organic product
    [getAllOrganicProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllOrganicProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.organicProduct = action.payload;
    },
    [getAllOrganicProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [makeProductOrganic.pending]: (state, action) => {
      state.loader = true;
    },
    [makeProductOrganic.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [makeProductOrganic.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [removeOrganicProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [removeOrganicProduct.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [removeOrganicProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    //trending product
    [getAllTrendingProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllTrendingProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.trendingProduct = action.payload;
    },
    [getAllTrendingProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [makeProductTrending.pending]: (state, action) => {
      state.loader = true;
    },
    [makeProductTrending.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [makeProductTrending.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [removeTrendingProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [removeTrendingProduct.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [removeTrendingProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    //kreesa product
    [getAllKreesaProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllKreesaProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.kreesaProduct = action.payload;
    },
    [getAllKreesaProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [makeProductKreesa.pending]: (state, action) => {
      state.loader = true;
    },
    [makeProductKreesa.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [makeProductKreesa.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [removeKreesaProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [removeKreesaProduct.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [removeKreesaProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    //trending product
    [getAllBrandProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllBrandProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.brandProduct = action.payload;
    },
    [getAllBrandProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [makeProductBrand.pending]: (state, action) => {
      state.loader = true;
    },
    [makeProductBrand.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [makeProductBrand.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [removeBrandProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [removeBrandProduct.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [removeBrandProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    //flash product
    [getAllFlashProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllFlashProduct.fulfilled]: (state, action) => {
      state.loader = false;
      state.flashProduct = action.payload;
    },
    [getAllFlashProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [makeProductFlash.pending]: (state, action) => {
      state.loader = true;
    },
    [makeProductFlash.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [makeProductFlash.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [removeFlashProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [removeFlashProduct.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [removeFlashProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [updateFlashProduct.pending]: (state, action) => {
      state.loader = true;
    },
    [updateFlashProduct.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [updateFlashProduct.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
// export const { filterEnquiries } = FeatureReducer.actions;
// export default
export default FeatureReducer.reducer;
