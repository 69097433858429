import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";

/* -------------------------------------------------------------------------- */
/*                           Delivery Actions start                          */
/* -------------------------------------------------------------------------- */

/* to get all Delivery */
export const getAllDelivery = createAsyncThunk(
  "getAllDelivery",
  async (userId) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/delivery/get-all-delivery/${userId}`
    );
    return data;
  }
);

/* to get all Delivery */
export const getAllQuotationDelivery = createAsyncThunk(
  "getAllQuotationnsDelivery",
  async (userId) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/delivery/get-all-assign-delivery-quotation-for-delivery-boy/${userId}`
    );
    return data;
  }
);

/* to get all Delivery */
export const changeDeliveryStatus = createAsyncThunk(
  "changeDeliveryStatusQuotation",
  async (status, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/delivery/change-delivery-status`,
      status
    )
      .then((res) => {
        toast.success("Status Changed Successfully");
        dispatch(getAllQuotationDelivery(localStorage.getItem("userId")));
      })
      .catch((err) => toast.error("An Error Occurred"));
    return data;
  }
);

/* delivery boy confirmation */
export const deliveryBoyConfirm = createAsyncThunk(
  "confirmDelivery_DeliveryBoy",
  async (order, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/delivery/confirm-delivery/${order.id}/${order.status}`
    )
      .then((res) => {
        order?.type === "order"
          ? toast.success("Status Changes Successfully")
          : toast.success(res.data);

        dispatch(getAllDelivery(order.userId));
        // order.history.go();
      })
      .catch((err) => toast.error("An Error Occurred"));
    return data;
  }
);

/* to get assigned single order */
export const getSingleDeliveryOrder = createAsyncThunk(
  "getSingleDeliveryOrder",
  async (orderId) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/delivery/view-single-assign-order/${orderId}`
    );
    return data;
  }
);
