import { useEffect, React, useState } from "react";
import "./Cart.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToBasket,
  decreaseCart,
  removeFromBasket,
  getTotals,
} from "../slices/basketSlice";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Header from "../Header";
import Nav from "../Nav";
import Footer from "../Footer";
import {
  removeCartItem,
  updateCartItem,
  viewAllCart,
} from "../Features/Action/WholesalerAction";
import { toast } from "react-toastify";
import Spinner from "../../Admin/Spinner";
import { useHistory } from "react-router-dom";

const Cart = () => {
  document.title = "Salers | Cart";
  const { items } = useSelector((state) => state.basketReducer);
  const { cartDetail, loader } = useSelector(
    (state) => state.WholesalerReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = localStorage.getItem("userId");

  const [bill, setBill] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [cart, setCart] = useState();
  const [customOrderData, setCustomOrderData] = useState({});
  const [checkAll, setCheckAll] = useState(false);

  const onItemSelect = (item, type) => {
    if (type === "all") {
      setSelectedItem((state) => ({
        ...state,
        [item.id]: !checkAll
          ? {
              cartId: item.id,
              colorId: item.colorId,
              orderQuantity: item.quantity,
              specificationId: item.product.specificationId,
              name: item.product.productName,
              price: item.product.productPrice,
              discountPrice:
                item.product.productPrice -
                (item.product.productPrice * item.product.productDiscount) /
                  100,
              discountedTotalPrice:
                (item.product.productPrice -
                  (item.product.productPrice * item.product.productDiscount) /
                    100) *
                item.quantity,
              discount: item.product.productDiscount,
            }
          : null,
      }));
    } else {
      setSelectedItem((state) => ({
        ...state,
        [item.id]: state[item.id]
          ? null
          : {
              cartId: item.id,
              colorId: item.colorId,
              orderQuantity: item.quantity,
              specificationId: item.product.specificationId,
              name: item.product.productName,
              price: item.product.productPrice,
              discountPrice:
                item.product.productPrice -
                (item.product.productPrice * item.product.productDiscount) /
                  100,
              discountedTotalPrice:
                (item.product.productPrice -
                  (item.product.productPrice * item.product.productDiscount) /
                    100) *
                item.quantity,
              discount: item.product.productDiscount,
            },
      }));
      Object.values(selectedItem).includes(null)
        ? setCheckAll(false)
        : setCheckAll(true);

      Object.values(selectedItem).filter(Boolean).length + 1 ===
      cartDetail?.length
        ? setCheckAll(true)
        : setCheckAll(false);
    }
  };

  let customOrder = localStorage.getItem("customOrder");
  useEffect(() => {
    if (history.location.pathname.includes("/custom-order")) {
      if (customOrder) {
        setCustomOrderData(JSON.parse(customOrder));
        dispatch(viewAllCart(JSON.parse(customOrder).userId));
      } else {
        history.push("/admin");
      }
    } else {
      dispatch(viewAllCart(userId));
    }
  }, []);

  useEffect(() => {
    //total and quantity
    if (cartDetail !== undefined) {
      setCart(cartDetail);

      //quantity
      //total
      let total = 0;
      cartDetail?.forEach((item) => {
        total =
          total +
          (item.product.productPrice -
            (item.product.productPrice * item.product.productDiscount) / 100) *
            item?.quantity;
      });
      setBill(total);
    }
  }, [cartDetail]);

  return (
    <div>
      <div style={{ position: "sticky", top: 0, zIndex: 99999 }}>
        <Header />
        <Nav />
      </div>
      {cartDetail?.length === 0 ? (
        <div>
          <div className="container-fluid mt-6">
            <div className="row">
              <aside className="col-lg-9 mt-6">
                <div className="card cdet">
                  <div className="table-responsive">
                    <table className="table table-borderless table-shopping-cart">
                      <thead className="text-muted">
                        <tr className="small text-uppercase">
                          <th scope="col">Product</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                          <th scope="col">Total Price</th>
                          <th
                            scope="col"
                            className="text-right d-none d-md-block"
                            width="200"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="d-flex "
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  <i
                    className="fas fa-cart-arrow-down"
                    style={{ fontSize: "112px" }}
                  ></i>
                  <p
                    className=" text-uppercase mt-4"
                    style={{ fontSize: "21px" }}
                  >
                    Your Kreesa Basket is empty
                  </p>
                </div>

                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <p className="ml-2 pt-0 mt-0" style={{ fontSize: "18px" }}>
                    Click Here for?{" "}
                  </p>
                  <Link
                    to={
                      history.location.pathname.includes("/custom-order")
                        ? "/custom-order"
                        : "/home"
                    }
                    className="ml-2 text-info mt-1"
                    data-abc="true"
                  >
                    Shopping
                  </Link>
                </div>
              </aside>

              <aside className="col-lg-3 total-card">
                <div className="card">
                  <div className="card-body">
                    <dl className="dlist-align">
                      <dt>Total Items:</dt>
                      <dd className="text-right ml-3"></dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Sub Total:</dt>
                      <dd className="text-right text-dark ml-3"></dd>
                    </dl>
                    <hr />{" "}
                    <a
                      href="#"
                      className="btn btn-out btn-primary btn-square btn-main disabled"
                      data-abc="true"
                    >
                      Proceed to checkout{" "}
                    </a>{" "}
                    <Link
                      to={
                        history.location.pathname.includes("/custom-order")
                          ? "/custom-order"
                          : "/home"
                      }
                      className="btn btn-out btn-success btn-square btn-main mt-2 disabled"
                      data-abc="true"
                    >
                      Continue Shopping
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!loader ? (
            <div>
              <p className="text-center h6 mt-5">
                {" "}
                Your Kreesa shopping Basket
              </p>
              <div className="container-fluid mt-6">
                <div className="row">
                  <aside className="col-lg-9">
                    <div className="card cdet">
                      <div className="table-responsive">
                        <table className="table table-borderless table-shopping-cart">
                          <thead className="text-muted">
                            <tr className="small text-uppercase">
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  checked={checkAll}
                                  onChange={() => {
                                    cartDetail.map((i) =>
                                      onItemSelect(i, "all")
                                    );
                                    setCheckAll(!checkAll);
                                  }}
                                />
                              </th>
                              <th scope="col" className="text-center">
                                Product
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                width="120"
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                width="120"
                              >
                                Price
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                width="120"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                className="text-center "
                                width="20"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart
                              ? cart?.map((cartItem) => (
                                  <tr key={cartItem.id}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        style={{
                                          width: 20,
                                          height: 20,
                                        }}
                                        checked={selectedItem[cartItem.id]}
                                        onChange={() =>
                                          onItemSelect(cartItem, "single")
                                        }
                                      />
                                    </td>
                                    <td className="itemside">
                                      <figure>
                                        <div className="aside">
                                          <img
                                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${cartItem?.product?.image?.id}/${cartItem?.product?.image?.imageName}`}
                                            className="img-sm"
                                          />
                                        </div>
                                      </figure>

                                      <figcaption
                                        className="ml-3"
                                        style={{ textAlign: "start" }}
                                      >
                                        <a
                                          href="#"
                                          className="title text-dark"
                                          data-abc="true"
                                        >
                                          <div style={{ fontSize: "16px" }}>
                                            {cartItem.product.productName
                                              .charAt(0)
                                              .toUpperCase() +
                                              cartItem.product.productName
                                                .slice(1)
                                                .substring(0, 20)}
                                          </div>
                                        </a>
                                        <p className="">
                                          {cartItem.colorName} <br />{" "}
                                          {cartItem.product.companyName}
                                        </p>
                                      </figcaption>
                                    </td>

                                    <td className="align-items-center">
                                      <div className="quantity-cart">
                                        <div
                                          className=" btn btn-light mr-2"
                                          onClick={() => {
                                            cartItem?.quantity != 1 &&
                                              dispatch(
                                                updateCartItem({
                                                  userId:
                                                    localStorage.getItem(
                                                      "userId"
                                                    ),
                                                  id: cartItem.id,
                                                  quantity:
                                                    cartItem?.quantity - 1,
                                                })
                                              );
                                          }}
                                        >
                                          <i
                                            className="fas fa-minus-circle "
                                            title="decrease items"
                                            // onClick={() =>
                                            //   addItemToBasket(cartItem)
                                            // }
                                          />
                                        </div>
                                        <p className="showitem">
                                          {cartItem?.quantity}
                                        </p>
                                        <div
                                          className=" btn btn-light ml-2"
                                          onClick={() => {
                                            dispatch(
                                              updateCartItem({
                                                userId:
                                                  history.location.pathname.includes(
                                                    "/custom-order"
                                                  )
                                                    ? customOrderData.userId
                                                    : localStorage.getItem(
                                                        "userId"
                                                      ),
                                                id: cartItem.id,
                                                quantity:
                                                  cartItem?.quantity + 1,
                                              })
                                            );
                                          }}
                                        >
                                          <i
                                            className="fas fa-plus-circle"
                                            title="Increase items"
                                          />
                                        </div>
                                      </div>
                                    </td>

                                    <td className="text-center">
                                      <div className="">
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Rs{" "}
                                          {Math.round(
                                            cartItem?.product?.productPrice -
                                              (cartItem?.product?.productPrice *
                                                cartItem?.product
                                                  ?.productDiscount) /
                                                100
                                          )}
                                        </div>
                                        {/* <small className="text-muted"> </small>{" "} */}
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Rs{" "}
                                        {Math.round(
                                          (cartItem?.product?.productPrice -
                                            (cartItem?.product?.productPrice *
                                              cartItem?.product
                                                ?.productDiscount) /
                                              100) *
                                            cartItem?.quantity
                                        )}
                                      </div>
                                    </td>

                                    <td className="text-center ">
                                      <div
                                        className=" btn btn-light"
                                        onClick={() => {
                                          dispatch(
                                            removeCartItem({
                                              cartId: cartItem.id,
                                              userId:
                                                history.location.pathname.includes(
                                                  "/custom-order"
                                                )
                                                  ? customOrderData.userId
                                                  : localStorage.getItem(
                                                      "userId"
                                                    ),
                                              productName:
                                                cartItem.product.productName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                cartItem.product.productName
                                                  .slice(1)
                                                  .substring(0, 20),
                                            })
                                          );
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash-alt "
                                          title="remove items"
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </aside>

                  {cartDetail?.length > 0 && (
                    <aside className="col-lg-3 total-card">
                      <div className="card">
                        <div className="card-body">
                          <dl className="dlist-align">
                            <dt>Total Items:</dt>
                            <dd className="text-right ml-3 font-weight-bold">
                              {
                                Object.values(selectedItem).filter(Boolean)
                                  .length
                              }
                            </dd>
                          </dl>
                          <dl className="dlist-align">
                            <dt>Sub Total:</dt>
                            <dd
                              className="text-right text-dark ml-3 font-weight-bold"
                              style={{ fontSize: "14px" }}
                            >
                              Rs{" "}
                              {!Object.values(selectedItem).filter(Boolean)
                                .length > 0
                                ? 0
                                : Math.round(
                                    Object.values(selectedItem)
                                      .filter(Boolean)
                                      ?.map((i) => i?.discountedTotalPrice)
                                      .reduce((a, b) => a + b)
                                  )}
                            </dd>
                          </dl>
                          {/* <dl className="dlist-align">
                        <dt>Sub Total:</dt>
                        <dd className="text-right text-dark b ml-3">
                          <strong>${basket?.cartTotalAmount}</strong>
                        </dd>
                      </dl> */}
                          <hr />{" "}
                          <Link
                            to={{
                              pathname:
                                Object.values(selectedItem).filter(Boolean)
                                  .length > 0
                                  ? history.location.pathname.includes(
                                      "/custom-order"
                                    )
                                    ? "/custom-order/checkout"
                                    : "/checkout-seller"
                                  : "",
                              state:
                                Object.values(selectedItem).filter(Boolean),
                            }}
                            onClick={() =>
                              !Object.values(selectedItem).filter(Boolean)
                                .length > 0 &&
                              toast.info("Please select items first")
                            }
                            className="btn btn-out btn-primary btn-square btn-main"
                            data-abc="true"
                          >
                            Proceed to checkout{" "}
                          </Link>{" "}
                          <Link
                            to={
                              history.location.pathname.includes(
                                "/custom-order"
                              )
                                ? "/custom-order"
                                : "/home"
                            }
                            className="btn btn-out btn-success btn-square btn-main mt-2"
                            data-abc="true"
                          >
                            Continue Shopping
                          </Link>
                        </div>
                      </div>
                    </aside>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "20px" }}>
              <Spinner />
            </div>
          )}
        </>
      )}
      <Footer />
    </div>
  );
};

export default Cart;
