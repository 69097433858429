import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RatingView } from "react-simple-star-rating";
import { toast } from "react-toastify";
import {
  getallUserReview,
  ReviewApprove,
} from "../Features/Action/ProductAction";
import Spinner from "../Spinner";
import Pagination from "../../Pagination";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Star from "../../User/Star";

const UserTab = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  const [modalId, setModalId] = useState("");
  const handleClose = () => setModalId("");

  const dispatch = useDispatch();
  const { loader, userReview } = useSelector((s) => s.ProductWholesalerReducer);

  // useEffect(() => {
  //   dispatch(getallUserReview());
  // }, [userReview]);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = userReview?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(userReview?.length / perPage);

  return !loader ? (
    <div className="row">
      {currentEntries?.length > 0 ? (
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">All User Review</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Company Name</th>
                  <th className="d-xl-table-cell">Product Name</th>
                  <th className="d-xl-table-cell">Status</th>
                  <th className="d-xl-table-cell">Rating ⭐</th>
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map((c, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {c.firstName} {c.lastName}
                    </td>
                    <td>{c.productName}</td>
                    <td>
                      {c.reviewApproval === false ? (
                        <span className="badge bg-danger">
                          Not Approved Yet
                        </span>
                      ) : (
                        <span className="badge bg-success">Approved</span>
                      )}
                    </td>
                    <td>
                      {/* <RatingView ratingValue={c.totalStar} size={30} /> */}
                      <Star value={c.totalStar} />
                    </td>
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#3b7ddd",
                          }}
                          className="btn-md btn btn-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <div className="dropdown-menu text-center">
                          {/* <Link
                            to={`/admin/edit-faq/${c.reviewId}`}
                            className="dropdown-item text-info"
                          >
                            <i className="fas fa-user-edit"></i> Edit
                          </Link>
                          <div className="dropdown-divider" /> */}
                          <button
                            onClick={() => setModalId(`modal${i}`)}
                            className="dropdown-item text-success"
                          >
                            <i className="fa fa-eye"></i> Quick View
                          </button>
                          {c.reviewApproval === false && (
                            <>
                              <div className="dropdown-divider" />
                              <p
                                onClick={async () => {
                                  await dispatch(ReviewApprove(c.reviewId));
                                  dispatch(getallUserReview());
                                  toast.success("Review has been approved");
                                }}
                                className="dropdown-item text-info cursor-pointer"
                              >
                                <i className="fa fa-check"></i> Approve
                              </p>
                            </>
                          )}
                        </div>
                        <Modal
                          show={modalId === `modal${i}`}
                          onHide={handleClose}
                          // size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          // centered
                        >
                          <Modal.Header>
                            <Modal.Title>
                              {c?.productName + "'s Review"} by {c.firstName}{" "}
                              {c.lastName}
                              <Star value={c.totalStar} />
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>{c?.review}</Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h3>No User Reviews Found !</h3>
        </div>
      )}
      {/* pagination  */}
      {currentEntries?.length > 0 && (
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={userReview}
        />
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default UserTab;
