import "../BestSellers/BestSellers.css";
import QuickViewButton from "../../../Widgets/OuickViewButton/QuickViewButton";
import RatingStar from "../../../Widgets/RatingStar/RatingStar";
import { Link, useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { addToBasket } from "../Features/slices/basketSlice";
import { useEffect } from "react";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import {
  getSingleRetailProducts,
  getRetailerProducts,
  featureProductForUser,
} from "../Features/action";
import "./grid.css";
import { Excerpt } from "../../../utils";
import HtmlParser from "react-html-parser";
import { RatingView } from "react-simple-star-rating";
import Star from "../Star";
import Card from "./CardSlider";

function BestSellers(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  //
  const { userpageProducts, userFeature } = useSelector(
    (state) => state.userProducts
  );
  // featured products

  const addInBasket = (product) => {
    dispatch(addToBasket(product));
  };

  useEffect(() => {
    dispatch(getRetailerProducts("retail"));
    dispatch(featureProductForUser());
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
    },

    desktop: {
      breakpoint: { max: 3000, min: 1570 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1570, min: 768 },
      items: 4,
    },
    tab_min: {
      breakpoint: { max: 768, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div
        className="container-fluid bestsellers-main px-lg-6"
        style={{ paddingBottom: "0rem" }}
      >
        {userFeature?.length > 0 && (
          <h2 className="text-center text-uppercase h2 pt-6 font-weight-bold">
            Feature Products
          </h2>
        )}
        {userFeature?.length > 0 && (
          <Carousel responsive={responsive} className="mt-4 rec">
            {userFeature?.map((product) => (
              <div className="card-group ml-2" key={product.id}>
                <div className="card">
                  {props?.customdata?.userId ? (
                    <Link
                      to={{
                        pathname: `/single-product/${product?.id}`,
                        key: product?.id,
                        id: product?.id,
                        state: { prevPath: history.location.pathname },
                        slectImage:
                          AxiosConfig.defaults.baseURL +
                          "/kreesa/image/product/" +
                          product?.image?.id +
                          "/" +
                          product?.image?.image,
                      }}
                    >
                      <div style={{ height: "235px" }}>
                        <img
                          className="card-img-top rounded"
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                          alt={product?.productName}
                          style={{ height: "235px", width: "100%" }}
                          loading="lazy"
                        />
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: `/single-product/${product?.id}`,
                        key: product?.id,
                        id: product?.id,
                        slectImage:
                          AxiosConfig.defaults.baseURL +
                          "/kreesa/image/product/" +
                          product?.image?.id +
                          "/" +
                          product?.image?.image,
                      }}
                    >
                      <div style={{ height: "235px" }}>
                        <img
                          className="card-img-top rounded"
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                          alt={product?.productName}
                          style={{ height: "235px", width: "100%" }}
                          loading="lazy"
                        />
                      </div>
                    </Link>
                  )}
                  <div className="card-body">
                    <div
                      style={{ margin: 0, padding: "0 10px" }}
                      onClick={() =>
                        dispatch(getSingleRetailProducts(product?.id))
                      }
                    >
                      <QuickViewButton />
                    </div>
                    <Link
                      to={{
                        pathname: `/single-product/${product?.id}`,
                        state: {
                          prevPath: history.location.pathname,
                        },
                      }}
                    >
                      <h4 className="mt-1 text-capitalize text-center">
                        {product?.productName}
                      </h4>

                      <RatingStar />
                      {product.specification?.productDiscount === 0 ? (
                        <p className="text-success mt-1 ">
                          <b>
                            Rs.{" "}
                            {Math.round(product.specification?.productPrice)}
                          </b>
                        </p>
                      ) : (
                        <p className="mt-1 text-center font-weight-bold">
                          <strike>
                            Rs.{" "}
                            {Math.round(product.specification?.productPrice)}
                          </strike>
                          <br />
                          <b className="text-success">
                            {" "}
                            Rs.{" "}
                            {Math.round(
                              product.specification?.productPrice -
                                (product.specification?.productPrice *
                                  product.specification?.productDiscount) /
                                  100
                            )}
                          </b>
                          <strong className="text-danger font-weight-bold">
                            {" "}
                            - {product.specification?.productDiscount} % OFF
                          </strong>
                        </p>
                      )}
                    </Link>
                    {/* <button
                    className="btn btn-primary border-0 fbtn mt-3"
                    onClick={() => addInBasket(product)}
                  >
                    {" "}
                    ADD TO CART
                  </button> */}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        )}
        {/*  ----------------------------- Recent Product -----------------------------  */}
        {userpageProducts?.length > 0 && (
          <>
            <h2 className="text-center text-uppercase pt-6 h2 font-weight-bold">
              Recent Products
            </h2>
            {/* <div className="container-fluid mt-4">
              <div className="row">
                {userpageProducts?.map((product) => (
                  <div className="col-sm-6 col-md-3">
                    <article className="product__cards__wrapper">
                      {props?.customdata?.userId ? (
                        <Link
                          to={{
                            pathname: `/single-product/${product?.id}`,
                            key: product?.id,
                            id: product?.id,
                            state: { prevPath: history.location.pathname },
                            slectImage:
                              AxiosConfig.defaults.baseURL +
                              "/kreesa/image/product/" +
                              product?.image?.id +
                              "/" +
                              product?.image?.image,
                          }}
                          className="product__images__holder cursor-pointer"
                        >
                          <p className="product__images__holder__link"></p>
                          <div
                            className="image-liquid product__images__holder--original"
                            style={{
                              backgroundImage: `url(${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName})`,
                            }}
                          ></div>
                        </Link>
                      ) : (
                        <Link
                          to={{
                            pathname: `/single-product/${product?.id}`,
                            key: product?.id,
                            id: product?.id,
                            slectImage:
                              AxiosConfig.defaults.baseURL +
                              "/kreesa/image/product/" +
                              product?.image?.id +
                              "/" +
                              product?.image?.image,
                          }}
                          className="product__images__holder cursor-pointer"
                        >
                          <p className="product__images__holder__link"></p>
                          <div
                            className="image-liquid product__images__holder--original"
                            style={{
                              backgroundImage: `url(${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName})`,
                            }}
                          ></div>
                        </Link>
                      )}

                      <div className="products__description">
                        <h1 className="products__description__title">
                          <p className="text-capitalize">
                            {product?.productName}
                          </p>
                        </h1>

                        <div className="row">
                          <div className="col-xs-12 col-sm-8 products__description__category product__secondary__text">
                            <p>
                              {HtmlParser(
                                Excerpt(product?.productHighlight, 60)
                              )}
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-4 products__description__price">
                            {product?.specification?.productDiscount === 0 ? (
                              <p className="text-success mt-1">
                                <b>
                                  Rs.{" "}
                                  {Math.round(
                                    product?.specification?.productPrice
                                  )}
                                </b>
                              </p>
                            ) : (
                              <p className="text-center font-weight-bold">
                                <strike
                                  style={{
                                    fontSize: 16,
                                  }}
                                >
                                  Rs.{" "}
                                  {Math.round(
                                    product?.specification?.productPrice
                                  )}
                                </strike>
                                <br />
                                <b className="text-success">
                                  {" "}
                                  Rs.{" "}
                                  {Math.round(
                                    product?.specification?.productPrice -
                                      (product?.specification?.productPrice *
                                        product?.specification
                                          ?.productDiscount) /
                                        100
                                  )}
                                </b>
                                <br />
                                <strong className="text-danger font-weight-bold">
                                  {" "}
                                  - {product?.specification?.productDiscount} %
                                  OFF
                                </strong>
                              </p>
                            )}
                          </div>
                            <hr className="product__horizontal_line" /> 
                          <div
                            // style={{ margin: 0, padding: "0 10px" }}
                            onClick={() =>
                              dispatch(getSingleRetailProducts(product?.id))
                            }
                          >
                            <QuickViewButton />
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                ))}
              </div>
            </div> */}
            {/* <div className=""> */}
            {userpageProducts?.length > 0 && (
              <Card title="Recent Products" data={userpageProducts} />
            )}
            <div className="row mt-5">
              {userpageProducts?.map((product) => (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                  <div className="card h-100 product__effect_">
                    <div className="d-flex justify-content-between position-absolute w-100">
                      <div className="label-new">
                        <span className="text-white bg-success small d-flex align-items-center px-2 py-1">
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <span className="ml-1">New</span>
                        </span>
                      </div>
                      <div className="label-sale">
                        <span className="text-white bg-primary small d-flex align-items-center px-2 py-1">
                          <i className="fa fa-tag" aria-hidden="true"></i>
                          <span className="ml-1">Sale</span>
                        </span>
                      </div>
                    </div>
                    {props?.customdata?.userId ? (
                      <Link
                        to={{
                          pathname: `/single-product/${product?.id}`,
                          key: product?.id,
                          id: product?.id,
                          state: { prevPath: history.location.pathname },
                          slectImage:
                            AxiosConfig.defaults.baseURL +
                            "/kreesa/image/product/" +
                            product?.image?.id +
                            "/" +
                            product?.image?.image,
                        }}
                      >
                        <img
                          // src="https://picsum.photos/700/550"
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                          className="card-img-top img-fluid"
                          style={{
                            height: 235,
                            objectFit: "contain",
                          }}
                          loading="lazy"
                          alt={product?.image?.imageName}
                        />
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: `/single-product/${product?.id}`,
                          key: product?.id,
                          id: product?.id,
                          slectImage:
                            AxiosConfig.defaults.baseURL +
                            "/kreesa/image/product/" +
                            product?.image?.id +
                            "/" +
                            product?.image?.image,
                        }}
                      >
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                          className="card-img-top img-fluid"
                          style={{
                            height: 235,
                            objectFit: "contain",
                          }}
                          loading="lazy"
                          alt={product?.image?.imageName}
                        />
                      </Link>
                    )}
                    <div className="card-body px-2 pb-2 pt-1">
                      <div className="d-flex justify-content-between">
                        <div>
                          {product?.specification?.productDiscount === 0 ? (
                            <p className="h4 text-primary">
                              {" "}
                              Rs.{" "}
                              {Math.round(product?.specification?.productPrice)}
                            </p>
                          ) : (
                            <p className="text-center font-weight-bold">
                              <strike
                                style={{
                                  fontSize: 16,
                                }}
                              >
                                Rs.{" "}
                                {Math.round(
                                  product?.specification?.productPrice
                                )}
                              </strike>
                              <b className="h4 text-success font-weight-bold">
                                {" "}
                                Rs.{" "}
                                {Math.round(
                                  product?.specification?.productPrice -
                                    (product?.specification?.productPrice *
                                      product?.specification?.productDiscount) /
                                      100
                                )}
                              </b>
                              <strong className="h5 text-danger font-weight-bold">
                                {" "}
                                - {product?.specification?.productDiscount} %
                                OFF
                              </strong>
                            </p>
                          )}
                        </div>
                        {/* <div>
                          <a
                            href="#"
                            className="text-secondary lead"
                            data-toggle="tooltip"
                            data-placement="left"
                            title="Compare"
                          >
                            <i
                              className="fa fa-line-chart"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div> */}
                      </div>
                      {/* <p className="text-warning d-flex align-items-center mb-2"> */}
                      {/* <p class="text-warning d-flex align-items-center mb-2">
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </p> */}
                      <Star value={5} />
                      <Link
                        to={{
                          pathname: `/single-product/${product?.id}`,
                          state: {
                            prevPath: history.location.pathname,
                          },
                        }}
                        className="mb-0"
                      >
                        <strong className="font-weight-bold h4 text-capitalize text-secondary">
                          {/* <a href="#" className="text-secondary"> */}
                          {product?.productName}
                          {/* </a> */}
                        </strong>
                      </Link>
                      {/* <p className="mb-1">
                        <small>
                          <a href="#" className="text-secondary">
                            Brands
                          </a>
                        </small>
                      </p> */}
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          {/* <p className="mb-0 small">
                            <b>UPC: </b> 2310010
                          </p>
                          <p className="mb-0 small">
                            <b>PART#: </b> 2121
                          </p>
                          <p className="mb-0 small">
                            <b>MPN#: </b> mpn22651
                          </p> */}
                        </div>
                        <div className="text-right">
                          {/* <p className="mb-0 small">
                            <b>Free Shipping</b>
                          </p>
                          <p className="mb-0 small">
                            <b>MSRP: </b> $119.99
                          </p>
                          <p className="mb-0 small">
                            <b>REG: </b> $19.99
                          </p> */}
                          {product?.specification?.productDiscount === 0 ? (
                            ""
                          ) : (
                            <p className="mb-0 large text-primary">
                              <span className="font-weight-bold">Save</span> Rs.{" "}
                              {Math.round(
                                Math.abs(
                                  product?.specification?.productPrice -
                                    product?.specification?.productPrice -
                                    (product?.specification?.productPrice *
                                      product?.specification?.productDiscount) /
                                      100
                                )
                              )}{" "}
                              ({product?.specification?.productDiscount}%)
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className=" "
                        onClick={() =>
                          dispatch(getSingleRetailProducts(product?.id))
                        }
                      >
                        <QuickViewButton />
                        {/* <div className="col px-0">
                          <button className="btn btn-outline-primary btn-block">
                            Add To Cart
                            <i
                              className="fa fa-shopping-basket"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                        <div className="ml-2">
                          <a
                            href="#"
                            className="btn btn-outline-success"
                            data-toggle="tooltip"
                            data-placement="left"
                            title="Add to Wishlist"
                          >
                            <i className="fa fa-heart" aria-hidden="true"></i>
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* </div> */}
          </>
        )}
      </div>
    </>
  );
}

export default BestSellers;
