import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { getCustomOrderByUserId } from "../../../../../Common/Features/Action/CustomOrderAction";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to get all CustomOrder */
export const getCustomOrder = createAsyncThunk("CustomOrder", async () => {
  const { data } = await AxiosConfig.get(
    "/kreesa/admin/custom-order/view-all-custom-order",
    config
  );
  return data;
});

/* to approve custom order */
export const approveCustomOrder = createAsyncThunk(
  "approveCustomOrder",
  async (id, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/custom-order/approve-custom-order/${id}`,
      config
    );
    dispatch(getCustomOrder());
    return data;
  }
);

/* to confirm custom order placement */
export const confirmCustomOrderPlacement = createAsyncThunk(
  "confirmCustomOrderPlacement",
  async (orderData, { dispatch }) => {
    try {
      const { data } = await AxiosConfig.get(
        `/kreesa/admin/custom-order/confirm-order-placement/${orderData.id}`,
        config
      );
      orderData.toast.success("Custom Order Placed");
      orderData.history.go();
      return data;
    } catch (err) {
      orderData.toast.error("An error occurred");
      return;
    }

    // dispatch(getCustomOrder());
  }
);

/* to reject custom Order by admin*/
export const rejectCustomOrder = createAsyncThunk(
  "rejectCustomOrder",
  async (id, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/custom-order/reject-custom-order/${id}`,
      config
    );
    dispatch(getCustomOrder());
    return data;
  }
);

/* to reject custom Order by seller*/
export const cancelCustomOrder = createAsyncThunk(
  "cancelCustomOrder",
  async (id, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/custom-order/reject-custom-order/${id}`,
      config
    );
    dispatch(getCustomOrderByUserId());
    return data;
  }
);
