import React from "react";

const Star = (props) => {
  const value = Math.round(props.value);
  return (
    <p class="text-warning d-flex align-items-center mb-2">
      {/* {props.value === 1 && <i class="fa fa-star" aria-hidden="true"></i>}
      {props.value === 2 && <i class="fa fa-star" aria-hidden="true"></i>}
      {props.value === 3 && <i class="fa fa-star" aria-hidden="true"></i>}
      {props.value === 4 && <i class="fa fa-star" aria-hidden="true"></i>}
      {props.value === 5 && <i class="fa fa-star" aria-hidden="true"></i>} */}
      {value === 1 ? (
        <i class="fa fa-star" aria-hidden="true"></i>
      ) : value === 2 ? (
        <>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
        </>
      ) : value === 3 ? (
        <>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
        </>
      ) : value === 4 ? (
        <>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
        </>
      ) : value === 5 ? (
        <>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
        </>
      ) : (
        <p
          style={{
            color: "salmon",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          No Rating Yet !
        </p>
      )}
    </p>
  );
};

export default Star;
