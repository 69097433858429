import React, { useEffect, useState } from "react";
import useButtonLoader from "../../ButtonLoaderHook";
import $ from "jquery";
import "./style.css";
import AdminCategoryValidation from "../../Validation/AdminCategoryValidation";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";

function PushNotificationDetail() {
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [state, setstate] = useState([]);
  const [type, setType] = useState("");
  const [sendTo, setSendto] = useState("");
  const [userId, setUserId] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader("Send", "Loading...");
  //   push notification
  const sendPushNotification = async (e) => {
    let res;
    e.preventDefault();
    setButtonLoader(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      res = await AxiosConfig.post(
        "/kreesa/admin/notification/send-push-notification",
        {
          message,
          type,
          sendTo,
          userId,
        },
        config
      );
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
      toast.error("please select all fields");
    }

    if (res && res.status === 200) {
      setButtonLoader(false);
      setMessage("");
      setUserId("");
      setType("");
      setSendto("");
      toast.success("Notification Sent Successfully");
      history.push("/admin");
    }
  };

  //   fetchUsers
  const fetchUsers = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      if (sendTo === "wholesaler") {
        const getAllWholesaler = await AxiosConfig.get(
          "/kreesa/admin/get-all-wholesaler",
          config
        );
        setstate(getAllWholesaler.data);
      } else if (sendTo === "retailer") {
        const getAllRetailer = await AxiosConfig.get(
          "/kreesa/admin/get-all-retailer",
          config
        );
        setstate(getAllRetailer.data);
      } else {
        const getAllCustomer = await AxiosConfig.get(
          "/kreesa/admin/get-all-customer",
          config
        );
        setstate(getAllCustomer.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, [sendTo]);
  //
  $(document).ready(function () {
    $(".trigger").click(function () {
      $("#notic").hide();
      $("." + $(this).data("rel")).show();
    });

    $(".trigger-single").click(function () {
      $(".single-content").hide();
      $("." + $(this).data("rel")).show();
    });
    $("#exampleRadios3").click(function () {
      $(".single-content").hide();
    });
  });
  AdminCategoryValidation();
  return (
    <div>
      <div className="col-md-12">
        <div className="card">
          <div
            className="card-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <h5 className="card-title font-weight-bold">Notification Form</h5>
              <h6 className="card-subtitle text-muted">
                You Can Easily send Notification.
              </h6>
            </div>
            <div>
              <Link
                to="/admin/notifications"
                className="btn btn-primary btn-block"
              >
                View all Notifications
              </Link>
            </div>
          </div>
          <div className="card-body">
            <form id="acategoryadd-forms" onSubmit={sendPushNotification}>
              <div className="col">
                <div className="col">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Message</span>
                    </label>

                    <textarea
                      className="form-control"
                      onChange={(e) => setMessage(e.target.value)}
                      name="message"
                      style={{ resize: "none" }}
                      rows="6"
                    ></textarea>
                  </div>
                </div>
                <div className="mb-3 mt-2 col-md-6">
                  <label className="form-label" htmlFor="inputCity">
                    <span className="font-weight-bold">Send To</span>{" "}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    checked={sendTo === "wholesaler"}
                    value="wholesaler"
                    onChange={(e) => {
                      setSendto(e.target.value);
                    }}
                    id="wholesaler"
                    className="form-check-input ml-2 trigger"
                    type="radio"
                    name="sendTo"
                    data-rel="sim-micro-desktop"
                  />
                  <label className="form-check-label pl-2" htmlFor="wholesaler">
                    {" "}
                    WholeSaler
                  </label>
                  <input
                    checked={sendTo === "retailer"}
                    value="retailer"
                    onChange={(e) => {
                      setSendto(e.target.value);
                    }}
                    className="form-check-input ml-2 trigger"
                    type="radio"
                    name="sendTo"
                    id="retailer"
                    data-rel="sim-micro-desktop"
                  />

                  <label className="form-check-label pl-2" htmlFor="retailer">
                    Retailer
                  </label>
                  <input
                    checked={sendTo === "user"}
                    value="user"
                    onChange={(e) => {
                      setSendto(e.target.value);
                    }}
                    className="form-check-input ml-2 trigger"
                    type="radio"
                    name="sendTo"
                    id="user"
                    data-rel="sim-micro-desktop"
                  />
                  <label className="form-check-label pl-2" htmlFor="user">
                    User
                  </label>
                </div>
              </div>
              <div className="mb-3 mt-2 col-md-4 sim-micro-desktop" id="notic">
                <label className="form-label" htmlFor="inputCity">
                  <span className="font-weight-bold">Send To</span>{" "}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  checked={type === "all"}
                  value="all"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  className="form-check-input ml-2"
                  type="radio"
                  name="type"
                  id="exampleRadios3"
                />
                <label
                  className="form-check-label pl-2"
                  htmlFor="exampleRadios1"
                >
                  {" "}
                  All
                </label>
                <input
                  checked={type === "single"}
                  value="single"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  className="form-check-input ml-2 trigger-single"
                  name="type"
                  type="radio"
                  id="exampleRadios4"
                  data-rel="single-sim-micro-desktop"
                />
                <label
                  className="form-check-label pl-2"
                  htmlFor="exampleRadios2"
                >
                  Single
                </label>
              </div>
              {/* single start  */}
              <div className="mb-3 col-md-4 single-sim-micro-desktop single-content">
                <label
                  className="form-label font-weight-bold"
                  htmlFor="inputState"
                >
                  Send To <span className="text-danger"> *</span>
                </label>
                {/* <select
                  id="inputState"
                  name="role"
                  onChange={(e) => setUserId(e.target.value)}
                  className="form-control"
                >
                  <option value="">Select Single User First...</option>
                  {state?.map((item, i) => (
                    <option value={item.userId} key={i}>
                      {sendTo === "user"
                        ? item.firstname + " " + item.lastname
                        : item.companyName}
                    </option>
                  ))}
                </select> */}
                <Select
                  options={state?.map((user) => ({
                    value: user.userId,
                    label:
                      sendTo === "user"
                        ? user.firstname + " " + user.lastname
                        : user.companyName,
                  }))}
                  name="role"
                  onChange={(e) => setUserId(e.value)}
                />
              </div>
              {/* single end  */}
              <div className="mb-3 mt-4 col-md-4">
                <button
                  id=""
                  type="submit"
                  className="btn btn-success btn-lg btn-block"
                  ref={buttonLoader}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PushNotificationDetail;
