import { createSlice } from '@reduxjs/toolkit'
import { getSecondaryCategory,editSecondaryCategory } from '../../Action/CategoryAction/CategoryAction';

const SecondaryCategoryReducer = createSlice({
    name: 'secondaryCategories',
    initialState: {
        secondaryCategories: [],
        secondaryContainer: [],
        loader: false,
        errors: {},
        editSecondaryCategories: [],
    },
    reducers: {
        filterSecondaryCategory: (state, action) => {
            state.secondaryCategories = state.secondaryContainer.filter((u) =>
                u.secondaryCategoryName.toLowerCase().includes(action.payload)||
                u.secondaryCategoryName.toUpperCase().includes(action.payload)
            );
        }
    },
    extraReducers: {
        /* to get all Secondary view Categories */
        [getSecondaryCategory.pending]: (state, action) => {
            state.loader = true;
        },
        [getSecondaryCategory.fulfilled]: (state, action) => {
            state.loader = false;
            state.secondaryCategories = action.payload;
            state.secondaryContainer = action.payload;
        },
        [getSecondaryCategory.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload
        },

         /* edit Secondary Categories */
         [editSecondaryCategory.pending]: (state, action) => {
            state.loader = true;
        },
        [editSecondaryCategory.fulfilled]: (state, action) => {
            state.loader = false;
            state.editSecondaryCategories = action.payload;
        },
        [editSecondaryCategory.rejected]: (state, action) => {
            state.loader = false;
            state.errors = action.payload
        },
    }
});
// export
export const { filterSecondaryCategory } = SecondaryCategoryReducer.actions;
// export default
export default SecondaryCategoryReducer.reducer;