import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllWishlists, removeFromWishlist } from "../Features/action";
import Spinner from "../../Admin/Spinner";
import "./wishlist.css";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Pagination from "../../Pagination";
import { Link } from "react-router-dom";
import Search from "../Search/Search";
import Navbar from "../NavBar/Navbar";
import UserFooter from "../UserFooter";

const WishList = () => {
  document.title = "Kreesa | Wishlist";
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const { wishlists, loader } = useSelector((state) => state.userProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWishlists(localStorage.getItem("userId")));
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = wishlists?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(wishlists?.length / perPage);

  return (
    <>
      <Search />
      <Navbar />
      {!loader ? (
        <div className="cart-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="main-heading mb-10 mt-2">My wishlist</h3>
                {currentEntries?.length > 0 ? (
                  <div className="table-wishlist">
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th width="45%">Product Name</th>
                          <th width="15%">Unit Price</th>
                          <th width="15%">Stock Status</th>
                          <th width="15%"></th>
                          <th width="15%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentEntries?.map((wishlist, index) => (
                          <tr key={index}>
                            <td width="45%">
                              <Link
                                to={`/single-product/${wishlist.productId}`}
                              >
                                <div className="display-flex align-center">
                                  <div className="img-product">
                                    <img
                                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${wishlist?.image?.id}/${wishlist?.image?.imageName}`}
                                      alt=""
                                      className="mCS_img_loaded"
                                    />
                                  </div>
                                  <div
                                    className="name-product"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {wishlist?.productName}
                                  </div>
                                </div>
                              </Link>
                            </td>
                            <td width="15%" className="price">
                              Rs. {wishlist?.specification?.productPrice}
                            </td>
                            <td width="15%">
                              <span
                                className={` ${
                                  wishlist?.specification.stock === null
                                    ? "in-stock_red"
                                    : "in-stock-box_green"
                                } `}
                              >
                                {wishlist?.specification?.stock === null
                                  ? "Out of Stock"
                                  : "In Stock"}
                              </span>
                            </td>
                            <td width="15%">
                              <Link
                                className="btn btn-info"
                                to={`/single-product/${wishlist.productId}`}
                              >
                                <i className="fa fa-eye"></i> More
                              </Link>
                            </td>
                            <td
                              width="10%"
                              onClick={async () => {
                                await dispatch(
                                  removeFromWishlist({
                                    id: wishlist?.id,
                                    userId: +localStorage.getItem("userId"),
                                  })
                                );
                                dispatch(
                                  getAllWishlists(
                                    localStorage.getItem("userId")
                                  )
                                );
                              }}
                              className="text-center"
                            >
                              <p
                                className="trash-icon cursor-pointer"
                                style={{ color: "blue" }}
                              >
                                <i className="far fa-trash-alt"></i>
                              </p>
                            </td>
                          </tr>
                        ))}

                        {/* <tr>
                        <td width="45%">
                          <div className="display-flex align-center">
                            <div className="img-product">
                              <img
                                src="https://www.91-img.com/pictures/laptops/asus/asus-x552cl-sx019d-core-i3-3rd-gen-4-gb-500-gb-dos-1-gb-61721-large-1.jpg"
                                alt=""
                                className="mCS_img_loaded"
                              />
                            </div>
                            <div className="name-product">Apple iPad Mini</div>
                          </div>
                        </td>
                        <td width="15%" className="price">
                          $110.00
                        </td>
                        <td width="15%">
                          <span className="in-stock-box">In Stock</span>
                        </td>
                        <td width="15%">
                          <button className="round-black-btn small-btn">
                            Add to Cart
                          </button>
                        </td>
                        <td width="10%" className="text-center">
                          <a href="#" className="trash-icon">
                            <i className="far fa-trash-alt"></i>
                          </a>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                    {/* pagination  */}
                    <Pagination
                      pages={totalPagesNum}
                      setCurrentPage={setCurrentPage}
                      currentEntries={currentEntries}
                      allEntries={wishlists}
                    />
                  </div>
                ) : (
                  <div className="text-center">
                    <strong>No items in wishlist</strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      )}
      <UserFooter />
    </>
  );
};

export default WishList;
