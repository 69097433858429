import React from 'react'
import Footer from '../Footer'
import TopNavbar from '../TopNavbar'
import LeftNavbar from '../LeftNavbar/'
import ProfileSetting from './ProfileSetting'
import { Redirect } from 'react-router-dom'

const Settings = () => {

    document.title = "Admin | settings & Privacy"
    // to check authentication user or not
    let token = localStorage.getItem('token', 'username', 'auth');
    if (token === null) {
        return <Redirect exact to="/admin/login" />
    }
    return (
        <>
            <div className="wrapper">
                <LeftNavbar />
                <div className="main">
                    <TopNavbar />
                    <main className="content">
                        <ProfileSetting />
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Settings
