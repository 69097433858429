import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import UserOrderValidation from "../../Validation/OrderValidation";
import "./order.css";
import { Table } from "react-bootstrap";
import logo from "../../../assets/Images/kreesalogo.png";

const OrderNow = (props) => {
  const history = useHistory();
  document.title = `Kreesa | Order-Now`;
  // catch a props through Link
  const { itemName, colorName, quantity, specificationId, discount, rate } =
    props.location.state;

  props.location.state === undefined && history.push("/home");

  // state
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Continue To checkout",
    "Loading..."
  );

  const [orders, setOrders] = useState({
    consumerId: localStorage.getItem("userId"),
    contactNo: "",
    orderDetails: [
      {
        colorId: colorName,
        orderQuantity: quantity,
        specificationId: specificationId,
      },
    ],
    orderType: "wholesale",
    payment: {
      paymentMethod: "cash_on_delivery",
    },
    shippingAddress: "",
    shippingCost: "50",
    shippingCountry: "Nepal",
    shippingState: "",
    totalCost: (rate - (rate * discount) / 100) * quantity,
  });

  // set state
  const handleChoice = (e) => {
    setOrders({
      ...orders,
      [e.target.name]: e.target.value,
    });
  };

  // add order
  const AddOrderhandle = async (e) => {
    setButtonLoader(true);
    e.preventDefault();
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.post(
        "/kreesa/order",
        { ...orders },
        config
      ).then((res) => {
        history.push("/home");
        toast.success(`Order Placed !`);
        setButtonLoader(false);
      });
    } catch (err) {
      console.log(err);
      setButtonLoader(false);
    }
  };

  // to check authentication user or not
  // let auth = localStorage.getItem("auth");
  // if (auth !== "ROLE_WHOLESALER" && auth !== "ROLE_RETAILER") {
  //   return (
  //     <>
  //       <Redirect exact to="/home" />
  //       {toast.error("please login first")}
  //     </>
  //   );
  // }

  UserOrderValidation();
  return (
    <div style={{ height: "100vh" }} className="bg-light text-capitalize">
      <main className="container">
        <div>
          <div className="py-2 text-center">
            <Link to="/home">
              <img
                className="d-block mx-auto mb-4 img-fluid"
                style={{ borderRadius: "50%" }}
                src={logo}
                alt="kreesa"
                width="72"
                height="57"
              />
            </Link>
            <h2>Order form</h2>
            <p className="lead">You can easily order from here...</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-8 ">
            {/* <h4 className="mb-3">Billing address</h4>
            <form id="user-order-form" onSubmit={AddOrderhandle}>
              <div className="row"> */}
            {/* <div className="col-sm-6">
                  <label
                    htmlFor="firstName"
                    className="form-label font-weight-bold"
                  >
                    Company name
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    value={localStorage.getItem("username")}
                    disabled
                    style={{
                      cursor: "not-allowed",
                      MozUserSelect: "none",
                    }}
                    className="form-control"
                    id="companyname"
                    placeholder="Enter Your Company name"
                  />
                </div>
                <div className="col-sm-6">
                  <label
                    htmlFor="firstName"
                    className="form-label font-weight-bold"
                  >
                    Contact Number
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    name="contactNo"
                    className="form-control"
                    onChange={handleChoice}
                    id="firstName"
                    placeholder="Enter Your Contact Number"
                  />
                </div>

                <div className="col-12 mt-4">
                  <label htmlFor="address" className="form-label">
                    Address
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="shippingAddress"
                    onChange={handleChoice}
                    id="address"
                    placeholder="Address"
                  />
                </div>

                <div className="col-md-5 mt-4">
                  <label htmlFor="country" className="form-label">
                    Country <span className="text-danger"> *</span>
                  </label>
                  <select
                    className="form-select"
                    name="shippingCountry"
                    id="country"
                    onChange={handleChoice}
                    required
                  >
                    <option value="Nepal">Nepal</option>
                  </select>
                </div>

                <div className="col-sm-7 mt-4">
                  <label
                    htmlFor="firstName"
                    className="form-label font-weight-bold"
                  >
                    State
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="shippingState"
                    onChange={handleChoice}
                    id="shippingState"
                    placeholder="Enter Your State"
                    required
                  />
                </div> */}

            <Table striped responsive="sm" style={{ marginTop: "20px" }}>
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{itemName}</td>
                  <td>{quantity}</td>
                  <td>Rs. {rate - (rate * discount) / 100}</td>
                  <td>Rs. {(rate - (rate * discount) / 100) * quantity}</td>
                </tr>
              </tbody>
            </Table>
            <div className="col-12 order-prod-desc">
              <h4>BILL</h4>
              <div className="desc-item">
                <div>Shipping Price</div>
                <div>: Rs. 50</div>
              </div>
              <hr
                style={{
                  margin: "5px 0px",
                  color: "teal",
                  width: "230px",
                }}
              />
              <div className="desc-item font-weight-bold">
                <div>Total</div>
                <div>: Rs. {rate * quantity}</div>
              </div>
            </div>
          </div>
          <button
            ref={buttonLoader}
            id="orderbtn"
            className="w-100 mt-6 btn btn-primary btn-lg"
            type="submit"
            onClick={() => {
              history.push("/checkout-seller", [
                [
                  {
                    // cartId: item.id,
                    colorId: colorName,
                    orderQuantity: quantity,
                    specificationId: specificationId,
                    name: itemName,
                    price: rate,
                    discount: discount,
                    discountPrice: rate - (rate * discount) / 100,
                    discountedTotalPrice:
                      (rate - (rate * discount) / 100) * quantity,
                  },
                ],
              ]);
            }}
          >
            Continue To checkout
          </button>
          {/* </form> */}
          {/* <hr className="my-4 bg-info" />
            <form className="mt-4">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="same-address"
                />
                <label className="form-check-label" htmlFor="same-address">
                  Shipping address is the same as my billing address
                </label>
              </div>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="save-info"
                />
                <label className="form-check-label" htmlFor="save-info">
                  Save this information for next time
                </label>
              </div>
              <hr className="my-4" />
              <h4 className="mb-3">Billing address</h4>
              <div className="row gy-3">
                <div className="col-md-6">
                  <label htmlFor="cc-name" className="form-label">
                    Name on card
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cc-name"
                    placeholder=""
                    required
                  />
                  <small className="text-muted">
                    Full name as displayed on card
                  </small>
                  <div className="invalid-feedback">
                    Name on card is required
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="cc-number" className="form-label">
                    Credit card number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cc-number"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">
                    Credit card number is required
                  </div>
                </div>

                <div className="col-md-3">
                  <label htmlFor="cc-expiration" className="form-label">
                    Expiration
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cc-expiration"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">
                    Expiration date required
                  </div>
                </div>

                <div className="col-md-3">
                  <label htmlFor="cc-cvv" className="form-label">
                    CVV
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cc-cvv"
                    placeholder=""
                    required
                  />
                  <div className="invalid-feedback">Security code required</div>
                </div>
              </div>

              <hr className="my-4" />

              <button className="w-100 btn btn-primary btn-lg" type="submit">
                Continue To checkout
              </button>
            </form> */}
        </div>
        {/* </div> */}
      </main>
      <footer className="mt-6 text-muted text-center text-small">
        <p className="mb-1">&copy; 2020–{new Date().getFullYear()} kreesa</p>
        <ul className="list-inline pb-4">
          <li className="list-inline-item">
            <a href="#">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Support</a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default OrderNow;
