import { createSlice } from "@reduxjs/toolkit";
import {
  deliveryBoyConfirm,
  getAllDelivery,
  getAllQuotationDelivery,
  getSingleDeliveryOrder,
} from "../Action/DeliveryAction";

const DeliveryReducer = createSlice({
  name: "DeliveryReducer",
  initialState: {
    deliveries: [],
    deliveriesContainer: [],
    quotationDeliveries: [],
    quotationDeliveriesContainer: [],
    delivery: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterDelivery: (state, action) => {
      state.deliveries = state.deliveriesContainer.filter(
        (u) =>
          u.orderNo.toLowerCase().includes(action.payload) ||
          u.orderNo.toUpperCase().includes(action.payload)
      );
    },

    filterQuotationDelivery: (state, action) => {
      state.quotationDeliveries = state.quotationDeliveriesContainer.filter(
        (u) =>
          u.product_name.toLowerCase().includes(action.payload) ||
          u.product_name.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Delivery */
    [getAllDelivery.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllDelivery.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.deliveries = payload;
      state.deliveriesContainer = payload;
    },
    [getAllDelivery.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [deliveryBoyConfirm.pending]: (state, action) => {
      state.loader = true;
    },
    [deliveryBoyConfirm.fulfilled]: (state, { payload }) => {
      state.loader = false;
    },
    [deliveryBoyConfirm.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getSingleDeliveryOrder.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleDeliveryOrder.fulfilled]: (state, { payload }) => {
      state.delivery = payload;
      state.loader = false;
    },
    [getSingleDeliveryOrder.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getAllQuotationDelivery.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllQuotationDelivery.fulfilled]: (state, { payload }) => {
      state.quotationDeliveries = payload;
      state.quotationDeliveriesContainer = payload;
      state.loader = false;
    },
    [getAllQuotationDelivery.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterDelivery, filterQuotationDelivery } =
  DeliveryReducer.actions;
// export default
export default DeliveryReducer.reducer;
