import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import EditProduct from "../../../Retailer/Product/EditProduct/EditProduct";

const AdminEditProduct = () => {
  const history = useHistory();
  document.title = "Admin | Edit Product";
  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }

  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <EditProduct
            wholesaler={history?.location?.state !== "retail" ? true : false}
          />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AdminEditProduct;
