import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  getCompanyType,
  deleteCompanyType,
} from "../Features/Action/CompanyTypeAction/CompanyTypeAction";
import Spinner from "../Spinner";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import HtmlParser from "react-html-parser";
import { filterCompanyType } from "../Features/Reducer/CompanyType/CompanyType";
import { Excerpt } from "../../../utils";

const CompanyTypeDetails = (props) => {
  const { companyType, loader } = useSelector((s) => s.CompanyType);

  const [modalId, setModalId] = React.useState("");
  const handleClose = () => setModalId("");

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  const inputRef = useRef("");
  const dispatch = useDispatch();
  /* --------------------------------- filter --------------------------------- */
  const filter = () => {
    dispatch(filterCompanyType(inputRef.current.value));
  };

  useEffect(() => {
    dispatch(getCompanyType());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = companyType.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(companyType.length / perPage);

  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link
                to="/admin/add-company-type"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add Company Type <i className="fas fa-bezier-curve ml-2"></i>
              </Link>
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Company Types…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Company Categories</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Company Type</th>
                    <th>Description</th>
                    <th>Creation Date</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>{p.typeName}</td>
                      <td>{HtmlParser(Excerpt(p.description, 40))}</td>
                      <td>{moment(p.creationDate).format("ll")}</td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-left">
                            <Link
                              className="dropdown-item text-info"
                              to={`/admin/edit-company-type/${p.id}`}
                            >
                              <i className="fas fa-user-edit"></i> Edit
                            </Link>
                            <div className="dropdown-divider"></div>

                            <button
                              onClick={() => setModalId(`modal${i}`)}
                              className="dropdown-item text-success"
                            >
                              <i className="fas fa-eye"></i> Quick View
                            </button>
                            <div className="dropdown-divider"></div>
                            <button
                              className="dropdown-item text-danger"
                              onClick={() => dispatch(deleteCompanyType(p.id))}
                            >
                              <i className="fas fa-trash"></i> Delete
                            </button>
                            <Modal
                              show={modalId === `modal${i}`}
                              onHide={handleClose}
                              {...props}
                              size="lg"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <Modal.Header>
                                <Modal.Title>{p.type_name}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {HtmlParser(p.description)}
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={companyType}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Company Type Found !!!</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default CompanyTypeDetails;
