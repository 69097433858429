import React, { useEffect, useState } from "react";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { useParams } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import HtmlParser from "react-html-parser";
import Spinner from "../../../../Components/Admin/Spinner";

const SingleStockDetails = () => {
  const { stockId } = useParams();
  const [loading, setLoading] = useState(false);

  /// modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };
  const [state, setstate] = useState([]);

  // get single stock data
  const getSingleStock = async () => {
    setLoading(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const getSingleStockDetails = await AxiosConfig.get(
        `/kreesa/stock-management/get-single-stock/${stockId}`,
        config
      );
      setstate(getSingleStockDetails.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleStock(stockId);
  }, []);

  return !loading ? (
    <div className="text-capitalize">
      <div className="card flex-fill">
        <div
          className="card-header text-uppercase font-weight-bold mt-2 "
          style={{ marginBottom: "-17px" }}
        >
          Retailer Stock Details
        </div>
        <hr className="text-info mt-0" />
        <div className="row m-4 mt-4">
          <div className="col-md-3">
            <label htmlFor="FirstName" className="font-weight-bold ">
              Product Name
            </label>
            <p className="text-dark h5 mt-2">
              {state.productAndSpecificationDetails?.productName}
            </p>
          </div>

          <div className="col-md-3">
            <label htmlFor="FirstName" className="font-weight-bold ">
              Total Stock
            </label>
            <p
              className="text-dark h5 mt-2"
              style={{
                background: " #a7c2ff",
                padding: "5px",
                width: "51px",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              {state.total_stock}
            </p>
          </div>
        </div>
        <hr />
        <div className="row m-4">
          <div className="col-md-12">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Description
            </label>
            <p className="text-dark h5 mt-2">
              {HtmlParser(state.productAndSpecificationDetails?.description)}
            </p>
          </div>
        </div>
        <hr />
        <div className="row m-4">
          <div className="col-md-3 col-sm">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Size
            </label>
            <p className="text-dark mt-2">
              {state.productAndSpecificationDetails?.size}
            </p>
          </div>
          <div className="col-md-3 col-sm">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Weight <span className="text-info">(kg)</span>
            </label>
            <p className="text-dark mt-2">
              {state.productAndSpecificationDetails?.weight}
            </p>
          </div>
          <div className="col-md-3 col-sm">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Height
            </label>
            <p className="text-dark mt-2">
              {state.productAndSpecificationDetails?.hight}
            </p>
          </div>
          <div className="col-md-3 col-sm">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Length
            </label>
            <p className="text-dark mt-2">
              {state.productAndSpecificationDetails?.length}
            </p>
          </div>

          <div className="col-md-3 col-sm">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Thickness
            </label>
            <p className="text-dark mt-2">
              {state.productAndSpecificationDetails?.thickness
                ? state.productAndSpecificationDetails?.thickness
                : 0}
            </p>
          </div>
        </div>

        <div
          className="card-header text-uppercase font-weight-bold"
          style={{ marginBottom: "-17px" }}
        >
          Images
        </div>
        <hr className="text-info mt-0" />
        <div className="row m-4 d-flex">
          <div className="col-md-12 d-flex">
            {state.images?.map((im, k) => (
              <div key={k}>
                <div
                  onClick={() => handleOpenModal(im.imageId)}
                  className="d-flex cursor-pointer"
                >
                  <img
                    className="img-fluid img-thumbnail ml-2"
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${im.imageId}/${im.imageName}`}
                    height="132px"
                    width="145px"
                    alt={im.imageName}
                  />
                </div>
                {/*Modal  */}
                {modal.show && modal.requestedModalId == im.imageId && (
                  <Modal
                    open={modal}
                    onClose={handleCloseModal}
                    center
                    classNames={{
                      overlayAnimationIn: "customEnterOverlayAnimation",
                      overlayAnimationOut: "customLeaveOverlayAnimation",
                      modalAnimationIn: "customEnterModalAnimation",
                      modalAnimationOut: "customLeaveModalAnimation",
                    }}
                    animationDuration={1000}
                  >
                    <div className="pro">
                      <img
                        className="img-fluid img-thumbnail"
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${im.imageId}/${im.imageName}`}
                        height={450}
                        width={550}
                        alt={im.imageName}
                      />
                    </div>
                  </Modal>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default SingleStockDetails;
