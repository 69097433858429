import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import QuotationsDelivery from "./QuotationsDelivery";

const DeliveryBoyAllQuotationDelivery = () => {
  document.title = "All Delivery Orders";
  // to check authentication user or not
  let auth = localStorage.getItem("auth");
  if (auth !== "ROLE_DELIVERY_BOY") {
    return <Redirect exact to="/login" />;
  }
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <QuotationsDelivery />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default DeliveryBoyAllQuotationDelivery;
