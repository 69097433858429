import React, { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import useButtonLoader from "../../../ButtonLoaderHook";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { getSingleFaqsForAdmin } from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";

const validationSchema = Yup.object().shape({
  question: Yup.string().required("question is required"),
  //   video_url: Yup.string().required("video url is required"),
  answer: Yup.string()
    .required("Answer is required")
    .min(13, "Answer must be 5 characters"),
  faqType: Yup.string().required("faq type is required"),
  //   message: Yup.string().required("Message is required"),
  //   email: Yup.string().email("Email is invalid").required("Email is required"),
});
const AddFAQ = () => {
  const { id } = useParams();

  document.title = `Admin | ${id ? "Edit" : "Add"} FAQ`;
  const history = useHistory();

  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    `${id ? "Update FAQ" : "Add FAQ"}`,
    "Loading..."
  );
  const { faq, loader } = useSelector((s) => s.AdminReducer);

  const dispatch = useDispatch();

  const UpdateFAQHandler = async (values, submitProps) => {
    setButtonLoader(true);
    const formData = new FormData();
    formData.append("question", values.question);
    formData.append("faqType", values.faqType);
    formData.append(
      "answer",
      draftToHtml(convertToRaw(description.getCurrentContent()))
    );
    formData.append("videoUrl", values.video_url);
    formData.append("id", id);
    AxiosConfig.post(`/kreesa/admin/faq/update/${id}`, formData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        toast.success("Faq update Successfully");
        setButtonLoader(false);
        history.push("/admin/view/faq");
      })
      .catch((err) => {
        setButtonLoader(false);
      });
  };

  const addFAQHandler = async (values, submitProps) => {
    setButtonLoader(true);
    const formData = new FormData();
    formData.append("question", values.question);
    formData.append("faqType", values.faqType);
    formData.append(
      "answer",
      draftToHtml(convertToRaw(description.getCurrentContent()))
    );
    formData.append("videoUrl", values.video_url);
    AxiosConfig.post(`/kreesa/admin/faq`, formData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        toast.success("Faq Added Successfully");
        setButtonLoader(false);
        submitProps.resetForm();
        history.push("/admin/view/faq");
      })
      .catch((err) => {
        setButtonLoader(false);
      });
  };

  // for getting answer
  useEffect(() => {
    if (faq?.answer?.length > 0) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(faq?.answer))
        )
      );
    }
  }, [faq, id]);

  // for getting single faq
  useEffect(() => {
    dispatch(getSingleFaqsForAdmin(id));
  }, [id]);

  // for empty state
  useEffect(() => {
    setDescription(EditorState.createEmpty());
  }, []);

  return !loader ? (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title font-weight-bold">
            {id ? "Edit" : "Add"} FAQ Form
          </h5>
          <h6 className="card-subtitle text-muted">
            {" "}
            You Can Easily {id ? "Edit" : "Add New"} FAQ.
          </h6>
        </div>
        <div className="card-body">
          <Formik
            initialValues={{
              question: id ? faq?.question : "",
              video_url: id ? faq?.videoUrl : "",
              answer: id ? faq?.answer : "",
              faqType: id ? faq?.faqType : "",
            }}
            onSubmit={id ? UpdateFAQHandler : addFAQHandler}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="col">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Question</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="question"
                      onChange={handleChange}
                      value={values.question}
                      id="inputCategory"
                      placeholder="Enter Question"
                    />
                    {errors.question && touched.question ? (
                      <div style={{ color: "red" }}> {errors.question} </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">FAQ For</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <select
                      id="inputState"
                      name="faqType"
                      onChange={handleChange}
                      className="form-control"
                      value={values.faqType || ""}
                    >
                      <option value="">Select FAQ Type...</option>
                      <option value="BUYER">Buyer</option>
                      <option value="SELLER">Seller</option>
                    </select>
                    {errors.faqType && touched.faqType ? (
                      <div style={{ color: "red" }}> {errors.faqType} </div>
                    ) : null}
                  </div>

                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Video Url</span>{" "}
                      <span className="text-info"> (optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="video_url"
                      onChange={handleChange}
                      value={values.video_url}
                      id="inputCategory"
                      placeholder="Enter Video Url"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 col-md-8">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Answer</span>
                      {/* <span className="text-info"> (optional)</span> */}
                      <span className="text-danger"> *</span>
                    </label>
                    <Editor
                      editorState={description}
                      onEditorStateChange={(desc) => {
                        setDescription(desc);
                        setFieldValue(
                          "answer",
                          draftToHtml(convertToRaw(desc.getCurrentContent()))
                        );
                      }}
                      editorStyle={{
                        backgroundColor: "white",
                        minHeight: "150px",
                        padding: "10px 20px",
                      }}
                      editorClassName="mt-2 p-10 mx-auto"
                    />
                  </div>
                  {errors.answer && touched.answer ? (
                    <div style={{ color: "red" }}> {errors.answer} </div>
                  ) : null}
                </div>
                <div className="mb-3 mt-4 col-md-4">
                  <button
                    id="catadd"
                    type="submit"
                    className="btn btn-success btn-lg btn-block"
                    ref={buttonLoader}
                  >
                    {id ? "Update FAQ" : "Add FAQ"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default AddFAQ;
