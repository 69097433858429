import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllDistrict } from "../../../Features/Action/AddressAction";
import Pagination from "../../../../Pagination";
import { filterDistrict } from "../../../Features/Reducer/AddressReducer";
import Spinner from "../../../Spinner";
import HtmlParser from "react-html-parser";
import { Excerpt } from "../../../../../utils";

const DistrictDetail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();

  // to get all districts
  const { districts, loader } = useSelector((s) => s.AddressReducer);

  const inputRef = useRef("");

  const handlefilterCountry = () => {
    dispatch(filterDistrict(inputRef.current.value));
  };

  useEffect(() => {
    dispatch(getAllDistrict());
  }, []);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = districts.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(districts.length / perPage);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link
                to="/admin/add-district"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add District
                {/* <i className="fas fa-flag"></i> */}
              </Link>
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search District"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={handlefilterCountry}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Country Details</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Country Code & Name / Province</th>
                    <th>District Name</th>
                    <th>Description</th>
                    <th>Delivery Charge</th>
                    <th className="d-xl-table-cell">Created By</th>
                    <th>Status</th>
                    <th className="d-md-table-cell">Last Modified by</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>
                        ({p?.stateOrProvience?.country?.country_code}){" "}
                        {p?.stateOrProvience?.country?.country_name} /{" "}
                        {p?.stateOrProvience?.provience_name}
                      </td>
                      <td>{p.district_name}</td>
                      <td>{HtmlParser(Excerpt(p.description, 40))}</td>
                      <td>
                        <small
                          className={`${
                            p?.deliveryCharge === 0
                              ? "badge bg-info p-2"
                              : "font-weight-bold"
                          }`}
                        >
                          {p?.deliveryCharge === 0
                            ? "FREE"
                            : `Rs. ${p.deliveryCharge}`}
                        </small>
                      </td>
                      <td className="d-xl-table-cell">{p.createdBy}</td>
                      {/* status */}
                      {p.status === false ? (
                        <td>
                          <span className="badge bg-danger">Inactive</span>
                        </td>
                      ) : (
                        <td>
                          <span className="badge bg-success">Active</span>
                        </td>
                      )}
                      {/* status */}
                      <td className="d-md-table-cell">{p.lastModifiedBy}</td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              className="dropdown-item text-info"
                              to={`/admin/district/edit/${p.id}`}
                            >
                              <i className="fas fa-user-edit"></i> Edit
                            </Link>
                            {/* <div className="dropdown-divider"></div>
                            <button className="dropdown-item text-danger" onClick={()=>dispatch(deleteDistrict(p.id))}>
                              <i className="fas fa-trash-alt"></i> Delete
                            </button> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={districts}
          />
        </div>
      ) : (
        <div className="row">
          <h3 className="text-center">No District Found !!</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default DistrictDetail;
