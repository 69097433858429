import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";

const SupplierDetailsModal = (props) => {
  const [su, setsu] = useState();
  const getSupplier = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const getus = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${props.sid}`,
        config
      );
      setsu(getus.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSupplier();
  }, [props.state]);
  return (
    <div>
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title>Supplier Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="pro">
              {/* <div className="col-md-4">
                    <div className="profile-img">
                      <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${id}/${user.companyLogo}`} alt={user?.companyName} />
                    </div>
                  </div> */}

              <h3 className="text-uppercase">{su?.companyName} </h3>
            </div>

            <div className="col-md-6 m-auto">
              <div className="tab-content profile-tab custom-height">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Company Name</label>
                    </div>
                    <div className="col-md-6 ">
                      <p className="text-capitalize">{su?.companyName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Company Address</label>
                    </div>
                    <div className="col-md-6">
                      <p className="text-capitalize">{`${su?.districtName}, ${su?.provienceName}, ${su?.countryName}`}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label>Company Email</label>
                    </div>
                    <div className="col-md-6">
                      <p>{su?.companyEmail}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Company Type</label>
                    </div>
                    <div className="col-md-6">
                      <p className="text-capitalize">{su?.companyType}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Company Contact</label>
                    </div>
                    <div className="col-md-6">
                      <p> {su?.companyContactNo}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>PAN No</label>
                    </div>
                    <div className="col-md-6">
                      <p> {su?.panNo}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Register Status</label>
                    </div>
                    <div className="col-md-6">
                      {su?.isRegistered === true ? (
                        <p>Register</p>
                      ) : (
                        <p>Not Register</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Insuranced Status</label>
                    </div>
                    <div className="col-md-6">
                      {su?.isInsured === true ? (
                        <p>Insuranced</p>
                      ) : (
                        <p>Not Insuranced</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SupplierDetailsModal;
