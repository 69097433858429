import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Nav from "../Nav";
import Footer from "../Footer";
import Spinner from "../../Admin/Spinner";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { getLegalDocsUser } from "../../User/Features/action";

const TermsandConditions = () => {
  const dispatch = useDispatch();
  document.title = "Kreesa | Terms & Conditions";
  const { legalDoc, loader } = useSelector((state) => state.userProducts);

  useEffect(() => {
    dispatch(getLegalDocsUser("term-condition"));
  }, []);

  return (
    <div>
      <Header />

      <Nav />
      {loader ? (
        <Spinner />
      ) : (
        <div className="container mt-6 mb-4">
          <h2 className="mb-3">{legalDoc.title}</h2>
          {ReactHtmlParser(legalDoc.description)}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default TermsandConditions;
