import { createSlice } from "@reduxjs/toolkit";
import {
  fetchImplementedTree,
  fetchTreeLimit,
  fetchUnimplementedTree,
} from "../../Action/TreeAction";

const TreeReducer = createSlice({
  name: "TreeReducer",
  initialState: {
    unimplemented: [],
    implemented: [],
    implementedContainer: [],
    treeLimit: {},
    loader: false,
    errors: {},
  },
  reducers: {
    filterImplemented: (state, action) => {
      state.implemented = state.implementedContainer.filter(
        (u) =>
          u.address_of_plant.toLowerCase().includes(action.payload) ||
          u.address_of_plant.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Unimplemented Tree */
    [fetchUnimplementedTree.pending]: (state, action) => {
      state.loader = true;
    },
    [fetchUnimplementedTree.fulfilled]: (state, action) => {
      state.loader = false;
      state.unimplemented = action.payload;
    },
    [fetchUnimplementedTree.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    /* to get all Implemented Tree */
    [fetchImplementedTree.pending]: (state, action) => {
      state.loader = true;
    },
    [fetchImplementedTree.fulfilled]: (state, action) => {
      state.loader = false;
      state.implemented = action.payload;
      state.implementedContainer = action.payload;
    },
    [fetchImplementedTree.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    /* to get  Tree Limit */
    [fetchTreeLimit.pending]: (state, action) => {
      state.loader = true;
    },
    [fetchTreeLimit.fulfilled]: (state, action) => {
      state.loader = false;
      state.treeLimit = action.payload;
    },
    [fetchTreeLimit.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterImplemented } = TreeReducer.actions;
// export default
export default TreeReducer.reducer;
