import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import {
  addVacancy,
  getVacancy,
  updateVacancy,
} from "../../Features/Action/AdminBlockUnblockAction";
import { toast } from "react-toastify";
import moment from "moment";
import Spinner from "../../Spinner";
import { useHistory, useParams } from "react-router-dom";
import { validationSchema } from "./validationSchema";

const AddEditVacancy = () => {
  const { id } = useParams();

  // state
  const [jobDescription, setJobDescription] = useState(
    EditorState.createEmpty()
  );

  const [requirement, setRequirement] = useState(EditorState.createEmpty());
  const [deadline, setDeadline] = useState(new Date());

  const dispatch = useDispatch();
  const { vacancy, loader } = useSelector((s) => s.AdminReducer);

  const history = useHistory();

  document.title = `Admin | ${!id ? "Add Vacancy" : vacancy?.title}`;

  const VacancyHandler = async (values) => {
    const datas = {
      ...values,
      toast,
      deadline: moment(deadline).format("YYYY-MM-DD HH:mm:ss"),
      jobDescription: draftToHtml(
        convertToRaw(jobDescription.getCurrentContent())
      ),
      requirement: draftToHtml(convertToRaw(requirement.getCurrentContent())),
    };
    if (!id) {
      dispatch(addVacancy({ ...datas }));
    } else {
      dispatch(updateVacancy({ ...datas, id }));
    }
    history.goBack();
  };
  // useEffect
  useEffect(() => {
    if (vacancy?.jobDescription?.length > 0) {
      setJobDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(vacancy?.jobDescription)
          )
        )
      );
    }
    if (vacancy?.requirement?.length > 0) {
      setRequirement(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(vacancy?.requirement)
          )
        )
      );
    }
    if (vacancy?.deadline) {
      setDeadline(new Date(vacancy?.deadline));
    }
  }, [vacancy, id]);

  useEffect(() => {
    dispatch(getVacancy(id));
  }, [id]);

  useEffect(() => {
    setJobDescription(EditorState.createEmpty());
    setRequirement(EditorState.createEmpty());
  }, []);

  // initialValues
  const initialValues = {
    jobLocation: "",
    title: "",
    jobOverview: "",
    jobType: "",
    openVacancies: "",
    deadline: "",
    requirement: "",
  };
  const vacancyValues = {
    jobLocation: vacancy?.jobLocation,
    title: vacancy?.title,
    jobOverview: vacancy?.jobOverview,
    jobType: vacancy?.jobType,
    openVacancies: vacancy?.openVacancies,
    deadline: vacancy?.deadline,
    requirement: vacancy?.requirement,
  };
  return !loader ? (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title font-weight-bold">
            {!id ? "Add" : "Update"} Vacancy Form
          </h5>
          <h6 className="card-subtitle text-muted">
            {" "}
            You Can Easily {!id ? "Add New" : "Update"} Vacancy.
          </h6>
        </div>
        <div className="card-body">
          <Formik
            initialValues={!id ? initialValues : vacancyValues}
            onSubmit={VacancyHandler}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Job Title</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="inputCategory"
                      onChange={handleChange}
                      value={values.title}
                      placeholder="Enter Job Title"
                    />
                    {errors.title && touched.title ? (
                      <div style={{ color: "red" }}> {errors.title} </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Location</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="jobLocation"
                      onChange={handleChange}
                      value={values.jobLocation}
                      id="inputCategory"
                      placeholder="Enter Location"
                    />
                    {errors.jobLocation && touched.jobLocation ? (
                      <div style={{ color: "red" }}> {errors.jobLocation} </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Deadline</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <DatePicker
                      className="form-control"
                      name="deadline"
                      format="YYYY-MM-DD HH:mm:ss"
                      selected={deadline}
                      onChange={(date) => {
                        setDeadline(date);
                        setFieldValue("deadline", date);
                      }}
                    />
                    {errors.deadline && touched.deadline ? (
                      <div style={{ color: "red" }}> {errors.deadline} </div>
                    ) : null}
                  </div>

                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Job Type</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="jobType"
                      onChange={handleChange}
                      value={values.jobType}
                      id="inputCategory"
                      placeholder="Enter Job Type"
                    />
                    {errors.jobType && touched.jobType ? (
                      <div style={{ color: "red" }}> {errors.jobType} </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Open Vacancy</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="openVacancies"
                      onChange={handleChange}
                      value={values.openVacancies}
                      id="inputCategory"
                      placeholder="Enter Open Vacancy"
                    />
                    {errors.openVacancies && touched.openVacancies ? (
                      <div style={{ color: "red" }}>
                        {" "}
                        {errors.openVacancies}{" "}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Overview</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="jobOverview"
                      onChange={handleChange}
                      value={values.jobOverview}
                      id="inputCategory"
                      placeholder="Enter Location"
                    />
                    {errors.jobOverview && touched.jobOverview ? (
                      <div style={{ color: "red" }}> {errors.jobOverview} </div>
                    ) : null}
                  </div>
                </div>

                <div className="col">
                  <div className="mb-3 col-md-12">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Job Requirement</span>
                      <span className="text-danger"> *</span>
                    </label>
                    <Editor
                      editorState={requirement}
                      onEditorStateChange={(requirement) => {
                        setRequirement(requirement);
                        setFieldValue(
                          "requirement",
                          draftToHtml(
                            convertToRaw(requirement.getCurrentContent())
                          )
                        );
                      }}
                      placeholder="Enter Job Requirement ..."
                      editorStyle={{
                        backgroundColor: "white",
                        minHeight: "150px",
                        padding: "10px 20px",
                      }}
                      editorClassName="mt-2 p-10 mx-auto"
                    />
                    {errors.requirement && touched.requirement ? (
                      <div style={{ color: "red" }}> {errors.requirement} </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-12">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Job Description</span>
                      <span className="text-info"> (optional)</span>
                    </label>
                    <Editor
                      editorState={jobDescription}
                      onEditorStateChange={(description) =>
                        setJobDescription(description)
                      }
                      placeholder="Enter Job Description ..."
                      editorStyle={{
                        backgroundColor: "white",
                        minHeight: "150px",
                        padding: "10px 20px",
                      }}
                      editorClassName="mt-2 p-10 mx-auto"
                    />
                  </div>
                </div>
                <div className="mb-3 mt-4 col-md-4">
                  <button
                    id="catadd"
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-success btn-lg btn-block"
                  >
                    {!id ? "Add" : "Update"} Vacancy
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default AddEditVacancy;
