import React, { useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import AllCustomerTabs from "./AllCustomerTabs";
import BlockCustomer from "./BlockCustomer";
import { exportCustomerInExcel, getAllCustomer } from "../../Features/Action/AdminBlockUnblockAction";
import { useDispatch } from "react-redux";
import { filterCustomer } from "../../Features/Reducer/AdminReducer";
import ExportInExcel from "../../ExportInExcel";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";

const CustomersDetails = () => {
  document.title = "Admin | Customers";

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    dispatch(getAllCustomer());
  }, []);

  const filter = () => {
    dispatch(filterCustomer(inputRef.current.value));
  };

  const exportInExcel = async () => {
    const res = await dispatch(exportCustomerInExcel());
    const fulfilled = res.meta.requestStatus === "fulfilled";

    const fileName = res.payload;

    if (fulfilled) {
      const url = AxiosConfig.defaults.baseURL + `/kreesa/document/report/${fileName}`;
      const newTab = window.open(url, "_blank");
      setTimeout(() => {
        newTab.close();
      }, 5000);
    }
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex w-100">
            <div className="card flex-fill flex-fill">
              <div className="card-header">
              <ExportInExcel onClick={exportInExcel} />
                <form className="mt-2 d-sm-inline-block">
                  <div className="input-group input-group-navbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Customers…"
                      aria-label="Search"
                      ref={inputRef}
                      onChange={filter}
                    />
                    <button className="btn" type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <Tabs>
            <TabList>
              <Tab>All Users</Tab>
              <Tab>Blocked Users</Tab>
            </TabList>

            <TabPanel>
              {/* All Users */}

              <AllCustomerTabs />
            </TabPanel>

            <TabPanel>
              {/* Blocked Users */}

              <BlockCustomer />
            </TabPanel>
          </Tabs>

          {/* pagination  */}
          {/* <Pagination /> */}
        </div>
      </div>
    </div>
  );
};

export default CustomersDetails;
