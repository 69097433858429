import UserFooter from ".";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";

const SalerPage = () => {
  document.title = "Kreesa | Seller Page";
  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      <div className="container mt-6 mb-4">
        <div
          className="mt-6"
          style={{ fontSize: "20px", textAlign: "justify" }}
        >
          <h1
            className="mb-5 font-weight-bold text-center"
            style={{ color: "#319ffb " }}
          >
            About Selling Process
          </h1>
          <div>
            Kreesa Sales Private Limited
            <br />
            Kathmandu, Nepal
          </div>
          <p className="mt-5">Dear Suppliers,</p>
          <div className="mt-4">
            <p>
              We aspire to be the most successful and leading E-commerce
              business by 2030 having “Bringing New Era in the Field of
              E-commerce” as our objective. We will be launching <b>Kreesa</b>{" "}
              on Web, Android and iOS at the same time by January end. Our
              concept is to provide platform to suppliers in order to reach to
              retailers and end consumers.
            </p>
            <p>
              As we know, Nepal is progressing towards Internet Era. Everything
              will be E- commercialized sooner or later. So, we have come up
              with peculiar concept which helps both of us to grow together and
              reach new heights. We feel, we have great potential to overcome
              traditional method and bringing new era in e-commerce.
            </p>
          </div>

          <div>
            <div
              className="text-success font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px" }}
            >
              Sell Online in Nepal
            </div>
            <p>
              Selling online in Nepal in the future. In today's digital age, you
              must have an online presence to prosper your business. The younger
              generation almost always prefer online platform to search for a
              product. You should be able to get your business in front of this
              new audience. To do that, <i>Start Selling Online!</i> But to sell
              online in Nepal, you will need an e-commerce website or an online
              platform to display your products.
            </p>
          </div>

          <div>
            <div
              className="text-success font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px" }}
            >
              Sell Online in Nepal with Kreesa
            </div>
            <p>
              Kreesa is known to be the best online selling website in Nepal. If
              you want to start your online business with a reliable
              organization to back you, then Kreesa seller center is the best
              way to go about it. All you've to do is go through the Kreesa
              seller account registration process that's available on
              kreesa.com, and you're all set to sell on Kreesa!
            </p>
          </div>

          <div>
            <div
              className="font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px", color: "orange" }}
            >
              Kreesa Seller
            </div>
            <p>
              If you want to start your small business and become part of a
              renowned online selling website in Nepal, then kreesa.com is the
              best platform for you. With the Kreesa seller center, you can set
              up a Kreesa seller account and start making online sale in Nepal!
            </p>
          </div>

          <div>
            <div
              className="font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px", color: "orange" }}
            >
              How to Become a Kreesa Seller?
            </div>
            <p>
              If you're confused about the process, or how to sell on Kreesa,
              the rest easy! The process of becoming a Kreesa seller is very
              simple. How to sell products via online selling sites in Nepal?
            </p>
            Three simple steps –
            <div className="text-center">
              <img src="/images/stepss.png" width="80%" />
            </div>
            For details on the process, just log on to{" "}
            <a href="/login">kreesa.com</a> and check the sell-on Kreesa page.
          </div>

          <div>
            <div
              className="font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px", color: "orange" }}
            >
              How to sell your products online with Kreesa?
            </div>
            <p>
              Three simple steps - register and list your products, receive
              orders, and sell across Nepal, get payments, and grow your
              business. For details on the process, just log on to{" "}
              <a href="/login">kreesa.com </a>
              and check <span className="font-weight-bold">Sellers Page </span>
              on Kreesa page.With the Kreesa seller account, you get to reach
              millions of customers. In addition, Kreesa helps your business by
              assisting with fast and reliable shipping, professional services
              to help you, no listing fee, secure and timely payment, and so
              much more!
            </p>
            <p>
              As the emerging largest Online Shopping platform in Nepal, Kreesa
              provides a variety of products in all kinds of categories.
              Customers can browse through the most reliable online shopping
              store. Kreesa makes a promise of giving you affordable prices,
              reliable products, safe shopping, secure payments, prompt
              deliveries, and easy return/ex-change. Offering the benefit of
              convenience and increased affordability, Kreesa is your most
              reliable online shopping store in Nepal. Download your Kreesa app
              now and get access to huge category of products, with amazing
              discounts, bundle amazing quality and much more!
            </p>
          </div>

          <div>
            <div
              className="font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px", color: "orange" }}
            >
              About Kreesa Online Shopping
            </div>
            <div className="text-center">
              <img src="/images/flowchart.png" width="80%" />
            </div>
            <p className="mt-4">
              So, download the Kreesa App now or visit to{" "}
              <a href="/">www.kreesa.com</a> and begin your online selling
              journey in Nepal!
            </p>
          </div>

          <div>
            <div
              className="text-success font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px" }}
            >
              Steps for Registration:
            </div>
            <ol type="A">
              <li className="font-weight-bold">
                <div>
                  Log into <a href="/login">www.kreesa.com</a>
                </div>
                <img src="/images/login.jpg" height="400px" />
              </li>
              <li className="font-weight-bold">
                Click on Register
                <img src="/images/register-now.jpg" height="100px" />
              </li>{" "}
              <li className="font-weight-bold mb-3">
                Select Business
                <img src="/images/business-select.png" height="100px" />
              </li>
              <li className="font-weight-bold mb-5">
                Select your category based on your Target Market.
                <br />
                <div className="font-weight-normal">
                  If you are interested in Business to business{" "}
                  <img src="/images/b2b.jpg" height="80px" /> select WHOLESALER.
                  And if you are interested in{" "}
                  <img src="/images/b2c.png" height="80px" /> select RETAILER
                  from the drop down menu under “Register As” menu in
                  registration form.
                </div>
              </li>
              <li className="font-weight-bold">
                Complete all the form requirements and submit. On verification
                of your mail id you are ready to go.
              </li>
            </ol>
            <br />
            <p className="text-center font-weight-bold">Best wishes.</p>
          </div>

          <div>
            <div
              className="text-success font-weight-bold mb-3 mt-5"
              style={{ fontSize: "24px" }}
            >
              Frequently Asked Questions by seller
            </div>
            {[
              {
                title: "How to Sell on Kreesa Nepal?",
                content: (
                  <p>
                    Register as a Wholesaler (wholesaler, distributor,
                    manufacturer) for B2B (Business to Business) purpose or
                    Retailer (for selling it to end consumer) go to{" "}
                    <a href="/login">kreesa.com</a>
                    and click Register, then click Business. Fill up the form
                    and submit. And verify your E-mail used. After Email
                    verification you need to wait for the approval of Kreesa. If
                    all documents are verified Kreesa will approve your request.
                    On approval of request you will be able to login to your
                    seller account. After logging in go to your dashboard and
                    feel free to add your products on respective category it
                    belongs to.
                  </p>
                ),
              },
              {
                title:
                  "I am supplier and I am not from Nepal, can I showcase my products on Kreesa.com?",
                content: (
                  <p>
                    Yes, for the very first time in Nepal kreesa.com provides
                    the platform for International sellers as well. But
                    International seller cannot register themselves as retailer.
                    They can list themselves on $
                    {<a href="/login">kreesa.com</a>} for Business to Business
                    purpose only. So they have to register themselves as
                    Wholesaler while registering on kreesa.com .
                  </p>
                ),
              },

              {
                title: "What is the process of selling products on Kreesa?",
                content: (
                  <p>
                    After adding products click on publish. After getting
                    approved from Kreesa’s backend your product will be listed
                    on the face of Kreesa.com. If you are supplying for business
                    it will be listed on the frontend of business and if you
                    have registered yourselves as Retailer then your product
                    will be listed on the frontend of consumer.
                    <br />
                    Receive orders, pack products, sell across Nepal. Showcasing
                    your products on Kreesa will help you reach out to Kreesa
                    community of Nepali consumers everyday. Get payments and
                    grow business.
                  </p>
                ),
              },
              {
                title: "Do I need a company to sell on Kreesa Nepal?",
                content: (
                  <p>
                    Yes, you need to have a PAN or VAT registered business to
                    start selling on Kreesa.
                  </p>
                ),
              },

              {
                title: "How can I earn money by selling on Kreesa?",
                content: (
                  <p>
                    You can easily register on Kreesa as a Wholesaler
                    (wholesaler, distributor, manufacturer) for B2B (Business to
                    Business) purpose or Retailer (for selling it to end
                    consumer) and start selling your products online across
                    Nepal. Selling products on marketplace like{" "}
                    <a href="/">kreesa.com</a> can not only be profitable, but
                    you can build a fortune provided you do the things right
                    way.
                  </p>
                ),
              },

              {
                title:
                  "What other features Kreesa offers? What makes kreesa.com different from other existing E-commerce Businesses in Nepal?",
                content: (
                  <p>
                    Kreesa is breaking stereotype of online shopping in Nepal.
                    Now for the very first time in Nepal overcoming traditional
                    way of doing business, Kreesa offers a platform where you
                    can have your ask by mentioning the product required,
                    quantity required and the price in which business i.e
                    Retailers are looking for. After getting such requirement
                    quote from retailer, supplier can match or can raise the
                    price and can resend the offer to the retailer. If retailer
                    accepts the suppliers price and all the order is finalized
                    on approval of Kreesa’s backend. Also businesses will be
                    able to order their requirements using Image or Audio as per
                    their convenience. We value time, Save your time placing
                    order using Kreesa’s special order option.
                    <br />
                    <span className="text-danger">
                      Note: This feature is only available for B2B businesses.
                    </span>
                  </p>
                ),
              },
            ].map((i, index) => (
              <div key={index} className="mb-4">
                <div
                  className="font-weight-bold mb-2 mt-4"
                  style={{ fontSize: "24px" }}
                >
                  {i.title}
                </div>
                <div>{i.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default SalerPage;
