import React, { useEffect, useRef, useState } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Excerpt } from "../../../../utils";
import Pagination from "../../../Pagination";
import moment from "moment";
import {
  deleteVacancy,
  getallVacancies,
} from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";
import { filterVacancies } from "../../Features/Reducer/AdminReducer";
import { toast } from "react-toastify";

const VacanciesDetail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();
  const inputRef = useRef("");

  const { vacancies, loader } = useSelector((s) => s.AdminReducer);

  // filter
  const handlefilterVacancy = () => {
    dispatch(filterVacancies(inputRef.current.value));
  };

  useEffect(() => {
    dispatch(getallVacancies());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = vacancies?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(vacancies?.length / perPage);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link
                to="/admin/add-vacancy"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add Vacancy <i className="fa fa-tasks"></i>
              </Link>
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Vacancy"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={handlefilterVacancy}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {currentEntries.length > 0 ? (
          <div className="row">
            <div className="col-12 col-lg-8 d-flex w-100">
              <div className="card flex-fill overflow-auto">
                <div className="card-header">
                  <h5 className="card-title mb-0">Latest Vacancies Details</h5>
                </div>
                <table className="table table-hover my-0 ">
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th>Title / Open Vacancies</th>
                      <th>Description</th>
                      <th className="d-xl-table-cell">requirement</th>
                      <th className="d-xl-table-cell">Overview</th>
                      <th className="d-md-table-cell">Deadline</th>
                      <th className="d-md-table-cell">Location</th>
                      <th className="d-md-table-cell">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries?.map((p, i) => (
                      <tr key={i}>
                        <td>{perPage * (currentPage - 1) + i + 1}</td>
                        <td>
                          {p.title} / ({p.openVacancies})
                        </td>
                        <td>{HtmlParser(Excerpt(p.jobDescription, 40))}</td>
                        <td className="d-xl-table-cel">
                          {HtmlParser(Excerpt(p.requirement, 40))}
                        </td>
                        <td className="d-xl-table-cel">{p.jobOverview}</td>
                        <td className="d-md-table-cell">
                          {moment(p.deadline).format("lll")}
                        </td>
                        <td className="d-md-table-cell">{p.jobLocation}</td>
                        <td className="d-md-table-cell">
                          <div className="btn-group">
                            <button
                              type="button"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#3b7ddd",
                              }}
                              className="btn-md btn btn-success dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More
                            </button>
                            <div className="dropdown-menu text-center">
                              <Link
                                className="dropdown-item text-info"
                                to={`/admin/edit-vacancy/${p.id}`}
                              >
                                <i className="fas fa-user-edit"></i> Edit
                              </Link>
                              <p className="dropdown-divider"></p>
                              <Link
                                className="dropdown-item text-success"
                                to={`/admin/vacancy-review/${p.id}`}
                              >
                                <i className="fas fa-eye"></i> Review
                              </Link>
                              <p className="dropdown-divider"></p>
                              <div
                                className="dropdown-item text-danger cursor-pointer"
                                onClick={() =>
                                  dispatch(deleteVacancy({ id: p.id, toast }))
                                }
                              >
                                <i className="fas fa-trash"></i> Delete
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* pagination  */}
            <Pagination
              pages={totalPagesNum}
              setCurrentPage={setCurrentPage}
              currentEntries={currentEntries}
              allEntries={vacancies}
            />
          </div>
        ) : (
          <div className="row">
            <h3 className="text-center">No Vacancies Found !!</h3>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default VacanciesDetail;
