import { useDispatch, useSelector } from "react-redux";
import { getLegalDocsUser } from "../Features/action";
import { useEffect } from "react";
import Search from "../Search/Search";
import Navbar from "../NavBar/Navbar";
import UserFooter from "../UserFooter";
import Spinner from "../../Admin/Spinner";
import ReactHtmlParser from "react-html-parser";

const TermsandConditions = () => {
  const dispatch = useDispatch();
  document.title = "Kreesa | Terms & Conditions";
  const { legalDoc, loader } = useSelector((state) => state.userProducts);

  useEffect(() => {
    dispatch(getLegalDocsUser("term-condition"));
  }, []);

  return (
    <div>
      <Search />
      <Navbar />
      {loader ? (
        <Spinner />
      ) : (
        <div className="container mt-6 mb-4 user-select-none">
          <h2 className="mb-3">{legalDoc.title}</h2>
          {ReactHtmlParser(legalDoc.description)}
        </div>
      )}
      <UserFooter />
    </div>
  );
};

export default TermsandConditions;
