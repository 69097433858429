import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAnalytics } from "../Features/Action/AdminBlockUnblockAction";
import Spinner from "../Spinner";
import "./style.css";

const Dashboard = () => {
  const { analytics, loader } = useSelector(
    (dashboard) => dashboard.AdminReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardAnalytics());
  }, []);

  return !loader ? (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto d-none d-sm-block">
          <h3>
            <strong>Analytics</strong> Dashboard
          </h3>
        </div>

        <div className="col-auto ml-auto text-right mt-n1">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
              <li className="breadcrumb-item">
                <a href="/" target="_blank">
                  Kreesa
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin">Dashboards</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Analytics
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Users */}
      <div className="row">
        <div className="w-100">
          <div className="row">
            <h5 className="h3">
              <strong>Users Details</strong>
            </h5>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h5 className="font-weight-bold mb-4">Total Administrator</h5>
                  <h1 className="mt-1 mb-3">
                    {analytics?.totalNumberOfAdministrator}
                  </h1>
                  <div className="mb-1">
                    <span className="text-success">
                      {/* <i className="mdi mdi-arrow-bottom-right"></i> 5.25% */}
                    </span>
                    {/* <span className="text-muted">Since last week</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h5 className="font-weight-bold mb-4">Total Wholesaler</h5>
                  <h1 className="mt-1 mb-3">{analytics?.totalWholesaler}</h1>
                  <div className="mb-1">
                    <span className="text-success">
                      {/* <i className="mdi mdi-arrow-bottom-right"></i> 5.25% */}
                    </span>
                    {/* <span className="text-muted">Since last week</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h5 className="font-weight-bold mb-4">Total Retailer</h5>
                  <h1 className="mt-1 mb-3">{analytics?.totalRetailer}</h1>
                  <div className="mb-1">
                    <span className="text-success">
                      {/* <i className="mdi mdi-arrow-bottom-right"></i> 5.25% */}
                    </span>
                    {/* <span className="text-muted">Since last week</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h5 className="font-weight-bold mb-4">Total Users</h5>
                  <h1 className="mt-1 mb-3">{analytics?.totalUser}</h1>
                  <div className="mb-1">
                    <span className="text-success">
                      {/* <i className="mdi mdi-arrow-bottom-right"></i> 5.25% */}
                    </span>
                    {/* <span className="text-muted">Since last week</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="w-100">
          <div className="row" id="product-details">
            <h5 className="h3">
              <strong>Product Details</strong>
            </h5>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {analytics?.totalProduct}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Total Products</p>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-l-4 col-xl-3 px-2">
            <div className="card">
              <div className="card-body">
                <h2 className="mt-1 h1 mb-3 text-center">
                  {" "}
                  {analytics?.totalApproveProduct}{" "}
                </h2>
                <p className="h4 text-center text-white">Approved Products</p>
              </div>
            </div>
          </div> */}

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 h1 mb-3 text-center">
                    {" "}
                    {analytics?.totalBlockProduct}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Block Products</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {analytics?.totalPendingProduct}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Pending Products</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {analytics?.totalRejectedProduct}{" "}
                  </h2>
                  <p className="h4 text-center text-white">Rejected Products</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="w-100">
          <div className="row" id="order-details">
            <h5 className="h3">
              <strong> Orders Details </strong>
            </h5>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {analytics?.totalOrder}
                  </h2>
                  <p className="h4 text-center text-white">Total Orders</p>

                  {/* <div className="mb-1">
                    <span className="text-success">
                      <i className="mdi mdi-arrow-bottom-right"></i> 5.25%
                    </span>
                    <span className="text-muted">Since last week</span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {analytics?.totalDispathedOrder}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Dispathed Orders</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {" "}
                    {analytics?.totalDeliveredOrder}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Delivered Orders</p>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 col-l-4 col-xl-3 px-2">
            <div className="card">
              <div className="card-body">
                <h2 className="mt-1 mb-3 h1 text-center">
                  {" "}
                  {analytics?.pendingOrder}{" "}
                </h2>

                <p className="h4 text-center text-white">Pending Orders</p>
              </div>
            </div>
          </div> */}
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card">
                <div className="card-body">
                  <h2 className="mt-1 mb-3 h1 text-center">
                    {analytics?.totalOnWayOrder}{" "}
                  </h2>

                  <p className="h4 text-center text-white">Onway Orders</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-6 col-xxl-7">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Recent Movement</h5>
          </div>
          <div className="card-body py-3">
            <div className="chart chart-sm">
              <canvas id="chartjs-dashboard-line"></canvas>
            </div>
          </div>
        </div>
      </div> */}
      </div>
      <div className="row">
        <div className="w-100">
          <div className="row" id="transction-details">
            <h5 className="h3">
              <strong> Transction Details </strong>
            </h5>
            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card bg-success">
                <div className="card-body">
                  <h2
                    className="mt-1 mb-3 h1 text-white text-truncate text-center"
                    style={{ letterSpacing: 1 }}
                  >
                    {analytics?.totalPaidTransction}
                  </h2>
                  <p className="h4 text-center text-white">Paid Transction</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-l-4 col-xl-3 px-2">
              <div className="card bg-success">
                <div className="card-body">
                  <h2
                    className="mt-1 mb-3 h1 text-white text-truncate text-center"
                    style={{ letterSpacing: 1 }}
                  >
                    {analytics?.totalUnPaidTransction}
                  </h2>

                  <p className="h4 text-center text-white">Unpaid Transction</p>
                  {/* <div className="mb-1">
                    <span className="text-success">
                      <i className="mdi mdi-arrow-bottom-right"></i> 5.25%
                    </span>
                    <span className="text-muted">Since last week</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-6 col-xxl-7">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Recent Movement</h5>
          </div>
          <div className="card-body py-3">
            <div className="chart chart-sm">
              <canvas id="chartjs-dashboard-line"></canvas>
            </div>
          </div>
        </div>
      </div> */}
      </div>
      {/* Custom Order */}
      <div className="row">
        <div className="w-100">
          <div className="row" id="transction-details">
            <h5 className="h3">
              <strong> Quotation Order </strong>
            </h5>
            {[
              {
                quotationOrderName: analytics?.total_quotation,
                text: "Total Quotation",
              },
              {
                quotationOrderName: analytics?.total_quotation_by_retailer,
                text: "Quotation By Retailer",
              },
              {
                quotationOrderName: analytics?.total_quotation_by_wholesaler,
                text: "Quotation By Wholesaler",
              },
              {
                quotationOrderName: analytics?.total_quotation_by_user,
                text: "Quotation By User",
              },
              {
                quotationOrderName: analytics?.total_pending_quotation,
                text: "Total Pending Quotation",
              },
            ].map((quotation, index) => (
              <div className="col-md-6 col-l-4 col-xl-3 px-2" key={index}>
                <div className="card bg-primary">
                  <div className="card-body">
                    <h2
                      className="mt-1 mb-3 h1 text-white text-truncate text-center"
                      style={{ letterSpacing: 1 }}
                    >
                      {quotation.quotationOrderName}
                    </h2>
                    <p className="h4 text-center text-white">
                      {quotation.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-100">
          <div className="row" id="transction-details">
            <h5 className="h3">
              <strong> Custom Order </strong>
            </h5>
            {[
              {
                customOrderName: analytics?.total_custom_order,
                text: "Total Custom Order",
              },
              {
                customOrderName: analytics?.total_approved_custom_order,
                text: "Approved Custom Order",
              },
              {
                customOrderName: analytics?.total_custom_order_by_retailer,
                text: "Custom Order By Retailer",
              },
              {
                customOrderName: analytics?.total_custom_order_by_wholesaler,
                text: "Custom Order By Wholesaler",
              },
              {
                customOrderName: analytics?.total_image_custom_order,
                text: "Total Custom Image Order",
              },
              {
                customOrderName: analytics?.total_voice_custom_order,
                text: "Total Custom Voice Order",
              },
              {
                customOrderName: analytics?.total_pending_custom_order,
                text: "Total Pending Custom Order",
              },
            ].map((customOrder, index) => (
              <div className="col-md-6 col-l-4 col-xl-3 px-2" key={index}>
                <div className="card bg-danger">
                  <div className="card-body">
                    <h2
                      className="mt-1 mb-3 h1 text-white text-truncate text-center"
                      style={{ letterSpacing: 1 }}
                    >
                      {customOrder.customOrderName}
                    </h2>
                    <p className="h4 text-center text-white">
                      {customOrder.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="col-xl-6 col-xxl-7">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Recent Movement</h5>
          </div>
          <div className="card-body py-3">
            <div className="chart chart-sm">
              <canvas id="chartjs-dashboard-line"></canvas>
            </div>
          </div>
        </div>
      </div> */}
      </div>
      {/* <div className="row">
      <div className="col-xl-6 col-xxl-5 d-flex">
        <div className="w-100">
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-4">Sales</h5>
                  <h1 className="mt-1 mb-3">2.382</h1>
                  <div className="mb-1">
                    <span className="text-danger">
                      <i className="mdi mdi-arrow-bottom-right"></i> -3.65%
                    </span>
                    <span className="text-muted">Since last week</span>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-4">Visitors</h5>
                  <h1 className="mt-1 mb-3">14.212</h1>
                  <div className="mb-1">
                    <span className="text-success">
                      <i className="mdi mdi-arrow-bottom-right"></i> 5.25%
                    </span>
                    <span className="text-muted">Since last week</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-4">Earnings</h5>
                  <h1 className="mt-1 mb-3">$21.300</h1>
                  <div className="mb-1">
                    <span className="text-success">
                      <i className="mdi mdi-arrow-bottom-right"></i> 6.65%
                    </span>
                    <span className="text-muted">Since last week</span>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-4">Orders</h5>
                  <h1 className="mt-1 mb-3">64</h1>
                  <div className="mb-1">
                    <span className="text-danger">
                      <i className="mdi mdi-arrow-bottom-right"></i> -2.25%
                    </span>
                    <span className="text-muted">Since last week</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="col-xl-6 col-xxl-7">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Recent Movement</h5>
          </div>
          <div className="card-body py-3">
            <div className="chart chart-sm">
              <canvas id="chartjs-dashboard-line"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      {/* <div className="row">
      <div className="col-12 col-md-6 col-xxl-3 d-flex order-2 order-xxl-3">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Browser Usage</h5>
          </div>
          <div className="card-body d-flex">
            <div className="align-self-center w-100">
              <div className="py-3">
                <div className="chart chart-xs">
                  <canvas id="chartjs-dashboard-pie"></canvas>
                </div>
              </div>

              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td>Chrome</td>
                    <td className="text-right">4306</td>
                  </tr>
                  <tr>
                    <td>Firefox</td>
                    <td className="text-right">3801</td>
                  </tr>
                  <tr>
                    <td>IE</td>
                    <td className="text-right">1689</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-12 col-md-12 col-xxl-6 d-flex order-3 order-xxl-2"
      >
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Real-Time</h5>
          </div>
          <div className="card-body px-4">
            <div id="world_map" style={{ height: "350px" }}></div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-xxl-3 d-flex order-1 order-xxl-1">
        <div className="card flex-fill">
          <div className="card-header">
            <h5 className="card-title mb-0">Calendar</h5>
          </div>
          <div className="card-body d-flex">
            <div className="align-self-center w-100">
              <div className="chart">
                <div id="datetimepicker-dashboard"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      {/* <div className="row">
      <div className="col-12 col-lg-8 col-xxl-9 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
            <h5 className="card-title mb-0">Latest Projects</h5>
          </div>
          <table className="table table-hover my-0">
            <thead>
              <tr>
                <th>Name</th>
                <th className="d-none d-xl-table-cell">Start Date</th>
                <th className="d-none d-xl-table-cell">End Date</th>
                <th>Status</th>
                <th className="d-none d-md-table-cell">Assignee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Project Apollo</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td><span className="badge bg-success">Done</span></td>
                <td className="d-none d-md-table-cell">Vanessa Tucker</td>
              </tr>
              <tr>
                <td>Project Fireball</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td><span className="badge bg-danger">Cancelled</span></td>
                <td className="d-none d-md-table-cell">William Harris</td>
              </tr>
              <tr>
                <td>Project Hades</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td><span className="badge bg-success">Done</span></td>
                <td className="d-none d-md-table-cell">Sharon Lessman</td>
              </tr>
              <tr>
                <td>Project Nitro</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td>
                  <span className="badge bg-warning">In progress</span>
                </td>
                <td className="d-none d-md-table-cell">Vanessa Tucker</td>
              </tr>
              <tr>
                <td>Project Phoenix</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td><span className="badge bg-success">Done</span></td>
                <td className="d-none d-md-table-cell">William Harris</td>
              </tr>
              <tr>
                <td>Project X</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td><span className="badge bg-success">Done</span></td>
                <td className="d-none d-md-table-cell">Sharon Lessman</td>
              </tr>
              <tr>
                <td>Project Romeo</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td><span className="badge bg-success">Done</span></td>
                <td className="d-none d-md-table-cell">Christina Mason</td>
              </tr>
              <tr>
                <td>Project Wombat</td>
                <td className="d-none d-xl-table-cell">01/01/2020</td>
                <td className="d-none d-xl-table-cell">31/06/2020</td>
                <td>
                  <span className="badge bg-warning">In progress</span>
                </td>
                <td className="d-none d-md-table-cell">William Harris</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      {/* <div className="col-12 col-lg-4 col-xxl-3 d-flex">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Monthly Sales</h5>
          </div>
          <div className="card-body d-flex w-100">
            <div className="align-self-center chart chart-lg">
              <canvas id="chartjs-dashboard-bar"></canvas>
            </div>
          </div>
        </div>
      </div> */}
      {/* </div> */}
    </div>
  ) : (
    <Spinner />
  );
};

export default Dashboard;
