import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row text-muted">
          <div className="col-6 text-left">
            <p className="mb-0">
              <a href="/wholesaler" className="text-muted">
                <strong>Kreesa Admin</strong>
              </a>
              &copy;
            </p>
          </div>
          <div className="col-6 text-right">
            <ul className="list-inline">
              <li className="list-inline-item">
                <a className="text-muted" href="/retailer/my-review">
                  Review
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-muted" href="/retailer/message">
                  Message
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-muted" href="/retailer/profile-settings">
                  Privacy
                </a>
              </li>
              <li className="list-inline-item">
                <a className="text-muted" href="/home">
                  Home
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
