import { createSlice } from "@reduxjs/toolkit";
import { viewAllLegalDocs, viewLegalDoc } from "../../Action/LegalAction";

const LegalReducer = createSlice({
  name: "LegalReducer",
  initialState: {
    legalData: [],
    legalDataContainer: [],
    singleDoc: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterLegal: (state, action) => {
      state.legalData = state.legalDataContainer.filter(
        (u) =>
          u.title.toLowerCase().includes(action.payload) ||
          u.title.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Group Product */
    [viewAllLegalDocs.pending]: (state, action) => {
      state.loader = true;
    },
    [viewAllLegalDocs.fulfilled]: (state, action) => {
      state.loader = false;
      state.legalData = action.payload;
      state.legalDataContainer = action.payload;
    },
    [viewAllLegalDocs.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [viewLegalDoc.pending]: (state, action) => {
      state.loader = true;
    },
    [viewLegalDoc.fulfilled]: (state, action) => {
      state.loader = false;
      state.singleDoc = action.payload;
    },
    [viewLegalDoc.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterLegal } = LegalReducer.actions;
// export default
export default LegalReducer.reducer;
