import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Pagination";
import { getAllEnquiries } from "../Features/Action/CompanyTypeAction/CompanyTypeAction";
import { filterEnquiries } from "../Features/Reducer/EnquiryReducer";
import Spinner from "../Spinner";

const EnquiryDetails = () => {
  const { allEnquiries, loader } = useSelector((s) => s.EnquiryReducer);
  const inputRef = useRef("");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  const filter = () => {
    dispatch(filterEnquiries(inputRef.current.value));
  };

  useEffect(() => {
    dispatch(getAllEnquiries());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = allEnquiries.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(allEnquiries.length / perPage);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Enquiries…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Enquiries</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Customer Name</th>
                    <th>Company Name</th>
                    <th>Customer Email</th>
                    <th>Contact Number</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((p, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{p.name}</td>
                      <td>{p.company === null ? "_______" : p.company}</td>
                      <td>
                        <a href={`mailto:${p.email}`}>{p.email}</a>
                      </td>
                      <td>{p.contact_details}</td>
                      <td>{p.message}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={allEnquiries}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Enquiry Found !!!</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default EnquiryDetails;
