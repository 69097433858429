import React, { useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UserTab from "./UserTab";
import BuyerTab from "./BuyerTab";
import { useDispatch } from "react-redux";
import {
  filterBuyerReview,
  filterUserReview,
} from "../Features/Reducer/ProductReducer/WholesalerReducer";
import {
  getallBuyerReview,
  getallUserReview,
} from "../Features/Action/ProductAction";

const ReviewDetails = () => {
  const inputRef = useRef("");
  const dispatch = useDispatch();

  const filterReviews = () => {
    dispatch(filterBuyerReview(inputRef.current.value));
    dispatch(filterUserReview(inputRef.current.value));
  };
  useEffect(() => {
    dispatch(getallUserReview());
    dispatch(getallBuyerReview());
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              {/* <Link
                to="/admin/add-faq"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add FAQ <i className="fa fa-question-circle"></i>
              </Link> */}
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Reviews…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterReviews}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <Tabs>
        <TabList>
          <Tab>User</Tab>
          <Tab>Buyer</Tab>
        </TabList>

        <TabPanel>
          {/* Seller */}

          <UserTab />
        </TabPanel>

        <TabPanel>
          {/* Buyer */}

          <BuyerTab />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ReviewDetails;
