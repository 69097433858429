import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Table, Modal } from "react-bootstrap";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
// import UserOrderValidation from "../../Validation/OrderValidation";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, removeCartRetailItem } from "../Features/action";
import { removeCartItem } from "../../RetailerWholeSaler/Features/Action/WholesalerAction";
import uuid from "react-uuid";
import logo from "../../../assets/Images/kreesalogo.png";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useRef } from "react";
import Cookies from "js-cookie";
import Spinner from "../../Admin/Spinner";
import "./pay.css";
import addCartAnimation from "./addCart.json";
import Lottie from "react-lottie";

const PayNow = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [token, setToken] = useState([]);
  const [ipsToken, setIpsToken] = useState([]);
  const [connectTxn, setConnectTxn] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  document.title = `Kreesa | Pay-Now`;

  const connectIpsRef = useRef();
  const visaRef = useRef();
  const pseudoEsewaRef = useRef();
  const pseudoCardRef = useRef();
  const pseudoConnectRef = useRef();

  (history?.location?.state === undefined ||
    !history?.location?.state === []) &&
    history.push("/home");
  const state = history.location.state;

  const [paymentLoading, setPaymentLoading] = useState({
    cardSignature: false,
    connectSignature: false,
    radioSelect: "",
  });

  const {
    access_key,
    amount,
    bill_to_address_city,
    bill_to_address_country,
    bill_to_address_line1,
    bill_to_address_postal_code,
    bill_to_address_state,
    bill_to_email,
    bill_to_forename,
    bill_to_phone,
    bill_to_surname,
    currency,
    locale,
    payment_method,
    profile_id,
    reference_number,
    signed_date_time,
    transaction_type,
    transaction_uuid,
    signed_field_names,
    unsigned_field_names,
    signature,
  } = token;

  const {
    appId,
    appName,
    merchantId,
    particulars,
    referenceId,
    remarks,
    txnCrncy,
    txnId,
    txnamt,
    token: connectIpsToken,
    txnDate,
  } = ipsToken;

  // get time for visa & debit card
  let timesCount = new Date().toISOString();
  let times = timesCount.split(".")[0]?.concat("Z");

  const paymentHandler = async (values) => {
    try {
      const res = await AxiosConfig.post("/kreesa/payment/signature", {
        ...values,
      });
      if (res.data) {
        setToken(res.data);
        setPaymentLoading({ ...paymentLoading, cardSignature: true });
        // AddOrderhandle();
      }
    } catch (err) {
      console.log(err);
    }
  };
  /* ----------------------------------- debit & credit handler end ---------------------------------- */
  let transitionUuid = uuid();

  /* ----------------------------------- connect IPS handler ---------------------------------- */
  const connectIpsHandler = async (values) => {
    try {
      const res = await AxiosConfig.post(
        "/kreesa/payment/connect-ips/signature",
        {
          ...values,
        }
      );
      if (res.data) {
        setIpsToken(res.data);
        setPaymentLoading({ ...paymentLoading, connectSignature: true });
        // AddOrderhandle();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // add order
  const AddOrderhandle = async (e) => {
    setBtnDisable(true);
    try {
      let SelctedPaymentMethod;
      switch (paymentLoading.radioSelect) {
        case "connectIps":
          SelctedPaymentMethod = "CONNECT_IPS";
          break;
        case "esewa":
          SelctedPaymentMethod = "ESEWA";
          break;
        case "card":
          SelctedPaymentMethod = "CARD_PAYMENT";
          break;

        default:
          SelctedPaymentMethod = "CASH_ON_DELIVERY";
          break;
      }
      setPageLoading(true);
      const res = await AxiosConfig.post(
        `/kreesa/order/${state.orderId}/update-payment`,
        {
          paymentMethod: SelctedPaymentMethod,
          transactionId: transaction_uuid,
          totalPayment: state.totalCost,
          paymentStatus: "unpaid",
        }
      ).then((res) => {
        setConnectTxn(res?.data?.id);

        // localStorage.getItem("auth") === "ROLE_USER"
        //   ? history.push("/")
        //   : history.push(
        //       `/${
        //         localStorage.getItem("auth") === "ROLE_WHOLESALER"
        //           ? "wholesaler"
        //           : "retailer"
        //       }/my-orders`
        //     );
        history.replace({ pathname: "/", state: [] });
        if (paymentLoading.radioSelect === "cash") {
          toast.success(`Payment Method Updated !`);

          // localStorage.getItem("auth") === "ROLE_USER"
          //   ? history.push("/manage-account?my-orders&success")
          //   : history.push(
          //       `/${
          //         localStorage.getItem("auth") === "ROLE_WHOLESALER"
          //           ? "wholesaler"
          //           : "retailer"
          //       }/my-orders`
          //     );
        }
        setBtnDisable(false);
        // setPageLoading(false);
      });
    } catch (err) {
      console.log(err);
      toast.error(`An Error Occurred !`);
      setBtnDisable(false);
      setPageLoading(false);
    }
  };

  Cookies.set("__connectIpsxnamt", txnamt);

  return pageLoading ? (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column justify-content-center"
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: addCartAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={200}
        width={200}
      />
      <h4 className="text-center font-weight-bold">
        Your Payment is being processed. Please Wait...
      </h4>
    </div>
  ) : (
    <div>
      {/*  --------------------------- visa or debit card start ---------------------------  */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          access_key: process.env.REACT_APP_CARD_ACCESS_KEY,
          profile_id: process.env.REACT_APP_CARD_PROFILE_ID,
          transaction_uuid: transitionUuid,
          signed_field_names:
            "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code",
          unsigned_field_names: "card_type,card_number,card_expiry_date",
          signed_date_time: times,
          locale: "en",
          transaction_type: "sale",
          reference_number: localStorage.getItem("userId"),
          amount: state.totalCost,
          currency: "NPR",
          payment_method: "card",
          bill_to_forename: "Kreesa",
          bill_to_surname: "Sales",
          bill_to_email: localStorage.getItem("username"),
          bill_to_phone: "",
          bill_to_address_line1: "NA",
          bill_to_address_city: "NA",
          bill_to_address_state: "Bagmati Province",
          bill_to_address_country: "NP",
          bill_to_address_postal_code: "Kathmandu",
        }}
        onSubmit={paymentHandler}
      >
        {({ handleChange, handleSubmit, values, touched, errors }) => (
          <Form onSubmit={handleSubmit} method="POST" id="frm-nicasia">
            <div className="form-group">
              <input
                type="hidden"
                name="access_key"
                value={values.access_key}
              />
              <input
                type="hidden"
                name="profile_id"
                value={values.profile_id}
              />
              <input
                type="hidden"
                name="transaction_uuid"
                value={values.transaction_uuid}
              />
              <input
                type="hidden"
                name="signed_field_names"
                value={values.signed_field_names}
              />
              <input
                type="hidden"
                name="unsigned_field_names"
                value={values.unsigned_field_names}
              />
              <input
                type="hidden"
                name="signed_date_time"
                value={values.signed_date_time}
              />
              <input type="hidden" name="locale" value={values.locale} />
              <input type="hidden" name="amount" value={values.amount} />
              <input
                type="hidden"
                name="bill_to_forename"
                value={values.bill_to_forename}
              />
              <input
                type="hidden"
                name="bill_to_surname"
                value={values.bill_to_surname}
              />
              <input
                type="hidden"
                name="bill_to_email"
                value={values.bill_to_email}
              />
              <input
                type="hidden"
                name="bill_to_phone"
                value={values.bill_to_phone}
              />
              <input
                type="hidden"
                name="bill_to_address_line1"
                value={values.bill_to_address_line1}
              />
              <input
                type="hidden"
                name="bill_to_address_city"
                value={values.bill_to_address_city}
              />
              <input
                type="hidden"
                name="bill_to_address_state"
                value={values.bill_to_address_state}
              />
              <input
                type="hidden"
                name="bill_to_address_country"
                value={values.bill_to_address_country}
              />
              <input
                type="hidden"
                name="bill_to_address_postal_code"
                value={values.bill_to_address_postal_code}
              />
              <input
                type="hidden"
                name="transaction_type"
                value={values.transaction_type}
              />
              <input
                type="hidden"
                name="reference_number"
                value={values.reference_number}
              />
              <input type="hidden" name="currency" value={values.currency} />
              <input
                type="hidden"
                name="payment_method"
                value={values.payment_method}
              />
              <input
                type="hidden"
                name="card_type"
                className="form-control"
                value={values.card_type}
              />
              <input
                type="hidden"
                name="card_number"
                className="form-control"
                value={values.card_number}
              />
              <input
                type="hidden"
                name="card_expiry_date"
                className="form-control"
                value={values.card_expiry_date}
              />
            </div>

            <button
              id="orderbtn"
              className="w-100 mt-4 btn btn-primary btn-lg"
              type="submit"
              ref={visaRef}
              style={{ display: "none" }}
            ></button>
          </Form>
        )}
      </Formik>
      {/*  --------------------------- visa or debit card end ---------------------------  */}

      {/*  --------------------------- connectIps start ---------------------------  */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          appId: process.env.REACT_APP_CONNET_APP_ID,
          appName: "Kreesa",
          merchantId: process.env.REACT_APP_CONNET_MERCHANT_ID,
          particulars: "PART-001",
          referenceId: "REF-" + transaction_uuid?.slice(0, 10),
          remarks: "RMKS-001",
          txnCrncy: "NPR",
          txnId: transaction_uuid?.slice(0, 18),
          txnamt: state.totalCost,
        }}
        onSubmit={connectIpsHandler}
      >
        {({ handleSubmit, values, submitForm }) => (
          <Form onLoad={submitForm} method="POST">
            <input
              onLoad={handleSubmit}
              type="hidden"
              name="merchantId"
              value={values.merchantId}
            />
            <input type="hidden" name="appId" value={values.appId} />
            <input type="hidden" name="appName" value={values.appName} />
            <input type="hidden" name="txnId" value={values.txnId} />
            <input type="hidden" name="txnCrncy" value={values.txnCrncy} />
            <input type="hidden" name="txnamt" value={values.txnamt} />

            <input
              type="hidden"
              name="referenceId"
              value={values.referenceId}
            />

            <input type="hidden" name="remarks" value={values.remarks} />

            <input
              type="hidden"
              name="particulars"
              value={values.particulars}
            />
            <button style={{ display: "none" }} ref={connectIpsRef}></button>
          </Form>
        )}
      </Formik>
      {/*  --------------------------- connectIps end ---------------------------  */}

      <div className="contentt">
        <div>
          <h4 className="text-center">Choose Your Preferred Payment Method</h4>
          <h5 className="text-center mt-3">
            Total Payable Amount : Rs. {state.totalCost}
          </h5>
          <div className="pay_option">
            <div
              onClick={() => {
                setPaymentLoading({ ...paymentLoading, radioSelect: "cash" });
              }}
              className={`pay_item ${
                paymentLoading.radioSelect === "cash" ? "active" : "inactive"
              }`}
            >
              <img src="/images/cashondelivery.webp" height="65px" />
              <label
                htmlFor="cash-in-hand"
                className="d-flex justify-content-between"
              >
                <p>Cash in hand</p>
                <input
                  style={{ display: "none" }}
                  checked={paymentLoading.radioSelect === "cash"}
                  type="radio"
                  name="checkout-type"
                  id="cash-in-hand"
                />
              </label>
            </div>

            <div
              onClick={(e) => {
                e.preventDefault();
                visaRef.current.click();
                setPaymentLoading({
                  ...paymentLoading,
                  radioSelect: "card",
                });
              }}
              className={`pay_item ${
                paymentLoading.radioSelect === "card" ? "active" : "inactive"
              }`}
            >
              <img src="/images/card.webp" height="65px" />
              <label
                htmlFor="cash-in-hand"
                className="d-flex justify-content-between"
              >
                <p>Debit / Credit Card</p>

                <input
                  style={{ display: "none" }}
                  checked={paymentLoading.radioSelect === "card"}
                  type="radio"
                  name="checkout-type"
                  id="card"
                />
              </label>
            </div>

            <div
              onClick={(e) => {
                e.preventDefault();
                setPaymentLoading({
                  ...paymentLoading,
                  radioSelect: "esewa",
                });
              }}
              className={`pay_item ${
                paymentLoading.radioSelect === "esewa" ? "active" : "inactive"
              }`}
            >
              <img src="/images/esewa.webp" height="65px" />
              <label
                htmlFor="cash-in-hand"
                className="d-flex justify-content-between"
              >
                <p>Pay via Esewa</p>
                <input
                  style={{ display: "none" }}
                  checked={paymentLoading.radioSelect === "esewa"}
                  type="radio"
                  name="checkout-type"
                  id="esewa"
                />
              </label>
            </div>

            <div
              onClick={(e) => {
                e.preventDefault();
                connectIpsRef.current.click();
                setPaymentLoading({
                  ...paymentLoading,
                  radioSelect: "connectIps",
                });
              }}
              className={`pay_item ${
                paymentLoading.radioSelect === "connectIps"
                  ? "active"
                  : "inactive"
              }`}
            >
              <img src="/images/connectips.webp" height="65px" />
              <label
                htmlFor="cash-in-hand"
                className="d-flex justify-content-between"
              >
                <p>Pay via Connect IPS</p>

                <input
                  style={{ display: "none" }}
                  checked={paymentLoading.radioSelect === "connectIps"}
                  type="radio"
                  name="checkout-type"
                  id="connectIps"
                />
              </label>
            </div>
          </div>

          <div className="pay_btn">
            {paymentLoading.radioSelect === "cash" && (
              <div className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end">
                <button
                  className={`btn btn-lg btn-primary `}
                  type="submit"
                  value="Submit"
                  onClick={() => AddOrderhandle()}
                >
                  Submit
                </button>
              </div>
            )}
            {paymentLoading.radioSelect === "connectIps" && (
              <form action={process.env.REACT_APP_CONNET_BASEURL} method="post">
                <input
                  type="hidden"
                  name="MERCHANTID"
                  id="MERCHANTID"
                  value={merchantId}
                />
                <input type="hidden" name="APPID" id="APPID" value={appId} />
                <input
                  type="hidden"
                  name="APPNAME"
                  id="APPNAME"
                  value={appName}
                />
                <input type="hidden" name="TXNID" id="TXNID" value={txnId} />
                <input
                  type="hidden"
                  name="TXNDATE"
                  id="TXNDATE"
                  value={txnDate}
                />
                <input
                  type="hidden"
                  name="TXNCRNCY"
                  id="TXNCRNCY"
                  value={txnCrncy}
                />
                <input type="hidden" name="TXNAMT" id="TXNAMT" value={txnamt} />

                <input
                  type="hidden"
                  name="REFERENCEID"
                  id="REFERENCEID"
                  value={referenceId}
                />

                <input
                  type="hidden"
                  name="REMARKS"
                  id="REMARKS"
                  value={remarks}
                />

                <input
                  type="hidden"
                  name="PARTICULARS"
                  id="PARTICULARS"
                  value={particulars}
                />

                <input
                  type="hidden"
                  name="TOKEN"
                  id="TOKEN"
                  value={connectIpsToken}
                />
                <div className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end">
                  <button
                    disabled={btnDisable}
                    className={`btn btn-lg  ${
                      paymentLoading.connectSignature === false
                        ? "btn-secondary"
                        : "btn-primary"
                    }`}
                    type="submit"
                    value="Submit"
                    onClick={() => pseudoConnectRef.current.click()}
                    // onClick={() => AddOrderhandle()}
                  >
                    {paymentLoading.connectSignature === false
                      ? "Please wait ..."
                      : "Submit"}
                  </button>
                  <button
                    ref={pseudoConnectRef}
                    onClick={() => AddOrderhandle()}
                    style={{ display: "none" }}
                  />
                </div>
              </form>
            )}
            {paymentLoading.radioSelect === "esewa" && (
              <form
                encType="multipart/form-data"
                action={process.env.REACT_APP_ESEWA_BASEURL}
                method="POST"
              >
                <input
                  value={Math.round(state.totalCost)}
                  name="tAmt"
                  type="hidden"
                />
                <input
                  value={Math.round(state.totalCost)}
                  name="amt"
                  type="hidden"
                />
                <input value="0" name="txAmt" type="hidden" />
                <input value="0" name="psc" type="hidden" />
                <input value="0" name="pdc" type="hidden" />
                <input
                  value={process.env.REACT_APP_ESEWA_SCD}
                  name="scd"
                  type="hidden"
                />
                <input value={transitionUuid} name="pid" type="hidden" />
                <input
                  value={`${window.location.origin}/payment-success/esewa?q=su`}
                  type="hidden"
                  name="su"
                />
                <input
                  value={`${window.location.origin}`}
                  type="hidden"
                  name="fu"
                />
                <div className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end">
                  <button
                    disabled={btnDisable}
                    className="btn btn-lg btn-primary"
                    type="submit"
                    value="Submit"
                    onClick={() => pseudoEsewaRef.current.click()}
                  >
                    Submit
                  </button>
                  <button
                    ref={pseudoEsewaRef}
                    onClick={() => AddOrderhandle()}
                    style={{ display: "none" }}
                  />
                </div>
              </form>
            )}
            {paymentLoading.radioSelect === "card" && (
              <form
                className="col-11 mt-3 d-flex flex-column align-items-end justify-content-end"
                id="frm-nicasia"
                action={process.env.REACT_APP_CARD_BASEURL}
                method="post"
              >
                <div className="form-group">
                  <input type="hidden" name="access_key" value={access_key} />
                  <input type="hidden" name="profile_id" value={profile_id} />
                  <input
                    type="hidden"
                    name="transaction_uuid"
                    value={transaction_uuid}
                  />
                  <input
                    type="hidden"
                    name="signed_field_names"
                    value={signed_field_names}
                  />
                  <input
                    type="hidden"
                    name="unsigned_field_names"
                    value={unsigned_field_names}
                  />
                  <input
                    type="hidden"
                    name="signed_date_time"
                    value={signed_date_time}
                  />
                  <input type="hidden" name="locale" value={locale} />
                  <input type="hidden" name="amount" value={amount} />
                  <input
                    type="hidden"
                    name="bill_to_forename"
                    value={bill_to_forename}
                  />
                  <input
                    type="hidden"
                    name="bill_to_surname"
                    value={bill_to_surname}
                  />
                  <input
                    type="hidden"
                    name="bill_to_email"
                    value={bill_to_email}
                  />
                  <input
                    type="hidden"
                    name="bill_to_phone"
                    value={bill_to_phone}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_line1"
                    value={bill_to_address_line1}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_city"
                    value={bill_to_address_city}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_state"
                    value={bill_to_address_state}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_country"
                    value={bill_to_address_country}
                  />
                  <input
                    type="hidden"
                    name="bill_to_address_postal_code"
                    value={bill_to_address_postal_code}
                  />
                  <input
                    type="hidden"
                    name="transaction_type"
                    value={transaction_type}
                  />
                  <input
                    type="hidden"
                    name="reference_number"
                    value={reference_number}
                  />
                  <input type="hidden" name="currency" value={currency} />
                  <input
                    type="hidden"
                    name="payment_method"
                    value={payment_method}
                  />
                  <input type="hidden" name="signature" value={signature} />
                  <input
                    type="hidden"
                    name="card_type"
                    className="form-control"
                    value="001"
                  />
                  <input
                    type="hidden"
                    name="card_number"
                    className="form-control"
                    value=""
                  />
                  <input
                    type="hidden"
                    name="card_expiry_date"
                    className="form-control"
                    value=""
                  />
                </div>
                <button
                  disabled={btnDisable}
                  className={`btn btn-lg  ${
                    paymentLoading.cardSignature === false
                      ? "btn-secondary"
                      : "btn-primary"
                  }`}
                  type="submit"
                  value="Submit"
                  onClick={() => pseudoCardRef.current.click()}
                  // onClick={() => AddOrderhandle()}
                >
                  {paymentLoading.cardSignature === false
                    ? "Please wait ..."
                    : "Submit"}
                </button>
                <button
                  ref={pseudoCardRef}
                  onClick={() => AddOrderhandle()}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayNow;
