import React from "react";
import { useLocation } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import HtmlParser from "react-html-parser";
import ModalImage from "react-modal-image";
import { saveFile } from "./AppliedVacanciesDetail";

const SingleVacancy = ({ id }) => {
  const location = useLocation();
  const { data } = location.state;

  return (
    <div className="container">
      <div className="col-12 col-lg-8 d-flex w-100">
        <div className="card flex-fill overflow-auto">
          <div className="card-header">
            <p className="h4 mb-0 text-capitalize">
              Vacancy applied for{" "}
              <span className="font-weight-bold">{data.vacancy?.title}</span> by{" "}
              <span className="font-weight-bold">{data?.name}</span>{" "}
            </p>
            {/*  */}
            <div className="row mt-4">
              <div className="col-md-6">
                <h5 style={{ textDecoration: "underline" }}>
                  Applicant Information
                </h5>
                <h6 className="mt-3">Name : {data?.name}</h6>
                <h6 className="mt-3">Contact : {data?.contact}</h6>
                <h6 className="mt-3">Email : {data?.email}</h6>
                <div className="mt-3 mb-2">
                  <h6 className="mb-2">Photo (pp size) :</h6>
                  <ModalImage
                    alt={data?.userImg}
                    small={`${AxiosConfig.defaults.baseURL}/kreesa/image/applicant/${data?.id}/${data?.userImg}`}
                    large={`${AxiosConfig.defaults.baseURL}/kreesa/image/applicant/${data?.id}/${data?.userImg}`}
                    className="img-fluid quote__modal__img"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                    loading="lazy"
                    showRotate={true}
                    hideDownload={true}
                  />
                </div>
                <div className="">
                  <h6 className="mb-2">{data?.identityType} :</h6>

                  <ModalImage
                    alt={data?.identityDoc}
                    small={`${AxiosConfig.defaults.baseURL}/kreesa/image/identity/${data?.id}/${data?.identityDoc}`}
                    large={`${AxiosConfig.defaults.baseURL}/kreesa/image/identity/${data?.id}/${data?.identityDoc}`}
                    className="img-fluid quote__modal__img"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                    loading="lazy"
                    showRotate={true}
                    hideDownload={true}
                  />
                </div>
                <h6 className="mt-3">Resume</h6>
                <p
                  style={{
                    color: "blue",
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  onClick={() => saveFile(data?.id, data?.resume)}
                >
                  {data?.resume}
                  <i className="fa fa-download cursor-pointer"></i>
                </p>
              </div>
              <hr className="d-md-none mt-3" />

              <div className="col-md-6">
                <h5 style={{ textDecoration: "underline" }}>Applied For</h5>
                <h6 className="mt-3">Title : {data?.vacancy?.title}</h6>
                <h6 className="mt-3">Type : {data?.vacancy?.jobType}</h6>
                <h6 className="mt-3">
                  Total Vacancy :{data?.vacancy?.openVacancies}
                </h6>
                <h6 className="mt-3">Requirement : </h6>{" "}
                {HtmlParser(data?.vacancy?.requirement)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVacancy;
