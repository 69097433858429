import React from "react";
import { Container, Image } from "react-bootstrap";
import TreeImage from "../../../assets/Images/tree.jpg";
import { Link } from "react-router-dom";

const TreeNotification = () => {
  document.title = "Tree Notification";
  const homeLink = localStorage.getItem("auth") === "ROLE_USER" ? "/" : "/home";

  return (
    <Container className="d-flex flex-column align-items-center p-2">
      <Image src={TreeImage} alt="Tree" fluid height={350} width={350} />
      <h5 className="mt-3" style={{ color: "green" }}>
        Congratulation, {localStorage.getItem("username")}
      </h5>
      <p style={{ fontStyle: "italic" }}>
        Kreesa and you combined contribution to make a world green.
      </p>
      <p>
        Your future purchase 0.01% will be allocated to take case of your tree
        planted on your name. So, we expect regularity to keep your tree green.
      </p>
      <Link to={homeLink}>
        <button
          type="button"
          className="text-uppercase btn btn-success mt-4 py-2 px-3 btn-success"
          style={{ fontWeight: "bold" }}
        >
          <i className="fas fa-home"></i> Take Me Home
        </button>
      </Link>
    </Container>
  );
};

export default TreeNotification;
