import React, { useState } from "react";
import { Excerpt } from "../../../utils";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getSingleWholesalerProducts } from "../Features/Action/WholesalerAction";
import SellerQuickViewButton from "./SellerQuickViewButton";

const SellerProductGrid = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [viewMore, setViewMore] = useState(12);
  const [loading, setLoading] = useState(false);

  return (
    <div className="container-fluid bg-white px-lg-6">
      <div className="row pt-3">
        <div className="col-8">
          <h4>{props?.title}</h4>
        </div>
      </div>
      <hr />
      <div
        className="row p-1"
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        {props?.data?.slice(0, viewMore)?.map((product) => (
          <div
            className="col-6 col-md-3 col-lg-2 text-center grid_gap__product_"
            key={product?.id}
          >
            <div className="card m-0 p-0">
              <Link
                to={{
                  pathname:
                    history?.location?.state?.prevPath === "/custom-order" ||
                    history.location.pathname.includes("/custom-order")
                      ? `/custom-order/product/${product.id}`
                      : `/single-product-page/${product.id}`,
                  key: product?.id,
                  id: product?.id,
                  slectImage:
                    AxiosConfig.defaults.baseURL +
                    "/kreesa/image/product/" +
                    product?.image?.id +
                    "/" +
                    product?.image?.image,
                }}
              >
                <img
                  className={`card-img-top p-2 img-fluid w-100 ${
                    product?.specification?.stock < 1 && "blur-image"
                  }`}
                  alt={product?.image?.imageName}
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                  srcSet={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                  style={{
                    height: 160,
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
                {product?.specification?.stock < 1 && (
                  <div
                    className="btn btn-danger"
                    style={{
                      position: "absolute",
                      borderRadius: 20,
                      top: "30%",
                      right: "32%",
                    }}
                  >
                    Sold out
                  </div>
                )}
              </Link>

              <div className="card-body p-1" title={product?.productName}>
                <Link
                  to={{
                    pathname:
                      history?.location?.state?.prevPath === "/custom-order" ||
                      history.location.pathname.includes("/custom-order")
                        ? `/custom-order/product/${product.id}`
                        : `/single-product-page/${product.id}`,
                    state: {
                      prevPath: history.location.pathname,
                    },
                  }}
                >
                  <h6 className="card-title text-capitalize">
                    {Excerpt(product?.productName, 15)}
                  </h6>
                </Link>
                <div
                  onClick={() =>
                    dispatch(getSingleWholesalerProducts(product?.id))
                  }
                  className="card-link "
                >
                  <SellerQuickViewButton
                    image={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                    review={product?.review}
                    description={product?.productHighlight}
                  />
                </div>
                <p style={{ color: "black", fontWeight: "600" }}>
                  Rs {product.specification?.productPrice}
                </p>
                {product?.specification?.productDiscount !== 0 && (
                  <p
                    className="card-subtitle"
                    style={{
                      color: "red",
                      fontSize: 14,
                      marginTop: "0.6em",
                    }}
                  >
                    - {product?.specification?.productDiscount} % OFF
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center p-4">
        <button
          type="button"
          className="btn px-5 py-2"
          style={{
            backgroundColor: "#e2e6eb",
            fontWeight: 600,
            borderRadius: 8,
          }}
          onClick={(e) => {
            e.preventDefault();
            if (props?.data.length > 0 && viewMore < props?.data.length) {
              setLoading(true);
              setTimeout(() => {
                setViewMore(viewMore + 6);
                setLoading(false);
              }, 1200);
            } else {
              setLoading(false);
              toast.info("No more products to show !");
            }
          }}
          disabled={loading && props?.data.length > 0 ? true : false}
        >
          {loading ? (
            <>
              LOADING... <i class="fas fa-spinner fa-spin"></i>
            </>
          ) : (
            <>
              VIEW MORE <i className="fa fa-chevron-circle-down"></i>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default SellerProductGrid;
