import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../Pagination";
import Spinner from "../Spinner";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import {
  applicationStatus,
  getAllApplications,
} from "../Features/Action/AdminBlockUnblockAction";
import { filterAppliedVaccencies } from "../Features/Reducer/AdminReducer";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const saveFile = (id, resume) => {
  FileSaver.saveAs(
    `${AxiosConfig.defaults.baseURL}/kreesa/document/resume/${id}/${resume}`,
    resume
  );
};

const AppliedVacanciesDetail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();
  const inputRef = useRef("");

  // to get all applications
  const { applications, loader } = useSelector((s) => s.AdminReducer);

  useEffect(() => {
    dispatch(getAllApplications());
  }, []);

  // filter
  const handlefilterVacancy = () => {
    dispatch(filterAppliedVaccencies(inputRef.current.value));
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = applications?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(applications?.length / perPage);

  return !loader ? (
    <div className="container">
      <h4>Applied Vacancies</h4>
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Applied Vacancy"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={handlefilterVacancy}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row mt-3">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Applicant Details</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Applicant Name</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th className="d-md-table-cell">Position</th>
                    <th className="d-xl-table-cell">Resume</th>
                    <th className="d-xl-table-cell">Status</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>{p.name}</td>
                      <td>
                        <a href={`mailto:${p.email}`}>{p.email}</a>
                      </td>
                      <td>{p.contact}</td>
                      <td>{p.vacancy.title}</td>
                      {p.resume === null ? (
                        <td className="d-md-table-cell">No resume uploaded</td>
                      ) : (
                        <td className="d-md-table-cell">
                          <div
                            className="cv"
                            style={{
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={() => saveFile(p.id, p.resume)}
                          >
                            {p.resume} <i className="fa fa-download"></i>
                          </div>
                        </td>
                      )}
                      <td>
                        <span
                          className={`badge ${
                            p.status === "REJECTED"
                              ? "bg-danger"
                              : p.status === "PENDING"
                              ? "bg-info"
                              : p.status === "INTERVIEWING"
                              ? "bg-primary"
                              : "bg-success"
                          }`}
                        >
                          {p?.status[0]?.toUpperCase() +
                            p?.status?.substring(1).toLowerCase()}
                        </span>
                      </td>

                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              to={{
                                pathname: `/admin/applied-vacancies/${p.id}`,
                                state: {
                                  data: p,
                                },
                              }}
                              className="dropdown-item text-info cursor-pointer"
                              // onClick={() =>
                              //   setModalData({ show: true, data: p })
                              // }
                            >
                              <i className="fas fa-eye"></i> View
                            </Link>
                            {p.status === "PENDING" && (
                              <>
                                <div className="dropdown-divider" />
                                <div
                                  className="dropdown-item text-primary cursor-pointer"
                                  onClick={() =>
                                    dispatch(
                                      applicationStatus({
                                        id: p.id,
                                        status: "INTERVIEWING",
                                        toast: toast,
                                      })
                                    )
                                  }
                                >
                                  <i className="fa fa-podcast"></i> Interviewing
                                </div>
                                <div className="dropdown-divider" />
                                <div
                                  className="dropdown-item text-danger cursor-pointer"
                                  onClick={() =>
                                    dispatch(
                                      applicationStatus({
                                        id: p.id,
                                        status: "REJECTED",
                                        toast: toast,
                                      })
                                    )
                                  }
                                >
                                  <i className="fa fa-ban"></i> Reject
                                </div>
                              </>
                            )}
                            {p.status === "ACCEPTED" && (
                              <>
                                <div className="dropdown-divider" />
                                <div
                                  className="dropdown-item text-danger cursor-pointer"
                                  onClick={() =>
                                    dispatch(
                                      applicationStatus({
                                        id: p.id,
                                        status: "DELETED",
                                        toast: toast,
                                      })
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"></i> Delete
                                </div>
                              </>
                            )}
                            {p.status === "INTERVIEWING" && (
                              <>
                                <div className="dropdown-divider" />
                                <div
                                  className="dropdown-item text-success cursor-pointer"
                                  onClick={() =>
                                    dispatch(
                                      applicationStatus({
                                        id: p.id,
                                        status: "ACCEPTED",
                                        toast: toast,
                                      })
                                    )
                                  }
                                >
                                  <i className="fa fa-check"></i> Accept
                                </div>
                                <div className="dropdown-divider" />
                                <div
                                  className="dropdown-item text-danger cursor-pointer"
                                  onClick={() =>
                                    dispatch(
                                      applicationStatus({
                                        id: p.id,
                                        status: "REJECTED",
                                        toast: toast,
                                      })
                                    )
                                  }
                                >
                                  <i className="fa fa-ban"></i> Reject
                                </div>
                              </>
                            )}
                            {p.status === "REJECTED" && (
                              <>
                                <div className="dropdown-divider" />
                                <div
                                  className="dropdown-item text-danger cursor-pointer"
                                  onClick={() =>
                                    dispatch(
                                      applicationStatus({
                                        id: p.id,
                                        status: "DELETED",
                                        toast: toast,
                                      })
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"></i> Delete
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={applications}
          />
        </div>
      ) : (
        <div className="row">
          <h4 className="text-center">No Applications Found !!</h4>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default AppliedVacanciesDetail;
