import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getProductsOfRetailer, getProductsOfWholesaler } from "..";
import AxiosConfig from "../../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to publish Product Of Wholesale in Admin Page*/
export const publishProductStatus = createAsyncThunk(
  "publishProductStatus",
  async (data, { dispatch }) => {
    // publishProductStatus/pending
    // publishProductStatus/fulfilled
    // publishProductStatus/rejected
    const response = await AxiosConfig.get(
      `/kreesa/admin/product/publish/${data.id}`,
      config
    );
    toast.success("Product has been Published");
    data.type === "retail"
      ? dispatch(getProductsOfRetailer())
      : dispatch(getProductsOfWholesaler());
    return response.data;
  }
);

/* to remove feature product */
export const approveAndRejectProductStatus = createAsyncThunk(
  "admins/approveAndRejectProductStatus",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosConfig.get(
        `/kreesa/admin/product/update-status/${id}`,
        config
      );
      toast.success(response.data.msg);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
