import moment from "moment";
import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getAllUserDetails } from "../../../Common/Features/Action/AllUserDetailsAction";
import {
  getAdminNotification,
  getTotalNotification,
  MarkAsReadForAdmin,
} from "../Features/Action/AdminNotificationAction";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import logo from "../../../assets/Images/kreesalogo.png";
import decode from "jwt-decode";
import ReactLinkify from "react-linkify";

const TopNavbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // to get TotalNotifications
  const { Notification } = useSelector((s) => s.AdminNotificationReducer);

  // to get total notification
  const { TotalNotification } = useSelector(
    (s) => s.GetTotalNumberOfNotification
  );

  // to get all user Details by their relavent email
  const { userDetails } = useSelector((s) => s.UserDetailsReducer);

  // token expire time
  useLayoutEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logOut();
    }
  }, [localStorage.getItem("token")]);

  // logout function
  const logOut = () => {
    localStorage.clear();
  };

  useEffect(() => {
    dispatch(getAdminNotification(10));
    dispatch(getTotalNotification());
    dispatch(getAllUserDetails(localStorage.getItem("userId")));
  }, []);

  return (
    <nav className="navbar navbar-expand navbar-light navbar-bg">
      <a
        href="#sidebar"
        role="button"
        aria-controls="multiCollapseExample1"
        className="sidebar-toggle d-flex"
        id="sidebar-toggle"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <i className="hamburger align-self-center"></i>
      </a>

      {/* <form className="d-none d-sm-inline-block">
        <div className="input-group input-group-navbar">
          <input
            type="text"
            className="form-control"
            placeholder="Search…"
            aria-label="Search"
          />
          <button className="btn" type="button">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </form> */}

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          <li className="nav-item dropdown">
            <a
              className="nav-icon dropdown-toggle"
              href
              id="alertsDropdown"
              data-toggle="dropdown"
            >
              <div className="position-relative">
                <i className="fal fa-bells" data-feather="bell"></i>
                <span
                  className={`${TotalNotification == 0 ? "" : "indicator"}`}
                >
                  {TotalNotification == 0
                    ? ""
                    : TotalNotification > 9
                    ? "9+"
                    : TotalNotification.toString()}
                </span>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0"
              aria-labelledby="alertsDropdown"
            >
              <div className="dropdown-menu-header">
                {TotalNotification == 0
                  ? ""
                  : `${TotalNotification} Notifications`}
              </div>
              <div
                className="list-group"
                style={{ height: "500px", overflow: "auto" }}
              >
                {Notification?.map((p, i) => (
                  <a
                    href
                    className="sm-hide list-group-item"
                    key={i}
                    onClick={async () => {
                      await dispatch(MarkAsReadForAdmin(p.id));
                      if (p?.route) {
                        history.push(p?.route);
                        history.go();
                      }
                    }}
                  >
                    <div className="row g-0 align-items-center">
                      <div className="col-2">
                        <div style={{ display: "flex" }}>
                          <i
                            className="d-none d-sm-inline-block fas fa-bell text-info"
                            style={{ fontSize: "25px" }}
                          ></i>
                        </div>
                      </div>
                      <div className="col-10">
                        {/* <div className="text-dark">Lorem ipsum</div> */}
                        <div className="text-muted small mt-1">
                          <ReactLinkify
                            componentDecorator={(
                              decoratedHref,
                              decoratedText,
                              key
                            ) => (
                              <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                              </a>
                            )}
                          >
                            {p.notificationMessage}
                          </ReactLinkify>
                        </div>
                        <div className="font-weight-bold small mt-1">
                          {/* {moment(p.creationDate).endOf("day").fromNow()}
                          <span style={{ color: "grey" }}>
                            {`  (${moment(p.creationDate).format(
                              "DD MMM, YYYY"
                            )})`}
                          </span> */}

                          {moment(p.creationDate).local().format("lll")}
                        </div>

                        <div className="text-dark mt-1">
                          {!p.readFlag && (
                            <i className="fa fa-eye text-success font-weight-bold"></i>
                          )}
                          <span className="text-muted">
                            {" "}
                            {!p.readFlag ? "(Unseen)" : "(seen)"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
              <div className="dropdown-menu-footer">
                <Link to="/admin/all-notification">Show all notifications</Link>
              </div>
            </div>
          </li>
          {/* <li className="nav-item dropdown">
                        <a
                            className="nav-icon dropdown-toggle"
                            href
                            id="messagesDropdown"
                            data-toggle="dropdown"
                        >
                            <div className="position-relative">
                                <i className="far fa-comment-alt" data-feather="message-square"></i>
                                <span className="indicator">2</span>
                            </div>
                        </a>
                        <div
                            className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0"
                            aria-labelledby="messagesDropdown"
                        >
                            <div className="dropdown-menu-header">
                                <div className="position-relative">2 New Messages</div>
                            </div>
                            <div className="list-group">
                                <a href className="list-group-item">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2">
                                            <img
                                                src="img/avatars/avatar-5.jpg"
                                                className="avatar img-fluid rounded-circle"
                                                alt="Vanessa Tucker"
                                            />
                                        </div>
                                        <div className="col-10 pl-2">
                                            <div className="text-dark">Vanessa Tucker</div>
                                            <div className="text-muted small mt-1">
                                                Nam pretium turpis et arcu. Duis arcu tortor.
                                            </div>
                                            <div className="text-muted small mt-1">15m ago</div>
                                        </div>
                                    </div>
                                </a>
                                <a href className="list-group-item">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2">
                                            <img
                                                src="img/avatars/avatar-3.jpg"
                                                className="avatar img-fluid rounded-circle"
                                                alt="Sharon Lessman"
                                            />
                                        </div>
                                        <div className="col-10 pl-2">
                                            <div className="text-dark">Sharon Lessman</div>
                                            <div className="text-muted small mt-1">
                                                Aenean tellus metus, bibendum sed, posuere ac,
                                                mattis non.
                                            </div>
                                            <div className="text-muted small mt-1">5h ago</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="dropdown-menu-footer">
                                <a href className="text-muted">Show all messages</a>
                            </div>
                        </div>
                    </li>  */}
          <li className="nav-item dropdown">
            <a className="nav-icon dropdown-toggle" href data-toggle="dropdown">
              <i className="align-middle" data-feather="settings"></i>
            </a>

            <a
              className="nav-link dropdown-toggle d-sm-inline-block"
              href
              data-toggle="dropdown"
            >
              {userDetails.profile === null ? (
                <img
                  src={logo}
                  className="avatar img-fluid rounded mr-1"
                  alt=""
                />
              ) : (
                <img
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/admin/${userDetails.userId}/${userDetails.profile}`}
                  className="avatar img-fluid rounded mr-1"
                  alt={userDetails.firstname}
                />
              )}
              <span className="text-dark text-capitalize">
                {userDetails.firstname} {userDetails.lastname}
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link className="dropdown-item" to="/admin/profile">
                <i className="fas fa-user-alt"></i>
                <span className="ml-1"> Profile</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/admin/profile-settings">
                <i className="fas fa-cog"></i>
                <span className="ml-1"> Settings & Privacy</span>
              </Link>
              <Link className="dropdown-item" to="/admin/messages">
                <i className="fas fa-info-circle"></i>
                <span className="ml-1"> Help Center</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link
                className="dropdown-item"
                to="/admin/login"
                onClick={logOut}
              >
                <i className="fas fa-sign-out-alt"></i>
                <span className="ml-1">Log out</span>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopNavbar;
