import React from "react";
import Footer from "../Footer";
import TopNavbar from "../TopNavbar";
import LeftNavbar from "../LeftNavbar/";
import ReviewDetails from "./ReviewDetails";
import { Redirect, useParams } from "react-router-dom";
import SingleReview from "./SingleReview";

const Reviews = () => {
  document.title = "Admin | Reviews";
  const { id } = useParams();

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            {id ? <SingleReview {...{ id }} /> : <ReviewDetails />}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Reviews;
