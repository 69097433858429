import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mantine/core";
import { fetchImplementedTree } from "../Features/Action/TreeAction";
import Spinner from "../Spinner";
import moment from "moment";
import { filterImplemented } from "../Features/Reducer/TreeReducer";

const Implemented = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const { loader, implemented } = useSelector((s) => s.TreeReducer);
  const dispatch = useDispatch();

  const inputRef = useRef("");

  useEffect(() => {
    dispatch(fetchImplementedTree());
  }, [dispatch]);

  // Search
  const filterImplementedHandler = () => {
    dispatch(filterImplemented(inputRef.current.value));
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = implemented?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(implemented?.length / perPage);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Implemented Tree..."
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterImplementedHandler}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="row">
        {currentEntries?.length > 0 ? (
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">All Implemented Trees</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Address Plan</th>
                    <th className="d-xl-table-cell">Plantation Date</th>
                    <th className="d-xl-table-cell">Username</th>
                    <th className="d-xl-table-cell">Latitude Longitude</th>
                    <th className="d-xl-table-cell">Plant Type</th>
                    {/* <th className="d-md-table-cell">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{p.address_of_plant}</td>
                      <td>{moment(p.date_of_plantation).format("ll")}</td>
                      <td>
                        <a
                          href={`mailto:${p.unImplementTreeDetails.user?.email}`}
                        >
                          {p.unImplementTreeDetails.user?.email}
                        </a>
                      </td>
                      <td>{p.latitude_longitude}</td>
                      <td>{p.type_of_plant}</td>
                      {/* <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <button
                              onClick={() => setModalId(`modal${i}`)}
                              className="dropdown-item text-success"
                            >
                              <i className="fa fa-eye"></i> Quick View
                            </button>
                            {c.reviewApproval === false && (
                              <>
                                <div className="dropdown-divider" />
                                <p
                                  onClick={async () => {
                                    await dispatch(ReviewApprove(c.reviewId));
                                    dispatch(getallUserReview());
                                    toast.success("Review has been approved");
                                  }}
                                  className="dropdown-item text-info cursor-pointer"
                                >
                                  <i className="fa fa-check"></i> Approve
                                </p>
                              </>
                            )}
                          </div>
                          <Modal
                            show={modalId === `modal${i}`}
                            onHide={handleClose}
                            aria-labelledby="contained-modal-title-vcenter"
                          >
                            <Modal.Header>
                              <Modal.Title>
                                {c?.productName + "'s Review"} by {c.firstName}{" "}
                                {c.lastName}
                                <Star value={c.totalStar} />
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{c?.review}</Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div> 
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3>No Implemented Tree Found !</h3>
          </div>
        )}
        {/* pagination  */}
        {currentEntries?.length > 0 && (
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Implemented;
