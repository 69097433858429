import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteBlog,
  getAllBlogForAdmin,
} from "../../Features/Action/AdminBlockUnblockAction";
import Spinner from "../../Spinner";
// import Pagination from "../../../Pagination";
import { Pagination } from "@mantine/core";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { filterBlogs } from "../../Features/Reducer/AdminReducer";

const NewsBlogNoticeDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const { blogs, loader } = useSelector((s) => s.AdminReducer);

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    dispatch(getAllBlogForAdmin());
  }, []);

  // filter blogs
  const filterBlogsHandler = () => {
    dispatch(filterBlogs(inputRef.current.value));
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = blogs?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(blogs?.length / perPage);
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link
                to="/admin/add/news-blog-notice"
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add News/Blog/Notice <i className="fab fa-blogger-b ml-2"></i>
              </Link>
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search News/Blog/Notice …"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterBlogsHandler}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Blogs</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Blog Type</th>
                    <th className="d-xl-table-cell">Created By</th>
                    <th className="d-md-table-cell">Modified by</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${p.id}/${p.image}`}
                          width="48"
                          height="48"
                          loading="lazy"
                          className="rounded-circle mr-2"
                          alt={p.title}
                        />
                      </td>
                      <td className="text-capitalize">{p.title}</td>
                      <td>
                        <span
                          className={`badge ${
                            p.blogType === "NEWS"
                              ? "bg-info"
                              : "" || p.blogType === "BLOG"
                              ? "bg-success"
                              : "" || p.blogType === "NOTICE"
                              ? "bg-danger"
                              : ""
                          } p-2`}
                        >
                          {p.blogType}
                        </span>
                      </td>
                      <td className="d-xl-table-cell">
                        <a href={`mailto:${p.createdBy}`}>{p.createdBy}</a>
                      </td>
                      <td className="d-md-table-cell">
                        <a href={`mailto:${p.modifiedBy}`}>{p.modifiedBy}</a>
                      </td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              className="dropdown-item text-success"
                              to={`/admin/show/news-blog-notice/${p.id}`}
                            >
                              <i className="fa fa-eye"></i> Review
                            </Link>

                            <div className="dropdown-divider"></div>
                            <Link
                              className="dropdown-item text-info"
                              to={`/admin/edit/news-blog-notice/${p.id}`}
                            >
                              <i className="fas fa-user-edit"></i> Edit
                            </Link>
                            <div className="dropdown-divider"></div>
                            <button
                              className="dropdown-item text-danger"
                              onClick={() => dispatch(deleteBlog(p.id))}
                            >
                              <i className="fas fa-trash-alt"></i> Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          {/* <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={blogs}
          /> */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Blogs Found !</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default NewsBlogNoticeDetails;
