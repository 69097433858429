import { useEffect, useState } from "react";
import "./Basket.css";
import Header from "../Header/Header";
import Search from "../Search/Search";
import Footer from "../UserFooter";
import Navbar from "../NavBar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  removeCartRetailItem,
  updateCartRetailItem,
  viewAllRetailCart,
} from "../Features/action";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";

const Basket = (props) => {
  document.title = "Kreesa | Basket";
  const { retailCart, loader } = useSelector((state) => state.userProducts);
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = localStorage.getItem("userId");

  const [bill, setBill] = useState();

  const [cart, setCart] = useState();
  const [checkAll, setCheckAll] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const [customOrder, setCustomOrder] = useState();

  const onItemSelect = (item, type) => {
    if (type === "all") {
      setSelectedItem((state) => ({
        ...state,
        [item.id]: !checkAll
          ? {
              cartId: item.id,
              colorId: item.colorId,
              orderQuantity: item.quantity,
              specificationId: item.product.specificationId,
              name: item.product.productName,
              price: item.product.productPrice,
              discountPrice:
                item.product.productPrice -
                (item.product.productPrice * item.product.productDiscount) /
                  100,
              discountedTotalPrice:
                (item.product.productPrice -
                  (item.product.productPrice * item.product.productDiscount) /
                    100) *
                item.quantity,
              discount: item.product.productPrice,
            }
          : null,
      }));
    } else {
      setSelectedItem((state) => ({
        ...state,
        [item.id]: state[item.id]
          ? null
          : {
              cartId: item.id,
              colorId: item.colorId,
              orderQuantity: item.quantity,
              specificationId: item.product.specificationId,
              name: item.product.productName,
              price: item.product.productPrice,
              discountPrice:
                item.product.productPrice -
                (item.product.productPrice * item.product.productDiscount) /
                  100,
              discountedTotalPrice:
                (item.product.productPrice -
                  (item.product.productPrice * item.product.productDiscount) /
                    100) *
                item.quantity,
              discount: item.product.productDiscount,
            },
      }));
      Object.values(selectedItem).includes(null)
        ? setCheckAll(false)
        : setCheckAll(true);

      Object.values(selectedItem).filter(Boolean).length + 1 ===
      retailCart?.length
        ? setCheckAll(true)
        : setCheckAll(false);
    }
  };

  useEffect(() => {
    if (
      location?.state?.prevPath === "/custom-order" ||
      location?.pathname === "/custom-order"
    ) {
      if (JSON.parse(localStorage.getItem("customOrder")) !== null) {
        setCustomOrder(JSON.parse(localStorage.getItem("customOrder")));
        dispatch(viewAllRetailCart(customOrder?.userId));
      }
    } else {
      dispatch(viewAllRetailCart(localStorage.getItem("userId")));
      setCustomOrder();
    }
  }, []);

  useEffect(() => {
    //total and quantity
    if (retailCart !== undefined) {
      setCart(retailCart);

      //quantity
      //total
      let total = 0;
      retailCart?.forEach((item) => {
        total =
          total +
          (item.product.productPrice -
            (item.product.productPrice * item.product.productDiscount) / 100) *
            item?.quantity;
      });
      setBill(total);
    }
  }, [retailCart]);

  return (
    <div>
      <Search customdata={location?.state} {...props} />
      <Navbar customdata={location?.state} {...props} />
      {retailCart?.length === 0 ? (
        <div>
          <div className="container-fluid mt-6">
            <div className="row">
              <aside className="col-lg-9 mt-6">
                <div className="card cdet">
                  <div className="table-responsive">
                    <table className="table table-borderless table-shopping-cart">
                      <thead className="text-muted">
                        <tr className="small text-uppercase">
                          <th scope="col"></th>
                          <th scope="col">Product</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                          <th scope="col">Total Price</th>
                          <th
                            scope="col"
                            className="text-right d-none d-md-block"
                            width="200"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="d-flex "
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  <i
                    className="fas fa-cart-arrow-down"
                    style={{ fontSize: "112px" }}
                  ></i>
                  <p
                    className=" text-uppercase mt-4"
                    style={{ fontSize: "21px" }}
                  >
                    Your Kreesa Basket is empty
                  </p>
                </div>

                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <p className="ml-2 pt-0 mt-0" style={{ fontSize: "18px" }}>
                    Click Here for?{" "}
                  </p>
                  <Link to="/" className="ml-2 text-info mt-1" data-abc="true">
                    Shopping
                  </Link>
                </div>
              </aside>

              <aside className="col-lg-3 total-card">
                <div className="card">
                  <div className="card-body">
                    <dl className="dlist-align">
                      <dt>Total Items:</dt>
                      <dd className="text-right ml-3"></dd>
                    </dl>
                    <dl className="dlist-align">
                      <dt>Total:</dt>
                      <dd className="text-right text-dark b ml-3"></dd>
                    </dl>
                    <hr />{" "}
                    <Link
                      className="btn btn-out btn-primary btn-main disabled"
                      data-abc="true"
                      to={
                        {
                          // pathname: selected && "/order-now",
                          // state: {
                          //   itemName: selected?.name,
                          //   colorName: selected?.colorId,
                          //   quantity: count,
                          //   specificationId: selected?.specificationId,
                          //   rate: selected?.rate,
                          // },
                        }
                      }
                    >
                      Proceed to checkout{" "}
                    </Link>
                    <Link
                      to="/"
                      className="btn btn-out btn-success  btn-main mt-2 disabled"
                      data-abc="true"
                    >
                      Continue Shopping
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!loader ? (
            <div>
              <p className="text-center h6 mt-5">
                {" "}
                Your Kreesa shopping Basket
              </p>
              <div className="container-fluid mt-6">
                <div className="row">
                  <aside className="col-lg-9">
                    <div className="card cdet">
                      <div className="table-responsive">
                        <table className="table table-borderless table-shopping-cart">
                          <thead className="text-muted">
                            <tr className="small text-uppercase">
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  checked={checkAll}
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  onChange={() => {
                                    retailCart.map((i) =>
                                      onItemSelect(i, "all")
                                    );
                                    setCheckAll(!checkAll);
                                  }}
                                />
                              </th>
                              <th scope="col" className="text-center">
                                Product
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                width="120"
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                width="120"
                              >
                                Price
                              </th>
                              <th
                                scope="col"
                                className="text-center"
                                width="120"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                className="text-center "
                                width="20"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart
                              ? cart?.map((cartItem) => (
                                  <tr key={cartItem.id}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        style={{
                                          width: 20,
                                          height: 20,
                                        }}
                                        checked={selectedItem[cartItem.id]}
                                        onChange={() => {
                                          onItemSelect(cartItem, "single");
                                        }}
                                      />
                                    </td>
                                    <td className="itemside">
                                      <figure>
                                        <div className="aside">
                                          <img
                                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${cartItem?.product?.image?.id}/${cartItem?.product?.image?.imageName}`}
                                            className="img-sm"
                                          />
                                        </div>
                                      </figure>

                                      <figcaption
                                        className="ml-3"
                                        style={{ textAlign: "start" }}
                                      >
                                        <a
                                          href="#"
                                          className="title text-dark"
                                          data-abc="true"
                                        >
                                          <div style={{ fontSize: "16px" }}>
                                            {cartItem.product.productName
                                              .charAt(0)
                                              .toUpperCase() +
                                              cartItem.product.productName
                                                .slice(1)
                                                .substring(0, 20)}
                                          </div>
                                        </a>
                                        <p className="">
                                          {cartItem.colorName} <br />
                                          {cartItem.product.companyName}
                                        </p>
                                      </figcaption>
                                    </td>

                                    <td
                                      className="align-items-center"
                                      // className="d-flex mt-4 text-center"
                                      // style={{ height: "53px" }}
                                    >
                                      <div className="quantity-cart">
                                        <div
                                          className=" btn btn-light mr-2"
                                          onClick={() => {
                                            cartItem?.quantity != 1 &&
                                              dispatch(
                                                updateCartRetailItem({
                                                  userId: customOrder
                                                    ? customOrder.userId
                                                    : localStorage.getItem(
                                                        "userId"
                                                      ),
                                                  id: cartItem.id,
                                                  quantity:
                                                    cartItem?.quantity - 1,
                                                  customUserId:
                                                    customOrder?.userId,
                                                })
                                              );
                                          }}
                                        >
                                          <i
                                            className="fas fa-minus-circle "
                                            title="decrease items"
                                            // onClick={() =>
                                            //   addItemToBasket(cartItem)
                                            // }
                                          />
                                        </div>
                                        <p className="showitem">
                                          {cartItem?.quantity}
                                        </p>
                                        <div
                                          className=" btn btn-light ml-2"
                                          onClick={() => {
                                            dispatch(
                                              updateCartRetailItem({
                                                userId: customOrder
                                                  ? customOrder.userId
                                                  : localStorage.getItem(
                                                      "userId"
                                                    ),
                                                id: cartItem.id,
                                                quantity:
                                                  cartItem?.quantity + 1,
                                                // customUserId:
                                                //   location?.state?.userId,
                                              })
                                            );
                                          }}
                                        >
                                          <i
                                            className="fas fa-plus-circle"
                                            title="Increase items"
                                          />
                                        </div>
                                      </div>
                                    </td>

                                    <td className="text-center">
                                      <div className="">
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Rs{" "}
                                          {Math.round(
                                            cartItem?.product?.productPrice -
                                              (cartItem?.product?.productPrice *
                                                cartItem?.product
                                                  ?.productDiscount) /
                                                100
                                          )}
                                        </div>
                                        {/* <small className="text-muted"> </small>{" "} */}
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Rs{" "}
                                        {Math.round(
                                          (cartItem?.product?.productPrice -
                                            (cartItem?.product?.productPrice *
                                              cartItem?.product
                                                ?.productDiscount) /
                                              100) *
                                            cartItem?.quantity
                                        )}
                                      </div>
                                    </td>
                                    <td className="text-center ">
                                      <div
                                        className=" btn btn-light"
                                        onClick={() => {
                                          dispatch(
                                            removeCartRetailItem({
                                              cartId: cartItem.id,
                                              userId: customOrder
                                                ? customOrder.userId
                                                : localStorage.getItem(
                                                    "userId"
                                                  ),
                                              productName:
                                                cartItem.product.productName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                cartItem.product.productName
                                                  .slice(1)
                                                  .substring(0, 20),
                                            })
                                          );
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash-alt "
                                          title="remove items"
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </aside>

                  {retailCart?.length > 0 && (
                    <aside className="col-lg-3 total-card">
                      <div className="card">
                        <div className="card-body">
                          <dl className="dlist-align">
                            <dt>Total Items:</dt>
                            <dd className="text-right ml-3 font-weight-bold">
                              {
                                Object.values(selectedItem).filter(Boolean)
                                  .length
                              }
                            </dd>
                          </dl>
                          <dl className="dlist-align">
                            <dt>Sub Total:</dt>
                            <dd
                              className="text-right text-dark ml-3 font-weight-bold"
                              style={{ fontSize: "14px" }}
                            >
                              Rs{" "}
                              {!Object.values(selectedItem).filter(Boolean)
                                .length > 0
                                ? 0
                                : Math.round(
                                    Object.values(selectedItem)
                                      .filter(Boolean)
                                      ?.map((i) => i?.discountedTotalPrice)
                                      .reduce((a, b) => a + b)
                                  )}
                            </dd>
                          </dl>
                          {/* <dl className="dlist-align">
                        <dt>Sub Total:</dt>
                        <dd className="text-right text-dark b ml-3">
                          <strong>${basket?.cartTotalAmount}</strong>
                        </dd>
                      </dl> */}
                          <hr />
                          <Link
                            to={{
                              pathname:
                                Object.values(selectedItem).filter(Boolean)
                                  .length > 0
                                  ? "/checkout-user"
                                  : "",
                              state:
                                Object.values(selectedItem).filter(Boolean),
                              username: location?.state?.username,
                            }}
                            onClick={() =>
                              !Object.values(selectedItem).filter(Boolean)
                                .length > 0 &&
                              toast.info("Please select items first")
                            }
                            className="btn btn-out btn-primary btn-square btn-main"
                            data-abc="true"
                          >
                            Proceed to checkout
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-out btn-success btn-square btn-main mt-2"
                            data-abc="true"
                          >
                            Continue Shopping
                          </Link>
                        </div>
                      </div>
                    </aside>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "20px" }}>
              <Spinner />
            </div>
          )}
        </>
      )}
      <Footer />
    </div>
  );
};

export default Basket;
