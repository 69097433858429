import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "./Dashboard/index.js";
import Footer from "./Footer/index.js";
import LeftNavbar from "./LeftNavbar/index.js";
import TopNavbar from "./TopNavbar/index.js";

const Delivery = () => {
  document.title = "Delivery | Kreesa";

  let auth = localStorage.getItem("auth");
  if (auth !== "ROLE_DELIVERY_BOY") {
    return <Redirect exact to="/login" />;
  }

  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <Dashboard />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Delivery;
