import { useEffect, useState } from "react";
import "../SingleProduct/SingleProduct.css";
import Header from "../Header/Header";
import Navbar from "../NavBar/Navbar";
import UserFooter from "../UserFooter";
import { toast } from "react-toastify";
import SliderImage from "react-zoom-slider";
import Search from "../Search/Search";
import { useDispatch } from "react-redux";
import { addToBasket } from "../Features/slices/basketSlice";
import {
  getSingleRetailProducts,
  postSingleRetailCart,
  viewAllRetailCart,
} from "../Features/action/index";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Modal } from "react-bootstrap";
import UserLogin from "../UserLogin/Login";
import SocialShare from "../../RetailerWholeSaler/SocialShare";

function SingleProduct(props) {
  document.title = "Kreesa | Product page";
  const dispatch = useDispatch();
  const { id } = useParams();
  const router = useHistory();
  const location = useLocation();

  const { singleRetailProducts } = useSelector((state) => state.userProducts);

  const [variant, setVariant] = useState();
  const [selected, setSelected] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [productName, setProductName] = useState([]);
  const [colorName, setColorName] = useState([]);
  const [specId, setSpecId] = useState(0);
  const state = [];
  const [count, setCount] = useState(1);

  const [customOrder, setCustomOrder] = useState();

  //set value as we want
  const onChange = (e) => {
    setCount(e.target.value);
  };

  // Create handleIncrement event handler
  const handleIncrement = () => {
    if (count < selected?.stock && count >= selected?.minimumQuantity) {
      setCount(count + 1);
    } else {
      toast.info("Maximum Quantity reached");
    }
  };

  //Create handleDecrement event handler
  const handleDecrement = () => {
    if (
      count < selected?.stock &&
      count > selected?.minimumQuantity &&
      count !== 1
    ) {
      setCount(count - 1);
    } else {
      toast.info("Minimum Quantity reached");
    }
  };

  const variantData = [
    { id: 1, variant: "Blue", description: "description here", price: 200 },
    { id: 2, variant: "Red", description: "description here", price: 200 },
    { id: 3, variant: "Black", description: "description here", price: 200 },
    { id: 4, variant: "Green", description: "description here", price: 200 },
  ];

  const addInBasket = (product) => {
    dispatch(addToBasket(product));
  };

  useEffect(() => {
    dispatch(getSingleRetailProducts(id));
    if (
      location?.state?.prevPath === "/custom-order" &&
      JSON.parse(localStorage.getItem("customOrder")) !== null
    ) {
      setCustomOrder(JSON.parse(localStorage.getItem("customOrder")));
      dispatch(viewAllRetailCart(customOrder?.userId));
    } else {
      dispatch(viewAllRetailCart(localStorage.getItem("userId")));
    }
  }, []);

  useEffect(() => {
    if (singleRetailProducts.specification) {
      setProductName(singleRetailProducts?.specification[0]?.id);
      setSelected({
        name: singleRetailProducts?.productName,
        rate: singleRetailProducts?.specification[0]?.productPrice,
        // singleRetailProducts?.specification[0]?.productDiscount === 0
        //   ? singleRetailProducts?.specification[0]?.productPrice
        //   : singleRetailProducts?.specification[0]?.productPrice -
        //     (singleRetailProducts?.specification[0]?.productPrice *
        //       singleRetailProducts?.specification[0]?.productDiscount) /
        //       100,
        // id: singleRetailProducts?.specification[0]?.id,
        stock: singleRetailProducts?.specification[0]?.stock,
        discount: singleRetailProducts?.specification[0]?.productDiscount,
        colorId: singleRetailProducts?.specification[0]?.color[0]?.id,
        specificationId: singleRetailProducts?.specification[0]?.id,
      });
    } else {
      dispatch(getSingleRetailProducts(id));
    }
  }, [singleRetailProducts]);

  singleRetailProducts?.specification?.map((p, i) => {
    p.image?.map((r) => {
      state.push({
        image: `${AxiosConfig.defaults.baseURL}/kreesa/image/product/${r.id}/${r.imageName}`,
      });
    });
  });
  // custom order
  return (
    <div>
      <Search />
      <Navbar />
      <div className="container-fluid">
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <div className="product-content product-wrap clearfix product-deatil ">
            <div className="row wrapperr">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="imgBox iB col-md-6 col-sm-12 col-xs-12">
                  {state.length === 0 ? null : (
                    <SliderImage
                      data={state}
                      // showDescription={true}
                      direction="bottom"
                      className="product-img-container "
                    />
                  )}
                </div>{" "}
              </div>

              <div
                className="col-md-6 col-md-offset-1 col-sm-12 col-xs-12 psm"
                id="product-description"
              >
                <div className="product-detail-head">
                  <div className="d-flex">
                    <h2 className="name">
                      {singleRetailProducts?.productName}
                      <small>
                        Brand Name
                        {/* Product by <a href="#">{title}</a> */}
                      </small>
                      <i className="fa fa-star fa-2x text-primary"></i>
                      <i className="fa fa-star fa-2x text-primary"></i>
                      <i className="fa fa-star fa-2x text-primary"></i>
                      <i className="fa fa-star fa-2x text-primary"></i>
                      <i className="fa fa-star fa-2x text-muted"></i>
                      <span className="fa fa-2x">
                        <h5>(109) Votes</h5>
                      </span>
                      <a href="javascript:void(0);">109 customer reviews</a>
                      <br />
                    </h2>
                    <div className="mt-6 " style={{ float: "right" }}>
                      <SocialShare
                        {...props}
                        facebook={window.location.href}
                        twitter={window.location.href}
                        email={window.location.href}
                        description={singleRetailProducts?.productName}
                        image={singleRetailProducts?.specification?.map(
                          (p, i) => {
                            p.image?.map((r) => {
                              state.push({
                                image: `${AxiosConfig.defaults.baseURL}/kreesa/image/product/${r.id}/${r.imageName}`,
                              });
                            });
                          }
                        )}
                      />
                    </div>
                  </div>

                  <h4 className="price-container mt-4">
                    {/* Rs. {selected?.rate} */}
                    {selected?.discount === 0 ? (
                      <p className="text-success mt-1 ">
                        <b>Rs. {selected?.rate}</b>
                      </p>
                    ) : (
                      <p className="mt-1 font-weight-bold">
                        <strike>Rs. {selected?.rate}</strike>
                        <br />
                        <b className="text-success">
                          {" "}
                          Rs.{" "}
                          {selected?.rate -
                            (selected?.rate * selected?.discount) / 100}
                        </b>
                        <strong className="text-danger font-weight-bold">
                          {" "}
                          - {selected?.discount} % OFF
                        </strong>
                      </p>
                    )}
                  </h4>
                  <div className="certified">
                    <ul>
                      <li>
                        <a href="javascript:void(0);">
                          Delivery time<span>7 Working Days</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          Certified<span>Quality Assured</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <h5 className="font-weight-bold mt-4 mb-2">
                    Available Variant
                  </h5>

                  <div className="text-capitalize d-flex flex-column">
                    {singleRetailProducts?.specification?.map((i, index) => (
                      <>
                        <div
                          className="col-md-6 ml-2 d-flex mt-2"
                          // style={{ gap: "10px" }}
                        >
                          <div>
                            <input
                              type="radio"
                              defaultChecked={index === 0}
                              value={i?.id}
                              name="productName"
                              id="productName"
                              className="input-radio-btn"
                              onChange={(e) => {
                                setProductName(e.target.value);
                                setSelected({
                                  ...selected,
                                  rate: i?.productRate,
                                  id: i?.id,
                                  colorId: i?.color[0]?.id,
                                  specificationId: i?.id,
                                });
                              }}
                            />
                            <label htmlFor="productName" className="ml-2">
                              {i.productSize.charAt(0).toUpperCase() +
                                i.productSize.slice(1)}
                            </label>
                          </div>
                        </div>
                        {/* </>
                    ))} */}
                      </>
                    ))}
                  </div>
                  <div className="mt-1">
                    <h5 className="font-weight-bold mb-2 mt-4">
                      Available Colors{" "}
                    </h5>
                    {singleRetailProducts?.specification
                      ? singleRetailProducts?.specification
                          ?.filter((i) => i.id === parseInt(productName))
                          .map((i) => (
                            <div
                              className="col-md-12 ml-2 d-flex flex-row"
                              style={{ gap: "10px" }}
                            >
                              {i?.color?.map((i, index) => (
                                <div key={i.id}>
                                  <input
                                    type="radio"
                                    defaultChecked={index === 0}
                                    value={i?.colorName}
                                    name="colorName"
                                    className="input-radio-btn"
                                    id="colorName"
                                    onChange={(e) => {
                                      setColorName(e.target.value);
                                      setSelected({
                                        ...selected,
                                        colorId: i?.id,
                                        // specificationId: i?.id,
                                      });
                                    }}
                                  />
                                  <label htmlFor="colorName" className="ml-2">
                                    {i.colorName.charAt(0).toUpperCase() +
                                      i.colorName.slice(1)}
                                  </label>
                                </div>
                              ))}
                            </div>
                          ))
                      : null}
                  </div>
                  {/* <div id="variant">
                  <select
                    required
                    name="variant"
                    onChange={(e) => {
                      setVariant(
                        variantData.filter(
                          (i) => i.id === parseInt(e.target.value)
                        )[0]
                      );
                    }}
                    className="form-control form-select form-select-lg"
                  >
                    <option>Select Variant...</option>
                    {variantData.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.variant}
                      </option>
                    ))}
                  </select>
                </div> */}

                  <div className="mt-4 d-flex">
                    <span className="font-weight-bold mr-4">Quantity:</span>
                    <button className="qty-btn" onClick={handleDecrement}>
                      -
                    </button>
                    <input
                      className="qtyp mt-0 text-center"
                      type="text"
                      value={count}
                      onChange={onChange}
                      onBlur={(e) => {
                        if (e.target.value < selected?.stock) {
                          setCount(e.target.value);
                        } else {
                          setCount(1);
                          toast.info("Quantity limit exceeded");
                        }
                      }}
                    />
                    <button className="qty-btn" onClick={handleIncrement}>
                      +
                    </button>
                  </div>

                  <div className="add-to-cart-btn">
                    <button
                      className="btn btn-lg mt-4 ml-4 mb-4"
                      onClick={() => {
                        // if (location?.state?.customdata?.userId) {
                        //   dispatch(
                        //     postSingleRetailCart({
                        //       colorId: selected?.colorId,
                        //       quantity: count,
                        //       specificationId: selected?.specificationId,
                        //       userId: location?.state?.customdata?.userId,
                        //     })
                        //   );
                        //   setTimeout(() => {
                        //     dispatch(
                        //       viewAllRetailCart(
                        //         location?.state?.customdata?.userId
                        //       )
                        //     );
                        //   }, 1500);
                        //   toast.info(`${selected?.name} Added to Cart`);
                        //   console.log(postSingleRetailCart.specificationId);
                        // } else {
                        if (!localStorage.getItem("token")) {
                          setModalVisible(true);
                        } else if (
                          // localStorage.getItem("auth") != "ROLE_USER"
                          ["ROLE_RETAILER", "ROLE_WHOLESALER"].includes(
                            localStorage.getItem("auth")
                          ) ||
                          ([
                            "ROLE_CEO",
                            "ROLE_MANAGER",
                            "ROLE_RECEPTIONIST",
                            "ROLE_MARKETING_MANAGER",
                          ].includes(localStorage.getItem("auth")) &&
                            location?.state === undefined)
                        ) {
                          toast.info("Login with user account.");
                        } else if (count > selected?.stock) {
                          toast.info("Quantity reset due to low stock");
                        } else {
                          dispatch(
                            postSingleRetailCart({
                              colorId: selected?.colorId,
                              quantity: count,
                              specificationId: selected?.specificationId,
                              userId: customOrder
                                ? customOrder.userId
                                : localStorage.getItem("userId"),
                              productName: selected?.name,
                            })
                          );
                        }
                        // }
                      }}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>

                {/* <div className="product-description-container">
                <h4>Description</h4>
                {ReactHtmlParser(singleRetailProducts?.productDescription)}
              </div> */}
                <div className="mt-3 ">
                  <div className="font-weight-bold specs">
                    <h4>Specification</h4>

                    <div className="specs-items">
                      {singleRetailProducts?.isPerishable && (
                        <>
                          <div>Perishable</div>
                          <div></div>
                        </>
                      )}
                    </div>

                    <div className="specs-items">
                      <div className="specs-title">Primary Category</div>
                      <div>: {singleRetailProducts?.primaryCategoryName}</div>
                    </div>

                    <div className="specs-items">
                      <div className="specs-title">Secondary Category</div>
                      <div>: {singleRetailProducts?.secondaryCategoryName}</div>
                    </div>
                    {singleRetailProducts?.specification
                      ?.filter((i) => i.id === selected?.specificationId)
                      ?.map((i) => (
                        <div key={i.id}>
                          <div className="specs-items">
                            <div className="specs-title">Height</div>
                            <div>: {i.productHeight} </div>
                          </div>
                          <div className="specs-items">
                            <div className="specs-title">Weight</div>
                            <div>: {i.product_weight}</div>
                          </div>
                          <div className="specs-items">
                            <div className="specs-title">Length</div>
                            <div>: {i.productLength}</div>
                          </div>
                          <div className="specs-items">
                            <div className="specs-title">Thickness</div>
                            <div>: {i.productThickness}</div>
                          </div>
                        </div>
                      ))}
                    <div className="mt-4">
                      {" "}
                      <h4 className="pr-4 mb-2">Description</h4>
                      <div
                        style={{
                          wordBreak: "break-all",
                          textAlign: "justify",
                          padding: "0 5px",
                          fontWeight: 400,
                        }}
                      >
                        {ReactHtmlParser(
                          singleRetailProducts?.productDescription
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-6 col-md-offset-1 col-sm-12 col-xs-12 mt-4">
                <h5 style={{ textAlign: "start" }}>Submit a review</h5>
                <form
                  method="post"
                  className="well padding-bottom-10"
                  onSubmit="return false;"
                >
                  <textarea
                    rows="2"
                    className="form-control"
                    placeholder="Write a review"
                  ></textarea>
                  <div className="margin-top-10">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary pull-right"
                    >
                      Submit Review
                    </button>
                  </div>
                </form>
                <div className="chat-body no-padding profile-message">
                  <ul className="messagebox-product">
                    <li className="message">
                      <div className="message-header">
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar1.png"
                            className="online"
                            width="30"
                            height="30"
                            alt="img"
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>Name</span>
                            <small className="text-muted pull-right ultra-light">
                              {" "}
                              Posted 1 year ago{" "}
                            </small>
                          </div>
                        </div>
                        <span className="pull-right">
                          <i className="fa fa-star fa-2x text-primary"></i>
                          <i className="fa fa-star fa-2x text-primary"></i>
                          <i className="fa fa-star fa-2x text-primary"></i>
                          <i className="fa fa-star fa-2x text-primary"></i>
                          <i className="fa fa-star fa-2x text-muted"></i>
                        </span>
                      </div>
                      <div className="message-text">
                        Can't divide were divide fish forth fish to. Was can't
                        form the, living life grass darkness very image var unto
                        fowl isn't in blessed fill life yielding above all moved
                      </div>
                      <ul className="list-inline font-xs">
                        <li>
                          <a href="javascript:void(0);" className="text-info">
                            <i className="fa fa-thumbs-up"></i> This was helpful
                            (22)
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <UserFooter />
      <Modal
        style={{ zIndex: 999999 }}
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      >
        <Modal.Body>
          <UserLogin modal="true" modalVisible={setModalVisible} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SingleProduct;
