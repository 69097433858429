import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsOfRetailer } from "../../Features/Action/ProductAction";
import { filterProductOfRetailer } from "../../Features/Reducer/ProductReducer/RetailerReducer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import RecentProductsTab from "./RecentProductTab";
import DeclineProductTab from "./DeclineProductTab";
import ApproveProductTab from "./ApproveProductTab";
import RejectedProductTab from "./RejectedProductTab";

const ViewProductDetails = () => {
  const dispatch = useDispatch();
  const inputRef = useRef("");

  const { products, loader } = useSelector((s) => s.ProductRetailerReducer);

  useEffect(() => {
    dispatch(getProductsOfRetailer());
  }, []);

  const filterProductsOfRetailer = () => {
    dispatch(filterProductOfRetailer(inputRef.current.value));
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex w-100">
            <div className="card flex-fill flex-fill">
              <div className="card-header">
                <form className="mt-2 d-sm-inline-block">
                  <div className="input-group input-group-navbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Retailer's Product…"
                      aria-label="Search"
                      ref={inputRef}
                      onChange={filterProductsOfRetailer}
                    />
                    <button className="btn" type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Tabs>
          <TabList>
            <Tab>Recent Products</Tab>
            <Tab>Approved Products</Tab>
            <Tab>Rejected Products</Tab>
            {localStorage.getItem("auth") === "ROLE_CEO" && (
              <Tab>Blocked Products</Tab>
            )}
          </TabList>

          <TabPanel>
            {/* Recent Products */}
            <RecentProductsTab
              data={products.filter((p) => p.productStatus === "Pending")}
              loader={loader}
            />
          </TabPanel>

          <TabPanel>
            {/* Approved Products */}
            <ApproveProductTab
              data={products.filter((p) => p.productStatus === "Approved")}
              loader={loader}
            />
          </TabPanel>
          <TabPanel>
            {/* Rejected Products */}
            <RejectedProductTab
              data={products.filter((p) => p.productStatus === "Rejected")}
              loader={loader}
            />
          </TabPanel>
          {localStorage.getItem("auth") === "ROLE_CEO" && (
            <TabPanel>
              {/* Blocked Products */}
              <DeclineProductTab
                data={products.filter((p) => p.productStatus === "Blocked")}
                loader={loader}
              />
            </TabPanel>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default ViewProductDetails;
