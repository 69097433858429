import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteFaq,
  getAllFaqsForAdmin,
} from "../../Features/Action/AdminBlockUnblockAction";
import { Excerpt } from "../../../../utils";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";
import Pagination from "../../../Pagination";
import HtmlParser from "react-html-parser";
import FaqImgae from "../../../../assets/Images/faq.png";

const SellerTab = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const { sellerFaqs, loader } = useSelector((s) => s.AdminReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllFaqsForAdmin());
  }, []);
  let increament = 1;
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = sellerFaqs?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(sellerFaqs?.length / perPage);
  return !loader ? (
    <div className="row">
      {currentEntries?.length > 0 ? (
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">All Sellers FAQ</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Question</th>
                  <th className="d-xl-table-cell">Answer</th>
                  {/* <th className="d-xl-table-cell">Video URL</th> */}
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map((faq, i) => (
                  <tr key={i}>
                    <td>{increament++}</td>
                    <td> {Excerpt(faq?.question, 40)}</td>
                    <td className="d-xl-table-cell">
                      {HtmlParser(Excerpt(faq?.answer, 40))}
                    </td>
                    {/*<td className="d-xl-table-cell">
                      {faq.videoUrl === "" ? (
                        <img
                          src={FaqImgae}
                          alt="no-image"
                          style={{
                            borderRadius: 8,
                          }}
                          height="80"
                        />
                      ) : (
                        <a href={faq?.videoUrl} target="_blank">
                          <img
                            src={`http://img.youtube.com/vi/${faq?.videoUrl?.slice(
                              17
                            )}/0.jpg`}
                            alt={faq?.videoUrl?.slice(17)}
                            height="80"
                          />
                        </a>
                      )}
                       
                    </td>*/}
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#3b7ddd",
                          }}
                          className="btn-md btn btn-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <div className="dropdown-menu text-center">
                          <Link
                            to={`/admin/edit-faq/${faq.id}`}
                            className="dropdown-item text-info"
                          >
                            <i className="fas fa-user-edit"></i> Edit
                          </Link>
                          <div className="dropdown-divider" />
                          <Link
                            to={`/admin/view-faq/${faq.id}`}
                            className="dropdown-item text-success cursor-pointer"
                          >
                            <i className="fa fa-eye"></i> Review
                          </Link>
                          <div className="dropdown-divider" />
                          <p
                            onClick={async () => {
                              await dispatch(deleteFaq(faq.id));
                              toast.error("FAQ Deleted");
                            }}
                            className="dropdown-item text-danger cursor-pointer"
                          >
                            <i className="fa fa-trash"></i> Delete
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h3>No Seller FAQ Found !</h3>
        </div>
      )}
      {currentEntries?.length > 0 && (
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={sellerFaqs}
        />
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default SellerTab;
