import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import "./style.css";
import { Modal } from "react-responsive-modal";
import Spinner from "../../../Spinner";
import { approveAndRejectProductStatus } from "../../../Features/Action/ProductAction/UpdateStatusAction";
import { getWholesalerReviewById } from "../../../Features/Action/ProductAction";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import moment from "moment";
import HtmlParser from "react-html-parser";

const ProductReviewDetails = () => {
  const { ReviewById, loader } = useSelector((s) => s.ProductWholesalerReducer);
  document.title = `Seller | ${ReviewById?.productName}`;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [user, setUser] = useState([]);
  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };
  // modal end
  const [group, setGroup] = useState();
  // console.log(ReviewById)
  useEffect(() => {
    dispatch(getWholesalerReviewById(id));
  }, []);

  const UpdateStatus = (groupId) => {
    if (groupId) {
      dispatch(approveAndRejectProductStatus("Approved" + "/" + ReviewById.id));
      return;
      // history.push("/admin/product/view/wholesale");
    } else {
      toast.error("Product is not Assign to any group");
      return;
    }
  };
  const handleAssignGroup = async (groupId, id) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      await AxiosConfig.get(
        `/kreesa/admin/product/assign-group/${id}/group/${groupId}`,
        config
      );
      toast.success("Group Assign Succesfully ");
      window.location.reload();
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };

  // Assign Group in Modal
  const handleGroupModal = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const group = await AxiosConfig.get(`/kreesa/admin/group/view`, config);
      setGroup(group.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  //get user details

  const getUser = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const user = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${ReviewById.userId}`,
        config
      );
      setUser(user.data);
    } catch (error) {
      console.log(error.response);
    }
  };
  useEffect(() => {
    getUser();
  }, [ReviewById]);

  return !loader ? (
    <div className="text-capitalize">
      <div className="card flex-fill">
        <div
          className="card-header text-uppercase font-weight-bold "
          style={{ backgroundColor: "rgb(30 182 202)", color: "white" }}
        >
          Product Details{" "}
          <Link
            className="btn btn-danger btn-sm px-4"
            to={`/admin/product/edit/${id}`}
            style={{
              float: "right",
              color: "whitesmoke",
              fontWeight: 600,
              fontFamily: "sans-serif",
              borderRadius: 4,
              letterSpacing: 1,
            }}
          >
            Edit
          </Link>
        </div>
        <div className="row m-4 mt-4">
          <div className="col-md-3">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Name
            </label>
            <p className="text-dark h5">{ReviewById.productName}</p>
          </div>
          <div className="col-md-3">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Category
            </label>
            <p className="text-dark h5">
              {ReviewById.primaryCategoryName}/
              {ReviewById.secondaryCategoryName}/
              {ReviewById.ternaryCategoryName}
            </p>
          </div>

          <div className="col-md-3">
            <label htmlFor="LastName" className="font-weight-bold ">
              placed by
            </label>
            <br />
            <a
              href
              className="text-info h5"
              id="user-details"
              onClick={() => handleOpenModal(ReviewById.userId)}
            >
              {ReviewById.sellerType} User Details
            </a>
            {/*Modal  */}
            {modal.show && modal.requestedModalId == ReviewById.userId && (
              <Modal
                open={modal}
                onClose={handleCloseModal}
                center
                classNames={{
                  overlayAnimationIn: "customEnterOverlayAnimation",
                  overlayAnimationOut: "customLeaveOverlayAnimation",
                  modalAnimationIn: "customEnterModalAnimation",
                  modalAnimationOut: "customLeaveModalAnimation",
                  modal: "customModal",
                }}
                animationDuration={1000}
              >
                <div className="pro">
                  {/* <div className="col-md-4">
                    <div className="profile-img">
                      <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${id}/${user.companyLogo}`} alt={user?.companyName} />
                    </div>
                  </div> */}

                  <h3 className="text-uppercase">{user?.companyName} </h3>
                  <h6>
                    {user.role === "ROLE_CEO"
                      ? "CEO"
                      : user.role === "ROLE_MANAGER"
                      ? "Manager"
                      : user.role === "ROLE_MARKETING_MANAGER"
                      ? "Marketing Manager"
                      : user.role === "ROLE_RECEPTIONIST"
                      ? "Receptionist"
                      : user.role === "ROLE_RETAILER"
                      ? "Retailer"
                      : user.role === "ROLE_WHOLESALER"
                      ? "WholeSaler"
                      : ""}
                  </h6>
                </div>

                <div className="col-md-6 m-auto">
                  <div className="tab-content profile-tab custom-height">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Name</label>
                        </div>
                        <div className="col-md-6">
                          <p>{user?.companyName}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Address</label>
                        </div>
                        <div className="col-md-6">
                          <p>{`${user?.districtName}, ${user?.provienceName}, ${user?.countryName}`}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Email</label>
                        </div>
                        <div className="col-md-6">
                          <p>{user?.companyEmail}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Type</label>
                        </div>
                        <div className="col-md-6">
                          <p>{user?.companyType}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Contact</label>
                        </div>
                        <div className="col-md-6">
                          <p> {user?.companyContactNo}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>PAN No</label>
                        </div>
                        <div className="col-md-6">
                          <p> {user?.panNo}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Register Status</label>
                        </div>
                        <div className="col-md-6">
                          {user?.isRegistered === true ? (
                            <p>Register</p>
                          ) : (
                            <p>Not Register</p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Insuranced Status</label>
                        </div>
                        <div className="col-md-6">
                          {user?.isInsured === true ? (
                            <p>Insuranced</p>
                          ) : (
                            <p>Not Insuranced</p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Status</label>
                        </div>
                        <div className="col-md-6">
                          {user?.flag === true ? (
                            user?.locked === true ? (
                              <p>Active</p>
                            ) : (
                              <p>Inactive</p>
                            )
                          ) : (
                            <p>Blocked</p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Created Date</label>
                        </div>
                        <div className="col-md-6">
                          <p>{moment(user.createdAt).format("ll")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
        <div className="row m-4">
          <div className="col-md-12">
            <label htmlFor="FirstName" className="font-weight-bold ">
              product Description
            </label>
            <p className="text-dark h5">
              {HtmlParser(ReviewById.productDescription)}
            </p>
          </div>
        </div>
        {/*  */}

        {ReviewById?.specification?.map((item, index) => (
          <div key={index}>
            <div
              className="card-header text-uppercase font-weight-bold "
              style={{ backgroundColor: "rgb(30 182 202)", color: "white" }}
            >
              Product Specification {index + 1}
            </div>

            <div className="row m-4">
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Size
                </label>
                <p className="text-dark">{item.productSize}</p>
              </div>
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Weight <span className="text-info">(kg)</span>
                </label>
                <p className="text-dark">{item.product_weight}</p>
              </div>
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Height
                </label>
                <p className="text-dark">{item.productHeight}</p>
              </div>
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Length
                </label>
                <p className="text-dark">{item.productLength}</p>
              </div>
              <hr />
              <div className="col-md-3 col-sm">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Thickness
                </label>
                <p className="text-dark">{item.productThickness}</p>
              </div>
              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product Quantity
                </label>
                <p className="text-dark">{item.productQuantity}</p>
              </div>
              <div className="col-md-3">
                <label htmlFor="FirstName" className="font-weight-bold ">
                  product Rate
                </label>
                <p className="text-dark">{item.productRate}</p>
              </div>
              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product price
                </label>
                <p className="text-dark">{item.productPrice}</p>
              </div>
              <hr />
              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product Discount
                </label>
                <p className="text-danger">{item.productDiscount}%</p>
              </div>

              <div className="col-md-3">
                <label htmlFor="LastName" className="font-weight-bold ">
                  product color
                </label>
                <br />
                {item.color?.map((c, ind) => (
                  <span className="text-dark" id="mu-color">
                    {c.colorName} &nbsp;
                  </span>
                ))}
              </div>
            </div>
            <div>
              <div
                className="card-header text-uppercase font-weight-bold "
                style={{
                  backgroundColor: "rgb(30 182 202)",
                  color: "white",
                }}
              >
                Product Images
              </div>

              <div className="d-flex m-4">
                {item.image?.map((image, im) => (
                  <div>
                    <div
                      onClick={() => handleOpenModal(image.id)}
                      className="d-flex cursor-pointer"
                    >
                      <img
                        className="img-fluid img-thumbnail ml-2"
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${image.id}/${image.imageName}`}
                        style={{ height: "20vh", width: "200px" }}
                        alt={image.imageName}
                      />
                    </div>
                    {/*Modal  */}
                    {modal.show && modal.requestedModalId == image.id && (
                      <Modal
                        open={modal}
                        onClose={handleCloseModal}
                        center
                        classNames={{
                          overlayAnimationIn: "customEnterOverlayAnimation",
                          overlayAnimationOut: "customLeaveOverlayAnimation",
                          modalAnimationIn: "customEnterModalAnimation",
                          modalAnimationOut: "customLeaveModalAnimation",
                        }}
                        animationDuration={1000}
                      >
                        <div className="pro">
                          <img
                            className="img-fluid img-thumbnail"
                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${image.id}/${image.imageName}`}
                            height={650}
                            width={700}
                            alt={image.imageName}
                          />
                        </div>
                      </Modal>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {/* Modal */}
          </div>
        ))}

        <div className="m-4 flex-fill">
          {ReviewById.productStatus !== "Approved" && (
            <button
            onClick={async () => {
              await UpdateStatus(ReviewById.groupId);
              dispatch(getWholesalerReviewById(id));
              history.go();
            }}
              className="btn btn-lg btn-outline-info text-capitalize"
              style={{ float: "right" }}
            >
              <i className="fa fa-check"></i> Approve Now
            </button>
          )}

          {ReviewById.groupId === null && (
            <button
              className="btn mx-4 btn-lg btn-outline-info text-capitalize"
              onClick={() => handleGroupModal()}
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
              style={{ float: "right" }}
            >
              Assign Group
            </button>
          )}

          {/* large modal start*/}
          <div
            className="modal fade bd-example-modal-lg"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                >
                  <span
                    aria-hidden="true"
                    className="m-2"
                    style={{ float: "right", fontSize: "30px" }}
                  >
                    &times;
                  </span>
                </button>
                <div className="card flex-fill m-4 overflow-auto">
                  <table className="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>Sn.</th>
                        <th className="d-xl-table-cell">Group Name</th>
                        <th className="d-xl-table-cell">Group SKU</th>
                        <th className="d-md-table-cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {group?.map((p, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td className="d-md-table-cell">{p.groupName}</td>
                          <td className="d-md-table-cell">{p.groupSKU}</td>
                          <td>
                            <div
                              onClick={() =>
                                handleAssignGroup(p.id, ReviewById.id)
                              }
                              className="cursor-pointer"
                            >
                              <span className="badge bg-success">
                                <i className="fas fa-check"></i> Assign
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ProductReviewDetails;
