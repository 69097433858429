import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to fetch unimplemented tree
export const fetchUnimplementedTree = createAsyncThunk(
  "fetchUnimplementedTree",
  async (_, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/tree/get-all-unimplement-tree-details`
    );
    return data;
  }
);

// to fetch implemented tree
export const fetchImplementedTree = createAsyncThunk(
  "fetchImplementedTree",
  async (_, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/tree/get-all-implement-tree-details`
    );
    return data;
  }
);

// to fetch limit tree
export const fetchTreeLimit = createAsyncThunk(
  "fetchTreeLimit",
  async (_, { dispatch }) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/tree/get-tree-condition`
    );
    return data;
  }
);

// to add new tree as a implemented tree
export const addImplementedTree = createAsyncThunk(
  "addImplementedTree",
  async (datas, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/tree/implement_tree`,
      datas
    );
    dispatch(fetchUnimplementedTree());
    return data;
  }
);

// to update tree limit
export const updateTreePrice = createAsyncThunk(
  "updateTreeLimit",
  async (datas, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/tree/update-condition`,
      datas
    );
    dispatch(fetchTreeLimit());
    return data;
  }
);
