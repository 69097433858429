import { createSlice } from "@reduxjs/toolkit";
import { getSingleCustomOrder,getCustomOrderByUserId } from "../../Action/CustomOrderAction";

const SingleCustomOrder = createSlice({
  name: "SingleCustomOrder",
  initialState: {
    singleCustomOrder: [],
    customOrderByUserId:[],
    loader: false,
    errors: {},
  },
  reducers: {},
  extraReducers: {
    /* to get all Primary Categories */
    [getSingleCustomOrder.pending]: (state, action) => {
      state.loader = true;
    },
    [getSingleCustomOrder.fulfilled]: (state, action) => {
      state.loader = false;
      state.singleCustomOrder = action.payload;
    },
    [getSingleCustomOrder.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    // to get custom order by user id
    [getCustomOrderByUserId.pending]: (state, action) => {
      state.loader = true;
    },
    [getCustomOrderByUserId.fulfilled]: (state, action) => {
      state.loader = false;
      state.customOrderByUserId = action.payload;
    },
    [getCustomOrderByUserId.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export default
export default SingleCustomOrder.reducer;
