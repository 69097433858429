import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../Admin/Spinner";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import "./style.css";
import { useQuery } from "../../../utils";

const KhaltiSuccess = () => {
  document.title = "Khalti Success";

  // state for loading
  const [loading, setLoading] = useState(true);
  let query = useQuery();

  const khaltiVerification = async () => {
    try {
      setLoading(true);

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const res = await AxiosConfig.post(
        "/kreesa/payment/khalti/verification",
        {
          amount: query.get("amount"),
          mobile: query.get("mobile"),
          pidx: query.get("pidx"),
          purchaseOrderId: query.get("purchase_order_id"),
          purchaseOrderName: query.get("purchase_order_name"),
          transactionId: query.get("transaction_id"),
        },
        config
      );
      if (res && res.data.status === "success") {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    khaltiVerification();
  }, []);

  return !loading ? (
    <div
      style={{
        background: "#EBF0F5",
        padding: "40px 0",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div className="card card__success_card">
        <div
          style={{
            borderRadius: "200px",
            height: "200px",
            width: "200px",
            background: "#F8FAF5",
            margin: "0 auto",
          }}
          className="payment__success"
        >
          <i className="success__checkmark">✓</i>
        </div>
        <h1
          style={{
            color: "#88B04B",
            fontFamily: "Nunito Sans",
            fontWeight: "900",
            fontSize: "40px",
            marginBottom: "10px",
          }}
        >
          Success
        </h1>
        <p
          style={{
            fontSize: "20px",
            margin: 0,
            color: "#404F5E",
            fontFamily: "Nunito Sans",
          }}
        >
          We received your purchase request;
          <br /> we'll be in touch shortly!
        </p>
        {localStorage.getItem("auth") === "ROLE_USER" ? (
          <Link to="/">
            <button
              type="button"
              className="text-uppercase btn btn-success mt-4 py-2 px-3"
            >
              <i className="fas fa-home"></i> Take Me Home
            </button>
          </Link>
        ) : (
          <Link to="/home">
            <button
              type="button"
              className="text-uppercase btn btn-success mt-4 py-2 px-3"
            >
              <i className="fas fa-home"></i> Take Me Home
            </button>
          </Link>
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default KhaltiSuccess;
