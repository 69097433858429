import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import './static/js/app.js'
import { BrowserRouter } from "react-router-dom";
import { store } from "./Store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Zoom } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

let persistor = persistStore(store);

// disable React DevTools for production
if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
        <ToastContainer
          theme="colored"
          autoClose={3000}
          limit={2}
          transition={Zoom}
          hideProgressBar={true}
          position="top-right"
        />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("kreesa")
);
