import React from "react";
import "../Contact/Contact.css";
import Header from "../Header/Header";
import RetailNav from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import Search from "../Search/Search";
import Navbar from "../NavBar/Navbar";
import UserFooter from "../UserFooter";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../ButtonLoaderHook";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // company: Yup.string().required("Company is required"),
  contact_details: Yup.number().required("Contact is required"),
  message: Yup.string().required("Message is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
});

const Index = () => {
  document.title = "Kreesa | Contact";
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "SUBMIT",
    "Loading..."
  );

  /* ------------------------------- contact-us ------------------------------- */

  const contactHandler = async (values, submitProps) => {
    setButtonLoader(true);
    try {
      const res = await AxiosConfig.post("/kreesa/submit-contactUs-details", {
        ...values,
      });
      if (res && res.status === 200) {
        toast.success("Message sent successfully");
        setButtonLoader(false);
        submitProps.resetForm();
      }
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };
  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <RetailNav />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      <div className="container mt-4">
        <div>
          <h3
            style={{
              fontSize: 20,
              textAlign: "center",
            }}
          >
            {" "}
            Please use the form below to send us your comments and feedback. We
            appreciate you taking the time to provide us with your views so that
            we can best meet the need of users.
          </h3>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 mt-2">
            <div id="googlemap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14127.688100048745!2d85.2791815!3d27.7196937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1a0a0e1dfdbd435f!2sKreesa%20Sales%20Private%20Limited!5e0!3m2!1sen!2snp!4v1641112127766!5m2!1sen!2snp"
                style={{ width: "100%", height: "350px" }}
              ></iframe>
            </div>
          </div>
          <br />
          <div className="col-md-6 mt-2 mb-4">
            <h3>E-mail Us</h3>
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
                contact_details: "",
              }}
              onSubmit={contactHandler}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Name*"
                    />
                  </div>
                  {errors.name && touched.name ? (
                    <div style={{ color: "red" }}> {errors.name} </div>
                  ) : null}
                  <div className="form-group mt-3">
                    <input
                      type="email"
                      className="form-control"
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      placeholder="Email*"
                    />
                  </div>
                  {errors.email && touched.email ? (
                    <div style={{ color: "red" }}> {errors.email} </div>
                  ) : null}
                  <div className="form-group mt-3">
                    <input
                      type="tel"
                      className="form-control"
                      value={values.contact_details}
                      onChange={handleChange}
                      name="contact_details"
                      placeholder="Contact*"
                    />
                  </div>
                  {errors.contact_details && touched.contact_details ? (
                    <div style={{ color: "red" }}>
                      {" "}
                      {errors.contact_details}{" "}
                    </div>
                  ) : null}
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      value={values.message}
                      onChange={handleChange}
                      name="message"
                      style={{ resize: "auto" }}
                      placeholder="Message..."
                      rows="6"
                    ></textarea>
                  </div>
                  {errors.message && touched.message ? (
                    <div style={{ color: "red" }}> {errors.message} </div>
                  ) : null}
                  <div>
                    <button
                      type="submit"
                      ref={buttonLoader}
                      onClick={handleSubmit}
                      className="btn btn-lg mt-3 px-4 btn-info"
                    >
                      SUBMIT
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default Index;
