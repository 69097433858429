import $ from "jquery";
import "jquery-validation";

const CustomeOrderValidation = () => {
  // Wait for the DOM to be ready
  $(function () {
    //validate file extension custom  method.
    $.validator.addMethod(
      "extension",
      function (value, element, param) {
        param =
          typeof param === "string"
            ? param.replace(/,/g, "|")
            : "png|jpe?g|gif";
        return (
          this.optional(element) ||
          value.match(new RegExp(".(" + param + ")$", "i"))
        );
      },
      $.validator.format("Please enter a value with a valid extension.")
    );
    $("#customer-add").validate({
      // Specify validation rules
      rules: {
        file: {
          required: true,
          extension: "jpg|jpeg|png",
        },
        estimatedDate: {
          required: true,
        },
        customType: {
          required: true,
        },
      },

      messages: {
        file: {
          required: "Image is required",
          extension: "Only accept jpg|jpeg|png",
        },
        estimatedDate: {
          required: "Date is required",
        },
        customType: {
          required: "Type is required",
        },
      },

      highlight: function (element) {
        $(element).removeClass("is-valid").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid").addClass("is-valid");
      },
    });
    $("#acsubmit").on("click", function () {
      $("#customer-add").valid();
    });
  });
  $("#customer-add input").on("keyup blur", function () {
    // fires on every keyup & blur
    if ($("#customer-add").valid()) {
      // checks form for validity
      $("button#acsubmit").prop("disabled", false); // enables button
    } else {
      $("button#acsubmit").prop("disabled", "disabled"); // disables button
    }
  });
};

export default CustomeOrderValidation;
