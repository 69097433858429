import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";

const MyTreePlantation = () => {
  const [state, setstate] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  // get all notification
  const getAllPlantedTree = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const allOrders = await AxiosConfig.get(
        `/kreesa/retailer/get-my-tree/${localStorage.getItem(
          "userId"
        )}`,
        config
      );
      setstate(allOrders.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
    }
  };
  //
  useEffect(() => {
    getAllPlantedTree();
  }, []);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = state?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(state?.length / perPage);
  return !isLoaded ? (
    <div className="container">
      <div className="row">
        {/* <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <Link to="/retailer/product/register" className="btn btn-lg btn-outline-info display-5 mt-2" style={{ float: "right" }}>
                Add Product <i className="fab fa-product-hunt"></i>
              </Link>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">Planted Trees</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Plant type</th>
                  <th>Location Address</th>
                  <th>Coordinates</th>
                  <th className="d-xl-table-cell">Purchase Date</th>
                  <th className="d-md-table-cell">Planted Date</th>
                  <th className="d-md-table-cell">Status</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map((p, i) => (
                  <tr>
                    <td>{perPage * (currentPage - 1) + i + 1}</td>
                    <td>{p.type_of_plant}</td>
                    <td>{p.address_of_plant}</td>
                    <td>{p.latitude_longitude}</td>
                    <td>{moment(p.purchaseDate, "ddd MMM DD HH:mm:ss [CEST] YYYY").format("ll")}</td>
                    <td>{moment(p.date_of_plantation).format("ll")}</td>
                    <td>
                        {p.flag ? (
                             <span className="badge bg-success">
                             Planted
                           </span>
                        ):(
                            <span className="badge bg-info">
                            Not Planted
                          </span>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={state}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default MyTreePlantation;
