import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import UserFooter from ".";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";

const onlineSellingGuide = () => {
  document.title = "Online Selling Guide";
  return (
    <div>
      {" "}
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      <div className="container mt-4">
        <div
          className="mt-4"
          style={{ fontSize: "20px", textAlign: "justify" }}
        >
          <h1
            className="font-weight-bold text-center"
            style={{ color: "#319ffb " }}
          >
            Online Selling Guide
          </h1>
          <p className="mt-4 mb-4">
            Sell on Kreesa!: 5 reasons why it makes sense to create an online
            business
          </p>
          <img src="/images/onlinesellingguide.png" width={"100%"} />
          <div className="mt-4">
            <p>
              As a business owner, your biggest goal is to constantly grow your
              business. This will help you earn better pro ts too. However, even
              if you are situated in a prime spot in a busy market, you may be
              well-known, but your reach still won’t be as vast as possible. How
              can you x this? By establishing an online business.
            </p>
            <p>
              As a business owner, your biggest goal is to constantly grow your
              business. This will help you earn better pro ts too. However, even
              if you are situated in a prime spot in a busy market, you may be
              well-known, but your reach still won’t be as vast as possible. How
              can you x this? By establishing an online business.
            </p>
            <p>
              Take a look at 5 great reasons why you should build an online
              business.
            </p>
          </div>
          <div className="mt-5">
            <p className="font-weight-bold" style={{ color: "orange" }}>
              Pan-Nepal{" "}
            </p>
            <p>
              You may be the most famous store in Putali Bazar in Gulmi or New
              road in Kathmandu, but customers who aren’t in the same locality
              won’t have any access to you. On the other hand, when you sell
              online, you can reach shoppers in your locality and city just as
              easily as shoppers from other parts of the country. When you sell
              on Kreesa, for example, you get access to over thousands of
              registered users instantly. You can transform your business by
              ensuring an all-Nepal presence with this one decision.
            </p>
          </div>
          <div className="mt-5">
            <p className="font-weight-bold" style={{ color: "orange" }}>
              Low set-up costs
            </p>
            <p>
              Unlike a shop where you have to impress customers with a good
              display of products, a trial room, perfect lighting or appealing
              interiors, when you sell online, you don’t have to invest any
              money towards this. You can set up your business for a fraction of
              the cost when you sell on Kreesa. Just this month, Kreesa has
              lowered its commission and xed fee on a host of product
              categories. This means that you get to keep more of the pro ts and
              use it to grow your online business.
            </p>
          </div>
          <div className="mt-5">
            <p className="font-weight-bold" style={{ color: "orange" }}>
              Ready logistics network
            </p>
            <p>
              When you sell online, you don’t have to worry about how you will
              deliver products to the customer or how much you will have to
              spend on this. When you choose the right platform, such as Kreesa,
              you can use the logistics network that has been created and
              perfected with ease. With hassle-free pick-up and delivery of
              products through 5+ pick-up hubs and 1000 delivery agents, you can
              enjoy the delivery experience and also insure yourself.
            </p>
          </div>
          <div className="mt-5">
            <p className="font-weight-bold" style={{ color: "orange" }}>
              Low marketing cost
            </p>
            <p>
              Wondering how you will stand out amongst the lakhs of sellers when
              you take your business online? Don’t worry, you can market your
              business without spending a lot. When you sell on Kreesa, for
              example, you are connected with staffs of Kreesa and photograph
              your products so that they look appealing to the customer, Kreesa
              staffs give you advice on promotions and advertising, and also
              help you take informed business decisions.Your review and rating
              by customers also give patch of good impression.
            </p>
          </div>
          <div className="mt-5">
            <p className="font-weight-bold" style={{ color: "orange" }}>
              Access to market trends
            </p>
            <p>
              When you build your online business on Kreesa, you can also bene t
              from market trends. Kreesa collects information on various aspects
              and helps you understand what products have a high demand, what
              are the new trends in the market, what products sell better, etc.
              You can use this information to make necessary changes to your
              stock and in turn increase your revenue.
              <br />
              With these 5 reasons in mind, take your business to the next level
              by making the decision to sell online. You can register in just a
              few minutes and make use of the simple seller platform to manage
              your business and grow it too.
            </p>
            <p>
              Register Your business at{" "}
              <a href="/login">https://kreesa.com/signup</a>
            </p>
            <p>
              You can be retailer or Wholesaler as you like. Retailer supply to
              end consumers whereas Wholesaler supplies to Retailers.
            </p>
            <p>
              Wholesaler here in Kreesa includes Manufacturers , Distributors
              and Wholesalers. Wholesalers can buy from Manufacturers ,
              Distributors and other Wholesalers and can sell to Retailers
              registered on kreesa.com. There is no any boundary to sell.
              Retailers as well as wholesalers can order using Image and voice
              under custom order section on kreesa.com.
            </p>
            <p>
              Also for the very rst time in Nepal you get a chance to offer your
              price. Suppliers from Nepal, India, China, Bangladesh etc. will
              ght for the best rate for you. Once you accept the price offered
              by supplier order is completed.
            </p>
          </div>

          <h1
            className="mt-5 font-weight-bold text-danger"
            style={{ color: "#319ffb " }}
          >
            FAQ For seller:
          </h1>
          <h1 className="mt-3 font-weight-bold">Getting Started:</h1>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Why Should I sell on Kreesa?
            </p>
            <p>
              Kreesa is the leader in Nepalese e-commerce with maximum online
              reach and highest credibility. With more than thousands of
              registered customers, thousands of daily page visits and the
              lowest cost of doing business, we are the strongest partner to
              take your products to customers all over Nepal. We have sale
              events that give each seller an equal opportunity to grow their
              business online.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Who can sell on Kreesa?
            </p>
            <p>
              Anyone selling new and genuine products is welcome. In order to
              start selling, you need to have :
              <ul>
                <li>Tax certificate</li>
                <li>Cancelled Cheque</li>
                <li>Sample Signatures</li>
              </ul>
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How do I sell on Kreesa?
            </p>
            <p>
              To sell on Kreesa:
              <ol type="1">
                <li>
                  Register yourself at{" "}
                  <a href="/login">https://kreesa.com/signup</a>.
                </li>
                <li>List your products under speci c product categories.</li>
                <li>
                  On receiving an order, pack the product and mark it as ‘Ready
                  to Dispatch’. Our logistics partner will pick up the product
                  and deliver it to the customer.
                </li>
                <li>
                  After your order is successfully dispatched, Kreesa will
                  settle your payment within 7-15 business days based on your
                  account that you have provided as primary recipient.
                </li>
              </ol>
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Can I offer both products and services on Kreesa?
            </p>
            <p>
              Currently, you can only sell products and not services on Kreesa.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Do I need to courier my products to Kreesa?
            </p>
            <p>
              No, Kreesa will handle shipping of your products. All you need to
              do is pack the product and keep it ready for dispatch. Our
              logistics partner will pick up the product from you and deliver it
              to the customer.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              What are the documents required to register as a seller on Kreesa?
            </p>
            <p>
              You are required to have the following documents:
              <ul>
                <li>Tax Certificate</li>
                <li>Cancelled cheque</li>
                <li>Sample signatures</li>
              </ul>
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Who decides the price of the products?
            </p>
            <p>As a seller, you will set the price of your products.</p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Will I get charged for listing products on Kreesa?
            </p>
            <p>
              No. Listing your products on Kreesa.com is absolutely free. Kreesa
              does not charge anything for listing your catalogue online. You
              only pay a small commission or xed payment for what you sell.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Who takes care of the delivery of my products?
            </p>
            <p>
              Our logistics partner will pick up the product from you and
              deliver it to the customer. All you need to do is keep it packed
              and ready for dispatch.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How and when will I get paid?
            </p>
            <p>
              The payment will be made directly to your bank account through
              NEFT transactions(On your primary account). Kreesa will settle
              your payments within 7-15 business days.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              When can I start selling?
            </p>
            <p>
              After all the required documents have been veri ed and your seller
              pro le is complete, you can start listing your products and start
              selling. Kreesa need to approve your application after reviewing
              all your documents and inputs in order to launch .
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How many listings are required to start selling?
            </p>
            <p>
              You are required to have a minimum of 1 listings(unique products)
              to start selling on Kreesa.
            </p>
          </div>

          <h1 className="mt-5 font-weight-bold ">Pricing and Payments:</h1>
          <p className="font-weight-bold">
            Who decides the price of the product?
          </p>
          <p>As a seller, you will set the price of your products.</p>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              What are the fees charged?
            </p>
            <p>
              The following deductions are made from the order item value:
              <ol type=".">
                <li>
                  Commission fee: A percentage of the order item value based on
                  vertical/sub-category
                </li>
                <li>
                  Shipping fee: Calculated on the basis of the product weight
                  and shipping location
                </li>
                <li>
                  Collection fee: This will vary based on order item value and
                  customer payment mode (Prepaid/Cash on Delivery)
                </li>{" "}
                <li>
                  Collection fee: This will vary based on order item value and
                  customer payment mode (Prepaid/Cash on Delivery)
                </li>
              </ol>
              You can view the entire pricing details.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              What is Commission fee and how much commission is charged?
            </p>
            <p>
              Commission fee is a certain percentage of the order item value of
              your product. It differs across categories and vertical/sub-
              categories.
              <br />
              Also for the very rst time, we accept xed payment and you can
              supply products without any limit.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Please give an example to show the cost calculation.
            </p>
            <p>
              Here’s an easy example, which illustrates a sample for the
              calculation.
              <br />
              If you have selected percentage based commissions :
            </p>
            <p
              className="font-weight-bold"
              style={{ textDecoration: "underline", fontStyle: "italic" }}
            >
              If % rate is 10% and you sell product for Rs 1000. Then commission
              charged by kreesa would be 1000*10% = 100.
            </p>

            <p
              className="font-weight-bold"
              style={{ textDecoration: "underline", fontStyle: "italic" }}
            >
              Kreesa deducts its commission and pay you the remaining amount
              once return period gets over. If you opt for xed commission basis
              you have to pay xed amount before your registration gets veri ed
              from kreesa team.
            </p>
            <p
              className="font-weight-bold"
              style={{ textDecoration: "underline", fontStyle: "italic" }}
            >
              Pay on our esewa id: kreesasales@gmail.com
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How and when do I get paid?
            </p>
            <p>
              All payments are made through online transactions (online
              banking). The payment is made directly to your bank account within
              the next 5-10 business days from the date of order dispatch. It's
              5 business days for 1st category, 7 business days for 2nd category
              and 10 business days for 3rd Category.
              <br />
              1st category: Insured and Registered.
              <br />
              2nd category: Registered but not Insured or Insured but not
              registered.
              <br />
              3rd Category: Unregistered and Uninsured business.
            </p>
          </div>

          <h1 className="mt-5 font-weight-bold ">Listing and Catalogue:</h1>

          <div className="">
            <p className="mt-3 font-weight-bold" style={{ color: "#319ffb " }}>
              What is listing?
            </p>
            <p>
              Listing a product refers to lling out all the necessary
              information and adding images of the product so that a customer
              can make an informed buying decision.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How many products do I need to list to start selling?
            </p>
            <p>
              You are required to have a minimum of 1 listings to start selling
              on <a href="/">kreesa.com</a>.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How do I list my products on kreesa.com?
            </p>
            <p>
              We give you a step-by-step process of how to list your products on
              our website. It is important to choose the most suitable category
              to list your product as it will help customers nd your products
              faster. Based on the category you choose, you'll be asked to
              include product details such as size, model, color, etc.
              <br />
              Also, you can add variations of same products.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Can I get help for the development of the catalog (product images,
              description, etc.)?
            </p>
            <p>
              Yes, we are happy to help you at every stage while doing business
              with us. We help you connect with industry experts for the
              development of your catalogs. With the help of our catalog
              partners across Nepal, you can have attractive images and crisp
              content developed at unbeatable prices.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How does a catalog partner help me?
            </p>
            <p>
              Our catalog partners develop high-quality photographs of your
              products and crisp product descriptions for your product catalog.
              A good catalog gives your customers a better understanding of your
              products and helps boost your sales. List of cataloging partners
              here.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How do I price my products?
            </p>
            <p>
              When pricing products on Kreesa, please account for the applicable
              Marketplace Fee and include a suitable margin to arrive at the
              Selling Price. For ease of calculation, you can add our Commission
              too.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Will I get charged for listing products on Kreesa?
            </p>
            <p>
              No. Listing of products on kreesa.com is absolutely free. Kreesa
              does not charge anything to you for listing your catalogue online.
              You only pay a small commission for what you sell.
            </p>
          </div>

          <h1 className="mt-5 font-weight-bold ">
            Order management and Shipping:
          </h1>
          <div className="">
            <p className="mt-3 font-weight-bold" style={{ color: "#319ffb " }}>
              Who takes care of the delivery of my products?
            </p>
            <p>
              Our logistics partner will pick up the product from you and
              deliver it to the customer. All you need to do is keep it packed
              and ready for dispatch.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              What should I do if my area is not serviceable by Kreesa?
            </p>
            <p>
              During registration, save the details of your Landmark and click
              on the Continue button. You will be noti ed via e-mail when your
              Landmark area becomes serviceable. You can register on kreesa.com
              without any aws. Once your area is serviceable we will approve
              your registration.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              How do I manage my orders on Kreesa?
            </p>
            <p>
              Through our seller dashboard, we make it really easy for you to
              manage your orders. Whenever a customer places an order, we will
              send you an e-mail alert and you will get noti cations. You need
              to pack the order and keep it ready for dispatch within the time
              frame provided by you and inform us through the seller portal
              clicking ready to dispatch button. This will alert our logistics
              partner to pick up the product from you.
            </p>
          </div>

          <div className="mt-5">
            <p className="mt-5 font-weight-bold" style={{ color: "#319ffb " }}>
              Does Kreesa provide packaging material?
            </p>
            <p>
              We have a strong network of best packaging material providers in
              the industry. We can connect you with them to get good quality
              packaging material which impresses the customers and ensures your
              products remain undamaged.
            </p>
            <p>The answer is soft “No”.</p>
          </div>
        </div>
      </div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default onlineSellingGuide;
