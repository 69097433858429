import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsOfWholesaler } from "../../Features/Action/ProductAction";
import { filterProductofWholesale } from "../../Features/Reducer/ProductReducer/WholesalerReducer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import RecentProductsTab from "./RecentProductTab";
import RejectedProductTab from "./RejectedProductTab";
import ApproveProductTab from "./ApproveProductTab";
import BlockedProductsTab from "./BlockedProductTab";

const ViewProductDetails = () => {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const { Wholesalerproducts, loader } = useSelector(
    (s) => s.ProductWholesalerReducer
  );

  useEffect(() => {
    dispatch(getProductsOfWholesaler());
  }, []);

  const filterWholesaler = () => {
    dispatch(filterProductofWholesale(inputRef.current.value));
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex w-100">
            <div className="card flex-fill flex-fill">
              <div className="card-header">
                <form className="mt-2 d-sm-inline-block">
                  <div className="input-group input-group-navbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Wholesaler's Product…"
                      aria-label="Search"
                      ref={inputRef}
                      onChange={filterWholesaler}
                    />
                    <button className="btn" type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Recent Products</Tab>
            <Tab>Approved Products</Tab>
            <Tab>Rejected Products</Tab>
            {localStorage.getItem("auth") === "ROLE_CEO" && (
              <Tab>Blocked Products</Tab>
            )}
          </TabList>

          <TabPanel>
            {/* Recent Products */}
            <RecentProductsTab
              data={Wholesalerproducts.filter(
                (p) => p.productStatus === "Pending"
              )}
              loader={loader}
            />
          </TabPanel>

          <TabPanel>
            {/* Approved Products */}
            <ApproveProductTab
              data={Wholesalerproducts.filter(
                (p) => p.productStatus === "Approved"
              )}
              loader={loader}
            />
          </TabPanel>
          <TabPanel>
            {/* Blocked Products */}
            <RejectedProductTab
              data={Wholesalerproducts.filter(
                (p) => p.productStatus === "Rejected"
              )}
              loader={loader}
            />
          </TabPanel>
          {localStorage.getItem("auth") === "ROLE_CEO" && (
            <TabPanel>
              {/* Blocked Products */}
              <BlockedProductsTab
                data={Wholesalerproducts.filter(
                  (p) => p.productStatus === "Blocked"
                )}
                loader={loader}
              />
            </TabPanel>
          )}
        </Tabs>

        {/* pagination  */}
        {/* <Pagination /> */}
      </div>
    </div>
  );
};

export default ViewProductDetails;
