import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "./Dashboard/index.js";
import Footer from "./Footer/index.js";
import LeftNavbar from "./LeftNavbar/index.js";
import TopNavbar from "./TopNavbar/index.js";
const AdminRoute = () => {
  document.title = "Admin | Kreesa";

  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }


  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <Dashboard />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AdminRoute;
