import React, { useEffect } from "react";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import Header from "../../RetailerWholeSaler/Header";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import Nav from "../../RetailerWholeSaler/Nav";
import UserFooter from "../UserFooter";
import { useDispatch, useSelector } from "react-redux";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { useParams } from "react-router-dom";
import { getBlogById } from "../Features/action";
import moment from "moment";
import SocialShare from "../../RetailerWholeSaler/SocialShare";
import HtmlParser from "react-html-parser";
import Spinner from "../../Admin/Spinner";
import ModalImage from "react-modal-image";

const SinglePage = () => {
  const { blog, loader } = useSelector((state) => state.userProducts);

  document.title = `Kreesa | ${blog?.title}`;

  const { id } = useParams();

  const dispatch = useDispatch();

  const str = blog?.blogType?.toLowerCase();

  useEffect(() => {
    dispatch(getBlogById(id));
  }, [id]);

  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      {!loader ? (
        <div
          className="container mt-4 mb-2 px-lg-5"
          style={{
            userSelect: "none",
          }}
        >
          <p
            style={{
              fontSize: 18,
            }}
            className="py-2"
          >
            <a
              href={
                localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
                localStorage.getItem("auth") === "ROLE_RETAILER"
                  ? "/home"
                  : "/"
              }
              className="text-muted"
            >
              {" "}
              Home /{" "}
            </a>
            <a
              href="/news"
              className="text-muted"
              style={{
                textTransform: "capitalize",
              }}
            >
              {str?.charAt(0)?.toUpperCase() + str?.slice(1)}
            </a>{" "}
            / {blog?.title}
          </p>
          <p
            style={{
              height: 1,
              background: "#3b7ddd",
            }}
          ></p>
          <div className="px-lg-5">
            <h3 className="news__blog_notice_clr">{blog?.title}</h3>

            <h4 className="text-muted mt-4">
              Posted on {moment(blog?.createdAt).local().format("MMM Do YYYY")}
            </h4>

            <div className="row mt-4">
              <SocialShare
                url={window.location.href}
                description={blog?.title}
                image={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
              />
            </div>
            <div
              className="row mt-4 py-4"
              style={{
                background: "#e3e3e3",
                borderRadius: 5,
              }}
            >
              <div className="col-md-6">
                {/* <img
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
                  alt={blog?.title}
                  loading="lazy"
                  className="img-fluid"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  onClick={() => setShown(true)}
                /> */}
                <ModalImage
                  alt={blog?.title}
                  small={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
                  large={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog?.id}/${blog?.image}`}
                  className="img-fluid"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  loading="lazy"
                  showRotate={true}
                  hideDownload={true}
                />
              </div>
              <div className="col-md-6">{HtmlParser(blog?.shortDesc)}</div>

              {/* image */}
              {/* <img
                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/blog/${blog.id}/${blog.image}`}
                alt={blog.title}
                loading="lazy"
                className="mt-4"
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
              /> */}

              <p className="mt-4">{HtmlParser(blog?.longDesc)}</p>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default SinglePage;
