import AxiosConfig from '../../Api/AxiosConfig'

const s={
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/graphql"
}
}
  
  const get = () => {
    let email=localStorage.getItem("username")
    return AxiosConfig.get(`/kreesa/user/view/${email}`,s);
  };
  
  const create = data => {
    return AxiosConfig.post("/tutorials", data);
  };
  
 export const update = (data) => {
    return AxiosConfig.post("/kreesa/wholesaler/update-profile",data,s);
  };
  
  const remove = id => {
    return AxiosConfig.delete(`/tutorials/${id}`);
  };
  
  const removeAll = () => {
    return AxiosConfig.delete(`/tutorials`);
  };
  
  const findByTitle = title => {
    return AxiosConfig.get(`/tutorials?title=${title}`);
  };
  
  const TutorialService = {
    // getAll,
    get,
    create,
    update,
    remove,
    removeAll,
    findByTitle
  };
  
  export default TutorialService;