import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDeliveryStatus,
  deliveryBoyConfirm,
  getAllDelivery,
} from "../../Features/Action/DeliveryAction";
import { filterDelivery } from "../../Features/Reducer/DeliveryReducer";
import { Pagination } from "@mantine/core";
import Spinner from "../../../Admin/Spinner";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { Button } from "react-bootstrap";

const Delivery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [deliveryStatus, setDeliveryStatus] = useState("");

  const [orderId, setOrderId] = useState();

  const inputRef = useRef();

  // DeliveryReducer
  const dispatch = useDispatch();
  const { deliveries, loader } = useSelector((s) => s.DeliveryReducer);

  // handleConfirmDeliveryspinner
  const handleConfirmDelivery = (data) => {
    dispatch(deliveryBoyConfirm(data));
  };

  useEffect(() => {
    dispatch(getAllDelivery(localStorage.getItem("userId")));
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = deliveries?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(deliveries?.length / perPage);

  const filter = () => {
    dispatch(filterDelivery(inputRef.current.value));
  };

  return !loader ? (
    <div className="container">
      <h3>Delivery Orders</h3>
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block col-lg-4">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Orders by Order Number…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th className="d-xl-table-cell">Order Number</th>
                    <th>Contact </th>
                    <th className="d-xl-table-cell">Order Date</th>
                    <th className="d-md-table-cell">Shipping Address</th>
                    <th>Status</th>
                    {/* <th>Delivery Status</th> */}
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td className="d-xl-table-cell">{p.orderNo}</td>
                      <td>{p.contactNo}</td>
                      <td className="d-xl-table-cell">
                        {moment(p.orderDate).format("ll")}
                      </td>

                      <td className="d-md-table-cell">
                        {p.shippingAddress}, {p.shippingCountry}
                      </td>
                      <td>
                        {p.deliveryFlag === "DELIVERED" ? (
                          <span className="badge bg-success">
                            {p.deliveryFlag}
                          </span>
                        ) : p.deliveryFlag === "RETURNED" ? (
                          <span className="badge bg-danger">
                            {p.deliveryFlag}
                          </span>
                        ) : p.deliveryFlag === "ON_WAY" ? (
                          <span className="badge bg-primary">
                            {p.deliveryFlag}
                          </span>
                        ) : (
                          <span className="badge bg-info">
                            {p.deliveryFlag}
                          </span>
                        )}
                      </td>

                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              // onClick={() => {
                              //   setModal(true);
                              //   setModalData(p);
                              // }}
                              className="dropdown-item text-info"
                              to={`/delivery/get-delivery-order/${p.id}`}
                            >
                              <i className="fas fa-eye"></i> View More
                            </Link>
                            {p.deliveryFlag !== "DELIVERED" && (
                              <div>
                                <div className="dropdown-divider"></div>
                                <div
                                  onClick={() => {
                                    setModal(true);
                                    setModalData(p);
                                    setDeliveryStatus(p.deliveryFlag);
                                  }}
                                  className="dropdown-item text-info cursor-pointer"
                                >
                                  <i className="fas fa-check"></i> Change
                                  Delivery Status
                                </div>
                                {/* {p.deliveryFlag === null && (
                                  <>
                                    <div className="dropdown-divider"></div>
                                    <div
                                      className="dropdown-item cursor-pointer text-success"
                                      onClick={() =>
                                        handleConfirmDelivery({
                                          id: p.id,
                                          status: "DISPATCHED",
                                        })
                                      }
                                    >
                                      <i className="fa fa-check"></i> Confirm
                                      Delivery
                                    </div>
                                  </>
                                )} */}
                              </div>
                            )}

                            {/* {p.deliveryFlag === false && (
                              <>
                                <div className="dropdown-divider"></div>
                                <div
                                  className="dropdown-item cursor-pointer text-success"
                                  onClick={() =>
                                    handleConfirmDelivery({
                                      id: p.id,
                                      status: "DELIVERED",
                                    })
                                  }
                                >
                                  <i className="fa fa-check"></i> Confirm
                                  Delivery
                                </div>
                              </>
                            )} */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <Modal
                  show={modal}
                  onHide={() => setModal(false)}
                  size="md"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title className="font-weight-light text-center w-100 d-flex justify-content-between">
                      <div>Change Delivery Status</div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setModal(false)}
                      >
                        X
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <div
                          className="d-flex flex-row mt-2 mb-4"
                          style={{ gap: "60px" }}
                        >
                          {["ON_WAY", "DELIVERED", "RETURNED"].map((i) => (
                            <div className="form-check">
                              <input
                                onChange={(e) => setDeliveryStatus(i)}
                                className="form-check-input"
                                type="radio"
                                name="deliveryStatus"
                                id={i}
                                checked={deliveryStatus === i}
                              />
                              <label className="form-check-label" for={i}>
                                {i}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Button
                        onClick={() => {
                          if (deliveryStatus !== "DISPATCHED") {
                            dispatch(
                              deliveryBoyConfirm({
                                id: modalData.id,
                                status: deliveryStatus,
                                type: "order",
                                userId: localStorage.getItem("userId"),
                              })
                            );
                            setModal(false);
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="p-2 text-center">
          <h3>No Data Found</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default Delivery;
