import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Spinner from "../../Spinner";
import { Pagination } from "@mantine/core";
import { Excerpt, useScrollHandler } from "../../../../utils";
import { toast } from "react-toastify";
import {
  deleteDiscountVoucher,
  viewAllDiscountVoucher,
} from "../../Features/Action/DiscountAction";
import { filterDiscount } from "../../Features/Reducer/DiscountReducer";
import moment from "moment/moment";

const VoucherList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const inputRef = useRef("");
  const history = useHistory();

  const { discountData, loader } = useSelector((s) => s.DiscountReducer);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = discountData?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(discountData?.length / perPage);

  useEffect(() => {
    dispatch(viewAllDiscountVoucher());
  }, []);

  // Search
  const filterQuotation = () => {
    dispatch(filterDiscount(inputRef.current.value));
  };

  return !loader ? (
    <div className="container">
      <div className="row">
        <h4>Discount Voucher List</h4>
        <div className="mt-3 col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <div
                onClick={() => history.push("/admin/discount-voucher/add")}
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Create Discount Voucher <i className="fas fa-percent"></i>
              </div>

              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Discount Voucher..."
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterQuotation}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div
              className="card flex-fill overflow-auto"
              onMouseEnter={useScrollHandler}
            >
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Discount Voucher</h5>
              </div>
              <table className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Title</th>
                    <th>Code</th>
                    <th>Target User</th>
                    <th>Discount</th>
                    <th>Max Discount</th>
                    <th>Max User</th>
                    <th>User Limit</th>
                    <th>Expiry Date</th>
                    <th>Created Date</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>{Excerpt(p.title, 30)}</td>

                      <td className="d-xl-table-cell cursor-pointer">
                        {p.code}{" "}
                        <i
                          className="fas fa-copy"
                          style={{
                            fontSize: 18,
                            color: "black",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(p.code)
                            .then(() => toast.success("Copied to clipboard"))
                            .catch((error) => console.error('Failed to copy text to clipboard:', error));
                          }}
                        ></i>
                      </td>
                      <td className="d-xl-table-cell">
                        <span
                          className={`badge bg-${
                            p.targetUser === "CUSTOMER" ? "info" : "success"
                          } p-1`}
                        >
                          {p.targetUser}
                        </span>
                      </td>

                      <td className="d-xl-table-cell">{p.discount}</td>
                      <td className="d-xl-table-cell">{p.maxDiscount}</td>
                      <td className="d-xl-table-cell">{p.maxUser}</td>
                      <td className="d-xl-table-cell">{p.userLimit}</td>
                      <td className="d-xl-table-cell">
                        {moment(p.expiryDate).format("DD MMM YYYY")}
                      </td>
                      <td className="d-xl-table-cell">
                        {moment(p.createdAt).format("DD MMM YYYY")}
                      </td>

                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <div
                              className="text-danger cursor-pointer"
                              onClick={() =>
                                dispatch(deleteDiscountVoucher(p.id))
                              }
                            >
                              <i className="fa fa-trash mr-2"></i> Delete
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Discount Voucher Found !</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default VoucherList;
