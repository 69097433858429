import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../../Footer";
import LeftNavbar from "../../LeftNavbar";
import TopNavbar from "../../TopNavbar";
import ShowProductDetail from "./ShowProductDetail";

const Product = () => {
  document.title = `${
    localStorage === "ROLE_WHOLESALER" ? "Wholsaler" : "Retailer"
  } | Product`;
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  // if (token !== "ROLE_RETAILER") {
  //   return <Redirect exact to="/login" />;
  // }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <ShowProductDetail />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Product;
