import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deliveryBoyConfirm,
  getAllDelivery,
  getAllQuotationDelivery,
  changeDeliveryStatus,
} from "../../Features/Action/DeliveryAction";
import { filterQuotationDelivery } from "../../Features/Reducer/DeliveryReducer";
import { Pagination } from "@mantine/core";
import Spinner from "../../../Admin/Spinner";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { Button } from "react-bootstrap";

const QuotationsDelivery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [modalData, setModalData] = useState({});

  const [orderId, setOrderId] = useState();

  const inputRef = useRef();

  // DeliveryReducer
  const dispatch = useDispatch();
  const { quotationDeliveries, loader } = useSelector((s) => s.DeliveryReducer);

  // handleConfirmDeliveryspinner
  const handleConfirmDelivery = (data) => {
    dispatch(deliveryBoyConfirm(data));
  };

  useEffect(() => {
    dispatch(getAllQuotationDelivery(localStorage.getItem("userId")));
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = quotationDeliveries?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(quotationDeliveries?.length / perPage);

  const filter = () => {
    dispatch(filterQuotationDelivery(inputRef.current.value));
  };

  return !loader ? (
    <div className="container">
      <h3>Quotation Delivery Orders</h3>
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block col-lg-4">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Quotation by Address…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th className="d-xl-table-cell">Product Name</th>
                    <th className="d-xl-table-cell">Created By</th>
                    <th className="d-xl-table-cell">Created Date</th>
                    <th className="d-md-table-cell">Shipping Address</th>
                    <th className="d-md-table-cell">Contact</th>
                    <th className="d-md-table-cell">Total Price</th>
                    <th>Status</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td className="d-xl-table-cell">{p.product_name}</td>
                      <td className="d-xl-table-cell">{p.created_by}</td>
                      <td className="d-xl-table-cell">
                        {moment(p.created_date).format("ll")}
                      </td>

                      <td className="d-md-table-cell">
                        {p.shipping_address}, {p.shipping_state},{" "}
                        {p.shipping_country}
                      </td>
                      <td>{p.contact_no}</td>
                      <td>
                        Rs. {p.actual_price * p.quantity + p.shipping_cost}
                      </td>
                      <td>
                        {" "}
                        {p.delivery_status === "DELIVERED" ? (
                          <span className="badge bg-success">
                            {p.delivery_status}
                          </span>
                        ) : p.delivery_status === "RETURNED" ? (
                          <span className="badge bg-danger">
                            {p.delivery_status}
                          </span>
                        ) : p.delivery_status === "ON_WAY" ? (
                          <span className="badge bg-primary">
                            {p.delivery_status}
                          </span>
                        ) : (
                          <span className="badge bg-info">
                            {p.delivery_status}
                          </span>
                        )}
                      </td>
                      <td className="d-md-table-cell">
                        {p.delivery_status !== "DELIVERED" && (
                          <div className="btn-group">
                            <button
                              type="button"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#3b7ddd",
                              }}
                              className="btn-md btn btn-success dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              More
                            </button>
                            <div className="dropdown-menu text-center">
                              {p.delivery_status !== "DELIVERED" && (
                                <div
                                  onClick={() => {
                                    setModal(true);
                                    setModalData(p);
                                    setDeliveryStatus(p.delivery_status);
                                  }}
                                  className="dropdown-item text-info cursor-pointer"
                                >
                                  <i className="fas fa-eye"></i> Change Delivery
                                  Status
                                </div>
                              )}
                              {p.delivery_status === false && (
                                <>
                                  <div className="dropdown-divider"></div>
                                  <div
                                    className="dropdown-item cursor-pointer text-success"
                                    onClick={() =>
                                      handleConfirmDelivery({
                                        id: p.id,
                                        status: "DISPATCHED",
                                      })
                                    }
                                  >
                                    <i className="fa fa-check"></i> Confirm
                                    Delivery
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <Modal
                  show={modal}
                  onHide={() => setModal(false)}
                  size="md"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title className="font-weight-light text-center w-100 d-flex justify-content-between">
                      <div>Change Delivery Status</div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setModal(false)}
                      >
                        X
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <div
                          className="d-flex flex-row mt-2 mb-4"
                          style={{ gap: "60px" }}
                        >
                          {["ON_WAY", "DELIVERED", "RETURNED"].map((i) => (
                            <div className="form-check">
                              <input
                                onChange={(e) => setDeliveryStatus(i)}
                                className="form-check-input"
                                type="radio"
                                name="deliveryStatus"
                                id={i}
                                checked={deliveryStatus === i}
                              />
                              <label className="form-check-label" for={i}>
                                {i}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Button
                        onClick={() => {
                          if (deliveryStatus !== "DISPATCHED") {
                            dispatch(
                              changeDeliveryStatus({
                                quoteId: modalData.id,
                                status: deliveryStatus,
                              })
                            );
                            setModal(false);
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="p-2 text-center">
          <h3>No Data Found</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default QuotationsDelivery;
