import { createSlice } from "@reduxjs/toolkit";
import {
  deleteDiscountVoucher,
  viewAllDiscountVoucher,
} from "../../Action/DiscountAction";

const DiscountReducer = createSlice({
  name: "DiscountReducer",
  initialState: {
    discountData: [],
    discountDataContainer: [],
    singleDoc: [],
    loader: false,
    errors: {},
  },
  reducers: {
    filterDiscount: (state, action) => {
      state.discountData = state.discountDataContainer.filter(
        (u) =>
          u.title.toLowerCase().includes(action.payload) ||
          u.title.toUpperCase().includes(action.payload)
      );
    },
  },
  extraReducers: {
    /* to get all Group Product */
    [viewAllDiscountVoucher.pending]: (state, action) => {
      state.loader = true;
    },
    [viewAllDiscountVoucher.fulfilled]: (state, action) => {
      state.loader = false;
      state.discountData = action.payload;
      state.discountDataContainer = action.payload;
    },
    [viewAllDiscountVoucher.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [deleteDiscountVoucher.pending]: (state, action) => {
      state.loader = true;
    },
    [deleteDiscountVoucher.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [deleteDiscountVoucher.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterDiscount } = DiscountReducer.actions;
// export default
export default DiscountReducer.reducer;
