import React, { useEffect } from "react";
import UserFooter from "../../User/UserFooter";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import { useParams } from "react-router-dom";
import ViewAllCard from "./ViewAllCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRetailerBrandProducts,
  getAllRetailerFeatureProducts,
  getAllRetailerFlashSale,
  getAllRetailerOrganicProducts,
  getAllRetailerTrendingProducts,
} from "../Features/action";
import Spinner from "../../Admin/Spinner";

const ViewAll = (props) => {
  const { title } = useParams();
  document.title = `Kreesa | ${title}`;
  const getTitleProps = props?.location.state.title;
  //

  const dispatch = useDispatch();
  const {
    allBrandProducts,
    loader,
    allTrendingProducts,
    allFeatureProducts,
    allOrganicProducts,
    allFlashProducts,
  } = useSelector((state) => state.userProducts);

  // featured products
  useEffect(() => {
    if (getTitleProps === "Brand products") {
      dispatch(getAllRetailerBrandProducts("retail"));
    }
    if (getTitleProps === "Trending Products") {
      dispatch(getAllRetailerTrendingProducts("retail"));
    }
    if (getTitleProps === "Feature Products") {
      dispatch(getAllRetailerFeatureProducts("retail"));
    }
    if (getTitleProps === "Organic Products") {
      dispatch(getAllRetailerOrganicProducts("retail"));
    }
    if (getTitleProps === "Flash Sale") {
      dispatch(getAllRetailerFlashSale("retail"));
    }
  }, []);

  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      {!loader ? (
        <div className="container-fluid mt-4">
          {/* /* ---------------------------- view all product start ----------------------------  */}
          <h3 className="text-muted text-center mb-2">{title}</h3>
          <div>
            {/* Flash Sale */}
            {getTitleProps === "Flash Sale" && allFlashProducts?.length > 0 && (
              <ViewAllCard data={allFlashProducts} />
            )}
            {/*  */}
            {/* all Brand */}
            {getTitleProps === "Brand products" &&
              allBrandProducts?.length > 0 && (
                <ViewAllCard data={allBrandProducts} />
              )}
            {/*  */}
            {getTitleProps === "Trending Products" &&
              allTrendingProducts?.length > 0 && (
                <ViewAllCard data={allTrendingProducts} />
              )}
            {/*  */}
            {getTitleProps === "Feature Products" &&
              allFeatureProducts?.length > 0 && (
                <ViewAllCard data={allFeatureProducts} />
              )}
            {/*  */}
            {getTitleProps === "Organic Products" &&
              allOrganicProducts?.length > 0 && (
                <ViewAllCard data={allOrganicProducts} />
              )}
          </div>

          {/* <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          /> */}

          {/* /* ---------------------------- view all product end ----------------------------  */}
        </div>
      ) : (
        <Spinner />
      )}

      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default ViewAll;
