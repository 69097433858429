import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect } from "react";
import Spinner from "../../../Spinner";
import { FileUploader } from "react-drag-drop-files";
import useButtonLoader from "../../../../ButtonLoaderHook";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

const EditSallerBannerForm = () => {
  const { bId } = useParams();
  const history = useHistory();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState();
  const [bannerPosition, setBannerPosition] = useState();
  const [file, setFile] = useState();
  const fileTypes = ["JPG", "PNG", "JPEG", "GIF", "WEBP"];

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Saller Banner",
    "Loading..."
  );

  const handleImageChange = (file) => {
    setFile(file);
  };
  const getSingleUserBanner = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      /* ------------------------- getting banner from db ------------------------- */
      const userBanner = await AxiosConfig.get(
        `/kreesa/admin/banner/get-banner-by-id/${bId}`,
        config
      );
      setState(userBanner.data);
      setLoading(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };
  /* --------------------------------- submit --------------------------------- */
  const onSubmitHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      data.append(
        "description",
        draftToHtml(convertToRaw(description.getCurrentContent()))
      );
      if (file) {
        data.append("file", file);
      }
      data.append("bannerPosition", values.bannerPosition);
      data.append("redirectionLink", values.redirectionLink);
      data.append("id", bId);
      data.append("type", "seller");
      const res = await AxiosConfig.post(
        "/kreesa/admin/banner/update-banner",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res) {
        toast.success("Banner Updated !");
        history.push("/admin/view/seller-banner");
        setButtonLoader(false);
      }
    } catch (err) {
      console.log(err);
      setButtonLoader(false);
    }
  };
  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {
    getSingleUserBanner();
  }, [bId]);
  /* ----------------------------- for wysiwyg editor ---------------------------- */
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  useEffect(() => {
    if (state.description != undefined) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(state?.description))
        )
      );
    }
  }, [state]);
  /* ----------------------------------------------------------------------------- */
  return !loading ? (
    <Formik
      enableReinitialize={true}
      initialValues={{
        bannerPosition: state?.bannerPosition,
        redirectionLink: state?.redirectionLink,
      }}
      onSubmit={onSubmitHandler}
    >
      {({ handleChange, handleSubmit, values, errors, touched }) => (
        <Form encType="multipart/form-data" onSubmit={handleSubmit}>
          <div className="col">
            <div className="mb-3 col-md-8">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Select Banner</span>
              </label>
              <FileUploader
                handleChange={handleImageChange}
                name="file"
                types={fileTypes}
              />
            </div>
            <div className="text-muted h5">
              Banner Image must be 1280 * 400 (pixels) Dimension
            </div>
          </div>
          <div className="col">
            <div className="mb-3 col-md-8">
              <img
                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${state.id}/${state.image}`}
                alt={state.image}
                height={120}
              />
            </div>
          </div>
          <div className="col">
            <div className="mb-3 col-md-5">
              <label
                className="form-label font-weight-bold"
                htmlFor="inputState"
              >
                Banner Position <span className="text-danger"> *</span>
              </label>
              <select
                id="inputState"
                name="bannerPosition"
                onChange={handleChange}
                value={values.bannerPosition || ""}
                className="form-select"
              >
                {/*  */}
                <option value="">Select Banner Position First...</option>
                <option value="TOP">Top</option>
                <option value="BOTTOM">Bottom</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="mb-3 col-md-5">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Redirection Link</span>
                <span className="text-info"> (optional)</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="redirectionLink"
                value={values.redirectionLink}
                onChange={handleChange}
                placeholder="Enter Redirection Link"
              />
            </div>
          </div>
          <div className="col">
            <div className="mb-3 col-md-8">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Banner Description</span>
                <span className="text-info"> (optional)</span>
              </label>
              <Editor
                editorState={description}
                onEditorStateChange={onEditorStateChange}
                editorStyle={{
                  backgroundColor: "white",
                  minHeight: "150px",
                  padding: "10px 20px",
                }}
                editorClassName="mt-2 p-10 mx-auto"
              />
            </div>
          </div>
          <div className="mb-3 mt-4 col-md-8">
            <button
              id="catadd"
              type="submit"
              className="btn btn-success btn-lg btn-block"
              ref={buttonLoader}
              //   disabled={!isValid}
            >
              Update User Banner
            </button>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <Spinner />
  );
};

export default EditSallerBannerForm;
