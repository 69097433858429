import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserDetails } from '../../../Common/Features/Action/AllUserDetailsAction';
import AxiosConfig from '../../../Common/Api/AxiosConfig';
import Spinner from '../../Admin/Spinner';
import logo from '../../../assets/Images/kreesalogo.png';
import cover from '../../../assets/Images/cover.jpg';

const SingleProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserDetails(localStorage.getItem('userId')));
  }, []);
  // to get all user Details by their relavent email
  const { userDetails, loader } = useSelector((s) => s.UserDetailsReducer);
  // console.log(userDetails);

  return !loader ? (
    <div className="container-fluid p-0">
      <h1 className="h3 mb-3">Profile</h1>

      <div className="row">
        <div className="col-md-4 col-xl-3">
          <div className="card mb-3">
            <div className="card-header">
              <h5 className="card-title mb-0">Profile Details</h5>
            </div>
            <div className="card-body text-center">
              {userDetails.companyLogo === null ? (
                <img
                  src={logo}
                  style={{ height: '200px', width: '200px' }}
                  className="avatar img-fluid rounded mr-1"
                  alt=""
                />
              ) : (
                <img
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${userDetails.userId}/${userDetails.companyLogo}`}
                  alt={userDetails?.companyLogo}
                  className="img-fluid rounded-circle mb-2"
                  style={{ height: '200px', width: '200px' }}
                />
              )}
              <h5 className="card-title mb-0">{userDetails?.companyName}</h5>
              <h4 style={{ color: 'green' }}>
                <i class="fas fa-award"></i> {userDetails.rewardPoints}
              </h4>

              <p className="text-white mt-3 bg-primary p-1">
                {userDetails?.companyType}
              </p>
            </div>
            {/* 
            <hr className="my-0" />
            <div className="card-body">
              <h5 className="h6 card-title font-weight-bold">About</h5>
              <ul className="list-unstyled mb-0">
                <li className="mb-1">
                  Lives in <a href="#">{userDetails.companyAddress}</a>
                </li>

                <li className="mb-1">
                  Contact <a href="#">{userDetails.companyContact}</a>
                </li>
              </ul>
            </div> */}
            <hr className="my-0" />
            <div className="card-body">
              <h5 className="h6 card-title font-weight-bold">About Company</h5>
              <ul className="list-unstyled mb-0">
                <li className="mb-1 text-justify">
                  Company Name: <a href="#">{userDetails?.companyName}</a>
                </li>
                {/* <li className="mb-1">
                  Company Type: <a href="#">{userDetails?.companyType}</a>
                </li> */}
                <li className="mb-1">
                  Company Address:{' '}
                  <a href="#">
                    {userDetails?.districtName} {userDetails?.provienceName}{' '}
                  </a>
                </li>
                <li className="mb-1">
                  Company Contact:{' '}
                  <a href="#">{userDetails?.companyContactNo}</a>
                </li>
                <li className="mb-1">
                  Company PAN: <a href="#">{userDetails?.panNo}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-8 col-xl-9">
          <div className="card" style={{ background: 'cornflowerblue' }}>
            <div className="card-body h-100">
              <div className="d-flex align-items-start">
                <div className="fb-profile ">
                  <img src={cover} className="fb-image-lg img-fluid" alt="bg" />
                  {/* <img
                    className="fb-image-profile thumbnail"
                    height="120"
                    src={logo}
                    alt="kreesa"
                    style={{ borderRadius: "25%", border: "5px solid black" }}
                  /> */}
                  <img
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${userDetails.userId}/${userDetails?.companyLogo}`}
                    alt={userDetails?.companyName}
                    className="fb-image-profile thumbnail"
                  />
                </div>
              </div>
              {/* <div className="d-flex align-items-start">
                    <img
                      src="/images/logo1.jpg"
                      width="36"
                      height="36"
                      className="rounded-circle mr-2"
                      alt="Vanessa Tucker"
                    />
                    <div className="flex-grow-1">
                      <small className="float-right text-navy">5m ago</small>
                      <strong>Vanessa Tucker</strong> started following{" "}
                      <strong>Christina Mason</strong>
                      <br />
                      <small className="text-muted">Today 7:51 pm</small>
                      <br />
                    </div>
                  </div> */}

              {/* <hr />
                  <div className="d-flex align-items-start">
                    <img
                      src="/images/logo1.jpg"
                      width="36"
                      height="36"
                      className="rounded-circle mr-2"
                      alt="Charles Hall"
                    />
                    <div className="flex-grow-1">
                      <small className="float-right text-navy">30m ago</small>
                      <strong>Charles Hall</strong> posted something on{" "}
                      <strong>Christina Mason</strong>'s timeline
                      <br />
                      <small className="text-muted">Today 7:21 pm</small>
                      <div className="border text-sm text-muted p-2 mt-1">
                        Etiam rhoncus. Maecenas tempus, tellus eget condimentum
                        rhoncus, sem quam semper libero, sit amet adipiscing sem
                        neque sed ipsum. Nam quam nunc, blandit vel, luctus
                        pulvinar, hendrerit id, lorem. Maecenas nec odio et ante
                        tincidunt tempus. Donec vitae sapien ut libero venenatis
                        faucibus. Nullam quis ante.
                      </div>
                      <a href="#" className="btn btn-sm btn-danger mt-1">
                        <i className="feather-sm" data-feather="heart"></i> Like
                      </a>
                    </div>
                  </div> */}

              {/* <hr />
                  <div className="d-flex align-items-start">
                    <img
                      src="/images/logo1.jpg"
                      width="36"
                      height="36"
                      className="rounded-circle mr-2"
                      alt="Christina Mason"
                    />
                    <div className="flex-grow-1">
                      <small className="float-right text-navy">1h ago</small>
                      <strong>Christina Mason</strong> posted a new blog
                      <br />
                      <small className="text-muted">Today 6:35 pm</small>
                    </div>
                  </div>
    
                  <hr /> */}
              {/* <div className="d-flex align-items-start">
                    <img
                      src="/images/logo1.jpg"
                      width="36"
                      height="36"
                      className="rounded-circle mr-2"
                      alt="William Harris"
                    />
                    <div className="flex-grow-1">
                      <small className="float-right text-navy">3h ago</small>
                      <strong>William Harris</strong> posted two photos on{" "}
                      <strong>Christina Mason</strong>'s timeline
                      <br />
                      <small className="text-muted">Today 5:12 pm</small>
                      <div className="row g-0 mt-1">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                          <img
                            src="/images/logo1.jpg"
                            className="img-fluid pr-2"
                            alt="Unsplash"
                          />
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                          <img
                            src="/images/logo1.jpg"
                            className="img-fluid pr-2"
                            alt="Unsplash"
                          />
                        </div>
                      </div>
                      <a href="#" className="btn btn-sm btn-danger mt-1">
                        <i className="feather-sm" data-feather="heart"></i> Like
                      </a>
                    </div>
                  </div>
    
                  <hr /> */}
              {/* <div className="d-flex align-items-start">
                    <img
                      src="/images/logo1.jpg"
                      width="36"
                      height="36"
                      className="rounded-circle mr-2"
                      alt="William Harris"
                    />
                    <div className="flex-grow-1">
                      <small className="float-right text-navy">1d ago</small>
                      <strong>William Harris</strong> started following{" "}
                      <strong>Christina Mason</strong>
                      <br />
                      <small className="text-muted">Yesterday 3:12 pm</small>
                      <div className="d-flex align-items-start mt-1">
                        <a className="pr-3" href="#">
                          <img
                            src="/images/logo1.jpg"
                            width="36"
                            height="36"
                            className="rounded-circle mr-2"
                            alt="Christina Mason"
                          />
                        </a>
                        <div className="flex-grow-1">
                          <div className="border text-sm text-muted p-2 mt-1">
                            Nam quam nunc, blandit vel, luctus pulvinar, hendrerit
                            id, lorem. Maecenas nec odio et ante tincidunt tempus.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
     */}
              {/* <hr />
                  <div className="d-flex align-items-start">
                    <img
                      src="/images/logo1.jpg"
                      width="36"
                      height="36"
                      className="rounded-circle mr-2"
                      alt="Christina Mason"
                    />
                    <div className="flex-grow-1">
                      <small className="float-right text-navy">1d ago</small>
                      <strong>Christina Mason</strong> posted a new blog
                      <br />
                      <small className="text-muted">Yesterday 2:43 pm</small>
                    </div>
                  </div>
    
                  <hr />
                  <div className="d-flex align-items-start">
                    <img
                      src="/images/logo1.jpg"
                      width="36"
                      height="36"
                      className="rounded-circle mr-2"
                      alt="Charles Hall"
                    />
                    <div className="flex-grow-1">
                      <small className="float-right text-navy">1d ago</small>
                      <strong>Charles Hall</strong> started following{" "}
                      <strong>Christina Mason</strong>
                      <br />
                      <small className="text-muted">Yesterdag 1:51 pm</small>
                    </div>
                  </div>
    
                  <hr />
                  <a href="#" className="btn btn-primary btn-block">
                    Load more
                  </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default SingleProfile;
