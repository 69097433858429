import React, { useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import UserLoginValidation from "../../Validation/UserLoginValidation";
import logo from "../../../assets/Images/kreesalogo.png";
const UserForgetPassword = () => {
  document.title = "Kreesa | Forget Password";
  const history = useHistory();
  const [username, setUserName] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Reset Password",
    "Loading..."
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    let res;
    try {
      // api
      res = await AxiosConfig.post(
        `/kreesa/user-login-registration/send-otp/`,
        { username }
      );
    } catch (error) {
      console.log(error);
    }
    if (res && res.status === 200) {
      setButtonLoader(false);
      toast.success("OTP has been sent to your email");
      history.push("/user/OTP-verification");
      localStorage.setItem("_forget_username", username);
    }
  };

  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token === "ROLE_RETAILER") {
    return <Redirect exact to="/retailer" />;
  }
  if (token === "ROLE_WHOLESALER") {
    return <Redirect exact to="/wholesaler" />;
  }
  // to check authentication user or not
  if (token === "ROLE_USER") {
    return <Redirect exact to="/" />;
  }
  UserLoginValidation();
  return (
    <div className="container infinity-container">
      <div className="row">
        <div className="col-md-1 infinity-left-space"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 text-center infinity-form">
          {/* <!-- Company Logo --> */}
          <div className="text-center mb-3 mt-5">
            <Link to="/">
              <img src={logo} alt="company logo" width="150px" />
            </Link>
          </div>
          <div className="text-center mb-4 rounded">
            <h4>Forget Password</h4>
          </div>

          <form id="user-login-form" className="px-3" onSubmit={handleSubmit}>
            <div className="form-input">
              <span>
                <i className="fa fa-envelope"></i>
              </span>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>

            {/* <!-- Forget Button --> */}
            <div className="mb-3">
              <button
                type="submit"
                id="lbtn"
                ref={buttonLoader}
                className="btn btn-block py-2 mt-4"
              >
                Reset Password
              </button>
            </div>
            <div className="text-right">
              <Link to="/login" className="forget-link">
                {" "}
                Login Now?
              </Link>
            </div>
          </form>
        </div>

        {/* <!-- FORM END --> */}
        <div className="col-md-1 infinity-right-space"></div>
      </div>
    </div>
  );
};

export default UserForgetPassword;
