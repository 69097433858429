import React from "react";
import { Link, NavLink } from "react-router-dom";
import LeftSidebar from "./sidebar";

const Ceo = (props) => {
  return (
    <LeftSidebar />
    // <nav id="sidebar" className={`collapse ${props?.matches && "show"}`}>
    //   <div
    //     className="sidebar-content js-simplebar"
    //     id="sidebarContent"
    //     style={{ width: 300 }}
    //     onScroll={(e) => props?.setPosition(Math.round(e.target.scrollTop))}
    //   >
    //     <Link className="sidebar-brand" to="/admin">
    //       <span className="align-middle">Kreesa Dashboard</span>
    //     </Link>

    //     <ul className="sidebar-nav">
    //       <li className="sidebar-header">Personal Settings</li>

    //       <li className="sidebar-item">
    //         <NavLink
    //           className="sidebar-link"
    //           exact
    //           activeClassName="active_nav0"
    //           to="/admin"
    //         >
    //           <i className="fas fa-tachometer-alt"></i>
    //           <span className="align-middle">Dashboard</span>
    //         </NavLink>
    //       </li>

    //       <li className="sidebar-item">
    //         <NavLink
    //           className="sidebar-link"
    //           exact
    //           activeClassName="active_nav0"
    //           to="/admin/profile-settings"
    //         >
    //           <i className="fas fa-cogs"></i>
    //           <span className="align-middle">Settings & Privacy</span>
    //         </NavLink>
    //       </li>
    //       <li className="sidebar-item">
    //         <NavLink
    //           className="sidebar-link"
    //           exact
    //           activeClassName="active_nav0"
    //           to="/admin/profile"
    //         >
    //           <i className="far fa-user-circle"></i>
    //           <span className="align-middle">Profile</span>
    //         </NavLink>
    //       </li>
    //       <li className="sidebar-item">
    //         <NavLink
    //           className="sidebar-link"
    //           exact
    //           activeClassName="active_nav0"
    //           to="/admin/notification"
    //         >
    //           <i className="fas fa-bell"></i>
    //           <span className="align-middle">Send Notification</span>
    //         </NavLink>
    //       </li>
    //       <li className="sidebar-item">
    //         <NavLink
    //           className="sidebar-link"
    //           exact
    //           activeClassName="active_nav0"
    //           to="/company-type"
    //         >
    //           <i className="fas fa-building"></i>
    //           <span className="align-middle">Company Type</span>
    //         </NavLink>
    //       </li>
    //       <li className="sidebar-item">
    //         <NavLink
    //           className="sidebar-link"
    //           exact
    //           activeClassName="active_nav0"
    //           to="/admin/complaint"
    //         >
    //           <i className="fas fa-comment-slash"></i>
    //           <span className="align-middle">Complaint</span>
    //         </NavLink>
    //       </li>
    //       <li className="sidebar-header">Customer Services</li>
    //       <li className="sidebar-item">
    //         <NavLink
    //           className="sidebar-link"
    //           exact
    //           activeClassName="active_nav0"
    //           to="/admin/enquiry"
    //         >
    //           <i className="fas fa-phone"></i>
    //           <span className="align-middle">Enquiry</span>
    //         </NavLink>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#MessagesAdmin"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-sms"></i>
    //           <span className="align-middle">Messages</span>
    //         </a>
    //         <ul
    //           id="MessagesAdmin"
    //           className={`sidebar-dropdown list-unstyled ${
    //             props?.history?.location?.state?.prevPath ===
    //               "/admin/messages" ||
    //             props?.history.location.pathname.includes("/admin/messages")
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/messages"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show Messages
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#Quotations"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fab fa-monero"></i>
    //           <span className="align-middle">Quotations</span>
    //         </a>
    //         <ul
    //           id="Quotations"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/quotations/retailer",
    //               "/admin/quotations/wholesaler",
    //               "/admin/quotations/user",
    //               "/admin/quotations/assigned",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/quotations/retailer"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retailer Quotations
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/quotations/wholesaler"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesaler Quotations
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/quotations/user"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               User Quotations
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/quotations/assigned"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Assigned Quotation
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#Vacancies"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fa fa-tasks"></i>
    //           <span className="align-middle">Vacancies</span>
    //         </a>
    //         <ul
    //           id="Vacancies"
    //           className={`sidebar-dropdown list-unstyled ${
    //             ["/admin/vacancies", "/admin/applied-vacancies"].includes(
    //               props?.history?.location?.pathname
    //             )
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/vacancies"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Vacancies
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/applied-vacancies"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Applied Vacancies
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           href
    //           data-target="#FAQ"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fa fa-question-circle"></i>
    //           <span className="align-middle">FAQ</span>
    //         </a>
    //         <ul
    //           id="FAQ"
    //           className={`sidebar-dropdown list-unstyled ${
    //             ["/admin/view/faq"].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/view/faq"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show all FAQ
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           href
    //           data-target="#Reviews"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-comments"></i>
    //           <span className="align-middle">Reviews</span>
    //         </a>
    //         <ul
    //           id="Reviews"
    //           className={`sidebar-dropdown list-unstyled ${
    //             ["/admin/view/reviews"].includes(
    //               props?.history?.location?.pathname
    //             )
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/view/reviews"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show all Reviews
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-header">Address Services</li>
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#Address"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-map-marker-alt"></i>
    //           <span className="align-middle">Address</span>
    //         </a>
    //         <ul
    //           id="Address"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/country",
    //               "/admin/province",
    //               "/admin/district",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/country"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show Countries
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/province"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show Province / State
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/district"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show District
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-header">Site Settings</li>
    //       <li className="sidebar-item">
    //         <a
    //           href
    //           data-target="#Site"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-images"></i>
    //           <span className="align-middle">Banner</span>
    //         </a>
    //         <ul
    //           id="Site"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/view/seller-banner",
    //               "/admin/view/user-banner",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/view/seller-banner"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Seller Banner
    //             </NavLink>
    //           </li>

    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/view/user-banner"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Customers Banner
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-header">Tools & Components</li>

    //       <li className="sidebar-item">
    //         <a
    //           href
    //           data-target="#ui"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-users-cog"></i>
    //           <span className="align-middle">User Management</span>
    //         </a>
    //         <ul
    //           id="ui"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/details",
    //               "/admin/retailers",
    //               "/admin/wholesalers",
    //               "/admin/customers",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/details"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Administrator
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/retailers"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retailers
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/wholesalers"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesalers
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/customers"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Customers
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           href
    //           data-target="#category"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-bezier-curve"></i>
    //           <span className="align-middle">Category</span>
    //         </a>
    //         <ul
    //           id="category"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/primary-category/view",
    //               "/admin/secondary-category/view",
    //               "/admin/ternary-category/view",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/primary-category/view"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Primary Category
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/secondary-category/view"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Secondary Category
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/ternary-category/view"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Ternary Category
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       {/* group */}
    //       <li className="sidebar-item">
    //         <a
    //           href
    //           data-target="#GroupProduct"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-object-group"></i>
    //           <span className="align-middle">Group Product</span>
    //         </a>
    //         <ul
    //           id="GroupProduct"
    //           className={`sidebar-dropdown list-unstyled ${
    //             ["/admin/group-product/view"].includes(
    //               props?.history?.location?.pathname
    //             )
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/group-product/view"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show Group Product
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#CustomOrder"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fas fa-boxes"></i>
    //           <span className="align-middle">Custom Order</span>
    //         </a>
    //         <ul
    //           id="CustomOrder"
    //           className={`sidebar-dropdown list-unstyled ${
    //             ["/admin/custom-order"].includes(
    //               props?.history?.location?.pathname
    //             )
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/custom-order"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show Custom Orders
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>

    //       {/*  */}
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#Product"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fab fa-product-hunt"></i>
    //           <span className="align-middle">Recent Product</span>
    //         </a>
    //         <ul
    //           id="Product"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/product/view/retail",
    //               "/admin/product/view/wholesale",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/product/view/retail"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retail Product
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/product/view/wholesale"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesale Product
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       {/*  */}
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#Orders"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fab fa-first-order"></i>
    //           <span className="align-middle">Orders</span>
    //         </a>
    //         <ul
    //           id="Orders"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/order/view/retail",
    //               "/admin/order/view/wholesale",
    //               "/admin/order/return",
    //               "/admin/order/assigned",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/order/view/retail"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retail Orders
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/order/view/wholesale"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesale Orders
    //             </NavLink>
    //           </li>{" "}
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/order/return"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Return Orders
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/order/assigned"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Assigned Delivery
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#Blogs"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fab fa-blogger-b"></i>
    //           <span className="align-middle">Blogs</span>
    //         </a>
    //         <ul
    //           id="Blogs"
    //           className={`sidebar-dropdown list-unstyled ${
    //             ["/admin/news-blog-notice"].includes(
    //               props?.history?.location?.pathname
    //             )
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/news-blog-notice"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Show all News/Blog/Notice
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-header">Product Features</li>

    //       {/*  */}
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#featureProduct"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fa fa-star"></i>
    //           <span className="align-middle">Feature Product</span>
    //         </a>
    //         <ul
    //           id="featureProduct"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/feature-product/view/retail",
    //               "/admin/feature-product/view/wholesale",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/feature-product/view/retail"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retail Product
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/feature-product/view/wholesale"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesale Product
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       <li className="sidebar-item">
    //         <a
    //           data-target="#trendingProduct"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fa fa-arrow-up"></i>
    //           <span className="align-middle">Trending Product</span>
    //         </a>
    //         <ul
    //           id="trendingProduct"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/trending-product/view/retail",
    //               "/admin/trending-product/view/wholesale",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/trending-product/view/retail"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retail Product
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/trending-product/view/wholesale"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesale Product
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>

    //       <li className="sidebar-item">
    //         <a
    //           data-target="#organicProduct"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fa fa-leaf"></i>
    //           <span className="align-middle">Organic Product</span>
    //         </a>
    //         <ul
    //           id="organicProduct"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/organic-product/view/retail",
    //               "/admin/organic-product/view/wholesale",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/organic-product/view/retail"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retail Product
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/organic-product/view/wholesale"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesale Product
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>

    //       <li className="sidebar-item">
    //         <a
    //           data-target="#kreesaProduct"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fab fa-product-hunt"></i>
    //           <span className="align-middle">Kreesa Product</span>
    //         </a>
    //         <ul
    //           id="kreesaProduct"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/kreesa-product/view/retail",
    //               "/admin/kreesa-product/view/wholesale",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/kreesa-product/view/retail"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retail Product
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/kreesa-product/view/wholesale"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesale Product
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>

    //       <li className="sidebar-item">
    //         <a
    //           data-target="#brandProduct"
    //           data-toggle="collapse"
    //           className="sidebar-link collapsed"
    //         >
    //           <i className="fa fa-copyright"></i>
    //           <span className="align-middle">Brand Product</span>
    //         </a>
    //         <ul
    //           id="brandProduct"
    //           className={`sidebar-dropdown list-unstyled ${
    //             [
    //               "/admin/brand-product/view/retail",
    //               "/admin/brand-product/view/wholesale",
    //             ].includes(props?.history?.location?.pathname)
    //               ? ""
    //               : "collapse"
    //           }`}
    //           data-parent="#sidebar"
    //         >
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/brand-product/view/retail"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Retail Product
    //             </NavLink>
    //           </li>
    //           <li className="sidebar-item">
    //             <NavLink
    //               className="sidebar-link"
    //               to="/admin/brand-product/view/wholesale"
    //               exact
    //               activeClassName="active_nav__admin_1"
    //             >
    //               Wholesale Product
    //             </NavLink>
    //           </li>
    //         </ul>
    //       </li>
    //       {/*  */}

    //       {/* blank div */}
    //       <li className="sidebar-header"></li>
    //       <li className="sidebar-item"></li>
    //     </ul>
    //   </div>
    // </nav>
  );
};

export default Ceo;
