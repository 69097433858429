import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../ButtonLoaderHook";

const validationSchema = Yup.object().shape({
  // currentPassword: Yup.string().required("Current Password is require"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required ")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

const ChangePassword = () => {
  const [buttonLoader1, setButtonLoader1] = useButtonLoader(
    " Change Password",
    "Loading..."
  );
  const updatePassword = async (values) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      let data = new FormData();
      data.append("password", values.password);
      const res = await AxiosConfig.post(
        "/kreesa/admin/update-password-for-buyer",
        values,
        config
      );
      if (res) {
        toast.success("Password Change Successfully");
        setButtonLoader1(false);
      }
    } catch (error) {
      console.log(error + "Something Went Wrong ! ");
      toast.error("Something Went Wrong !");
      setButtonLoader1(false);
    }
  };

  return (
    <div>
      {/* <div
        className="modal fade"
        id="exampleModalLongs"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        style={{zIndex: '1600',marginTop:'10rem'}}
      > */}
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-info" id="exampleModalLongTitle">
              Change Password
            </h4>
            {/* <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          <div className="modal-body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                // currentPassword: "",
                password: "",
                confirmPassword: "",
                userId: localStorage.getItem("userId"),
              }}
              onSubmit={(values) => {
                updatePassword(values);
              }}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
                setFieldValue,
                handleBlur,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {/* <div className="row mb-4">
                      <label
                        for="currentPassword"
                        className="col-lg-4 col-form-label text-left"
                      >
                        Current Password:
                      </label>
                      <div className="col-lg-7">
                        <input
                          type="password"
                          name="currentPassword"
                          value={values.currentPassword}
                          onChange={handleChange}
                          className="form-control"
                          id="inputEmail3"
                          placeholder="Enter Current Password"
                        />
                        {errors.currentPassword && touched.currentPassword ? (
                          <div style={{ color: "red" }}>
                            {errors.currentPassword}
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="password"
                      className="col-lg-4 col-form-label text-left"
                    >
                      New Password:
                    </label>
                    <div className="col-lg-7">
                      <input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter New password"
                        style={{ border: "1px solid black" }}
                      />
                      {errors.password && touched.password ? (
                        <div style={{ color: "red" }}>{errors.password}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-4 text-dark font-weight-bold">
                    <label
                      for="confirmPassword"
                      className="col-lg-4 col-form-label text-left"
                    >
                      Confirm Password:
                    </label>
                    <div className="col-lg-7">
                      <input
                        value={values.confirmPassword}
                        onChange={handleChange}
                        name="confirmPassword"
                        type="password"
                        className="form-control"
                        placeholder="Enter Confirm Password"
                        style={{ border: "1px solid black" }}
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div style={{ color: "red" }}>
                          {errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="modal-footer">
                    {/* <button
                      type="button"
                      className="border-0 btn-sm btn-danger p-2 rounded"
                      data-dismiss="modal"
                    >
                      Close
                    </button> */}
                    <button
                      type="submit"
                      className="border-0 btn-sm btn-success p-2 rounded"
                      ref={buttonLoader1}
                    >
                      Change Password
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default ChangePassword;
