import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTreeLimit, updateTreePrice } from "../Features/Action/TreeAction";
import Spinner from "../Spinner";
import { Formik, Form } from "formik";
import Modal from "react-bootstrap/Modal";
import useButtonLoader from "../../ButtonLoaderHook";
import { validationPlantLimitSchema } from "./validationSchema";
import { toast } from "react-toastify";

const TreeLimit = () => {
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "SUBMIT",
    "Loading..."
  );
  const [modal, setModal] = useState(false);
  const [perPage] = useState(7);
  const { loader, treeLimit } = useSelector((s) => s.TreeReducer);
  const dispatch = useDispatch();

  const treeLimitPriceHandler = async (values) => {
    setButtonLoader(true);
    try {
      const res = await dispatch(
        updateTreePrice({ ...values, id: treeLimit.id })
      );

      if (res) {
        toast.success("Price limit has been updated");
      }
      setButtonLoader(false);
      setModal(false);
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    dispatch(fetchTreeLimit());
  }, [dispatch]);
  return !loader ? (
    <div className="container">
      {/* <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Tree Limit..."
                    aria-label="Search"
                    // ref={inputRef}
                    // onChange={filterReviews}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/*  */}
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">Tree Limit</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Rs. {treeLimit?.price}</td>
                  <td>
                    <div className="btn-group">
                      <button
                        type="button"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "#3b7ddd",
                        }}
                        className="btn-md btn btn-success dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                      </button>
                      <div className="dropdown-menu text-center">
                        {/* <button className="dropdown-item text-success">
                              <i className="fa fa-eye"></i> Quick View
                            </button>
                            <div className="dropdown-divider" /> */}
                        <div
                          className="dropdown-item text-info cursor-pointer"
                          onClick={() => setModal(true)}
                        >
                          <i className="fas fa-edit"></i> Update Price
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                {/* {currentEntries?.map((c, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {c.firstName} {c.lastName}
                      </td>
                      <td>{c.productName}</td>
                      <td>
                        {c.reviewApproval === false ? (
                          <span className="badge bg-danger">
                            Not Approved Yet
                          </span>
                        ) : (
                          <span className="badge bg-success">Approved</span>
                        )}
                      </td>
                      <td>
                        <Star value={c.totalStar} />
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <button
                              onClick={() => setModalId(`modal${i}`)}
                              className="dropdown-item text-success"
                            >
                              <i className="fa fa-eye"></i> Quick View
                            </button>
                            {c.reviewApproval === false && (
                              <>
                                <div className="dropdown-divider" />
                                <p
                                  onClick={async () => {
                                    await dispatch(ReviewApprove(c.reviewId));
                                    dispatch(getallUserReview());
                                    toast.success("Review has been approved");
                                  }}
                                  className="dropdown-item text-info cursor-pointer"
                                >
                                  <i className="fa fa-check"></i> Approve
                                </p>
                              </>
                            )}
                          </div>
                          <Modal
                            show={modalId === `modal${i}`}
                            onHide={handleClose}
                            aria-labelledby="contained-modal-title-vcenter"
                          >
                            <Modal.Header>
                              <Modal.Title>
                                {c?.productName + "'s Review"} by {c.firstName}{" "}
                                {c.lastName}
                                <Star value={c.totalStar} />
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{c?.review}</Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </td>
                    </tr>
                  ))} */}
              </tbody>
            </table>
            <Modal
              show={modal}
              onHide={() => setModal(false)}
              size="lg"
              centered
            >
              <Modal.Header>
                <Modal.Title className="font-weight-light text-center w-100 d-flex justify-content-between">
                  <div>Update Price</div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setModal(false)}
                  >
                    X
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <PlantTreeForm {...{ modalData }} setModal={modal} /> */}
                <Formik
                  initialValues={{
                    price: "",
                  }}
                  onSubmit={treeLimitPriceHandler}
                  validationSchema={validationPlantLimitSchema}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isValid,
                    handleBlur,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit} autoComplete={false}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={values.price}
                          onChange={handleChange}
                          name="price"
                          placeholder="Price*"
                        />
                      </div>
                      {errors.price && touched.price ? (
                        <div style={{ color: "red" }}> {errors.price} </div>
                      ) : null}
                      <div>
                        <button
                          type="submit"
                          ref={buttonLoader}
                          onClick={handleSubmit}
                          className="btn btn-lg mt-3 px-4 btn-info"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default TreeLimit;
