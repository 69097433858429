import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

// to get token
const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};

/* to get all getCompanyTypes */
export const getCompanyType = createAsyncThunk("companyType", async () => {
  const response = await AxiosConfig.get("/kreesa/admin/company-type/get-all-company-type", config);
  return response.data;
});

/* to deleteCompanyType */
export const deleteCompanyType = createAsyncThunk("deleteCompanyType", async (id, { dispatch }) => {
  const response = await AxiosConfig.get(`/kreesa/admin/company-type/delete/${id}`, config);
  dispatch(getCompanyType());
  return response.data;
});

// to edit primary category
export const editPrimaryCategory = createAsyncThunk("editPrimaryCategory", async (id) => {
  // editPrimaryCategory/pending
  // editPrimaryCategory/fulfilled
  // editPrimaryCategory/rejected
  const response = await AxiosConfig.get(`/kreesa/admin/category/primary/view/${id}`, config);
  return response.data;
});
/* -------------------------------------------------------------------------- */
/*                            to get all enquiries                            */
/* -------------------------------------------------------------------------- */
export const getAllEnquiries = createAsyncThunk("getAllEnquiries", async () => {
  // getAllEnquiries/pending
  // getAllEnquiries/fulfilled
  // getAllEnquiries/rejected
  const { data } = await AxiosConfig.get(`/kreesa/admin/get-all-contactUs-data`, config);
  return data;
});
