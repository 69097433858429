import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../../../ButtonLoaderHook";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyType } from "../../../../Admin/Features/Action/CompanyTypeAction/CompanyTypeAction";
import * as Yup from "yup";
import $ from "jquery";
import Spinner from "../../../Spinner";
import { getAllCountries } from "../../../../Admin/Features/Action/AddressAction";
import { getAllProvince } from "../../../../Admin/Features/Action/AddressAction";
import { getAllDistrict } from "../../../../Admin/Features/Action/AddressAction";

const validationSchema = Yup.object().shape({
  company_name: Yup.string().required("Company Name is required"),
  paymentRate: Yup.number()
    .required("Commission Rate is required")
    .typeError("Must be a positive Number"),
  // company_address: Yup.string().required("Company Address is required"),
  companyTypeId: Yup.string().required("Select Company Type"),
  company_contact_no: Yup.number().required("Contact Number is required"),
  // company_email: Yup.string()
  //   .email("Email is invalid")
  //   .required("Email is required"),
});

const EditRetailorForm = () => {
  const { id } = useParams();
  const [state, setState] = useState([]);
  const [registration_document, setFile] = useState("");
  const { companyType, loader } = useSelector((s) => s.CompanyType);
  const [logofile, setLogoFile] = useState();
  const [image, setImage] = useState();
  const [rimage, setrImage] = useState();

  const { countries } = useSelector((s) => s.AddressReducer);
  const { proviences } = useSelector((s) => s.AddressReducer);
  const { districts } = useSelector((s) => s.AddressReducer);

  const [selectProvince, SetSelectProvince] = useState([]);
  const [selectDistrict, SetSelectDistrict] = useState([]);

  const dispatch = useDispatch();
  let history = useHistory();
  const initialValues = {
    company_name: state.companyName,
    company_address: state.companyAddress,
    company_contact_no: state.companyContactNo,
    company_email: state.companyEmail,
    companyType: state.companyType,
    companyTypeId: state.companyTypeId,
    countryId: state.countryId,
    provinceId: state.provinceId,
    districtId: state.districtId,
    street_address: state.streetAddress,
    landmark: state.landMark,
    pin_code: state.pinCode,
    // role: state.role,
    hasInsured: state.isInsured,
    hasRegistered: state.isRegistered,
    pan_no: state.panNo,
    paymentType: state.paymentType,
    paymentRate: state.paymentRate,
    role: "ROLE_RETAILER",
  };

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update User",
    "Loading..."
  );

  // update user
  const UpdateUsersHandler = async (values) => {
    setButtonLoader(true);

    try {
      let data = new FormData();

      if (registration_document) {
        data.append("registration_document", registration_document);
      }
      if (logofile) {
        data.append("company_logo", logofile);
      }

      data.append("email", values.company_email);
      data.append("company_name", values.company_name);
      data.append("companyTypeId", values.companyTypeId);
      data.append("countryId", values.countryId);
      data.append("provinceId", values.provinceId);
      data.append("districtId", values.districtId);
      data.append("street_address", values.street_address);
      data.append("landmark", values.landmark);
      data.append("pin_code", values.pin_code);
      data.append("company_email", values.company_email);
      data.append("hasRegistered", values.hasRegistered);
      data.append("hasInsured", values.hasInsured);
      // data.append("company_address", values.company_address);
      data.append("company_contact_no", values.company_contact_no);
      data.append("paymentType", values.paymentType);
      data.append("rate", values.paymentRate);
      if (values.hasRegistered) {
        data.append("pan_no", values.pan_no);
      }
      data.append("role", values.role);
      data.append("id", state.userId);

      const res = await AxiosConfig.post("/kreesa/admin/update-user", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res) {
        toast.success("Update user succesfully !");
        setButtonLoader(false);
        history.push("/admin/retailers");
      }
    } catch (err) {
      console.log(err + "error found");
      toast.error(err.response.data.message);
      setButtonLoader(false);
    }
  };
  const getSingleRetailor = async (id) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetSingleRetailor = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${id}`,
        config
      );
      setState(GetSingleRetailor.data);
      SetSelectProvince(GetSingleRetailor.data.countryId);
      SetSelectDistrict(GetSingleRetailor.data.provinceId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleRetailor(id);
  }, [id]);

  useEffect(() => {
    dispatch(getAllDistrict());
    dispatch(getAllProvince());
    dispatch(getAllCountries());
    dispatch(getCompanyType());
  }, []);

  $(function () {
    $(".myCheckbox").change(function () {
      if ($(this).is(":checked")) {
        $("div#select_div").children().prop("disabled", false);
      } else {
        $("div#select_div").children().prop("disabled", true);
      }
    });
  });
  return !loader ? (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto">
            <div className="card px-5 pt-4 d-flex justify-content-center">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  UpdateUsersHandler(values);
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  isValid,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-heading">
                      <p className="text-left">Edit WholeSaler/Retailer User</p>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.company_name}
                          onChange={handleChange}
                          name="company_name"
                          id="inputEmail5"
                          placeholder="Enter Company Name"
                        />
                        {errors.company_name && touched.company_name ? (
                          <div style={{ color: "red" }}>
                            {errors.company_name}
                          </div>
                        ) : null}
                      </div>

                      {/* <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Company Address
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control"
                          value={values.company_address}
                          onChange={handleChange}
                          name="company_address"
                          id="inputPassword5"
                          placeholder="Enter Company Address"
                        />
                        {errors.company_address && touched.company_address ? (
                          <div style={{ color: "red" }}>
                            {errors.company_address}
                          </div>
                        ) : null}
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Country
                        </label>
                        <select
                          name="countryId"
                          onChange={handleChange}
                          onBlur={(e) => {
                            SetSelectProvince(e.target.value);
                          }}
                          values={values.countryId}
                          className="form-control"
                        >
                          <option value="">Select Your Country...</option>
                          {countries?.map((c, i) => (
                            <option
                              key={i}
                              value={c.id}
                              selected={state?.countryName === c.country_name}
                            >
                              {c.country_name}
                            </option>
                          ))}
                        </select>
                        {errors.countryId && touched.countryId ? (
                          <div style={{ color: "red" }}>{errors.countryId}</div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Province
                        </label>
                        <select
                          name="provinceId"
                          onChange={handleChange}
                          onBlur={(e) => {
                            SetSelectDistrict(e.target.value);
                          }}
                          values={values.provinceId}
                          className="form-control"
                        >
                          <option value="">Select Your Provience...</option>
                          {proviences?.map(
                            (pr, i) => (
                              // selectProvince == pr.id && (
                              <option
                                key={i}
                                value={pr.id}
                                selected={
                                  state?.provienceName === pr.provience_name
                                }
                              >
                                {pr?.provience_name}
                              </option>
                            )
                            // )
                          )}
                        </select>
                        {errors.provinceId && touched.provinceId ? (
                          <div style={{ color: "red" }}>
                            {errors.provinceId}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          District
                        </label>
                        <select
                          name="districtId"
                          onChange={handleChange}
                          values={values.districtId}
                          className="form-control"
                        >
                          <option value="">Select Your District...</option>
                          {districts?.map(
                            (d, i) => (
                              // selectDistrict == d?.id && (
                              <option
                                key={i}
                                value={d.id}
                                selected={
                                  state?.districtName === d.district_name
                                }
                              >
                                {d.district_name}
                              </option>
                            )
                            // )
                          )}
                        </select>
                        {errors.districtId && touched.districtId ? (
                          <div style={{ color: "red" }}>
                            {errors.districtId}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Street Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.street_address}
                          name="street_address"
                          onChange={handleChange}
                          id="street_address"
                          placeholder="Enter Street Address"
                        />
                        {errors.street_address && touched.street_address ? (
                          <div style={{ color: "red" }}>
                            {errors.street_address}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Landmark
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.landmark}
                          name="landmark"
                          onChange={handleChange}
                          id="landmark"
                          placeholder="Enter Landmark"
                        />
                        {errors.landmark && touched.landmark ? (
                          <div style={{ color: "red" }}>{errors.landmark}</div>
                        ) : null}
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Pin Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.pin_code}
                          name="pin_code"
                          onChange={handleChange}
                          id="pin_code"
                          placeholder="Enter Street Address"
                        />
                        {errors.pin_code && touched.pin_code ? (
                          <div style={{ color: "red" }}>{errors.pin_code}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Company Email
                        </label>
                        <input
                          type="email"
                          required
                          className="form-control"
                          value={values.company_email}
                          name="company_email"
                          onChange={handleChange}
                          id="inputPassword5"
                          placeholder="Enter Company company_email"
                        />
                        {errors.company_email && touched.company_email ? (
                          <div style={{ color: "red" }}>
                            {errors.company_email}
                          </div>
                        ) : null}
                      </div> */}
                    </div>

                    {/*  */}
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Company Type
                        </label>
                        <select
                          name="companyTypeId"
                          onChange={handleChange}
                          values={values.companyTypeId}
                          className="form-control"
                        >
                          {companyType?.map((p, i) => (
                            <option
                              key={i}
                              value={p.id}
                              selected={state?.companyType === p.typeName}
                            >
                              {p.typeName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Company Contact Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.company_contact_no}
                          name="company_contact_no"
                          onChange={handleChange}
                          id="inputEmail5"
                          placeholder="Enter Company Contact Number"
                        />
                        {errors.company_contact_no &&
                        touched.company_contact_no ? (
                          <div style={{ color: "red" }}>
                            {errors.company_contact_no}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputEmail4">
                          Commission Type
                        </label>
                        <select
                          name="paymentType"
                          onChange={handleChange}
                          value={values.paymentType}
                          className="form-control"
                        >
                          <option> Select Commission Type... </option>
                          {[
                            {
                              id: 1,
                              name: "Fix Amount",
                              value: "FIX_AMOUNT",
                            },
                            {
                              id: 2,
                              name: "Percentage",
                              value: "PERCENTAGE",
                            },
                          ].map((i) => (
                            <option key={i.id} value={i.value}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="paymentRate">
                          Commission Rate
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.paymentRate}
                          name="paymentRate"
                          onChange={handleChange}
                          id="paymentRate"
                          placeholder="Enter Commission Rate"
                        />
                        {errors.paymentRate && touched.paymentRate ? (
                          <div style={{ color: "red" }}>
                            {errors.paymentRate}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Role
                        </label>
                        <div className="row">
                          <div className="mb-3 col-md-6 mcol">
                            <label className="radio">
                              <input
                                type="radio"
                                name="role"
                                value="ROLE_RETAILER"
                                disabled
                              />
                              <span>Retailer</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Company Logo
                        </label>
                        <input
                          type="file"
                          className="form-control "
                          name="company_logo"
                          accept="image/png,image/jpg,image/jpeg"
                          onChange={(e) => {
                            setLogoFile(e.target.files[0]);
                            setImage(URL.createObjectURL(e.target.files[0]));
                          }}
                          id="pas"
                        />
                        <small>Accepted Formats: png, jpg & jpeg</small>

                        <div className="d-flex">
                          {image && (
                            <div
                              className="mt-2"
                              style={{ flexDirection: "column" }}
                            >
                              <img
                                src={image}
                                alt="dummy"
                                height={80}
                                width={100}
                              />
                              <p className="text-center">New Image</p>
                            </div>
                          )}
                          <div
                            className="ml-4"
                            style={{ flexDirection: "column" }}
                          >
                            <img
                              src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${id}/${state.companyLogo}`}
                              className="m-2"
                              height={80}
                              width={100}
                              alt=""
                            />
                            <p className="text-center">Old Image</p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="inputPassword4">
                          Does your company have insurance ?
                        </label>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="radio">
                              {" "}
                              <input
                                type="radio"
                                name="hasInsured"
                                value={values.hasInsured === true}
                                checked
                                onChange={handleChange}
                                // disabled
                              />{" "}
                              <span>Yes</span>
                            </label>
                          </div>
                          <div className="col-md-6 mcol">
                            <label className="radio">
                              {" "}
                              <input
                                type="radio"
                                name="hasInsured"
                                value={values.hasInsured === false}
                                checked
                                onChange={handleChange}
                                // disabled
                              />{" "}
                              <span>No</span>{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6 mt-4">
                        <label className="form-label" htmlFor="inputPassword4">
                          <input
                            type="checkbox"
                            name="hasRegistered"
                            onChange={handleChange}
                            checked={values.hasRegistered}
                            className="form-check-input"
                          />{" "}
                          Click If Your Company Is Register
                        </label>

                        <div className="row">
                          <div>
                            Upload Register Document
                            <div className="form-group register-group">
                              <div className="row">
                                <div className="col" id="select_div">
                                  <input
                                    type="file"
                                    className="form-control "
                                    name="registration_document"
                                    accept="images/*"
                                    onChange={(e) => {
                                      setFile(e.target.files[0]);
                                      setrImage(
                                        URL.createObjectURL(e.target.files[0])
                                      );
                                    }}
                                    disabled={!values.hasRegistered}
                                  />
                                  <div className="d-flex">
                                    {rimage && (
                                      <div
                                        className="mt-2"
                                        style={{ flexDirection: "column" }}
                                      >
                                        <img
                                          src={rimage}
                                          alt="dummy"
                                          height={80}
                                          width={100}
                                        />
                                        <p className="text-center">New Image</p>
                                      </div>
                                    )}
                                    <div
                                      className="ml-4"
                                      style={{ flexDirection: "column" }}
                                    >
                                      <img
                                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/retailer/${id}/${state.registrationDocument}`}
                                        className="m-2"
                                        height={80}
                                        width={100}
                                        alt=""
                                      />
                                      <p className="text-center">Old Image</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col" id="select_div">
                                  <input
                                    type="text"
                                    className="form-control "
                                    name="pan_no"
                                    onChange={handleChange}
                                    value={values.pan_no}
                                    placeholder="Pan Number/VAT No"
                                    disabled={!values.hasRegistered}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group pt-3 mb-4">
                      <div className="row">
                        <div className="col-md-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            id="btrw"
                            ref={buttonLoader}
                            onClick={handleSubmit}
                            disabled={!isValid}
                          >
                            Update User
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default EditRetailorForm;
