import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from '../../../../Common/Api/AxiosConfig'

const config = {
  headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
  }
}

/* to get Single Custom Order */
export const getSingleCustomOrder = createAsyncThunk("getSingleCustomOrder", async (customId) => {
  // getSingleCustomOrder/pending
  // getSingleCustomOrder/fulfilled
  // getSingleCustomOrder/rejected
  const response = await AxiosConfig.get(`/kreesa/wholesaler/custom-order/view/${customId}`, config);
  return response.data;
});

/* to getCustomOrderByUserId */
export const getCustomOrderByUserId = createAsyncThunk('getCustomOrderByUserId', async () => {
  // CustomOrder/pending
  // CustomOrder/fulfilled
  // CustomOrder/rejected
  const response = await AxiosConfig.get(`/kreesa/wholesaler/custom-order/view-all?userId=${localStorage.getItem('userId')}`, config);
  return response.data;
})
