import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { validationSchema } from "./validation";

const QuoteModal = ({ modal, setModal }) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleQuotation = (values) => {
    let data = new FormData();
    setLoading(true);
    data.append("userId", localStorage.getItem("userId"));
    data.append("product_name", values.product_name);
    data.append("quantity", values.quantity);
    data.append("excepted_price", values.expected_price);
    if (values.photo) {
      data.append("photo", values.photo);
    }
    data.append("quotation_by", localStorage.getItem("auth"));

    AxiosConfig.post(`/kreesa/quotation/save-quotation`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((res) => {
      toast.success("Quotation Successfully Submitted");
      setModal(false);
      setLoading(false);
    });
  };

  const handleredirect = () => {
    history.push("/register-form");
    toast.error("please logged in to submit quotation");
  };

  return (
    <Modal
      show={modal}
      onHide={() => setModal(false)}
      size="md"
      centered
      style={{ zIndex: "99999", top: "0.2rem" }}
    >
      <Modal.Header>
        <Modal.Title
          className={`${
            localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
            localStorage.getItem("auth") === "ROLE_RETAILER" ||
            localStorage.getItem("auth") === "ROLE_USER"
              ? "font-weight-light text-center font-italic"
              : ""
          } `}
        >
          {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
          localStorage.getItem("auth") === "ROLE_RETAILER" ||
          localStorage.getItem("auth") === "ROLE_USER" ? (
            `We couldn't find any result what you are searching please Submit this
          form for easy to find your desirable product !!!`
          ) : (
            <p className="text-center px-4 font-weight-bold">
              यहाँ तपाईंहरूले खोजेको सामानको फोटो नाम,रङ,साईज,ब्रान्ड,आदि राखेर
              कतिमा खोज्नु भएको हो त्यसको मुल्य राखेर पेस गर्नुहोस ।देशी तथा
              बिदेशी आपुर्तिकर्ताले तपाईंले खोजेको सामान दिन सक्ने मुल्य तोकि
              फोटो राखेर पठाउनु हुन्छ।आफुलाई मन पर्ने अथवा सस्तो जुन ठिक लाग्छ
              त्यो अडर ओके गर्नुहोला l
            </p>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <Formik
            enableReinitialize={true}
            initialValues={{
              product_name: "",
              quantity: "",
              expected_price: "",
              photo: null,
              checked: false,
            }}
            onSubmit={(values) =>
              localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
              localStorage.getItem("auth") === "ROLE_RETAILER" ||
              localStorage.getItem("auth") === "ROLE_USER"
                ? handleQuotation(values)
                : handleredirect()
            }
            validationSchema={validationSchema}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              handleBlur,
            }) => (
              <Form id="quotation-form" onSubmit={handleSubmit}>
                <div className="col">
                  <div className="mt-1">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="productName"
                    >
                      Product Name
                    </label>
                    <p className="">
                      Here you can add the name of product, brand, color, size,
                      country of manufacture etc.
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="product_name"
                      onChange={handleChange}
                      value={values.product_name || ""}
                      id="productName"
                      placeholder="Enter Product Name"
                    />
                    {errors.product_name && touched.product_name ? (
                      <div style={{ color: "red" }}>{errors.product_name}</div>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="quantityy"
                    >
                      Quantity
                    </label>
                    <p className="">
                      Kindly mention number of units you are looking for.
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="quantity"
                      onChange={handleChange}
                      value={values.quantity || ""}
                      id="quantityy"
                      placeholder="Enter quantity"
                    />
                    {errors.quantity && touched.quantity ? (
                      <div style={{ color: "red" }}>{errors.quantity}</div>
                    ) : null}
                  </div>
                  <div className="mt-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="price"
                    >
                      Expected Price
                    </label>
                    <p className="">
                      Here you can add the range of price you can offer for the
                      required product. (Per Unit)
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="expected_price"
                      onChange={handleChange}
                      value={values.expected_price || ""}
                      id="price"
                      placeholder="Enter Expected Price"
                    />
                    {errors.expected_price && touched.expected_price ? (
                      <div style={{ color: "red" }}>
                        {errors.expected_price}
                      </div>
                    ) : null}
                  </div>
                  <div class="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="checked"
                      value={values.checked}
                      checked={values.checked ? "checked" : ""}
                      onChange={handleChange}
                      style={{
                        width: 20,
                        height: 20,
                        marginTop: -1,
                      }}
                    />
                    <label
                      className="form-check-label className"
                      for="flexCheckChecked"
                    >
                      Is image available?
                    </label>
                  </div>

                  {values.checked && (
                    <div className="mt-2">
                      <label
                        className="form-label font-weight-normal"
                        htmlFor="Image"
                      >
                        Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="photo"
                        accept="image/png,image/jpg,image/jpeg"
                        onChange={(e) => {
                          setFieldValue("photo", e.target.files[0]);
                        }}
                        onBlur={handleBlur}
                      />
                      <small>Accepted Formats: png, jpg & jpeg</small>

                      {errors.photo && touched.photo ? (
                        <div style={{ color: "red" }}>{errors.photo}</div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className="mt-4 d-flex justify-content-end w-100">
                  <Button
                    variant="secondary"
                    disabled={loading}
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    form="quotation-form"
                    className="ml-2"
                    htmlFor="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuoteModal;
