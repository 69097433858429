import React, { useState } from "react";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { FileUploader } from "react-drag-drop-files";
import useButtonLoader from "../../../../ButtonLoaderHook";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  bannerPosition: Yup.string().required("Banner Position is required"),
  redirectionLink: Yup.string().url("Please enter a valid URL"),
  file: Yup.mixed()
    .required("Image is required")
    .nullable()
    .test(
      "fileType",
      "Only accept Jpg/Jpeg/Png/webp/gif file type",
      (file) =>
        file &&
        [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/webp",
          "image/gif",
        ].includes(file.type)
    ),
});

function CustomerBanner() {
  const history = useHistory();
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Add User Banner",
    "Loading..."
  );
  const [image, setImage] = useState({ preview: "", raw: "" });
  const fileTypes = ["JPG", "PNG", "JPEG", "MP4"];
  //
  const handleImageChange = (file) => {
    setImage({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };
  /* --------------------------------- submit --------------------------------- */
  const onSubmitHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      data.append(
        "description",
        draftToHtml(convertToRaw(description.getCurrentContent()))
      );
      data.append("file", values.file);
      data.append("bannerPosition", values.bannerPosition);
      data.append("redirectionLink", values.redirectionLink);
      data.append("type", "customer");
      const res = await AxiosConfig.post(
        "/kreesa/admin/banner/upload-banner",
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      history.push("/admin/view/user-banner");
      if (res) {
        toast.success("New Banner added succesfully !");
        setButtonLoader(false);
      }
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };

  return (
    <Formik
      initialValues={{
        file: null,
        bannerPosition: "",
        redirectionLink: "",
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="col">
            <div className="mb-3 col-md-5">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Select Banner</span>
              </label>
              <FileUploader
                handleChange={(file) => {
                  setFieldValue("file", file);
                  handleImageChange(file);
                }}
                name="file"
                types={fileTypes}
              />
              {errors.file && touched.file ? (
                <div style={{ color: "red" }}>{errors.file}</div>
              ) : null}
            </div>
            <div className="text-muted h5">
              Banner Image must be 1280 * 400 (pixels) Dimension
            </div>
          </div>
          <div className="col mb-3">
            {image.preview && (
              <img src={image.preview} alt="dummy" height="100" />
            )}
          </div>
          <div className="col">
            <div className="mb-3 col-md-5">
              <label
                className="form-label font-weight-bold"
                htmlFor="inputState"
              >
                Banner Position <span className="text-danger"> *</span>
              </label>
              <select
                id="inputState"
                name="bannerPosition"
                onChange={handleChange}
                value={values.bannerPosition}
                className="form-select"
              >
                {/*  */}
                <option value="">Select Banner Position First...</option>
                <option value="TOP">Top</option>
                <option value="BOTTOM">Bottom</option>
              </select>
              {errors.bannerPosition && touched.bannerPosition ? (
                <div style={{ color: "red" }}>{errors.bannerPosition}</div>
              ) : null}
            </div>
          </div>

          <div className="col">
            <div className="mb-3 col-md-5">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Redirection Link</span>
                <span className="text-info"> (optional)</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="redirectionLink"
                value={values.redirectionLink}
                onChange={handleChange}
                placeholder="Enter Redirection Link"
              />
              {errors.redirectionLink && touched.redirectionLink ? (
                <div style={{ color: "red" }}>{errors.redirectionLink}</div>
              ) : null}
            </div>
          </div>

          <div className="col">
            <div className="mb-3 col-md-7">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Banner Description</span>
                <span className="text-info"> (optional)</span>
              </label>
              <Editor
                editorState={description}
                onEditorStateChange={(val) => {
                  setDescription(val);
                  setFieldValue(
                    "description",
                    draftToHtml(convertToRaw(val.getCurrentContent()))
                  );
                }}
                editorStyle={{
                  backgroundColor: "white",
                  minHeight: "150px",
                  padding: "10px 20px",
                }}
                editorClassName="mt-2 p-10 mx-auto"
              />
            </div>
          </div>
          <div className="mb-3 mt-4 col-md-5">
            <button
              type="submit"
              className="btn btn-success btn-lg btn-block UppyInput-Progress"
              ref={buttonLoader}
              //   disabled={!isValid}
            >
              Add Seller Banner
            </button>
          </div>
        </Form>
      )}
    </Formik>
    // <div>
    //   <div className="col">
    //     <div className="mb-3 col-md-12">
    //       <label className="form-label" htmlFor="inputCity">
    //         <span className="font-weight-bold">Select Banner</span>
    //       </label>
    //       <FileUploader
    //         handleChange={handleChange}
    //         name="file"
    //         types={fileTypes}
    //       />
    //     </div>
    //   </div>
    //   <div className="col">
    //     {image.preview && (
    //       <img src={image.preview} alt="dummy" width="100" height="100" />
    //     )}
    //   </div>
    //   <div className="col">
    //     <div className="mb-3 col-md-8">
    //       <label className="form-label" htmlFor="inputCity">
    //         <span className="font-weight-bold">Banner Description</span>
    //         <span className="text-info"> (optional)</span>
    //       </label>
    //       <Editor
    //         editorState={description}
    //         onEditorStateChange={onEditorStateChange}
    //         editorStyle={{
    //           backgroundColor: "white",
    //           minHeight: "150px",
    //           padding: "10px 20px",
    //         }}
    //         editorClassName="mt-2 p-10 mx-auto"
    //       />
    //     </div>
    //   </div>
    //   <div className="mb-3 mt-4 col-md-8">
    //     <button
    //       id="catadd"
    //       type="submit"
    //       className="btn btn-success btn-lg btn-block"
    //       ref={buttonLoader}
    //       onClick={onSubmitHandler}
    //       disabled={!buttonLoader}
    //     >
    //       Add User Banner
    //     </button>
    //   </div>
    // </div>
  );
}

export default CustomerBanner;
