import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "../../Pagination";
import { getVacancies } from "../Features/action";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import ReactHtmlParser from "react-html-parser";
import "./style.css";
import Spinner from "../../Admin/Spinner";
import UserFooter from "../UserFooter";

const JobPortal = () => {
  document.title = "Kreesa | Jobs";

  const history = useHistory();
  // {new Date().getFullYear()}
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  const { userVacancies, loader } = useSelector((state) => state.userProducts);

  useEffect(() => {
    dispatch(getVacancies());
  }, []);

  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = userVacancies?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(userVacancies?.length / perPage);

  return (
    <div>
      <Search />
      <Navbar />
      {!loader ? (
        <div className="container">
          {currentEntries?.length > 0 ? (
            currentEntries?.map((i) => (
              <>
                <div
                  className="job__box"
                  key={i.id}
                  onClick={() => history.push(`/job-detail/${i.id}`)}
                >
                  <div className="job__box__col box__80">
                    <div className="job__head">
                      <h2 className="text-capitalize h2">
                        {i.title} ({i.openVacancies})
                      </h2>
                      <h4 className="job__function text-capitalize">
                        {i.jobType}
                      </h4>
                    </div>

                    <div className="job__content">
                      <h5 className="font-weight-bold">
                        Location : {i.jobLocation}
                      </h5>
                      <h5 className="font-weight-bold">
                        Deadline : {moment(i.deadline).format("D MMM, YYYY")}
                      </h5>

                      <div className="job__desc">
                        {ReactHtmlParser(i.requirement)}
                      </div>
                    </div>
                  </div>
                  <div className="job__box__col box__20 text__job__right  font-weight-bold">
                    <div>
                      <h5 className="text-success font-weight-bold">
                        {i.status}
                      </h5>
                    </div>
                  </div>
                  {/* <div className="job__box__col box__20 text__job__right">
              <button className="job__buttons">Active</button>
            </div> */}
                </div>
              </>
            ))
          ) : (
            <div
              style={{ marginTop: "60px" }}
              className="d-flex flex-column justify-content-center"
            >
              <img
                src="/images/vacancy.jpg"
                // style={{ height: 400, width: "100%", objectFit: "fill" }}
                width="100%"
                height="100%"
                alt="vacancy"
                className="img-fluid vacancy__img_"
              />
              <div
                style={{
                  marginTop: "15px",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                We are not hiring at the moment.
                <br /> Kindly contact us at{" "}
                <a href="mailto:kreesasales@gmail.com">kreesasales@gmail.com</a>
              </div>
            </div>
          )}
          {/* pagination  */}
          {currentEntries.length > 0 && (
            <Pagination
              pages={totalPagesNum}
              setCurrentPage={setCurrentPage}
              currentEntries={currentEntries}
              allEntries={userVacancies}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      )}
      <UserFooter />
    </div>
  );
};

export default JobPortal;
