import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import useButtonLoader from "../../../../ButtonLoaderHook";
import { toast } from "react-toastify";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Form, Formik } from "formik";
import draftToHtml from "draftjs-to-html";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleCountries,
  updateCountries,
} from "../../../Features/Action/AddressAction";
import Spinner from "../../../Spinner";

const CountryForm = () => {
  const { id } = useParams();
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Country",
    "Loading..."
  );
  const history = useHistory();
  const dispatch = useDispatch();

  // to get all countries
  const { country, loader } = useSelector((s) => s.AddressReducer);

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };

  const EditContryHandler = async (values) => {
    setButtonLoader(true);
    const res = await dispatch(
      updateCountries({
        country_code: values.country_code,
        country_name: values.country_name,
        status: values.status,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        id: id,
      })
    );
    if (res) {
      setButtonLoader(false);
      toast.success("Country Updated Successfully");
      history.push("/admin/country");
    }
  };

  useEffect(() => {
    dispatch(getSingleCountries(id));
  }, []);

  useEffect(() => {
    if (country?.description?.length > 0) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(country?.description)
          )
        )
      );
    }
  }, [country]);
  return !loader ? (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title font-weight-bold">Edit Country Form</h5>
          <h6 className="card-subtitle text-muted">
            {" "}
            You Can Easily Edit Countries.
          </h6>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              country_code: country?.country_code,
              country_name: country?.country_name,
              status: country?.status,
              description: country?.description,
              id: country?.id,
            }}
            onSubmit={EditContryHandler}
          >
            {({ handleChange, handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="col">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Country Name</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="country_name"
                      id="inputCategory"
                      onChange={handleChange}
                      value={values.country_name}
                      placeholder="Enter Country Name"
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Country code</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="country_code"
                      onChange={handleChange}
                      value={values.country_code}
                      id="inputCategory"
                      placeholder="Enter Country code"
                    />
                  </div>
                  <div className="mb-3 mt-2 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Status</span>
                      <span className="text-danger"> *</span>
                    </label>

                    <input
                      className="form-check-input ml-2"
                      type="radio"
                      name="status"
                      id="exampleRadios1"
                      checked={values?.status === true ? "checked" : null}
                      value={true}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label pl-2"
                      htmlFor="exampleRadios1"
                    >
                      {" "}
                      Active
                    </label>
                    <input
                      className="form-check-input ml-2"
                      type="radio"
                      name="status"
                      id="exampleRadios2"
                      checked={values?.status === false ? "checked" : null}
                      value={false}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label pl-2"
                      htmlFor="exampleRadios2"
                    >
                      {" "}
                      Inactive{" "}
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 col-md-8">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">
                        Country Description
                      </span>
                      <span className="text-info"> (optional)</span>
                    </label>
                    <Editor
                      editorState={description}
                      onEditorStateChange={onEditorStateChange}
                      editorStyle={{
                        backgroundColor: "white",
                        minHeight: "150px",
                        padding: "10px 20px",
                      }}
                      editorClassName="mt-2 p-10 mx-auto"
                      value={values.description}
                    />
                  </div>
                </div>
                <div className="mb-3 mt-4 col-md-4">
                  <button
                    id="catadd"
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-success btn-lg btn-block"
                    ref={buttonLoader}
                  >
                    Update Country
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default CountryForm;
