import React, { useEffect, useState } from "react";
import { Modal as Modals } from "react-responsive-modal";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import Spinner from "../../Spinner";
import moment from "moment";
import HtmlParser from "react-html-parser";
import {
  approveCustomOrder,
  confirmCustomOrderPlacement,
} from "../../Features/Action/CustomOrderAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const SingleCustomOrders = (props) => {
  const { orderId } = useParams();
  const [cstate, setState] = useState("");
  const [user, setUser] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [modalId, setModalId] = useState("");
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.CustomOrder);

  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };
  const handleClose = () => setModalId(false);
  let history = useHistory();

  const pass = () => {
    window.open("/custom-order", "_blank");
    localStorage.setItem("customOrder", JSON.stringify(cstate));
    return;
  };

  const SingleViewCustomOrder = async () => {
    setIsLoaded(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.get(
        `/kreesa/admin/custom-order/view-single-custom-order/${orderId}`,
        config
      );
      setState(res.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    SingleViewCustomOrder(orderId);
  }, []);

  const SingleViewCustomUserDetails = async () => {
    setIsLoaded(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const getcustom = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${cstate.userId}`,
        config
      );
      setUser(getcustom.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
      setIsLoaded(false);
    }
  };
  useEffect(() => {
    SingleViewCustomUserDetails();
  }, [cstate.userId]);

  const handleApproveCustomOrder = async (id) => {
    await dispatch(approveCustomOrder(id));
    await SingleViewCustomOrder();
    toast.info("Custom Order has been Approved");
  };

  return !isLoaded || !loader ? (
    <div className="text-capitalize">
      <div className="card mx-5 p-2">
        <h5 className="font-weight-bold">Custom Order Details</h5>
      </div>
      <div className="card mx-5 mt-4">
        <div className="p-1">
          <p className="card-text ml-2">
            Custom Order Date:{" "}
            <span className="text-muted">
              {moment(cstate.dueDate).format("lll")}
            </span>
          </p>
          <p className="card-text ml-2">
            <span
              onClick={() => {
                setModalId(`modal${cstate?.userId}`);
              }}
              className="text-success cursor-pointer"
            >
              Custom Order Details
            </span>
          </p>
          <Modal
            show={modalId === `modal${cstate?.userId}`}
            onHide={() => setModalId(handleClose)}
          >
            <Modal.Header>
              <Modal.Title>Custom Order Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="pro">
                  {/* <div className="col-md-4">
                    <div className="profile-img">
                      <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${id}/${user.companyLogo}`} alt={user?.companyName} />
                    </div>
                  </div> */}

                  <h3 className="text-uppercase">{user?.companyName} </h3>
                </div>

                <div className="col-md-12 m-auto">
                  <div className="tab-content profile-tab custom-height">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Name</label>
                        </div>
                        <div className="col-md-6 ">
                          <p className="text-capitalize">{user?.companyName}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Address</label>
                        </div>
                        <div className="col-md-6">
                          <p className="text-capitalize">
                            {`${user?.districtName} , ${user?.provienceName} , ${user?.countryName}`}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Email</label>
                        </div>
                        <div className="col-md-6">
                          <p>{user?.companyEmail}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Type</label>
                        </div>
                        <div className="col-md-6">
                          <p className="text-capitalize">{user?.companyType}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Company Contact</label>
                        </div>
                        <div className="col-md-6">
                          <p> {user?.companyContactNo}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>PAN No</label>
                        </div>
                        <div className="col-md-6">
                          <p> {user?.panNo}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Register Status</label>
                        </div>
                        <div className="col-md-6">
                          {user?.isRegistered === true ? (
                            <p>Register</p>
                          ) : (
                            <p>Not Register</p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Insuranced Status</label>
                        </div>
                        <div className="col-md-6">
                          {user?.isInsured === true ? (
                            <p>Insuranced</p>
                          ) : (
                            <p>Not Insuranced</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className="card mx-5 mt-4">
        <div className="">
          <h6 className="h6 ml-4 mt-1 pt-1">Custom Order By Customer</h6>
          <hr className="text-success mx-4" />
        </div>
        {/*  ------------------------------- main image -------------------------------  */}
        <div className="row g-0">
          <div
            className={`col-md-6 ${
              cstate.fileType === "IMAGE" || cstate.fileType === null
                ? "cursor-pointer"
                : ""
            }`}
            onClick={() =>
              cstate.fileType === "IMAGE" ||
              (cstate.fileType === null && handleOpenModal(cstate.orderId))
            }
          >
            {cstate.fileType === "IMAGE" || cstate.fileType === null ? (
              <img
                src={`${AxiosConfig.defaults.baseURL}/kreesa/image/custom/${cstate.orderId}/${cstate.orderImage}`}
                className="img-fluid img-thumbnail ml-4"
                style={{
                  width: "90%",
                  height: "300px",
                  // border: "1px solid gray",
                }}
                alt={cstate.orderBy}
              />
            ) : (
              <audio
                controls
                src={`${AxiosConfig.defaults.baseURL}/kreesa/public/audio/custom-order/${cstate.orderId}/${cstate.voiceMessage}`}
                style={{ height: 55 }}
                className="img-thumbnail ml-4"
              />
            )}
          </div>
          {/*Modal  */}
          {modal.show && modal.requestedModalId == cstate.orderId && (
            <Modals
              open={modal}
              onClose={handleCloseModal}
              center
              classNames={{
                overlayAnimationIn: "customEnterOverlayAnimation",
                overlayAnimationOut: "customLeaveOverlayAnimation",
                modalAnimationIn: "customEnterModalAnimation",
                modalAnimationOut: "customLeaveModalAnimation",
              }}
              animationDuration={1000}
            >
              <div className="pro">
                <img
                  className="img-fluid img-thumbnail"
                  src={`${AxiosConfig.defaults.baseURL}/kreesa/image/custom/${cstate.orderId}/${cstate.orderImage}`}
                  height={650}
                  width={700}
                  alt={cstate.orderBy}
                />
              </div>
            </Modals>
          )}

          <div className="col-md-3">
            <div className="card-body">
              <p className="h5 font-weight-bold text-uppercase">
                {cstate.orderBy}
              </p>
              <p className="h5 text-muted">{cstate.address}</p>
              {/* <p className="card-text">this isi</p>  */}
              <p className="card-text">
                <small className="badge bg-info">
                  {cstate.Approval ? "Approved" : "Not Approved"}
                </small>
              </p>

              <p className="card-text">
                Contact No:
                <span className="text-dark pl-1 font-weight-bold">
                  {cstate.contactNo}
                </span>
              </p>
            </div>
          </div>

          <div className="col-md-3 mt-2 ">
            {!cstate?.orderPlacementFlag ? (
              <div className="btn-group">
                <button
                  type="button"
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#3b7ddd",
                  }}
                  className="btn-md btn btn-success dropdown-toggle ml-sm-4 ml-xs-4"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Actions
                </button>

                <div className="dropdown-menu">
                  {cstate.Approval ? (
                    <>
                      <p
                        onClick={pass}
                        // to={{
                        //   pathname: `/custom-order/${cstate.orderId}`,
                        //   userProps: { i: "1"},
                        // }}
                        target="_blank"
                        data-target=".bd-example-modal-lg"
                        className="dropdown-item cursor-pointer"
                      >
                        <i className="fas fa-user text-info"></i> Place Order
                      </p>
                      <a
                        onClick={() => {
                          // dispatch(
                          //   confirmCustomOrderPlacement({
                          //     id: cstate.orderId,
                          //     toast,
                          //     history,
                          //   })
                          // );
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You have placed this custom order ?",
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonColor: "#28a745",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, placed !",
                            cancelButtonText: "No, cancel !",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(
                                confirmCustomOrderPlacement({
                                  id: cstate.orderId,
                                  toast,
                                  history,
                                })
                              );
                            }
                          });
                        }}
                        target="_blank"
                        data-target=".bd-example-modal-lg"
                        className="dropdown-item cursor-pointer"
                      >
                        <i className="fa fa-check text-success"></i> Confirm
                        Order Placement
                      </a>
                    </>
                  ) : (
                    <>
                      <div
                        onClick={() => handleApproveCustomOrder(orderId)}
                        className="dropdown-item cursor-pointer text-success"
                      >
                        <i className="fa fa-check"></i> Approve
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-success font-weight-bold mt-2">
                Custom Order Placed <i className="fa fa-check"></i>
              </div>
            )}
          </div>
          <div className="col-md-12">
            <p className="p-5 text-justify text-dark">
              {HtmlParser(cstate.orderDescription)}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default SingleCustomOrders;
