import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../assets/Images/kreesalogo.png";
import "./Header.css";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { searchItem, viewAllCart } from "../Features/Action/WholesalerAction";
import { toast } from "react-toastify";
import { GoSearch } from "react-icons/go";
import { RiFilter2Fill } from "react-icons/ri";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { MdSpaceDashboard } from "react-icons/md";
import {
  InputGroup,
  Dropdown,
  DropdownButton,
  FormControl,
  Button,
} from "react-bootstrap";
import { getCategories } from "../../../Common/Features/Action/CategoryAction/CategoryAction";
import QuoteModal from "../../User/Quotation/QuoteModal";
import moment from "moment";
import ReactLinkify from "react-linkify";
import SearchFilter from "../../User/searchFilter";

const Header = () => {
  const { cartDetail } = useSelector((state) => state.WholesalerReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const { loader, searchItem } = useSelector(
    (state) => state.WholesalerReducer
  );
  const userId = localStorage.getItem("userId");
  const { Categories } = useSelector((s) => s.Categories);

  const [count, setCount] = useState();
  const [searchData, setSearchData] = useState();
  const [dropdown, setDropdown] = useState({ type: "All", id: 0 });
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState();
  const [state, setstate] = useState();
  const [notificationNo, setNotificationNo] = useState("");

  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const getNotification = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const notification = await AxiosConfig.get(
        `/kreesa/wholesaler/get-all-notification/${localStorage.getItem(
          "userId"
        )}?limit=10`,
        config
      );
      setstate(notification.data);
    } catch (error) {
      console.log(error);
    }
  };
  // to get notification number
  const getNotificationNumber = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const notificationNo = await AxiosConfig.get(
        `/kreesa/wholesaler/get-total-number-of-notification/${localStorage.getItem(
          "userId"
        )}`,
        config
      );
      setNotificationNo(notificationNo.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNotification();
    getNotificationNumber();
  }, []);
  // Mark all as Read for seller
  const MarkasAllRead = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.get(
        `/kreesa/wholesaler/notification/${localStorage.getItem(
          "userId"
        )}/mark-read/all`,
        config
      );
      if (res && res.status === 200) {
        getNotification();
        getNotificationNumber();
      }
    } catch (error) {
      console.log(error);
    }
  };
  let customOrder = localStorage.getItem("customOrder");

  useEffect(() => {
    if (
      history?.location?.state?.prevPath === "/custom-order" ||
      history.location.pathname.includes("/custom-order")
    ) {
      if (customOrder) {
        dispatch(viewAllCart(JSON.parse(customOrder).userId));
      } else {
        history.push("/admin");
      }
    } else {
      dispatch(viewAllCart(userId));
    }
  }, []);

  useEffect(() => {
    dispatch(getCategories());
    setDropdown({ type: "All", id: 0 });
    setResponse();
  }, []);

  useEffect(() => {
    cartDetail && setCount(cartDetail.length);
  }, [cartDetail]);

  const dashboardRedirect = () => {
    const role = localStorage.getItem("auth");
    if (role === "ROLE_RETAILER") {
      history.push("/retailer");
    } else if (role === "ROLE_WHOLESALER") {
      history.push("/wholesaler");
    } else {
      history.push("/");
    }
  };

  const searchDataItem = () => {
    let data;
    if (dropdown.type === "Category" || dropdown.type === "All") {
      data = {
        searchKey: searchData !== undefined ? searchData : "",
        userType: "wholesale",
      };

      // dispatch(searchItem(data));
    } else {
      data = {
        catType: "Primary",
        categoryId: dropdown.id,
        searchKey: searchData,
        userType: "wholesale",
        ...(filterData !== null ? filterData : {}),
      };
      // dispatch(searchItem(data));
    }
    AxiosConfig.post(`/kreesa/search-product`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((res) => {
      res.data.length === 0
        ? setModal(true)
        : history.push({
            pathname: "/seller/search-page",
            search: `?category=${searchData}`,
            state: res.data,
          });
    });
  };

  return (
    <div id="cf">
      <div className="container-lg">
        <div className="row ">
          <div className="col-2">
            <Link
              to={
                history?.location?.state?.prevPath === "/custom-order" ||
                history.location.pathname.includes("/custom-order")
                  ? "/custom-order"
                  : "/home"
              }
            >
              {" "}
              <img
                src={logo}
                width="80"
                height="80"
                style={{ borderRadius: "20px", margin: "10px 20px" }}
                alt=""
              />
            </Link>
          </div>
          <div className="col-md-7 col-sm-11 ml-xs-4 ml-4 ml-md-0 col-11 order-last order-sm-last order-md-second  d-flex justify-content-center align-items-center h-drop">
            <div className="row ">
              <InputGroup size="lg" style={{ height: "45px" }}>
                <DropdownButton
                  variant="info"
                  title={dropdown.type}
                  id="input-group-dropdown-12 "
                >
                  <Dropdown.Item
                    onClick={() =>
                      setDropdown({
                        ...dropdown,
                        type: "All",
                      })
                    }
                    id="d-d1"
                  >
                    All
                  </Dropdown.Item>
                  {Categories?.primary?.map((i) => (
                    <Dropdown.Item
                      key={i.id}
                      onClick={() =>
                        setDropdown({
                          ...dropdown,
                          type: i.categoryName,
                          id: i.id,
                        })
                      }
                    >
                      {i.categoryName}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <FormControl
                  style={{
                    color: "black",
                    backgroundColor: "lightgrey",
                  }}
                  onChange={(e) => setSearchData(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && searchDataItem()}
                  aria-label="Text input with dropdown button"
                />
                <InputGroup.Text
                  style={{
                    cursor: "pointer",
                    fontSize: "1.2rem",
                    backgroundColor: "white",
                  }}
                  onClick={() => setFilterModal(true)}
                >
                  <RiFilter2Fill color="rgb(49, 159, 251)" size="22px" />
                </InputGroup.Text>
                <InputGroup.Text
                  style={{ cursor: "pointer", backgroundColor: "#319FFB" }}
                  onClick={() => searchDataItem()}
                >
                  <GoSearch size={18} color="white" />
                </InputGroup.Text>
              </InputGroup>
              {/* <div className="input-group ">
                <input
                  type="hidden"
                  name="search_param"
                  value="all"
                  id="search_param"
                />
                <input
                  type="text"
                  className="form-control "
                  id="bf"
                  name="x"
                  placeholder="Enter a keywords to search..."
                  onChange={(e) => setSearchData(e.target.value)}
                />
                <span className="input-group-btn">
                  <button
                    onClick={() => searchDataItem()}
                    className="btn btn-default"
                    id="sbp"
                    type="button"
                  >
                    <i className="fas fa-search-plus"></i>
                  </button>
                </span>
              </div> */}
            </div>
          </div>
          <div
            className="col-md-3 col-sm-10 col-10 order-sm-second order-second order-md-last  d-flex"
            style={{ justifyContent: "flex-end" }}
          >
            <div className="shopping-cart  d-flex justify-content-center">
              <ul
                className="d-flex flex-row justify-content-center align-items-center "
                style={{ gap: "15px" }}
              >
                <li className="nav-item dropdown">
                  <a
                    className="nav-icon dropdown-toggle"
                    href="#"
                    id="alertsDropdown"
                    data-toggle="dropdown"
                  >
                    <div
                      className="position-relative"
                      onMouseOver={MarkasAllRead}
                    >
                      <i className="fal fa-bells" data-feather="bell"></i>
                      <span
                        className={`${notificationNo === 0 ? "" : "indicator"}`}
                      >
                        {notificationNo == 0 ? "" : notificationNo}
                      </span>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-lg __drop"
                    aria-labelledby="alertsDropdown"
                  >
                    <div className="dropdown-menu-header">
                      {notificationNo === 0
                        ? ""
                        : `${notificationNo} Notifications`}
                    </div>
                    <div
                      className="list-group"
                      style={{
                        height: "500px",
                        overflow: "auto",
                      }}
                    >
                      {state?.map((p, i) => (
                        <a
                          href="#"
                          className="sm-hide list-group-item"
                          key={i}
                          onClick={() =>
                            p?.route ? history.push(p?.route) : ""
                          }
                        >
                          <div className="row g-0 align-items-center">
                            <div className="col-2">
                              <i
                                className="d-none d-sm-inline-block fas fa-bell text-info"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </div>
                            <div className="col-10">
                              {/* <div className="text-dark">Lorem ipsum</div> */}
                              <div className="text-muted small mt-1">
                                <ReactLinkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target="blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  {p.notification_message}
                                </ReactLinkify>
                              </div>
                              <div className="font-weight-bold small mt-1">
                                {moment(p.created_date).fromNow()}
                                <span style={{ color: "grey" }}>
                                  {`  (${moment(p.creationDate).format(
                                    "DD MMM, YYYY"
                                  )})`}
                                </span>
                              </div>
                              <div className="text-dark mt-1">
                                {!p.read_flag && (
                                  <i className="fa fa-eye text-success font-weight-bold"></i>
                                )}
                                <span className="text-muted">
                                  {" "}
                                  {!p.read_flag ? "(Unseen)" : "(seen)"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                    <div className="dropdown-menu-footer">
                      <Link
                        to={
                          localStorage.getItem("auth") === "ROLE_RETAILER"
                            ? "/retailer/all-notification"
                            : "/wholesaler/all-notification"
                        }
                      >
                        Show all notifications
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="nav-item" id="l-text">
                  <h4
                    className="dashboard-home m-0"
                    onClick={() => dashboardRedirect()}
                  >
                    <MdSpaceDashboard size="25px" />
                  </h4>
                </li>

                <li
                  className="nav-item px-3 text-uppercase position-relative d-lg-flex __cart-icon"
                  style={{ marginLeft: "20px" }}
                >
                  <Link
                    to={
                      history?.location?.state?.prevPath === "/custom-order" ||
                      history.location.pathname.includes("/custom-order")
                        ? "/custom-order/cart"
                        : "/seller/cart"
                    }
                    className="cart position-relative d-inline-flex"
                  >
                    {" "}
                    <ShoppingCartIcon
                      className="fa-lg"
                      color="#319ffb"
                      style={{ height: "30px" }}
                    />
                    {count ? (
                      <span className="cart-baskets d-flex align-items-center justify-content-center">
                        {count}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SearchFilter
        modal={filterModal}
        setModal={setFilterModal}
        data={filterData}
        setData={setFilterData}
      />
      <QuoteModal modal={modal} setModal={setModal} />
    </div>
  );
};

export default Header;
