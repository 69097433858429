import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Excerpt } from "../../../utils";
import QuickViewButton from "../../../Widgets/OuickViewButton/QuickViewButton";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { getSingleRetailProducts } from "../Features/action";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const Card = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="container-fluid bg-white px-lg-6 mt-1 mb-4 py-2">
      <div className="row pt-3">
        <div className="col-8">
          <h4>{props?.title}</h4>
        </div>
        <div className="col col-sm-4 view__buttons_">
          <div>
            <Link
              to={{
                pathname: `/view-all/${props?.title}`,
                state: {
                  title: props?.title,
                },
              }}
              className="btn btn-primary shadow px-4"
            >
              View All
            </Link>
          </div>
        </div>
      </div>
      <hr />
      <div
        className="row d-flex nowrap justify-content-center py-1"
        style={{
          backgroundColor: "#e2e6eb",
        }}
      >
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          centerMode={true}
          containerclassName="carousel-container"
          dotListclassName="custom-dot-list-style"
          itemclassName="carousel-item-padding-40-px"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          arrows={props.title === "Feature Products" ? false : true}
        >
          {props?.data?.map((product) => (
            <div className="card text-center sales__product">
              {props?.customdata?.userId ? (
                <Link
                  to={{
                    pathname: `/single-product/${product?.id}`,
                    key: product?.id,
                    id: product?.id,
                    state: { prevPath: history.location.pathname },
                    slectImage:
                      AxiosConfig.defaults.baseURL +
                      "/kreesa/image/product/" +
                      product?.image?.id +
                      "/" +
                      product?.image?.image,
                  }}
                >
                  <img
                    className={`card-img-top p-2 img-fluid w-100 ${
                      product?.specification?.stock < 1 && "blur-image"
                    }`}
                    alt={product?.image?.imageName}
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                    srcset={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                    style={{
                      height: 160,
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                  {product?.specification?.stock < 1 && (
                    <div
                      className="btn btn-danger"
                      style={{
                        position: "absolute",
                        borderRadius: 20,
                        top: "30%",
                        right: "32%",
                      }}
                    >
                      Sold out
                    </div>
                  )}
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/single-product/${product?.id}`,
                    key: product?.id,
                    id: product?.id,
                    slectImage:
                      AxiosConfig.defaults.baseURL +
                      "/kreesa/image/product/" +
                      product?.image?.id +
                      "/" +
                      product?.image?.image,
                  }}
                >
                  <img
                    className={`card-img-top p-2 img-fluid w-100 ${
                      product?.specification?.stock < 1 && "blur-image"
                    }`}
                    alt={product?.image?.imageName}
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                    srcset={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                    style={{
                      height: 160,
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                  {product?.specification?.stock < 1 && (
                    <div
                      className="btn btn-danger"
                      style={{
                        position: "absolute",
                        borderRadius: 20,
                        top: "30%",
                        right: "32%",
                      }}
                    >
                      Sold out
                    </div>
                  )}
                </Link>
              )}

              <div className="card-body p-1" title={product?.productName}>
                <Link
                  to={{
                    pathname: `/single-product/${product?.id}`,
                    state: {
                      prevPath: history.location.pathname,
                    },
                  }}
                >
                  <h6 className="card-title text-capitalize">
                    {Excerpt(product?.productName, 15)}
                  </h6>
                </Link>
                <div
                  onClick={() => dispatch(getSingleRetailProducts(product?.id))}
                  className="card-link"
                >
                  <QuickViewButton
                    image={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${product?.image?.id}/${product?.image?.imageName}`}
                    review={product?.review}
                    description={product?.productHighlight}
                  />
                </div>
                <p
                  style={{
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  Rs {product.specification?.productPrice}
                </p>

                {product?.specification?.productDiscount !== 0 && (
                  <p className="card-subtitle">
                    - {product?.specification?.productDiscount} % OFF
                  </p>
                )}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      {/* <hr className="mx-5" /> */}
    </div>
  );
};

export default Card;
