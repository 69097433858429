import React from 'react'
import { Redirect } from 'react-router-dom'
import Footer from '../Footer'
import LeftNavbar from '../LeftNavbar'
import TopNavbar from '../TopNavbar'
import PushNotificationDetail from './PushNotificationDetail'

const Notification = () => {
    document.title = "Admin | Notification"
    // to check authentication user or not
    let token = localStorage.getItem('token', 'username','auth');
    if (token === null) {
        return <Redirect exact to="/admin/login" />
    }
    return (
        <div className="wrapper">
            <LeftNavbar />
            <div className="main">
                <TopNavbar />
                <main className="content">
                <PushNotificationDetail/>    
                </main>
                <Footer />
            </div>
        </div>
    )
}

export default Notification
