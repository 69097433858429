import { createSlice } from "@reduxjs/toolkit";
import { getPrimaryCategory, editPrimaryCategory } from "../../Action/CategoryAction/CategoryAction";

const PrimaryCategoryReducer = createSlice({
  name: "primaryCategories",
  initialState: {
    primaryCategories: [],
    primaryContainer: [],
    loader: false,
    errors: {},
    editPrimaryCategories: [],
  },
  reducers: {
    filterPrimaryCategory: (state, action) => {
      state.primaryCategories = state.primaryContainer.filter((u) => u.categoryName.toLowerCase().includes(action.payload) || u.categoryName.toUpperCase().includes(action.payload));
    },
  },
  extraReducers: {
    /* to get all Primary Categories */
    [getPrimaryCategory.pending]: (state, action) => {
      state.loader = true;
    },
    [getPrimaryCategory.fulfilled]: (state, action) => {
      state.loader = false;
      state.primaryCategories = action.payload;
      state.primaryContainer = action.payload;
    },
    [getPrimaryCategory.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    /* edit Primary Categories */
    [editPrimaryCategory.pending]: (state, action) => {
      state.loader = true;
    },
    [editPrimaryCategory.fulfilled]: (state, action) => {
      state.loader = false;
      state.editPrimaryCategories = action.payload;
      state.primaryContainer = action.payload;
    },
    [editPrimaryCategory.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export
export const { filterPrimaryCategory } = PrimaryCategoryReducer.actions;
// export default
export default PrimaryCategoryReducer.reducer;
