import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import moment from "moment";
import HtmlParser from "react-html-parser";
import Spinner from "../../../Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Excerpt } from "../../../../../utils";

const CustomerBannerDetails = (props) => {
  const history = useHistory();
  const [state, setState] = useState([]);

  // _______Modal_________//
  const [modalId, setModalId] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => setModalId("");

  const getcustomerBanner = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const type = "customer";
      const getCustomerDetails = await AxiosConfig.get(
        `/kreesa/admin/banner/get-alll-banner-by-type/${type}`,
        config
      );
      setState(getCustomerDetails.data);
      setLoading(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setLoading(false);
    }
  };
  /* ----------------------------------- deleteBanner ---------------------------------- */
  const deleteBanner = async (bannerId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      // const type = "customer";
      await AxiosConfig.get(`/kreesa/admin/banner/delete/${bannerId}`, config);
      // setState(getCustomerDetails.data);
      await getcustomerBanner();
    } catch (err) {
      console.log(err);
    }
  };
  /* ----------------------------------- deleteBanner ---------------------------------- */

  useEffect(() => {
    getcustomerBanner();
  }, []);

  /* -------------------------- seller length check -------------------------- */
  const sellerHandler = () => {
    if (state.length < 8) {
      return history.push("/admin/user-banner");
    } else {
      return toast.error(
        "Banner limit reached, Please update the existing one !!!"
      );
    }
  };

  return !loading ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <div
                onClick={sellerHandler}
                className="btn btn-lg btn-outline-info display-5 mt-2"
                style={{ float: "right" }}
              >
                Add User Banner <i className="fa fa-picture-o ml-2"></i>
              </div>
              {/* <form className="d-none mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search User Banner…"
                    aria-label="Search"
                    // ref={inputRef} onChange={filterCategory}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">Customers Banners </h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Banner</th>
                  <th>Banner Position</th>
                  <th>Redirection Link</th>
                  <th>Creation Date</th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody>
                {state?.map((p, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <div className="cursor-pointer">
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${p.id}/${p.image}`}
                          width="48"
                          height="48"
                          loading="lazy"
                          className="rounded-circle mr-2 "
                          alt="Banner"
                        />
                      </div>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          p.bannerPosition === "TOP"
                            ? "bg-success"
                            : "bg-danger"
                        } p-2`}
                      >
                        {p.bannerPosition}
                      </span>
                    </td>
                    <td>
                      {p.redirectionLink === null ? (
                        "_____"
                      ) : (
                        <a href={p.redirectionLink} target="_blank">
                          {Excerpt(p?.redirectionLink, 25)}
                        </a>
                      )}
                    </td>
                    <td>{moment(p.creationDate).format("ll")}</td>
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#3b7ddd",
                          }}
                          className="btn-md btn btn-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <div className="dropdown-menu text-left">
                          <Link
                            className="dropdown-item text-info"
                            to={`/admin/edit-user-banner/${p.id}`}
                          >
                            <i className="fas fa-user-edit"></i> Edit
                          </Link>
                          <div className="dropdown-divider"></div>
                          <button
                            onClick={() => setModalId(`modal${i}`)}
                            className="dropdown-item text-success"
                          >
                            <i className="fas fa-eye"></i> Quick View
                          </button>
                          <div className="dropdown-divider"></div>
                          <button
                            className="dropdown-item text-danger"
                            onClick={() => deleteBanner(p.id)}
                          >
                            <i className="fas fa-trash"></i> Delete
                          </button>
                          <Modal
                            show={modalId === `modal${i}`}
                            onHide={handleClose}
                            {...props}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header>
                              <Modal.Title>User Banner Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="container">
                                <div className="row">
                                  <div className="col">
                                    <img
                                      className="img-fluid img-thumbnail"
                                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/banner/${p.id}/${p.image}`}
                                      height={650}
                                      width={700}
                                      alt="banner"
                                    />
                                  </div>
                                  <div className="col">
                                    <h4>Description</h4>
                                    {HtmlParser(p.description)}
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default CustomerBannerDetails;
