import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../Admin/Spinner";
import {
  deliveryBoyConfirm,
  getSingleDeliveryOrder,
} from "../../Features/Action/DeliveryAction";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Modal as Modals } from "react-responsive-modal";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import moment from "moment";
import SupplierDetailsModal from "../../../Admin/Orders/SingleOrderDetail/SupplierDetailsModal";

const Order = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  const { delivery, loader } = useSelector((state) => state.DeliveryReducer);

  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const [user, setUser] = useState();
  const [modalId, setModalId] = useState("");
  const [specId, setSpecId] = useState();

  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };
  // get consumer details
  const getUsers = async (id) => {
    // console.log(delivery?.orderAndShippingDetails?.consumerId, "delivery ii");

    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const getu =
        delivery?.orderAndShippingDetails?.consumerId &&
        (await AxiosConfig.get(
          `/kreesa/admin/get-user-by-user-id/${delivery?.orderAndShippingDetails?.consumerId}`,
          config
        ));
      setUser(getu.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDelivery = (id) => {
    dispatch(deliveryBoyConfirm({ id, status: "DISPATCHED", history }));
    // history.goBack();
  };

  useEffect(() => {
    dispatch(getSingleDeliveryOrder(id));
  }, []);

  useEffect(() => {
    if (delivery) {
      //   console.log(delivery);
      getUsers();
    }
  }, [delivery]);

  return !loader ? (
    <div className="text-capitalize">
      <div className="card mx-lg-4 p-2">
        <h5 className="font-weight-bold">Order Details</h5>
      </div>
      <div className="card mx-lg-4 mt-4">
        <div className="p-1">
          <p className="ml-2">
            Order Id:{" "}
            <span className="text-muted">
              {delivery?.orderAndShippingDetails?.orderNo}
            </span>
          </p>
          <p className="card-text ml-2">
            Order Date:{" "}
            <span className="text-muted">
              {moment(delivery?.orderAndShippingDetails?.orderDate).format(
                "lll"
              )}
            </span>
          </p>
          <p className="card-text ml-2">
            Order Type:{" "}
            <span className="text-muted font-weight-bold">
              {delivery?.orderAndShippingDetails?.orderType}
            </span>
            <span
              onClick={() =>
                handleOpenModal(delivery?.orderAndShippingDetails?.consumerId)
              }
              className="text-success ml-4 cursor-pointer"
            >
              Consumer Details
            </span>
          </p>
        </div>
      </div>
      {modal.show &&
        modal.requestedModalId ==
          delivery?.orderAndShippingDetails?.consumerId && (
          <Modals
            open={modal}
            onClose={handleCloseModal}
            center
            classNames={{
              overlayAnimationIn: "customEnterOverlayAnimation",
              overlayAnimationOut: "customLeaveOverlayAnimation",
              modalAnimationIn: "customEnterModalAnimation",
              modalAnimationOut: "customLeaveModalAnimation",
              modal: "customModal",
            }}
            animationDuration={1000}
          >
            <div className="pro">
              {/* <div className="col-md-4">
                    <div className="profile-img">
                      <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${id}/${user.companyLogo}`} alt={user?.companyName} />
                    </div>
                  </div> */}

              <h3 className="text-uppercase">{user?.companyName} </h3>
            </div>

            <div className="col-md-6 m-auto">
              <div className="tab-content profile-tab custom-height">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      {delivery?.orderAndShippingDetails?.orderType ===
                      "retail" ? (
                        <label>Full Name</label>
                      ) : (
                        <label>Company Name</label>
                      )}
                    </div>
                    {delivery?.orderAndShippingDetails?.orderType ===
                    "retail" ? (
                      <div className="col-md-6">
                        <p>
                          {user?.firstname} {user?.lastname}
                        </p>
                      </div>
                    ) : (
                      <div className="col-md-6">
                        <p>{user?.companyName}</p>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {delivery?.orderAndShippingDetails?.orderType ===
                      "retail" ? (
                        <label> Address</label>
                      ) : (
                        <label>Company Address</label>
                      )}
                    </div>
                    <div className="col-md-6">
                      <p>
                        {`${
                          user.streetAddress === null
                            ? ""
                            : user?.streetAddress + ","
                        } ${user?.districtName}, ${user?.provienceName}, ${
                          user?.countryName
                        }, (${user?.pinCode})`}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-md-6">
                      {delivery?.orderAndShippingDetails?.orderType ===
                      "retail" ? (
                        <label> Email</label>
                      ) : (
                        <label>Company Email</label>
                      )}
                    </div>
                    <div className="col-md-6">
                      {delivery?.orderAndShippingDetails?.orderType ===
                      "retail" ? (
                        <p>{user?.emailId}</p>
                      ) : (
                        <p>{user?.companyEmail}</p>
                      )}
                    </div>
                  </div>
                  {delivery?.orderAndShippingDetails?.orderType ===
                    "wholesale" && (
                    <div className="row">
                      <div className="col-md-6">
                        <label>Company Type</label>
                      </div>
                      <div className="col-md-6">
                        <p>{user?.companyType}</p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      {delivery?.orderAndShippingDetails?.orderType ===
                      "retail" ? (
                        <label> Mobile</label>
                      ) : (
                        <label>Company Contact</label>
                      )}
                    </div>
                    <div className="col-md-6">
                      {delivery?.orderAndShippingDetails?.orderType ===
                      "retail" ? (
                        <p>{user?.contact}</p>
                      ) : (
                        <p> {user?.companyContactNo}</p>
                      )}
                    </div>
                  </div>
                  {delivery?.orderAndShippingDetails?.orderType ===
                    "wholesale" && (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <label>PAN No</label>
                        </div>
                        <div className="col-md-6">
                          <p> {user?.panNo}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Register Status</label>
                        </div>
                        <div className="col-md-6">
                          {user?.isRegistered === true ? (
                            <p>Register</p>
                          ) : (
                            <p>Not Register</p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Insuranced Status</label>
                        </div>
                        <div className="col-md-6">
                          {user?.isInsured === true ? (
                            <p>Insuranced</p>
                          ) : (
                            <p>Not Insuranced</p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modals>
        )}
      {delivery?.orderDetails?.map((p, index) => (
        <div className="card mx-lg-4 mt-4" key={index}>
          <div className="">
            <h5 className=" ml-4 mt-3 pt-1 font-weight-bold">
              Uploaded by {p?.productDetails?.uploadedBy}
            </h5>
            <hr className="text-success mx-4" />
          </div>
          {/*  ------------------------------- main image -------------------------------  */}
          <div className="row g-0">
            {p.image?.map((img, i) =>
              i === 0 ? (
                <div className="col-md-3 ml-4" key={i}>
                  <img
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                    className="img-fluid m-2 rounded-start"
                    style={{
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                    }}
                    alt={img.image}
                  />
                </div>
              ) : (
                ""
              )
            )}
            <div className="col-md-3">
              <div className="card-body">
                <p className="h5 font-weight-bold">
                  {p.productDetails.productName}
                </p>
                {/* <p className="card-text">this isi</p>  */}
                <p className="card-text">
                  <small className="badge bg-info">
                    {p.order?.selectedColor}
                  </small>
                </p>
                {p.order.discount === 0 ? (
                  <p className="card-text">
                    <small className="text-warning h4">
                      Rs. {p.order?.productPrice}
                    </small>
                  </p>
                ) : (
                  <p className="card-text">
                    <s className="text-muted">Rs. {p.order?.productPrice}</s>
                    <small className="ml-2 text-warning font-weight-bold h4">
                      Rs{" "}
                      {Math.round(
                        p.order?.productPrice -
                          (p.order?.productPrice * p.order?.discount) / 100
                      )}
                    </small>
                    <small className="ml-2 h5">
                      {" "}
                      - {p.order?.discount} % OFF
                    </small>
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-1 m-4">
              <p className="card-text">
                <small
                  className="badge bg-secondary cursor-pointer"
                  onClick={() => {
                    setModalId(`modal${p?.order?.supplierId}`);
                  }}
                >
                  Supplier Details
                </small>
              </p>

              {/* Modal */}
              {p?.order?.supplierId && (
                <SupplierDetailsModal
                  show={modalId === `modal${p?.order?.supplierId}`}
                  // {...props}
                  sid={p?.order?.supplierId}
                  onHide={() => setModalId("")}
                  state={delivery}
                />
              )}

              <p className="card-text font-weight-bold">
                Height: {p.productDetails?.hight}
              </p>
              <p className="card-text font-weight-bold">
                Weight: {p.productDetails?.weight}
              </p>
              <p className="card-text font-weight-bold">
                Size: {p.productDetails?.size}
              </p>
            </div>
            <div className="col-md-1 font-weight-bold m-4">
              Qty:{p.order.orderQuantity}
            </div>

            <div className="col-md-2 m-2">
              <div className="btn-group">
                {/* <button
                  type="button"
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#3b7ddd",
                  }}
                  className="btn-md btn btn-success dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Actions
                </button> */}

                <div className="dropdown-menu">
                  {/* {p.order.orderApproval === "Approved" ? (
                      <>
                        <div
                          onClick={() => {
                            handlAssignOrderModal(p.order.specificationId);
                            setOrderDetailsId(p.order.id);
                            setModalId(`modal${p.order.id}`);
                          }}
                          data-target=".bd-example-modal-lg"
                          className="dropdown-item cursor-pointer"
                          to="#"
                        >
                          <i className="fas fa-user text-info"></i> Assign
                        </div>
                        <div className="dropdown-divider"></div>
                      </>
                    ) : (
                      <> */}
                  <div
                    onClick={() =>
                      handleConfirmDelivery(delivery.orderAndShippingDetails.id)
                    }
                    className="dropdown-item cursor-pointer text-center"
                  >
                    <i className="fas fa-check text-success"></i> Confirm
                  </div>
                  {/* <div className="dropdown-divider"></div> */}
                  {/* </>
                    )} */}

                  {/* <div
                    // onClick={() => {
                    //   handlerejectOrder(p.order.id);
                    // }}
                    className="dropdown-item cursor-pointer"
                  >
                    <i className="fas fa-trash-alt text-danger"></i> Reject
                  </div> */}
                </div>
              </div>
            </div>
            {/*  ------------------------------- model start ------------------------------  */}
            <div className="row m-4">
              {p.image?.map((img, i) => (
                <div className="col-md-2" key={i}>
                  <div
                    onClick={() => handleOpenModal(img.imageId)}
                    className="d-flex cursor-pointer"
                  >
                    <img
                      className="img-fluid img-thumbnail m-1"
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                      height={100}
                      width={120}
                      alt={img.image}
                    />
                  </div>
                  {/*Modal  */}
                  {modal.show && modal.requestedModalId == img.imageId && (
                    <Modals
                      open={modal}
                      onClose={handleCloseModal}
                      center
                      classNames={{
                        overlayAnimationIn: "customEnterOverlayAnimation",
                        overlayAnimationOut: "customLeaveOverlayAnimation",
                        modalAnimationIn: "customEnterModalAnimation",
                        modalAnimationOut: "customLeaveModalAnimation",
                      }}
                      animationDuration={1000}
                    >
                      <div className="pro">
                        <img
                          className="img-fluid img-thumbnail"
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                          height={650}
                          width={700}
                          alt={img.image}
                        />
                      </div>
                    </Modals>
                  )}
                </div>
              ))}
            </div>
            {/*  ------------------------------- model end ------------------------------  */}
          </div>
          {/* large modal start*/}
          <Modal
            show={modalId === `modal${p.order.id}`}
            onHide={() => setModalId("")}
          >
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card flex-fill m-4 overflow-auto">
                <table className="table table-hover my-0">
                  <thead>
                    <tr>
                      <th>Sn.</th>
                      <th className="d-xl-table-cell">Company Name</th>
                      <th className="d-md-table-cell">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {specId?.map((p, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td className="d-md-table-cell">{p.company_name}</td>
                        <td>
                          <a
                            href
                            // onClick={() => handlOrderAssign(p.user_id)}
                            className="cursor-pointer"
                          >
                            <span className="badge bg-success">
                              <i className="fas fa-check"></i> Assign
                            </span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModalId("")}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ))}

      <div
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                className="m-2"
                style={{ float: "right", fontSize: "30px" }}
              >
                &times;
              </span>
            </button>
            <div className="card flex-fill m-4 overflow-auto">
              <table className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th className="d-xl-table-cell">Company Name</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {specId?.map((p, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="d-md-table-cell">{p.company_name}</td>
                      <td>
                        <a
                          href
                          // onClick={() => handlOrderAssign(p.user_id)}
                          className="cursor-pointer"
                        >
                          <span className="badge bg-success">
                            <i className="fas fa-check"></i> Assign
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* large modal end*/}

      <div className="card mx-lg-4 mt-4">
        <div className="row">
          <div className="col-6 ml-5 mt-4 col-md-3">
            {/*<h6 className="h6 ml-4 mt-1">Billing Address</h6>
            <hr className="text-info" />
            <p className="ml-2">Name</p>
            <i className="ml-2">{delivery.shippingAddress}</i>
            <p className="ml-2">{delivery.contactNo}</p>*/}
          </div>
          <div className="col-6 ml-5 mt-4 col-md-3">
            <h6 className="h6 ml-4 mt-1">Shipping Address</h6>
            <hr className="text-info" />
            <p className="ml-2">Name</p>
            <i className="ml-2">
              {delivery?.orderAndShippingDetails?.shippingState} -{" "}
              {delivery?.orderAndShippingDetails?.shippingAddress} -{" "}
              {delivery?.orderAndShippingDetails?.shippingCountry}
            </i>
            <p className="ml-2">
              {delivery?.orderAndShippingDetails?.contactNo}
            </p>
          </div>

          <div className="col-6 ml-5 mt-4 col-md-3">
            <ul className="list-group mb-3">
              {delivery?.orderDetails?.map((p, i) => (
                <li
                  className="list-group-item d-flex justify-content-between lh-sm"
                  key={i}
                >
                  <div>
                    <h6 className="my-0">{p.productDetails.productName}</h6>
                    <span className="text-muted">
                      Qty : {p.order.orderQuantity}
                    </span>
                  </div>
                  <small className="text-muted">
                    Rs{" "}
                    {Math.round(
                      p.order?.productPrice -
                        (p.order?.productPrice * p.order?.discount) / 100
                    ) * p.order.orderQuantity}
                  </small>
                </li>
              ))}
              <li className="list-group-item d-flex justify-content-between">
                <span>Shipping Cost</span>
                <strong>
                  Rs {delivery?.orderAndShippingDetails?.shippingCost}
                </strong>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span>Total (Npr)</span>
                <strong>
                  {Math.round(delivery?.orderAndShippingDetails?.totalCost) +
                    50}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Order;
