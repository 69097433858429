import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";

/* -------------------------------------------------------------------------- */
/*                           Countries Actions start                          */
/* -------------------------------------------------------------------------- */

/* to get all Countries */
export const getAllCountries = createAsyncThunk("getCountries", async () => {
  const { data } = await AxiosConfig.get(
    "/kreesa/admin/address/get-all-country"
  );
  return data;
});

/* to get single Countries */
export const getSingleCountries = createAsyncThunk(
  "getSingleCountries",
  async (id) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/address/get-country/${id}`
    );
    return data;
  }
);

/* to update Countries */
export const updateCountries = createAsyncThunk(
  "updateCountries",
  async (datas, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/address/update-country`,
      datas
    );
    dispatch(getAllCountries());
    return data;
  }
);

/* -------------------------------------------------------------------------- */
/*                            Province Actions Start                           */
/* -------------------------------------------------------------------------- */

// to get all Province
export const getAllProvince = createAsyncThunk("getAllProvince", async () => {
  const { data } = await AxiosConfig.get(
    "/kreesa/admin/address/get-all-province"
  );
  return data;
});

// to get single Province
export const getSingleProvince = createAsyncThunk(
  "getSingleProvince",
  async (id) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/address/get-province/${id}`
    );
    return data;
  }
);

/* to update Proviences */
export const updateProviences = createAsyncThunk(
  "updateProviences",
  async (datas, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/address/update-province`,
      datas
    );
    dispatch(getAllProvince());
    return data;
  }
);

/* -------------------------------------------------------------------------- */
/*                            District Actions Start                           */
/* -------------------------------------------------------------------------- */

// to get all district
export const getAllDistrict = createAsyncThunk("getAllDistrict", async () => {
  const { data } = await AxiosConfig.get(
    "/kreesa/admin/address/get-all-district"
  );
  return data;
});

// to get single district
export const getSingleDistrict = createAsyncThunk(
  "getSingleDistrict",
  async (id) => {
    const { data } = await AxiosConfig.get(
      `/kreesa/admin/address/get-district/${id}`
    );
    return data;
  }
);

/* to update district */
export const updateDistrict = createAsyncThunk(
  "updateDistrict",
  async (datas, { dispatch }) => {
    const { data } = await AxiosConfig.post(
      `/kreesa/admin/address/update-district`,
      datas
    );
    dispatch(getAllDistrict());
    return data;
  }
);
