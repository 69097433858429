import React from "react";
import SingleProduct from "./SingleProduct";
import SingleProductDetail from "./SingleProductDetail";

const index = () => {
  return (
    <div>
      {/* <SingleProduct /> */}
      <SingleProductDetail />
    </div>
  );
};

export default index;
