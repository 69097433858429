import { Button, Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  makeProductFlash,
  updateFlashProduct,
} from "../../Features/Action/FeatureProductAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";

const FlashModal = ({ state, setState, data, mode = "create" }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    from_date: "",
    to_date: "",
    discount: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    if (mode === "update") {
      setValue({
        from_date: new Date(data.fromDate),
        to_date: new Date(data.toDate),
        discount: data.specialDiscount,
      });
    } else {
      setValue({
        from_date: "",
        to_date: "",
        discount: "",
      });
    }
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "discount") {
      setError(false);
      setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  return (
    <Modal
      show={state.includes("flash" + data.id)}
      onHide={() => {
        setState(state.filter((i) => i !== "flash" + data.id));
        setError(false);
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Flash Product Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 row">
          <label for="staticEmail" className="col-3 col-form-label">
            Start Date
          </label>
          <div className="col-9">
            <DatePicker
              minDate={new Date()}
              className="form-control"
              name="from_date"
              selected={value?.from_date || ""}
              onChange={(date) => setValue({ ...value, from_date: date })}
              placeholderText="Start Date"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label for="staticEmail" className="col-3 col-form-label">
            End Date
          </label>
          <div className="col-9">
            <DatePicker
              minDate={value?.from_date || new Date()}
              className="form-control"
              name="to_date"
              selected={value?.to_date || ""}
              onChange={(date) => setValue({ ...value, to_date: date })}
              placeholderText="End Date"
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label for="staticEmail" className="col-3 col-form-label">
            Discount
          </label>
          <div className="col-9">
            <input
              type="tel"
              style={{
                border: "1px solid #d3d3d3",
                paddingLeft: "10px",
              }}
              value={value.discount}
              onChange={handleChange}
              className="form-control-plaintext"
              name="discount"
              placeholder="For example: 10"
            />
          </div>
        </div>

        {error && <p className="text-danger">{errorText}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            if (Object.values(value).includes("")) {
              setError(true);
              setErrorText("Please fill up the form");
            } else {
              if (moment(state.to_date) > moment(state.from_date)) {
                setError(true);
                setErrorText("End Date cannot be greater than Start Date");
                return;
              }
              setState(state.filter((i) => i !== "flash" + data.id));

              mode === "update"
                ? dispatch(
                    updateFlashProduct({
                      ...value,
                      flashSaleId: data.flashSalesId,
                      productId: data.id,
                      type: history.location.pathname.includes("retail")
                        ? "retail"
                        : "wholesale",
                    })
                  )
                : dispatch(
                    makeProductFlash({
                      ...value,
                      productId: data.id,
                      type: history.location.pathname.includes("retail")
                        ? "retail"
                        : "wholesale",
                    })
                  );

              //remove all values
              for (const key in value) {
                delete value[key];
              }
            }
          }}
        >
          Submit
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setError(false);
            setState(state.filter((i) => i !== "flash" + data.id));
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FlashModal;
