import React, { useEffect } from "react";
import "../HomepageMainContent/HomepageMainContent.css";
import Navbar from "../NavBar/Navbar";
import ImageSlide from "../ImageSlide/ImageSlide";
import video from "../../../assets/Images/video2.mp4";
import banner2 from "../../../assets/Images/coming.jpg";
import banner3 from "../../../assets/Images/coming2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../Common/Features/Action/CategoryAction/CategoryAction";
import $ from "jquery";
import { homePageSearch } from "../Features/action";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import AxiosConfig from "../../../Common/Api/AxiosConfig";

function HomepageMainContent() {
  const history = useHistory();
  // to get all Categories(Primary, Secondary, Ternary)
  const { Categories, loader } = useSelector((s) => s.Categories);
  const { searchItem } = useSelector((state) => state.userProducts);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const dropdownSearch = (id, type) => {
    let data = {
      catType: type,
      categoryId: id,
      userType: "retail",
    };
    AxiosConfig.post(`/kreesa/search-product`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((res) => {
      if (res.data?.length > 0) {
        dispatch(homePageSearch(res.data));
        history.push("/user/search-page");
      }
    });
  };

  const [dataSearch, setDataSearch] = useState();

  useEffect(() => {
    setDataSearch(searchItem);
  }, [searchItem]);

  $(".btn-group, .dropdown").hover(
    function () {
      $(">.dropdown-menu", this).stop(true, true).fadeIn("fast");
      $(this).addClass("open");
    },
    function () {
      $(">.dropdown-menu", this).stop(true, true).fadeOut("fast");
      $(this).removeClass("open");
    }
  );

  return (
    <>
      <Navbar />

      <div className="container-fluid text-capitalize">
        <div className="row">
          {/* <div className="col-xs-12 col-md-4 col-lg-3">
            <ul id="ul-list" className="">
              {Categories.primary
                ?.slice(0)
                .reverse()
                .map((p, i) => (
                  <li
                    className="dropdown-submenu pt-2 mb-2"
                    style={{ cursor: "pointer" }}
                    key={i}
                  >
                    <div
                      onClick={() => {
                        dropdownSearch(p.id, "primary");
                      }} >
                    
                      {p.categoryName}
                    </div>
                    <MdKeyboardArrowRight className="cat-icon" />
                    <ul className="dropdown-menu">
                      {Categories.secondary
                        ?.slice(0)
                        .reverse()
                        .map(
                          (sec, is) =>
                            p.id === sec.primaryCategoryId && (
                              <li className="dropdown-submenu" key={is}>
                                <a
                                  onClick={() => {
                                    dropdownSearch(sec.id, "secondary");
                                  }}
                                  className="dropdown-item"
                                >
                                  {sec.secondaryCategoryName}
                                  <MdKeyboardArrowRight className="cat-icon" />
                                </a>
                                <ul className="dropdown-menu">
                                  {Categories.ternary
                                    ?.slice(0)
                                    .reverse()
                                    .map(
                                      (ten, it) =>
                                        sec.id === ten.secondaryCategoryId && (
                                          <li key={it}>
                                            <a
                                              onClick={() => {
                                                dropdownSearch(
                                                  ten.id,
                                                  "ternary"
                                                );
                                              }}
                                              className="dropdown-item"
                                            >
                                              {ten.ternaryCategoryName}
                                            </a>
                                          </li>
                                        )
                                    )}
                                </ul>
                              </li>
                            )
                        )}
                    </ul>
                  </li>
                ))}
            </ul>
          </div> */}
          {/* <div
            className="col-md-12 col-xs-12 col-lg-12 image-slider"
          > */}
          <ImageSlide />
          {/* </div> */}
          {/* <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="embed-responsive embed-responsive-16by9 b-video">
              <video autoPlay="autoplay" muted width="105%" height="120px">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <img src={banner2} className="imgg mt-1" alt="" />
            <img src={banner3} className="imgg mt-1" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default HomepageMainContent;
