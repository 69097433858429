import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllUserDetails,
  updateUser,
} from "../../Action/AllUserDetailsAction";
import TutorialDataService from "../../Services/TutorialService";
import { UPDATE_USER } from "../../Action/Types/types";
import AxiosConfig from "../../../Api/AxiosConfig";

const s = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/graphql",
  },
};

export const updateTutorial = createAsyncThunk(
  "user/update",
  async ({ company_address, company_contact_no }) => {
    return fetch(AxiosConfig + "/kreesa/wholesaler/update-profile", {
      metho: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/graphql",
      },
      body: JSON.stringify({
        company_address,
        company_contact_no,
      }),
    }).then((res) => res.json);
  }
);

// export const findTutorialsByEmail = createAsyncThunk(
//   "user/findByTitle",
//   async ({ email }) => {
//     const res = await TutorialDataService.get(email);
//     return res.data;
//   }
// );
// export const getUser = createAsyncThunk(
//   "user/getUser",
//   async () => {
//     let email=localStorage.getItem("username")
//     return await fetch(AxiosConfig+`/kreesa/user/view/${email}`,s)
//     .then((res)=>
//       res.json()
//     );
//   });

const UserDetails = createSlice({
  name: "userDetails",
  initialState: {
    userDetails: [],
    edit: false,
    body: "",
    loader: false,
    errors: {},
  },
  reducers: {
    setEdit: (state, action) => {
      state.edit = state.payload.edit;
      state.body = action.payload.body;
    },
  },

  extraReducers: {
    /* to get all User Details */
    [getAllUserDetails.pending]: (state, action) => {
      state.loader = true;
    },
    [getAllUserDetails.fulfilled]: (state, action) => {
      state.loader = false;
      state.userDetails = action.payload;
    },
    [getAllUserDetails.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
    [updateTutorial.pending]: (state, action) => {
      state.loader = true;
    },
    [updateTutorial.fulfilled]: (state, action) => {
      state.loader = false;
      state.userDetails = [action.payload];
      // const index=state.findIndex(userDetails=>userDetails === action.payload);
      // state[index]={
      //   ...state[index],
      //   ...action.payload
      // }
    },
    [updateTutorial.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export default
export const { setEdit } = UserDetails.actions;
export default UserDetails.reducer;
