import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import useButtonLoader from "../../../../ButtonLoaderHook";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Spinner from "../../../Spinner";
import { getAllCountries } from "../../../../Admin/Features/Action/AddressAction";
import { getAllProvince } from "../../../../Admin/Features/Action/AddressAction";
import { getAllDistrict } from "../../../../Admin/Features/Action/AddressAction";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../Features/Action/AdminBlockUnblockAction";

let validationSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  // email: yup.string().email("Email is required"),
  phone_no: yup.number().required("Phone number is required").min(12),
});

const EditAdministratorForm = () => {
  // get single user
  const { id } = useParams();
  const [file, setFile] = useState();
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update User",
    "Loading..."
  );
  const [image, setImage] = useState();
  const { countries, proviences, districts } = useSelector(
    (s) => s.AddressReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();

  // update user
  const UpdateUserHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      if (file) {
        data.append("image", file);
      }
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("email", values.email);
      data.append("address", values.address);
      data.append("phone_no", values.phone_no);
      if (values.countryId) {
        data.append("countryId", values.countryId);
      }
      if (values.provinceId) {
        data.append("provinceId", values.provinceId);
      }
      if (values.districtId) {
        data.append("districtId", values.districtId);
      }
      data.append("street_address", values.street_address);
      data.append("landmark", values.landmark);
      data.append("pin_code", values.pin_code);
      data.append("role", values.role);
      data.append("id", user.userId);
      const res = await AxiosConfig.post("/kreesa/admin/update-user", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res) {
        toast.success("Update user succesfully !");
        setButtonLoader(false);
        history.push("/admin/details");
      }
    } catch (err) {
      console.log(err + "error found");
      setButtonLoader(false);
    }
  };
  const { user, loader } = useSelector((s) => s.AdminReducer);

  useEffect(() => {
    dispatch(getUserDetail(id));
  }, [id]);

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllProvince());
    dispatch(getAllDistrict());
  }, []);

  return !loader ? (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title font-weight-bold">Edit Admin Form</h5>
          <h6 className="card-subtitle text-muted">
            {" "}
            You Can Easily Edit User From Here...
          </h6>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              first_name: user?.firstname,
              last_name: user?.lastname,
              email: user?.emailId,
              address: user?.address,
              phone_no: user?.contact,
              role: user?.role,
              countryId: user?.countryId,
              provinceId: user?.provinceId,
              districtId: user?.districtId,
              street_address: user?.streetAddress,
              landmark: user?.landmark,
              pin_code: user?.pinCode,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => UpdateUserHandler(values)}
          >
            {({ handleChange, handleSubmit, values, errors, touched }) => (
              <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputEmail4">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      onChange={handleChange}
                      value={values.first_name || ""}
                      id="inputEmail5"
                      placeholder="Enter First Name"
                    />
                    {errors.first_name && touched.first_name ? (
                      <div style={{ color: "red" }}>{errors.first_name}</div>
                    ) : null}
                  </div>

                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputPassword4">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      onChange={handleChange}
                      value={values.last_name || ""}
                      id="inputPassword5"
                      placeholder="Enter First Name"
                    />
                    {errors.last_name && touched.last_name ? (
                      <div style={{ color: "red" }}>{errors.last_name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  {/* <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputEmail4">
                      Email{" "}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      value={values.email || ""}
                      id="inputEmail4"
                      placeholder="Enter Email"
                    />
                    {errors.email && touched.email ? (
                      <div style={{ color: "red" }}>{errors.email}</div>
                    ) : null}
                  </div> */}
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputEmail4">
                        Country
                      </label>
                      <select
                        name="countryId"
                        onChange={handleChange}
                        // onBlur={(e) => SetSelectProvince(e.target.value)}
                        value={values.countryId}
                        className="form-control"
                      >
                        <option value="">Select Your Country...</option>
                        {countries?.map((c, i) => (
                          <option
                            key={i}
                            value={c.id}
                            selected={values.countryId === c.id}
                          >
                            {c.country_name}
                          </option>
                        ))}
                      </select>
                      {errors.countryId && touched.countryId ? (
                        <div style={{ color: "red" }}>{errors.countryId}</div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputEmail4">
                        Province
                      </label>
                      <select
                        name="provinceId"
                        onChange={handleChange}
                        // onBlur={(e) => SetSelectDistrict(e.target.value)}
                        value={values.provinceId}
                        className="form-control"
                      >
                        <option value="">Select Your Provience...</option>
                        {proviences?.map(
                          (pr, i) => (
                            // selectProvince == pr.country.id && (
                            <option
                              key={i}
                              value={pr.id}
                              selected={values?.provinceId === pr.id}
                            >
                              {pr?.provience_name}
                            </option>
                          )
                          // )
                        )}
                      </select>
                      {errors.provinceId && touched.provinceId ? (
                        <div style={{ color: "red" }}>{errors.provinceId}</div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputEmail4">
                        District
                      </label>
                      <select
                        name="districtId"
                        onChange={handleChange}
                        value={values.districtId}
                        className="form-control"
                      >
                        <option value="">Select Your District...</option>
                        {districts?.map(
                          (d, i) => (
                            // selectDistrict == d?.stateOrProvience?.id && (
                            <option
                              key={i}
                              value={d.id}
                              selected={values?.districtId === d.id}
                            >
                              {d.district_name}
                            </option>
                          )
                          // )
                        )}
                      </select>
                      {errors.districtId && touched.districtId ? (
                        <div style={{ color: "red" }}>{errors.districtId}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputPassword4">
                        Street Address
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={values.street_address}
                        name="street_address"
                        onChange={handleChange}
                        id="street_address"
                        placeholder="Enter Street Address"
                      />
                      {errors.street_address && touched.street_address ? (
                        <div style={{ color: "red" }}>
                          {errors.street_address}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputPassword4">
                        Landmark
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={values.landmark}
                        name="landmark"
                        onChange={handleChange}
                        id="landmark"
                        placeholder="Enter Landmark"
                      />
                      {errors.landmark && touched.landmark ? (
                        <div style={{ color: "red" }}>{errors.landmark}</div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputPassword4">
                        Pin Code
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={values.pin_code}
                        name="pin_code"
                        onChange={handleChange}
                        id="pin_code"
                        placeholder="Enter Street Address"
                      />
                      {errors.pin_code && touched.pin_code ? (
                        <div style={{ color: "red" }}>{errors.pin_code}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputZip">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone_no"
                      onChange={handleChange}
                      value={values.phone_no || ""}
                      id="inputZip"
                      placeholder="Enter Phone Number"
                    />
                    {errors.phone_no && touched.phone_no ? (
                      <div style={{ color: "red" }}>{errors.phone_no}</div>
                    ) : null}
                  </div>

                  <div className="mb-3 col-md-6">
                    <label className="form-label" htmlFor="inputState">
                      Role
                    </label>
                    <select
                      id="inputState"
                      name="role"
                      value={values.role || ""}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">Choose </option>
                      <option value="ROLE_CEO">CEO</option>
                      <option value="ROLE_MANAGER">Manager</option>
                      <option value="ROLE_MARKETING_MANAGER">
                        Marketing Manager
                      </option>
                      <option value="ROLE_RECEPTIONIST">Receptionist</option>
                      <option value="ROLE_DELIVERY_BOY">Delivery Boy</option>
                      <option value="ROLE_HR">HR</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label" htmlFor="Image">
                      Image
                    </label>
                    <input
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      name="image"
                      className="form-control"
                      id="formFileSm"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setImage(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    <small>Accepted Formats: png, jpg & jpeg</small>
                    <div className="d-flex">
                      {image && (
                        <div
                          className="mt-2"
                          style={{ flexDirection: "column" }}
                        >
                          <img
                            src={image}
                            alt="dummy"
                            height={80}
                            width={100}
                          />
                          <p className="text-center">New Image</p>
                        </div>
                      )}
                      <div className="ml-4" style={{ flexDirection: "column" }}>
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/admin/${id}/${user.profile}`}
                          className="m-2"
                          height={80}
                          width={100}
                          alt=""
                        />
                        <p className="text-center">Old Image</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-success btn-lg"
                  ref={buttonLoader}
                  onClick={handleSubmit}
                  //   onClick={UpdateUserHandler}
                >
                  Update User
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default EditAdministratorForm;
