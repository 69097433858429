import React from 'react'
import { Redirect } from 'react-router-dom';
import Footer from '../Footer';
import LeftNavbar from '../LeftNavbar';
import TopNavbar from '../TopNavbar';
import StockDetails from './StockDetails';

const Stocks = () => {
    document.title = "Wholesaler | My Stock";

    // to check authentication user or not
    let token = localStorage.getItem("auth");
    if (token !== "ROLE_WHOLESALER") {
      return <Redirect exact to="/login" />;
    }
    return (
      <>
        <div className="wrapper">
          <LeftNavbar />
          <div className="main">
            <TopNavbar />
            <main className="content">
              <StockDetails />
            </main>
            <Footer />
          </div>
        </div>
      </>
    );
}

export default Stocks
