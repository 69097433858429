import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Spinner from "../../../Spinner";
import { toast } from "react-toastify";
import {
  AdminConfirmDelivery,
  changePaymentStatus,
  getAllDeliveryBoy,
  getAllRetailOrder,
} from "../../../Features/Action/OrderStatusAction";
import { useDispatch, useSelector } from "react-redux";
import { filterRetailDelivery } from "../../../Features/Reducer/OrderStatusReducer";
import Modal from "../../Modal";
import { Pagination } from "@mantine/core";
import Swal from "sweetalert2";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { generateQrCode } from "../../../Features/Action/QuotationAction";

const OrderDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [orderId, setOrderId] = useState();

  const dispatch = useDispatch();
  const inputRef = useRef("");

  const { retailOrders, loader, deliveryBoys } = useSelector(
    (s) => s.AdminDeliveryReducer
  );

  // handleConfirmDelivery
  const handleConfirmDelivery = (data) => {
    dispatch(AdminConfirmDelivery(data));
  };
  useEffect(() => {
    // getOrders();
    dispatch(getAllRetailOrder());
    dispatch(getAllDeliveryBoy());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = retailOrders?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(retailOrders?.length / perPage);

  const filter = () => {
    dispatch(filterRetailDelivery(inputRef.current.value));
  };

  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Orders…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Orders Of Retailers</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th className="d-xl-table-cell">Order Number</th>
                    <th>Contact </th>
                    <th className="d-xl-table-cell">Order Date</th>
                    <th>Delivery Status</th>
                    <th>Seller Status</th>
                    <th>Admin</th>
                    <th className="d-md-table-cell">Shipping Address</th>
                    <th className="d-md-table-cell">Payment Method</th>
                    <th className="d-md-table-cell">Total Payment</th>
                    <th className="d-md-table-cell">Payment Status</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td className="d-xl-table-cell">{p.orderNo}</td>
                      <td>{p.contactNo}</td>
                      <td className="d-xl-table-cell">
                        {moment(p.orderDate).format("ll")}
                      </td>

                      <td>
                        <span
                          className={`badge ${
                            p.deliveryStatus === null
                              ? "bg-danger"
                              : p.deliveryStatus === "DELIVERED"
                              ? "bg-success"
                              : "bg-info"
                          }`}
                        >
                          {p.deliveryStatus === null
                            ? "NOT READY"
                            : p.deliveryStatus}
                        </span>
                      </td>

                      <td>
                        {p.orderDetails[0].sellerApproval === "Pending" ? (
                          <span className={`badge bg-info`}>
                            {p.orderDetails[0].sellerApproval}
                          </span>
                        ) : p.orderDetails[0].sellerApproval === "Rejected" ? (
                          <span className={`badge bg-danger`}>
                            {p.orderDetails[0].sellerApproval}
                          </span>
                        ) : (
                          <span className={`badge bg-success`}>
                            {p.orderDetails[0].sellerApproval}
                          </span>
                        )}
                      </td>
                      <td>
                        {p.orderDetails[0].orderApproval === "Pending" ? (
                          <span className={`badge bg-info`}>
                            {p.orderDetails[0].orderApproval}
                          </span>
                        ) : p.orderDetails[0].orderApproval === "Rejected" ? (
                          <span className={`badge bg-danger`}>
                            {p.orderDetails[0].orderApproval}
                          </span>
                        ) : (
                          <span className={`badge bg-success`}>
                            {p.orderDetails[0].orderApproval}
                          </span>
                        )}{" "}
                      </td>
                      <td className="d-md-table-cell">
                        {p?.shippingAddress}, {p?.shippingState},{" "}
                        {p?.shippingCountry}
                      </td>
                      <td className="d-md-table-cell">
                        {p?.payment?.paymentMethod}
                      </td>
                      <td className="d-md-table-cell">
                        Rs. {Math.round(p?.totalCost)}
                      </td>
                      <td className="d-md-table-cell">
                        {p?.payment?.paymentStatus === "UNPAID" ? (
                          <p className="badge bg-danger">UNPAID</p>
                        ) : (
                          <p className="badge bg-success">PAID</p>
                        )}
                      </td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              to={`/admin/order/view/wholesale/${p.id}`}
                              className="dropdown-item text-info"
                            >
                              <i className="fas fa-eye"></i> View More
                            </Link>
                            <>
                              {p.orderDetails.map((i) =>
                                p.payment.paymentStatus === "UNPAID" &&
                                p.deliveryStatus === "DELIVERED" ? (
                                  <>
                                    <div className="dropdown-divider"></div>
                                    <div
                                      className="dropdown-item cursor-pointer text-success"
                                      onClick={() =>
                                        p.orderDetails.map((i) =>
                                          dispatch(
                                            changePaymentStatus({
                                              orderId: i.orderId,
                                              type: "retail",
                                            })
                                          )
                                        )
                                      }
                                    >
                                      <i className="fa fa-check"></i> Change
                                      Payment Status
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {/* {i.sellerApproval === "Approved" && (
                                        <>
                                          <div className="dropdown-divider"></div>
                                          <div
                                            className="dropdown-item cursor-pointer text-success"
                                            onClick={() =>
                                              handleConfirmDelivery({
                                                id: p.id,
                                                status: "DELIVERED",
                                              })
                                            }
                                          >
                                            <i className="fa fa-check"></i>{" "}
                                            Confirm Delivery
                                          </div>
                                        </>
                                      )} */}
                                  </>
                                )
                              )}
                            </>
                            <>
                              {p.deliveryStatus === null && (
                                <>
                                  <div className="dropdown-divider"></div>
                                  <div
                                    className="dropdown-item cursor-pointer text-info"
                                    onClick={() => {
                                      setModal(true);
                                      setOrderId(p.id);
                                    }}
                                  >
                                    <i className="fas fa-user"></i> Assign
                                    Delivery
                                  </div>
                                </>
                              )}
                            </>

                            <>
                              <div className="dropdown-divider"></div>
                              <button
                                onClick={() => {
                                  dispatch(
                                    generateQrCode({
                                      type: "order",
                                      id: p.id,
                                    })
                                  );
                                }}
                                className="dropdown-item text-info"
                              >
                                <i className="fas fa-qrcode"></i> View QR Code
                              </button>
                            </>

                            <>
                              {p.deliveryStatus !== null && (
                                <>
                                  <div className="dropdown-divider"></div>
                                  <div
                                    className="dropdown-item cursor-pointer text-primary"
                                    onClick={() => {
                                      AxiosConfig.get(
                                        `/kreesa/admin/get-user-by-user-id/${p.deliveryBoyId}`
                                      )
                                        .then(({ data }) => {
                                          Swal.fire({
                                            titleText: "Delivery Boy Details",
                                            confirmButtonColor: "#0d6efd",
                                            html: `<div style="text-align:start"><h5>Name :  ${data.firstname} ${data.lastname}</h5></br><h5>Address : ${data.districtName}, ${data.provienceName}, ${data.countryName}</h5></br><h5>Email :  ${data.emailId}</h5></br><h5>Contact :  ${data.contact}</h5></div>`,
                                          });
                                        })
                                        .catch((err) =>
                                          toast.error("An Error Occurred ")
                                        );
                                    }}
                                  >
                                    <i className="fas fa-user"></i> Delivery
                                    Details
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Modal
            modal={modal}
            setModal={setModal}
            data={deliveryBoys}
            orderId={orderId}
          />
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
        </div>
      ) : (
        <div className="p-2 text-center">
          <h3>No Data Found</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default OrderDetails;
