import { useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";
import Pagination from "../../Pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllComplimentAdmin } from "../Features/Action/AdminBlockUnblockAction";
import { filterCompliments } from "../Features/Reducer/AdminReducer";

const Complaint = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);

  const inputRef = useRef();

  const dispatch = useDispatch();
  const { compliments, loader } = useSelector((s) => s.AdminReducer);

  useEffect(() => {
    dispatch(getAllComplimentAdmin());
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = compliments?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(compliments?.length / perPage);

  const filter = () => {
    dispatch(filterCompliments(inputRef.current.value));
  };

  return !loader ? (
    <div className="container">
      <div className="row">
        <h3>Kreesa Complaint</h3>
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="d-sm-inline-block" style={{ float: "right" }}>
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Complaint…"
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filter}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Complaint</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th className="d-xl-table-cell">Contact</th>
                    <th className="d-md-table-cell">Email</th>
                    <th className="d-xl-table-cell">Country</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>{p.complement_title}</td>
                      <td>{p.complement_type}</td>
                      <td className="d-xl-table-cell">{p.contact_number}</td>
                      <td className="d-xl-table-cell">
                        <a href={`mailto:${p.email}`}>{p.email}</a>
                      </td>
                      <td className="d-xl-table-cell">{p.country}</td>
                      <td className="d-xl-table-cell">{p.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={compliments}
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Complain Found !</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default Complaint;
