import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  approveSalerQuotation,
  getUserQuotation,
  rejectSalerQuotation,
} from "../Features/Action/WholesalerAction";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import moment from "moment";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";
import {
  approveUserQuotation,
  getUserInvoice,
} from "../../User/Features/action";
import FileSaver from "file-saver";

const MyQuotation = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();

  const { myQuotation, loader } = useSelector(
    (state) => state.WholesalerReducer
  );

  useEffect(() => {
    dispatch(getUserQuotation(localStorage.getItem("userId")));
  }, []);

  const saveFile = (orderId, invoice) => {
    FileSaver.saveAs(
      `${AxiosConfig.defaults.baseURL}/kreesa/image/quote-invoice/${orderId}`,
      invoice
    );
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = myQuotation?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(myQuotation?.length / perPage);
  return !loader ? (
    <div>
      <div className="">
        {currentEntries?.length > 0 ? (
          <div className="">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h3 className="card-title mb-0 font-weight-bold">
                  My Quotations
                </h3>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Expected Price</th>
                    <th>Actual Price</th>
                    <th>Quantity</th>
                    <th>Image</th>
                    <th>Admin</th>
                    <th>Seller</th>
                    <th>Payment Status</th>
                    <th>Payment Method</th>
                    <th>Invoice</th>
                    <th>Delivery</th>
                    <th>User</th>
                    <th>Quote Status</th>

                    {/* <th className="d-xl-table-cell">Quantity</th> */}

                    {/* <th className="d-md-table-cell">Address</th> */}
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{p.product_name}</td>
                      <td>{moment(p.created_date).format("DD MMM YY")}</td>
                      {/* <td>{moment(p.orderDate).format("ll")}</td> */}
                      <td>{p.expected_price}</td>
                      <td className="font-weight-bold">
                        {p.actual_price === 0 ? "----" : p.actual_price}
                      </td>

                      <td>{p.quantity}</td>
                      <td>
                        {" "}
                        <img
                          src={
                            p.image
                              ? `${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${p.id}/${p.image}`
                              : "/images/kreesalogo.png"
                          }
                          alt={p.image}
                          height={50}
                          width={70}
                        />
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            p.admin_final_approval === "REJECTED"
                              ? "bg-danger"
                              : p.admin_final_approval === "APPROVED"
                              ? "bg-success"
                              : "bg-info"
                          }`}
                        >
                          {p.admin_final_approval}
                        </span>
                      </td>
                      <td>
                        {p.buyer_approval === true ? (
                          <span className="badge bg-success">Approved</span>
                        ) : (
                          <span className="badge bg-info">Pending</span>
                        )}
                      </td>
                      <td>{p?.paymentStatus}</td>
                      <td>{p?.paymentMethod}</td>
                      <td>
                        {p.user_approval === true ? (
                          <div
                            className="cv"
                            style={{
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={() => saveFile(p.id, p.invoice)}
                          >
                            {p?.invoice} <i className="fa fa-download"></i>
                          </div>
                        ) : (
                          <p>Invoice not found</p>
                        )}
                      </td>
                      <td>
                        {p.delivery_status === null ? (
                          <span className="badge bg-danger">NOT READY</span>
                        ) : p.delivery_status === "ASSIGNED" ? (
                          <span className="badge bg-success">
                            {p.delivery_status}
                          </span>
                        ) : (
                          <span className="badge bg-info">
                            {p.delivery_status}
                          </span>
                        )}
                      </td>
                      <td>
                        {p.user_approval == true ? (
                          <span className="badge bg-success">Approved</span>
                        ) : (
                          <span className="badge bg-info">Pending</span>
                        )}
                      </td>
                      <td>
                        {p.quote_status === "REJECTED" ? (
                          <span className="badge bg-danger">
                            {p.quote_status}
                          </span>
                        ) : p.quote_status === "ASSIGNED" ? (
                          <span className="badge bg-success">
                            {p.quote_status}
                          </span>
                        ) : (
                          <span className="badge bg-info">
                            {p.quote_status}
                          </span>
                        )}
                      </td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              // to={`/retailer/quotation/${p.id}`}
                              to={`/${
                                localStorage.getItem("auth") ===
                                "ROLE_WHOLESALER"
                                  ? "wholesaler"
                                  : "retailer"
                              }/quotation/${p.id}`}
                              className=" text-success"
                            >
                              <i className="fas fa-eye "></i> Review
                            </Link>
                            {/* <button
                              onClick={() => {
                                setModal(true);
                                setModalData(p);
                                //   handleApproveQuotation(p.id);
                              }}
                              className="dropdown-item text-info"
                            >
                              <i className="fas fa-eye"></i> View
                            </button> */}
                            {p.final_approval === "PENDING" && (
                              <>
                                {" "}
                                {/* {p.user_approval === false && (
                                  <>
                                    {p.admin_final_approval === "APPROVED" &&
                                      p.buyer_approval === true && (
                                        <>
                                          <div className="dropdown-divider"></div>

                                          <button
                                            onClick={() => {
                                              history.push({
                                                pathname: "/checkout-user",
                                                state: [
                                                  {
                                                    quoteId: p.id,
                                                    orderQuantity: p.quantity,
                                                    name: p.product_name,
                                                    price: p.actual_price,
                                                    discount: 0,
                                                    discountPrice:
                                                      p.actual_price -
                                                      (p.actual_price * 0) / 100,
                                                    discountedTotalPrice:
                                                      (p.actual_price -
                                                        (p.actual_price * 0) /
                                                          100) *
                                                      p.quantity,
                                                    type: "quote",
                                                  },
                                                ],
                                              });
                                            }}
                                            className="dropdown-item text-info"
                                          >
                                            <i className="fas fa-check"></i>{" "}
                                            Approve
                                          </button>
                                        </>
                                      )}
                                  </>
                                )} */}
                                {p.user_approval === false &&
                                  p.admin_approval === false && (
                                    <>
                                      <div className="dropdown-divider"></div>

                                      <button
                                        onClick={() => {
                                          dispatch(
                                            rejectSalerQuotation({
                                              id: p.id,
                                              userId: p.user_id,
                                            })
                                          );
                                        }}
                                        className="dropdown-item text-danger"
                                      >
                                        <i className="fas fa-times"></i> Reject
                                      </button>
                                    </>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <h4 className="text-center">No Quotation Found</h4>
        )}
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={myQuotation}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default MyQuotation;
