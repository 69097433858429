import React, { useEffect, useState } from "react";
import useButtonLoader from "../../ButtonLoaderHook";
import "./Message.css";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import moment from "moment";
import { Form, Formik } from "formik";
import Spinner from "../../Admin/Spinner";

const Message = () => {
  const [buttonLoader, setButtonLoader] = useButtonLoader("Send", "Loading...");
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  /* ----------------------------------- ... ---------------------------------- */
  const messageHandle = async (values, submitProps) => {
    try {
      setButtonLoader(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await AxiosConfig.post(
        "/kreesa/retailer/send-message",
        {
          ...values,
          messageBy:
            localStorage.getItem("auth") === "ROLE_RETAILER"
              ? "Retailer"
              : "Wholesaler",
          userId: localStorage.getItem("userId"),
        },
        config
      );
      if (res && res.status === 200) {
        setButtonLoader(false);
        submitProps.resetForm();
        getSingleMessages();
      }
    } catch (error) {
      console.log(error);
      setButtonLoader(false);
    }
  };
  /* -------------------------- ...getSingleMessages -------------------------- */
  const getSingleMessages = async () => {
    setLoading(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const GetSingleRetailor = await AxiosConfig.get(
        `/kreesa/retailer/get-all-message/${localStorage.getItem("userId")}`,
        config
      );
      setState(GetSingleRetailor.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleMessages(localStorage.getItem("userId"));
  }, [localStorage.getItem("userId")]);

  return !loading ? (
    <div>
      <h3>Messages</h3>
      <div className="back-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-md-12 message-area">
              <div className="message-table-scroll">
                <table className="table">
                  <tbody>
                    {/* <tr>
                    <td>
                      <p className="bg-primary p-2 mt-2 mr-5 shadow-sm text-white float-left rounded">
                        Hello Rahul
                        <br />
                        <small className="pt-2" style={{ float: "right" }}>
                          11:44 PM
                        </small>
                      </p>
                    </td>
                  </tr> */}
                    {state.map((p, i) => (
                      <tr key={i}>
                        <td>
                          <p className="bg-success p-2 mt-2 mr-5 shadow-sm text-white float-right rounded">
                            {p.message}
                            <br />
                            <small className="pt-2" style={{ float: "right" }}>
                              {moment(p.created_date).format("LT")}
                            </small>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Formik
                initialValues={{
                  message: "",
                }}
                onSubmit={messageHandle}
              >
                {({ handleChange, handleSubmit, values, dirty, isValid }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row message-box p-3">
                      <div className="col-sm-1 mt-2"></div>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          required
                          placeholder="Write message..."
                        />
                      </div>
                      <div className="col-sm-2">
                        <button
                          ref={buttonLoader}
                          onClick={handleSubmit}
                          disabled={!(dirty && isValid)}
                          type="submit"
                          className="btn btn-success btn-block"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Message;
