import React, { useEffect } from "react";
import "../NavBar/Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../Common/Features/Action/CategoryAction/CategoryAction";
import $ from "jquery";
import { homePageSearch, searchItemRetail } from "../Features/action";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../../Common/WindowDimensions/WindowDimensions";
import QuoteModal from "../Quotation/QuoteModal";
import { Drawer } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import NestedListGroup from "./Drawer/Drawer";

function Navbar(props) {
  const { width } = useWindowDimensions;

  const history = useHistory();
  // to get all Categories(Primary, Secondary, Ternary)
  const { Categories, loader } = useSelector((s) => s.Categories);
  const { searchItem } = useSelector((state) => state.userProducts);
  const [style, setStyle] = useState({ width: "17rem" });
  const [modal, setModal] = useState(false);
  const [navText, setNavText] = useState("Quotation Order");
  // drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    var index = 0;
    const interval = setInterval(() => {
      switch (index) {
        case 0:
          setNavText("Offer Your Price");
          break;
        case 1:
          setNavText("Bargain Online");
          break;
        default:
          setNavText("Quotation Order");
          break;
      }
      index++;
      if (index > 2) {
        index = 0;
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const dropdownSearch = (id, type, name) => {
    let data = {
      catType: type,
      categoryId: id,
      userType: "retail",
    };
    AxiosConfig.post(`/kreesa/search-product`, data, {
      // headers: {
      //   Authorization: "Bearer " + localStorage.getItem("token"),
      // },
    }).then((res) => {
      history.push({
        pathname: "/user/search-page",
        search: `?category=${name}`,
        state: res.data,
      });
    });
  };

  const [dataSearch, setDataSearch] = useState();

  // const onChange = (e) => {
  //   console.log("change: ", e);
  // };

  // useEffect(() => {
  //   setDataSearch(searchItem);
  // }, [searchItem]);

  // useEffect(() => {
  //   $(".menu-iconsed").on("click", function () {
  //     $(".head-nav").toggleClass("active");
  //   });

  //   $(function () {
  //     // whenever we hover over a menu item that has a submenu
  //     $("li.parent").on("mouseover", function () {
  //       var $menuItem = $(this),
  //         $submenuWrapper = $("> .wrappered", $menuItem);

  //       // grab the menu item's position relative to its positioned parent
  //       var menuItemPos = $menuItem.position();

  //       // place the submenu in the correct position relevant to the menu item
  //       $submenuWrapper.css({
  //         top: "0px",
  //         left: menuItemPos.left + Math.round($menuItem.outerWidth() * 1.75),
  //       });
  //     });
  //   });
  // }, []);

  /* -------------------------------------------------------------------------- */
  /*                                handle scroll                               */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      {/* <nav
        className="navbar navbar-expand-lg navbar-light "
        style={{
          backgroundColor: "rgb(35 64 122)",
          padding: "0",
          margin: "0",
          position: "sticky",
          top: "0px",
          zIndex: "50",
        }}
      >
        <NavLink
          className="navbar-brand ml-6"
          exact
          to="/"
          activeClassName="active-navigation"
        >
          <div className="text-white text-uppercase">ALL CATEGORIES</div>
        </NavLink>
        <button
          className="navbar-toggler "
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ color: "white" }}
        >
          <AiOutlineMenu />
         
        </button>
        <div className="collapse navbar-collapse ml-6" id="navbarNav">
          <ul className="navbar-nav ml-6 pl-6">
            <li className="nav-item text-uppercase">
              <NavLink
                exact
                to="/"
                className="nav-link text-uppercase"
                activeClassName="active-navigation"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item text-uppercase">
              <NavLink
                className="nav-link text-uppercase"
                activeClassName="active-navigation"
                to="/about"
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item text-uppercase">
              <NavLink
                className="nav-link text-uppercase"
                exact
                to="/contact"
                activeClassName="active-navigation"
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav> */}
      {/* /* -------------------------------------------------------------------------- */
      /*                                   old one                                  */
      /* --------------------------------------------------------------------------  */}
      {/* <nav className="head-nav" id="head-nav">
        <div className="menu-iconsed" id="menu-iconsed">
          <i className="fas fa-bars ion-md-menu"></i>
          <i className="far fa-times-circle ion-md-close"></i>
        </div>

        <ul className="nav-listed">
           

           <li>
            <a style={{ marginLeft: "4.5rem" }}>
              All Categories
              <i
                className="fas fa-chevron-circle-down ml-2 mt-1"
                style={{ fontSize: "13px" }}
              ></i>
            </a>
            <div className="wrappered">
              <ul
                className="sub-menued"
                id="sm"
                // onMouseEnter={(e) => {
                //   setStyle({ width: "31rem" });
                // }}
                // onMouseLeave={(e) => {
                //   setStyle({ width: "17rem" });
                // }}
                // onMouseOver={(e) => {
                //   setsStyle({ width: "51rem" });
                // }}
              >
                {Categories.primary?.map((p, i) => (
                  <li key={i} className="parent">
                    <a
                      onClick={() => {
                        dropdownSearch(p.id, "primary", p.categoryName);
                      }}
                    >
                      {p.categoryName}
                      {Categories.secondary.filter((secId) => {
                        return secId.primaryCategoryId === p.id;
                      }).length > 0 && (
                        <i
                          className="fas fa-arrow-circle-right  mt-1 ml-1"
                          style={{ fontSize: "12px" }}
                        ></i>
                      )}
                    </a>
                    {Categories.secondary.filter((secId) => {
                      return secId.primaryCategoryId === p.id;
                    }).length > 0 && (
                      <div className="wrappered">
                        <ul className="sub-menued ss-menu">
                          {Categories.secondary?.map(
                            (sec, is) =>
                              p.id === sec.primaryCategoryId && (
                                <li key={is} className="parent">
                                  <a
                                    className="mr-6"
                                    onClick={() => {
                                      dropdownSearch(
                                        sec.id,
                                        "secondary",
                                        sec.secondaryCategoryName
                                      );
                                    }}
                                  >
                                    {sec.secondaryCategoryName}
                                    {Categories.ternary.filter((terId) => {
                                      return (
                                        terId.secondaryCategoryId === sec.id
                                      );
                                    }).length > 0 && (
                                      <i
                                        className="fas fa-arrow-circle-right  mt-1 ml-1"
                                        style={{ fontSize: "12px" }}
                                      ></i>
                                    )}
                                  </a>
                                  {Categories.ternary.filter((terId) => {
                                    return terId.secondaryCategoryId === sec.id;
                                  }).length > 0 && (
                                    <div className="wrappered">
                                      <ul className="sub-menued sss-menu">
                                        {Categories.ternary?.map(
                                          (ten, it) =>
                                            sec.id ===
                                              ten.secondaryCategoryId && (
                                              <li
                                                key={it}
                                                className="ten-parent"
                                              >
                                                <a
                                                  style={{
                                                    marginLeft: "1.5rem",
                                                  }}
                                                  onClick={() => {
                                                    dropdownSearch(
                                                      ten.id,
                                                      "ternary"
                                                    );
                                                  }}
                                                >
                                                  {ten.ternaryCategoryName}
                                                </a>
                                              </li>
                                            )
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </li>  
          
          
          {history?.location?.state?.prevPath === "/custom-order" ||
          history.location.pathname === "/custom-order" ? (
            ""
          ) : (
            <>
              <li className="ml-6">
                <NavLink
                  exact
                  to="/"
                  className="ml-1"
                  activeClassName="active-navigation"
                >
                  Home
                </NavLink>
              </li>
              {localStorage.getItem("auth") === "ROLE_USER" && (
                <li className="ml-6">
                  <NavLink
                    to={"?quote=true"}
                    className="active-navigation"
                    onClick={() => setModal(true)}
                  >
                    Quotation
                  </NavLink>
                </li>
              )}
              <li className="ml-6">
                <NavLink className="active-navigation" to="/jobs">
                  Career@Kreesa
                </NavLink>
              </li>

              <li className="ml-6">
                <NavLink exact to="/about" activeClassName="active-navigation">
                  About Us
                </NavLink>
              </li>

              <li className="ml-6">
                <NavLink
                  exact
                  to="/contact"
                  activeClassName="active-navigation"
                >
                  Contact Us
                </NavLink>
              </li>
              <li className="ml-6">
                <NavLink exact to="/news" activeClassName="active-navigation">
                  News
                </NavLink>
              </li>
            </>
          )}
          <li className="move-right btn-nav">
                        <a href="#">Book a Car</a>
                    </li> 
          <QuoteModal modal={modal} setModal={setModal} />
        </ul>
      </nav>*/}
      {/* /* -------------------------------------------------------------------------- */
      /*                                   old one                                  */
      /* --------------------------------------------------------------------------  */}
      {/*    
    <section className="hero">
        
    </section> */}
      {/* /* -------------------------------------------------------------------------- */
      /*                                   new                                  */
      /* --------------------------------------------------------------------------  */}
      <nav
        className="navbar navbar-expand-lg px-lg-6"
        style={{
          backgroundImage: "linear-gradient(-8deg, #0b8793, #360033)",
          position: "sticky",
          zIndex: "999",
          opacity: "0.9",
          width: "100%",
          top: "auto",
        }}
      >
        <div
          className="navbar-brand nav__hover_color cursor-pointer"
          onClick={() => setOpenDrawer(true)}
        >
          All Categories
        </div>
        {/* drawer */}
        <Drawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          size="xs"
          placement="left"
        >
          <Drawer.Header>
            <Drawer.Title className="font-weight-bold h3">
              All Categories
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body className="m-0 p-0">
            <NestedListGroup onClose={() => setOpenDrawer(false)} />
          </Drawer.Body>
        </Drawer>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i
              className="fas fa-bars"
              style={{
                color: "white",
                fontSize: "1.5rem",
              }}
            ></i>
          </span>
        </button>
        {history?.location?.state?.prevPath === "/custom-order" ||
        history.location.pathname === "/custom-order" ? (
          ""
        ) : (
          <>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item pr-4 active">
                  <NavLink
                    exact
                    to="/"
                    className="navbar-brand nav__hover_color"
                    activeClassName="active__navigation_"
                  >
                    Home <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
                {/* Navbar Items */}
                <NavbarItems to="/quotation" text={navText} />
                <NavbarItems to="/jobs" text="Career@Kreesa" />
                <NavbarItems to="/about" text="About Us" />
                <NavbarItems to="/contact" text="Contact Us" />
                <NavbarItems to="/news" text="News" />
                {/* modal */}
                <QuoteModal modal={modal} setModal={setModal} />
              </ul>
            </div>
          </>
        )}
      </nav>
    </>
  );
}

export default Navbar;

function NavbarItems({ to, text }) {
  return (
    <li className="nav-item pr-4">
      <NavLink
        exact
        to={to}
        activeClassName="active__navigation_"
        className="navbar-brand nav__hover_color"
      >
        {text}
      </NavLink>
    </li>
  );
}
