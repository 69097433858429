import SearchCard from "./searchCard";
import { useSelector } from "react-redux";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Header from "../Header/Header";
import Search from "../Search/Search";
import Navbar from "../NavBar/Navbar";
import UserFooter from "../UserFooter";
import Spinner from "../../Admin/Spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const SearchPageUser = () => {
  document.title = "Kreesa | Search";
  // const { loader, searchItem } = useSelector((state) => state.userProducts);
  // console.log(searchItem, "search");
  const location = useLocation();
  const history = useHistory();

  const [searchItem, setSearchItem] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    setSearchItem(location.state);
    setLoader(false);
  }, [location]);

  const redirectHandler = () => {
    if (
      localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ||
      localStorage.getItem("auth") === "ROLE_USER"
    ) {
      return history.push("/quotation");
    } else {
      history.push("/login");
      toast.error("kindly login or register before placing quote order");
    }
  };

  return !loader ? (
    <div>
      <Search />

      <Navbar />
      <div className="mt-4 " style={{ minHeight: "100vh" }}>
        <div className="row">
          {/* <div className="col-xs-12 offset-1 col-sm-3 col-md-3 col-lg-2 align-center">
            <div className="search-sidebar-item">
              <div className="font-weight-bold mb-2">Related Cateogries</div>
              <div className="search-sidebar-item">Related Cateogries</div>
            </div>
            <hr style={{ backgroundColor: "grey" }} />

            <div className="search-sidebar-item">
              <div className="font-weight-bold mb-2 mt-2">Brand</div>
              <div className="search-sidebar-item mb-2">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                  <div>
                    <input
                      type="checkbox"
                      id="reg-signup"
                      name="hasRegistered"
                      // value={values.hasRegistered}
                      // onChange={handleChange}
                      // onClick={Handlechange}
                      // onClick={() => setVisible(!visible)}
                    />
                    <label htmlFor="reg-signup">{i}</label>
                  </div>
                ))}
              </div>
            </div>
            <hr style={{ backgroundColor: "grey" }} />
          </div> */}

          <div className="col-xs-12" style={{ minHeight: "100vh" }}>
            <div className="row">
              <div
                className="d-flex align-items-center row "
                style={{ padding: "15px 0" }}
              >
                {searchItem?.length > 0 ? (
                  <div className="d-flex justify-content-between ">
                    <div
                      className="col-sm-6"
                      style={{
                        marginLeft: "100px",
                        fontSize: 25,
                        fontWeight: "bold",
                      }}
                    >
                      {searchItem?.length} items found
                    </div>
                  </div>
                ) : (
                  <h3
                    className="col-sm-12 text-center"
                    // style={{ marginLeft: "100px", color: "blue", fontSize: 22 }}
                    style={{ fontSize: 25, fontWeight: "bold" }}
                  >
                    {" "}
                    😔
                    <span className="news__blog_notice__color_">
                      Sorry, no products found !
                    </span>
                    <br />
                    <q className="news__blog_notice__color_">
                      Products are not available in this <br /> category please
                      try any other category
                    </q>
                    <br /> <br />
                    <a
                      onClick={() => redirectHandler()}
                      style={{ fontSize: 20 }}
                      className="btn btn-outline-secondary px-4"
                    >
                      Also You can order by Quote !
                    </a>
                  </h3>
                )}
              </div>

              <div className="d-flex col-sm-12  mx-auto">
                {/* <div className="btn-group " style={{ marginLeft: "-80px" }}>
                    <button
                      className="btn btn-lg dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sort By{" "}
                    </button>
                    <div
                      className="dropdown-menu col-sm-6 "
                      style={{ marginLeft: "-60px" }}
                    >
                      {[1, 2, 3, 4, 5].map((i) => (
                        <a className="dropdown-item" href="#">
                          item
                        </a>
                      ))}
                    </div>
                  </div> */}
              </div>

              <hr style={{ backgroundColor: "grey" }} />
              {searchItem?.map((i) => (
                <div
                  className="col-xs-12 col-lg-3 col-sm-6 col-md-4"
                  style={{ padding: "0" }}
                >
                  <SearchCard
                    title={i?.productName}
                    id={i?.id}
                    subTitle={i?.companyName}
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${i?.image?.id}/${i?.image?.imageName}`}
                    price={i?.specification?.productPrice}
                    discount={i?.specification?.productDiscount}
                    review={i?.review}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <UserFooter />
    </div>
  ) : (
    <Spinner />
  );
};

export default SearchPageUser;
