import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal as Modals } from "react-responsive-modal";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import {
  approveOrder,
  getAllDeliveryBoy,
  rejectOrder,
} from "../../Features/Action/OrderStatusAction";
import Spinner from "../../Spinner";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import SupplierDetailsModal from "./SupplierDetailsModal";
import CustomModal from "../Modal/index";

const SingleOrderDetail = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setstate] = useState();
  const [specId, setSpecId] = useState();
  const [orderDetailsId, setOrderDetailsId] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  const [modalId, setModalId] = useState("");
  const [deliveryModalId, setDeliveryModalId] = useState("");
  const [user, setUser] = useState();
  const [assignOrderModal, setAssignOrderModal] = useState(false);
  const [assignDeliveryModal, setAssignDeliveryModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const [searchText, setSearchText] = useState("");

  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const { wholesaleOrders, loader, deliveryBoys } = useSelector(
    (s) => s.AdminDeliveryReducer
  );

  useEffect(() => {
    dispatch(getAllDeliveryBoy());
  }, []);

  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };
  // const handleClose = () => setModalId(false);
  // get single order from API
  const getSingleOrder = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const { data } = await AxiosConfig.get(
        `/kreesa/admin/order/view-order/${id}`,
        config
      );
      setstate(data);
      setIsLoaded(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSingleOrder(id);
  }, []);

  // get consumer details
  const getUsers = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const getu = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${state.orderAndShippingDetails?.consumerId}`,
        config
      );

      history.location.state !== undefined &&
        AxiosConfig.get(
          `/kreesa/admin/get-user-by-user-id/${state.orderAndShippingDetails?.consumerId}`,
          config
        );
      setUser(getu.data);
    } catch (error) {}
  };
  useEffect(() => {
    getUsers();
  }, [state]);

  // approve Order
  const handleApproveOrder = (id) => {
    dispatch(approveOrder({ id, history }));
    getSingleOrder(id);
  };
  // rejectOrder
  const handlerejectOrder = (id) => {
    dispatch(rejectOrder({ id, history }));
    getSingleOrder(id);
    // history.goBack();
  };

  // Assign Order in Modal
  const handlAssignOrderModal = async (specId) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetSpecId = await AxiosConfig.get(
        `/kreesa/admin/order/view-all-seller/${id}/${specId}`,
        config
      );
      setSpecId(GetSpecId.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handlOrderAssign = async (supplierId) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let data = {
        orderId: orderDetailsId,
        supplierId: supplierId,
      };

      await AxiosConfig.post("/kreesa/admin/order/assign-order", data, config);
      toast.success("Order has been assigned");
      setModalId("");
      setAssignOrderModal(false);
      history.go();
      setIsLoaded(false);
    } catch (error) {
      console.log(error);
    }
  };

  return !isLoaded ? (
    <div className="">
      <div className="card mx-lg-4 p-2">
        <h5 className="font-weight-bold">Order Details</h5>
      </div>
      <div className="card mx-lg-4 mt-4">
        <div className="p-1">
          <p className="ml-2">
            Order Id:{" "}
            <span className="text-muted">
              {state.orderAndShippingDetails?.orderNo}
            </span>
          </p>
          <p className="card-text ml-2">
            Order Date:{" "}
            <span className="text-muted">
              {moment(state.orderAndShippingDetails?.orderDate).format("lll")}
            </span>
          </p>
          <p className="card-text ml-2">
            Order Type:{" "}
            <span className="text-muted font-weight-bold">
              {state.orderAndShippingDetails?.orderType}
            </span>
            <span
              onClick={() =>
                handleOpenModal(state.orderAndShippingDetails?.consumerId)
              }
              className="text-success ml-4 cursor-pointer"
            >
              Consumer Details
            </span>
          </p>
        </div>
      </div>
      {modal.show &&
        modal.requestedModalId == state.orderAndShippingDetails?.consumerId && (
          <Modals
            open={modal}
            onClose={handleCloseModal}
            center
            classNames={{
              overlayAnimationIn: "customEnterOverlayAnimation",
              overlayAnimationOut: "customLeaveOverlayAnimation",
              modalAnimationIn: "customEnterModalAnimation",
              modalAnimationOut: "customLeaveModalAnimation",
              modal: "customModal",
            }}
            animationDuration={1000}
          >
            <div className="pro">
              {/* <div className="col-md-4">
                    <div className="profile-img">
                      <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${id}/${user.companyLogo}`} alt={user?.companyName} />
                    </div>
                  </div> */}

              <h3 className="text-uppercase">{user?.companyName} </h3>
            </div>

            <div className="col-md-12 m-auto">
              <div className="tab-content profile-tab custom-height">
                {user.role === "ROLE_USER" ? (
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label>User Name</label>
                      </div>
                      <div className="col-md-6">
                        <p>
                          {user?.firstname} {user?.lastname}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Address</label>
                      </div>
                      <div className="col-md-6">
                        <p>{`${
                          user.streetAddress === null
                            ? ""
                            : user?.streetAddress + ","
                        } ${user?.districtName}, ${user?.provienceName}, ${
                          user?.countryName
                        }`}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label>Email</label>
                      </div>
                      <div className="col-md-6 ">
                        <p>{user?.emailId}</p>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6">
                        <label>Contact</label>
                      </div>
                      <div className="col-md-6">
                        <p>{user?.contact}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label>Company Name</label>
                      </div>
                      <div className="col-md-6 ">
                        <p className="text-capitalize">{user?.companyName}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Company Address</label>
                      </div>
                      <div className="col-md-6">
                        <p className="text-capitalize">
                          <p>{`${
                            user.streetAddress === null
                              ? ""
                              : user?.streetAddress + ","
                          } ${user?.districtName}, ${user?.provienceName}, ${
                            user?.countryName
                          }`}</p>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label>Company Email</label>
                      </div>
                      <div className="col-md-6">
                        <p>{user?.companyEmail}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Company Type</label>
                      </div>
                      <div className="col-md-6">
                        <p className="text-capitalize">{user?.companyType}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Company Contact</label>
                      </div>
                      <div className="col-md-6">
                        <p> {user?.companyContactNo}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>PAN No</label>
                      </div>
                      <div className="col-md-6">
                        <p> {user?.panNo}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Register Status</label>
                      </div>
                      <div className="col-md-6">
                        {user?.isRegistered === true ? (
                          <p>Register</p>
                        ) : (
                          <p>Not Register</p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Insuranced Status</label>
                      </div>
                      <div className="col-md-6">
                        {user?.isInsured === true ? (
                          <p>Insuranced</p>
                        ) : (
                          <p>Not Insuranced</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modals>
        )}
      {state?.orderDetails?.map((p, index) => (
        <div className="card mx-lg-4 mt-4" key={index}>
          <div className="">
            <h5 className=" ml-4 mt-3 pt-1 font-weight-bold">
              Uploaded By {p.productDetails.uploadedBy}
            </h5>
            <hr className="text-success mx-4" />
          </div>
          {/*  ------------------------------- main image -------------------------------  */}
          <div className="row g-0">
            {p.image?.map((img, i) =>
              i === 0 ? (
                <div className="col-md-3 ml-4" key={i}>
                  <img
                    src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                    className="img-fluid m-2 rounded-start"
                    style={{
                      width: "125px",
                      height: "125px",
                      borderRadius: "50%",
                    }}
                    alt={img.image}
                  />
                </div>
              ) : (
                ""
              )
            )}
            <div className="col-md-3">
              <div className="card-body">
                <p className="h5 font-weight-bold">
                  {p.productDetails.productName}
                </p>
                {/* <p className="card-text">this isi</p>  */}
                <p className="card-text">
                  <small className="badge bg-info">
                    {p.order?.selectedColor}
                  </small>
                </p>
                {p.order.discount === 0 ? (
                  <p className="card-text">
                    <small className="text-warning h4">
                      Rs. {p.order?.productPrice}
                    </small>
                  </p>
                ) : (
                  <p className="card-text">
                    <s className="text-muted">Rs. {p.order?.productPrice}</s>
                    <small className="ml-2 text-warning font-weight-bold h4">
                      Rs{" "}
                      {Math.round(
                        p.order?.productPrice -
                          (p.order?.productPrice * p.order?.discount) / 100
                      )}
                    </small>
                    <small className="ml-2 h5">
                      {" "}
                      - {p.order?.discount} % OFF
                    </small>
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-1 m-4">
              <p className="card-text">
                <small className="badge bg-danger">
                  {p.order?.assignStatus}
                </small>
              </p>
              <p className="card-text">
                <small
                  className={`badge ${
                    p.deliveryStatus === false ? "bg-danger" : "bg-success"
                  }`}
                >
                  {p.deliveryStatus === false
                    ? "Not Delivered Yet"
                    : "Delivered"}
                </small>
              </p>
              {p?.order?.assignStatus === "Assigned" && (
                <>
                  <p className="card-text">
                    <small
                      className="badge bg-secondary cursor-pointer"
                      onClick={() => {
                        setModalId(`modal${p?.order?.supplierId}`);
                      }}
                    >
                      Supplier Details
                    </small>
                  </p>
                </>
              )}
              {/* Modal */}
              <SupplierDetailsModal
                show={modalId === `modal${p?.order?.supplierId}`}
                {...props}
                sid={p?.order?.supplierId}
                onHide={() => setModalId("")}
                state={state}
              />

              <Modal
                show={deliveryModalId}
                onHide={() => setDeliveryModalId("")}
              >
                <Modal.Header>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="card flex-fill m-4 overflow-auto">
                    <table className="table table-hover my-0">
                      <thead>
                        <tr>
                          <th className="d-xl-table-cell">Name</th>
                          <th className="d-md-table-cell">Contact</th>
                          <th className="d-md-table-cell">Email</th>
                          <th className="d-md-table-cell">Address</th>
                        </tr>
                      </thead>
                      {/* <tbody>
                            <td className="p-2">
                              {deliveryBoy.firstname} {deliveryBoy.lastname}
                            </td>
                            <td className="p-2">{deliveryBoy.contact}</td>
                            <td className="p-2">{deliveryBoy.emailId}</td>
                            <td className="p-2">
                              {deliveryBoy.streetAddress},{" "}
                              {deliveryBoy.districtName},{" "}
                              {deliveryBoy.countryName}
                            </td>
                          </tbody> */}
                    </table>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setDeliveryModalId("")}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <p className="card-text font-weight-bold">
                Height: {p.productDetails?.hight}
              </p>
              <p className="card-text font-weight-bold">
                Weight: {p.productDetails?.weight}
              </p>
              <p className="card-text font-weight-bold">
                Size: {p.productDetails?.size}
              </p>
            </div>
            <div className="col-md-1 font-weight-bold m-4">
              Qty:{p.order.orderQuantity}
            </div>

            {localStorage.getItem("auth") !== "ROLE_RECEPTIONIST" &&
              history.location.state === undefined && (
                <div className="col-md-2 m-2">
                  {p.deliveryStatus === true ? (
                    <p className="text-success">Already Delivered</p>
                  ) : (
                    <div className="btn-group">
                      {p.order.orderApproval !== "Rejected" ? (
                        <>
                          {p.order.statusFromBuyer === "Rejected" ? (
                            <p className="text-danger font-weight-bold">
                              THIS ORDER HAS BEEN REJECTED
                            </p>
                          ) : (
                            state.orderAndShippingDetails.orderType !==
                              "retail" && (
                              <>
                                {p.deliveryStatus === false ? (
                                  <button
                                    type="button"
                                    style={{
                                      textTransform: "capitalize",
                                      backgroundColor: "#3b7ddd",
                                    }}
                                    className="btn-md btn btn-success dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Actions
                                  </button>
                                ) : (
                                  <p className="text-info font-weight-bold">
                                    No Action Remaining
                                  </p>
                                )}
                              </>
                            )
                          )}
                        </>
                      ) : (
                        <p className="text-danger font-weight-bold">
                          THIS ORDER HAS BEEN REJECTED
                        </p>
                      )}
                      <div className="dropdown-menu">
                        {p.order.orderApproval === "Approved" ? (
                          <>
                            {p.order.assignStatus !== "Assigned" && (
                              <>
                                <div
                                  onClick={() => {
                                    handlAssignOrderModal(
                                      p.order.specificationId
                                    );
                                    setOrderDetailsId(p.order.id);
                                    setModalId(`modal${p.order.id}`);
                                    setAssignOrderModal(true);
                                  }}
                                  data-target=".bd-example-modal-lg"
                                  className="dropdown-item cursor-pointer"
                                  to="#"
                                >
                                  <i className="fas fa-user text-info"></i>{" "}
                                  Assign Order
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => handleApproveOrder(p.order.id)}
                              className="dropdown-item cursor-pointer"
                            >
                              <i className="fas fa-check text-success"></i>{" "}
                              Approve
                            </div>
                            <div className="dropdown-divider"></div>
                          </>
                        )}

                        {p.order.assignStatus === "Assigned" &&
                          p.order.statusFromBuyer === "Approved" &&
                          p.deliveryStatus === false && (
                            <>
                              <div className="dropdown-divider"></div>
                              <div
                                className="dropdown-item cursor-pointer text-info"
                                onClick={() => {
                                  setAssignDeliveryModal(true);
                                  setOrderId(state.orderAndShippingDetails.id);
                                }}
                              >
                                <i className="fas fa-user"></i> Assign Delivery
                              </div>
                            </>
                          )}

                        {p.order.orderApproval !== "Approved" && (
                          <div
                            onClick={() => {
                              handlerejectOrder(p.order.id);
                            }}
                            className="dropdown-item cursor-pointer"
                          >
                            <i className="fas fa-trash-alt text-danger"></i>{" "}
                            Reject
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            {/*  ------------------------------- model start ------------------------------  */}
            <div className="row m-4">
              {p.image?.map((img, i) => (
                <div className="col-md-2" key={i}>
                  <div
                    onClick={() => handleOpenModal(img.imageId)}
                    className="d-flex cursor-pointer"
                  >
                    <img
                      className="img-fluid img-thumbnail m-1"
                      src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                      height={100}
                      width={120}
                      alt={img.image}
                    />
                  </div>
                  {/*Modal  */}
                  {modal.show && modal.requestedModalId == img.imageId && (
                    <Modals
                      open={modal}
                      onClose={handleCloseModal}
                      center
                      classNames={{
                        overlayAnimationIn: "customEnterOverlayAnimation",
                        overlayAnimationOut: "customLeaveOverlayAnimation",
                        modalAnimationIn: "customEnterModalAnimation",
                        modalAnimationOut: "customLeaveModalAnimation",
                      }}
                      animationDuration={1000}
                    >
                      <div className="pro">
                        <img
                          className="img-fluid img-thumbnail"
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${img.imageId}/${img.image}`}
                          height={650}
                          width={700}
                          alt={img.image}
                        />
                      </div>
                    </Modals>
                  )}
                </div>
              ))}
            </div>
            {/*  ------------------------------- model end ------------------------------  */}
          </div>
          {/* large modal start*/}
        </div>
      ))}

      <CustomModal
        modal={assignDeliveryModal}
        setModal={setAssignDeliveryModal}
        data={deliveryBoys}
        orderId={orderId}
      />

      <Modal show={assignOrderModal} onHide={() => setAssignOrderModal(false)}>
        <Modal.Header>
          <Modal.Title>Assign Order </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="search"
            autoComplete="false"
            className="form-control ml-4 w-75"
            placeholder="Search Company"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div className="card flex-fill m-4 overflow-auto">
            <table className="table table-hover my-0">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th className="d-xl-table-cell">Company Name</th>
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {specId
                  ?.filter((i) =>
                    i.fullName.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((p, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="d-md-table-cell">{p?.fullName}</td>
                      <td>
                        <a
                          href
                          onClick={() => handlOrderAssign(p.user_id)}
                          className="cursor-pointer"
                        >
                          <span className="badge bg-success">
                            <i className="fas fa-check"></i> Assign
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <div
        className="modal fade bd-example-modal-lg "
        tabIndex="-1"
        role="dialog"
        id="myLargeModalLabel"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg"></div>
      </div>
      {/* large modal end*/}

      <div className="card mx-lg-4 mt-4">
        <div className="row">
          <div className="col-6 ml-5 mt-4 col-md-3">
            {/*<h6 className="h6 ml-4 mt-1">Billing Address</h6>
            <hr className="text-info" />
            <p className="ml-2">Name</p>
            <i className="ml-2">{state.shippingAddress}</i>
            <p className="ml-2">{state.contactNo}</p>*/}
          </div>
          <div className="col-6 ml-5 mt-4 col-md-3">
            <h6 className="h6 ml-4 mt-1">Shipping Address</h6>
            <hr className="text-info" />
            <p className="ml-2">Name</p>
            <i className="ml-2">
              {state.orderAndShippingDetails?.shippingState} -{" "}
              {state.orderAndShippingDetails?.shippingAddress} -{" "}
              {state.orderAndShippingDetails?.shippingCountry}
            </i>
            <p className="ml-2">{state.orderAndShippingDetails?.contactNo}</p>
          </div>

          <div className="col-6 ml-5 mt-4 col-md-3">
            <ul className="list-group mb-3">
              {state?.orderDetails?.map((p, i) => (
                <li
                  className="list-group-item d-flex justify-content-between lh-sm"
                  key={i}
                >
                  <div>
                    <h6 className="my-0">{p.productDetails.productName}</h6>
                    <span className="text-muted">
                      Qty : {p.order.orderQuantity}
                    </span>
                  </div>
                  <small className="text-muted">
                    Rs{" "}
                    {Math.round(
                      p.order?.productPrice -
                        (p.order?.productPrice * p.order?.discount) / 100
                    ) * p.order.orderQuantity}
                  </small>
                </li>
              ))}
              <li className="list-group-item d-flex justify-content-between">
                <span>Shipping Cost</span>
                <strong>
                  Rs {state.orderAndShippingDetails?.shippingCost}
                </strong>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span>Total (Npr)</span>
                <strong>
                  {Math.round(state?.orderAndShippingDetails?.totalCost)}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default SingleOrderDetail;
