import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import Spinner from "../../Admin/Spinner";
import FileSaver from "file-saver";
import Pagination from "../../Pagination";
import Review from "../../User/ReviewModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const YourOrderDetails = () => {
  const history = useHistory();
  const [state, setstate] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [ratingModal, setRatingModal] = useState(false);
  const [orderId, setOrderId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  // get my order from API
  const getMyOrder = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetMyOrderDetails = await AxiosConfig.get(
        `/kreesa/wholesaler/order/view/consumer/${localStorage.getItem(
          "userId"
        )}`,
        config
      );
      setstate(GetMyOrderDetails.data);
      setIsLoaded(false);
    } catch (error) {
      console.log(error.response);
    }
  };
  const saveFile = (orderId, invoice) => {
    FileSaver.saveAs(
      `${AxiosConfig.defaults.baseURL}/kreesa/image/invoice/${orderId}`,
      invoice
    );
  };
  useEffect(() => {
    getMyOrder();
  }, []);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = state?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(state?.length / perPage);

  return !isLoaded ? (
    <div className="container">
      <div className="row">
        {/* <div className="col-12 d-flex w-100">
            <div className="card flex-fill flex-fill">
                <div className="card-header">
                    <Link to="/retailer/product/register" className="btn btn-lg btn-outline-info display-5 mt-2" style={{ float: 'right' }}>
                        Add Product  <i className="fab fa-product-hunt"></i></Link>
                </div>
            </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex w-100">
          <div className="card flex-fill overflow-auto">
            <div className="card-header">
              <h5 className="card-title mb-0">My Latest Orders</h5>
            </div>
            <table className="table table-hover my-0 ">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Orders Number</th>
                  <th>Orders Date</th>
                  <th className="d-xl-table-cell">Number of Order</th>
                  <th className="d-md-table-cell">Address</th>
                  <th className="d-md-table-cell">Payment Status</th>
                  <th className="d-md-table-cell">Payment Method</th>
                  <th className="d-md-table-cell">Delivery Status</th>
                  <th className="d-md-table-cell">Invoice</th>
                  <th className="d-md-table-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries?.map((p, i) => (
                  <tr>
                    <td>{perPage * (currentPage - 1) + i + 1}</td>
                    <td>{p.orderNo}</td>
                    <td>{moment(p.orderDate).format("ll")}</td>
                    <td className="d-xl-table-cell">
                      {p.orderDetails?.length}
                    </td>
                    <td className="d-md-table-cell">{p.shippingAddress}</td>
                    <td>
                      <span
                        className={
                          p?.payment?.paymentStatus === "PAID"
                            ? "badge bg-success"
                            : "badge bg-danger"
                        }
                      >
                        {p?.payment?.paymentStatus}
                      </span>
                    </td>
                    <td>{p?.payment?.paymentMethod}</td>
                    <td>
                      {p.deliveryStatus === "DELIVERED" ? (
                        <span className="badge bg-success">
                          {p.deliveryStatus}
                        </span>
                      ) : p.deliveryStatus === "RETURNED" ? (
                        <span className="badge bg-danger">
                          {p.deliveryStatus}
                        </span>
                      ) : p.deliveryStatus === "ON_WAY" ? (
                        <span className="badge bg-primary">
                          {p.deliveryStatus}
                        </span>
                      ) : p.deliveryStatus === "DISPATCHED" ? (
                        <span className="badge bg-info">
                          {p.deliveryStatus}
                        </span>
                      ) : (
                        <span className="badge bg-secondary">NOT READY</span>
                      )}
                    </td>
                    <td className="d-md-table-cell">
                      <div
                        className="cv"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => saveFile(p.id, p?.invoice)}
                      >
                        {p?.invoice} <i className="fa fa-download"></i>
                      </div>
                    </td>
                    <td className="d-md-table-cell">
                      <div className="btn-group">
                        <button
                          type="button"
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#3b7ddd",
                          }}
                          className="btn-md btn btn-success dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <div className="dropdown-menu text-center">
                          <Link
                            to={`/wholesaler/my-orders/${p.id}`}
                            className="dropdown-item text-primary"
                          >
                            <i className="fas fa-eye"></i> View More
                          </Link>

                          {p.payment?.paymentMethod !== "CASH_ON_DELIVERY" &&
                            p?.payment?.paymentStatus !== "PAID" && (
                              <>
                                <div className="dropdown-divider"></div>
                                <div
                                  className="dropdown-item cursor-pointer text-success"
                                  onClick={() => {
                                    history.push("/wholesaler/pay-now", {
                                      orderId: p.id,
                                      totalCost: p.totalCost,
                                      shippingCost: p.shippingCost,
                                    });
                                  }}
                                >
                                  <i className="fas fa-dollar-sign"></i> Pay Now
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={state}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default YourOrderDetails;
