import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetailerProducts } from "../../../Admin/Features/Action/ProductAction";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import "./style.css";
import { Link } from "react-router-dom";
import Spinner from "../../../Admin/Spinner";
import { filterOfRetailer } from "../../../Admin/Features/Reducer/ProductReducer/RetailerReducer/getRetailerProductsReducer";
import moment from "moment";
import Pagination from "../../../Pagination";

const ViewProductDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();
  const inputRef = useRef("");

  useEffect(() => {
    dispatch(getRetailerProducts(localStorage.getItem("userId")));
  }, []);

  const { Retailers, loader } = useSelector(
    (s) => s.getRetailerProductsReducer
  );
  // console.log(Retailers)

  const filterCategory = () => {
    dispatch(filterOfRetailer(inputRef.current.value));
  };

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = Retailers.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(Retailers.length / perPage);
  return !loader ? (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex w-100">
            <div className="card flex-fill flex-fill">
              <div className="card-header">
                <Link
                  to="/retailer/product/register"
                  className="btn btn-lg btn-outline-info display-5 mt-2"
                  style={{ float: "right" }}
                >
                  Add Product <i className="fab fa-product-hunt"></i>
                </Link>
                <form className="d-none mt-2 d-sm-inline-block">
                  <div className="input-group input-group-navbar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Product…"
                      aria-label="Search"
                      ref={inputRef}
                      onChange={filterCategory}
                    />
                    <button className="btn" type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h5 className="card-title mb-0">All Products Of Retailers</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Products Name</th>
                    <th>Company Name</th>
                    <th>Status</th>
                    <th>Category</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/Product/${p.image.id}/${p.image?.imageName}`}
                          width="48"
                          height="48"
                          className="rounded-circle mr-2"
                          alt="Avatar"
                        />
                        {p.productName}
                      </td>
                      <td>{p.companyName}</td>
                      <td>
                        {p.isPublish === true ? (
                          <span className="badge bg-success">Publish</span>
                        ) : (
                          <span className="badge bg-info">Pending</span>
                        )}
                      </td>
                      <td>
                        {p.primaryCategoryName}/{p.secondaryCategoryName}/
                        {p.ternaryCategoryName}
                      </td>
                      <td>{moment(p.createdAt).format("ll")}</td>
                      <td>{moment(p.updatedAt).format("ll")}</td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              to={`/saller/edit-product/${p.id}`}
                              className="dropdown-item text-primary"
                            >
                              <i className="fas fa-user-edit"></i> Edit
                            </Link>
                            <div className="dropdown-divider" />
                            <Link
                              className="dropdown-item text-info"
                              to={`/retailer/product/single-view/${p.id}`}
                            >
                              <i className="fas fa-eye"></i> Review
                            </Link>
                            {/* <a className="dropdown-item" href="#">Another action</a>
                                                        <a className="dropdown-item" href="#">Something else here</a> */}
                            {/* <div className="dropdown-divider"></div> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={Retailers}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ViewProductDetails;
