import React from "react";
import { useParams } from "react-router-dom";
import MyQuotationDetails from "../../Retailer/MyQuotation/MyQuotationDetails";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import UserFooter from "../UserFooter";

const MyQuotation = () => {
  const { id } = useParams();
  return (
    <div>
      <Search />
      <Navbar />
      <div className="container mt-6">
        <MyQuotationDetails {...{ id }} />
      </div>

      <UserFooter />
    </div>
  );
};

export default MyQuotation;
