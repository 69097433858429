import React, { useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import "./style.css";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import UserLoginValidation from "../../Validation/UserLoginValidation";
import logo from "../../../assets/Images/kreesalogo.png";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .email("Invalid Email"),
  // company: Yup.string().required("Company is required"),
  password: Yup.string().required("password is required"),
});

const AdminLogin = () => {
  document.title = "Kreesa | Admin Login";

  const [showPassword, setShowPassword] = useState(false);

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Login",
    "Loading..."
  );

  let history = useHistory();

  const loginHandleSubmit = async ({ username, password }) => {
    setButtonLoader(true);
    let res;

    try {
      //api
      res = await AxiosConfig.post("/kreesa/user-login-registration/login", {
        username,
        password,
      });
    } catch (error) {
      toast.error(error.response.data.message);
      toast.error(error.response.data);
      setButtonLoader(false);
    }

    if (res && res.status === 203) {
      setButtonLoader(false);
      toast.info(res.data);
      return;
    }
    // to check role as well as redirect to dashboard or not
    if (res && res.status == 200) {
      setButtonLoader(false);
      if (
        res.data.role === "ROLE_SUPER_ADMIN" ||
        res.data.role === "ROLE_CEO" ||
        res.data.role === "ROLE_MANAGER" ||
        res.data.role === "ROLE_MARKETING_MANAGER" ||
        res.data.role === "ROLE_RECEPTIONIST" ||
        res.data.role === "ROLE_HR"
      ) {
        // set localstorage
        localStorage.setItem("token", res.headers.jwttoken);
        localStorage.setItem("username", username);
        localStorage.setItem("auth", res.data.role);
        localStorage.setItem("userId", res.data.id);
        /* ----------------------------------- ... ---------------------------------- */
        // var hours = 1;
        // var now = new Date().getTime();
        // var setupTime = localStorage.getItem("setupTime");
        // if (setupTime == null) {
        //   localStorage.setItem("setupTime", now);
        //   localStorage.setItem("token", res.headers.jwttoken);
        //   localStorage.setItem("username", username);
        //   localStorage.setItem("auth", res.data.role);
        // } else {
        //   if (now - setupTime > hours * 60 * 60 * 1000) {
        //     localStorage.clear();
        //     // localStorage.removeItem("setupTime");
        //     // localStorage.removeItem("token");
        //     // localStorage.removeItem("username");
        //     // localStorage.removeItem("auth");
        //     localStorage.setItem("setupTime", now);
        //   }
        // }
        /* ----------------------------------- ... ---------------------------------- */
        // redirect to /admin url
        history.push("/admin");
        history.go(0);
      } else {
        toast.error("you are not authorized");
      }
    }
  };

  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token) {
    return <Redirect exact to="/admin" />;
  }

  // UserLoginValidation();

  return (
    <div className="container infinity-container">
      <div className="row">
        <div className="col-md-1 infinity-left-space"></div>
        <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 text-center infinity-form">
          {/* <!-- Company Logo --> */}
          <Link to="/" className="text-center mb-3 mt-5">
            <img src={logo} alt="company logo" width="150px" />
          </Link>
          <div className="text-center mb-4 rounded">
            <h4>Login into account</h4>
          </div>

          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            onSubmit={loginHandleSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => (
              <Form
                className="px-3"
                id="user-login-form"
                onSubmit={handleSubmit}
              >
                <div className="input-group">
                  <span className="input-group-text">
                    {/* <i className="fas fa-user"></i> */}
                    <i className="fa fa-envelope"></i>
                  </span>
                  <input
                    className="form-control py-2"
                    id="username"
                    name="username"
                    onChange={handleChange}
                    placeholder="Enter Username"
                    value={values.username}
                  />

                  {/* <span className="input-group-text">
                  <i className="far fa-eye-slash" id="togglePassword"></i>
                </span> */}
                </div>
                {errors.username && touched.username ? (
                  <div style={{ color: "red" }}> {errors.username} </div>
                ) : null}
                <div className="input-group mt-2">
                  <span className="input-group-text">
                    <i className="fas fa-lock"></i>
                  </span>
                  <input
                    className="form-control py-2"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    placeholder="Enter Password"
                    value={values.password}
                    type={showPassword ? "text" : "password"}
                  />
                  <span
                    className="input-group-text cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={`${
                        showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                      }`}
                      id="togglePassword"
                    ></i>
                  </span>
                </div>
                {errors.password && touched.password ? (
                  <div style={{ color: "red" }}> {errors.password} </div>
                ) : null}
                {/* <div className="form-input">
              <span>
                <i className="fas fa-key"></i>
              </span>
              <input
                type={show ? "text" : "password"}
                name="password"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div> */}
                {/* <div className="row mb-3">
                            <div className="col-auto d-flex align-items-center">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="cb1" />
                                    <label className="custom-control-label" htmlFor="cb1">Remember me</label>
                                </div>
                            </div>
                        </div> */}
                {/* <!-- Login Button --> */}
                <div className="mb-3">
                  <button
                    type="submit"
                    id="lbtn"
                    ref={buttonLoader}
                    className="btn btn-block py-2 mt-4"
                  >
                    Login
                  </button>
                </div>
                <div className="text-right">
                  <Link to="/admin/forgot-password" className="forget-link">
                    Forgot password?
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {/* <!-- FORM END --> */}
        <div className="col-md-1 infinity-right-space"></div>
      </div>
    </div>
  );
};

export default AdminLogin;
