import $ from "jquery";
import "jquery-validation";

const UserOrderValidation = () => {
  // Wait for the DOM to be ready
  $(document).ready(function () {
    $("#user-order-form").validate({
      // Specify validation rules
      rules: {
        contactNo: {
          required: true,
        },
        company_name: {
          required: true,
        },
        shippingAddress: {
          required: true,
        },
        shippingState: {
          required: true,
        },
      },
      messages: {
        contactNo: {
          required: "contact number required",
        },
        company_name: {
          required: "company name required",
        },
        shippingAddress: {
          required: "address required",
        },
        shippingState: {
          required: "state required",
        },
      },

      highlight: function (element) {
        $(element).removeClass("is-valid").addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid").addClass("is-valid");
      },
    });
    $("#orderbtn").on("click", function () {
      $("#user-order-form").valid();
    });
  });
  $("#user-order-form input").on("keyup blur", function () {
    // fires on every keyup & blur
    if ($("#user-order-form").valid()) {
      // checks form for validity
      $("button#orderbtn").prop("disabled", false); // enables button
    } else {
      $("button#orderbtn").prop("disabled", "disabled"); // disables button
    }
  });
};

export default UserOrderValidation;
