import { createSlice } from "@reduxjs/toolkit";
import {
  approveUserQuotation,
  getNotificationCountUser,
  getUserNotification,
  getUserProfile,
  getUserQuotation,
  rejectUserQuotation,
  updateProfile,
  userReadAllNotification,
} from "../action";

const userProfile = createSlice({
  name: "userProfile",
  initialState: {
    profile: [],
    notification: [],
    quotation: [],
    notificationCount: null,
  },
  reducers: {},
  extraReducers: {
    [getUserProfile.pending]: (state, action) => {
      state.loader = true;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.loader = false;
      state.profile = action.payload;
    },
    [getUserProfile.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [updateProfile.pending]: (state, action) => {
      state.loader = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.loader = false;
      state.profile = action.payload;
    },
    [updateProfile.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getUserNotification.pending]: (state, action) => {
      state.loader = true;
    },
    [getUserNotification.fulfilled]: (state, action) => {
      state.loader = false;
      state.notification = action.payload;
    },
    [getUserNotification.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getNotificationCountUser.pending]: (state, action) => {
      state.loader = true;
    },
    [getNotificationCountUser.fulfilled]: (state, action) => {
      state.loader = false;
      state.notificationCount = action.payload;
    },
    [getNotificationCountUser.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [userReadAllNotification.pending]: (state, action) => {
      state.loader = true;
    },
    [userReadAllNotification.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [userReadAllNotification.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [getUserQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [getUserQuotation.fulfilled]: (state, action) => {
      state.quotation = action.payload;
      state.loader = false;
    },
    [getUserQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [approveUserQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [approveUserQuotation.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [approveUserQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },

    [rejectUserQuotation.pending]: (state, action) => {
      state.loader = true;
    },
    [rejectUserQuotation.fulfilled]: (state, action) => {
      state.loader = false;
    },
    [rejectUserQuotation.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

// export default
export default userProfile.reducer;
