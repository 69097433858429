import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/Images/kreesalogo.png";
import Individual from "../../../assets/Images/Individual.png";
import business from "../../../assets/Images/business.png";
import "./register.css";

const RegisterPage = () => {
  document.title = "Register";
  return (
    <div>
      <div class="container px-lg-7 mt-4 mb-4">
        <div class="row">
          <div className="text-center">
            <Link to="/">
              <img className="rlogo-image mr-3" src={logo} alt="logo" />
            </Link>
          </div>
          <h3 className="mt-4 text-uppercase font-weight-bold text-center">
            Register Your Kreesa Account
          </h3>
          <div class="col-xs-12 col-sm-6 mt-5">
            <Link
              to="/usersignup"
              class="box __box_user"
              style={{
                backgroundColor: "#8bb7f0",
              }}
            >
              <img
                src={Individual}
                alt="user"
                className="img-fluid individual-user"
              />
              <h3 className="font-weight-bold">Individual</h3>
            </Link>
          </div>
          <div class="col-xs-12 col-sm-6 mt-5">
            <Link
              to="/signup"
              class="box __box_user"
              style={{
                backgroundColor: "#05739d",
              }}
            >
              <img
                src={business}
                alt="seller"
                className="img-fluid business-user"
              />
              <h3 className="font-weight-bold">Business</h3>
            </Link>
          </div>
        </div>
      </div>
      {/*  */}
      {/* <div className="container-fluid bg-white" style={{ height: "100vh" }}>
        <div className="row">
          <div className="col-lg-12 col-sm-12 d-flex justify-content-center align-items-center mt-3">
            <div className="mt-4">
              <div className="text-center">
                <Link to="/">
                  <img className="rlogo-image mr-3" src={logo} alt="logo" />
                </Link>
              </div>
              <h3 className="mt-4 text-uppercase font-weight-bold text-center">
                Register Your Kreesa Account
              </h3>
              <div
                className="register-form mt-6 col-lg-12 d-flex mb-6"
                style={{ gap: "30px" }}
              >
                <Link to="/usersignup" className="col-10 user-sec text-center">
                  <div className="">
                    <img
                      src={Individual}
                      alt="user"
                      className="img-fluid individual-user"
                    />
                    <h3 className="text-center r-text mt-4 mb-4 ml-2">
                      Individual
                    </h3>
                    <i className="d-none re-sign fas fa-sign-in-alt mr-2"></i>
                  </div>
                </Link>
                <Link
                  to="/signup"
                  className="col-10 seller-sec text-center"
                  id="seller-sec"
                >
                  <div className="">
                    <img
                      src={business}
                      alt="seller"
                      className="img-fluid business-user"
                    />
                    <h3 className="text-center r-text mt-4 ml-4 mb-4">
                      Business
                    </h3>

                    <i className="d-none re-sign fas fa-sign-in-alt mr-2"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default RegisterPage;
