import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Spinner from "../../Spinner";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { unBlockUser } from "../../Features/Action/AdminBlockUnblockAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const BlockedUsersTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setstate] = useState();
  const [isLoaded, setIsLoaded] = useState(true);
  // get all users from API
  const getBlockedUsers = async () => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const GetallUserDetails = await AxiosConfig.get(
        "/kreesa/admin/view-all-admin",
        config
      );
      setstate(GetallUserDetails.data);
      setIsLoaded(false);
    } catch (err) {
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
    }
  };
  useEffect(() => {
    getBlockedUsers();
  }, []);

  //unblock user
  const handleUnBlockUser = (id) => {
    dispatch(unBlockUser(id));

    toast.info("User has been unblocked");
    setTimeout(() => {
      getBlockedUsers();
    }, 500);
  };
  return !isLoaded ? (
    <>
      <div
        className="col-12 col-lg-8 d-flex w-100"
        style={{ minHeight: "420px" }}
      >
        <div className="card flex-fill overflow-auto">
          <div className="card-header">
            <h5 className="card-title mb-0">Blocked Users</h5>
          </div>
          <table className="table table-hover my-0 ">
            <thead>
              <tr>
                <th>Sn.</th>
                <th>Full Name</th>
                <th className="d-xl-table-cell">E-mail</th>
                <th className="d-xl-table-cell">Phone</th>
                <th className="d-xl-table-cell">Profile Image</th>
                <th className="d-xl-table-cell">Role</th>
                <th>Status</th>
                <th className="d-md-table-cell">Action</th>
              </tr>
            </thead>
            <tbody>
              {state?.map(
                (p, i) =>
                  p.flag == false && (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {p.firstname} {p.lastname}
                      </td>
                      <td className="d-xl-table-cell">
                        <a href={`mailto:${p.email}`}>{p.email}</a>
                      </td>
                      <td className="d-xl-table-cell">{p.phoneNumber}</td>
                      <td className="d-xl-table-cell">
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/Admin/${p.userId}/${p.image}`}
                          width="48"
                          height="48"
                          className="rounded-circle mr-2"
                          alt="Avatar"
                        />
                      </td>
                      <td className="d-xl-table-cell">
                        {p.role === "ROLE_CEO"
                          ? "CEO"
                          : "" || p.role === "ROLE_MANAGER"
                          ? "MANAGER"
                          : "" || p.role === "ROLE_MARKETING_MANAGER"
                          ? "MARKETING MANAGER"
                          : "" || p.role === "ROLE_RECEPTIONIST"
                          ? "RECEPTIONIST"
                          : ""}
                      </td>
                      <td></td>
                      <td>
                        <span className="badge bg-danger">Blocked</span>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-left">
                            <Link
                              to={`/admin/details/${p.userId}`}
                              className="dropdown-item text-info"
                            >
                              <i className="fas fa-user-edit"></i> Edit
                            </Link>
                            <div className="dropdown-divider" />

                            <Link
                              to={`/admin/view-details/${p.userId}`}
                              className="dropdown-item text-success"
                            >
                              <i className="fas fa-user-edit"></i> Review
                            </Link>

                            <div className="dropdown-divider" />
                            <p
                              onClick={() => handleUnBlockUser(p.userId)}
                              className="dropdown-item text-danger cursor-pointer"
                            >
                              <i className="fas fa-user-lock"></i> Unblock
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : (
    <Spinner />
  );
};

export default BlockedUsersTab;
