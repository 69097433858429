import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "../../../Pagination";
import {
  AssignQuoteToDeliveryBoy,
  AssignToDeliveryBoy,
} from "../../Features/Action/OrderStatusAction";

const CustomModal = ({ modal, setModal, data, orderId, type = "order" }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [perPage] = useState(7);
  const dispatch = useDispatch();
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = data?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(data?.length / perPage);
  return (
    <div>
      <Modal show={modal} onHide={() => setModal(false)} size="lg">
        <Modal.Header>
          <Modal.Title className="font-weight-light text-center w-100 d-flex justify-content-between">
            <div>Delivery Detail</div>
            <div style={{ cursor: "pointer" }} onClick={() => setModal(false)}>
              X
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="search"
            autoComplete="false"
            className="form-control ml-4 w-75 mb-4"
            placeholder="Search Delivery Boy"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <table className="table table-hover my-0 ">
            <thead>
              <tr>
                <th>Sn.</th>
                <th className="d-xl-table-cell">First Name</th>
                {/* <th>Contact </th> */}
                <th className="d-xl-table-cell">Last Name</th>
                {/* <th>Status</th>
                <th className="d-md-table-cell">Shipping Address</th> */}
                <th className="d-md-table-cell">Action</th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.filter(
                  (i) =>
                    i.firstName
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    i.lastName.toLowerCase().includes(searchText.toLowerCase())
                )
                .map((p, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td className="d-xl-table-cell">{p.firstName}</td>
                    <td className="d-xl-table-cell">{p.lastName}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={async () => {
                          if (type === "quote") {
                            dispatch(
                              AssignQuoteToDeliveryBoy({
                                quoteId: orderId,
                                userId: p.userId,
                                history,
                              })
                            );
                          } else {
                            await dispatch(
                              AssignToDeliveryBoy({
                                orderId,
                                userId: p.userId,
                                history,
                              })
                            );
                          }
                          // history.go(0);
                          setModal(false);
                        }}
                      >
                        Assign
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={data}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomModal;
