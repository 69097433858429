import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../Footer";
import LeftNavbar from "../LeftNavbar";
import TopNavbar from "../TopNavbar";
import Message from "./Message";

const Profile = () => {
  document.title = "Admin | Message";
  // to check authentication user or not
  // to check authentication user or not
  let token = localStorage.getItem('token', 'username', 'auth');
  if (token === null) {
      return <Redirect exact to="/admin/login" />
  }
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <Message />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
