import React from "react";
import Footer from "../Footer";
import TopNavbar from "../TopNavbar";
import LeftNavbar from "../LeftNavbar/";
import { Redirect } from "react-router-dom";
import Legal from "./legal";

const LegalDocsAdmin = () => {
  document.title = "Admin | Legal Documents";
  // to check authentication user or not
  let token = localStorage.getItem("token", "username", "auth");
  if (token === null) {
    return <Redirect exact to="/admin/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <Legal />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LegalDocsAdmin;
