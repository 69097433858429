import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../Features/action";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  getAllCountries,
  getAllDistrict,
  getAllProvince,
} from "../../Admin/Features/Action/AddressAction";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone_no: Yup.number().required("Phone Number is required"),
  // email: Yup.string().email("Email is invalid").required("Email is required"),
});

const EditProfile = ({ onSuccess, isModal }) => {
  const dispatch = useDispatch();

  const [selectProvince, SetSelectProvince] = useState([]);
  const [selectDistrict, SetSelectDistrict] = useState([]);

  const { countries, districts, proviences } = useSelector(
    (s) => s.AddressReducer
  );
  const history = useHistory();

  const { profile } = useSelector((state) => state.userProfile);
  const {
    firstname,
    lastname,
    emailId,
    address,
    contact,
    countryId,
    districtId,
    provinceId,
    streetAddress,
    landmark,
    pinCode,
  } = profile;

  useEffect(() => {
    if (profile) {
      SetSelectProvince(profile.countryId);
      SetSelectDistrict(profile.provinceId);
    }
  }, [profile]);

  const [images, setImage] = useState();
  return (
    <div>
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "1600",marginTop:'10rem'}}
      > */}
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="modal-title font-weight-bold text-info"
              id="exampleModalLabel"
            >
              {`${isModal?"Update": "Edit"} Your Profile`}
            </h4>
            {/* <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          <div className="modal-body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                first_name: firstname,
                last_name: lastname,
                email: emailId,
                phone_no: contact,
                countryId: countryId,
                provinceId: provinceId,
                districtId: districtId,
                street_address: streetAddress,
                landmark,
                pin_code: pinCode,
              }}
              onSubmit={async (values) => {
                const data = new FormData();
                if (images != null) {
                  data.append("image", images);
                }
                data.append("first_name", values.first_name);
                data.append("last_name", values.last_name);
                data.append("email", values.email);
                data.append("phone_no", values.phone_no);
                data.append("countryId", values.countryId);
                data.append("provinceId", values.provinceId);
                data.append("districtId", values.districtId);
                data.append("street_address", values.street_address);
                data.append("landmark", values.landmark);
                data.append("pin_code", values.pin_code);
                data.append("id", localStorage.getItem("userId"));
                data.append("role", localStorage.getItem("auth"));

                await dispatch(updateProfile(data));
                if(isModal && onSuccess){
                  onSuccess();
                }else{
                  history.push("/manage-account?my-profile");
                }
                toast.success("Profile Update Successfully");
              }}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
                setFieldValue,
                handleBlur,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row mb-4 text-dark font-weight-bold">
                    <label
                      for="first_name"
                      className="col-lg-3 col-form-label text-left"
                    >
                      First Name:
                    </label>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail3"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        placeholder="Enter First Name"
                        style={{ border: "1px solid black" }}
                      />
                      {errors.first_name && touched.first_name ? (
                        <div style={{ color: "red" }}>{errors.first_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="last_name"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Last Name:
                    </label>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        className="form-control"
                        id="inputPassword3"
                        placeholder="Enter Last Name"
                        style={{ border: "1px solid black" }}
                      />
                      {errors.last_name && touched.last_name ? (
                        <div style={{ color: "red" }}>{errors.last_name}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="email"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Email:
                    </label>
                    <div className="col-lg-7">
                      <input
                        name="emailId"
                        value={values.email}
                        onChange={handleChange}
                        type="email"
                        className="form-control"
                        id="inputPassword3"
                        placeholder="Enter Email"
                        style={{ border: "1px solid black" }}
                      />
                      {errors.email && touched.email ? (
                        <div style={{ color: "red" }}>{errors.email}</div>
                      ) : null}
                    </div>
                  </div> */}
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="contact"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Mobile No:
                    </label>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        name="contact"
                        value={values.phone_no}
                        onChange={handleChange}
                        className="form-control"
                        id="inputPassword3"
                        placeholder="Enter Mobile Number"
                        style={{ border: "1px solid black" }}
                      />
                      {errors.phone_no && touched.phone_no ? (
                        <div style={{ color: "red" }}>{errors.phone_no}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="contact"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Profile Image:
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control"
                        type="file"
                        accept="image/png,image/jpg,image/jpeg"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                        name="image"
                        style={{ border: "1px solid black" }}
                      />
                      <small>Accepted Formats: png, jpg & jpeg</small>
                    </div>
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="contact"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Country:
                    </label>
                    <div className="col-lg-7">
                      <select
                        name="countryId"
                        onChange={handleChange}
                        onBlur={(e) => {
                          SetSelectProvince(e.target.value);
                        }}
                        value={values.countryId}
                        className="form-control"
                        style={{ border: "1px solid black" }}
                      >
                        <option value="">Select Your Country...</option>
                        {countries?.map((c, i) => (
                          <option
                            key={i}
                            value={c.id}
                            selected={countryId === c.country_name}
                          >
                            {c.country_name}
                          </option>
                        ))}
                      </select>
                      {errors.countryId && touched.countryId ? (
                        <div style={{ color: "red" }}>{errors.countryId}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      className="col-lg-3 col-form-label text-left"
                      htmlFor="inputEmail4"
                    >
                      Province:
                    </label>
                    <div className="col-lg-7">
                      <select
                        name="provinceId"
                        onChange={handleChange}
                        onBlur={(e) => {
                          SetSelectDistrict(e.target.value);
                        }}
                        value={values.provinceId}
                        style={{ border: "1px solid black" }}
                        className="form-control"
                      >
                        <option value="">Select Your Provience...</option>
                        {proviences?.map(
                          (pr, i) => (
                            // selectProvince == pr.id && (
                            <option
                              key={i}
                              value={pr.id}
                              selected={provinceId === pr.provience_name}
                            >
                              {pr?.provience_name}
                            </option>
                          )
                          // )
                        )}
                      </select>
                    </div>

                    {errors.provinceId && touched.provinceId ? (
                      <div style={{ color: "red" }}>{errors.provinceId}</div>
                    ) : null}
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      className="col-lg-3 col-form-label text-left"
                      htmlFor="inputEmail4"
                    >
                      District:
                    </label>
                    <div className="col-lg-7">
                      <select
                        name="districtId"
                        onChange={handleChange}
                        value={values.districtId}
                        className="form-control"
                        style={{ border: "1px solid black" }}
                      >
                        <option value="">Select Your District...</option>
                        {districts?.map(
                          (d, i) => (
                            // selectDistrict == d?.id && (
                            <option
                              key={i}
                              value={d.id}
                              selected={districtId === d.district_name}
                            >
                              {d.district_name}
                            </option>
                          )
                          // )
                        )}
                      </select>
                    </div>

                    {errors.districtId && touched.districtId ? (
                      <div style={{ color: "red" }}>{errors.districtId}</div>
                    ) : null}
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="contact"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Street Address:
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control"
                        type="text"
                        onChange={handleChange}
                        name="street_address"
                        value={values.street_address}
                        style={{ border: "1px solid black" }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="contact"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Landmark:
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control"
                        type="text"
                        onChange={handleChange}
                        name="landmark"
                        value={values.landmark}
                        style={{ border: "1px solid black" }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 text-dark font-weight-bold">
                    <label
                      for="contact"
                      className="col-lg-3 col-form-label text-left"
                    >
                      Pin Code:
                    </label>
                    <div className="col-lg-7">
                      <input
                        className="form-control"
                        type="text"
                        onChange={handleChange}
                        name="pin_code"
                        value={values.pin_code}
                        style={{ border: "1px solid black" }}
                      />
                    </div>
                  </div>

                  <div className="modal-footer">
                    {/* <button
                      type="button"
                      className="border-0 btn-sm btn-danger p-2 rounded"
                      data-dismiss="modal"
                    >
                      Close
                    </button> */}
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="border-0 btn-sm btn-success p-2 px-4 font-weight-bold rounded"
                      data-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

EditProfile.propTypes = {
  onSuccess: PropTypes.func,
  isModal: PropTypes.bool,
};

// Define default props
EditProfile.defaultProps = {
  onSuccess: () => {},
  isModal: false,
};

export default EditProfile;
