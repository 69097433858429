import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useButtonLoader from "../../../../ButtonLoaderHook";
import {
  getSingleProvince,
  updateProviences,
} from "../../../Features/Action/AddressAction";
import Spinner from "../../../Spinner";

const EditProvinceForm = () => {
  const { id } = useParams();
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Country",
    "Loading..."
  );
  const history = useHistory();
  const dispatch = useDispatch();

  // to get all countries
  const { provience, loader } = useSelector((s) => s.AddressReducer);

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  const ProvienceHandler = async (values) => {
    setButtonLoader(true);
    const res = await dispatch(
      updateProviences({
        provience_name: values.provience_name,
        status: values.status,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        id: id,
        country_id: provience?.country?.id,
      })
    );
    if (res) {
      setButtonLoader(false);
      toast.success("Provience Updated Successfully");
      history.push("/admin/province");
    }
  };

  useEffect(() => {
    if (provience?.description?.length > 0) {
      setDescription(
        EditorState?.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(provience?.description)
          )
        )
      );
    }
  }, [provience]);
  // to get single provience
  useEffect(() => {
    dispatch(getSingleProvince(id));
  }, []);
  return !loader ? (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title font-weight-bold">Edit Province Form</h5>
          <h6 className="card-subtitle text-muted">
            {" "}
            You Can Easily Edit Province.
          </h6>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={{
              provience_name: provience?.provience_name,
              status: provience?.status,
              description: provience?.description,
            }}
            onSubmit={ProvienceHandler}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="col">
                  <div className="mb-3 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Province Name</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="provience_name"
                      id="inputCategory"
                      onChange={handleChange}
                      value={values.provience_name}
                      placeholder="Enter Province Name"
                    />
                    {errors.provience_name && touched.provience_name ? (
                      <div style={{ color: "red" }}>
                        {" "}
                        {errors.provience_name}{" "}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-3 mt-2 col-md-4">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">Status</span>{" "}
                      <span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-check-input ml-2"
                      type="radio"
                      name="status"
                      checked={values?.status === true ? "checked" : null}
                      value={true}
                      onChange={handleChange}
                      id="exampleRadios1"
                    />
                    <label
                      className="form-check-label pl-2"
                      htmlFor="exampleRadios1"
                    >
                      {" "}
                      Active
                    </label>
                    <input
                      className="form-check-input ml-2"
                      type="radio"
                      name="status"
                      value={false}
                      checked={values?.status === false ? "checked" : null}
                      onChange={handleChange}
                      id="exampleRadios2"
                    />
                    <label
                      className="form-check-label pl-2"
                      htmlFor="exampleRadios2"
                    >
                      Inactive
                    </label>
                    {errors.status && touched.status ? (
                      <div style={{ color: "red" }}> {errors.status} </div>
                    ) : null}
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3 col-md-8">
                    <label className="form-label" htmlFor="inputCity">
                      <span className="font-weight-bold">
                        Country Description
                      </span>
                      <span className="text-info"> (optional)</span>
                    </label>
                    <Editor
                      editorState={description}
                      onEditorStateChange={onEditorStateChange}
                      editorStyle={{
                        backgroundColor: "white",
                        minHeight: "150px",
                        padding: "10px 20px",
                      }}
                      value={values.description}
                      editorClassName="mt-2 p-10 mx-auto"
                    />
                  </div>
                </div>
                <div className="mb-3 mt-4 col-md-4">
                  <button
                    id="UpdateProvince"
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-success btn-lg btn-block"
                    ref={buttonLoader}
                  >
                    Update Province
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default EditProvinceForm;
