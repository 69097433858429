import React from "react";
// import { ScaleLoader, CircleLoader } from "react-spinners";

const Spinner = (props) => {
  return (
    <div
      className="App"
      {...props}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* <ScaleLoader size={70} color="red" /> */}
      {/* <CircleLoader size={80} color="red" loading /> */}
      <div className="text-center text-info">
        <div className="spinner-border mb-6" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
