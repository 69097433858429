import React from "react";
import { Switch } from "react-router-dom";
import Delivery from "../../Components/DeliveryBoy";
import Profile from "../../Components/DeliveryBoy/Profile";
import Settings from "../../Components/DeliveryBoy/Settings";
import ShowAllDelivery from "../../Components/DeliveryBoy/Delivery/ShowAllDelivery";
import SingleOrder from "../../Components/DeliveryBoy/Delivery/SigleOrder";
import DeliveryRouting from "../PrivateRoute/deliveryRouting";
import DeliveryBoyAllQuotationDelivery from "../../Components/DeliveryBoy/Delivery/AllQuotationDelivery";

const UserRoute = () => {
  return (
    <Switch>
      <DeliveryRouting exact path="/delivery" component={Delivery} />
      <DeliveryRouting
        exact
        path="/delivery/profile-settings"
        component={Settings}
      />
      <DeliveryRouting exact path="/delivery/profile" component={Profile} />
      <DeliveryRouting
        exact
        path="/delivery/get-all-delivery-orders"
        component={ShowAllDelivery}
      />
      <DeliveryRouting
        exact
        path="/delivery/get-all-quotation-delivery-orders"
        component={DeliveryBoyAllQuotationDelivery}
      />
      <DeliveryRouting
        exact
        path="/delivery/get-delivery-order/:id"
        component={SingleOrder}
      />
    </Switch>
  );
};

export default UserRoute;
