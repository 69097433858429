import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addToBasket } from "../../../Components/User/Features/slices/basketSlice";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { useHistory } from "react-router-dom";
import { Excerpt } from "../../../utils";
import HtmlParser from "react-html-parser";
import Star from "../../User/Star";

const SellerQuickViewButton = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { singleWholesalerProducts, loader } = useSelector(
    (s) => s.SingleWholesalerReducer
  );

  const addInBasket = (product) => {
    dispatch(addToBasket(product));
  };

  const { specification = [] } = singleWholesalerProducts;
  const [open, setOpen] = useState(false);
  // const onOpenModal = () => setOpen(true);
  // const onCloseModal = () => setOpen(false);

  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        className="quickview-button btn sm-h3"
      >
        {" "}
        QUICK VIEW
      </button>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size="md"
        centered
        style={{ zIndex: "99999", top: "0.2rem" }}
      >
        <Modal.Header>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pro p-2">
            {/* <ImageSlider
            height="300px"
            width="300px"
            data={imageData}
            showDots={false}
            duration={10}
          /> */}
            <img
              // src={`${AxiosConfig.defaults.baseURL}/kreesa/image/product/${specification[0]?.image[0]?.id}/${specification[0]?.image[0]?.imageName}`}
              src={props?.image}
              style={{
                height: 210,
                width: "100%",
                objectFit: "contain",
              }}
            />

            <div className="product_content">
              <h4 className="text-capitalize mb-1">
                {singleWholesalerProducts?.productName}
              </h4>
              {/* <div className="rating">
                <span className="glyphicon glyphicon-star"></span>
                <span className="glyphicon glyphicon-star"></span>
                <span className="glyphicon glyphicon-star"></span>
                <span className="glyphicon glyphicon-star"></span>
                <span className="glyphicon glyphicon-star"></span>
                  (4.2 reviews)  
              </div> */}
              <Star value={props?.review} />

              <div className="cost" style={{ fontSize: 16 }}>
                {specification[0]?.productDiscount === 0 ? (
                  <p className="text-success">
                    <b>Rs. {Math.round(specification[0]?.productPrice)}</b>
                  </p>
                ) : (
                  <p className="font-weight-bold">
                    <strike>
                      Rs. {Math.round(specification[0]?.productPrice)}
                    </strike>
                    {/* <br /> */}
                    <b className="text-success">
                      {" "}
                      Rs.{" "}
                      {Math.round(
                        specification[0]?.productPrice -
                          (specification[0]?.productPrice *
                            specification[0]?.productDiscount) /
                            100
                      )}
                    </b>
                    <strong className="text-danger font-weight-bold">
                      {" "}
                      - {Math.round(specification[0]?.productDiscount)} % OFF
                    </strong>
                  </p>
                )}
              </div>
              <div
                className="mt-2"
                style={{
                  // wordBreak: "break-all",
                  // textAlign: "justify",
                  // padding: "0 5px",
                  fontWeight: 400,
                }}
              >
                {Excerpt(HtmlParser(props?.description), 100)}
              </div>
              <div
                className="mt-4"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn-primary px-3"
                  onClick={() =>
                    history.push({
                      pathname:
                        history?.location?.state?.prevPath ===
                          "/custom-order" ||
                        history.location.pathname.includes("/custom-order")
                          ? `/custom-order/product/${singleWholesalerProducts.id}`
                          : `/single-product-page/${singleWholesalerProducts.id}`,
                    })
                  }
                >
                  SHOW ITEM
                </button>
                <Button
                  variant="danger"
                  className="px-4"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
              </div>
              <div
                className="row"
                id="row"
                style={{ backgroundColor: "white" }}
              >
                {/* <div className="col-md-4 col-sm-6 col-xs-12 hel">
                <select className="form-control qtyform" name="select">
                  <option value="" selected="">
                    Color
                  </option>
                  <option value="black">Black</option>
                  <option value="white">White</option>
                  <option value="gold">Gold</option>
                  <option value="rose gold">Rose Gold</option>
                </select>
              </div> */}

                {/* <div className="col-md-4 col-sm-12  hel">
                <select className="form-control qtyform" name="select">
                  <option value="" selected="">
                    QTY
                  </option>
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                </select>
              </div>
            </div> */}
                {/* <div className="space-ten"></div> */}
                {/* <div className="btn-ground">
              <button
                type="button"
                className="btn btn-primary qbtn"
                onClick={() => addInBasket}
              >
                {" "}
                Add To Cart
              </button>
            </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SellerQuickViewButton;
