import { createSlice } from "@reduxjs/toolkit";
import { getCategories } from "../../Action/CategoryAction/CategoryAction";

const PrimaryCategoryReducer = createSlice({
  name: "primaryCategories",
  initialState: {
    Categories: [],
    loader: false,
    errors: {},
  },
  reducers: {},
  extraReducers: {
    /* -------------------------------------------------------------------------- */
    /*                        to get all Primary Categories                       */
    /* -------------------------------------------------------------------------- */
    [getCategories.pending]: (state, action) => {
      state.loader = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.loader = false;
      state.Categories = action.payload;
    },
    [getCategories.rejected]: (state, action) => {
      state.loader = false;
      state.errors = action.payload;
    },
  },
});

 /* ----------------------------- export default ----------------------------- */
export default PrimaryCategoryReducer.reducer;
