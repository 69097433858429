import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import UserFooter from ".";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

const validationSchema = Yup.object().shape({
  complement_title: Yup.string().required("Complaint Title is required"),
  complement_type: Yup.string().required("Complaint Type is required"),
  contact_number: Yup.string().required("Contact Number is required"),
  country: Yup.string().required("Country Name is required"),
  description: Yup.string().required("Description is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
});

const KreesaComplaint = () => {
  document.title = "Kreesa | Complaint";

  const history = useHistory();

  const initialValues = {
    complement_title: "",
    complement_type: "",
    contact_number: "",
    country: "",
    description: "",
    email: "",
  };

  const [loading, setLoading] = useState(false);

  const SubmitComplaint = async (values) => {
    setLoading(true);
    try {
      await AxiosConfig.post("/kreesa/save-compliment", values).then((res) => {
        toast.success(`Complaint Placed !`);
        history.push("/");
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      toast.error(`An Error Occurred`);
    }
  };

  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      <div className="container mt-4">
        <div className="" style={{ fontSize: "18px" }}>
          <h2 className="mb-3">Kreesa Complaint</h2>
          <div className="mt-2">
            <p>
              Please use the form below to share your concern with us and we
              will come back to you with the right solution.
            </p>
            <div className="mt-4">
              <p>Please tell the type of complaint you want to report</p>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={SubmitComplaint}
                validationSchema={validationSchema}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  isValid,
                  setFieldValue,
                  handleBlur,
                }) => (
                  <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="row px-3 mt-4">
                      {[
                        "Quote Order Issue",
                        "Image Order Issue",
                        "Audio Order Issue",
                        "Complaint against seller",
                        "Complaint against buyer",
                        "Profile related Issues",
                        "Activation/Deactivation of Account",
                        "Other Issues (To be mentioned)",
                      ].map((i) => (
                        <div className="form-check mb-2 col-12">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={i}
                            name="complement_type"
                            onChange={(e) =>
                              setFieldValue("complement_type", e.target.value)
                            }
                            id={i}
                          />
                          <label className="form-check-label" for={i}>
                            {i}
                          </label>
                        </div>
                      ))}
                      {errors.complement_type && touched.complement_type ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          {errors.complement_type}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="mt-5 col-md-12 col-lg-4">
                        <label for="complaintTitle" className="form-label">
                          Complaint Title
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg "
                          id="complaintTitle"
                          name="complement_title"
                          onChange={handleChange}
                          placeholder="Provide short title to your complaint"
                        />
                      </div>
                      {errors.complement_title && touched.complement_title ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          {errors.complement_title}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="mt-3 col-md-12 col-lg-4">
                        <label for="email" className="form-label">
                          Communication Email
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-lg "
                          id="email"
                          name="email"
                          onChange={handleChange}
                          placeholder="Provide your email address"
                        />
                      </div>{" "}
                      {errors.email && touched.email ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          {errors.email}
                        </div>
                      ) : null}
                    </div>{" "}
                    <div className="row">
                      <div class="mt-3 col-md-12 col-lg-4">
                        <label for="description" class="form-label">
                          Write complaint here in detail
                        </label>
                        <textarea
                          type="text"
                          class="form-control form-control-lg"
                          id="description"
                          name="description"
                          onChange={handleChange}
                          rows={4}
                          placeholder="Provide complaint description"
                        />
                      </div>
                      {errors.description && touched.description ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          {errors.description}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div class="mt-3 col-md-12 col-lg-4">
                        <label for="description" class="form-label">
                          Your Country
                        </label>
                        <input
                          type="text"
                          class="form-control form-control-lg "
                          id="country"
                          onChange={handleChange}
                          name="country"
                          placeholder="Provide your country name"
                        />
                      </div>
                      {errors.country && touched.country ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          {errors.country}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div class="mt-3 col-md-12 col-lg-4">
                        <label for="contact" class="form-label">
                          Your Contact Number
                        </label>
                        <input
                          type="number"
                          class="form-control form-control-lg "
                          id="contact"
                          name="contact_number"
                          onChange={handleChange}
                          placeholder="Provide your contact number"
                        />
                      </div>
                      {errors.contact_number && touched.contact_number ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          {errors.contact_number}
                        </div>
                      ) : null}
                    </div>
                    <Button
                      className="mt-5 p-2"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Loading . . . " : "Submit Complaint"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default KreesaComplaint;
