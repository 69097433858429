import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../../../Footer";
import LeftNavbar from "../../../LeftNavbar";
import TopNavbar from "../../../TopNavbar";
import ProductReviewDetails from "./ProductReviewDetails";

const WholeSalerReviewById = () => {
  document.title = `Retailer | RetailerReviewById`;
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token !== "ROLE_RETAILER") {
    return <Redirect exact to="/login" />;
  }
  return (
    <>
      <div className="wrapper">
        <LeftNavbar />
        <div className="main">
          <TopNavbar />
          <main className="content">
            <ProductReviewDetails />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default WholeSalerReviewById;
