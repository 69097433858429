import React, { useEffect } from "react";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Admin/Spinner";
import SellerViewAllCard from "./SellerViewAllCard";
import {
  getAllRetailerBrandProducts,
  getAllRetailerFeatureProducts,
  getAllRetailerOrganicProducts,
  getAllRetailerTrendingProducts,
} from "../../User/Features/action";

const SellerViewAll = (props) => {
  const { title } = useParams();
  document.title = `Kreesa | ${title}`;
  const getTitleProps = props?.location.state.title;
  //

  const dispatch = useDispatch();
  const {
    allBrandProducts,
    loader,
    allTrendingProducts,
    allFeatureProducts,
    allOrganicProducts,
  } = useSelector((state) => state.userProducts);

  // featured products
  useEffect(() => {
    if (getTitleProps === "Brand products") {
      dispatch(getAllRetailerBrandProducts("wholesale"));
    }
    if (getTitleProps === "Trending Products") {
      dispatch(getAllRetailerTrendingProducts("wholesale"));
    }
    if (getTitleProps === "Feature Products") {
      dispatch(getAllRetailerFeatureProducts("wholesale"));
    }
    if (getTitleProps === "Organic Products") {
      dispatch(getAllRetailerOrganicProducts("wholesale"));
    }
  }, []);

  return (
    <div>
      <Header />
      <Nav />

      {!loader ? (
        <div className="container-fluid mt-4">
          {/* /* ---------------------------- view all product start ----------------------------  */}
          <h3 className="text-muted text-center mb-2">{title}</h3>
          <div>
            {/* all Brand */}
            {getTitleProps === "Brand products" &&
              allBrandProducts?.length > 0 && (
                <SellerViewAllCard data={allBrandProducts} />
              )}
            {/*  */}
            {getTitleProps === "Trending Products" &&
              allTrendingProducts?.length > 0 && (
                <SellerViewAllCard data={allTrendingProducts} />
              )}
            {/*  */}
            {getTitleProps === "Feature Products" &&
              allFeatureProducts?.length > 0 && (
                <SellerViewAllCard data={allFeatureProducts} />
              )}
            {/*  */}
            {getTitleProps === "Organic Products" &&
              allOrganicProducts?.length > 0 && (
                <SellerViewAllCard data={allOrganicProducts} />
              )}
          </div>

          {/* <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          /> */}

          {/* /* ---------------------------- view all product end ----------------------------  */}
        </div>
      ) : (
        <Spinner />
      )}

      <SellerFooter />
    </div>
  );
};

export default SellerViewAll;
