import * as yup from "yup";

export const validationSchema = yup.object().shape({
  product_name: yup.string().required("Product name is required"),
  quantity: yup
    .number("must be number")
    .typeError("Quantity must be a number")
    .required("Required")
    .positive("Quantity must be a positive number")
    .integer("Quantity must be an integer"),
  expected_price: yup.string().required("Price is required"),
  checked: yup.boolean(),
  photo: yup.mixed().when("checked", (checked) => {
    if (checked) {
      return yup
        .mixed()
        .required("image is required")
        .test(
          "fileType",
          "Only accept Jpg/Jpeg/Png/webp/gif file type",
          (file) =>
            file &&
            [
              "image/png",
              "image/jpg",
              "image/jpeg",
              "image/webp",
              "image/gif",
            ].includes(file.type)
        );
    }
  }),
});
