import { useState, useEffect, useLayoutEffect } from "react";
import "./Search.css";
import logo from "../../../assets/Images/kreesalogo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllWishlists,
  getNotificationCountUser,
  getUserNotification,
  searchItemRetail,
  userReadAllNotification,
  userReadNotification,
  viewAllRetailCart,
} from "../Features/action";
import { logoutUser } from "../Features/action";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { GoSearch } from "react-icons/go";
import { RiFilter2Fill } from "react-icons/ri";
import {
  InputGroup,
  Dropdown,
  DropdownButton,
  FormControl,
} from "react-bootstrap";
import { getCategories } from "../../../Common/Features/Action/CategoryAction/CategoryAction";
import { BsPerson } from "react-icons/bs";
import { getAllUserDetails } from "../../../Common/Features/Action/AllUserDetailsAction";
import QuoteModal from "../Quotation/QuoteModal";
import decode from "jwt-decode";
import moment from "moment";
import { AiOutlineHeart } from "react-icons/ai";
import Review from "../ReviewModal";
import Linkify from "react-linkify";
import SearchFilter from "../searchFilter";

function Search(props) {
  // console.log(props.email)
  const dispatch = useDispatch();
  const history = useHistory();
  const { items } = useSelector((state) => state.basketReducer);
  const { Categories, loader } = useSelector((s) => s.Categories);
  const { wishlists } = useSelector((state) => state.userProducts);

  const { retailCart, searchItem } = useSelector((state) => state.userProducts);
  // to get all user Details by their relavent email
  const { userDetails } = useSelector((s) => s.UserDetailsReducer);
  const { notification, notificationCount } = useSelector((s) => s.userProfile);

  const [searchData, setSearchData] = useState();
  const [dropdown, setDropdown] = useState({ type: "All", id: 0 });
  const [response, setResponse] = useState();
  const [modal, setModal] = useState(false);

  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState(null);

  // logout function
  const logOut = () => {
    localStorage.clear();
    dispatch(logoutUser());
  };

  // token expire time
  useLayoutEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) return logOut();
    }
  }, [localStorage.getItem("token")]);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (history) {
      if (
        history?.location?.state?.prevPath === "/custom-order" ||
        history.location.pathname === "/custom-order"
      ) {
        dispatch(
          viewAllRetailCart(
            JSON.parse(localStorage.getItem("customOrder"))?.userId
          )
        );
      }
    } else if (userId) {
      dispatch(viewAllRetailCart(userId));
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("auth") === "ROLE_USER" ||
      localStorage.getItem("auth") === "ROLE_DELIVERY_BOY"
    ) {
      dispatch(getUserNotification(userId));
      dispatch(getNotificationCountUser(userId));
      dispatch(getAllWishlists(userId));
    }
  }, []);

  useEffect(() => {
    setResponse(searchItem);
  }, [searchItem]);

  useEffect(() => {
    dispatch(getCategories());
    setDropdown({ type: "All", id: 0 });
    setResponse();
    if (localStorage.getItem("token")) {
      dispatch(getAllUserDetails(localStorage.getItem("userId")));
    }
  }, []);

  const searchDataItem = () => {
    let data;
    if (dropdown.type === "Category" || dropdown.type === "All") {
      data = {
        searchKey: searchData !== undefined ? searchData : "",
        userType: "retail",
        ...(filterData !== null ? filterData : {}),
      };
    } else {
      data = {
        catType: "Primary",
        categoryId: dropdown.id,
        searchKey: searchData,
        userType: "retail",
        ...(filterData !== null ? filterData : {}),
      };

      // dispatch(searchItemRetail(data));
    }
    AxiosConfig.post(`/kreesa/search-product`, data).then((res) => {
      localStorage.getItem("userId") && res.data.length === 0
        ? setModal(true)
        : history.push({
            pathname: "/user/search-page",
            search: `${
              searchData === undefined ? "" : `category=${searchData}?`
            }`,
            state: res.data,
          });
    });
  };

  // console.log(userDetails);
  return (
    <div
      className="container-fluid"
      style={{ background: "#211950", fontSize: "1.2rem" }}
    >
      <div className="row">
        <div className="col-10 col-sm-2 mr-sm-3 k-logos">
          {history?.location?.state?.prevPath === "/custom-order" ||
          history.location.pathname === "/custom-order" ? (
            <Link
              to="/custom-order"
              style={{
                display: "flex",
                // width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                // className="m-2 "
                id="k-logo"
                alt="kreesa"
              />
            </Link>
          ) : (
            <Link
              to="/"
              style={{
                display: "flex",
                // width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                // className="m-2 "
                id="k-logo"
                alt="kreesa"
              />
            </Link>
          )}
        </div>

        <div
          className="d-sm-none d-xs-flex col-12 d-flex mt-4"
          style={{
            marginLeft: "90px",
          }}
        >
          {!localStorage.getItem("token") && (
            <>
              <div className="" id="navbarText">
                <Link className="" to="/register-form">
                  <button type="submit" className="btn r-button mt-4">
                    <BsPerson className="login-sign" />
                    Register
                  </button>
                </Link>
              </div>
              <div className="" id="navbarText">
                <Link className="button " to="/login">
                  <button type="submit" className="btn blinkbtn ml-2 mt-4">
                    <i className="fas fa-sign-in-alt mr-2"></i>
                    Login
                  </button>
                </Link>
              </div>
            </>
          )}

          {localStorage.getItem("token") ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                }}
              >
                <div className="store md-6">
                  <a
                    className="ml-1 us-profile md-6"
                    style={{ textDecoration: "none" }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <i className="fas fa-user mr-2"></i> */}
                    {history?.location?.state?.prevPath === "/custom-order" ||
                    history.location.pathname === "/custom-order" ? (
                      ` ${
                        JSON.parse(localStorage.getItem("customOrder"))?.email
                      }'s Profile`
                    ) : (
                      <>
                        {userDetails?.profile === null ? (
                          <img
                            src={logo}
                            className="avatar img-fluid  mr-1 user-profile-image"
                            alt=""
                          />
                        ) : localStorage.getItem("auth") !== "ROLE_USER" ? (
                          <img
                            src={logo}
                            className="avatar img-fluid  mr-1 user-profile-image"
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${userDetails.userId}/${userDetails.profile}`}
                            className="avatar img-fluid  mr-1 user-profile-image"
                            alt={userDetails.firstname}
                          />
                        )}
                        <span className="user-profile-name">
                          {userDetails.firstname}'s Profile
                        </span>
                      </>
                    )}
                  </a>
                  <div
                    className="dropdown-menu mt-2 account-drop"
                    style={{ zIndex: "9999" }}
                  >
                    <Link
                      to="/manage-account?my-profile&view"
                      className="dropdown-item drop-text"
                      href="#"
                    >
                      <i className="far fa-smile"></i> My Account
                    </Link>

                    <Link
                      className="dropdown-item drop-text mt-1"
                      to="/"
                      onClick={logOut}
                    >
                      <i className="fas fa-sign-out-alt"></i> Logout
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="shopping-cart mt-4">
            <ul className="navbar-nav flex-row justify-content-end flex-wrap align-items-center mr-lg-4 mr-xl-0 m-0">
              <li className="nav-item px-3 text-uppercase mb-0 position-relative d-lg-flex">
                <div
                  className="cart position-relative"
                  style={{
                    display: "flex",
                    gap: "3rem",
                  }}
                >
                  {localStorage.getItem("auth") === "ROLE_USER" && (
                    <>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-icon dropdown-toggle"
                          href
                          id="alertsDropdown"
                          data-toggle="dropdown"
                        >
                          <div
                            className="position-relative"
                            onClick={() =>
                              dispatch(userReadAllNotification(userId))
                            }
                          >
                            <i
                              className="fal fa-bells"
                              style={{ color: "white" }}
                              data-feather="bell"
                            ></i>
                            <span
                              className={`${
                                notificationCount == 0 ? "" : "indicator"
                              }`}
                            >
                              {notificationCount == 0 ? "" : notificationCount}
                            </span>
                          </div>
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-lg  py-0"
                          aria-labelledby="alertsDropdown"
                          style={{
                            zIndex: "9999",
                            left: 0,
                            top: "auto",
                            marginTop: "60px",
                            marginLeft: "-150px",
                          }}
                        >
                          <div className="dropdown-menu-header">
                            {notificationCount == 0
                              ? ""
                              : `${notificationCount} Notifications`}
                          </div>
                          <div
                            className="list-group"
                            style={{ height: "500px", overflow: "auto" }}
                          >
                            {notification?.map((p, i) => (
                              <a
                                href
                                className="sm-hide list-group-item"
                                key={i}
                                onClick={async () => {
                                  await dispatch(userReadNotification(p.id));
                                  if (p?.route) {
                                    history.push(p?.route);
                                    history.go();
                                  }
                                }}
                              >
                                <div className="row g-0 align-items-center">
                                  <div className="col-2">
                                    <i
                                      className="d-none d-sm-inline-block fas fa-bell text-info"
                                      style={{ fontSize: "25px" }}
                                    ></i>
                                  </div>
                                  <div className="col-10">
                                    {/* <div className="text-dark">Lorem ipsum</div> */}
                                    <div className="text-muted small mt-1">
                                      <Linkify
                                        componentDecorator={(
                                          decoratedHref,
                                          decoratedText,
                                          key
                                        ) => (
                                          <a
                                            target="blank"
                                            href={decoratedHref}
                                            key={key}
                                          >
                                            {decoratedText}
                                          </a>
                                        )}
                                      >
                                        {p.notification_message}
                                      </Linkify>
                                    </div>
                                    <div className="font-weight-bold small mt-1">
                                      {/* {moment(p.created_Date)
                                    .endOf("day")
                                    .fromNow()}
                                  <span style={{ color: "grey" }}>
                                    {`  (${moment(p.creationDate).format(
                                      "DD MMM, YYYY"
                                    )})`}
                                  </span> */}
                                      {moment(p.created_date)
                                        .local()
                                        .format("lll")}
                                    </div>
                                    <div className="text-dark">
                                      {!p.read_flag && (
                                        <i className="fa fa-eye text-success font-weight-bold"></i>
                                      )}
                                      <span className="text-muted">
                                        {" "}
                                        {!p.read_flag ? "(Unseen)" : "(seen)"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))}
                          </div>
                          <div className="dropdown-menu-footer">
                            <Link to="/manage-account?all-notification">
                              Show all notifications
                            </Link>
                          </div>
                        </div>
                      </li>
                      <Link
                        to={{
                          pathname: "/user-cart",
                          state: {
                            prevPath:
                              JSON.parse(
                                localStorage.getItem("customOrder")
                              ) !== null && "/custom-order",
                          },
                        }}
                        className="cart position-relative d-inline-flex"
                      >
                        <ShoppingCartIcon
                          className="fa-lg cart background-xs-white"
                          style={{ height: "30px", color: "black" }}
                        />

                        {retailCart.length > 0 &&
                        retailCart.filter(
                          (i) => i.userId === localStorage.getItem("userId")
                        ) ? (
                          <span className="cart-basket d-flex align-items-center justify-content-center">
                            {retailCart?.length}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                    </>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-12 col-sm-5 ml-sm-2 mb-3">
          <div className="row mt-2">
            <div className="form mt-4 d-flex" id="s-form">
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  zIndex: "1000",
                }}
              >
                {/* <i className="fas fa-search"></i> */}
                {/* <input
                  type="text"
                  className="form-control form-input ml-2 search-input"
                  placeholder="Search Products..."
                  id="form-input"
                  onChange={(e) => setSearchData(e.target.value)}
                /> */}
                <InputGroup size="lg" className="mb-3 search-dropdown">
                  <DropdownButton
                    variant="outline-secondary  text-white"
                    title={dropdown.type}
                    id="input-group-dropdown-1"
                  >
                    <Dropdown.Item
                      onClick={() =>
                        setDropdown({
                          ...dropdown,
                          type: "All",
                        })
                      }
                      id="d-d1"
                    >
                      All
                    </Dropdown.Item>
                    {Categories?.primary?.map((i) => (
                      <Dropdown.Item
                        key={i.id}
                        onClick={() =>
                          setDropdown({
                            ...dropdown,
                            type: i.categoryName,
                            id: i.id,
                          })
                        }
                        id="d-d1"
                      >
                        {i.categoryName}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <FormControl
                    onChange={(e) => setSearchData(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && searchDataItem()}
                    aria-label="Text input with dropdown button"
                  />
                  <InputGroup.Text
                    style={{
                      cursor: "pointer",
                      fontSize: "1.2rem",
                      backgroundColor: "white",
                    }}
                    onClick={() => setFilterModal(true)}
                  >
                    <RiFilter2Fill color="rgb(33, 25, 80)" size="22px" />
                  </InputGroup.Text>
                  <InputGroup.Text
                    style={{ cursor: "pointer", fontSize: "1.2rem" }}
                    onClick={() => searchDataItem()}
                  >
                    <GoSearch />
                  </InputGroup.Text>
                </InputGroup>
              </div>
              {/* <button
                type="submit"
                onClick={() => searchDataItem()}
                className="btn p-1 px-4 ml-3 btn-stylee"
                style={{ backgroundColor: "rgb(72 93 137)", color: "white" }}
              >
                Search
              </button> */}
            </div>
          </div>
        </div>

        <div
          className="d-none d-sm-flex col-sm-4 ml-sm-3 cart-login mt-2"
          // style={{ justifyContent: "center" }}
        >
          <div className="" id="navbarText">
            {!localStorage.getItem("token") && (
              <Link className="button" to="/register-form">
                <button type="submit" className="btn r-button mr-md-2 mt-4">
                  <BsPerson
                    className="login-sign mr-1 mb-1"
                    style={{ fontSize: "16px" }}
                  />
                  Register
                </button>
              </Link>
            )}
          </div>
          <div className="" id="navbarText">
            {!localStorage.getItem("token") && (
              <Link className="button " to="/login">
                <button type="submit" className="btn blinkbtn mr-md-5 mt-4">
                  <i className="fas fa-sign-in-alt mr-2"></i>
                  Login
                </button>
              </Link>
            )}
            {localStorage.getItem("token") ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "60px" }}
              >
                {(localStorage.getItem("auth") === "ROLE_USER" ||
                  localStorage.getItem("auth") === "ROLE_DELIVERY_BOY") && (
                  <li className="nav-item dropdown mt-3">
                    <a
                      className="nav-icon dropdown-toggle"
                      href
                      id="alertsDropdown"
                      data-toggle="dropdown"
                    >
                      <div
                        className="position-relative"
                        onMouseOver={() =>
                          dispatch(userReadAllNotification(userId))
                        }
                      >
                        <i
                          className="fal fa-bells"
                          data-feather="bell"
                          style={{ color: "white" }}
                        ></i>
                        <span
                          className={`${
                            notificationCount == 0 ? "" : "indicator"
                          }`}
                        >
                          {notificationCount == 0 ? "" : notificationCount}
                        </span>
                      </div>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0"
                      aria-labelledby="alertsDropdown"
                    >
                      <div className="dropdown-menu-header">
                        {notificationCount == 0
                          ? ""
                          : `${notificationCount} Notifications`}
                      </div>
                      <div
                        className="list-group"
                        style={{ height: "500px", overflow: "auto" }}
                      >
                        {notification?.length > 0 ? (
                          notification?.map((p, i) => (
                            <a
                              href
                              className="sm-hide list-group-item"
                              key={i}
                              onClick={async () => {
                                await dispatch(userReadNotification(p.id));
                                if (p?.route) {
                                  history.push(p?.route);
                                  history.go();
                                }
                              }}
                            >
                              <div className="row g-0 align-items-center">
                                <div className="col-2">
                                  <i
                                    className="d-none d-sm-inline-block fas fa-bell text-info"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </div>
                                <div className="col-10">
                                  <div className="text-muted small mt-1">
                                    <Linkify
                                      componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                      ) => (
                                        <a
                                          target="blank"
                                          href={decoratedHref}
                                          key={key}
                                        >
                                          {decoratedText}
                                        </a>
                                      )}
                                    >
                                      {p.notification_message}
                                    </Linkify>
                                  </div>
                                  <div className="font-weight-bold small mt-1">
                                    {/* {moment(p.created_Date)
                                      .endOf("day")
                                      .fromNow()}
                                    <span style={{ color: "grey" }}>
                                      {`  (${moment(p.creationDate).format(
                                        "DD MMM, YYYY"
                                      )})`}
                                    </span> */}
                                    {moment(p.created_date)
                                      .local()
                                      .format("lll")}
                                  </div>
                                  <div className="text-dark">
                                    {!p.read_flag && (
                                      <i className="fa fa-eye text-success font-weight-bold"></i>
                                    )}
                                    <span className="text-muted">
                                      {" "}
                                      {!p.read_flag ? "(Unseen)" : "(seen)"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </a>
                          ))
                        ) : (
                          <p className="text-center">No Notification</p>
                        )}
                      </div>
                      <div className="dropdown-menu-footer">
                        <Link to="/manage-account?all-notification">
                          Show all notifications
                        </Link>
                      </div>
                    </div>
                  </li>
                )}
                <div className="store" style={{ marginLeft: "-45px" }}>
                  <a
                    className="us-profile md-6"
                    style={{ textDecoration: "none", display: "flex" }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <i className="fas fa-user mr-2"></i> */}
                    {history?.location?.state?.prevPath === "/custom-order" ||
                    history.location.pathname === "/custom-order" ? (
                      `${
                        JSON.parse(localStorage.getItem("customOrder"))?.email
                      }'s Profile`
                    ) : (
                      <>
                        {userDetails.profile === null ? (
                          <img
                            src={logo}
                            className="avatar img-fluid  mr-1 user-profile-image"
                            alt=""
                          />
                        ) : localStorage.getItem("auth") !== "ROLE_USER" ? (
                          <img
                            src={logo}
                            className="avatar img-fluid  mr-1 user-profile-image"
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${userDetails.userId}/${userDetails.profile}`}
                            className="avatar img-fluid  mr-1 user-profile-image"
                            alt={userDetails.firstname}
                          />
                        )}
                        <span className="user-profile-name">
                          {userDetails.firstname}'s Profile
                        </span>
                      </>
                    )}
                  </a>
                  <div
                    className="dropdown-menu mt-2 account-drop"
                    style={{ zIndex: "1000" }}
                  >
                    <Link
                      to={
                        localStorage.getItem("auth") === "ROLE_DELIVERY_BOY"
                          ? "/delivery"
                          : "/manage-account?my-profile&view"
                      }
                      className="dropdown-item drop-text"
                      href="#"
                    >
                      <i className="far fa-smile"></i> My Account
                    </Link>
                    <hr className="hr" />

                    <Link
                      className="dropdown-item drop-text mt-1"
                      to="/wishlist"
                    >
                      <AiOutlineHeart size="18px" /> Wishlist
                    </Link>
                    {/* <hr className="hr" /> */}

                    <Link
                      className="dropdown-item drop-text mt-1"
                      to="/"
                      onClick={logOut}
                    >
                      <i className="fas fa-sign-out-alt"></i> Logout
                    </Link>
                  </div>
                </div>
              </div>
            ) : localStorage.getItem("token") ? (
              <>
                <div className="store ">
                  <a
                    className="ml-1 us-profile ml-md-6"
                    style={{ textDecoration: "none" }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <i className="fas fa-user mr-2"></i> */}
                    <>
                      {userDetails.profile === null ? (
                        <img
                          src={logo}
                          className="avatar img-fluid  mr-1 user-profile-image"
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/user/${userDetails.userId}/${userDetails.profile}`}
                          className="avatar img-fluid  mr-1 user-profile-image"
                          alt={userDetails.firstname}
                        />
                      )}
                      <span className="user-profile-name">
                        {userDetails.firstname}'s Profile
                      </span>
                    </>
                  </a>
                  <div
                    className="dropdown-menu mt-2 account-drop"
                    style={{ zIndex: "1000" }}
                  >
                    <Link
                      to={
                        localStorage.getItem("auth") === "ROLE_DELIVERY_BOY"
                          ? "/delivery"
                          : "/manage-account?my-profile&view"
                      }
                      className="dropdown-item drop-text"
                      href="#"
                    >
                      <i className="far fa-smile"></i> My Account
                    </Link>
                    {/* <hr className="hr" /> */}

                    {/* <a className="dropdown-item drop-text mt-1" href="#">
              <i className="fas fa-boxes"></i> My Orders
            </a> */}
                    {/* <hr className="hr" /> */}

                    <Link
                      className="dropdown-item drop-text mt-1"
                      to="/"
                      onClick={logOut}
                    >
                      <i className="fas fa-sign-out-alt"></i> Logout
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          {localStorage.getItem("auth") === "ROLE_USER" && (
            <div className="mt-4">
              <ul
                className="navbar-nav flex-row justify-content-end align-items-center mr-lg-0 mr-xl-0"
                style={{ width: "1px", display: "flex", gap: "25px" }}
              >
                <li className="nav-item text-uppercase mb-0 position-relative d-lg-flex">
                  <Link
                    to={{
                      pathname: "/user-cart",
                      state: {
                        prevPath:
                          JSON.parse(localStorage.getItem("customOrder")) !==
                            null && "/custom-order",
                      },
                    }}
                    className="cart position-relative d-inline-flex"
                  >
                    <ShoppingCartIcon
                      className="fa-lg"
                      style={{ height: "35px", color: "white" }}
                    />
                    {retailCart.length > 0 ? (
                      <span className="cart-basket d-flex align-items-center justify-content-center">
                        {retailCart?.length}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <SearchFilter
        modal={filterModal}
        setModal={setFilterModal}
        data={filterData}
        setData={setFilterData}
      />
      <QuoteModal modal={modal} setModal={setModal} />
    </div>
  );
}

export default Search;
