import React, { useEffect } from "react";
import UserFooter from ".";
import Navbar from "../NavBar/Navbar";
import Search from "../Search/Search";
import Header from "../../RetailerWholeSaler/Header";
import Nav from "../../RetailerWholeSaler/Nav";
import SellerFooter from "../../RetailerWholeSaler/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getUserFaq } from "../Features/action";
import FaqImgae from "../../../assets/Images/faq.png";
import HtmlParser from "react-html-parser";
import Spinner from "../../Admin/Spinner";
import { Link } from "react-router-dom";

const KreesaTeaches = () => {
  document.title = "Kreesa | FAQ";
  const { faqs, loader } = useSelector((state) => state.userProducts);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserFaq());
  }, []);
  return (
    <div>
      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <>
          <Header />
          <Nav />
        </>
      ) : (
        <>
          <Search />
          <Navbar />
        </>
      )}
      {!loader ? (
        <div className="container mt-6 mb-4">
          <div className="mt-6">
            <h3 className="mb-3">Kreesa Teaches (FAQ) </h3>
          </div>
          {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
          localStorage.getItem("auth") === "ROLE_RETAILER" ? (
            <div style={{ fontSize: 20, textAlign: "justify" }}>
              <p>Kick start with basics to know kreesa.com better</p>
              <p className="font-weight-bold">Saler Related:</p>
              {faqs?.map(
                (faq, index) =>
                  faq.faqType === "SELLER" && (
                    <div className="mt-4" key={index}>
                      <p className="font-weight-bold">{faq?.question}</p>
                      {/* <p>How to shop?</p> */}
                      {/* <p>How to post the requirements?</p> */}
                      {faq.videoUrl === "" || faq.videoUrl === null ? (
                        <img
                          src={FaqImgae}
                          alt="no-image"
                          style={{
                            borderRadius: 12,
                          }}
                          height="250"
                          className="mt-2"
                        />
                      ) : (
                        <div className="mt-3 faq__video_iframe">
                          {HtmlParser(faq?.videoUrl)}
                        </div>
                      )}
                      <div className="mt-2" style={{ fontStyle: "oblique" }}>
                        {HtmlParser(faq?.answer)}
                      </div>
                    </div>
                  )
              )}
            </div>
          ) : (
            <div style={{ fontSize: 20, textAlign: "justify" }}>
              <p>Kick start with basics to know kreesa.com better</p>
              <p className="font-weight-bold">Buyer Related:</p>
              {faqs?.map(
                (faq, index) =>
                  faq.faqType === "BUYER" && (
                    <div className="mt-4" key={index}>
                      <p className="font-weight-bold">{faq?.question}</p>
                      {/* <p>How to shop?</p> */}
                      {/* <p>How to post the requirements?</p> */}
                      {faq.videoUrl === "" || faq.videoUrl === null ? (
                        <img
                          src={FaqImgae}
                          alt="no-image"
                          style={{
                            borderRadius: 12,
                          }}
                          height="250"
                          className="mt-2"
                        />
                      ) : (
                        <div className="mt-3 faq__video_iframe">
                          {HtmlParser(faq?.videoUrl)}
                        </div>
                      )}

                      <div className="mt-2" style={{ fontStyle: "oblique" }}>
                        {HtmlParser(faq?.answer)}
                      </div>
                    </div>
                  )
              )}
            </div>
          )}

          {/* <div className="mt-6">
            <p className="font-weight-bold">Here you will learn about</p>
            <p>How to update stock?</p>
            <p>How to respond to demand/assigned demand?</p>
            <p>How to place custom orders using voice/image?</p>
            <p>How to place quote order?</p>
          </div>

          <div>
            <ol type="1">
              <li className="mb-5">How to respond to quote order?</li>
              <li className="mb-5">
                How to delete the products?
                <ul>
                  <li>
                    How to delete a product from your Kreesa catalog. This will
                    help you maintain a clean catalog without products that you
                    don’t sell anymore
                  </li>
                </ul>{" "}
              </li>

              <li className="mb-5">
                How to add or change company logo?
                <ul>
                  <li>
                    How to add or change company logo on Kreesa seller panel.
                    Adding company logo enhances your catalog and helps in
                    attracting more buyers
                  </li>
                </ul>{" "}
              </li>

              <li className="mb-5">
                How to edit product details?
                <ul>
                  <li>
                    How to edit and update details and specifications of your
                    products to always keep your Kreesa catalog updated
                  </li>
                </ul>{" "}
              </li>

              <li className="mb-5">How to submit bank details on dashboard?</li>

              <li className="mb-5">
                How to update contact details?
                <ul>
                  <li>
                    How to easily update your contact number on your seller
                    panel
                  </li>
                </ul>{" "}
              </li>

              <li className="mb-5">How to download invoice?</li>
            </ol>
          </div> */}

          {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
          localStorage.getItem("auth") === "ROLE_RETAILER" ? (
            ""
          ) : (
            <p className="text-black mt-4 h3 font-weight-bold">
              Note :{" "}
              <Link to="/login">
                For seller related FAQ's login/Register to seller’s Account
              </Link>
            </p>
          )}
        </div>
      ) : (
        <Spinner />
      )}

      {localStorage.getItem("auth") === "ROLE_WHOLESALER" ||
      localStorage.getItem("auth") === "ROLE_RETAILER" ? (
        <SellerFooter />
      ) : (
        <UserFooter />
      )}
    </div>
  );
};

export default KreesaTeaches;
