import React from "react";
import { Redirect } from "react-router-dom";
import Footer from "../Footer";
import LeftNavbar from "../LeftNavbar";
import TopNavbar from "../TopNavbar";
import Message from "./Message";

const Profile = () => {
  document.title = "Wholesaler | Message";
  // to check authentication user or not
  let token = localStorage.getItem("auth");
  if (token !== "ROLE_WHOLESALER") {
    return <Redirect exact to="/login" />;
  }
  return (
    <div className="wrapper">
      <LeftNavbar />
      <div className="main">
        <TopNavbar />
        <main className="content">
          <Message />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
