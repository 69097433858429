import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Spinner from "../../../Admin/Spinner";
import useButtonLoader from "../../../ButtonLoaderHook";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Company Type is required"),
});

const EditForm = () => {
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [state, seState] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const initialValues = {
    type: state.typeName,
    description: state.description,
    id: state.id,
  };

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Update Company Type",
    "Loading..."
  );

  const getSingleCompanyType = async (id) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const GetCompanyData = await AxiosConfig.get(
        `/kreesa/admin/company-type/get-single-company-type/${id}`,
        config
      );

      seState(GetCompanyData.data);
      setButtonLoader(false);
      setLoading(false);
    } catch (err) {
      console.log(err + "Something Went Wrong ! ");
      toast.error(err?.response.data.message);
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };
  useEffect(() => {
    getSingleCompanyType(id);
  }, [id]);

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  useEffect(() => {
    if (state) {
      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(state?.description))
        )
      );
    }
  }, [state]);

  const updateHandler = async (values) => {
    setButtonLoader(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const des = draftToHtml(convertToRaw(description.getCurrentContent()));
      const res = await AxiosConfig.post(
        "/kreesa/admin/company-type/update-company-type",
        { ...values, description: des },
        config
      );
      if (res) {
        toast.success("Company Type Update Successfully");
        setButtonLoader(false);
        history.push("/company-type");
      }
    } catch (error) {
      console.log(error + "Something Went Wrong ! ");
      toast.error("Something Went Wrong !");
      setButtonLoader(false);
    }
  };
  return !loading ? (
    <div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title font-weight-bold">
              Update Company Type Form
            </h5>
            <h6 className="card-subtitle text-muted">
              {" "}
              You Can Easily Add New Company Type.
            </h6>
          </div>
          <div className="card-body">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => updateHandler(values)}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="col">
                    <div className="mb-3 col-md-4">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">Company Type</span>{" "}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        required="required"
                        name="type"
                        onChange={handleChange}
                        id="inputCategory"
                        placeholder="Enter Company Type"
                        value={values.type || ""}
                      />
                      {errors.type && touched.type ? (
                        <div style={{ color: "red" }}>{errors.type}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3 col-md-8">
                      <label className="form-label" htmlFor="inputCity">
                        <span className="font-weight-bold">
                          Company Type Description
                        </span>
                        <span className="text-info"> (optional)</span>
                      </label>
                      <Editor
                        editorState={description}
                        onEditorStateChange={onEditorStateChange}
                        editorStyle={{
                          backgroundColor: "white",
                          minHeight: "150px",
                          padding: "10px 20px",
                        }}
                        value={values.description}
                        editorClassName="mt-2 p-10 mx-auto"
                      />
                    </div>
                  </div>
                  <div className="mb-3 mt-4 col-md-4">
                    <button
                      id="catadd"
                      type="submit"
                      className="btn btn-success btn-lg btn-block"
                      ref={buttonLoader}
                      onClick={handleSubmit}
                      disabled={!isValid}
                    >
                      Update Company Type
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default EditForm;
