import React, { useEffect } from "react";
import BestSellers from "../Components/User/BestSellers/BestSellers";
import ProductList from "../Components/User/BestSellers/ProductList.js";
import Header from "../Components/User/Header/Header";
import HomepageMainContent from "../Components/User/HomepageMainContent/HomepageMainContent";
import Offer from "../Components/User/Offer/Offer";
import Search from "../Components/User/Search/Search";
import "./Homepage.css";
import Banner from "../Widgets/AddBanner/Banner";
import AccountStep from "../Widgets/AccountStep/Step";
import UserFooter from "../Components/User/UserFooter";
import { useDispatch } from "react-redux";
import {
  getRetailerProducts,
  clearCart,
  viewAllRetailCart,
} from "../Components/User/Features/action";
import { useHistory, useLocation } from "react-router-dom";

function Homepage(props) {
  document.title = "Kreesa | Home";
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      ["ROLE_WHOLESALER", "ROLE_RETAILER"].includes(
        localStorage.getItem("auth")
      )
    ) {
      history.push("/home");
    }
    dispatch(getRetailerProducts("retail"));
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("userId") === null
    ) {
      dispatch(clearCart());
      localStorage.removeItem("customOrder");
    }
  }, []);

  return (
    <>
      <div className="homepage-main">
        <Search />
        <div className="home-main-content col-sm-12 col-md-12 col-lg-12">
          <HomepageMainContent />
          <Offer />
          {/* <BestSellers /> */}
          <ProductList />
          <AccountStep />
          <div
            style={{
              background: "#e2e6eb",
            }}
          >
            <Banner />
            <UserFooter id="hf" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
