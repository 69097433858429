import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

export const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addToBasket: (state, action) => {
      const exisitingIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (exisitingIndex >= 0) {
        state.items[exisitingIndex] = {
          ...state.items[exisitingIndex],
          items: state.items[exisitingIndex].items + 1,
        };
      } else {
        let tempProductItem = { ...action.payload, items: 1 };
        state.items.push(tempProductItem);
      }
    },
    decreaseCart: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (state.items[itemIndex].items > 1) {
        state.items[itemIndex].items -= 1;
      } else if (state.items[itemIndex].items === 1) {
        const nextCartItems = state.items.filter(
          (item) => item.id !== action.payload.id
        );
        state.items = nextCartItems;
      }
    },

    removeFromBasket: (state, action) => {
      // const index = state.items.findIndex((b) => b.id === action.payload.id);

      // let newBasket = [...state.items]
      // if (index >= 0) {
      //   // item exist in the basket........remove
      //   newBasket.splice(index, 1)
      // }
      // else {
      //   console.warn(`cant remove product ${action.payload.id} as its not in basket`)
      // }
      // state.items = newBasket
      state.items.map((cartItem) => {
        if (cartItem.id === action.payload.id) {
          const nextCartItems = state.items.filter(
            (item) => item.id !== cartItem.id
          );
          state.items = nextCartItems;
        }
      });
    },
    getTotals(state, action) {
      let { total, quantity } = state.items.reduce(
        (cartTotal, cartItem) => {
          const { price, items } = cartItem;
          const itemTotal = price * items;

          cartTotal.total += itemTotal;
          cartTotal.quantity += items;
          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
  },
});

export const { addToBasket, decreaseCart, removeFromBasket, getTotals } =
  basketSlice.actions;

// Selectors - This is how we pull information from the Global store slice
export const selectItems = (state) => state.basket.items;
// export const selectTotal = (state) => state.basket.items.reduce((total, item) => total + item.price, 0)

export default basketSlice.reducer;
