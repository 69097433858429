import React from "react";
import Header from "../Header";
import Nav from "../Nav";
import Footer from "../Footer";
import knlogo from "../../../assets/Images/kn.jpg";
import aboutlogo from "../../../assets/Images/aboutlogo.png";
import piechart from "../../../assets/Images/piechart.png";
import { Link } from "react-router-dom";
import video2 from "../../../assets/Images/video2.mp4";
import about from "../../../assets/Images/aboutimg.jpg";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <Nav />
      <div className="container-fluid about-us-bg">
        <div className="row">
          <div className="col-md-10 mt-6 ml-6 intro-d">
            <div className="introducation-pa col-lg-12">
              <p className="introducation-para px-4">
                <span>In</span> order to create a new mile stone everything must
                start. Every successful business in existence today had their
                start someday. With some rays of hope to do something in our own
                country, promising blood and sweat to our mother nation we are
                going to start a E-business in Nepal.
                <br />
                <br />
                We expect support and facilities in our favor from government
                side. Almost more than 70 percentage of world is using Internet
                as the weapon for developing their nation and Nepal is also in
                the verge of Internet Era. Taking same thing in mind we are
                starting a business which is e-commerce with new idea.
              </p>
            </div>
            <h1 className="introducation-heading font-weight-bold py-6">
              Section 1: Business Information
            </h1>
            <p className="second-introducation px-4">
              Brief introduction of the project, goal and our target audience.
              Our project primary objective is to bring{" "}
              <i>
                <b>“New era in the field of e-commerce”</b>
              </i>
              . We are thinking to bring revolution in the field of E-commerce
              for which we need your support to help us with the required
              facilities.
              <br />
              <br />
              Our goal is to provide wide range of products and services at
              affordable cost. Also not to compromise with the quality.
              <br />
              <br />
              Our target audiences are businesses and end consumers . We work on{" "}
              <b>D2C(Direct to consumer)</b>, <b>B2B (Business to Business) </b>
              and <b>B2C (Business to Consumer)</b> models.
            </p>
            <div className="row d-flex mt-4 mvo-d">
              <div className="col mission">
                <h2> What is our Mission?</h2>
                <p>
                  Our mission is to satisfy our <b>10,000</b> customers by the
                  1st year end and to generate employment opportunity to at
                  least <b>300</b> Nepali Citizens which will go on increasing
                  with time.
                </p>
              </div>
              <div className="col vision">
                <h2>What is our Vision?</h2>
                <p>
                  Our vision is to prove foreign investors that Nepal is the
                  land of opportunity in the field of E-business also and to
                  make us the leading E-commerce Company by the <b>2030-year</b>{" "}
                  end. Our Vision is also to inspire the youths for stepping
                  into Entrepreneurship and to change conservative mindset.{" "}
                </p>
              </div>
              <div className="col objective ">
                <h2> What is our Objective?</h2>
                <p>
                  To bring revolution in the field of E-commerce is our one and
                  only primary objective. Benefits for Nation:
                  <ul style={{ listStyle: "disc", textAlign: "left" }}>
                    <li>Generating employment opportunities.</li>
                    <li>Producing Skilled Manpower.</li>
                    <li> Training Privileged and indigenous group</li>
                    <li>Contributing to Gross National Product</li>
                    <li>Setting example for Upcoming projects</li>
                    <li>Bringing Revolution in E-commerce</li>
                    <li>Facilitating both urban and rural areas.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <h1 className="text-center font-weight-bold ab-heading pt-6 mt-4">
            Section 2: Birth of Kreesa.com
          </h1>
          <div className="col-md-10 col-sm-12 d-flex s-section ">
            <div className="row">
              <div className="col-md-12 company-story m-6">
                <div className="aboutpara">
                  <p className="abu-para">You and Us, moving together.</p>
                  <img src={about} className="img-fluid about-img" alt="img" />
                </div>

                <hr className="f-hr ml-6" />
                <h4 className="text-uppercase font-weight-bold text-danger pl-6">
                  {" "}
                  OUR BIRTH STORY
                </h4>
                <h1 className="best-story pl-6">
                  The Best <br /> story
                </h1>
                <h3 className="pl-6 pt-4">
                  How <Link to="/">kreesa.com</Link> was born?
                </h3>
                <h3 className="pl-6 pt-4">
                  {" "}
                  <i>By</i> Nishant Marasini
                </h3>
                <hr className="s-hr ml-6" style={{ height: "5px" }} />
              </div>
              <div className="d-flex str-img">
                <div className="col-md-6  mt-4  nm">
                  <p className="ml-md-6 p-md-4 ml-lg -6 ab-para">
                    <h2 className="text-info "> © Nishant Marasini</h2>
                    <br />
                    Nishant Marasini a very optimist person aged 27 , is a
                    qualified Master’s graduate from Tribhuwan University in
                    Business Studies and an owner and CEO of e-commerce company.
                    Nishant has worked for national as well as multinational
                    companies for four and half years. Nishant has knowledge of
                    multidisciplinary subjects. He has pursued Chartered
                    Accountancy course from The Institute Of Chartered
                    Accountants of India. He is also a qualified Company
                    Secretary.
                    <h5 className="mt-2 font-weight-bold">Nishant Marasini</h5>
                    <i>
                      44620, Halchowk Swoyambhu, Kathmandu Nepal
                      <br />
                      entrepreneurnishant@icloud.com
                    </i>
                  </p>
                </div>
                <div className="col-md-6 mt-4 text-center">
                  <img src={knlogo} alt="pimage" className="img-fluid knlogo" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 ml-3 mt-6 d-flex create-history">
            <div className="row mt-4">
              <h1 className="font-weight-bold text-center">Prologue</h1>
              <div className="ch-pa d-flex">
                <div className="col-md-4 mt-6 ml-5 quote-rounded">
                  <p className="px-4 quote">
                    <i>
                      Let's Create history together, You help us, we will
                      satisfy your needs.
                      <br />
                      <br />
                      “Life is very similar to a boxing Ring. Defeat is not
                      declared when you fall DOWN. It’s declared when you Refuse
                      to get up”
                    </i>
                  </p>
                </div>
                <div className="col-md-6 mt-6 ml-md-4 prologue ">
                  <p className="text-justify px-4">
                    In ancient times, a king had a boulder placed on a roadway.
                    Then he hide himself and watched to see if anyone would
                    remove the huge rock. Some of the king's wealthiest
                    merchants and courtiers came by and simply walked around it.
                  </p>
                  <p className="text-justify px-4">
                    Many loudly blamed the king for not keeping the roads clear,
                    but none did anything about getting the big stone out of the
                    way. Then a peasant came along carrying a load of
                    vegetables. On approaching the boulder, the peasant laid
                    down his burden and tried to move the stone to the side of
                    the road. After much pushing and straining, he finally
                    succeeded. As the peasant picked up his load of vegetables,
                    he noticed a purse lying in the road where the boulder had
                    been. The purse contained many gold coins and a note from
                    the king indicating that the gold was for the person who
                    removed the boulder from the roadway. The peasant learned
                    what many others never understand.
                  </p>
                  <p className="px-4">
                    {" "}
                    Every obstacle presents an opportunity to improve condition.
                  </p>
                  <p className=" px-4 font-weight-bold">
                    AN OBSTACLE IS OFTEN A STEPPING STONE, WHAT OBSTACLE LEAD TO
                    THE BIRTH OF KREESA?
                  </p>
                  <p className=" px-4">
                    {" "}
                    We want to remove the Existing boulder in retail business of
                    waiting for giving order by providing you the platform to
                    order your needs and sell other’s needs.
                  </p>
                  <p className=" px-4"> We think of you. </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-6 concentrate">
            <h1 className="font-weight-bold text-capitalize text-center mt-6">
              What we Want to concentrate upon?
            </h1>
            <div className="row mt-6">
              <div className="col-md-6  ml-md-6 mt-4">
                <p className="px-6 py-4 c-para">
                  An ancient Indian sage was teaching his disciples the art of
                  archery. He put a wooden bird as the target and asked them to
                  aim at the eye of the bird. The first disciple was asked to
                  describe what he saw. He said, "I see the trees, the branches,
                  the leaves, the sky, the bird and its eye.." The sage asked
                  this disciple to wait. Then he asked the second disciple the
                  same question and he replied, "I only see the eye of the
                  bird." The sage said, "Very good, then shoot." The arrow went
                  straight and hit the eye of the bird.
                  <br />
                  <b>What the moral of the story?</b>
                  <br />
                  Unless we focus, we cannot achieve our goal.
                </p>
              </div>
              <div className="col-md-4 ml-2 mt-4 focus-para ">
                <p className="px-2 py-4 f-para">
                  <b> What Kreesa focuses upon?</b>
                  <br />
                  <br />
                  Our main motive is too satisfy our customers needs. So our one
                  and only focus will be the “Customers Satisfaction”.
                </p>
                <p className="px-2 f-para">
                  Co-relating with the above mentioned story, the focus of
                  second disciple was the eye of bird, Our eye of bird would be
                  “Quality over Quantity to satisfy Customers while taking care
                  of Earth”{" "}
                </p>
              </div>
            </div>
            <h3 className="mt-6 font-weight-bold text-center">
              Conclusion : We want to concentrate on your Mere Satisfaction.
            </h3>
          </div>
          <div className="col-md-12 months mt-6 mb-4 month-part">
            <h1 className=" text-capitalize text-center font-weight-bold mt-6">
              The Nine Month Maternity Period Of kreesa.com{" "}
            </h1>
            <br />{" "}
            <h1 className="text-capitalize text-center font-weight-bold">
              month by month.
            </h1>
            <div className="row mt-6">
              <div className="col-md-5 mt-4 ml-6 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6">
                  Month 1: The Founder’s Birth
                </h4>
                <p className="px-6">
                  On year 1995 August 20, Nishant Marasini was born on Resunga
                  Municipality, Ward no 6 , the then Arkhale Vdc Ward no 1. He
                  was home born from the womb of Mrs. Debmaya Marasini Mum and
                  Dad Jhabilal Marasini. He grew up in a middle class family
                  with 3 siblings.
                </p>
              </div>
              <div className="col-md-5 mt-4 ml-4 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6">
                  {" "}
                  Month 2: Business Mind?
                </h4>
                <p className="px-6">
                  He was always a business minded, one of his sister says “
                  Nishant use to sell his snacks to us, he was completely
                  business minded. We used to pay him in installments from our
                  money that we collected from festivals and Rituals”. Another
                  sister adds, “ He used to place a shop on some religious
                  functions like Teej, Dashain etc. she adds,”He also used to
                  conduct lottery of Nrs. 1 and the purchaser have to take out
                  one of the many cheat from a box and he get’s whatever it was
                  written on that cheat” she smiles.
                </p>
              </div>
              <div className="col-md-5 mt-4 ml-6 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6">
                  {" "}
                  Month 3: Business Fit?
                </h4>
                <p className="px-6">
                  Nishant has completed his masters degree on Business Studies
                  from Tribhuwan University. Also he has worked for national as
                  well as international companies. He has vast knowledge of
                  Accountancy, Auditing, Taxation , Risk management and many
                  more subjects.
                </p>
              </div>
              <div className="col-md-5 mt-4 ml-4 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6"> Month 4: Astrology</h4>
                <p className="px-6">
                  What astrologers have to say about Nishant and Business?
                  Nishant is born for Business and will be a business tycoon by
                  2030 and will introduce his country to the world.
                </p>
              </div>
              <div className="col-md-5 mt-4 ml-6 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6">
                  Month 5: 1st Kick Let’s hear it from him only
                </h4>
                <p className="px-6">
                  “The Druk Pickle Story This may sound inappropriate but I love
                  sour foods in comparison to sweet food. Yea, I am a guy.I
                  don’t know why but I am always fascinated by sour foods like
                  Water Balls, Chatpat, sour pickle etc. I am very much
                  selective and I love Druk Pickle. I went to Grocery store and
                  asked for Druk Pickle and they Replied “ Marketting Personnel
                  of good products don’t come to take orders”. Then I replied,
                  Why? They said, we don’t know maybe because our store is not
                  on prime location. Then in my mind, I started cooking : Almost
                  70% of stores are not on prime location. Each of them must be
                  facing same problem. Then I asked the storekeeper gently: Me:
                  What type of phone do you use? Him:Smart Phone Me:Are you
                  Educated? Him:Yes, I am. I have completed my graduation. Me:Do
                  you have internet connection on your phone? Him:Yes, it has.
                  Me:What if you can order things you want from your phone only?
                  Him:That will be very nice. What about the time it takes to
                  order? Me:Ok, I will try my best to save your time and fulfill
                  your needs. Him: ok (He smiles with hope in his eyes.) Then on
                  that day itself, I decided to provide them the platform where
                  they can order their requirements using their voice, image or
                  search option.”
                </p>
              </div>
              <div className="col-md-5 mt-4 ml-4 cb-para py-4 px-2">
                <h4 className="font-weight-bold px-6">
                  Month 6: 2nd Kick “The Red Bull Story
                </h4>
                <p className="px-6">
                  “Tired of thinking ,studying I convinced myself to get a
                  break. Knock knock someone knocks at my door. And being
                  enthusiastic nature of mine, I started to think who can be
                  behind the door. I watched out from the door lenses but
                  couldn’t see anyone. I immediately opened the door and looked
                  on both sides, no one was there. Was that my imagination or
                  hallucination after a continuous study of 10 hours. The only
                  solution that I thought that time was mum. I called mum and
                  explained everything. She said it may be because of tiredness.
                  She insisted me to have fruits, plenty of water and other
                  energetic foods, drinks. The only thing that came to my mind
                  1st was “Redbull” because while I stayed in India for four and
                  half years, I used to drink Redbull on my low time and when I
                  need energy most. Then I Decided to push myself towards
                  beverage store. Reaching there, I asked for a cartoon of
                  Redbull. The owner gently replied “Order linai aako xaina “
                  which means, the one who takes order on behalf of Redbull has
                  not come to collect orders. Then, I asked him quickly, why
                  don’t you go to the store and order by yourself. He said “
                  It’s not feasible always”. Then something ran into my mind,
                  how can I solve the existing problem and at the same time I
                  clicked to the concept taught by Dr Vivek Bindra in order to
                  get success in Business you need to: 1) Solve Problems & 2)
                  Provide platform And for the 2nd time it kicked my mind and
                  motivated to start kreesa.com “
                </p>
              </div>
              <div className="col-md-5 mt-4 ml-6 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6">
                  Month 7: Research and possibility
                </h4>
                <p className="px-6">
                  We started research for the feasibility of our concept. Our
                  research started from “Thulo Bharyang “. Our research involves
                  more than 1,00,000 data from primary as well as secondary
                  sources. The findings of research are illustrated with
                  following images:
                </p>
                <a href={piechart} target="_blank">
                  <img
                    className="card-img-top piechart"
                    src={piechart}
                    alt="piechart"
                  />
                </a>
              </div>
              <div className="col-md-5 mt-4 ml-4 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6">
                  Month 8: Software and Apps development
                </h4>
                <p className="px-6">
                  We entered into Agreement with Software company for
                  development of Web app and Applications compatible for both
                  Android and iOs mobiles.
                </p>
              </div>
              <div className="col-md-5 mt-4 ml-6 cb-para py-4 px-2">
                <h4 className=" font-weight-bold px-6">
                  Month 9: Trial and Launch
                </h4>
                <p className="px-6">
                  We did a trial of 1 month before launching it to you. We have
                  given birth to{" "}
                  <Link to="/" className="text-info">
                    {" "}
                    kreesa.com{" "}
                  </Link>{" "}
                  and it’s now your turn to nurture{" "}
                  <Link to="/" className="text-info">
                    {" "}
                    kreesa.com{" "}
                  </Link>
                  . Let’s Grow Together.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
