import { Redirect, Route } from "react-router";

const consumerRouting = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  const auth = localStorage.getItem("auth");
  //roles
  const consumerRole = ["ROLE_USER"];

  const salerRole = ["ROLE_RETAILER", "ROLE_WHOLESALER"];

  const adminRole = [
    "ROLE_SUPER_ADMIN",
    "ROLE_CEO",
    "ROLE_MANAGER",
    "ROLE_MARKETING_MANAGER",
    "ROLE_RECEPTIONIST",
  ];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && adminRole.includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/admin",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && salerRole.includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/home",
                state: { from: props.location },
              }}
            />
          );
        } else if (token && ["ROLE_DELIVERY_BOY"].includes(auth)) {
          return (
            <Redirect
              exact
              to={{
                pathname: "/delivery",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default consumerRouting;
