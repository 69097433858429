import React, { useState } from "react";
import "./UserLogin.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/Images/kreesalogo.png";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import useButtonLoader from "../../ButtonLoaderHook";
import UserLoginValidation from "../../Validation/UserLoginValidation";
import { useDispatch } from "react-redux";
import { viewAllRetailCart } from "../Features/action";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .email("Invalid Email"),
  // company: Yup.string().required("Company is required"),
  password: Yup.string().required("password is required"),
});

const UserLogin = ({ modal = false, modalVisible }) => {
  document.title = "Kreesa | User/Wholesaler/Retailer Login";

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Login",
    "Loading..."
  );

  let history = useHistory();

  const location = useLocation();
  const redirect = location?.state?.redirect;

  // api
  const loginHandleSubmit = async ({ username, password }) => {
    setButtonLoader(true);
    let res;

    try {
      //api
      res = await AxiosConfig.post("/kreesa/user-login-registration/login", {
        username,
        password,
      });
    } catch (error) {
      toast.error(error.response.data);
      toast.error(error.response.data.message);
      setButtonLoader(false);

      // to redirect verification link
      if (error.response && error.response.status === 428) {
        // if (
        //   res.data.role === "ROLE_RETAILER" ||
        //   res.data.role === "ROLE_WHOLESALER" ||
        //   res.data.role === "ROLE_USER"
        // ) {
        history.push("/activation-link");
        localStorage.setItem("activation", username);
        // }
      }
    }

    if (res && res.status === 203) {
      setButtonLoader(false);
      toast.info(res.data);
      return;
    }

    // to check role as well as redirect to saler or not
    if (res && res.status === 200) {
      setButtonLoader(false);
      if (
        res.data.role === "ROLE_RETAILER" ||
        res.data.role === "ROLE_WHOLESALER"
      ) {
        // set localstorage
        localStorage.setItem("token", res.headers.jwttoken);
        localStorage.setItem("username", username);
        localStorage.setItem("auth", res.data.role);
        localStorage.setItem("userId", res.data.id);
        dispatch(viewAllRetailCart(res.data.id));
        // redirect to /home url
        history.push("/home");
        history.go(0);
      } else if (res.data.role === "ROLE_DELIVERY_BOY") {
        // set localstorage
        localStorage.setItem("token", res.headers.jwttoken);
        localStorage.setItem("username", username);
        localStorage.setItem("auth", res.data.role);
        localStorage.setItem("userId", res.data.id);
        // dispatch(viewAllRetailCart(res.data.id));
        // redirect to /home url
        history.push("/delivery");
        history.go(0);
      } else if (res.data.role === "ROLE_USER") {
        // set localstorage
        localStorage.setItem("token", res.headers.jwttoken);
        localStorage.setItem("username", username);
        localStorage.setItem("auth", res.data.role);
        localStorage.setItem("userId", res.data.id);
        // redirect to / url
        !modal ? history.push("/") : modalVisible(false);
        redirect && history.push(redirect);
        !modal && history.go(0);
      } else {
        toast.error("Invalid login credentials");
      }
      // if (res.data.role === "ROLE_WHOLESALER") {
      //   // set localstorage
      //   localStorage.setItem("token", res.headers.jwttoken);
      //   localStorage.setItem("username", username);
      //   localStorage.setItem("auth", res.data.role);
      //   localStorage.setItem("userId", res.data.id);
      //   dispatch(viewAllCart(res.data.id));
      //   // redirect to /home url
      //   history.push("/home");
      //   history.go(0);
      // }
    }
  };

  // UserLoginValidation();
  return (
    <div>
      <div className="container-fluid bg-white" style={{ height: "100vh" }}>
        <div className="row ">
          <div className="col-lg-12  d-flex justify-content-center align-items-center all-login">
            <div className="mt-5">
              <Link to="/">
                <img className="logo-image mr-3" src={logo} alt="logo" />
              </Link>
            </div>
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              onSubmit={loginHandleSubmit}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
                handleBlur,
                setFieldValue,
              }) => (
                <Form
                  className="uform d-flex "
                  onSubmit={handleSubmit}
                  id="user-login-form"
                >
                  <h4 className="text-center text-capitalize font-weight-bold py-4">
                    Please login to your kreesa account
                  </h4>

                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                    <input
                      className="form-control py-2"
                      id="username"
                      name="username"
                      onChange={handleChange}
                      placeholder="Enter Username"
                      value={values.username}
                    />
                    {/* <span className="input-group-text">
                  <i className="far fa-eye-slash" id="togglePassword"></i>
                </span> */}
                  </div>
                  {errors.username && touched.username ? (
                    <div style={{ color: "red" }}> {errors.username} </div>
                  ) : null}

                  <div className="input-group mt-2">
                    <span className="input-group-text">
                      <i className="fas fa-lock"></i>
                    </span>
                    <input
                      className="form-control py-2"
                      id="password"
                      name="password"
                      onChange={handleChange}
                      placeholder="Enter Password"
                      value={values.password}
                      type={showPassword ? "text" : "password"}
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`${
                          showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                        }`}
                        id="togglePassword"
                      ></i>
                    </span>
                  </div>
                  {errors.password && touched.password ? (
                    <div style={{ color: "red" }}> {errors.password} </div>
                  ) : null}

                  <div className="mb-3" style={{ width: "100%" }}>
                    <button
                      name="submit"
                      ref={buttonLoader}
                      type="submit"
                      className="btn btn-blue text-center cbtn mt-4"
                      id="lbtn"
                    >
                      Login
                    </button>
                  </div>
                  <div className="text-center mb-4">
                    <Link to="/user/forgot-password" className="forget-link">
                      Forgot password?
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
            <div className=" mb-4 px-3 ">
              <h6 className="font-weight-bold ">
                Don't have an account?{" "}
                <Link to="/register-form" className="text-danger ">
                  {" "}
                  Register from Here
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
