import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Orders/Modal";
import { Link } from "react-router-dom";
import {
  getAllQuotations,
  ApproveQuotations,
  DeclineQuotations,
  ChangePaymentStatusQuotation,
  generateQrCode,
} from "../../Features/Action/QuotationAction";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import { filterWholesaleQuotations } from "../../Features/Reducer/QuotationsReducer";
import { getAllDeliveryBoy } from "../../Features/Action/OrderStatusAction";
import Swal from "sweetalert2";
import { Pagination } from "@mantine/core";
import { useScrollHandler } from "../../../../utils";

const QuotationsWholesale = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const [modal, setModal] = useState(false);
  const [orderId, setOrderId] = useState();

  const { deliveryBoys } = useSelector((s) => s.AdminDeliveryReducer);
  const { wholesalequot, loader } = useSelector((s) => s.QuotationsReducer);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = wholesalequot.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(wholesalequot.length / perPage);

  useEffect(() => {
    dispatch(getAllQuotations());
    dispatch(getAllDeliveryBoy());
  }, []);

  /* ------------------------ handle Approve Quotation ------------------------ */
  const handleApproveQuotation = (id) => {
    dispatch(ApproveQuotations(id));
    toast.success("Quotation Approved Successfully");
  };

  // Search
  const filterQuotation = () => {
    dispatch(filterWholesaleQuotations(inputRef.current.value));
  };
  return !loader ? (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex w-100">
          <div className="card flex-fill flex-fill">
            <div className="card-header">
              <form className="mt-2 d-sm-inline-block">
                <div className="input-group input-group-navbar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Wholesale Quotations..."
                    aria-label="Search"
                    ref={inputRef}
                    onChange={filterQuotation}
                  />
                  <button className="btn" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <h3>Wholesalers Quotation</h3>
      </div>
      {currentEntries?.length > 0 ? (
        <div className="row">
          <div className="col-12 col-lg-8 d-flex w-100">
            <div
              className="card flex-fill overflow-auto"
              onMouseEnter={useScrollHandler}
            >
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Quotation</h5>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    {/* <th>Address</th> */}
                    <th className="d-xl-table-cell">Expected Price</th>
                    <th className="d-xl-table-cell">Created By</th>
                    <th>Total Payment</th>
                    <th>Admin</th>
                    <th className="d-md-table-cell">Last Modified by</th>
                    <th>User</th>
                    <th>Quotation Status</th>
                    <th>Delivery</th>
                    <th>Payment Method</th>
                    <th>Payment Status</th>
                    <th className="d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{perPage * (currentPage - 1) + i + 1}</td>
                      <td>
                        <img
                          src={`${AxiosConfig.defaults.baseURL}/kreesa/image/quotation/${p.id}/${p.image}`}
                          width="48"
                          height="48"
                          loading="lazy"
                          className="rounded-circle mr-2"
                          alt={p.product_name}
                        />
                        {p.product_name}
                      </td>
                      <td>{p.quantity}</td>
                      {/* <td>
                        {p?.shippingAddress}, {p?.shippingState},{" "}
                        {p?.shippingCountry}
                      </td> */}
                      <td className="d-xl-table-cell">{p.expected_price}</td>
                      <td className="d-xl-table-cell">
                        <a href={`mailto:${p.createdBy}`}>{p.createdBy}</a>
                      </td>
                      <td className="d-md-table-cell">
                        {"Rs." +
                          Math.round(
                            p?.actual_price * p?.quantity + p?.shippingCost
                          )}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            p.adminQuotationApprovalStatus === "REJECTED"
                              ? "bg-danger"
                              : p.adminQuotationApprovalStatus === "APPROVED"
                              ? "bg-success"
                              : "bg-info"
                          }`}
                        >
                          {p.adminQuotationApprovalStatus}
                        </span>
                      </td>

                      <td className="d-md-table-cell">
                        <a href={`mailto:${p.lastModifiedBy}`}>
                          {p.lastModifiedBy}
                        </a>
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            p.user_approval === true
                              ? "bg-success"
                              : "bg-danger"
                          }`}
                        >
                          {p.user_approval === true
                            ? "Approved"
                            : "Not Approved"}
                        </span>
                      </td>
                      {/* quotationStatus */}
                      {p.quotationStatus === "ASSIGN" && (
                        <td>
                          <span className="badge bg-success">Assign</span>
                        </td>
                      )}
                      {p.quotationStatus === "ACCEPTED" && (
                        <td>
                          <span className="badge bg-success">Accepted</span>
                        </td>
                      )}
                      {p.quotationStatus === "REJECTED" && (
                        <td>
                          <span className="badge bg-danger">Rejected</span>
                        </td>
                      )}
                      {p.quotationStatus === "NOT_ASSIGNED" && (
                        <td>
                          <span className="badge bg-danger">Not Assigned</span>
                        </td>
                      )}
                      {p.quotationStatus === null && <td></td>}
                      <td>
                        <span
                          className={`badge ${
                            p.quoteDeliveryStatus === "DELIVERED"
                              ? "bg-success"
                              : p.quoteDeliveryStatus === "NOT_ASSIGNED"
                              ? "bg-danger"
                              : "bg-info"
                          }`}
                        >
                          {p.quoteDeliveryStatus}
                        </span>
                      </td>
                      <td>{p?.payment?.paymentMethod}</td>
                      <td>
                        <span
                          className={`badge ${
                            p?.payment?.paymentStatus === "PAID"
                              ? "bg-success"
                              : "bg-danger"
                          }`}
                        >
                          {p?.payment?.paymentStatus}
                        </span>
                      </td>
                      <td className="d-md-table-cell">
                        <div className="btn-group">
                          <button
                            type="button"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#3b7ddd",
                            }}
                            className="btn-md btn btn-success dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <div className="dropdown-menu text-center">
                            <Link
                              to={`/admin/quotations/${p.id}`}
                              className=" text-success"
                            >
                              <i className="fas fa-eye "></i> Review
                            </Link>
                            {p?.payment?.paymentStatus === "UNPAID" &&
                              p?.quoteDeliveryStatus === "DELIVERED" && (
                                <>
                                  <div className="dropdown-divider"></div>

                                  <button
                                    onClick={() => {
                                      dispatch(
                                        ChangePaymentStatusQuotation(p.id)
                                      );
                                    }}
                                    className="dropdown-item text-info"
                                  >
                                    <i className="fas fa-check"></i> Change
                                    Payment Status
                                  </button>
                                </>
                              )}

                            {p.user_approval === true && (
                              <>
                                <div className="dropdown-divider"></div>
                                <button
                                  onClick={() => {
                                    dispatch(
                                      generateQrCode({
                                        type: "quotation",
                                        id: p.id,
                                      })
                                    );
                                  }}
                                  className="dropdown-item text-info"
                                >
                                  <i className="fas fa-qrcode"></i> View QR Code
                                </button>
                              </>
                            )}

                            {p.adminQuotationApprovalStatus === "APPROVED" &&
                            p.buyer_approval === true &&
                            p.user_approval === true &&
                            p.supplier_delivery_approval === true &&
                            p.quoteDeliveryStatus === "NOT_ASSIGNED" ? (
                              <>
                                <div className="dropdown-divider"></div>

                                <button
                                  className="dropdown-item cursor-pointer text-info"
                                  onClick={() => {
                                    setModal(true);
                                    setOrderId(p.id);
                                  }}
                                >
                                  <i className="fas fa-user"></i> Assign
                                  Delivery
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                            {p.adminQuotationApprovalStatus === "PENDING" ? (
                              <>
                                <div className="dropdown-divider"></div>

                                <button
                                  onClick={() => {
                                    handleApproveQuotation(p.id);
                                  }}
                                  className="dropdown-item text-info"
                                >
                                  <i className="fas fa-check"></i> Approve
                                </button>
                              </>
                            ) : (
                              p.adminQuotationApprovalStatus === "APPROVED" &&
                              p.quotationStatus === "NOT_ASSIGNED" && (
                                <>
                                  <div className="dropdown-divider"></div>

                                  <Link
                                    to={`/admin/quotations/assign/${p.id}`}
                                    className="dropdown-item text-info"
                                  >
                                    <i className="fa fa-user"></i> Assign
                                  </Link>
                                </>
                              )
                            )}
                            {p.adminQuotationApprovalStatus === "PENDING" && (
                              <>
                                <div className="dropdown-divider"></div>

                                <button
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Enter decline reason",
                                      input: "text",
                                      inputAttributes: {
                                        autocapitalize: "off",
                                      },
                                      showCancelButton: true,
                                      confirmButtonText: "Confirm",
                                      showLoaderOnConfirm: true,
                                      preConfirm: (reason) => {
                                        dispatch(
                                          DeclineQuotations({
                                            id: p.id,
                                            reason,
                                          })
                                        );
                                      },
                                      allowOutsideClick: () =>
                                        !Swal.isLoading(),
                                    }).then((result) => {});
                                  }}
                                  className="dropdown-item text-danger"
                                >
                                  <i className="fas fa-times"></i> Decline
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* pagination  */}
          <Pagination
            onChange={setCurrentPage}
            page={currentEntries}
            total={totalPagesNum}
          />
          <Modal
            modal={modal}
            setModal={setModal}
            data={deliveryBoys}
            orderId={orderId}
            type="quote"
          />
        </div>
      ) : (
        <div className="text-center">
          <h3>No Wholesaler Quotation Found !</h3>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default QuotationsWholesale;
