import React from "react";
import { useState } from "react";
import RatingStar from "../../../Widgets/RatingStar/RatingStar";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import Spinner from "../../Admin/Spinner";
import Pagination from "../../Pagination";

const ShowReview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [loader, setLoader] = useState(false);
  const [perPage] = useState(7);

  const getAllReview = async () => {
    setLoader(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    await AxiosConfig.get(
      [
        "ROLE_SUPER_ADMIN",
        "ROLE_CEO",
        "ROLE_MANAGER",
        "ROLE_MARKETING_MANAGER",
        "ROLE_RECEPTIONIST",
      ].includes(localStorage.getItem("auth"))
        ? `kreesa/get-my-review/${localStorage.getItem("userId")}`
        : `kreesa/get-my-review/${localStorage.getItem("userId")}`,
      config
    )
      .then((res) => {
        setReviews(res.data);
      })
      .catch((err) => toast.error("An error occurred"));
    setLoader(false);
  };

  useEffect(() => {
    getAllReview();
  }, []);

  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = reviews?.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(reviews?.length / perPage);

  let j = 0;

  return !loader ? (
    <div>
      <div className="">
        {currentEntries?.length > 0 ? (
          <div className="">
            <div className="card flex-fill overflow-auto">
              <div className="card-header">
                <h3 className="card-title mb-0 font-weight-bold">My Review</h3>
              </div>
              <table className="table table-hover my-0 ">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Product</th>
                    <th>Review</th>
                    <th>Stars</th>
                    {/* <th>Approval</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((p, i) => (
                    <tr key={i}>
                      <td>{j + 1}</td>
                      <td>{p.productName}</td>
                      <td>{p.review}</td>
                      <td>{p.totalStar}</td>

                      {/* <td>
                        {p.reviewApproval === true ? (
                          <span className="badge bg-success">Approved</span>
                        ) : (
                          <span className="badge bg-info">Pending</span>
                        )}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <h4 className="text-center">No Review Found</h4>
        )}
        {/* pagination  */}
        <Pagination
          pages={totalPagesNum}
          setCurrentPage={setCurrentPage}
          currentEntries={currentEntries}
          allEntries={reviews}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default ShowReview;
