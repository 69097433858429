import React, { useState, useEffect } from "react";
import "./style.css";
import $ from "jquery";
import AxiosConfig from "../../../../Common/Api/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../Admin/Spinner";
import { getCategories } from "../../../../Common/Features/Action/CategoryAction/CategoryAction";
import { toast } from "react-toastify";
import useButtonLoader from "../../../ButtonLoaderHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ProductaddValidation from "../../../Validation/WholsalerValidation/ProductaddValidation";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import { warrantyPeriodOptions } from "../EditProduct/EditProduct";

const animatedComponents = makeAnimated();

const colorNames = [
  { label: "Red", value: "Red" },
  { label: "Green", value: "Green" },
  { label: "Black", value: "Black" },
  { label: "Yellow", value: "Yellow" },
  { label: "Gray", value: "Gray" },
  { label: "Blue", value: "Blue" },
  { label: "Golden", value: "Golden" },
];

const MultiStepProductForm = () => {
  // state
  const history = useHistory();
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [expiryDate, SetExpiryDate] = useState(new Date());
  const [hasVarient, setHasVarient] = useState(false);
  const [isReturnable, setIsReturnable] = useState([]);
  const [editorError, setEditorError] = useState(false);
  const [perishable, setPerishable] = useState(false);
  const [hasWarranty, setHasWarranty] = useState(false);
  const [colors, setColors] = useState([]);
  const [colorTouched, setColorTouched] = useState(false);
  const [colorError, setColorError] = useState(false);
  const [products, setProducts] = useState({
    specification: [
      {
        color: [
          {
            colorName: [],
          },
        ],
        productHeight: "",
        productWeight: "",
        productSize: "",
        productStatus: "",
        productThickness: "",
        productQuantity: "",
        productDiscount: "",
        productRate: "",
        productLength: "",
        productPrice: 0.0,
        images: [],
        variantName: "",
        isReturnable: false,
        returnableDays: "",
      },
    ],
    productName: "",
    warrentyType: "",
    warrentyPeriod: "",
    unit: "",
  });
  const [primaryCategoryId, setCategoryId] = useState("");
  const [secondaryCategoryId, SetsecondaryCategoryId] = useState("");
  const [ternaryCategoryId, setTernaryCategoryId] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Publish Now",
    "Loading..."
  );

  const handleChoice = (event) => {
    setProducts({
      ...products,
      [event.target.name]: event.target.value,
    });
  };
  const handleSpecificationChange = (index, event) => {
    const values = { ...products };
    if (event.target.name === "productHeight") {
      values.specification[index].productHeight = event.target.value;
    } else if (event.target.name === "productWeight") {
      values.specification[index].productWeight = event.target.value;
    } else if (event.target.name === "productSize") {
      values.specification[index].productSize = event.target.value;
    } else if (event.target.name === "productStatus") {
      values.specification[index].productStatus = event.target.value;
    } else if (event.target.name === "productThickness") {
      values.specification[index].productThickness = event.target.value;
    } else if (event.target.name === "productQuantity") {
      values.specification[index].productQuantity = event.target.value;
    } else if (event.target.name === "productDiscount") {
      values.specification[index].productDiscount = event.target.value;
    } else if (event.target.name === "productRate") {
      values.specification[index].productRate = event.target.value;
    } else if (event.target.name === "productLength") {
      values.specification[index].productLength = event.target.value;
    } else if (event.target.name === "productPrice") {
      values.specification[index].productPrice = event.target.value;
    } else if (event.target.name === "images") {
      values.specification[index].images = event.target.files;
    } else if (event.target.name === "variantName") {
      values.specification[index].variantName = event.target.value;
    } else if (event.target.name === "isReturnable") {
      values.specification[index].isReturnable = event.target.checked;
    } else if (event.target.name === "returnableDays") {
      values.specification[index].returnableDays = event.target.value;
    }
    setProducts(values);
  };

  //select color
  const handleSelectChange = (index, key, event) => {
    const values = { ...products };
    values.specification[index].color[key].colorName = event;
    setProducts(values);
  };

  //for wysiwyg editor
  const onEditorStateChange = (description) => {
    setDescription(description);
  };
  // save data by retailor
  const addProductHandler = async (e) => {
    e.preventDefault();
    if (!colors.length > 0) {
      setColorError(true);
      setColorTouched(false);
      return;
    } else {
      if (colors.filter((i) => i.e.length === 0).length > 0) {
        return;
      }
    }
    setButtonLoader(true);
    if (
      draftToHtml(convertToRaw(description.getCurrentContent())).replace(
        /<[^>]*>?/gm,
        ""
      )?.length === 1
    ) {
      setEditorError(true);
      return;
    }
    try {
      const data = new FormData();
      for (let i = 0; i < products.specification?.length; i++) {
        data.append(
          `specification[${i}].productQuantity`,
          products.specification[i].productQuantity
        );
        data.append(
          `specification[${i}].productThickness`,
          products.specification[i].productThickness
        );
        data.append(
          `specification[${i}].productWeight`,
          products.specification[i].productWeight
        );
        data.append(
          `specification[${i}].productRate`,
          products.specification[i].productRate
        );
        data.append(
          `specification[${i}].productLength`,
          products.specification[i].productLength
        );
        data.append(
          `specification[${i}].productHeight`,
          products.specification[i].productHeight
        );
        data.append(
          `specification[${i}].productSize`,
          products.specification[i].productSize
        );
        if (products.specification[i].productDiscount) {
          data.append(
            `specification[${i}].productDiscount`,
            products.specification[i].productDiscount
          );
        }
        if (hasVarient === true) {
          data.append(
            `specification[${i}].variantName`,
            products.specification[i].variantName
          );
        }
        data.append(
          `specification[${i}].returnable`,
          products.specification[i].isReturnable
        );
        if (products.specification[i].isReturnable === true) {
          data.append(
            `specification[${i}].return_days`,
            products.specification[i].returnableDays
          );
        }
        for (let k = 0; k < products.specification[i].images.length; k++) {
          data.append(
            `specification[${i}].images[]`,
            products.specification[i].images[k]
          );
        }
        for (let cl = 0; cl < products.specification[i].color.length; cl++) {
          for (
            let j = 0;
            j < products.specification[i].color[cl].colorName.length;
            j++
          ) {
            data.append(
              `specification[${i}].color[${j}].colorName[]`,
              products.specification[i].color[cl].colorName[j].label
            );
          }
        }
      }
      data.append("productName", products.productName);
      data.append("sellerType", "Retail");
      data.append(
        "productDescription",
        draftToHtml(convertToRaw(description.getCurrentContent()))
      );
      if (perishable === true) {
        data.append("expiryDate", moment(expiryDate).format("YYYY-MM-DD"));
      }
      data.append("perishable", perishable);
      data.append("hasVariant", hasVarient);
      data.append("primaryCategoryId", primaryCategoryId);
      data.append("secondaryCategoryId", secondaryCategoryId);
      if (ternaryCategoryId !== null) {
        data.append("ternaryCategoryId", ternaryCategoryId);
      }
      data.append("hasWarranty", hasWarranty);
      if (hasWarranty === true) {
        data.append("warrantyType", products.warrentyType);
        data.append("warrantyPeriod", products.warrentyPeriod);
      }
      data.append("userId", localStorage.getItem("userId"));
      data.append("unit", products.unit);

      const res = await AxiosConfig.post("/kreesa/retailer/product/add", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res.status === 200) {
        toast.success(`${products.productName} added succesfully !`);
        history.push("/retailer/product/view");
        setButtonLoader(false);
      } else {
        toast.error("Something went wrong");
        setButtonLoader(false);
      }
    } catch (err) {
      console.log(err);
      setButtonLoader(false);
    }
  };

  // to get all Categories(Primary, Secondary, Ternary)
  const { Categories, loader } = useSelector((s) => s.Categories);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  ProductaddValidation();

  // to disable field
  $(function () {
    $(".myCheckbox").change(function () {
      if ($(this).is(":checked")) {
        $("div#select_div").children().prop("disabled", false);
      } else {
        $("div#select_div").children().prop("disabled", true);
      }
    });
  });
  // for add and remove field
  const handleRemoveFields = (i) => {
    let someArray = products.specification;
    someArray.splice(i, 1);
    setProducts({ specification: someArray });
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    let array = products.specification;

    array.push({
      id: array.length + 1,

      color: [
        {
          colorName: [],
          images: [],
          // inStock: "",
        },
      ],
      productHeight: "",
      productWeight: "",
      productSize: "",
      productStatus: "",
      productThickness: "",
      productQuantity: "",
      productDiscount: "",
      productRate: "",
      productLength: "",
      productPrice: 0.0,
      images: [],
      variantName: "",
      isReturnable: false,
      returnableDays: "",
    });
    setProducts({ specification: array, ...products });
  };
  // for click to visible
  const [visible, setVisible] = useState(false);
  const [visibles, setVisibles] = useState(false);
  const [selectSecondary, SetSelectSecondary] = useState([]);
  const [selectTernary, SetSelectTernary] = useState([]);

  return !loader ? (
    <form encType="multipart/form-data" id="productadd-forms" autoComplete="off">
      <div className="d-grid gap-3 border">
        <div className="card-header text-header0">What you are selling</div>
        <div className="card-body">
          <div className="row">
            <label htmlFor="inputProduct" className="col-md-2 col-form-label">
              Product Name <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <input
                type="text"
                name="productName"
                onChange={handleChoice}
                className="form-control"
                placeholder="Enter product name"
              />
            </div>
          </div>
          <div className="form-group row mt-4">
            <label htmlFor="inputProduct" className="col-md-2 col-form-label">
              Primary Category<span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <select
                className="form-control"
                name="role"
                onChange={(e) => {
                  setCategoryId(e.target.value);
                  SetSelectSecondary(e.target.value);
                }}
              >
                <option value="">Select Primary Category First ...</option>
                {/* to get all Primary Categories */}
                {Categories.primary?.map((p, i) => (
                  <option key={i} value={p.id}>
                    {p.categoryName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mt-4">
            <label htmlFor="inputProduct" className="col-md-2 col-form-label">
              Secondary Category <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <select
                className="form-control"
                name="role2"
                onChange={(e) => {
                  SetsecondaryCategoryId(e.target.value);
                  SetSelectTernary(e.target.value);
                }}
              >
                <option value="">Select Secondary Category First ...</option>
                {/* to get all Secondary Categories */}
                {Categories.secondary?.map(
                  (p, i) =>
                    selectSecondary == p.primaryCategoryId && (
                      <option key={i} value={p.id}>
                        {p.secondaryCategoryName}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
          <div className="row mt-4">
            <label htmlFor="inputProduct" className="col-md-2 col-form-label">
              Ternary Category
              {/* <span className="text-danger">*</span> */}
            </label>
            <div className="col-lg-6">
              <select
                className="form-control"
                name="role3"
                onChange={(e) => {
                  setTernaryCategoryId(e.target.value);
                }}
              >
                <option value="">Select Ternary Category First ...</option>
                {/* to get all Ternary Categories */}
                {Categories.ternary?.map(
                  (p, i) =>
                    selectTernary == p.secondaryCategoryId && (
                      <option key={i} value={p.id}>
                        {p.ternaryCategoryName}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="d-grid gap-3  border">
        <div className="card-header text-header0">Basic Information</div>
        <div className="card-header">
          <div className="form-group row">
            <label htmlFor="inputProduct" className="col-form-label">
              Product's Short Description:
              <span className="text-danger">*</span>
            </label>
            <div className="text-editor1 mt-2">
              <Editor
                name="description"
                editorState={description}
                onEditorStateChange={onEditorStateChange}
                editorClassName="mt-2 p-10 mx-auto"
                onBlur={() => {
                  const check = draftToHtml(
                    convertToRaw(description.getCurrentContent())
                  ).replace(/<[^>]*>?/gm, "")?.length;
                  check === 1 ? setEditorError(true) : setEditorError(false);
                }}
              />
            </div>
          </div>{" "}
          {editorError && (
            <p style={{ color: "red", marginTop: "10px" }}>
              Description is required
            </p>
          )}
          <hr />
          <div className="form-group row align-items-center">
            <label className="form-label col-md-2" htmlFor="unit">
              Product Unit <span className="text-info">(Optional)</span>
            </label>
            <div className="col-md-6">
              <input
                type="text"
                name="unit"
                onChange={handleChoice}
                className="form-control"
                placeholder="Enter the product Unit (eg. per unit, per kg ...)"
              />
            </div>
          </div>
          <div className="form-group row mt-4">
            <label className="form-label" htmlFor="inputPassword4">
              <input
                type="checkbox"
                className="form-check-input mr-2"
                name="Perishable"
                onClick={() => setPerishable(!perishable)}
              />
              Is Perishable ?
            </label>
            {perishable && (
              <div className="form-group row mt-4">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Expiry Date<span className="text-danger"> *</span>
                </label>
                <div className="col-lg-6">
                  <DatePicker
                    className="form-control"
                    name="expiryDate"
                    selected={expiryDate}
                    onChange={(date) => SetExpiryDate(date)}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="form-group row mt-4">
            <div className="">
              <input
                type="checkbox"
                className="form-check-input mr-2"
                name="hasWarrenty"
                onClick={() => {
                  setVisible(!visible);
                  setHasWarranty(!hasWarranty);
                }}
                onChange={handleChoice}
              />
              <label id="search_checkbox_label" className="ml-2">
                {" "}
                Click If Product Have Warrenty{" "}
              </label>
            </div>
            {visible && (
              <div className="row mt-4">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Warrenty Type: <span className="text-danger">*</span>
                </label>

                <div className="col-md-4">
                  <select
                    className="form-control mt-2"
                    name="warrentyType"
                    onChange={handleChoice}
                  >
                    <option>Select Warrenty Type</option>
                    <option value="Shop Warrenty">Shop Warrenty</option>
                    <option value="Brand Warrenty">Brand Warrenty</option>
                  </select>
                </div>
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 mt-1 col-form-label"
                >
                  Warrenty Time Period: <span className="text-danger">*</span>
                </label>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    name="warrentyPeriod"
                    onChange={handleChoice}
                  >
                    <option>Select Warrenty Year</option>
                    {warrantyPeriodOptions?.map((option, i) => (
                      <option key={i} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="fieldGroup">
        <div
          className="row mr-4 mt-0"
          style={{ float: "right", width: "185px" }}
        >
          <span className="btn btn-primary" onClick={handleAddFields}>
            Add More Specification
          </span>
        </div>
        <div className="ml-4 mb-4">
          <input
            type="checkbox"
            className="form-check-input ml-2"
            name="hasVarient"
            onClick={() => {
              setVisibles(!visibles);
              setHasVarient(!hasVarient);
            }}
          />
          <label id="search_checkbox_label" className="ml-2">
            {" "}
            Click If Product Have Variation{" "}
          </label>
        </div>
        {products.specification?.map((x, ind) => (
          <div className="d-grid gap-3 border" key={ind}>
            <div className="card-header text-header0">
              Variations
              <div className=" mt-0" style={{ float: "right" }}>
                {products.specification?.length > 1 ? (
                  <span
                    className="btn btn-danger"
                    style={{
                      width: "178px",
                      position: "relative",
                      left: "21px",
                      top: "7px",
                    }}
                    onClick={() => handleRemoveFields(ind)}
                  >
                    Remove
                  </span>
                ) : (
                  <span
                    className="btn btn-danger ml-2"
                    style={{ cursor: "not-allowed", display: "none" }}
                    disabled="disabled"
                  >
                    Remove
                  </span>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row mt-4">
                <label
                  htmlFor="inputProduct"
                  className="col-md-2 col-form-label"
                >
                  Varient: <span className="text-danger">*</span>
                </label>

                <div className="col-md-6" id="select_div">
                  <input
                    className="form-control"
                    id="varientName"
                    name="variantName"
                    disabled={hasVarient ? "" : "disabled"}
                    placeholder="Varient Name"
                    onChange={(e) => handleSpecificationChange(ind, e)}
                  />
                </div>
              </div>
              <div>
                {x.color?.map((c, inc) => (
                  <div className="form-group row mt-4" key={inc}>
                    <label
                      htmlFor="inputProduct"
                      className="col-md-2 col-form-label"
                    >
                      Color:
                    </label>
                    <div className="col-md-6">
                      {/* <Select
                        className="Select"
                        name="colorName"
                        placeholder="Choose Color"
                        onChange={(event) =>
                          handleSelectChange(ind, inc, event)
                        }
                        options={colorNames}
                        isMulti
                      
                      /> */}
                      <CreatableSelect
                        className="Select"
                        name="colorName"
                        placeholder="Choose Color"
                        isMulti
                        onChange={(event) => {
                          handleSelectChange(ind, inc, event);
                          if (
                            colors.some(
                              (ii) => ii.index === ind && ii.id === inc
                            )
                          ) {
                            let position = colors.findIndex(
                              (jj) => jj.index === ind && jj.id === inc
                            );
                            colors[position] = {
                              index: ind,
                              id: inc,
                              e: event,
                            };
                          } else {
                            setColors([
                              ...colors,
                              { index: ind, id: inc, e: event },
                            ]);
                          }
                        }}
                        onBlur={() => {
                          setColorError(false);
                          setColorTouched(true);
                        }}
                        options={colorNames}
                      />
                      {colorTouched && (
                        <div className="text-danger mt-2">
                          {colors.some(
                            (ii) => ii.index === ind && ii.id === inc
                          ) &&
                            colors[
                              colors.findIndex(
                                (jj) => jj.index === ind && jj.id === inc
                              )
                            ].e.length === 0 &&
                            "Please Select Colors"}
                        </div>
                      )}

                      {colorError && (
                        <div className="text-danger mt-2">
                          Please Select Colors
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="form-group row mt-4">
                  <label
                    htmlFor="inputProduct"
                    className="col-md-2 col-form-label"
                  >
                    Product's Images (4): <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-6">
                    <input
                      type="file"
                      multiple
                      className="form-control"
                      id="pimages"
                      name="images"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={(event) =>
                        handleSpecificationChange(ind, event)
                      }
                    />
                    <small>
                      {" "}
                      Accepted Formats: png, jpg & jpeg{" "}
                      <span className="text-danger font-weight-bold">
                        (Must be 1024 * 1024)
                      </span>
                    </small>
                  </div>
                </div>
                <div className="mt-4 mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input ml-2"
                    name="isReturnable"
                    id="isReturnable"
                    checked={isReturnable.includes("Returnable" + ind)}
                    onClick={(e) => {
                      if (!e.target.checked) {
                        setIsReturnable(
                          isReturnable.filter((i) => i !== "Returnable" + ind)
                        );
                      } else {
                        setIsReturnable([...isReturnable, "Returnable" + ind]);
                      }
                      handleSpecificationChange(ind, e);
                    }}
                  />
                  <label htmlFor="isReturnable" className="ml-2">
                    {" "}
                    Click If Product Is Returnable{" "}
                  </label>
                </div>
                {isReturnable.includes("Returnable" + ind) && (
                  <div className="form-group row mt-4">
                    <label
                      htmlFor="returnableDays"
                      className="col-md-2 col-form-label"
                    >
                      Returnable Days: <span className="text-danger">*</span>
                    </label>

                    <div className="col-md-6" id="select_div">
                      <input
                        className="form-control"
                        id="returnableDays"
                        name="returnableDays"
                        type="number"
                        placeholder="Returnable Days"
                        onChange={(e) => handleSpecificationChange(ind, e)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <table className="table">
                  <thead className="table-primary">
                    <tr>
                      <th scope="col">Product Size</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">
                        Price{" "}
                        <span className="text-info">
                          (Per Quantity including 13% VAT)
                        </span>
                        <span className="ml-1"> * </span>
                      </th>
                      <th scope="col">Discount in %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">
                        <div>
                          <input
                            className="form-control"
                            id="inStock"
                            name="productSize"
                            onChange={(event) =>
                              handleSpecificationChange(ind, event)
                            }
                            placeholder="Size"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            className="form-control"
                            id="productQuantity"
                            name="productQuantity"
                            onChange={(event) =>
                              handleSpecificationChange(ind, event)
                            }
                            placeholder="Quantity"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            className="form-control"
                            id="productRate"
                            name="productRate"
                            onChange={(event) =>
                              handleSpecificationChange(ind, event)
                            }
                            placeholder="Enter Price Inclusive of VAT @ 13%"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            className="form-control"
                            id="productDiscount"
                            name="productDiscount"
                            onChange={(event) =>
                              handleSpecificationChange(ind, event)
                            }
                            placeholder="Special Price"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-group row mt-4">
                  <div className="row mt-3">
                    <label
                      htmlFor="inputProduct"
                      className="col-md-2 col-form-label"
                    >
                      Package Weight (kg):{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-2 mt-2">
                      <input
                        name="productWeight"
                        className="form-control"
                        onChange={(event) =>
                          handleSpecificationChange(ind, event)
                        }
                        placeholder="Package Weight (kg)"
                      />
                    </div>
                    <label
                      htmlFor="inputProduct"
                      className="col-md-2 mt-1 col-form-label"
                    >
                      Package Dimensions:{" "}
                      <span className="text-info">(Optional)</span>
                    </label>
                    <div className="col-md-2 mt-2">
                      <input
                        className="form-control"
                        name="productLength"
                        placeholder="Length (cm)"
                        onChange={(event) =>
                          handleSpecificationChange(ind, event)
                        }
                      />
                    </div>
                    <div className="col-md-2 mt-2">
                      <input
                        className="form-control"
                        name="productThickness"
                        placeholder="Thickness (cm)"
                        onChange={(event) =>
                          handleSpecificationChange(ind, event)
                        }
                      />
                    </div>
                    <div className="col-md-2 mt-2">
                      <input
                        className="form-control"
                        name="productHeight"
                        onChange={(event) =>
                          handleSpecificationChange(ind, event)
                        }
                        placeholder="Height (cm)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex-fill flex-fill">
        <button
          ref={buttonLoader}
          onClick={addProductHandler}
          className="btn btn-lg btn-outline-info mt-2"
          id="psends"
          style={{ float: "right", textTransform: "capitalize" }}
        >
          Publish Now
        </button>
      </div>
    </form>
  ) : (
    <Spinner />
  );
};

export default MultiStepProductForm;
