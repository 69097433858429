import React from "react";
import "./Step.css";
import { NavLink } from "react-router-dom";

const Step = () => {
  return (
    <div>
      {localStorage.getItem("token") === null && (
        // <div classNameName="container-fluid step-page col-sm-12 col-md-12 col-lg-12 ">
        //   <div classNameName="page-header">
        //     <h2 classNameName="text-uppercase text-center py-6 font-weight-bold">
        //       Create Your Kreesa Account And Start Your Work
        //     </h2>
        //   </div>
        //   <div classNameName="container-fluid col-sm-12 col-md-12 col-lg-12 mt-4">
        //     <div>
        //       <div
        //         classNameName="row step2"
        //         style={{
        //           display: "flex",
        //           justifyContent: "center",
        //           // gap: "20px",
        //         }}
        //       >
        //         <div classNameName="col py-5 col1">
        //           <i classNameName="fas fa-user-circle fuc"></i>
        //           <h5
        //             classNameName="step-heading"
        //             style={{ marginTop: "10px", fontSize: "17px" }}
        //           >
        //             Step 1
        //           </h5>
        //           <p id="par">Choose Your Account</p>
        //         </div>
        //         <div classNameName="col ml-2 py-5 col1">
        //           <i classNameName="fas fa-id-card-alt fic"></i>
        //           <h5
        //             classNameName="step-heading"
        //             style={{ marginTop: "10px", fontSize: "17px" }}
        //           >
        //             Step 2
        //           </h5>
        //           <p id="par">Complete Your Profile</p>
        //         </div>
        //         <div classNameName="col ml-2 py-5 col1">
        //           <i classNameName="fas fa-lock"></i>
        //           <h5
        //             classNameName="step-heading"
        //             style={{ marginTop: "10px", fontSize: "17px" }}
        //           >
        //             Step 3
        //           </h5>
        //           <p id="par">Type Strong Password</p>
        //         </div>
        //         <div classNameName="col ml-2 py-5 col1">
        //           <i classNameName="fas fa-cloud-upload-alt"></i>
        //           <h5
        //             classNameName="step-heading"
        //             style={{ marginTop: "10px", fontSize: "17px" }}
        //           >
        //             Step 4
        //           </h5>
        //           <p id="par">Upload Your Info</p>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <NavLink
        //     to="/signup"
        //     classNameName="d-flex mt-4"
        //     style={{ justifyContent: "space-around" }}
        //   >
        //     <button
        //       type="submit"
        //       classNameName="btn btn-blue text-center mb-6"
        //       id="stepbut"
        //     >
        //       <i classNameName="fas fa-sign-in-alt mr-2"></i>
        //       Lets Get Started
        //     </button>
        //   </NavLink>
        // </div>
        <div
          className="container-fluid px-lg-6"
          style={{
            backgroundColor: "#e2e6eb",
          }}
        >
          <div className="row justify-content-center mt-0 text-center">
            <div className="col-12 col-sm-9 col-md-12 col-lg-12 text-center p-0 mb-2">
              <div className="step-card px-0 pb-0 mt-4">
                <h2 className="py-4">
                  <strong className="text-uppercase h2 font-weight-bold">
                    Create Your Kreesa Account And Start Your Work
                  </strong>
                </h2>

                <div className="row mt-4">
                  <div className="col-md-12 mx-0 mb-5">
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" id="account">
                          <strong id="sfont">Choose Your Account</strong>
                        </li>
                        <li id="personal">
                          <strong id="sfont">Complete Your Profile</strong>
                        </li>
                        <li id="payment">
                          <strong id="sfont">Type Strong Password</strong>
                        </li>
                        <li id="confirm">
                          <strong id="sfont">Upload Your Info</strong>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <NavLink
              to="/signup"
              classNameName="d-flex text-center"
              style={{ zIndex: "1" }}
            > */}
            {/* <button
                style={{ borderRadius: "7px" }}
                type="submit"
                classNameName="btn btn-blue"
                id="stepbut"
              >
                <i classNameName="fas fa-sign-in-alt mr-2"></i>
                Lets Get Started
              </button> */}
            <div
              className="lets__get_start_button_wrap"
              style={{ zIndex: "1" }}
            >
              <NavLink
                to="/signup"
                className="lets__get_start_button mb-6"
                style={{
                  color: "#fff",
                  border: "none",
                  outline: "none",
                  zIndex: 99999,
                }}
              >
                Let's Get Started
              </NavLink>
            </div>
            {/* </NavLink> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step;
