import React, { useLayoutEffect, useState } from "react";
import "./Message.css";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import moment from "moment";
import logo from "../../../assets/Images/kreesalogo.png";
import Modal from "react-responsive-modal";
import Spinner from "../../Admin/Spinner";
import "react-responsive-modal/styles.css";
import Pagination from "../../Pagination";

const Message = () => {
  const [user, setUser] = useState([]);
  const [state, setState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  // modal start
  const [modal, setModal] = useState({ show: false, requestedModalId: 0 });
  const [loading, setLoading] = useState(false);
  const handleOpenModal = (id) => {
    setModal({ show: true, requestedModalId: id });
  };
  const handleCloseModal = () => {
    setModal({ show: false });
  };

  /* ----------------------------------- ... ---------------------------------- */
  // const messageHandle = async (values, submitProps) => {
  //   try {
  //     setButtonLoader(true);
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     };
  //     const res = await AxiosConfig.post(
  //       "/kreesa/retailer/send-message",
  //       {
  //         ...values,
  //         messageBy: localStorage.getItem("auth") === "ROLE_RETAILER" ? "Retailer" : "Wholesaler",
  //         userId: localStorage.getItem("userId"),
  //       },
  //       config
  //     );
  //     if (res && res.status === 200) {
  //       setButtonLoader(false);
  //       submitProps.resetForm();
  //       getSingleMessages();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setButtonLoader(false);
  //   }
  // };
  /* -------------------------- ...getSingleMessages -------------------------- */
  const getSingleMessages = async () => {
    setLoading(true);
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const GetSingleRetailor = await AxiosConfig.get(
        `/kreesa/admin/get-all-message`,
        config
      );
      setState(GetSingleRetailor.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  /* ----------------------------------- ... ---------------------------------- */
  const getUser = async (userId) => {
    try {
      // to get token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const user = await AxiosConfig.get(
        `/kreesa/admin/get-user-by-user-id/${userId}`,
        config
      );
      setUser(user.data);
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    getSingleMessages();
  }, []);
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = state.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(state.length / perPage);

  return !loading ? (
    <div>
      <h3>Messages</h3>
      <div className="back-container">
        <div className="container">
          {/* <div className="row chat-top">
            <div className="col-sm-4 border-right border-secondary">
              <img
                src="https://media.istockphoto.com/photos/colored-powder-explosion-on-black-background-picture-id1057506940?k=20&m=1057506940&s=612x612&w=0&h=3j5EA6YFVg3q-laNqTGtLxfCKVR3_o6gcVZZseNaWGk="
                alt=""
                className="profile-image rounded-circle"
              />
              <span className="float-right mt-2">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chat-left-fill mx-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                </svg>
              </span>
            </div>
            <div className="col-sm-8">
              <img
                src="https://media.istockphoto.com/photos/colored-powder-explosion-on-black-background-picture-id1057506940?k=20&m=1057506940&s=612x612&w=0&h=3j5EA6YFVg3q-laNqTGtLxfCKVR3_o6gcVZZseNaWGk="
                alt=""
                className="profile-image rounded-circle"
              />
              <span className="ml-2">Rahul Kumar</span>
              <span className="float-right mt-2">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots-vertical mx-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </span>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12 contacts">
              {/* <div className="contact-table-scroll" style={{height:'550px !important'}}> */}
              <div className="">
                <table className="table table-hover">
                  <tbody>
                    {currentEntries.map((p, i) => (
                      <>
                        <tr
                          className="cursor-pointer"
                          key={i}
                          onClick={() => {
                            getUser(p.userId);
                            handleOpenModal(p.userId);
                          }}
                        >
                          <td>
                            <img
                              src={logo}
                              alt=""
                              className="profile__image rounded-circle"
                            />
                          </td>
                          <td>
                            <span className="font-weight-bold">
                              {p.companyName}
                            </span>
                            <br /> <small>{p.messageData}</small>
                          </td>
                          <td>
                            <small className="font-weight-bold">
                              {p.messageBy}
                            </small>
                          </td>
                        </tr>
                        {/*Modal  */}
                        {modal.show && modal.requestedModalId == p.userId && (
                          <Modal
                            open={modal}
                            onClose={handleCloseModal}
                            center
                            classNames={{
                              overlayAnimationIn: "customEnterOverlayAnimation",
                              overlayAnimationOut:
                                "customLeaveOverlayAnimation",
                              modalAnimationIn: "customEnterModalAnimation",
                              modalAnimationOut: "customLeaveModalAnimation",
                              modal: "customModal",
                            }}
                            animationDuration={1000}
                          >
                            <div className="pro">
                              {/* <div className="col-md-4">
                                <div className="profile-img">
                                  <img src={`${AxiosConfig.defaults.baseURL}/kreesa/image/wholesaler/${p.userId}/${user.companyLogo}`} alt={user?.companyName} />
                                </div>
                              </div> */}

                              <h3 className="text-uppercase">
                                {user?.companyName}{" "}
                              </h3>
                              <h6>
                                {user.role === "ROLE_RETAILER"
                                  ? "Retailer"
                                  : "WholeSaler"}
                              </h6>
                            </div>

                            <div className="col-md-6 m-auto">
                              <div className="tab-content profile-tab custom-height">
                                <div
                                  className="tab-pane fade show active"
                                  id="home"
                                  role="tabpanel"
                                  aria-labelledby="home-tab"
                                >
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Company Name</label>
                                    </div>
                                    <div className="col-md-6">
                                      <p>{user?.companyName}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Company Address</label>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                        {`${user?.districtName}, ${user?.provienceName}, ${user?.countryName}`}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Company Email</label>
                                    </div>
                                    <div className="col-md-6">
                                      <p>{user?.companyEmail}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Company Type</label>
                                    </div>
                                    <div className="col-md-6">
                                      <p>{user?.companyType}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Company Contact</label>
                                    </div>
                                    <div className="col-md-6">
                                      <p> {user?.companyContactNo}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>PAN No</label>
                                    </div>
                                    <div className="col-md-6">
                                      <p> {user?.panNo}</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Register Status</label>
                                    </div>
                                    <div className="col-md-6">
                                      {user?.isRegistered === true ? (
                                        <p>Register</p>
                                      ) : (
                                        <p>Not Register</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Insuranced Status</label>
                                    </div>
                                    <div className="col-md-6">
                                      {user?.isInsured === true ? (
                                        <p>Insuranced</p>
                                      ) : (
                                        <p>Not Insuranced</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Status</label>
                                    </div>
                                    <div className="col-md-6">
                                      {user?.flag === true ? (
                                        user?.locked === true ? (
                                          <p>Active</p>
                                        ) : (
                                          <p>Inactive</p>
                                        )
                                      ) : (
                                        <p>Blocked</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>Created Date</label>
                                    </div>
                                    <div className="col-md-6">
                                      <p>
                                        {moment(user.createdAt).format("ll")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="col-sm-8 message-area">
              <div className="message-table-scroll">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bg-primary p-2 mt-2 mr-5 shadow-sm text-white float-left rounded">
                          Hello Rahul
                          <br />
                          <small className="pt-2" style={{ float: "right" }}>
                            11:44 PM
                          </small>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bg-success p-2 mt-2 mr-5 shadow-sm text-white float-right rounded">
                          Hello Rahul
                          <br />
                          <small className="pt-2" style={{ float: "right" }}>
                            11:44 PM
                          </small>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row message-box p-3">
                <div className="col-sm-1 mt-2"></div>
                <div className="col-sm-8">
                  <form action="">
                    <input type="text" className="form-control" placeholder="Write message..." />
                  </form>
                </div>
                <div className="col-sm-2">
                  <button type="submit" className="btn btn-success btn-block">
                    Send
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          {/* pagination  */}
          <Pagination
            pages={totalPagesNum}
            setCurrentPage={setCurrentPage}
            currentEntries={currentEntries}
            allEntries={state}
          />
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default Message;
