import React from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import useButtonLoader from "../../ButtonLoaderHook";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import * as Yup from "yup";

const EditModal = (props) => {
  const [buttonLoader1, setButtonLoader1] = useButtonLoader(
    "Save Changes",
    "Loading..."
  );
  // to update stock
  const UpdateStockHandler = async (values) => {
    setButtonLoader1(true);
    try {
      const updateStock = await AxiosConfig.post(
        `/kreesa/stock-management/update-stock/${props.id}`,
        {
          ...values,
        }
      );
      if (updateStock) {
        props.onHide();
        toast.success("Stock updated sucessfually !");
      }
      setButtonLoader1(false);
      props.getStocks();
    } catch (error) {
      console.log(error);
      setButtonLoader1(false);
    }
  };
  /* ----------------------------- ...validate... ----------------------------- */
  const validationSchema = Yup.object().shape({
    quantity: Yup.number("Must be number")
      .typeError("Stock must be a number")
      .required("Required")
      .positive("Must be a positive number")
      .integer("Must be an integer"),
    action: Yup.string().required("Required"),
  });

  return (
    <div>
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title>Update {props.name} Total Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              quantity: props.quantity,
              action: "INCREASE",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => UpdateStockHandler(values)}
          >
            {({
              values,
              handleSubmit,
              handleBlur,
              handleChange,
              dirty,
              isValid,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="inputStockNew">
                    Total Stock{" "}
                    <span className="font-weight-bold">{props.quantity}</span>
                  </label>
                  {/*  */}
                  <div className="mb-3 mt-2 col-md-6">
                    <label className="form-label" htmlFor="inputPassword4">
                      Choose Any method ?
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="radio">
                          {" "}
                          <input
                            type="radio"
                            name="action"
                            value="INCREASE"
                            checked={
                              values.action === "INCREASE" ? "INCREASE" : null
                            }
                            onChange={handleChange}
                          />{" "}
                          <span
                            style={{
                              display: "block",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Increase
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6 mcol">
                        <label className="radio">
                          {" "}
                          <input
                            type="radio"
                            name="action"
                            value="DECREASE"
                            checked={
                              values.action === "DECREASE" ? "DECREASE" : null
                            }
                            onChange={handleChange}
                          />{" "}
                          <span
                            style={{
                              display: "block",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Decrease
                          </span>
                        </label>
                      </div>
                    </div>
                    {errors.action && touched.action ? (
                      <div style={{ color: "red" }}>{errors.action}</div>
                    ) : null}
                  </div>
                  {/*  */}
                  <input
                    name="quantity"
                    type="text"
                    placeholder="Enter New Stock"
                    className="form-control"
                    id="inpuStockNew"
                    onChange={handleChange}
                    value={values.quantity}
                  />
                </div>
                {errors.quantity && touched.quantity ? (
                  <div style={{ color: "red" }}>{errors.quantity}</div>
                ) : null}

                <button
                  type="submit"
                  className="btn btn-primary"
                  ref={buttonLoader1}
                  onClick={handleSubmit}
                >
                  Save changes
                </button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditModal;
